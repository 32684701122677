const React = require('react');
const {campaign,areSameDeep,globalDataListener} = require('../lib/campaign.js');
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Popover from '@material-ui/core/Popover';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
const {snackMessage} = require('../src/notification.jsx');
import Autocomplete from '@material-ui/lab/Autocomplete';
import Modal from '@material-ui/core/Modal';
import sizeMe from 'react-sizeme';
const {gamesystemOptions,gamesystemAnyOptions} = require('../lib/stdvalues.js');

class TextPlusEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
            anchorEl:null,
            text:props.text
        };
    }

    showMenu(event){
        if (this.props.editable) {
            this.setState({ showmenu:true, text:this.props.text, extraText:this.props.extraText, anchorEl:event.currentTarget });
        }
    }

    handleClose(savechanges, event) {
        if (savechanges && this.props.onChange){
            if (this.props.extraTextLabel) {
                this.props.onChange(this.state.text||null, this.state.extraText||null, this.props.paramA, this.props.paramB);
            } else {
                this.props.onChange(this.state.text||null, this.props.paramA, this.props.paramB);
            }
        }
        if (!savechanges) {
            this.setState({showmenu:false, text:this.props.text});
        } else {
            this.setState({showmenu:false});
        }
        event.stopPropagation();
    };

    onChange(prop, event) {
        const val = event.target.value;

        if ((prop=="text") && this.props.number && (/[0-9]*/.exec(val) != val)) {
            return;
        }

        if (/[^\n]*/.exec(val) == val) {
            const state = {};
            state[prop]=val;
            this.setState(state);
        }
    }

    addValue(value) {
        if (!this.state.text){
            this.setState({text:value});
        } else {
            this.setState({text:this.state.text+", "+value});
        }
    }

    render() {
        if (!this.props.editable && !this.props.text)
            return null;
        
        let r = <span>
            {(this.props.useButton && this.props.editable && this.props.label)?
                <span>
                    <Button color={this.props.color||"primary"} variant={this.props.variant} size={this.props.size} onClick={this.showMenu.bind(this)}>
                        {this.props.label||this.props.noTextLabel}
                    </Button> {this.props.text||this.props.noTextLabel}
                </span>
            :
                <span className={this.props.editable?"hover-bg-contrast":""} onClick={this.showMenu.bind(this)}>{this.props.label?<b>{this.props.label} </b>:null}{this.props.text}</span>
            }
            {this.props.children}
            <Dialog
                open={this.state.showmenu}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <div className="flex items-center">
                        <TextField
                            multiline
                            value={this.state.text||""}
                            onChange={this.onChange.bind(this,"text")}
                            margin="normal"
                            fullWidth
                            autoFocus
                        />
                        {this.props.values?
                            <PickVal
                                values={this.props.values}
                                onClick={this.addValue.bind(this)}
                            >
                                <ButtonBase>
                                    <i className="hoverhighlight titlecolor f2 mb2 fas fa-caret-down pa1"/>
                                </ButtonBase>
                            </PickVal>
                        :null}
                    </div>
                    {this.props.extraTextLabel?<div>
                        <TextField
                            multiline
                            value={this.state.extraText||""}
                            onChange={this.onChange.bind(this,"extraText")}
                            margin="normal"
                            helperText={this.props.extraTextLabel}
                            fullWidth
                        />
                    </div>:null}
                    {this.props.info?<div className="hk-well">{this.props.info}</div>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class AskYesNo extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const {open, text, children,title} = this.props;

        if (!open) {
            return null;
        }
        return <Dialog
            open
            maxWidth="sm"
        >
            {title?<DialogTitle onClose={this.onClose.bind(this,false)}>{title}</DialogTitle>:null}
            <DialogContent>
                <div className="f3">{text}</div>
                {children}
            </DialogContent>
            <DialogActions classes={{root:"justify-center"}}>
                <Button onClick={this.onClose.bind(this, true)} color="primary">
                    Yes
                </Button>
                <Button onClick={this.onClose.bind(this, false)} color="primary">
                    No
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onClose(yes) {
        this.props.onClose(yes);
    }
}

class TextLabelEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onChangeVal(val) {
        if (this.props.number) {
            val = Number(val);
            if (!val && this.props.tristate) {
                val=true;
            }
            this.props.onChange(val);
        } else if (/[^\n]*/.exec(val) == val) {
            if (!val && this.props.tristate) {
                val=true;
            }
            this.props.onChange(val);
        }
    }

    toggle(){
        this.props.onChange(!this.props.text);
    }

    render() {
        const editable = this.props.editable;
        if (!editable && !this.props.text)
            return null;
        let inputclass;
        switch( this.props.size ) {
            case "sm":
                inputclass = "w3 ";
                break;
            case "md":
                inputclass = "w4 ";
                break;
            case "lg":
                inputclass = "w6 ";
                break;
            case "xl":
                inputclass = "w7 ";
                break;
            default:
                inputclass = "";
                break;
        }
        inputclass = inputclass + (this.props.inputclass||"");
        let text = this.props.text || "";
        if (text === true) {
            text = "";
        }

        let r = <span className={(this.props.size=="fill")?"flex db":""}>
            {(this.props.label && (!this.props.fullWidth || !editable))?<span className="notetext b mr1">{this.props.label}</span>:null}
            {(editable&&this.props.tristate)?<span className={this.props.text?"mh1 f2 fas fa-toggle-on":"mh1 f2 fas fa-toggle-off"} onClick={this.toggle.bind(this)}/>:null}
            {(editable&&!this.props.values&&(!this.props.tristate || this.props.text))?
                <TextVal
                    className={(this.props.size=="fill")?"flex-auto mr1":""}
                    classes={{root:inputclass}}
                    InputProps={{classes:{input:"textval"}}}
                    placeholder="value"
                    text={text}
                    label={this.props.fullWidth?this.props.label:null}
                    fullWidth={this.props.fullWidth||false}
                    onChange={this.onChangeVal.bind(this)}
                    isDecimal={this.props.number}
                />
            :(editable&&this.props.values&&(!this.props.tristate || this.props.text))?
                <SelectVal label={this.props.fullWidth && this.props.label} fullWidth={this.props.fullWidth||false} value={text} onClick={this.onChangeVal.bind(this)} values={this.props.values}/>
            :<span>{text}</span>}
            {(!editable||!this.props.fullWidth)?<span> {this.props.children}</span>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className={this.props.noMargin?"":"mb1"}>{r}</div>
        }
    }
}

class PickVal extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            anchorEl:null,
            showpick:false, 
        };
    }

    openPick(event){
        if (this.props.disablepick)
            return;
        event.stopPropagation();
        this.setState({showpick:true, anchorEl:event.target});
    }

    closePick(e){
        if (e) {
            e.stopPropagation();
        }
        this.setState({showpick:false});
    }

    handleClick(v, event) {
        if (this.props.onClick){
            if (this.props.isPossibleNum) {
                if (!isNaN(v)) {
                    v=Number(v);
                }
            } else if (this.props.isNum) {
                v = Number(v);
            }
            this.props.onClick(v, this.props.paramA, this.props.paramB);
        }
        this.closePick();
        event.stopPropagation();
    };

    render() {
        return <span onClick={(!this.props.noEdit&&!this.props.disablepick)?function(evt){evt.stopPropagation(); evt.preventDefault()}:null}>
            {this.props.label?
                <Button className={this.props.className} onClick={!this.props.noEdit?this.openPick.bind(this):null} color={this.props.color||"primary"} variant={this.props.variant} size={this.props.size}>{this.props.label}</Button>
            :
                <span onClick={!this.props.noEdit?this.openPick.bind(this):null}>{this.props.children}</span>
            }
            {this.state.showpick?<Menu
                anchorEl={this.state.anchorEl}
                open={this.state.showpick}
                onClose={this.closePick.bind(this)}
                PaperProps={this.props.short?{style:{maxHeight:"256px"}}:{}}
            >
                {this.getList()}
            </Menu>:null}
        </span>
    }

    getList(){
        const values=this.props.values;
        let i;
        let ret=[];
        const isArray = Array.isArray(values);
        const found={};

        if (this.props.includeVal) {
            ret.push(<MenuItem 
                className="mv1"
                key={this.props.includeVal}
                value={this.props.includeVal}
                onClick={this.handleClick.bind(this,this.props.includeVal)} 
                style={{paddingTop:"5px", paddingBottom:"5px"}}
            >
                {this.props.includeVal}
            </MenuItem>);
        }
    
        if (this.props.includeAll) {
            ret.push(<MenuItem 
                className="mv1"
                key="all"
                value="all"
                onClick={this.handleClick.bind(this,"all")} 
                style={{paddingTop:"5px", paddingBottom:"5px"}}
            >
                all
            </MenuItem>);
        }
    
        for (i in values){
            const v= values[i];
            let name, value;

            if (typeof v === 'object') {
                name=v.name;
                value=v.value;
            } else {
                if (isArray) {
                    name=value=v;
                } else {
                    name = v;
                    value = i;
                }
            }
        
            if (!found[name]) {
                ret.push(<MenuItem 
                    key={name}
                    onClick={this.handleClick.bind(this,value)} 
                    value={value}
                    selected={value==this.props.value}
                    style={{paddingTop:"5px", paddingBottom:"5px"}}
                >
                    {name}
                </MenuItem>);
                found[name]=true;
            }
        }
        return ret;
    }
    
}

class DialogSelectVal extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({selected:this.props.selected||[]});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            this.props.onClose(this.state.selected)
        } else {
            this.props.onClose();
        }
    };

    onChange(value) {
        const selected = (this.state.selected||[]).concat([]);
        const pos = selected.indexOf(value);
        if (pos >= 0) {
            selected.splice(pos,1);
        } else {
            selected.push(value);
        }
        this.setState({selected});
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const values=this.props.values;
        const selected = (this.state.selected||[]);
        let i;
        let options=[];
        const isArray = Array.isArray(values);
        const found={};

        for (i in values){
            const v= values[i];
            let name, value;

            if (typeof v === 'object') {
                name=v.name;
                value=v.value;
            } else {
                if (isArray) {
                    name=value=v;
                } else {
                    name = v;
                    value = i;
                }
            }

            if (!found[value]) {
                options.push(<div key={value}>
                    <CheckVal value={selected.includes(value)} label={name} onChange={this.onChange.bind(this, value)}/>
                </div>);
            }
        }
        
        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
            <DialogContent>
                {options}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    OK
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;

    }
}

class SelectVal extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    handleClick(event) {
        let v = event.target.value;

        if (this.props.onClick){
            if (this.props.isPossibleNum) {
                if (!isNaN(v)) {
                    v=Number(v);
                }
            } else if (this.props.isNum) {
                v = Number(v);
            }
            this.props.onClick(v, this.props.paramA, this.props.paramB);
        }
        event.stopPropagation();
    };

    render() {
        let {list, value} = this.getList();
        if (this.props.basic) {
            return <Select
                value={value}
                onChange={this.handleClick.bind(this)}
                MenuProps={this.props.MenuProps}
                classes={{select:this.props.selectClass||(!this.props.titleText?"pt0 notetext f4 mt--1":"pt0 stdcontent mt--1")}}
            >
                {list}
            </Select>
        }
        
        return <FormControl fullWidth={this.props.fullWidth} margin={this.props.margin} className={this.props.className} disabled={this.props.disabled||false} variant={this.props.variant||null} size={this.props.size}>
            {this.props.label?<InputLabel htmlFor={"sel"+this.state.idkey}>{this.props.label}</InputLabel>:null}
            <Select
                id={"sel"+this.state.idkey}
                value={value}
                onChange={this.handleClick.bind(this)}
                MenuProps={this.props.MenuProps}
                classes={{select:this.props.selectClass||(!this.props.titleText?"pt0 notetext f4 mt--1":"pt0 stdcontent mt--1")}}
            >
                {list}
            </Select>
            {this.props.helperText?<FormHelperText>{this.props.helperText}</FormHelperText>:null}
        </FormControl>;
    }

    getList(){
        const values=this.props.values;
        const unavailable = this.props.unavailable||[];
        let i;
        let ret=[];
        const isArray = Array.isArray(values);
        const found={};
        let selVal = this.props.value;

        if (this.props.includeVal) {
            ret.push(<MenuItem 
                className={this.props.menuClassName||"mv1"}
                key={this.props.includeVal}
                value={this.props.includeVal}
            >
                {this.props.includeVal}
            </MenuItem>);
        }
    
        if (this.props.includeAll) {
            ret.push(<MenuItem 
                className={this.props.menuClassName||"mv1"}
                key="all"
                value="all"
            >
                all
            </MenuItem>);
        }
    
        for (i in values){
            const v= values[i];
            let name, value;

            if ((typeof v === 'object') && (v.value != null)) {
                name=v.name;
                value=v.value;
            } else {
                if (isArray) {
                    name=value=v;
                } else {
                    name = v;
                    value = i;
                }
            }

            if ((name==this.props.value) || (this.props.isNum && Number(value)==Number(this.props.value))) {
                selVal = value;
            }
        
            if (!found[value]) {
                ret.push(<MenuItem 
                    className={(this.props.menuClassName||"mv1")+((unavailable.includes(value)&&(value!=selVal))?" gray-60":"")}
                    key={value}
                    value={value}
                >
                    {this.props.prefix}{name}{this.props.suffix}
                </MenuItem>);
                found[value]=true;
            }
        }
        return {list:ret, value:selVal};
    }
    
}


class SelectTextVal extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            key:"default",
            selValue:(props.text||"").toString()
        };
    }

    onChange(event,value, reason) {
        if (reason == "reset") {
            return;
        }
        if (/[^\n]*/.exec(value) == value) {
            this.props.onChange(value);
        }
    }

    onChangeValue(event,value, reason) {
        this.setState({selValue:value});
        this.props.onChange(value);
    }

    render() {
        const t=this;
        return <Autocomplete
            className={this.props.className}
            classes={{input:"f4"}}
            freeSolo
            autoComplete={true}
            blurOnSelect={true}
            fullWidth={this.props.fullWidth}
            value={this.state.selValue}
            inputValue={(this.props.text||"").toString()}
            options={this.props.values}
            onInputChange={this.onChange.bind(this)}
            onChange={this.onChangeValue.bind(this)}
            renderInput={(params) => (
                <TextField {...params} label={t.props.label} helperText={t.props.helperText}/>
            )}
        />
    }
}

class SelectMultiTextVal extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onChange(event,value) {
        if (!value || !value.length) {
            value=null;
        } else if (this.props.csv) {
            value=value.join(", ");
        }
        this.props.onChange(value);
    }

    render() {
        const t=this;
        let value = this.props.value;
        if (this.props.csv && value) {
            value = value.split(",").map(function (a){return a.trim()});
        }
        return <Autocomplete
            multiple
            freeSolo={this.props.freeSolo}
            className={this.props.className}
            classes={{input:"f4"}}
            autoComplete={true}
            blurOnSelect={true}
            value={value||[]}
            options={this.props.values}
            onChange={this.onChange.bind(this)}
            fullWidth={this.props.fullWidth}
            renderInput={(params) => (
                <TextField {...params} label={t.props.label} helperText={t.props.helperText}/>
            )}
        />
    }
}

class SelectMultiVal extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onChange(event,value) {
        if (!value || !value.length) {
            value=null;
        }
        this.props.onChange(value);
    }

    render() {
        const t=this;
        const {value, values, label, helperText, fullWidth, getOptionLabel,placeholder} = this.props;
        let maxLen=0;
        let minWidth;
        for (let i in values) {
            maxLen = Math.max(maxLen, values[i]?.length||0);
        }
        if (maxLen) {
            minWidth=(((maxLen>20)?20:maxLen)*0.5+1)+"em";
        }
        return <Autocomplete
            multiple
            style={fullWidth?null:{minWidth}}
            className={this.props.className}
            autoComplete
            fullWidth={fullWidth||false}
            value={value||[]}
            options={values}
            onChange={this.onChange.bind(this)}
            getOptionLabel={getOptionLabel}
            ChipProps={{size:"small"}}
            renderInput={(params) => (
                <TextField {...params} label={label} helperText={helperText} placeholder={placeholder}/>
            )}
        />
    }
}

class CheckVal extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

    toggleSelected() {
        this.props.onChange(!this.props.value)
    }

    render() {
        return <FormControlLabel
            classes={{root:this.props.className||"mr0",label:this.props.labelClass||null}}
            control={<Checkbox size="small" checked={!!this.props.value} onChange={this.toggleSelected.bind(this)}/>}
            label={this.props.label}
        />;
    }
}

class TextVal extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {text:props.text===0?"0":(props.text||"")};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.text != this.props.text) {
            const newtext = this.props.text===0?"0":(this.props.text||"");
            if (newtext != this.state.text) {
                this.setState({text:newtext});
            }
        }
    }

    onChange(event) {
        let value = event.target.value
        if (this.props.filter && value.match(this.props.filter)) {
            return;
        }
        if (this.props.multiline) {
            // value good
        } else if (this.props.isSignedNum) {
            if (event.target.value=="-") {
                value=0;
            } else if (/[-\+]?[\d]*/.exec(event.target.value) != event.target.value) {
                return;
            } 
            value=Number(value);
        } else if (this.props.isDecimal) {
            if (/\+?\d*\.?\d*/.exec(event.target.value) != event.target.value) {
                return;
            }
            value=Number(value);
        } else if (this.props.isNum) {
            if (/[\+]?[\d]*/.exec(event.target.value) != event.target.value) {
                return;
            }
            value=Number(value);
        } else if (/[^\n]*/.exec(event.target.value) != event.target.value) {
            return;
        }
        this.setState({text:event.target.value});
        this.props.onChange(value);
    }

    render() {
        
        return <TextField
            value={this.state.text}
            onChange={this.onChange.bind(this)}
            label={this.props.label}
            helperText={this.props.helperText}
            margin={this.props.margin}
            placeholder={this.props.placeholder}
            fullWidth={this.props.fullWidth}
            className={this.props.className}
            classes={this.props.classes}
            inputProps={this.props.inputProps}
            InputProps={this.props.InputProps}
            multiline={this.props.multiline}
            rows={this.props.rows}
            rowsMax={this.props.rowsMax}
            variant={this.props.variant}
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
        />;
    }
}

class DateVal extends React.Component {
    constructor(props) {
        super(props);
    }

    onChange(event) {
        let value = event.target.value
        this.props.onChange(value);
    }

    render() {
        
        return <TextField
            value={this.props.date}
            onChange={this.onChange.bind(this)}
            type="date"
            label={this.props.label}
            helperText={this.props.helperText}
            margin={this.props.margin}
            placeholder={this.props.placeholder}
            fullWidth={this.props.fullWidth}
            className={this.props.className}
            classes={this.props.classes}
            inputProps={this.props.inputProps}
            InputProps={this.props.InputProps}
            multiline={this.props.multiline}
            rows={this.props.rows}
            rowsMax={this.props.rowsMax}
            variant={this.props.variant}
            disabled={this.props.disabled}
        />;
    }
}

function getDateStrFromDate(date) {
    
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate());

    function pad(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }
  
}

class PriceVal extends React.Component {
    constructor(props) {
        super(props);
        this.state= {textPrice:props.price||"0"};
        this.onChangeFn = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.price != this.props.price) {
            if ((this.props.price||0) != Number(this.state.textPrice)) {
                this.setState({textPrice:this.props.price||"0"});
            }
        }
    }

    onChange(event) {
        const val = event.target.value;
        if (isNaN(val) && (val!=".") && (val!="")) {
            return;
        }
        const num = ((val==".")||(val==""))?0:Number(val);
        if ((num < 0) || (num != Number(num.toFixed(2)))) {
            return;
        }
        this.setState({textPrice:val});
        this.props.onChange(num);
    }

    render() {
        return <TextField
            className={this.props.className}
            value={this.state.textPrice}
            onChange={this.onChangeFn}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            helperText={this.props.label}
        />    
    }
}

class TextBasicEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            text:props.text
        };
    }

    handleClose(savechanges, event) {
        if (this.props.isNum) {
            this.props.onChange(savechanges?Number(this.state.text):null);
        } else {
            this.props.onChange(savechanges?this.state.text||"":null);
        }
        event.stopPropagation();
    };

    onChange(event) {
        if (this.props.filter && (event.target.value||"").match(this.props.filter)) {
            return;
        }

        if (this.props.isNum) {
            if (/[\d]*/.exec(event.target.value) == event.target.value) {
                this.setState({text:Number(event.target.value)});
            }
        } else if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({text:event.target.value});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.show != prevProps.show) {
            this.setState({text:this.props.text});
        }
    }

    render() {
        if (!this.props.show)
            return null;
        
        return <Dialog
            open={this.props.show||false}
            maxWidth="xs"
            fullWidth

        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
            <DialogContent>
                <div className="flex items-end">
                    <TextField
                        multiline
                        value={this.state.text||""}
                        onChange={this.onChange.bind(this)}
                        margin="normal"
                        fullWidth
                        autoFocus
                    />
                </div>
                {(this.props.info||this.props.children)?<div className="hk-well">{this.props.info}{this.props.children}</div>:null}
            </DialogContent>
            <DialogActions>
                <Button disabled={(!this.state.text || this.state.text == "") && !this.props.allowNull} onClick={this.handleClose.bind(this, true)} color="primary">
                    OK
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class ConfirmDialog extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges, event) {
        this.props.onClose(savechanges);
        event.stopPropagation();
    };

    componentDidUpdate(prevProps) {
        if ((this.props.show != prevProps.show) && this.props.show) {
            this.setState({confirm:null});
        }
    }

    render() {
        if (!this.props.show)
            return null;
        
        return <Dialog
            open={this.props.show}
            maxWidth="sm"
        >
            <DialogContent className="minh35">
                <div className="f3">{this.props.description}</div>
                {this.props.extraDescription?<div className="hk-well mt2">{this.props.extraDescription}</div>:null}
                {this.props.confirm?<div>
                    <div className="mt2">Type <b>{this.props.confirm}</b> to confirm this delete operation.</div>
                    <TextVal text={this.state.confirm||""} onChange={this.setConfirm.bind(this)} fullWidth/>
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button disabled={this.props.confirm && (this.props.confirm != this.state.confirm)} onClick={this.handleClose.bind(this, true)} color="primary">
                    OK
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    setConfirm(confirm) {
        this.setState({confirm});
    }
}

class DeleteWithConfirm extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

    render() {
        return <span>
            {this.props.useMenu?
                <MenuItem onClick={this.onDelete.bind(this)}>{this.props.altText}</MenuItem>
            :this.props.useButton?
                <Button className={this.props.className} disabled={this.props.disabled||false} onClick={this.onDelete.bind(this)} color={this.props.color||"primary"} variant={this.props.variant} size={this.props.size}>delete</Button>
            :
                <span className={this.props.className+" fas fa-trash "+(this.props.noHighlight?"":"hoverhighlight")} onClick={this.onDelete.bind(this)}/>}
            <ConfirmDialog show={this.state.open} description={"Are you sure that you want to delete"+(this.props.name?(" "+this.props.name+"?"):"?")} onClose={this.closeConfirm.bind(this)} confirm={this.props.confirm && "Delete"} extraDescription={this.props.extraDescription}/>
        </span>
    }

    onDelete(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({open:true})
    }

    closeConfirm(doDelete) {
        if (doDelete) {
            this.props.onClick();
        }
        this.setState({open:false})
    }

}

class DeleteEntry extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

    render() {
        const entry = this.props.entry;
        if (!entry || !entry.edited || campaign.isSharedCampaign()) {
            return null;
        }
        return <DeleteWithConfirm 
            className={this.props.className} 
            onClick={this.onDelete.bind(this)} 
            useButton 
            name={entry.displayName}
            extraDescription="Your edited version in My Creations will be permanently deleted."
        />
    }

    onDelete(e) {
        campaign.deleteCampaignContent(this.props.type, this.props.entry.name);
        this.props.onClose();
    }
}

class NumberAdjust extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            num:0,
            showPopup:props.open||false,
            positive:(typeof props.positive == "boolean")?props.positive:true,
            direct:false,
            selected:false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open != prevProps.open) {
            this.setState({showPopup:this.props.open||false, positive:(typeof this.props.positive == "boolean")?this.props.positive:true, direct:false, selected:false});
        }
    }

    handleClose(savechanges,e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (savechanges) {
            let value = Number(this.props.value);

            if (Number.isNaN(value)) {
                value = 0;
            }
            let adjust  = this.state.direct?(this.state.num-this.props.value):(this.state.num*(this.state.positive?1:-1))
            value = value+adjust;
            if (this.props.noneg && value < 0)  {
                value=1;
            }
            if (this.props.onChange) {
                this.props.onChange(value, adjust, this.props.paramA, this.props.paramB);
            }
            if (this.props.onClose) {
                this.props.onClose(value, adjust, this.props.paramA, this.props.paramB);
            }
        } else {
            if (this.props.onClose) {
                this.props.onClose();
            }
        }
        this.setState({showPopup:false, num:0});
    };

    showPopup(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.readonly) {
            return;
        }
        this.setState({showPopup:true, positive:(typeof this.props.positive == "boolean")?this.props.positive:true, direct:false, selected:false});
    }

    saveRef(ref) {
        this.anchorEl = ref;
    }

    render() {
        const {positive, direct, num,selected} = this.state;
        let value = Number(this.props.value);

        if (Number.isNaN(value)) {
            value = 0;
        }
        const buttonClass = "tc minw3 f1 hoverhighlight br3 pv2";
        return <span>
            {this.props.noShowValue?null:
                this.props.useMenu?
                    <MenuItem onClick={this.showPopup.bind(this)}><span ref={this.saveRef.bind(this)}>{this.props.altText}</span></MenuItem>
                : this.props.useDiv?
                    <div className={((this.props.readonly||this.props.altText)?"":"hover-bg-contrast ")+(this.props.className||"")} ref={this.saveRef.bind(this)} onClick={this.showPopup.bind(this)}>{this.props.altText || (value?value.toLocaleString():"0")}{this.props.children}</div>
                :
                    <span className={((this.props.readonly||this.props.altText)?"":"hover-bg-contrast ")+(this.props.className||"")} ref={this.saveRef.bind(this)} onClick={this.showPopup.bind(this)}>{this.props.altText || (value?value.toLocaleString():"0")}{this.props.children}</span>
            }
            <Popover
                open={this.state.showPopup}
                anchorEl={this.props.anchorEl || this.anchorEl}
                onClose={this.handleClose.bind(this, false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClick={ignoreEvents} onKeyPress={ignoreEvents} onKeyUp={ignoreEvents}
            >
                <div className="defaultbackground titlecolortext" tabIndex="1" onKeyDown={this.onKeyPress.bind(this)}>
                    <div className="flex f1 justify-end pa1 items-center">
                        <div className={(direct?"":(positive?"greenbackground ":"redbackground "))+"ba titleborder br2 pa1 flex-auto tr"}>
                            <ButtonBase onClick={this.clickDirect.bind(this)}>
                                {!direct?<span>
                                    {value||""}
                                    {(positive?" + ":" - ")}
                                </span>:null}
                                <span className={selected?"specialBackgroundColor pa1":""}>{num||"0"}</span>
                            </ButtonBase>
                        </div>
                        <span className="fas fa-backspace pa1" onClick={this.onBackspace.bind(this)}/>
                    </div>
                    <table>
                        <tbody>
                            <tr> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 7)}><ButtonBase>7</ButtonBase></td> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 8)}><ButtonBase>8</ButtonBase></td> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 9)}><ButtonBase>9</ButtonBase></td> 
                            </tr>
                            <tr> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 4)}><ButtonBase>4</ButtonBase></td> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 5)}><ButtonBase>5</ButtonBase></td> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 6)}><ButtonBase>6</ButtonBase></td> 
                            </tr>
                            <tr> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 1)}><ButtonBase>1</ButtonBase></td> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 2)}><ButtonBase>2</ButtonBase></td> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 3)}><ButtonBase>3</ButtonBase></td> 
                            </tr>
                            <tr> 
                                <td className={buttonClass} onClick={this.onToggleSign.bind(this)}><ButtonBase>{direct?null:(positive?"-":"+")}</ButtonBase></td> 
                                <td className={buttonClass} onClick={this.onClickNum.bind(this, 0)}><ButtonBase>0</ButtonBase></td>
                                <td className={buttonClass} onClick={this.handleClose.bind(this, true)}><ButtonBase autoFocus>=</ButtonBase></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Popover>
        </span>;
    }

    clickDirect(e) {
        const {positive, direct, num} = this.state;

        if (!direct) {
            let value = Number(this.props.value);
    
            if (Number.isNaN(value)) {
                value = 0;
            }
            value = value+(num*(positive?1:-1));
            if (this.props.noneg && value < 0)  {
                value=1;
            }
    
            this.setState({direct:true, num:value, selected:true})
        } else {
            this.setState({selected:true})
        }
    }

    onKeyPress(e) {
        const key = e.key;
        const charCode = e.key.charCodeAt(0);

        e.preventDefault();
        e.stopPropagation();

        if (charCode >= 48 /*0*/ && charCode <= 57) {
            this.onClickNum(charCode - 48, e);
            return;
        }

        switch (key) {
            case "+":
                this.setState({positive:true});
                return;
            case "-":
                this.setState({positive:false});
                return;
            case "Backspace":
                this.onBackspace(e);
                return;
            case "Escape":
                this.handleClose(false);
                return;
            case "Enter":
                this.handleClose(true);
                return;
        }
    }

    onChangeNum(event) {
        let newNum = Number(event.target.value);
        if (Number.isNaN(newNum)) {
            return;
        }

        this.setState({num:newNum});
    }

    onClickNum(n, e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.selected) {
            this.setState({num:n, selected:false});
        } else {
            this.setState({num:Number(this.state.num)*10+n});
        }
    }

    onToggleSign(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({positive:!this.state.positive});
    }

    onBackspace(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({num:Math.trunc(Number(this.state.num)/10)});
    }
}

class NumberAdjustPlusMinus extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            num:0,
        };
    }

    render() {
        let value = Number(this.props.value);

        if (Number.isNaN(value)) {
            value = 0;
        }
        
        return <span className="ba ph1 f5 br2 titleborder nowrap">
            {this.props.readonly?null:<span className="fas fa-minus titlecolor cursor-context-menu hoverhighlight" onClick={this.onChangeNum.bind(this, value-1, -1)}/>}
            <NumberAdjust value={value||0} readonly={this.props.readonly} className="nowrap notetext minw1 ph1 tc" onChange={this.onChangeNum.bind(this)}>{this.props.children}</NumberAdjust>
            {this.props.readonly?null:<span className="fas fa-plus titlecolor cursor-context-menu hoverhighlight" onClick={this.onChangeNum.bind(this, value+1, 1)}/>}
        </span>;
    }

    onChangeNum(val, adjust) {
        let newNum = Number(val);
        if (Number.isNaN(newNum)) {
            return;
        }

        this.props.onChange(newNum, adjust, this.props.paramA, this.props.paramB);
    }
}

class MaxNumberAdjust extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    render() {
        const max = this.props.max;
        let value = Number(this.props.value);
        let inside = null;
        const {useNumbers,inverse,readonly,className,label,children}=this.props;
        const useNumberAdjust = useNumbers || (max>(this.props.maxPip||8));

        if (Number.isNaN(value)) {
            value = 0;
        }

        if (useNumberAdjust) {
            inside = <span className={className||"pl1"}><NumberAdjustPlusMinus readonly={readonly} value={value} onChange={this.onAdjustVal.bind(this)}/></span>;
        } else {
            const list=[];

            if (inverse) {
                for (let i=max-1; i>=0; i--) {
                    list.push(<span 
                        key={"s"+i} 
                        className={(readonly?"":"hoverhighlight ")+(i<(max-value)?"far fa-square pa--2 f3":"far fa-check-square pa--2 f3")}
                        onClick={this.changeValue.bind(this, (i<(max-value))?(value+1):(value-1))}
                        style={{display:"inline"}}
                    />);
                }
            } else {
                for (let i=0; i< max; i++) {
                    list.push(<span 
                        key={"s"+i} 
                        className={(readonly?"":"hoverhighlight ")+(i<(max-value)?"far fa-times-circle pa--2 f3":"far fa-circle pa--2 f3")}
                        onClick={this.changeValue.bind(this, (i<(max-value))?(value+1):(value-1))}
                        style={{display:"inline"}}
                    />);
                }
            }
            inside = <span className="nudge-down--1">{list}</span>;
        }

        const element = <span>
            <span>
                {label?<b>{label} </b>:null}{inside}
            </span>
            {children}
        </span>;

        if (label) {
            return <div className="mb1">{element}</div>;
        } else {
            return <span>{element}</span>;
        }
    }

    clickVal(event) {
        if (!this.props.readonly){
            return;
        }
        this.setState({showValAdjust:true, showValAnchorEl:event.target});
    }

    onAdjustVal(value, adjust) {
        if (adjust) {
            value = Math.max(0, value);
            value = Math.min(this.props.max, value);
            this.props.onAdjustValue(value);
        }
        this.setState({showValAdjust:false});
    }

    changeValue(value) {
        if (this.props.readonly){
            return;
        }
        this.props.onAdjustValue(value);
    }

}

class PlainTextClick extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        if (!this.props.editable && !this.props.text)
            return null;
        
        let r = <span><span className={(this.props.editable||this.props.alwaysHighlight)?"hover-bg-contrast":""} onClick={this.props.onClick}>{this.props.label?<b>{this.props.label} </b>:null}{this.props.text}</span>
            {this.props.children}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class ClipboardCopy extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.id = "copykey"+Math.random();
    }

    render() {
        return <Tooltip title="copy to clipboard">
            <div className="ba titleborder pa1 mv1 hoverhighlight word-wrap" onClick={this.copyKey.bind(this)}>
                {this.props.children}
                <div className="fas fa-clipboard f3 pa1"/>
                <input id={this.id} readOnly={true} value={this.props.text} style={{width:0.1}} className="clearAll"/>
            </div>
        </Tooltip>
    }

    copyKey(event) {
        event.preventDefault();
        event.stopPropagation();

        const el = document.getElementById(this.id);
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand('copy');
        el.blur();
        snackMessage("Link copied to clipboard");
    }
}

class DisplayTable extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
           const columns = this.props.columns;
           const list = this.props.list;

           const blob = new Blob(
                [ getCSVFromObjList(columns,list) ],
                { type: 'text/csv' }
            );
            const link =URL.createObjectURL(blob);
            this.setState({link});
        }
    }

    componentWillUnmount() {
        const link = this.state.link;
        if (link) {
            URL.revokeObjectURL(link);
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const list = this.props.list||[];
        const columns = this.props.columns||[];
        const res = [];
        const header=[]

        for (let i of columns) {
            header.push(<th key={i}>{i.replace(/_/g," ")}</th>);
        }

        for (let i in list) {
            const r = list[i]
            const row = [];
            for (let c of columns){
                let d = r[c];
                if (d && d.replace) {
                    d = d.replace(/T\d\d:\d\d:\d\d.\d\d\dZ/,"");
                }
                row.push(<td key={c} className={(typeof d==="number")?"tr":"nowrap tc"}>{d}</td>);
            }
            res.push(<tr key={i}>{row}</tr>);
        }

        return <Dialog
            maxWidth="lg"
            fullWidth
            open
        >
            <DialogTitle onClose={this.props.onClose}>{this.props.title}</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    <table>
                        <tbody>
                            <tr>{header}</tr>
                            {res}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            <DialogActions>
                <a href={this.state.link} download={(this.props.description||"download")+".csv"}><Button color="primary">Download</Button></a>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

const dividerSize = 4;

class VDivider extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        let inside;
        const {capture, pRect, pos} = this.state;
        const {spacer,spacerSize} = this.props;
        if (capture) {
            const sections = this.props.getSections(pos);
            const list=[];
            for (let i in sections) {
                list.push(<div key={i} className="bg-white-30 ba b--black ma--2 pe-none br2" style={{width:sections[i]}}/>)
            }
            inside = <div 
                className="absolute flex items-stretch pe-none"
                style={{left:pRect.x, top:pRect.y, left:pRect.x, width:pRect.width, height:pRect.height }}
            >{list}</div>
        }

        return <div>
            {spacer=="left"?<span className="h-100 hoverhighlight br titleborder dib v-top" style={{width:spacerSize}} onClick={this.doExpand.bind(this)}><span className="fas fa-chevron-right mt2 titlecolor f3"/></span>:null}
            <span className="relative h-100 w0 dib"
                onMouseDown={this.onMouseDown.bind(this)} 
                onTouchStart={this.onTouchStart.bind(this)}
                onTouchMove={this.onTouchMove.bind(this)}
                onTouchEnd={this.releaseCapture.bind(this)}
                onTouchCancel={this.releaseCapture.bind(this)}
            >
                <div 
                    className="absolute z-5 cursor-ew-resize drag-v h-100" 
                    style={{width:dividerSize*2, left:-dividerSize}}
                />
                {this.state.capture?<Modal open 
                    disableAutoFocus
                    disableEnforceFocus
                    BackdropProps={{
                        onMouseUp:this.releaseCapture.bind(this),
                        onMouseOut:this.releaseCapture.bind(this),
                        onMouseMove:this.onMouseMove.bind(this),
                        className:"cursor-ew-resize"
                    }}
                >{inside}</Modal>:null}
            </span>
            {spacer=="right"?<span className="h-100 hoverhighlight bl titleborder dib v-top" style={{width:spacerSize}} onClick={this.doExpand.bind(this)}><span className="fas fa-chevron-left mt2 titlecolor f3"/></span>:null}
        </div>
    }

    doExpand() {
        const {defaultPercentage} = this.props;
        const pRect = this.props.parentRef.current.getBoundingClientRect();
        this.props.onSlide(pRect.width*defaultPercentage/100);
    }

    doCapture(pageX, target) {
        const elRect = target.getBoundingClientRect();
        const pRect = this.props.parentRef.current.getBoundingClientRect();
        let base = -pRect.x;
        base+=(elRect.left+dividerSize-pageX);
        const pos = pageX + base;

        this.setState({capture:true, base, pRect, pos, updatePos:false});
    }

    onMouseDown(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.button != 0) {
            return;
        }
        this.doCapture(evt.pageX, evt.target);
    }

    onTouchStart(evt) {
        if (!evt.touches || (evt.touches.length !=1)) {
            return;
        }
        this.doCapture(evt.touches[0].pageX, evt.target);
    }

    releaseCapture() {
        const {pos, updatePos} = this.state;
        if (updatePos && this.props.onSlide) {
            this.props.onSlide(pos);
        }
    
        this.setState({capture:false, updatePos:false});
    }

    onMouseMove(evt) {
        if (evt.buttons != 1) {
            this.releaseCapture();
            return;
        }
        const pos =evt.pageX +this.state.base;
        this.setState({pos, updatePos:true})
    }

    onTouchMove(evt) {
        if (!evt.touches || (evt.touches.length !=1) || !this.state.capture) {
            this.releaseCapture();
            return;
        }
        evt.preventDefault();
        evt.stopPropagation();
        const pos =evt.touches[0].pageX +this.state.base;
        this.setState({pos, updatePos:true})
    }
}

class HDivider extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        let inside;
        const {capture, pRect, pos} = this.state;
        const {spacer,spacerSize} = this.props;

        if (capture) {
            const sections = this.props.getSections(pos);
            const list=[];

            for (let i in sections) {
                list.push(<div key={i} className="bg-white-30 ba b--black ma--2 pe-none br2" style={{height:sections[i]}}/>)
            }
            inside = <div 
                className="absolute flex flex-column items-stretch pe-none"
                style={{left:pRect.x, top:pRect.y, left:pRect.x, width:pRect.width, height:pRect.height }}
            >{list}</div>
        }

        return <div>
            {spacer=="top"?<div className="hoverhighlight bb titleborder tc" style={{height:spacerSize}} onClick={this.doExpand.bind(this)}><span className="fas fa-chevron-down nudge-up--3 titlecolor f3"/></div>:null}
            <div className="relative w-100 h0"
                onMouseDown={this.onMouseDown.bind(this)} 
                onTouchStart={this.onTouchStart.bind(this)}
                onTouchMove={this.onTouchMove.bind(this)}
                onTouchEnd={this.releaseCapture.bind(this)}
                onTouchCancel={this.releaseCapture.bind(this)}
            >
                <div 
                    className="absolute z-5 cursor-ns-resize drag-h w-100" 
                    style={{height:dividerSize*2, top:-dividerSize}}
                />
                {this.state.capture?<Modal open 
                    disableAutoFocus
                    disableEnforceFocus
                    BackdropProps={{
                        onMouseUp:this.releaseCapture.bind(this),
                        onMouseOut:this.releaseCapture.bind(this),
                        onMouseMove:this.onMouseMove.bind(this),
                        className:"cursor-ns-resize"
                    }}
                >{inside}</Modal>:null}
            </div>
            {spacer=="bottom"?<div className="hoverhighlight bt titleborder tc" style={{height:spacerSize}} onClick={this.doExpand.bind(this)}><span className="fas fa-chevron-up nudge-up--3 titlecolor f3"/></div>:null}
        </div>
    }

    doExpand() {
        const {defaultPercentage} = this.props;
        const pRect = this.props.parentRef.current.getBoundingClientRect();
        this.props.onSlide(pRect.height*defaultPercentage/100);
    }

    doCapture(pageY, target) {
        const elRect = target.getBoundingClientRect();
        const pRect = this.props.parentRef.current.getBoundingClientRect();
        const base = -pRect.y+(elRect.top+dividerSize-pageY);
        const pos = pageY + base;

        this.setState({capture:true, base, pRect, pos, updatePos:false});
    }

    onMouseDown(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (evt.button != 0) {
            return;
        }
        this.doCapture(evt.pageY, evt.target);
    }

    onTouchStart(evt) {
        if (!evt.touches || (evt.touches.length !=1)) {
            return;
        }
        this.doCapture(evt.touches[0].pageY, evt.target);
    }

    releaseCapture() {
        const {pos, updatePos} = this.state;
        if (updatePos && this.props.onSlide) {
            this.props.onSlide(pos);
        }
    
        this.setState({capture:false, updatePos:false});
    }

    onMouseMove(evt) {
        if (evt.buttons != 1) {
            this.releaseCapture();
            return;
        }
        const pos =evt.pageY +this.state.base;
        this.setState({pos, updatePos:true})
    }

    onTouchMove(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (!evt.touches || (evt.touches.length !=1) || !this.state.capture) {
            this.releaseCapture();
            return;
        }
        const pos =evt.touches[0].pageY +this.state.base;
        this.setState({pos, updatePos:true})
    }
}

class TabBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidMount() {
        const {saveRef} = this.props;
        if (saveRef) {
            saveRef(this);
        }
    }

    render() {
        const {tabs,currentTab} = this.props;
        const selectedTab = "ph2 pv1 titlecolor defaultbackground br--top br2 nudge-down--2";
        const unselectedTab = "ph2 pv1 hoverhighlight"

        let curTab;
        for (let i in tabs) {
            const ctab = tabs[i];
            if (ctab.id == currentTab) {
                curTab = ctab;
            } 
            if (!curTab) {
                curTab = ctab;
            }
        }

        const tabList =[];
        const tabBody = [];
        for (let i in tabs) {
            const ctab = tabs[i];
            const selTab = (curTab==ctab);
            tabList.push(<div key={ctab.id} className={selTab?selectedTab:unselectedTab} onClick={this.changeTab.bind(this,ctab)}>{ctab.label}</div>);
            tabBody.push(<div key={ctab.id} className={selTab?"flex-auto h1 overflow-hidden":"h0 overflow-hidden"}>
                {ctab.body}
            </div>);
        }

        return <div className="w-100 flex flex-column h-100 overflow-hidden">
            <div className="flex flex-wrap titlebackground titlecolorcontrast tourTabList">
                {tabList}
            </div>
            {tabBody}
            {this.props.children}
        </div>
    }

    gotoTab(tabName) {
        const {tabs,currentTab} = this.props;
        let setTab;
        for (let i in tabs) {
            const ctab = tabs[i];
            if ((ctab.label == tabName) && (currentTab != ctab.id)) {
                setTab = ctab;
            }
        }
        if (setTab) {
            this.changeTab(setTab);
        }

    }

    changeTab(ctab) {
        this.props.onChangeTab(ctab.id);
    }

}

const videoScale = 16/9;
class InlineVideoBase extends React.Component {
    constructor(props) {
        super(props);
    }

	render() {
        const {size} = this.props;
        let {height, width} = size;
        if (width > 600) {
            width=600;
        }
        height=width/videoScale;
        return <div>
            <div className="bg-purple">{width}x{height}</div>
        </div>
    }
}
const InlineVideo = sizeMe({monitorWidth:true})(InlineVideoBase);

function getCSVFromObjList(columns, list) {
    const res=[];
    const headers=[];
    for (let x in columns){
        headers.push(columns[x]);
    }
    res.push(headers.join(","));

    for (let i in list) {
        const l=list[i];
        const row=[];
        for (let x in columns){
            const c = columns[x];
            let d = l[c];
            if (c == "date") {
                d = d.substr(0,10);
            }
            row.push(d);
        }
        res.push(row.join(","));
    }
    return res.join("\n");
}

function getAnchorPos(evt, shift) {
    let x;
    let y;

    if (evt.changedTouches && evt.changedTouches.length > 0) {
        x = evt.changedTouches[0].clientX;
        y = evt.changedTouches[0].clientY;
    } else {
        x=evt.clientX;
        y=evt.clientY;
    }

    if (shift) {
        x=x-50;
        y=y-30;
    }

    return {top:y, left:x};
}



function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function isMobile() {
    try {
        return !(navigator.userAgent.search(/(Android|webOS|iPhone|iPad|iPod)/i) <0);
    } catch (err) {
        return true;
    }
}

let bookMap = null;
let bookMapDetails = null;
globalDataListener.onChangeCampaignContent(function () {
    bookMap=null;
}, "books");

globalDataListener.onChangeCampaignContent(function () {
    bookMap=null;
}, "plannedencounters");

function getBookMapDetals() {
    getBookMap();
    return bookMapDetails;
}

function getBookMap() {
    if (bookMap) {
        return bookMap;
    }
    const {getChapterInfoFromFragment}=require('./book.jsx');
    bookMap = {};
    bookMapDetails={};
    const books = campaign.getAllBooks();
    const visible = campaign.getVisibleBooks();
    let bv;
    for (let i in books) {
        const book = books[i];
        bv = visible[book.name.toLowerCase()];

        for (let c in book.chapters) {
            const chapter = book.chapters[c];
            let lastSection=-1;
            c=Number(c);
            addBookMap(book, chapter.contentId,c,-1,-1);
            addContentList(book, chapter.contentList,c,-1,-1);

            for (let s in chapter.sections) {
                s=Number(s);
                const section = chapter.sections[s];
                let lastSubsection=-1;
                if (section.name) {
                    lastSection=s;
                }

                addBookMap(book, section.contentId,c,lastSection,-1);
                addContentList(book, section.contentList,c,lastSection,-1);

                for (let ss in section.subsections) {
                    ss=Number(ss);
                    const subsection = section.subsections[ss];
                    if (subsection.name) {
                        lastSubsection=ss;
                    }
                    addBookMap(book, subsection.contentId,c,s,lastSubsection);
                    addContentList(book, subsection.contentList,c,s,lastSubsection);
                }    
            }
        }
    }


    const encounters = campaign.getPlannedEncounters();

    for (let i in encounters) {
        const e = encounters[i];
        const br = e.bookReference;

        if (br && br.book) {
            const book = campaign.getBookInfo(br.book);
            if (book) {
                bv = visible[book.name.toLowerCase()];
                if (bv) {
                    let chapter,section,subsection;
                    if (br) {
                        if (br.fragment) {
                            const chaptInfo = getChapterInfoFromFragment(br.book, br.fragment);
            
                            chapter=chaptInfo.chapter;
                            section=chaptInfo.section;
                            subsection=chaptInfo.subsection;
                        } else {
                            chapter=br.chapter;
                            section=br.section;
                            subsection=br.subsection;
                        }
                    }

                    const combatants = e.combatants;
                    for (let i in combatants) {
                        const c = combatants[i];
                        if (c.ctype == "monster") {
                            const mon = campaign.getMonsterInfo(c.type);
                            if (mon && mon.source == book?.source) {
                                addBookMap(book, mon.name, chapter, section, subsection,true);
                            }
                        }
                    }
                }
            }
        }
    }


    return bookMap;

    function addContentList(book, cl, chapter,section,subsection) {
        if (!cl) {
            return;
        }
        for (let i in cl) {
            const ci = cl[i];
            addBookMap(book, ci.contentId, chapter,section,subsection);
        }
    }
    
    function addBookMap(book, id, chapter,section,subsection, dedup) {
        if (!id) {
            return;
        }
        const key = stringFromId(id);
        if (!bookMap[key]) {
            bookMap[key] = [book.collectionDisplayName|| book.displayName];
        } else {
            bookMap[key].push(book.collectionDisplayName|| book.displayName);
        }
        if (bv) {
            const bi = {name:book.name, title:book.collectionDisplayName|| book.displayName, displayName:book.displayName, chapter,section,subsection};
            const bl = bookMapDetails[key];
            if (!bl) {
                bookMapDetails[key]=[bi];
            } else {
                let found;
                if (dedup) {
                    for (let x of bl) {
                        if (x.name == book.name) {
                            found=true;
                        }
                    }
                }
                if (!found) {
                    bookMapDetails[key].push(bi);
                }
            }
        }
    }
}

function stringFromId(id){
    if (typeof id == "string") {
        return id.toLowerCase();
    }
    const key= (id.className?"c:"+id.className.toLowerCase():"")+
           (id.subclassName?"sc:"+id.subclassName.toLowerCase():"")+
           (id.id?"i:"+id.id.toLowerCase():"");
    return key;
}

const defaultSourceFilter = {
    filterName:"Source",
    fieldName:"source",
    advancedOnly:true,
    sortFn:function(a,b) {
        if (a=="My Creations") {
            return -1;
        }
        if (b=="My Creations") {
            return 1;
        }
        return (a||"").toLowerCase().localeCompare((b||"").toLowerCase())
    },
    convertField(source, it) {
        const src = campaign.getSourceName(source);
        if (it.edited) {
            if (src) {
                return ["My Creations",src];
            } else {
                return "My Creations";
            }
        }
        return src;
    }
};

const defaultGamesystemFilter = {
    filterName:"Game System",
    fieldName:"gamesystem",
    advancedOnly:true,
    convertField(gamesystem, it) {
        return gamesystemOptions[gamesystem||"5e"];
    }
};

const defaultGamesystemAnyFilter = {
    filterName:"Game System",
    fieldName:"gamesystem",
    advancedOnly:true,
    convertField(gamesystem, it) {
        return gamesystemAnyOptions[gamesystem||"any"];
    }
};

function maxLen(text, len) {
    if (text && text.length > len) {
        return text.substr(0,len)+"...";
    }
    return text;
}

const defaultBookFilter = {
    filterName:"Books",
    fieldName:"bookref",
    advancedOnly:true,
    convertField(bookref, it) {
        const bm=getBookMap();
        const key = stringFromId(it.id||it.name);
        return bm[key]||["none"];
    }
}

const classBookFilter = {
    filterName:"Books",
    fieldName:"bookref",
    advancedOnly:true,
    convertField(bookref, it) {
        const bm=getBookMap();
        const id = {className:it.className};
        if (it.subclassName) {
            id.subclassName = it.subclassName;
        }
        const key = stringFromId(id);
        return bm[key]||["none"];
    }
}

const customTypeBookFilter = {
    filterName:"Books",
    fieldName:"bookref",
    advancedOnly:true,
    convertField(bookref, it) {
        const bm=getBookMap();
        const id = {name:it.name, id:it.id};
        const key = stringFromId(id);
        return bm[key]||["none"];
    }
}

const customTypeContentMapBookFilter = {
    filterName:"Books",
    fieldName:"bookref",
    advancedOnly:true,
    convertField(bookref, it) {
        const bm=getBookMap();
        const key = stringFromId(it.id);
        return bm[key]||["none"];
    }
}

const windowSize={
    innerHeight:window.innerHeight,
    innerWidth:window.innerWidth
}

window.addEventListener('resize', function (){
    windowSize.innerHeight = window.innerHeight,
    windowSize.innerWidth = window.innerWidth
});

function alwaysArray(v) {
    if (["string","number"].includes(typeof v)) {
        return [v];
    }
    return v||[];
}

function nullDefault(v, d) {
    if (v === null) {
        return null;
    }
    return v || d;
}

function getInitials(s) {
    if (!s) {
        return s;
    }
    const words = s.split(" ");
    let res = "";
    for (let w of words) {
        if (w.trim().length) {
            res = res+w[0];
        }
    }
    return res;
}

function truncateStr(s,l) {
    if (s && (s.length > l)) {
        const half = Math.trunc((l-3)/2);
        return s.substr(0,half)+"..."+s.substr(s.length-half);
    }
    return s;
}

function ignoreEvents(event) {
    event.stopPropagation();
}

export {
    TextPlusEdit, 
    PickVal, 
    TextBasicEdit, 
    NumberAdjust, 
    getAnchorPos, 
    ConfirmDialog,
    MaxNumberAdjust,
    PlainTextClick,
    escapeRegExp,
    TextLabelEdit,
    SelectVal,
    SelectTextVal,
    SelectMultiTextVal,
    SelectMultiVal,
    NumberAdjustPlusMinus,
    DeleteWithConfirm,
    InlineVideo,
    CheckVal,
    TextVal,
    ClipboardCopy,
    isMobile,
    defaultSourceFilter,
    defaultBookFilter,
    defaultGamesystemFilter,
    defaultGamesystemAnyFilter,
    classBookFilter,
    customTypeBookFilter,
    customTypeContentMapBookFilter,
    AskYesNo,
    PriceVal,
    DateVal,
    getDateStrFromDate,
    DisplayTable,
    getCSVFromObjList,
    DeleteEntry,
    DialogSelectVal,
    windowSize,
    VDivider,
    HDivider,
    TabBlock,
    maxLen,
    alwaysArray,
    nullDefault,
    getBookMapDetals,
    getBookMap,
    getInitials,
    truncateStr
};
const React = require('react');
const ReactDOM = require('react-dom');
const EventEmitter = require('events'); 
const {campaign,sleep, upgradeItem,globalDataListener,areSameDeep,areSameDeepInst,areSameDeepIgnore,areSameDeepDebug} = require('../lib/campaign.js');
const {getAttackActions} = require('../lib/monobj.js');
const {Chat} = require('../lib/chat.js');
const {displayMessage,snackMessage} = require('./notification.jsx');
const {findOverlap} = require('./mapengine.jsx');
const {doRoll,getDiceFromString,dicerandom, getRollLastActions,getRollSum} = require('../src/diceroller.jsx');
const {doSelectedSave} = require('./renderchat.jsx');
const {PickCondition,addConditionInfoToConditions} = require('./conditions.jsx');
import PopoutWindow from './react-popout.jsx';
import Popover from '@material-ui/core/Popover';
const {MapDialog, MapPicker} = require('./rendermaps.jsx');
import Paper from '@material-ui/core/Paper';
const {HoverMenu} = require( "./hovermenu.jsx");
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {getAnchorPos,maxLen,isMobile,DeleteWithConfirm, TextBasicEdit,CheckVal,NumberAdjust,AskYesNo} = require('./stdedit.jsx');
const {MonsterPicker} = require("./rendermonster.jsx");
const {getTokenInfo,addMonsters,MonsterTokenSelect,Combatant,findFreeSpot,fixupCombatants} = require('./encountermonsterlist.jsx');
const {MonsterPCDialog} = require("./monsterpc.jsx");
const {ShowHandout,PickHandout} = require('./handouts.jsx');
const {PinDialog,getPinMaps,getPinBooks,getPinEncounters,pinScaleValues,pinMarkerValues} = require('./pins.jsx');
const {imageCache} = require('./imagecache.jsx');
import sizeMe from 'react-sizeme';
const {sizeScaleMap,colorContrastMap} = require('../lib/stdvalues.js');
const {EditMapObject,getDefaultNames} = require('./objects.jsx');
const {getAllSpells,getSpellActionInfo} = require('./characterspells.jsx');
const {getDurationFromSpell} = require('./renderspell.jsx');


import Konva from 'konva'

const testCallback=0;
const measureColor="yellow";
const dragHandleColor="#182026";
const dragHandleBorder = "#c0c0c0";
const bgColor = "#fdf1dc";
const fogColor = "#282828";
const toolBackground = "#182026";
const toolStroke = "rgb(238, 231, 205)";
const skullUrl = "/scross.png";
const heartbeatUrl = "/heartbeat.png";
const gridBaseSize = 70;
const selWidth = 0.2;
const doubleSelWidth = 0.4;
const topToolbarHeight = 30;
const toolWidth = 28;
const selectHighlight = "rgba(64,64,64,0.2)";

let EncounterDialog, getDefaultBookName,getFragmentFromChapterSection,getSuggestedPlayerInfo,popupState,setPointer,clearPointer;

function hasToolbar(variant) {
    return ["edit","encounter","gm"].includes(variant);
}

function hasDrawFog(variant) {
    return ["edit","gm"].includes(variant);
}

function readonlyVariant(variant) {
    return ["popout","inline"].includes(variant);
}

class MapView extends React.Component {
    constructor(props) {
        super(props);
        this.loadAdventureViewFn = this.loadAdventureView.bind(this);
        this.state={mapInfo:null};

        if (props.mapRef) {
            props.mapRef(this);
        }

        if (!EncounterDialog) {
            // protect against recursion
            const ev = require('./encounterview.jsx');
            EncounterDialog = ev.EncounterDialog;
            getDefaultBookName = ev.getDefaultBookName;
            getSuggestedPlayerInfo = ev.getSuggestedPlayerInfo;

            getFragmentFromChapterSection = require('./book.jsx').getFragmentFromChapterSection;
            const mp = require('./map.jsx');

            popupState = mp.popupState;
            setPointer = mp.setPointer;
            clearPointer = mp.clearPointer;
        }
        this.popupChangedFn = this.popupChanged.bind(this);
    }

    componentDidMount() {
        this.startCanvas();

        if (this.props.campaignMode) {
            globalDataListener.onChangeCampaignContent(this.loadAdventureViewFn,"adventure");
            this.loadAdventureView();
        }
        if (this.props.variant == "gm") {
            popupState.subscribe(this.popupChangedFn);
        }
        if (this.focusRef) {
            this.focusRef.focus();
        }
    }

    componentWillUnmount() {
        this.map && this.map.destroy();
        this.map=null;
        this.combatants && this.combatants.destroy();
        this.combatants=null;

        if (this.props.campaignMode) {
            globalDataListener.removeCampaignContentListener(this.loadAdventureViewFn,"adventure");
        }

        if (this.updateShared) {
            clearTimeout(this.updateShared);
            this.updateShared=null;
            updateSharedCampaign();
        }

        if (this.props.variant == "gm") {
            popupState.unsubscribe(this.popupChangedFn);
            popupState.setPopupState(false);
        }
    }

    loadAdventureView() {
        const {campaignMode} = this.props;
        if (campaignMode && this.map) {
            const av = campaign.getAdventureView();
            const handouts = campaign.getHandouts();

            const ns = {};
    
            const handout = (handouts.showHandout && handouts.mru && handouts.mru.length)?handouts.mru[0]:null;
            if ((handouts.showHandout  && !this.state.lastShowHandout) || !areSameDeep(handout, this.state.lastShownHandout)) {
                ns.handout = handout;
                ns.lastShownHandout = handout;
                ns.lastShowHandout = handouts.showHandout;
            }
    
            this.cversion = av.cversion;
            this.setState(ns);
        }
    }

    componentDidUpdate(prevProps,prevState) {
        const {size, pointerX, pointerY, handout, mapName,variant,mapPos,encounter,selected} = this.props;
        const {fixedHeight} = this.state;
        const {width,height} = this.getCanvasDimensions();
        
        if (this.map) {
            if (mapName != prevProps.mapName) {
                this.map.setMap(mapName);
            }
            if (mapPos && (mapPos != prevProps.mapPos)) {
                this.map.setMap(mapPos.mapName, mapPos);
            }
            if ((prevProps.size.width != size.width) || (prevProps.size.height!=size.height) ) {
                this.calcFixedHeight();
                this.map.setDimensions(width, height);
            } else if (!fixedHeight) {
                this.calcFixedHeight();
            }
        }
        if ((prevProps.pointerX != pointerX) || (prevProps.pointerY != pointerY)) {
            this.map.setPointer(pointerX, pointerY);
        }
        if (prevProps.encounter != encounter) {
            if (!this.combatants) {
                this.combatants = new EncounterCombatants(encounter);

                this.map.setEncounterCombatants(this.combatants);
            } else {
                this.combatants.changeEncounter(encounter);
            }
        }
        if (handout != prevProps.handout) {
            this.setState({handout});
        }

        if (selected!=prevProps.selected) {
            if (!areSameDeep(selected||{}, this.combatants.getSelection())) {
                if (selected && Object.keys(selected).length) {
                    if (!["select","move"].includes(this.map.mode)) {
                        const t = this;
                        this.setMode("move");
                        // deal with set mode clearing selection and introducing a race
                        setTimeout(function (){
                            t.map.selectCombatants(Object.keys(selected));

                        },50);
                    } else {
                        this.map.selectCombatants(Object.keys(selected));
                    }
                } else if (this.combatants.hasSelection()) {
                    this.map.selectCombatants();
                }
            }
        }
    }

    getCenter() {
        const mapPos = this.map.mapPos;

        if (!mapPos) {
            return {x:1,y:1};
        }

        let x = mapPos.x,
            y = mapPos.y;

        return {x:x, y:y};
    }

    getMapName() {
        return this.map.mapInfo?.name;
    }

    calcFixedHeight() {
        const {size, variant} = this.props;
        if ((variant=="inline") && this.map) {
            const mapDimensions = this.map.mapDimensions;
            if (mapDimensions) {
                const setHeight = size.width * (mapDimensions.height||1)/(mapDimensions.width||1);
                this.setState({fixedHeight:setHeight});
            }
        }
    }

    startCanvas() {
        let {size, variant, campaignMode, playerView,mapName,mapPos,encounter,showMonsterPicker,eventSync} = this.props;
        const {width,height} = (size||{}); 
        this.map = new Map({
            container: this.contentRef,
            width,
            height,
            showCover:!["inline","encounter"].includes(variant),
            playerView,
            campaignMode,
            variant,
            mode:"move",
            eventSync
        });

        if (campaignMode) {
            this.combatants = new EncounterCombatants("campaign", playerView);

            this.map.setEncounterCombatants(this.combatants);
            if (!playerView) {
                this.map.setShowGrid(campaign.getPrefs().grid||false);
            }
        } else if (encounter) {
            this.combatants = new EncounterCombatants(encounter);

            this.map.setEncounterCombatants(this.combatants);
        }

        if (mapPos) {
            this.map.setMap(mapPos.mapName, mapPos);
        } else if (mapName) {
            this.map.setMap(mapName);
        } else if (campaignMode && !playerView) {
           this.updateSharedCampaign();
        }

        this.calcFixedHeight();
        // cleaned up on destroy
        this.map.events.on("tap", this.tapCombatant.bind(this));
        this.map.events.on("selection", this.selectChange.bind(this));
        this.map.events.on("changeMapPos", this.changeMapPos.bind(this));
        this.map.events.on("changeMapExtra", this.changeMapExtra.bind(this));
        this.setState({select:campaign.newUid()}); // force reload after setting map
    }

    getCanvasDimensions() {
        let {width,height} = this.props.size;
        const hasTopToolbar =hasToolbar(this.props.variant);

        if (hasTopToolbar) {
            height -= topToolbarHeight;
        }
        return {width:Math.max(1,width),height:Math.max(1,height)};
    }

    onKeyPress(e) {
        const map=this.map;
        const selectionType = map.getSelectionType();
        const mode = map.mode;
        const {key,ctrlKey,shiftKey} = e;
        e.preventDefault();

        if (map.dragging) {
            // don't handle keys while dragging
            return;
        }

        //console.log("press key", e.key);
        switch (e.key) {
            case "PageUp":
            case "+":
                this.zoomKey(true);
                break;
            case "PageDown":
            case "-":
                this.zoomKey(false);
                break;
            case "s":
            case "S":
            case "ArrowDown":
                map.moveKey(selectionType,mode,ctrlKey,0,1);
                break;
            case "w":
            case "W":
            case "ArrowUp":
                map.moveKey(selectionType,mode,ctrlKey,0,-1);
                break;
            case "a":
            case "A":
            case "ArrowLeft":
                map.moveKey(selectionType,mode,ctrlKey,-1,0);
                break;
            case "d":
            case "D":
            case "ArrowRight":
                map.moveKey(selectionType,mode,ctrlKey,1,0);
                break;
            case "f":
            case "F":
                if (hasDrawFog(map.variant)) {
                    this.setFogDiameter({value:5});
                }
                break;
            case "p":
            case "P":
            case "c":
            case "C":
                if (hasToolbar(map.variant)) {
                    this.setMode("draw");
                }
                break;
            case "m":
            case "M":
                if (this.combatants) {
                    this.showMonsterPicker(true);
                }
                break;
            case "r":
            case "R":
                if (hasToolbar(map.variant) && map.showCover) {
                    this.setMode("region");
                }
                break;
            case "Enter":
                // Do something for "enter" or "return" key press.
                break;
            case " ":
                // Do something for "space" key press.
                break;
            case "Tab":
                map.tabSelection(shiftKey);
                break;
            case "Escape":
                this.setMode("move");
                map.clearSelection();
                break;
            case "Delete":
                this.askDelete(selectionType, mode);
                break;
            default:
        }
    }

    askDelete(selectionType, mode) {
        let askTitle, askText;

        switch (selectionType) {
            case "drawsel":
                this.deleteSelection();
                break;

            case "combatants":
                const {noDelete} = this.getGroupTokenInfoOptions(this.combatants.selected);
                if (noDelete) {
                    return;
                }
                // fall through
            case "pin":
            case "region":
                askText = "Are you sure that you want to delete?";
                break;
        }

        //console.log("ask delete", askTitle, askText)
        if (askTitle||askText) {
            this.setState({askTool:{
                askTitle,
                askText,
                onClick:this.deleteSelection.bind(this)
            }});
        }
    }

    deleteSelection() {
        const map = this.map;
        const selectionType = map.getSelectionType();

        switch (selectionType) {
            case "combatants":
                this.combatants.deleteSelected();
                break;
            case "pin":
                map.deleteSelectedPin();
                break;
            case "drawsel":
                map.deleteSelectedDrawing();
                break;
            case "region":
                map.deleteSelectedRegion();
                break;
        }
    }

    render() {
        const {size,character,variant,className,onClick, bookname, chapter, section, subsection} = this.props;
        const {fixedHeight,showMapSettings,showEditObject,editObject,handout, editPin,showNameEncounter,nameEncounter,showEncounter,showPickMap,showMonsterPicker,pickMonsterTokens,showCid,editName,anchorEl, showAdjust, showAdjustHP,askTool} = this.state;
        const {width,height} = this.getCanvasDimensions();
        const readonly = readonlyVariant(variant);
        const map = (this.map||{});
        const mode = (this.map||{}).mode;

        const topToolbar = this.getTopToolbar();
        const onContextMenu = onClick?null:function(e){e.preventDefault()};

        return <div tabIndex={readonly?null:0} className={"tourMap "+(className||"")} style={{height:fixedHeight||"100%", width:"100%", overflow:"hidden", position:"relative",backgroundColor:fogColor}} onClick={onClick} onKeyDown={readonly?null:this.onKeyPress.bind(this)} ref={this.saveFocus.bind(this)}>
            {topToolbar}
            <span onContextMenu={onContextMenu}>
                <div className={this.getCursor(map)} style={{position:"absolute", top:topToolbar?topToolbarHeight:0, left:0, width, height}} ref={this.saveRef.bind(this)}>
                </div>
                {this.getSelectedToolbar(topToolbar?topToolbarHeight:0)}
                {this.getZoom()}
                {this.getToolMenu()}
                {this.getColorPicker()}
            </span>

            {showEditObject?<EditMapObject open object={editObject} onClose={this.handleEditObject.bind(this)}/>:null}
            {showMapSettings?<MapDialog open={showMapSettings} name={map.mapInfo.name} onClose={this.showMapSettings.bind(this,false)}/>:null}
            {handout?<ShowHandout handout={handout} onClick={this.hideHandout.bind(this)} size={size} character={character}/>:null}
            {editPin?<PinDialog 
                open 
                pin={editPin} 
                onClose={this.showEditPin.bind(this, null)}
                bookname={bookname}
                chapter={chapter}
                section={section}
                subsection={subsection}
            />:null}
            {showNameEncounter?<TextBasicEdit show label="New Encounter Name" text={nameEncounter} onChange={this.onNewEncounter.bind(this)}/>:null}
            {showEncounter?<EncounterDialog 
                encounter={showEncounter} 
                open
                onClose={this.showEncounter.bind(this,null)} 
                onGoToEncounter={this.onGoToEncounter.bind(this)}
            />:null}
            {showPickMap?<MapPicker 
                onClose={this.pickMap.bind(this)} 
                campaignVersion 
                bookname={["edit","encounter"].includes(variant)&&bookname}
                mapName={map.mapInfo?.name}
                onClickAltMap={this.pickAlternateMap.bind(this)} 
                open
            />:null}

            {showMonsterPicker?<MonsterPicker 
                open
                includeCount 
                onClose={this.hideMonsterPicker.bind(this)} 
                extraButtons={this.getMonsterPickerExtraButtons.bind(this)}
            />:null}
            {pickMonsterTokens?<MonsterTokenSelect 
                open
                monsters={pickMonsterTokens} 
                onClose={this.closePickMonsterTokens.bind(this)}
            />:null}
            {showCid?<MonsterPCDialog
                open
                onClose={this.showCombatant.bind(this,null)}
                cid={showCid}
                members={this.combatants.combatants}
                onChange={this.combatants.changeCombatants.bind(this.combatants)}
            />:null}
            {editName?<TextBasicEdit show onChange={this.changeName.bind(this)} label="Name" text={editName}/>:null}
            {showAdjust?<NumberAdjust 
                open
                anchorEl={anchorEl}
                positive={false} 
                value={showAdjustHP||0} 
                noShowValue 
                onChange={this.onAdjustChange.bind(this)}
                onClose={this.hideAdjust.bind(this)}
            />:null}
            {askTool?<AskYesNo
                open
                title={askTool.askTitle}
                text={askTool.askText}
                onClose={this.closeAsk.bind(this)}
            />:null}
        </div>;
    }

    getCursor(map) {
        if (!["popout","inline"].includes(map.variant)) {
            switch (map.mode) {
                case "move":
                    break;
                case "select":
                    return "cursor-cell";
                    break;
                case "fog":
                    if (["rect", "brush"].includes(map.fogTool)) {
                        return "cursor-crosshair";
                    }
                    break;
            }
        }
    }

    showPickMap(showPickMap) {
        this.setState({showPickMap});
    }

    showEditObject(c){
        this.setState({showEditObject:true, editObject:c});
    }

    showEditPin(newEditPin) {
        const {editPin} = this.state;
        if (editPin) {
            // make sure pin is selected.
            this.map.selectPin(editPin.name);
        }
        this.setState({editPin:newEditPin})
    }

    showNewPin() {
        const {bookname, chapter, section, subsection} = this.props;
        const newPin = {
            name:campaign.newUid(),
            type:"location", 
            mapPos: this.map.getSelectedMapPos(),
        }

        if (bookname) {
            const fragment = getFragmentFromChapterSection(bookname,chapter,section,subsection);

            newPin.links=[{
                name:bookname, 
                type:"book", 
                book:bookname, 
                fragment
            }];
            newPin.displayName = this.getDefaultBookName();
        }
        this.setState({editPin:newPin})
    }

    setEncounterPin() {
        const {encounter} = this.props;
        const encounterInfo = campaign.getPlannedEncounterInfo(encounter);

        if (encounterInfo) {
            let updatePin, pin = campaign.findEncounterPin(encounter);

            if (pin) {
                const ep = (pin.links||[]).findIndex(function (t){return t.name==encounter});
                if (ep>=0) {
                    pin = Object.assign({}, pin);
                    pin.links = pin.links.concat([])
                    pin.links.splice(ep,1);
                }
            } else {
                pin = {type:"location", name:campaign.newUid(), displayName:encounterInfo.displayName};
            }
            pin.mapPos = this.map.getSelectedMapPos();
            campaign.updateCampaignContent("pins", pin);
            if (encounterInfo.pinName != pin.name) {
                const newEncounterInfo = Object.assign({}, encounterInfo);
                newEncounterInfo.pinName = pin.name;
                campaign.updateCampaignContent("plannedencounters", pin);
            }
            this.map.selectPin(pin.name);
        }
    }

    onNewEncounter(newName) {
        const {bookname, chapter, section, subsection} = this.props;
        if (newName) {
            const newPin = {
                name:campaign.newUid(),
                type:"location", 
                mapPos: this.map.getSelectedMapPos(),
                displayName:newName,
                links:[]
            }
            campaign.updateCampaignContent("pins", newPin);

            const newEncounter = Object.assign({name:campaign.newUid(), displayName:newName, pinName:newPin.name}, getSuggestedPlayerInfo());
            if (bookname) {
                const fragment = getFragmentFromChapterSection(bookname,chapter,section,subsection);
        
                newEncounter.bookReference = {book:bookname, fragment}
            }
            campaign.updateCampaignContent("plannedencounters", newEncounter);

            // make sure pin is selected.
            this.map.selectPin(newPin.name);

            this.onGoToEncounter(newEncounter.name);
        }
        this.setState({showNameEncounter:false})
    }

    doPinTool(t) {
        switch (t.name) {
            case "New Pin":
                this.showNewPin();
                break;
            case "Set Encounter Pin":
                this.setEncounterPin();
                break;
        }
    }

    doAddTool(t) {
        switch (t.name) {
            case "New Pin":
                this.showNewPin();
                break;
            case "Add Monsters":
                this.showMonsterPicker(true);
                break;
            case "New Encounter":
                this.showNewEncounter();
                break;
            case "Set Encounter Pin":
                this.setEncounterPin();
                break;
            case "Add Map Object":
                this.showNewMapObject();
                break;
            case "Add Image":
                this.showNewMapObject("image");
                break;
        }
    }

    doCombatantTools(t) {
        switch (t.name) {
            case "Add Monsters":
                this.showMonsterPicker(true);
                break;
            case "Huddle Monsters":
                this.combatants.doHuddle(this.map.getSelectedMapPos(),"monster");
                break;
            case "Select All Monsters":
                this.combatants.selectGroup("monster");
                break;
            case "Huddle Characters":
                this.combatants.doHuddle(this.map.getSelectedMapPos(),"pc");
                break;
            case "Select All Characters":
                this.combatants.selectGroup("pc");
                break;
        }
        if (this.combatants.hasSelection()) {
            this.map.stageTap=null;
            this.map.updateSelection();
        }
    }

    onGoToEncounter(name) {
        const {onGoToEncounter} = this.props;
        if (onGoToEncounter) {
            onGoToEncounter(name);
        } else {
            this.showEncounter(name);
        }
    }

    showNewEncounter() {
        this.setState({showNameEncounter:true,nameEncounter:this.getDefaultBookName()})
    }

    pickMap(mapInfo) {
        if (mapInfo) {
            var newMapPos ={};
            if (campaign.isCampaignGame()) {
                const mapextra = campaign.getMapExtraInfo(mapInfo.name);
                Object.assign(newMapPos, (mapextra||{}).mapPos||{});
                campaign.setPrefs({selectedMap:mapInfo.name});
            }

            newMapPos.mapName = mapInfo.name;
            this.props.onGoToMap(newMapPos);

            campaign.addMRUList("mruMaps", {description:mapInfo.displayName, mapName:mapInfo.name});
        }
        this.setState({showPickMap:false});
    }

    pickAlternateMap(art) {
        if (campaign.isCampaignGame()) {
            const {name} = this.map.mapInfo;
            const newExtra=Object.assign({},campaign.getMapExtraInfo(name)||{name});
            newExtra.altImage = art;
            
            campaign.updateCampaignContent("mapextra", newExtra);
        } else {
            this.map.setAltImage(art);
        }
        this.setState({showPickMap:false});
    }

    showMapSettings(showMapSettings) {
        this.setState({showMapSettings});
    }

    showEncounter(showEncounter) {
        this.setState({showEncounter});
    }

    getTopToolbar() {
        const map = this.map;
        if (map) {
            const {variant,onGoToMap} = this.props;
            if (!hasToolbar(variant)) {
                return;
            }
            const mode=map.mode;

            const tools=[];

            if (variant == "gm") {
                tools.push({
                    tool:"fas fa-share-square",
                    toolTip:"Project",
                    selected:!!popupState.showPopup,
                    onClick:this.togglePopup.bind(this)
                });
            }
            
            tools.push({
                tool:"fas fa-arrows-alt",
                toolTipTitle:"Move",
                toolTipBody:<div>
                    <p><b>Move:</b> Click and drag map</p>
                    <p><b>Select:</b> Click tokens or pins</p>
                    <p><b>Select Multiple:</b> CTRL + Click and drag</p>
                    <p><b>Zoom:</b> Rotate mouse wheel</p>
                </div>,
                onClick:this.setMode.bind(this,"move"),
                selected:(mode=="move")
            });

            if (map.combatants) {
                
                tools.push({
                    tool:"far fa-object-group",
                    toolTipTitle:"Select",
                    toolTipBody:<div>
                        <p><b>Select Multiple:</b> Click and drag</p>
                        <p><b>Move:</b> SHIFT + Click or Right-Click drag</p>
                        <p><b>Select:</b> Click tokens or pins</p>
                        <p><b>Zoom:</b> Rotate mouse wheel</p>
                    </div>,
                    onClick:this.setMode.bind(this,"select"),
                    selected:(mode=="select")
                });
            }
            tools.push({
                tool:map.showGrid?"fas fa-th-large":"fas fa-square-full",
                toolTip:map.showGrid?"Hide Grid":"Show Grid",
                onClick:this.toggleGrid.bind(this)
            });
            tools.push({
                tool:"fas fa-search-plus",
                toolTip:"Zoom",
                onClick:this.showZoom.bind(this,true)
            });
            if (onGoToMap) {
                tools.push({
                    tool:"fas fa-images",
                    toolTip:"Pick Map",
                    onClick:this.showPickMap.bind(this,true)
                });
            }

            if (variant == "gm") {
                tools.push({
                    handout:true
                });
                tools.push({
                    tool:"fas fa-magnet",
                    toolTip:"Center Player View",
                    onClick:this.synchMap.bind(this)
                });
            }

            tools.push({
                spacer:true
            });
            if (mode=="fog") {
                tools.push({
                    tool:"fas fa-undo",
                    toolTip:"Undo Fog",
                    onClick:this.coverUndo.bind(this,true),
                    disabled:!map.hasUndoCover
                });
                tools.push({
                    tool:"fas fa-redo",
                    toolTip:"Redo Fog",
                    onClick:this.coverUndo.bind(this,false),
                    disabled:!map.hasRedoCover
                });
            }
            if ((map.showCover || mode=="region") && ["fog","region","move","select"].includes(mode)) {
                tools.push({
                    tool:"fas fa-draw-polygon",
                    toolTip:"Polygon",
                    onClick:this.setFogTool.bind(this,"polygon"),
                    selected:["fog","region"].includes(mode) && map.fogTool=="polygon"
                });
                tools.push({
                    tool:"fas fa-vector-square",
                    toolTip:"Rectangle",
                    onClick:this.setFogTool.bind(this,"rect"),
                    selected:["fog","region"].includes(mode) && map.fogTool=="rect"
                });
                if (mode != "region") {
                    tools.push({
                        tool:"fas fa-brush",
                        toolTip:"Brush",
                        onShowClick:this.setFogTool.bind(this,"brush"),
                        onClick:this.setFogDiameter.bind(this),
                        list:fogToolDiameters,
                        selectedVal:map.fogDiameter,
                        selected:mode=="fog" && map.fogTool=="brush"
                    });
                }
            } 
            if (mode == "draw") {
                tools.push({
                    tool:"fas fa-undo",
                    toolTip:"Undo Drawing",
                    onClick:this.drawUndo.bind(this,true),
                    disabled:!map.hasUndoDrawing
                });
                tools.push({
                    tool:"fas fa-redo",
                    toolTip:"Redo Drawing",
                    onClick:this.drawUndo.bind(this,false),
                    disabled:!map.hasRedoDrawing
                });
                tools.push({
                    color:this.map.drawColor,
                    toolTip:"Draw Color",
                    onClick:this.showColorPicker.bind(this,"draw")
                });
            }
            
            if (["draw","move","select"].includes(mode)) {
                tools.push({
                    tool:"fas fa-pen",
                    toolTip:"Draw",
                    onClick:this.setMode.bind(this,"draw"),
                    selected:mode=="draw"
                });
            }

            if ((mode=="region")&&["region","move","select"].includes(mode)) {
                    tools.push({
                    tool:"fas fa-object-ungroup",
                    toolTip:"Build Regions",
                    onClick:this.setMode.bind(this,"region"),
                    selected:true
                });
            }

            if (map.showCover && ["fog","move","select","draw"].includes(mode)) {
                tools.push({
                    tool:"fas fa-eye",
                    toolTip:"Fog Tools",
                    onClick:this.doFogTool.bind(this),
                    list:fogToolList,
                    selectedVal:map.fogOpacity
                });
            }
            if (map.mapInfo) {
                tools.push({
                    tool:"fas fa-cog",
                    toolTip:"Settings",
                    onClick:this.showMapSettings.bind(this,true),
                });
            }

            if (tools.length) {
                const list = [];

                for (let i in tools) {
                    const t = tools[i];
                    if (t.spacer) {
                        list.push(<span key={i} className="flex-auto"/>)
                    } else if (t.handout) {
                        list.push(<span className={"dib br2 pv--2 tc toolnormal"+(i>0?" ml--3":"")} style={{width:toolWidth}} key={i}><PickHandout  allowDelete setActive><Tooltip classes={{tooltip:"f4"}} title="Pick Handout"><span className="mv--2 fas fa-scroll toolnormal"/></Tooltip></PickHandout></span>);
                    } else {
                        const onClick = t.disabled?null:t.list?this.showToolMenu.bind(this, t):t.onClick;
                        let toolTipClasses={tooltip:"f4"}, toolTip=t.toolTip;

                        if (t.toolTipTitle) {
                            toolTipClasses={tooltip:"ba b--light-orange pa0 overflow-hidden"};
                            toolTip = <div>
                                <div className="bg-light-orange darkText tc pa1 f4">
                                    {t.toolTipTitle}
                                </div>
                                <div className="maxh55 w7 darkBackground lightAltText overflow-auto pa1 f5">
                                    {t.toolTipBody}
                                </div>
                            </div>;
                        }

                        list.push(<span onClick={onClick||null} className={"dib br2 pv--2 tc"+(i>0?" ml--3":"")+(t.selected?" toolselect":" toolnormal")+(t.disabled?" tooldisabled":"")} style={{width:toolWidth}} key={i}>
                            <Tooltip classes={toolTipClasses} title={toolTip}>
                                {t.color?<ColorToken className="mv--2" color={t.color}/>:<span className={"mv--2 "+t.tool}/>}
                            </Tooltip>
                        </span>);
                    }
                }

                //console.log("draw tool", top,left,pos, width, height, toolbarHalfWidth, toolbarHeight);
                return <div className="maptool flex ph1 pv--2 cursor-default" style={{height:topToolbarHeight}}>
                    {list}
                </div>
            }
        }
    }

    toggleGrid() {
        const grid = !this.map.showGrid;
        this.map.setShowGrid(grid);
        if (campaign.isCampaignGame()) {
            popupState.setGrid(grid);
        }
    }

    togglePopup() {
        if (!campaign.secondScreen) {
            displayMessage(<span>You need a subscription to activate the second screen.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to enable this capability.</span>);
            return;
        }
        popupState.setPopupState(!popupState.showPopup);
    }

    synchMap() {
        campaign.updateAdventureView({cversion:campaign.newUid()});
    }  

    getSelectedToolbar(toolAdjust) {
        if (this.map) {
            const {size, variant} = this.props;
            const selectionType = this.map.getSelectionType();
            let tools=[], forceTop,full;

            switch (variant) {
                case "player":
                    forceTop = this.getPlayerTools(tools, selectionType);
                    break;
                case "edit":
                    this.getEditTools(tools, selectionType);
                    forceTop = false;
                    break;
                case "gm":
                    full=true;
                    // fall through
                case "encounter":
                    this.getFullTools(tools, selectionType,full);
                    forceTop = false;
                    break;
            }

            if (tools.length) {
                let toolbarHalfWidth = (((tools.length)*toolWidth+3)+10)/2;
                const toolbarHeight = 40;
                const list = [];

                for (let i in tools) {
                    const t = tools[i];
                    const onClick = t.list?this.showToolMenu.bind(this, t):t.onClick;
                    if (t.hp!=null) {
                        const text = t.maxHP?`${t.hp||0}/${t.maxHP}`:(t.hp||0).toString();
                        list.push(<span onClick={onClick||null} className={"dib toolhp "+(i>0?" ml--3":"")+(t.healthGroup?((" health"+t.healthGroup)):"")} key={i}>
                            <Tooltip title={t.toolTip}><span className="mv--2">{text}</span></Tooltip>
                        </span>);
                        toolbarHalfWidth += (text.length*11-toolWidth)/2;
                    } else {
                        list.push(<span onClick={t.delete?null:onClick||null} className={"dib br2 pv--2 tc"+(i>0?" ml--3":"")+(t.selected?" toolselect":" toolnormal")} style={{width:toolWidth}} key={i}>
                            <Tooltip title={t.toolTip}>
                                {t.color?<ColorToken className="nudge-down--2" color={t.color}/>:
                                t.delete?<DeleteWithConfirm className="mv--2" onClick={onClick||null} name={t.deletePrompt} noHighlight/>:
                                <span className={"mv--2 "+t.tool}/>}
                            </Tooltip>
                        </span>);
                    }
                }

                let {width,height} = size;
                let top=5+toolAdjust, left=5;
                const pos = this.map.getSelectedPosition();
                if (!forceTop && pos) {
                    left = Math.min(Math.max(pos.x-toolbarHalfWidth, 5), width-toolbarHalfWidth*2-20);
                    top = Math.min(Math.max(pos.y, 5)+toolAdjust, height-toolbarHeight);
                }

                //console.log("draw tool", top,left,pos, width, height, toolbarHalfWidth, toolbarHeight);
                return <div className="maptool shadow-3 ph1 pv--2 ba br4 cursor-default" style={{position:"absolute", top, left}}>
                    {list}
                </div>
            }
        }
    }

    showToolMenu(tool,e) {
        if (tool) {
            if (tool.onShowClick) {
                tool.onShowClick(e);
            }
            if (tool.list.length==1 && !tool.alwaysShowMenu) {
                // just do action if only one option
                this.clickToolMenu(tool, tool.list[0]);
                return;
            }
        } else {
            return this.setState({showToolMenu:false});
        }
        this.setState({showToolMenu:tool, anchorEl:e&&e.target});
    }

    closeAsk(yes) {
        if (yes) {
            this.state.askTool.onClick();
        }
        this.setState({askTool:null});
    }

    getToolMenu() {
        const {showToolMenu,anchorEl} = this.state;

        if (!showToolMenu) {
            return null;
        }

        const mlist = [];
        const menuList = (typeof showToolMenu.list == "function")?showToolMenu.list():showToolMenu.list;
        for (let i in menuList) {
            const m = menuList[i];
            const showName = m.displayName||m.name;
            const selected =(showToolMenu.selectedVal!=null) && ((showToolMenu.selectedVal==showName)||(showToolMenu.selectedVal==m.value));

            if (m.addCondition) {
                mlist.push(<PickCondition key={i} dark onAddCondition={this.closeAddCondition.bind(this)}/>)
            } else {
                mlist.push(<MenuItem className={m.separated?"bb titleborder":null} key={i} onClick={m.noClick?null:this.clickToolMenu.bind(this, showToolMenu, m)}>
                    <div className={(m.centered?"tc w-100":null)}>
                        {showName}
                        {selected?<span className="pa1 fas fa-check"/>:null}
                    </div>
                </MenuItem>);
            }
        }
        return <Popover 
            open 
            anchorOrigin={{vertical: 'bottom',horizontal: 'center',}}
            transformOrigin={{vertical: 'top',horizontal: 'center',}} 
            anchorEl={anchorEl} 
            onClose={this.showToolMenu.bind(this,null)}
        >
            <Paper className="darkT darkBackground">
                {mlist}
            </Paper>
        </Popover>
    }

    clickToolMenu(tool, m) {
        this.showToolMenu(null);
        tool.onClick(m);
    }

    getPlayerTools(tools, selectionType) {
        const mode = this.map.mode;
        switch (selectionType) {
            case "combatants":
                if (this.combatants) {
                    const selected = this.combatants.selected;
                    const ids = Object.keys(selected);
                    if (ids.length == 1) {
                        const cInfo = this.combatants.getCombatant(ids[0]);
                        if (cInfo) {
                            const {ctype} = cInfo;

                            if (ctype == "object") {
                                const waList = this.getAOEActions(cInfo);
                                if (waList) {
                                    tools.push({
                                        tool:"fas fa-hand-rock",
                                        toolTip:"Effects",
                                        onClick:this.handleEffectsOptions.bind(this,cInfo.cid),
                                        list:waList,
                                        alwaysShowMenu:true
                                    });
                                }
        
                                if (cInfo.otype!="image") {
                                    tools.push({
                                        color:cInfo.fill,
                                        toolTip:"Color",
                                        onClick:this.showColorPicker.bind(this,"object")
                                    });
                                }
            
                                tools.push({
                                    tool:"fas fa-edit",
                                    toolTip:"Edit",
                                    onClick:this.showEditObject.bind(this, cInfo)
                                })
                            } else {

                                tools.push({
                                    tool:"fas fa-bullseye",
                                    toolTip:"Measure",
                                    list:this.getShowMeasureDistanceList(),
                                    onClick:this.showMeasureDistance.bind(this)
                                });

                                const crow = new Combatant(cInfo);
                                const onClick = this.handleHealthOptions.bind(this);
                                const hp = crow.hp;
                                const list = this.getHealthOptions.bind(this, hp,crow.displayName);
                                tools.push({
                                    toolTip:"HP",
                                    hp:crow.hp,
                                    maxHP:crow.hpMax<1000?crow.hpMax:0,
                                    healthGroup:getHealthGroup(hp,crow.hpMax),
                                    onClick,
                                    list
                                });

                                const waList = this.getWeaponOptions(cInfo, crow);
                                if (waList) {
                                    tools.push({
                                        tool:"fas fa-hand-rock",
                                        toolTip:"Attacks",
                                        onClick:this.handleWeaponOptions.bind(this,cInfo,crow),
                                        list:waList,
                                        alwaysShowMenu:true
                                    });
                                }
                            }
                            if (["object", "cmonster"].includes(ctype)) {
                                if (cInfo.actionInfo) {
                                    tools.push({
                                        tool:"fas fa-trash",
                                        toolTip:"Delete",
                                        onClick:this.combatants.deleteCombatant.bind(this.combatants, cInfo.id)
                                    });
                                } else {
                                    tools.push({
                                        delete:true,
                                        toolTip:"Delete",
                                        onClick:this.combatants.deleteCombatant.bind(this.combatants, cInfo.id)
                                    });
                                }
                            }
                        }
                    } else {
                        
                    }
                }
                return false;
            case "stage":
                tools.push({
                    tool:"fas fa-arrows-alt",
                    toolTip:"Move",
                    onClick:this.setMode.bind(this,"move"),
                    selected:(mode=="move")
                });
                tools.push({
                    tool:"far fa-object-group",
                    toolTip:"Select",
                    onClick:this.setMode.bind(this,"select"),
                    selected:(mode=="select")
                });
                if (this.map.mapPos) {
                    tools.push({
                        tool:"fas fa-search-plus",
                        toolTip:"Zoom",
                        onClick:this.showZoom.bind(this,true)
                    });
                    tools.push({
                        tool:"fas fa-bullseye",
                        toolTip:"Measure",
                        list:this.getShowMeasureDistanceList(),
                        onClick:this.showMeasureDistance.bind(this)
                    });
                }
                tools.push({
                    tool:"fas fa-times",
                    toolTip:"Hide",
                    onClick:this.clearSelection.bind(this)
                });
                return true;
        }
        return false;
    }

    getEditTools(tools, selectionType) {
        const {variant,onAddEncounter,onGoToBook,onGoToMap} = this.props;

        const mode = this.map.mode;
        switch (selectionType) {
            case "combatants":
                break;
            case "pin":
                this.getPinTools(tools);
                break;
            case "stage":
                if (this.map.hoverRegion) {
                    const unfogged = this.map.isSelectedRegionUnfogged();
                    tools.push({
                        tool:unfogged?"fas fa-eye-slash":"fas fa-eye",
                        toolTip:unfogged?"Fog Region":"Unfog Region",
                        onClick:this.map.toggleFog.bind(this.map, this.map.hoverRegion)
                    });
                }
                tools.push({
                    tool:"fas fa-dragon",
                    toolTip:"New Encounter",
                    onClick:this.showNewEncounter.bind(this)
                });

                tools.push({
                    tool:"fas fa-thumbtack",
                    toolTip:"New Pin",
                    onClick:this.showNewPin.bind(this)
                });

                break;
            case "region":
                this.getRegionTools(tools)
                break;
            case "drawsel":
                this.getDrawSelTools(tools);
                break;
        }
        return false;
    }

    getFullTools(tools, selectionType,gmVersion) {
        const {variant,onAddEncounter,onGoToBook,onGoToMap} = this.props;

        const mode = this.map.mode;
        switch (selectionType) {
            case "combatants":
                this.getCombatantTools(tools, gmVersion);
                break;
            case "pin":
                this.getPinTools(tools,gmVersion);
                break;
            case "stage": {
                if (["draw","region","fog"].includes(mode)) {
                    return;
                }

                tools.push({
                    tool:"fas fa-bullseye",
                    toolTip:"Measure",
                    list:this.getShowMeasureDistanceList(),
                    onClick:this.showMeasureDistance.bind(this)
                });

                if (gmVersion) {
                    if (this.map.hoverRegion) {
                        const unfogged = this.map.isSelectedRegionUnfogged();
                        tools.push({
                            tool:unfogged?"fas fa-eye-slash":"fas fa-eye",
                            toolTip:unfogged?"Fog Region":"Unfog Region",
                            onClick:this.map.toggleFog.bind(this.map, this.map.hoverRegion)
                        });
                    }
                }
                const cInfo = this.map.getNoDragObject();
                if (cInfo) {
                    const overlap = this.map.findOverlap(cInfo.id);
                    if (overlap) {
                        tools.push({
                            tool:"fas fa-crosshairs",
                            toolTip:"Select Overlap",
                            onClick:this.map.selectCombatants.bind(this.map,overlap,false)
                        });
                    }

                    if (gmVersion) {
                        // need the full combatant info not the stub used by maps
                        const actionC = this.combatants.getCombatant(cInfo.id)||{};
                        const waList = this.getAOEActions(actionC);
                        if (waList) {
                            tools.push({
                                tool:"fas fa-hand-rock",
                                toolTip:"Effects",
                                onClick:this.handleEffectsOptions.bind(this,actionC.cid),
                                list:waList,
                                alwaysShowMenu:true
                            });
                        }
                    }

                    tools.push({
                        tool:"fas fa-mouse-pointer",
                        toolTip:"Select Map Object",
                        onClick:this.selectObject.bind(this,cInfo)
                    });
                }

                tools.push({
                    tool:"fas fa-users",
                    toolTip:"Group Actions",
                    list:groupToolList,
                    onClick:this.doCombatantTools.bind(this)
                });

                this.getAddObjectTool(tools,gmVersion);

                tools.push({
                    tool:"far fa-plus-square",
                    toolTip:"Add",
                    onClick:this.doAddTool.bind(this),
                    list:gmVersion?addToolList:addToolList.concat({name:"Set Encounter Pin"})
                });

                break;
            }
            case "region":
                this.getRegionTools(tools,gmVersion)
                break;
            case "drawsel":
                this.getDrawSelTools(tools,gmVersion);
                break;
        }
        return false;
    }

    getCombatantTools(tools,gmVersion) {
        if (this.combatants) {
            const selected = this.combatants.selected;
            const ids = Object.keys(selected);
            const {variant} = this.props;


            if (ids.length == 1) {
                const cInfo = this.combatants.getCombatant(ids[0]);
                if (cInfo) {
                    const crow = new Combatant(cInfo);
                    const {ctype,hidden,includeInCombat,otype} = cInfo;
                    const isCombatToken = ((ctype != "object") || includeInCombat);

                    if (isCombatToken) {
                        tools.push({
                            tool:"fas fa-bullseye",
                            toolTip:"Measure",
                            list:this.getShowMeasureDistanceList(),
                            onClick:this.showMeasureDistance.bind(this)
                        });

                        const onClick = this.handleHealthOptions.bind(this);
                        const hp = crow.hp;
                        const list = this.getHealthOptions.bind(this, hp,crow.displayName);
                        tools.push({
                            toolTip:"HP",
                            hp:crow.hp,
                            maxHP:crow.hpMax<1000?crow.hpMax:0,
                            healthGroup:getHealthGroup(hp,crow.hpMax),
                            onClick,
                            list
                        });

                        if (gmVersion) {
                            const waList = this.getWeaponOptions(cInfo, crow);
                            if (waList) {
                                tools.push({
                                    tool:"fas fa-hand-rock",
                                    toolTip:"Attacks",
                                    onClick:this.handleWeaponOptions.bind(this,cInfo,crow),
                                    list:waList,
                                    alwaysShowMenu:true
                                });
                            }
                        }
                    } else {
                        const overlap = this.map.findOverlap(cInfo.id);
                        if (overlap) {
                            tools.push({
                                tool:"fas fa-crosshairs",
                                toolTip:"Select Overlap",
                                onClick:this.map.selectCombatants.bind(this.map,overlap,false)
                            });
                        }
                        if (gmVersion && (ctype == "object")) {
                            const waList = this.getAOEActions(cInfo);
                            if (waList) {
                                tools.push({
                                    tool:"fas fa-hand-rock",
                                    toolTip:"Effects",
                                    onClick:this.handleEffectsOptions.bind(this,cInfo.cid),
                                    list:waList,
                                    alwaysShowMenu:true
                                });
                            }
                        }
                    }

                    tools.push({
                        tool:hidden?"fas fa-eye-slash":"fas fa-eye",
                        toolTip:hidden?"Show":"Hide",
                        onClick:this.combatants.updateSelected.bind(this.combatants, "hidden", !hidden)
                    });

                    if ((ctype=="object") && (otype!="image")) {
                        tools.push({
                            color:cInfo.fill,
                            toolTip:"Color",
                            onClick:this.showColorPicker.bind(this,"object")
                        });
    
                    }
                    tools.push({
                        tool:"fas fa-info",
                        toolTip:"Details",
                        onClick:this.handleTokenInfoTools.bind(this),
                        list:this.getTokenInfoOptions(crow, cInfo,isCombatToken)
                    });

                    if (["object", "cmonster"].includes(ctype)) {
                        if (cInfo.actionInfo) {
                            tools.push({
                                tool:"fas fa-trash",
                                toolTip:"Delete",
                                onClick:this.combatants.deleteSelected.bind(this.combatants)
                            });
                        } else {
                            tools.push({
                                delete:true,
                                toolTip:"Delete",
                                onClick:this.combatants.deleteSelected.bind(this.combatants)
                            });
                        }
                    }
                }
            } else {
                const {noDelete,someShown, someNonCombatants,list,names} = this.getGroupTokenInfoOptions(selected);

                if (!someNonCombatants) {
                    const list = this.getHealthOptions.bind(this,null,names);
                    tools.push({
                        tool:"fas fa-heartbeat",
                        toolTip:"Health & Conditions",
                        onClick:this.handleHealthOptions.bind(this),
                        list
                    });
                }

                tools.push({
                    tool:someShown?"fas fa-eye":"fas fa-eye-slash",
                    toolTip:someShown?"Hide":"Show",
                    onClick:this.combatants.updateSelected.bind(this.combatants, "hidden", !!someShown)
                });

                if (list.length) {
                    tools.push({
                        tool:"fas fa-info",
                        toolTip:"Details",
                        onClick:this.handleTokenInfoTools.bind(this),
                        list
                    });
                }

                if (!noDelete) {
                    tools.push({
                        delete:true,
                        toolTip:"Delete",
                        onClick:this.combatants.deleteSelected.bind(this.combatants)
                    });
                }
            }
        }
    }

    getHealthOptions(hp,names) {
        const list = [
            {name:"Damage/Heal",hp},
            {addCondition:true},
            {name:"Kill",separated:true}
        ];
        if (names) {
            list.unshift({displayName:names,centered:true,separated:true,noClick:true})
        }
        const {campaignMode, playerView} = this.props;

        if (campaignMode) {
            const lr = getRollLastActions(2);
            const userId = campaign.userId;
            
            //console.log("last roll actions", lr);
            for (let i in lr) {
                const {chat, roll, sum, saveAbility, saveVal, noHalfDamage} = lr[i];
                const hideDetails = playerView && ((chat.userId!=userId)||(chat.userType=="gm")) && (chat.permissions!="public");
    
                const actor = hideDetails?"":roll.source || roll.playerDisplayName || chat.actor;
                let tv,tvh,tvs;
                if (roll.action == "heal") {
                    list.push({displayName:`${actor} Heal ${sum}`, adjust:sum});
                } else {
                    const half = Math.trunc(sum/2);
    
                    if (saveAbility && saveVal) {
                        list.push({displayName:`${actor} Save + Damage ${sum}/${noHalfDamage?0:Math.trunc(sum/2)}`, sum, noHalfDamage,saveAbility, saveVal, origin:chat.origin});
                    }
    
                    list.push({displayName:`${actor} Damage ${sum}`, adjust:-sum});
                    if (half) {
                        list.push({displayName:`${actor} Half Damage ${half}`, adjust:-half});
                    }
                }
            }
        }

        const mru =  campaign.getMRUList("damageMRU");
        if (mru) {
            for (let x=0; x< Math.min(3,mru.length); x++){
                const m=mru[x];
                list.push({displayName:m.description, adjust:m.adjust})
            }
        }
        return list;
    }

    handleHealthOptions(val) {
        //console.log("handle click",val)
        if (val.adjust) {
            this.adjustHP(val.adjust);
            return;
        } else if (val.saveAbility) {
            doSelectedSave(this.combatants.getSelection(), null, val.saveAbility, val.saveVal, val.chatName, val.sum, null/*conditions*/, val.noHalfDamage);
            return;
        }
        switch (val.name) {
            case "Damage/Heal":
                this.showAdjust(val.hp);
                break;
            case "Kill":
                this.adjustHP(-100000);
                break;
        }
    }

    getAOEActions(cInfo) {
        const {getDmgString} = require('./renderchat.jsx');
        let list = [];
        const {actionInfo} = cInfo;
        if (actionInfo) {
            list.push({displayName:actionInfo.displayName,centered:true,separated:true,noClick:true});
            if (actionInfo.attackRoll || !actionInfo.damages) {
                list.push({
                    type:"attack",
                    displayName:`${actionInfo.attackRoll?"Attack":"Spell"} ${actionInfo.attackRoll||""}`,
                    actionName:actionInfo.displayName,
                    attackRoll:actionInfo.attackRoll,
                    damages:actionInfo.damages,
                    saveAbility:actionInfo.saveAbility,
                    saveVal:actionInfo.saveVal,
                    conditions:actionInfo.conditions,
                    altDamages:actionInfo.altdamages,
                    href:actionInfo.href
                });
            }
            if (actionInfo.damages) {
                list.push({
                    type:"damage",
                    displayName:`Damage ${getDmgString(actionInfo.damages)}`,
                    actionName:actionInfo.displayName,
                    damages:actionInfo.damages,
                    saveAbility:actionInfo.saveAbility,
                    saveVal:actionInfo.saveVal,
                    conditions:actionInfo.conditions,
                    altDamages:actionInfo.altdamages,
                    href:actionInfo.href
                });
            }
            if (actionInfo.altdamages) {
                list.push({
                    type:"damage",
                    displayName:`Damage ${getDmgString(actionInfo.altdamages)}`,
                    actionName:actionInfo.displayName,
                    damages:actionInfo.altdamages,
                    saveAbility:actionInfo.saveAbility,
                    saveVal:actionInfo.saveVal,
                    conditions:actionInfo.conditions,
                    href:actionInfo.href
                });
            }
        }
        return list.length>1?list:null;
    }

    getWeaponOptions(cInfo, crow) {
        const character = crow.characterObj;
        const {monObj} = crow;
        let list = [];

        if (((cInfo.ctype=="pc") || cInfo.type)&&character) {
            // find weapons/unarmed strikes of character
            if (!character.shape) {
                const itemMods = character.getItemMods();
                const eq = character.equipment||[];
                const unarmedAttacks = character.getSortedUnarmedAttacks();
                let secondWeapon = false;

                let hw = [];
                for (let i in eq) {
                    const it=eq[i];
                    upgradeItem(it);
                    secondWeapon = secondWeapon || ((it.dmg1 || it.weapon) && (it.equip=="OH"));
                    if (["PH","2H"].includes(it.equip)) {
                        hw[0] = {id:i,it};
                    } else if (it.equip == "OH") {
                        hw[1] = {id:i,it};
                    }
                }
                for (let i in unarmedAttacks) {
                    const ua = unarmedAttacks[i];

                    const {damages, hitBonus} = character.getUAActionInfo(ua, itemMods);
                    list.push({
                        type:"attack",
                        displayName:ua.name||"Unarmed Strike",
                        damages,
                        hitBonus:hitBonus||"+0"
                    });
                }

                for (let i in hw) {
                    const itid = hw[i];
                    if (itid) {
                        const {it,id}=itid;
                        const {dmg, damages, effects, hitBonus} = character.getItemActionInfo(id, it, itemMods, secondWeapon);
                        if (dmg && (hitBonus!="use")) {
                            list.push({
                                type:"attack",
                                displayName:it.displayName||it.name,
                                damages,
                                hitBonus:hitBonus||"+0",
                                effects,
                                id,
                                altEffect:it?.feature?.altEffect,
                                character
                            })
                        }
                    }
                }

                const allSpells = getAllSpells(character);
                const spellmods = character.getSpellMods();
                for (let i in allSpells) {
                    const sInfo = allSpells[i];
                    const {spell} = sInfo;
                    if (!spell.level && !getDurationFromSpell(spell, 0)) {
                        // cantrips only without duration
                        const {casterSpellSave, casterAttackRoll, spellcastingMod, saveAbility, saveVal, href, concentration, actionType, tempHp, attackRoll, damages, altdamages, castLevel, extraNotes, range,conditions,noShowDuration,areaOfEffect,duration} = getSpellActionInfo(character, spellmods, null, sInfo);
                        if (damages) {
                            list.push({
                                type:"spell",
                                displayName:spell.displayName,
                                attackRoll,
                                damages,
                                saveAbility,
                                saveVal,
                                conditions,
                                tempHp,
                                altDamages:altdamages&&altdamages.length?[{damages:altdamages}]:null,
                                href,
                                areaOfEffect
                            });
                        }
                    }
                }
            } else {
                list = getAttackActions(character.shape, character);
            }
            //console.log("attacks", list);
        } else if (monObj) {
            list = getAttackActions(monObj.state, monObj);
            //console.log("found mattacks", list);
        }
        return list.length?list:null;
    }

    handleWeaponOptions(cInfo, crow,wa) {
        const character = crow.characterObj;
        const {monObj} = crow;
        const {type, displayName,damages,hitBonus,effects,id,altEffect,save, html, altDamages,attackRoll,saveAbility,saveVal,conditions,tempHp,areaOfEffect} = wa;

        if (((cInfo.ctype=="pc") || cInfo.type)&&character) {
            if (type == "spell") {
                Chat.addAction(character, displayName, wa.href, attackRoll, damages, saveAbility, saveVal, "Cast Spell", conditions, tempHp, altDamages, true, areaOfEffect||{});
            } else {
                let href=null;
                if (id) {
                    href = "#item?iid="+encodeURIComponent(id)+"&cid="+encodeURIComponent(character.name);
                }
                if (hitBonus) {
                    Chat.addAction(character, displayName, href, (Number.isInteger(hitBonus)?"+"+hitBonus:" "), damages, altEffect?.save, altEffect?.saveDC,id?"Item":" ", altEffect?.conditions, altEffect?.temphp, effects);
                } else {
                    Chat.addCharacterDamageRoll(character, displayName, href, damages, null, null, altEffect?.save, altEffect?.saveDC);
                }
            }
        } else if (monObj) {
            const ignoreInfo = (cInfo.ctype=="cmonster");
            const href = "#charfeature?fhtml="+encodeURIComponent(html);
            if (hitBonus) {
                Chat.addMonsterAction(monObj, ignoreInfo?null:cInfo, character, displayName, href, hitBonus, damages, altEffect?.save, altEffect?.saveDC," ",null,null,effects);
            } else {
                Chat.addMonsterDamageRoll(monObj, ignoreInfo?null:cInfo, character, displayName, href, damages, null, altEffect?.save, altEffect?.saveDC);
            }
        }
    }

    handleEffectsOptions(cid,wa) {
        const cInfo = this.combatants.getCombatant(cid);
        if (cInfo) {
            const crow = new Combatant(cInfo);

            const {monObj,characterObj} = crow;

            if (((cInfo.ctype=="pc") || cInfo.type)&&characterObj) {
                console.log("click effect", cInfo, crow, wa);
                if (wa.type == "attack") {
                    Chat.addAction(characterObj, wa.actionName, wa.href, wa.attackRoll, wa.damages, wa.saveAbility, wa.saveVal,  wa.attackRoll?"Attack":"Spell", wa.conditions, null, wa.altDamages, true);
                } else if (wa.type=="damage") {
                    Chat.addCharacterDamageRoll(characterObj, wa.actionName, wa.href, wa.damages, null, null, wa.saveAbility, wa.saveVal);
                }
            } else if (monObj) {
                console.log("need code to handle monsters", cInfo, wa)
            }
        } else {
            console.log("could not find source")
        }
    }

    closeAddCondition(conditionInfo) {
        if (conditionInfo) {
            this.combatants.addConditionSelected(conditionInfo);
        }
        this.showToolMenu(null);
    }

    hideAdjust() {
        this.setState({showAdjust:false});
    }

    showAdjust(hp) {
        console.log("show adjust popup", hp)
        this.setState({showAdjust:true, showAdjustHP:hp});
    }

    onAdjustChange(value,adjust) {
        this.adjustHP(adjust);
        this.setState({showAdjust:false});
    }

    adjustHP(adjust) {
        if (adjust) {
            let description;

            //console.log("adjust hp", adjust);
            if (adjust < 0) {
                description = "Damage "+(-adjust);
            } else {
                description = "Heal "+adjust;
            }
            if (adjust > -100) {
                campaign.addMRUList("damageMRU", {description:description, adjust});
            }
            
            this.combatants.adjustSelectedHP(adjust);
        }
        this.setState({showAdjust:false});
    }

    getTokenInfoOptions(c, cInfo,isCombatToken) {
        const {ctype} = cInfo;
        const list=[];
        
        list.push({displayName:c.displayName,centered:true,separated:true,noClick:true});

        if (ctype != "object" || (cInfo.custom && cInfo.customId)) {
            list.push({name:"View Details",cInfo});
        }

        if (isCombatToken) {
            if (cInfo.type) { 
                //monster
                list.push({name:cInfo.friendly?"Set as Foe":"Set as Friend"});
                list.push({name:cInfo.hideName?"Show Name":"Hide Name"});
            }
            if ((ctype != "object") && c.editInline) {
                list.push({name:"Rename",dn:cInfo.name});
            }

            list.push({name:((cInfo.state||"active")!="active")?"Set Active":"Set Inactive"});
        }
        if (ctype=="object") {
            list.push({name:"Edit",cInfo});
        }
        list.push({name:"Remove From Map"});

        return list;
    }

    getGroupTokenInfoOptions(selected) {
        const nameList=[];
        let noDelete,
        someShown, 
        someNonCombatants, 
        allMonsters=true,
        allFoes=true,
        allHideName=true,
        allActive=true;

        for (let id in selected) {
            const cInfo = this.combatants.getCombatant(id);
            if (cInfo) {
                const crow = new Combatant(cInfo);
                const {ctype} = cInfo;
                const isCombatToken = ((ctype != "object") || cInfo.includeInCombat);

                const dn = crow.displayName;
                if (dn && !nameList.includes(dn)) {
                    nameList.push(dn);
                }

                if (isCombatToken) {
                    if (!cInfo.type) { 
                        allMonsters=false;
                    }
                    if (cInfo.friendly) {
                        allFoes=false;
                    }
                    if (!cInfo.hideName) {
                        allHideName=false;
                    }
                    if (((cInfo.state||"active")!="active")) {
                        allActive=false;
                    }
                } else{
                    someNonCombatants = true;
                }

                if (!["object", "cmonster"].includes(ctype)) {
                    noDelete = true;
                }
                if (!cInfo.hidden) {
                    someShown = true;
                }
            }
        }

        const list=[];
        let names = nameList.join(", ");
        if (names.length >30) {
            names = names.substr(0,30)+"...";
        }
        
        list.push({displayName:names,centered:true,separated:true,noClick:true});

        if (!someNonCombatants) {
            if (allMonsters) {
                list.push({name:allFoes?"Set as Friend":"Set as Foe"});
                list.push({name:allHideName?"Show Name":"Hide Name"});
            }

            list.push({name:allActive?"Set Inactive":"Set Active"});
        }
        list.push({name:"Remove From Map"});

        return {noDelete,someShown, someNonCombatants,list,names};
    }

    handleTokenInfoTools(val) {
        switch (val.name) {
            case "View Details":
                this.showCombatant(val.cInfo.id);
                break;
            case "Add Condition":
                break;
            case "Set as Foe":
                this.combatants.updateSelected("friendly",false);
                break;
            case "Set as Friend":
                this.combatants.updateSelected("friendly",true);
                break;
            case "Show Name":
                this.combatants.updateSelected("hideName",false);
                break;
            case "Hide Name":
                this.combatants.updateSelected("hideName",true);
                break;
            case "Rename":
                this.setEditName(val.dn);
                break;
            case "Set Active":
                this.combatants.updateSelected("state","active");
                break;
            case "Set Inactive":
                this.combatants.updateSelected("state","inactive");
                break;
            case "Edit":
                this.showEditObject(val.cInfo);
                break;
            case "Remove From Map":
                this.combatants.updateSelected("tokenMap",null);
                this.clearSelection();
                break;
        }
        this.selectChange();
    }

    setEditName(editName) {
        this.setState({editName})
    }

    changeName(editName) {
        if (editName) {
            this.combatants.updateSelected("name",editName);
        }
        this.setState({editName:null})
    }

    showCombatant(showCid) {
        this.setState({showCid});
    }

    getPinTools(tools) {
        const {onAddEncounter,onGoToBook,onGoToMap,encounter} = this.props;

        const pin = campaign.getPinInfo(this.map.selectedPin);
        if (pin) {
            const hasEncounters = getPinEncounters(pin);
            const hasBooks = getPinBooks(pin);
            const hasMaps = getPinMaps(pin);
            
            if (hasEncounters && !((hasEncounters.length==1) && (hasEncounters[0].name==encounter))) {
                tools.push({
                    tool:"fas fa-dragon",
                    toolTip:onAddEncounter?"Start Encounter":"View Encounter",
                    onClick:onAddEncounter?this.menuStartEncounter.bind(this):this.menuGoToEncounter.bind(this, pin),
                    list:hasEncounters
                });
            }

            if (hasBooks && onGoToBook) {
                tools.push({
                    tool:"fas fa-book-open",
                    toolTip:"Go to Book",
                    onClick:this.goToBook.bind(this),
                    list:hasBooks
                });
            }

            if (hasMaps && onGoToMap) {
                tools.push({
                    tool:"fas fa-map-marked-alt",
                    toolTip:"Map",
                    onClick:this.goToMap.bind(this),
                    list:hasMaps
                });
            }

            tools.push({
                tool:"fas fa-signal",
                toolTip:"Size",
                onClick:this.setPinValue.bind(this,pin,"scale"),
                list:pinScaleValues,
                selectedVal:findValue(pinScaleValues,pin.scale||"none")
            });
            tools.push({
                tool:"fas fa-star",
                toolTip:"Marker",
                onClick:this.setPinValue.bind(this,pin,"marker"),
                list:pinMarkerValues,
                selectedVal:findValue(pinMarkerValues, pin.marker||"am")
            });
            tools.push({
                tool:pin.showPlayers?"fas fa-eye":"fas fa-eye-slash",
                toolTip:pin.showPlayers?"Hide Pin":"Show Pin",
                onClick:this.setPinValue.bind(this,pin,"showPlayers",{value:!pin.showPlayers})
            });
            tools.push({
                tool:"fas fa-edit",
                toolTip:"Edit",
                onClick:this.showEditPin.bind(this,pin)
            });
        }
    }

    getRegionTools(tools) {
        tools.push({
            toolTip:"Delete",
            onClick:this.map.deleteSelectedRegion.bind(this.map),
            deletePrompt:"region",
            delete:true
        });
    }

    getDrawSelTools(tools) {
        tools.push({
            tool:"fas fa-trash",
            toolTip:"Delete",
            onClick:this.map.deleteSelectedDrawing.bind(this.map),
        });
    }

    setMode(mode) {
        if ((mode == this.map.mode) && ["region","draw"].includes(mode)) {
            mode = "move";
        }
        this.map.setMode(mode);
    }

    setFogTool(tool) {
        this.map.setFogTool(tool);
    }

    setFogDiameter(v) {
        if (v.value == "none") {
            this.setMode("move")
        } else {
            this.map.fogDiameter = v.value;
            this.map.setFogTool("brush");
        }
    }

    doFogTool(t) {
        if (t.value) {
            return this.map.setFogOpacity(t.value);
        }
        switch (t.name) {
            case "Reveal All":
                this.map.clearFog();
                break;
            case "Cover All":
                this.map.fogAll();
                break;
            case "Build Fog Regions":
                this.setMode("region");
                break;
        }
    }

    clearSelection() {
        this.map.clearSelection();
    }

    hideHandout() {
        if (!this.props.playerView) {
            const handouts = Object.assign({}, campaign.getHandouts());
            handouts.showHandout = false;
            campaign.updateCampaignContent("adventure", handouts);
        }
        this.setState({handout:null});
    }

    saveRef(ref) {
        if (ref) {
            this.contentRef = ref;
            if (this.map) {
                this.map.container = ref;
            }
        }
    }

    saveFocus(ref) {
        if (ref) {
            this.focusRef = ref;
        }
    }

    tapCombatant(cInfo, event) {
        const {onTapCombatant,onClickCombatant} = this.props;
        if (onTapCombatant) {
            onTapCombatant(cInfo.id, cInfo.index, event);
        }
        if (onClickCombatant) {
            onClickCombatant(cInfo);
        }
    }

    selectChange() {
        const {setSelection,selected}=this.props;
        if (this.combatants) {
            const csel = this.combatants.getSelection();
            if (setSelection && !areSameDeep(selected||{}, csel)) {
                setSelection(csel);
            }
        }
        this.setState({change:campaign.newUid()});
    }

    popupChanged() {
        this.setState({change:campaign.newUid()});
    }

    changeMapPos(mapPos) {
        if (this.map.campaignMode && !this.map.playerView) {
            const {name} = this.map.mapInfo;
            const lname = name?name.toLowerCase():name;

            const newExtra=Object.assign({},campaign.getMapExtraInfo(name)||{name:lname});
            newExtra.mapPos = mapPos;            
            campaign.updateCampaignContent("mapextra", newExtra);
            if ((campaign.getPrefs().selectedMap||"").toLowerCase() != lname) {
                campaign.setPrefs({selectedMap:lname});
                if (lname) {
                    campaign.addMRUList("mruMaps", {description:this.map.mapInfo.displayName, mapName:lname});
                }
            }

            this.updateSharedCampaign();
        }
    }

    changeMapExtra() {
        if (this.map.campaignMode && !this.map.playerView) {
            this.updateSharedCampaign();
        }
    }
    
    updateSharedCampaign() {
        if (!this.updateShared) {
            const t=this;
            this.updateShared=setTimeout(function (){
                updateSharedCampaign();
                t.updateShared = null;
            },200);
        }
    }
    

    showColorPicker(showColor, e) {
        this.setState({showColor, anchorEl:e&&e.target});
    }

    getColorPicker() {
        const {showColor, anchorEl} = this.state;
        if (!showColor) {
            return;
        }
        const list = [];
        let sublist=[];
        for (let c of colorDrawList) {
            sublist.push(<ColorToken className="pa1" onClick={this.setDrawColor.bind(this,c)} key={c} color={c}/>);
            if (sublist.length==4) {
                list.push(<div key={list.length}>{sublist}</div>);
                sublist=[];
            }
        }

        return <Popover 
            open 
            anchorOrigin={{vertical: 'bottom',horizontal: 'center',}}
            transformOrigin={{vertical: 'top',horizontal: 'center',}} 
            anchorEl={anchorEl} 
            onClose={this.showColorPicker.bind(this,null)}
        >
            <Paper className="darkT darkBackground">
                <div className="pa2">
                    {list}
                    <div>{sublist}</div>
                </div>
            </Paper>
        </Popover>;
    }

    setDrawColor(color) {
        switch (this.state.showColor) {
            case "draw":
                campaign.setPrefs({drawColor:color});
                this.map.setDrawColor(color);
                break;
            case "object":
                campaign.setPrefs({objectColor:color});
                this.combatants.updateSelected("fill", color);
                break;
        }
        this.showColorPicker(null);
    }

    drawUndo(undo) {
        if (undo) {
            this.map.undoDrawing();
        } else {
            this.map.redoDrawing();
        }
    }

    coverUndo(undo) {
        if (undo) {
            this.map.undoCover();
        } else {
            this.map.redoCover();
        }
    }

    showZoom(showZoom,evt) {
        this.setState({showZoom, anchorEl:evt&&evt.target});
    }

    zoomKey(zoomin) {
        const {diameter} = this.map.mapPos;
        const mapDimensions = this.map.mapDimensions;
        const maxDim = Math.max(mapDimensions.width, mapDimensions.height)
        const logValue = Math.log(maxDim/diameter)/Math.log(1.1);

        this.setZoom(null, logValue +(zoomin?1:-1));
    }

    getZoom(scale, onScale) {
        const {showZoom,anchorEl} = this.state;
        if (!showZoom) {
            return null;
        }

        const {diameter} = this.map.mapPos;
        const mapDimensions = this.map.mapDimensions;
        const maxDim = Math.max(mapDimensions.width, mapDimensions.height)
        const logValue = Math.log(maxDim/diameter)/Math.log(1.1);

        return <Popover 
            open 
            anchorOrigin={{vertical: 'bottom',horizontal: 'center',}}
            transformOrigin={{vertical: 'top',horizontal: 'center',}} 
            anchorEl={anchorEl} 
            onClose={this.showZoom.bind(this,false)}
        >
            <Paper className="darkT darkBackground">
                <div className="tc titlecolor hoverhighlight" onClick={this.setZoom.bind(this,null, logValue+1)}><span className="far fa-plus-square f2 pa1"/></div>
                <div className="h6 flex mv1">
                    <div className="flex-auto"/>
                    <Slider classes={{root:"ph2"}} orientation="vertical" value={logValue} min={-10} max={30} step={1} onChange={this.setZoom.bind(this)}/>
                    <div className="flex-auto"/>
                </div>
                <div className="tc titlecolor hoverhighlight" onClick={this.setZoom.bind(this,null, logValue-1)}><span className="far fa-minus-square f2 pa1"/></div>
                <div className="tc titlecolor pa1 hoverhighlight f3" onClick={this.setZoom.bind(this,null, 1)}>all</div>
            </Paper>
        </Popover>;
    }

    setZoom(e,logScale) {
        const mapPos = Object.assign({}, this.map.mapPos);

        const {diameter} = mapPos;
        const mapDimensions = this.map.mapDimensions;
        const maxDim = Math.max(mapDimensions.width, mapDimensions.height)
        const mult = Math.pow(1.1, logScale);
        mapPos.diameter = maxDim/mult;
        if (logScale == 1) {
            mapPos.x = mapDimensions.width/2;
            mapPos.y = mapDimensions.height/2;
        }
        this.map.setMap(this.map.mapInfo.name, mapPos,true);
    }

    setPinValue(pin,field, set) {
        const op = campaign.getPinInfo(pin.name);
        if (op) {
            const p = Object.assign({}, op);
            p[field] = set.value;
            campaign.updateCampaignContent("pins", p);
        }
    }

    menuGoToEncounter(pin, encounter) {
        this.onGoToEncounter(encounter.name, pin.mapPos);
    }
    
    menuStartEncounter(encounter) {
        const cur = this.map.getCurrentCombatantIds();
        this.props.onAddEncounter(encounter.name);
        this.combatants.doUpdate(true);
        this.map.selectDiffCombatants(cur);
    }
    
    goToBook(pl) {
        this.props.onGoToBook(pl.book, pl.chapter, pl.section, pl.subsection);
    }

    goToMap(map) {
        this.props.onGoToMap({mapName:map.name});
    }

    getDefaultBookName() {
        const {bookname, chapter, section, subsection} = this.props;
        if (bookname) {
            return getDefaultBookName(bookname, chapter, section, subsection);
        }
        return "";
    }

    showMonsterPicker(showMonsterPicker) {
        this.setState({showMonsterPicker});
    }
    getMonsterPickerExtraButtons() {
        return <CheckVal labelClass="titlecolor f7" label="SELECT TOKENS" value={this.state.showPickMonsterTokens} onChange={this.setShowPickMonsterTokens.bind(this)}/>
    }

    setShowPickMonsterTokens(val) {
        this.setState({showPickMonsterTokens:val});
    }

    hideMonsterPicker(selList) {
        const {showPickMonsterTokens} = this.state;
        if (selList) {
            if (showPickMonsterTokens) {
                this.setState({pickMonsterTokens:selList});
            } else {
                const cur = this.map.getCurrentCombatantIds();
                this.combatants.addMonsters(selList, this.map.getSelectedMapPos(), null, true);
                this.map.selectDiffCombatants(cur);
            }
        }
        this.setState({showMonsterPicker:false,showPickMonsterTokens:false});
    }

    closePickMonsterTokens(monsterTokens) {
        if (monsterTokens) {
            const cur = this.map.getCurrentCombatantIds();
            this.combatants.addMonsters(this.state.pickMonsterTokens, this.map.getSelectedMapPos(), monsterTokens, true);
            this.map.selectDiffCombatants(cur);
        }
        this.setState({pickMonsterTokens:null});
    }

    handleEditObject(ao) {
        if (ao && this.combatants) {
            if (!ao.id) {
                // this must be a new map object need to set values
                const mapPos = this.map.getSelectedMapPos();
                const {name,sname} = getDefaultNames(ao);
                campaign.addMRUList("mruObjects", {description:name, object:Object.assign({},ao)});

                ao.id = campaign.newUid();
                if (!ao.name) {
                    ao.name = sname;
                }
                ao.tokenX = mapPos.x;
                ao.tokenY = mapPos.y;
                ao.tokenMap = mapPos.mapName;
                this.map.selectCombatants([ao.id]);
            }
            this.combatants.setCombatant(ao);
        }
        this.setState({showEditObject:false});
    }

    getAddObjectTool(tools) {
        const mruList = campaign.getMRUList("mruObjects");
        const objectColor = campaign.getPrefs().objectColor||"#ffffff";
        const mList=[//{name:"Custom Map Object",separated:true},
            {displayName:"Cone", object:{ctype:"object", otype:"cone", fill:objectColor, width:15}},
            {displayName:"Circle", object:{ctype:"object", otype:"circle", fill:objectColor, diameter:5}},
            {displayName:"Cube", object:{ctype:"object", otype:"cube", fill:objectColor, width:10}},
            {displayName:"Line", object:{ctype:"object", otype:"line", fill:objectColor, width:15}},
            {displayName:"Rectangle", object:{ctype:"object", otype:"rectangle", fill:objectColor, width:15, height:10}},
        ];

        if (mruList && mruList.length) {
            for (let i in mruList) {
                const m=mruList[i];
                if ((mList.length < 10) && (!m.object.tokenArt || campaign.getArtInfo(m.object.tokenArt))) {
                    mList.push({displayName:m.description, object:m.object});
                }
            }
        }

        tools.push({
            tool:"fas fa-shapes",
            toolTip:"Add Shape",
            list:mList,
            onClick:this.handleNewMapObject.bind(this)
        });
    }

    selectObject(cInfo) {
        this.selectId(cInfo.id);
    }

    selectId(id) {
        this.clearSelection()
        this.combatants.select([id]);
    }

    handleNewMapObject(val) {
        if (val.name == "Custom Map Object") {
            return this.showNewMapObject();
        }
        const ao = Object.assign({},val.object);
        delete ao.id;
        this.handleEditObject(ao);
    }

    showNewMapObject(otype) {
        const editObject={ctype:"object", otype:otype||"circle", fill:"#ffffff", diameter:5, width:5}
        this.setState({showEditObject:true, editObject})
    }

    getShowMeasureDistanceList() {
        return this.map.measureDistance?[{name:"Hide", radius:0}].concat(measureDistanceList):measureDistanceList;
    }

    showMeasureDistance(val) {
        this.map.showMeasureDistance(val.radius);
    }
}

const fogToolList = [
    {name:"Reveal All"},
    {name:"Cover All"},
    {name:"Fog Cover 25%", value:0.25},
    {name:"Fog Cover 50%", value:0.5},
    {name:"Fog Cover 75%", value:0.75},
    {name:"Fog Cover 100%", value:1},
    {name:"Build Fog Regions"},
]

const measureDistanceList = [
    {name:"15 ft", radius:15},
    {name:"30 ft", radius:30},
    {name:"45 ft", radius:45},
    {name:"60 ft", radius:60},
    {name:"90 ft", radius:90},
    {name:"120 ft", radius:120},
];

const groupToolList = [
    {name:"Huddle Monsters"},
    {name:"Huddle Characters"},
    {name:"Select All Monsters"},
    {name:"Select All Characters"},
];


const addToolList = [
    {name:"Add Monsters"},
    {name:"Add Map Object"},
    {name:"New Pin"},
    {name:"New Encounter"},
    {name:"Add Image"},
];

class Map {
    constructor(options) {

        this.events = new EventEmitter();

        const stage = new Konva.Stage({
            container: options.container,
            width:options.width||1,
            height:options.height||1,
            name:"stage"
        });
        stage.stage=true;

        this.width = options.width||1;
        this.height = options.height||1;

        this.showName = options.showName;
        this.playerView = options.playerView;
        this.campaignMode = options.campaignMode;
        this.variant = options.variant;
        this.mode = options.mode;
        this.eventSync = options.eventSync;

        this.backgroundLayer = new Konva.Layer();
        stage.add(this.backgroundLayer);

        this.mapGroup = new Konva.Group();
        this.backgroundLayer.add(this.mapGroup);
        this.gridGroup = new Konva.Group();
        this.backgroundLayer.add(this.gridGroup);
        if (["edit", "encounter", "gm"].includes(this.variant)) {
            this.fogHitGroup = new Konva.Group();
            this.backgroundLayer.add(this.fogHitGroup);
        }   

        this.pinGroup = new Konva.Group();
        this.backgroundLayer.add(this.pinGroup);

        this.drawLayer = new Konva.Layer({listening:false});
        stage.add(this.drawLayer);

        this.tokenLayer = new Konva.Layer();
        stage.add(this.tokenLayer);

        if (options.showCover) {
            this.showCover = true;
            const opacity = this.playerView?1:campaign.getUserSettings().fogDensity||0.5;

            this.coverLayer = new Konva.Layer({opacity, listening:false});
            stage.add(this.coverLayer);

            this.coverGroup = new Konva.Group();
            this.coverLayer.add(this.coverGroup);
        }

        this.topLayer = new Konva.Layer();
        stage.add(this.topLayer);
        if (!["popout","inline"].includes(this.variant)) {
            stage.on("mousedown touchstart", this.onMouseDown.bind(this));
            stage.on("mouseup touchend", this.onMouseUp.bind(this));
            stage.on("mousemove touchmove", this.onMouseMove.bind(this));
            stage.on("wheel", this.onWheel.bind(this))

            stage.on("dragmove", this.onDragMove.bind(this));
            stage.on("dragend", this.onDragEnd.bind(this));
            stage.on("mouseenter", this.onMouseEnter.bind(this));
            stage.on("mouseleave", this.onMouseLeave.bind(this));
        } else {
            stage.listening(false);
        }

        if (this.eventSync) {
            this.hoverChangeFn = this.hoverChange.bind(this);
            this.eventSync.addListener("listToken", this.hoverChangeFn);
        }

        this.pingGroup = new Konva.Group({listening:false});
        this.topLayer.add(this.pingGroup);
        if (this.variant == "player") {
            this.topTokens = new Konva.Group();
            this.topLayer.add(this.topTokens);
        }

        this.stage = stage;

        this.onMapUpdateFn = this.onMapUpdate.bind(this);
        globalDataListener.onChangeCampaignContent(this.onMapUpdateFn, "maps");
        globalDataListener.onChangeCampaignContent(this.onMapUpdateFn, "mapextra");

        this.onArtUpdateFn = this.onArtUpdate.bind(this);
        globalDataListener.onChangeCampaignContent(this.onArtUpdateFn, "art");

        this.onPinsChangeFn = this.updatePins.bind(this);
        globalDataListener.onChangeCampaignContent(this.onPinsChangeFn, "pins");

        if (this.campaignMode) {
            this.onAdventureUpdateFn = this.onAdventureUpdate.bind(this);
            globalDataListener.onChangeCampaignContent(this.onAdventureUpdateFn, "adventureview");
            globalDataListener.onChangeCampaignContent(this.onAdventureUpdateFn, "adventure");
            globalDataListener.onChangeCampaignSettings(this.onAdventureUpdateFn);
        }
        this.pingProgress = {};
        this.loadInitialState();
        this.fogDiameter = 5;
        this.drawColor = campaign.getPrefs().drawColor||'#0062b1';
        this.drawUndo=[];
        this.drawRedo=[];
        this.coverUndo=[];
        this.coverRedo=[];
        this.localPingList=[];
    }

    destroy() {
        if (this.loadingAnim) {
            this.loadingAnim.stop();
        }
        this.stage.destroy();
        this.backgroundLayer = null;
        this.tokenLayer = null;
        this.topLayer = null;
        this.stage = null;

        this.combatants=null;
        this.combatantMapDetails=null;

        globalDataListener.removeCampaignContentListener(this.onMapUpdateFn, "maps");
        globalDataListener.removeCampaignContentListener(this.onMapUpdateFn, "mapextra");

        globalDataListener.removeCampaignContentListener(this.onArtUpdateFn, "art");

        globalDataListener.removeCampaignContentListener(this.onPinsChangeFn, "pins");

        if (this.campaignMode) {
            globalDataListener.removeCampaignContentListener(this.onAdventureUpdateFn, "adventureview");
            globalDataListener.removeCampaignContentListener(this.onAdventureUpdateFn, "adventure");
            globalDataListener.removeCampaignSettingsListener(this.onAdventureUpdateFn);
        }

        if (this.eventSync) {
            this.eventSync.removeListener("listToken", this.hoverChangeFn);
        }


        if (this.pingtimer) {
            clearInterval(this.pingtimer);
            this.pingtimer=null;
        }
        this.cancelPingCheck();

        this.events.removeAllListeners();
        this.events=null;
    }

    freezeUpdates() {
        if (!this.pending) {
            this.pending={x:1};
        }
    }

    unfreezeUpdates() {
        if (this.pending) {
            const pending = this.pending;
            this.pending = null;
            if (pending.mapUpdate) {
                this.onMapUpdate();
            }
            if (pending.artUpdate) {
                this.onArtUpdate();
            }
            if (pending.pinUpdate) {
                this.updatePins();
            }
            if (pending.adventureUpdate) {
                this.onAdventureUpdate();
            }
        }
    }


    loadInitialState() {
        if (this.campaignMode) {
            this.onAdventureUpdate();
        }
    }

    get fogOpacity() {
        return this.coverLayer.opacity();
    }

    setFogOpacity(o) {
        this.coverLayer.opacity(o)
        campaign.updateUserSettings({fogDensity:o});
    }

    setMode(mode) {
        if ((this.mode!=mode) && ["fog"].includes(this.mode)) {
            this.pointerHighlight && this.pointerHighlight.destroy();
            this.pointerHighlight=null;
            this.clearSelection();
            this.cancelFog();
        }
        if (["fog","region","draw"].includes(mode) || ["fog","region","draw"].includes(this.mode)) {
            if (this.mode!=mode) {
                this.clearUndo();
                this.clearSelection();
                this.cancelFog();
            }
            this.fogTool=null;
        }
        this.pinGroup.listening(!["region"].includes(mode));
        this.tokenLayer.listening(!["region"].includes(mode));
        if (this.showCover) {
            this.coverLayer.visible(!["region"].includes(mode));
        }

        this.drawLayer.listening(mode=="draw");
        this.mode = mode;
        this.updateSelection();
        this.updateCover();
    }

    setFogTool(tool) {
        let needUpdate;

        if ((tool == this.fogTool) && ["rect","polygon"].includes(tool)) {
            if (this.mode == "fog") {
                this.setMode("move");
                return;
            } else if (this.mode == "region") {
                tool = null;
            }
        }

        if (this.mode != "region") {
            this.setMode("fog");
        }

        this.pointerHighlight && this.pointerHighlight.destroy();
        this.pointerHighlight=null;
        this.fogTool = tool;
        switch (tool) {
            case "brush":

                this.pointerHighlight = new Konva.Circle({
                    radius:this.fogDiameter/2,
                    position:this.getPointerPosition(),
                    strokeScaleEnabled:false,
                    shadowBlur:5,
                    stroke:"red",
                    listening:false,
                    visible:false
                });
                this.topLayer.add(this.pointerHighlight);
                break;
        }
        this.cancelFog();
        this.clearSelection();
    }

    setEncounterCombatants(ec) {
        this.combatants = ec;
        this.updateCombatants();
        ec.eventSync.on("change", this.updateCombatants.bind(this));
    }

    onAdventureUpdate() {
        this.startPingUpdate();
        if (this.pending) {
            this.pending.adventureUpdate = true;
            return;
        }
        const curMap = this.mapName;
        if (this.playerView) {
            const av = campaign.getAdventureView();
            //console.log("adventure change", av);
            if ((av.imageName != curMap) || (this.cversion != av.cversion) || (this.variant == "popout")) {
                this.setMap(av.imageName,av.mapPos);
                this.cversion = av.cversion;
            }
            this.setShowGrid(av.grid);
        } else {
            const {selectedMap,grid} = campaign.getPrefs();
            if (selectedMap && selectedMap != curMap) {
                this.setMap(selectedMap);
            }
            this.setShowGrid(grid);
        }
        this.updateCombatants();
    }

    setMap(name, mapPos, force) {
        //console.log("set map",name, mapPos);
        const newMap = (name != this.mapInfo?.name);

        if (name && (force || newMap)) {
            let mapInfo = campaign.getMapInfo(name);
            if (newMap) {
                this.lastMapPos=null;
                this.mapPos=null;
                this.altImageOverride=null;
            }
            //console.log("get mapInfo", mapInfo);
            if (mapInfo) {
                const art = campaign.getArtInfo(mapInfo.art)||mapInfo;
                //console.log("get art", art);
                let pixels = 70;
                let gridSize = 5;
                let pixelMult=1;
                
                if (art.imgWidth && art.imgHeight) {
                    if (mapInfo.pixelsPerGrid)
                        pixels = Number(mapInfo.pixelsPerGrid);

                    if (mapInfo.gridSize) {
                        gridSize = Number(mapInfo.gridSize);
                    }

                    if (mapInfo.units == "miles") {
                        gridSize = gridSize*5280;
                    }

                    if (art.originalWidth && (art.originalWidth != art.imgWidth)) {
                        pixelMult = art.imgWidth/art.originalWidth;
                    }
                
                    pixels = pixels*pixelMult;
                    const mult = gridSize/pixels;

                    this.pixelsPerFoot = 1/mult;
                    this.mapDimensions = {width:art.imgWidth*mult, height:art.imgHeight*mult};
                    this.gridSizeInFeet = gridSize;
                    this.gridxShift = (mapInfo.gridxShift||0)*mult*pixelMult;
                    this.gridyShift = (mapInfo.gridyShift||0)*mult*pixelMult;
                    this.mapInfo=mapInfo;
                    this.mapextra=campaign.isCampaignGame()?campaign.getMapExtraInfo(name):null;
                    let useArt=art;
                    let altImage = this.altImageOverride || this.mapextra?.altImage;
                    if (altImage && (mapInfo.artList||[]).includes(altImage)) {
                        useArt = campaign.getArtInfo(altImage) || art;
                    }

                    if (useArt.url != this.mapUrl) {
                        this.mapUrl=null;
                        this.loadMap(useArt.url);
                    }
                    this.updateCover();
                    this.updateDrawing();
                    this.updateCombatants();
                    this.updatePins();
                } else {
                    this.resetMap();
                }
            } else {
                this.resetMap();
            }
            if (newMap) {
                this.clearUndo();
                this.clearSelection();
            }
        }
        if (mapPos && (mapPos.x||mapPos.y||mapPos.diameter)) {
            this.mapPos=mapPos;
        } else if (newMap && this.mapextra&&(!["inline","encounter"].includes(this.variant))) {
            this.mapPos = Object.assign({mapName:name}, this.mapextra.mapPos||{})
        }
        this.updateStageZoom();
    }

    setAltImage(altImageOverride) {
        this.altImageOverride = altImageOverride;
        this.setMap(this.mapInfo.name, this.mapPos,true);
    }

    get mapInfoName() {
        return this.mapInfo?.name;
    }

    onMapUpdate() {
        if (this.pending) {
            this.pending.mapUpdate=true;
            return;
        }
        
        if (this.mapInfo?.name) {
            let mapInfo = campaign.getMapInfo(this.mapInfo.name);
            if (mapInfo) {
                const mapextra=campaign.isCampaignGame()?campaign.getMapExtraInfo(mapInfo.name):null;
                if (!areSameDeep(mapInfo, this.mapInfo) || !areSameDeep(mapextra, this.mapextra)) {
                    //console.log("map updated", areSameDeep(mapInfo, this.mapInfo), !areSameDeep(mapextra, this.mapextra));
                    this.setMap(this.mapInfo.name, null, true);
                }
            } else {
                this.resetMap();
            }
        }
    }

    async loadMap(url) {
        const name = this.mapName;
        this.incrementLoading();
        let {width, height} = this.mapDimensions||{};
        this.mapGroup.destroyChildren();
        try {
            this.loadError = null;
            this.mapUrl = url;
            //await sleep(3000);
            const imageObj = await imageCache.loadImage(url);
            if (name == this.mapName) {
                let image = new Konva.Image({image: imageObj, x:0,y:0, width, height});
                this.mapGroup.add(image);
            }
        } catch (err) {
            console.log("error loading map", err);
            let fillScale = width/100;
            this.loadError = err;
            this.mapUrl = null;

            let image = new Konva.Rect({x:0, y:0, width, height, fillPatternImage:this.getErrorFill(), fillPatternScale:{x:fillScale, y:fillScale}, fillPatternRepeat:"repeat"});
            this.mapGroup.add(image);
        }
        this.decrementLoading();
    }

    onArtUpdate() {
        if (this.pending) {
            this.pending.artUpdate = true;
            return;
        }
        if (this.mapInfo?.name) {
            let mapInfo = campaign.getMapInfo(this.mapInfo.name);
            if (mapInfo) {
                this.setMap(this.mapInfo.name, null, true);
            } else {
                this.resetMap();
            }
        }
    }

    destroyName(name) {
        if (this.stage) {
            const node = this.stage.findOne(name);
            node && node.destroy();
        }
    }

    get mapName() {
        return this.mapInfo?.name;
    }

    setMapPos(mapPos) {
        if (mapPos) {
            if (mapPos.mapName) {
                this.setMap(mapPos.mapName,mapPos)
            } else {
                this.mapPos = mapPos;
                //console.log("set map pos", mapPos);
                this.updateStageZoom();
            }
        }
    }

    updateStageZoom() {
        if (this.mapInfo && this.stage) {
            this.updateSelection();
            if (!this.mapPos || isNaN(this.mapPos.diameter) || isNaN(this.mapPos.x) || isNaN(this.mapPos.y)) {
                this.mapPos = this.showAllMap();
            }
            let {diameter, x, y} = this.mapPos||{};
            let viewWidth = this.width, viewHeight = this.height;
            let scale = Math.min(viewWidth, viewHeight)/diameter;
            let newX = viewWidth/2-x*scale, newY=viewHeight/2-y*scale;

            this.stage.scale({x:scale, y:scale});

            this.stage.position({x:newX, y:newY});
            this.scale = scale;

            let viewPort = {left:-newX/scale, top:-newY/scale};
            viewPort.right=viewPort.left+viewWidth/scale;
            viewPort.bottom=viewPort.top+viewHeight/scale;
            this.viewPort = viewPort;

            let loading = this.stage.findOne(".loading");
            if (loading) {
                loading.scale({x:1/scale, y:1/scale});
                loading.x(x+((viewWidth/2)-25)/scale);
                loading.y(y-((viewHeight/2)-25)/scale);
                //console.log("scale", viewWidth, viewHeight, viewWidth/scale, viewHeight/scale,this.loading.x(),this.loading.y(), this.stage.x(),this.stage.y(), this.mapPos);
            }
            this.genGridLines();
            if (this.selectedRegion) {
                this.updateCover(true);
            }

            this.checkChangeMapPos();
            this.redrawSelected();
        }
    }

    checkChangeMapPos() {
        if (!areSameDeep(this.lastMapPos, this.mapPos) && !this.dragging) {
            this.events.emit("changeMapPos", this.mapPos);
            this.lastMapPos = Object.assign({},this.mapPos);
        }
    }

    showAllMap() {
        let {width=10,height=10} = this.mapDimensions||{};
        let viewWidth = this.width, viewHeight = this.height;
        let mapPos={x:width/2, y:height/2, mapName:this.mapInfo.name};

        if (this.variant == "inline") {
            mapPos.diameter = Math.min(width,height);
        } else if (width/height > viewWidth/viewHeight) {
            // too wide relative to view
            mapPos.diameter=width;
        } else {
            // too tall to view
            mapPos.diameter = height;
        }
        return mapPos;
    }

    resetMap(){
        this.mapInfo=null;
        this.mapextra=null;
        this.mapUrl=null;
        if (this.combatants) {
            this.combatants.destroy();
            this.combatants=null;
        }
        this.combatantMapDetails=null;
        this.pins=null;
        this.mapGroup && this.mapGroup.destroyChildren();
        this.gridGroup && this.gridGroup.destroyChildren();
        this.fogHitGroup && this.fogHitGroup.destroyChildren();
        this.coverRegions = null;
        this.pinGroup && this.pinGroup.destroyChildren();

        this.drawLayer && this.drawLayer.destroyChildren();
        this.drawObjs=null;
        this.tokenLayer && this.tokenLayer.destroyChildren();
        this.topTokens=null;
        if (this.coverLayer) {
            this.coverObjs=null;
            this.coverGroup.destroyChildren();
        }
        this.pingProgress = {};
        this.topLayer && this.topLayer.destroyChildren();
        this.hoverRegion=null;
    }

    set container(container) {
        this.stage.container(container);
    }

    setDimensions(width, height) {
        this.height = height||10;
        this.width = width||10;
        this.stage.width(this.width);
        this.stage.height(this.height);
        this.updateStageZoom()
    }

    getCombatantDraggable(c) {
        switch (this.variant) {
            case "player":
                if (c.canMove) {
                    return true;
                }
                break;
            case "encounter":
                return true;
            case "gm":
                if ((c.ctype == "object") && !c.showAsToken && !c.actionInfo) {
                    return false
                }
                return true;
            }
        return false;
    }

    getStageDraggable(e) {
        switch (this.variant) {
            case "player":
                break;
        }

        if ((e.evt.buttons==2||e.evt.ctrlKey)) {
            return true;
        }
        switch (this.mode) {
            case "region":
                if (this.fogTool) {
                    return false;
                }
                break;
            case "move":
                if (e.evt.shiftKey) {
                    return false;
                }
                break;
            case "draw":
            case "select":
            case "fog":
                return false;
        }
        return true;
    }

    getPinDraggable(p) {
        switch (this.variant) {
            case "gm":
            case "edit":
            case "encounter":{
                switch (this.mode) {
                    case "fog":
                        return false;
                }
                return true;
                break;
            }
        }

    }

    findCombatantObj(id) {
        return this.tokenLayer && this.tokenLayer.findOne("."+id) || this.topTokens && this.topTokens.findOne("."+id);
    }

    updateCombatants(dontNotify) {
        //console.log("updating combatants")
        if (this.combatants && this.mapInfo) {
            const mc =  this.combatants.getMapDetails((this.mapInfo.name||"F").toLowerCase());
            if (!areSameDeep(this.combatantMapDetails, mc)) {
                if (mc && mc.length) {
                    removeFromGroup(this.tokenLayer.getChildren(), mc)
                    if (this.topTokens) {
                        removeFromGroup(this.topTokens.getChildren(), mc)
                    }
                    //console.log("update combatants", mc);
                    let tli=0,tti=0;
                    for (let i in mc) {
                        const c = mc[i]
                        const layer = this.topTokens && c.player?this.topTokens:this.tokenLayer;
                        let index;
                        if (layer==this.topTokens) {
                            index = tli;
                            tli++;
                        } else {
                            index = tti;
                            tti++;
                        }
                        const p = layer.findOne("."+c.id);
                        if (!p || !areSameDeepIgnore(c, p.cInfo,["tokenX","tokenY"])) {
                            let group;
                            if (p) {
                                p.destroyChildren();
                                p.position({x:c.tokenX, y:c.tokenY});
                                group = p;
                            } else {
                                group = new Konva.Group({
                                    name:c.id, 
                                    x:c.tokenX, 
                                    y:c.tokenY
                                });
                                layer.add(group);
                            }

                            group.cInfo = c;
                            this.genCombatant(group, c);
                            group.zIndex(index);
                        } else {
                            if (p.cInfo.tokenX != c.tokenX) {
                                p.x(c.tokenX);
                            }
                            if (p.cInfo.tokenY != c.tokenY) {
                                p.y(c.tokenY);
                            }
                            if (p.zIndex() != index) {
                                p.zIndex(index);
                            }
                            p.cInfo = c;
                        }
                    }
                } else {
                    this.tokenLayer.destroyChildren();
                    this.topTokens && this.topTokens.destroyChildren();
                }
                this.combatantMapDetails = mc;
                //console.log("update to combatants", mc);
            }
        } else {
            this.tokenLayer.destroyChildren();
            this.topTokens && this.topTokens.destroyChildren();
        }
        if (!dontNotify) {
            this.updateSelection();
        }
    }

    redrawSelected() {
        //console.log("updating combatants")
        const mc =  this.combatantMapDetails;
        for (let i in mc) {
            const c = mc[i];
            if (c.select) {
                this.redrawCombatant(c.id);
            }
        }
    }

    updatePins() {
        if (this.pending) {
            this.pending.pinUpdate = true;
            return;
        }
        if (this.mapInfo) {
            const pins =  this.getMapPins(this.mapInfo.name);
            if (!areSameDeep(this.pins, pins)) {
                let selPinNotFound = this.selectedPin;
                if (pins.length) {
                    const children = this.pinGroup.getChildren();
                    removeFromGroup(children, pins);
                    //console.log("update pins", pins);
                    for (let i in pins) {
                        const pin = pins[i];
                        if (pin.name == this.selectedPin) {
                            selPinNotFound = false;
                        }
                        const p = this.pinGroup.findOne("."+pin.name);
                        if (!p || !areSameDeep(pin, p.pin)) {
                            if (p) {
                                p.destroy();
                            };
                            const group = new Konva.Group({name:pin.name, x:pin.mapPos.x, y:pin.mapPos.y});
                            group.pin = pin;
                            this.genPin(group,pin)
                            this.pinGroup.add(group);
                            group.zIndex(i);
                        } else {
                            if (p.zIndex() != i) {
                                p.zIndex(i);
                            }
                        }
                    }
                } else {
                    this.pinGroup.destroyChildren();
                }
                this.pins = pins;
                if (selPinNotFound) {
                    this.selectedPin = null;
                    this.updateSelection();
                }
                if (this.selectedPin) {
                    this.updateSelection();
                }
            }
        } else {
            this.pinGroup.destroyChildren();
        }
    }

    genPin(group,pin) {
        const ignoreHidden = ["inline"].includes(this.variant);
        let pinScale;
        if (isNaN(pin.scale)) {
            const autoBase=0.3*(this.gridSizeInFeet||5)/12;
            switch (pin.scale) {
                case "axl":
                    pinScale= autoBase*2;
                    break
                case "al":
                    pinScale = autoBase*1.5;
                    break
                case "as":
                    pinScale= autoBase*0.75;
                    break
                case "axs":
                    pinScale= autoBase*0.5;
                    break;
                case "am":
                default:
                    pinScale= autoBase;
            }
        } else {
            const fixBase=100/72/this.pixelsPerFoot;
            pinScale = Number(pin.scale)/18*fixBase;
        }
        group.scale({x:pinScale, y:pinScale});
        const name = pin.displayName;
        let selX=-9, selY=-9, selWidth=18, selHeight=0;
        if (name) {
            const text = new Konva.Text({
                text:name,
                fontSize:18,
                fontFamily:"Convergence, sans-serif",
                y:-28,
                fill:(pin.showPlayers||ignoreHidden)?"white":"mediumspringgreen",
                opacity:0.87
            });
            text.x(-text.width()/2);
            const rect = new Konva.Rect({
                x:-(text.width()+4)/2,
                y:-31,
                width:text.width()+4,
                height:22,
                fill:"#58170D",
                opacity:0.5,
                cornerRadius:4
            });
            group.add(rect);
            selX = rect.x()-1;
            selY = rect.y()-1;
            selWidth = rect.width()+2;
            selHeight = rect.height()+2;
            group.add(text);
        }

        let marker, numPoints=5;
        switch (pin.marker) {
            default:
            case "circle":
                marker = new Konva.Circle({
                    radius:6,
                    stroke:"black",
                    fill:"white",
                    opacity:0.65,
                    strokeScaleEnabled:true,
                    strokeWidth:1.5,
                });
                break;
            case "triangle":
            case "diamond":
                numPoints=4;
            case "star":
                if (pin.marker=="triangle") {
                    numPoints=3;
                }
                marker = new Konva.Star({
                    numPoints,
                    outerRadius:8,
                    innerRadius:4,
                    stroke:"black",
                    fill:"white",
                    opacity:0.65,
                    strokeScaleEnabled:true,
                    strokeWidth:1.5,
                });
                break;
            case "square":
                marker = new Konva.Rect({
                    x:-6,
                    y:-6,
                    width:12,
                    height:12,
                    stroke:"black",
                    fill:"white",
                    opacity:0.65,
                    strokeScaleEnabled:true,
                    strokeWidth:1.5,
                });
                break;
            case "none":
                break;
        }
        if (marker) {
            selHeight+=16;
            group.add(marker);
        }

        if (pin.selected) {
            group.add(new Konva.Rect({
                x:selX,
                y:selY,
                width:selWidth,
                height:selHeight,
                stroke:selectHighlight,
                strokeScaleEnabled:false,
                strokeWidth:2.5,
            }), new Konva.Rect({
                x:selX,
                y:selY,
                width:selWidth,
                height:selHeight,
                stroke:"cyan",
                strokeScaleEnabled:false,
                strokeWidth:1.5,
            }));
        }
    }

    getMapPins(name) {
        const pinList = [];
        if (name) {
            name=name.toLowerCase();
            const pins = campaign.getPins();
            for (let i in pins) {
                let p = pins[i];

                if ((p.mapPos.mapName||"").toLowerCase()==name) {
                    if ((p.showPlayers || !this.playerView)) {
                        if (p.name == this.selectedPin) {
                            p = Object.assign({}, p);
                            p.selected = true;
                        }
                        pinList.push(p);
                    }
                }
            }
        }
        return pinList;
    }

    changePins(pupdates) {
        for (let pu of (pupdates||[])) {
            const op = campaign.getPinInfo(pu.name);
            if (op) {
                const p = Object.assign({}, op);
                const mapPos = Object.assign({}, p.mapPos);
                mapPos.x=pu.x;
                mapPos.y=pu.y
                p.mapPos = mapPos;
                mapPos.diameter = this.mapPos.diameter;
                campaign.updateCampaignContent("pins", p);
            } else {
                console.log("could not find pin", pu)
            }
        }
    }

    redrawCombatant(id) {
        const group = this.findCombatantObj(id);
        if (!group) {
            //console.log("could not find group to update",id);
            return;
        }
        const c = (this.combatantMapDetails||[]).find(function(d) {return id==d.id});
        //console.log("redrawCombatant", c, group.cInfo)
        if (c) {
            //console.log("redraw", id);
            group.destroyChildren();
            this.genCombatant(group,c);
        }
    }

    hoverChange(index) {
        this.combatants.hoverChange(index);
    }

    genCombatant(group, c, showSize) {
        let rectColors=[];
        if (c.currentTurn) {
            rectColors.push("yellow");
        }
        if (c.select) {
            rectColors.push("cyan");
        }

        if (c.hover) {
            rectColors.push("red");
        }

        group.rotation(c.rotation);
        group.scale({x:1,y:1});
        switch (c.tokenType) {
            case "nametoken":
                return this.genNameToken(group,c,rectColors, showSize);
            case "rectangle":
            case "cube":
            case "line":
            case "cone":
                return this.genLineToken(group,c,rectColors, showSize);
            case "circle":
            case "cylinder":
            case "sphere":
                return this.genCircleToken(group,c,rectColors, showSize);
            case "image":
            case "imagetoken":
                return this.genImageToken(group,c,rectColors, showSize);
        }
        
    }

    genImageToken(group,c,rectColors,showSize) {
        const delayLoad = this.redrawCombatant.bind(this,c.id);
        const image = imageCache.getImage(c.tokenImageURL,delayLoad, null, testCallback);

        const opacity= (c.hidden?0.7:1)*(c.opacity||1);
        let width=Number(c.width), height=Number(c.height);
        if (image) {
            group.add(new Konva.Image({
                image,
                x:-width/2,
                y:-height/2,
                opacity,
                width,
                height
            }));
        }

        this.genRects(group, rectColors, width+doubleSelWidth, height+doubleSelWidth, width/2+selWidth, height/2+selWidth, selWidth);

        if (c.tokenType=="image") {
            if (showSize) {
                if (["top","bottom"].includes(showSize)) {
                    this.genDimensions(group,0,height/2,0,-height/2);
                } else {
                    this.genDimensions(group,-width/2,0,width/2,0);
                }
            }

            if (c.select) {
                const size = Math.min(width,height);
                this.genDragHandle(group,width/2, 0, 270,size,"right");
                this.genDragHandle(group,-width/2, 0, 90,size,"left");
                this.genDragHandle(group,0, height/2,0,size,"bottom");
                this.genDragHandle(group,0, -height/2, 180,size,"top");
            }
        }

        this.genRotateHandle(group,c, height, width,height/2);
    }

    genCircleToken(group,c,rectColors, showSize) {
        const opacity= c.hidden?0.7:1;
        const radius = (c.tokenType=="circle")?((c.diameter||5)/2):(c.width||2.5);
        group.add(new Konva.Circle({
            x:0,
            y:0,
            radius,
            fill:c.fill||"white",
            opacity:(c.target?0.1:0.4)*opacity,
        }),new Konva.Circle({
            x:0,
            y:0,
            radius,
            stroke:c.fill||"white",
            strokeWidth:0.3,
            opacity,
        }));

        const width=radius*2+doubleSelWidth,
            offset = width/2;
        this.genRects(group, rectColors, width, width, offset, offset, selWidth);

        if (c.target) {
            const or = radius*1.25;
            const dash = [radius*0.75, (or-radius*0.75)*2, radius*0.75];
            group.add(new Konva.Line({
                x:0,y:0,
                points:[-or,0,or,0],
                opacity,
                stroke:"white",
                strokeWidth:0.2,
                dash
            }),new Konva.Line({
                x:0,y:0,
                points:[0,-or,0,or],
                opacity,
                stroke:"white",
                strokeWidth:0.2,
                dash
            }),new Konva.Circle({
                x:0,
                y:0,
                radius:0.2,
                fill:"white",
                opacity:opacity,
            }));
        } else {
            if (showSize) {
                let sX=0,sY=0,eX=radius,eY=0, showDec;
                if ((c.tokenType=="circle")) {
                    sX=-radius;
                } else {
                    showDec=true;
                }
                this.genDimensions(group,sX,sY,eX,eY,showDec);
            }

            if (c.select) {
                this.genDragHandle(group,radius, 0, 270,width,"radius");
                this.genDragHandle(group,-radius, 0, 90,width,"radius");
                this.genDragHandle(group,0, radius, 0,width,"radius");
                this.genDragHandle(group,0, -radius, 180,width,"radius");
            }
        }
    }

    genLineToken(group,c,rectColors,showSize) {
        const opacity= c.hidden?0.7:1;
        let height, width, offsetX, offsetY, extraOffset=(c.extraOffset||0);
        let points;

        switch (c.tokenType) {
            case "rectangle":
                height = Number(c.height)||5;
                width = Number(c.width)||5;
                offsetX = width/2;
                offsetY = height/2;
                break;
            case "cube":
                height = width = Number(c.width)||5;
                offsetX = width/2;
                offsetY = height+extraOffset;
                break;
            case "line":
                height = Number(c.width)||5;
                width = 5;
                offsetX = width/2;
                offsetY = height+extraOffset;
                break;
            case "cone":
                height = width = Number(c.width)||5;
                offsetX = width/2;
                offsetY = height+extraOffset;
                points = [width/2,height,0,0,width,0];
                break;
        }
        if (!points) {
            points = [0,0,0,height,width,height,width,0];
        }
        group.add(new Konva.Line({
            points,
            closed:true,
            fill:c.fill||"white",
            x:0, y:0,
            offsetX,offsetY,
            opacity:0.4*opacity,
            cornerRadius:0.25,
        }),new Konva.Line({
            points,
            opacity,
            closed:true,
            x:0, y:0,
            offsetX,offsetY,
            cornerRadius:0.25,
            stroke:c.fill||"white",
            strokeWidth:0.3,
        }),new Konva.Circle({
            x:0,
            y:0,
            opacity,
            radius:0.5,
            fill:c.fill||"white"
        }));
        this.genRects(group, rectColors, width+doubleSelWidth, height+doubleSelWidth, offsetX+selWidth, offsetY+selWidth, selWidth);

        if (showSize) {
            switch (c.tokenType) {
                case "rectangle":{
                    if (["top","bottom"].includes(showSize)) {
                        this.genDimensions(group,0,height/2,0,-height/2);
                    } else {
                        this.genDimensions(group,-width/2,0,width/2,0);
                    }
                    break;
                }
                case "line":
                    this.genDimensions(group,0,0,0,-height,false, extraOffset);
                    break;
                case "cube":
                case "cone":
                    this.genDimensions(group,0,0,0,-width,false, extraOffset);
                    break;
            }
        }

        if (c.select) {
            switch (c.tokenType) {
                case "rectangle":
                    const size = Math.min(width,height);
                    this.genDragHandle(group,width/2, 0, 270,size,"right");
                    this.genDragHandle(group,-width/2, 0, 90,size,"left");
                    this.genDragHandle(group,0, height/2,0,size,"bottom");
                    this.genDragHandle(group,0, -height/2, 180,size,"top");
                    break;
                case "line":
                    this.genDragHandle(group,0, -height, 180, Math.min(width,height),"cone",extraOffset);
                    if (height > 50) {
                        // set width/height smaller so that extra handle will disappear sooner
                        this.genRotateHandle(group,c, 4, 4,15);
                    }
                    break;
                case "cube":
                case "cone":
                    this.genDragHandle(group,0, -width, 180, width,"cone",extraOffset);
                    break;
            }
        }

        this.genRotateHandle(group,c, height, width,offsetY);
    }

    genDragHandle(group, x,y, rotation,size,direction,extraOffset) {
        if (size*this.scale < 40) {
            return;
        }
        const dim = 10/this.scale;
        const dh= new Konva.Line({
            x,y,rotation,offsetY:dim/4-(extraOffset||0),
            points:[-dim,0,0,dim,dim,0],
            closed:true,
            fill:dragHandleColor,
            stroke:dragHandleBorder,
            strokeWidth:dim/20,
            tension:0.2,
            shadowBlur:dim/5,
            shadowColor:dragHandleBorder,
            opacity:0.9,
            name:"dh"
        })
        dh.dragHandle = direction;
        group.add(dh)
    }

    genDimensions(group, startPosX, startPosY, endPosX, endPosY,showDec,extraOffset) {
        const dist = Math.sqrt(Math.pow(startPosX-endPosX, 2)+Math.pow(startPosY-endPosY, 2));
        let distTxt;
        if (dist < 1000) {
            distTxt = dist.toFixed(showDec?1:0)+" ft";
        } else {
            distTxt = (dist/5280).toFixed(2)+" mi";
        }
        const x = startPosX+(endPosX-startPosX)/2, y=startPosY+(endPosY-startPosY)/2;
        const text = new Konva.Text({
            text:distTxt,
            x,
            y:y-(extraOffset||0),
            scaleX:1/this.scale,
            scaleY:1/this.scale,
            fontSize:18, 
            fontFamily:"Convergence, sans-serif",
            fill:"white",
            rotation:-group.rotation(),
            listening:false
        });
        const offsetX = (text.width()/2/this.scale);
        const offsetY =  (text.height()/2/this.scale);

        text.offsetX(offsetX*this.scale);
        text.offsetY(offsetY*this.scale);
        const extra =5/this.scale;
        group.add(new Konva.Line({
            points:[startPosX,startPosY,endPosX, endPosY],
            offsetY:(extraOffset||0),
            stroke:"white",
            strokeWidth:4,
            strokeScaleEnabled:false,
            dash:[0.5*this.scale,0.5*this.scale],
            listening:false
        }),new Konva.Rect({
            x,y:y-(extraOffset||0),
            offsetX:offsetX+extra,
            offsetY:offsetY+extra,
            width:text.width()/this.scale+extra*2,
            height:text.height()/this.scale+extra*2,
            cornerRadius:extra*2,
            fill:dragHandleColor,
            opacity:0.7,
            rotation:-group.rotation(),
            listening:false
        }),
        text);
    }

    genRects(group, rectColors, width, height, offsetX, offsetY, strokeWidth) {
        const len = (rectColors || []).length;
        if (!len) {
            return;
        }

        group.add(new Konva.Rect({
            width:width+strokeWidth*(len-1)*1.5,
            height:height+strokeWidth*(len-1)*1.5,
            offsetX:offsetX+strokeWidth*(len-1)*0.75,
            offsetY:offsetY+strokeWidth*(len-1)*0.75,
            stroke:selectHighlight,
            strokeWidth:strokeWidth*len*1.5+strokeWidth*0.5,
            shadowBlur:strokeWidth*2
        }));

        for (let rectColor of rectColors) {
            group.add(new Konva.Rect({
                width,
                height,
                offsetX,
                offsetY,
                stroke:rectColor,
                strokeWidth
            }));
            width+=strokeWidth*3;
            height+=strokeWidth*3;
            offsetX+=strokeWidth*1.5;
            offsetY+=strokeWidth*1.5;
        }

    }

    genRotateHandle(group, c, height, width,offsetY) {
        if (Math.min(height,width)*this.scale < 20) {
            return;
        }
        if (c.select && c.canMove) {
            const radius = 10/this.scale;
            const innerRadius = radius*0.5;
            const strokeWidth = radius*0.2;
            const arrowSize = radius*0.5;
            const handleOffset = (offsetY||0)+(radius*2)+doubleSelWidth;

            const handle = new Konva.Circle({
                x:0,y:0,
                offsetY:handleOffset,
                radius,
                fill:toolBackground,
                opacity:0.8,
                name:"draghandle",
            });
            handle.handle = true;

            group.add(handle,new Konva.Arc({
                x:0,y:0,
                offsetY:handleOffset,
                innerRadius:innerRadius,
                outerRadius:innerRadius,
                strokeWidth,
                stroke:toolStroke,
                angle:300,
                strokeCap:"round",
                listening:false
            }),new Konva.Arrow({
                x:0,
                y:-innerRadius,
                offsetY:handleOffset,
                points:[0,0,arrowSize,0],
                fill:toolStroke,
                pointerLength:arrowSize,
                pointerWidth:arrowSize,
                listening:false
            }));
        }
    }

    genNameToken(group, c,rectColors) {
        if (!c.tokenImageURL) {
            return;
        }
        let name = maxLen(c.displayName || "", 30);
        let opacity;

        const id=c.number;
        if (c.hideName || ((c.showNames=="hide") && (c.group=="pc"))) {
            name = (id||"").toString();
        } else if (c.showNames=="abbrev") {
            const s = name.split(" ");
            let an = "";
            for (let i in s) {
                an += s[i].substr(0,1);
            }
            name=an;
            if (id) {
                name = "("+id+") "+name;
            }
        } else {
            if (id) {
                name = "("+id+") "+name;
            }
        }

        const s = (sizeScaleMap[c.tokenSize]||1)*gridBaseSize;
        let health;
        let showCondition = showConditionIndicator(c.conditions);
        let healthColor;
        let showDead;
        let almostDead;

        switch (c.state) {
            case "inactive":
                opacity=0.85;
                break;
            case "active":
            default:
                if (c.type && (c.hp==0)) {
                    opacity = 0.65;
                    showDead=true;
                } else {
                    if (c.hidden) {
                        opacity=0.7;
                    } else {
                        opacity=1;
                    }
                    healthColor = getHealthGroup(c.hp,c.hpMax);
                }
                break;
        }

        if (c.hp==0) {
            if ((c.ctype=="pc") && !c.showDead) {
                almostDead=true;
            } else {
                showDead = true;
            }
        }

        group.scale({x:5/gridBaseSize,y:5/gridBaseSize});
        group.x(c.tokenX);
        group.y(c.tokenY);
        group.opacity(opacity);
        
        const delayLoad = this.redrawCombatant.bind(this,c.id);
        const tokenImage = imageCache.getImage(c.tokenImageURL,delayLoad, null, testCallback);
        if (tokenImage) {
            group.add(new Konva.Image({
                image:tokenImage,
                width:s,
                height:s,
                x:-s/2,
                y:-s/2
            }));
        }
        let overlay;
        if (showDead) {
            overlay = imageCache.getImage(skullUrl,delayLoad, null, testCallback);
        } else if (almostDead) {
            overlay = imageCache.getImage(heartbeatUrl,delayLoad, null, testCallback);
        }

        if (overlay) {
            group.add(new Konva.Image({
                image:overlay,
                width:s,
                height:s,
                x:-s/2,
                y:-s/2
            }));
        }

        if (healthColor) {
            group.add(new Konva.Circle({
                radius:s/10,
                offsetX:s*0.39,
                offsetY:s*0.39,
                fill:healthColor,
                opacity:0,
                shadowBlur:2,
                shadowColor:"black"    
            }),new Konva.Circle({
                radius:s/2+1,
                stroke:healthColor,
                opacity:0.7,
                shadowBlur:2,
                strokeWidth:4
            }));
        }

        if (rectColors) {
            const width=s+8,
            height=s+18,
            offset = width/2;
            this.genRects(group, rectColors, width, height, offset, offset, 2);
        }

        if (showCondition) {
            group.add(addCondition(new Konva.Group({
                scaleX:s*0.0028,
                scaleY:s*0.0028,
                x:s*0.225,
                y:-s*0.5,
                opacity:0.8,
                shadowBlur:2,
                shadowColor:"black"
            })));
        }

        if (name) {
            const text = new Konva.Text({
                text:name,
                fontSize:18,
                fontFamily:"Convergence, sans-serif",
                y:s/2-3,
                fill:"yellow"
            });
            text.x(-text.width()/2);
            const rect = new Konva.Rect({
                x:-(text.width()+14)/2,
                y:s/2-6,
                width:text.width()+14,
                height:22,
                fill:"gray",
                opacity:0.65,
                cornerRadius:4
            });
            group.add(rect);
            group.add(text);
        }
    }

    dragRotate(obj) {
        const {full} = this.getActionSelected(null, true);
        const pos = this.getPointerPosition(10000);
        const tokenObj = obj.getParent();
        const c = tokenObj.cInfo;
        const selection = this.combatants.getSelection();

        const deg = Math.round((180-Math.atan2(pos.x-c.tokenX, pos.y-c.tokenY)*180/Math.PI)/3)*3;
        tokenObj.rotation(deg);
        obj.position({x:0,y:0});

        const rotationChange = deg - (c.rotation||0);

        for (let t of full) {
            const p = this.findCombatantObj(t);
            if (p && (p!= tokenObj)) {
                const pc = p.cInfo;
                const radius = Math.sqrt(Math.pow(pc.tokenX - c.tokenX, 2) + Math.pow(pc.tokenY-c.tokenY, 2));
                const odeg = (180-Math.atan2(pc.tokenX-c.tokenX, pc.tokenY-c.tokenY)*180/Math.PI);
                const ndeg = odeg+rotationChange;
                const nrad = (ndeg-90)*Math.PI/180;
                const newX = radius*Math.cos(nrad)+c.tokenX;
                const newY = radius*Math.sin(nrad)+c.tokenY;
                //console.log("change pos", newX, newY, rotationChange, p, obj)
                p.position({x:newX, y:newY});
                if (pc.rotation != null) {
                    p.rotation(pc.rotation+rotationChange);
                }
            }
        }
    }

    startDragHandle(dragging,o) {
        const startDrag = this.getPointerPosition();

        const obj = new Konva.Circle({
            radius:0,
            position:startDrag,
            name:"dh",
            draggable:true,
        });
        obj.dragHandle = o.dragHandle;
        obj.tokenObj = o.getParent();
        obj.updatedcInfo = obj.tokenObj.cInfo;
        obj.startDragPos =startDrag;

        this.backgroundLayer.add(obj);
        this.selectHandle = obj;
        dragging.push(obj);
        obj.tokenObj.destroyChildren();
        this.genCombatant(obj.tokenObj, obj.tokenObj.cInfo,obj.dragHandle);

    }

    cancelDragHandle() {
        if (this.selectHandle) {
            const {tokenObj,updatedcInfo} = this.selectHandle;

            // redraw to hide measure in case of no change
            tokenObj.destroyChildren();
            this.genCombatant(tokenObj, updatedcInfo);
            
            this.selectHandle.destroy();
            this.selectHandle = null;
        }
    }

    dragHandle(obj) {
        const pos = this.getPointerPosition();
        const {dragHandle, tokenObj, startDragPos,updatedcInfo} = obj;
        const cInfo = Object.assign({}, updatedcInfo);
        const nUnit = (dragHandle=="radius")?2:1;
        const nRadius=Math.round(nUnit*Math.sqrt(Math.pow(cInfo.tokenX - pos.x, 2) + Math.pow(cInfo.tokenY-pos.y, 2)))/nUnit;
        let deg = Math.round((180-Math.atan2(pos.x-cInfo.tokenX, pos.y-cInfo.tokenY)*180/Math.PI)/3)*3;
        const degShift={bottom:180, top:0, left:90, right:-90};
        const ratio = Number(cInfo.width)/Number(cInfo.height);

        switch (dragHandle) {
            case "radius":{
                if (cInfo.tokenType=="circle") {
                    cInfo.diameter = nRadius*2;
                } else {
                    cInfo.width = nRadius;
                }
                break;
            }
            case "cone":{
                cInfo.rotation=deg;
                cInfo.width = nRadius;
                break;
            }
            case "bottom": 
            case "top":{
                cInfo.rotation=degShift[dragHandle]+deg;
                cInfo.height=nRadius*2;
                if (cInfo.tokenType =="image") {
                    cInfo.width = nRadius*2*ratio;
                    cInfo.artWidth = cInfo.width;
                }
                break;
            }
            case "left": 
            case "right":{
                cInfo.rotation=degShift[dragHandle]+deg;
                cInfo.width=nRadius*2;
                if (cInfo.tokenType =="image") {
                    cInfo.artWidth = cInfo.width;
                    cInfo.height = nRadius*2/ratio;
                }
                break;
            }
        }
        tokenObj.destroyChildren();
        obj.updatedcInfo = cInfo;
        this.genCombatant(tokenObj, cInfo,dragHandle);
    }

    saveDragHandle(obj) {
        const {dragHandle, tokenObj, startDragPos,updatedcInfo} = obj;
        const {cInfo} = tokenObj;
        const oldC = this.combatants.getCombatant(cInfo.id);
        if (oldC) {
            const cupdates = [];
            const newC = Object.assign({},oldC);
            if (updatedcInfo.width!=null) {
                newC.width = updatedcInfo.width;
            }
            if (updatedcInfo.artWidth!=null) {
                newC.artWidth = updatedcInfo.artWidth;
            }
            if (updatedcInfo.height!=null) {
                newC.height = updatedcInfo.height;
            }
            if (updatedcInfo.diameter!=null) {
                newC.diameter = updatedcInfo.diameter;
            }
            newC.tokenY=updatedcInfo.tokenY;
            newC.tokenX=updatedcInfo.tokenX;
            if (updatedcInfo.rotation!=null) {
                newC.rotation = updatedcInfo.rotation;
            }
            this.combatants.setCombatant(newC);
            //console.log("update", newC, updatedcInfo)
        } else {
            tokenObj.destroy();
        }
    }

    getCoverObjs() {
        return (this.mapextra&&this.mapextra.coverObjs) || (this.mapInfo && this.mapInfo.coverObjs)||[];
    }

    getDrawObjs() {
        return (this.mapextra&&this.mapextra.drawObjs) || (this.mapInfo && this.mapInfo.drawObjs)||[];
    }

    getFogRegions() {
        const regionBuildMode = (this.mode == "region");
        const selectedRegion = (this.selectedRegion||{}).name;
        const hoverRegion = (this.hoverRegion||{}).name;
        const {name,coverRegions} = this.mapInfo;
        const retCover = [];
        for (let i in coverRegions) {
            const genName = "cr"+i;
            retCover.push(Object.assign({
                name:genName,
                index:Number(i),
                strokeWidth:genName==hoverRegion?2:regionBuildMode?1:0,
                fill:regionBuildMode?"rgba(128,128,128,0.5)":"rgba(0,0,0,0)",
                selected:genName==selectedRegion
            },coverRegions[i]));
        }
        return retCover;
    }

    updateCover(force) {
        if (this.coverLayer) {
            const coverObjs = this.getCoverObjs();
            if (!areSameDeep(this.coverObjs, coverObjs) || (this.coverObjs == null)) {
                this.coverGroup.destroyChildren();
                //console.log("updating cover", coverObjs);
                this.coverObjs = coverObjs;

                this.coverGroup.add(new Konva.Rect({
                    x:-1000000,
                    y:-1000000,
                    width:50000000,
                    height:50000000,
                    fill:fogColor
                }));

                for (let i in coverObjs) {
                    const o= coverObjs[i];
                    const c = this.genCover(o);
                    if (c) {
                        this.coverGroup.add(c);
                    }
                }
            }
        }
        //console.log("cover regions", !!this.fogHitGroup, this.mapInfo, this.coverObjs)
        if (this.fogHitGroup && this.mapInfo) {
            const coverRegions = this.getFogRegions();
            if (force || !areSameDeep(this.coverRegions, coverRegions)) {
                //console.log("update cover regions", areSameDeep(this.coverRegions, coverRegions), coverRegions)
                this.fogHitGroup.destroyChildren();
                //console.log("updating cover", coverRegions);
                this.coverRegions = coverRegions;
                const selectedRegions=[];

                for (let i in coverRegions) {
                    const o = coverRegions[i];

                    const cr = new Konva.Line({
                        name:o.name,
                        points:o.points,
                        lineCap:"round",
                        lineJoin:"round",
                        tension:0,
                        fill:o.fill,
                        stroke:"rgba(255,0,0,0.5)",
                        strokeWidth:o.strokeWidth,
                        strokeScaleEnabled:false,
                        closed:true
                    });
                    cr.coverRegion = o;
                    this.fogHitGroup.add(cr);
                    if (o.selected) {
                        for (let p=0; p<o.points.length; p+=2) {
                            const rp = new Konva.Circle({
                                name:"rp."+p,
                                x:o.points[p],
                                y:o.points[p+1],
                                radius:5/this.scale,
                                stroke:"white",
                                fill:"red",
                                strokeScaleEnabled:false,
                                strokeWidth:1
                            });
                            rp.regionPoint={
                                region:o.index,
                                point:p
                            }
                            selectedRegions.push(rp);
                        }
                    }
                }

                for (let rp of selectedRegions) {
                    this.fogHitGroup.add(rp);
                }
            }
        } 
    }

    genCover(o) {
        switch (o.type) {
            case "uncover":
                return new Konva.Rect({
                    width:o.width,
                    height:o.height,
                    x:o.x,
                    y:o.y,
                    fill:"gray",
                    opacity:1,
                    shadowColor:"gray",
                    shadowBlur:2,
                    shadowOpacity:1,
                    shadowOffset:{ x: .00001, y: .00001 },
                    globalCompositeOperation:"destination-out"
                });
                break;
            case "circle":
                return new Konva.Line({
                    points:o.points,
                    lineCap:"round",
                    lineJoin:"round",
                    tension:.1,
                    stroke:"gray",
                    strokeWidth:o.diameter,
                    opacity:1,
                    shadowColor:"gray",
                    shadowBlur:2,
                    shadowOpacity:1,
                    shadowOffset:{ x: .00001, y: .00001 },
                    globalCompositeOperation:"destination-out"
                });
                break;
            case "polygon":
                if (o.circlePoints) {
                    const {points,circlePoints} = o;
                    const pg = new Konva.Group({
                        clipFunc:function (ctx) {
                            ctx.moveTo(points[0], points[1]);
                            for (let i=2; i<points.length; i+=2){
                                ctx.lineTo(points[i], points[i+1]);
                            }
                            ctx.closePath();
                        }
                    });
                    pg.add(new Konva.Line({
                        points:circlePoints,
                        lineCap:"round",
                        lineJoin:"round",
                        tension:.1,
                        stroke:"gray",
                        strokeWidth:o.diameter,
                        opacity:1,
                        globalCompositeOperation:"destination-out",
                        shadowColor:"gray",
                        shadowBlur:2,
                        shadowOpacity:1,
                        shadowOffset:{ x: .00001, y: .00001 }
                    }))
                    return pg;
                }else {
                    return new Konva.Line({
                        points:o.points,
                        lineCap:"round",
                        lineJoin:"round",
                        tension:0,
                        fill:"gray",
                        opacity:1,
                        closed:true,
                        shadowColor:"gray",
                        shadowBlur:0.5,
                        shadowOpacity:1,
                        shadowOffset:{ x: .00001, y: .00001 },
                        globalCompositeOperation:"destination-out"
                    });
                }
                break;
            case "polycircle":
                break;
            default:
                console.log("unknown cover type");
        }
    }

    updateRegionHover(obj) {
        if (!obj || obj.pin || obj.token) {
            return;
        }

        if ((this.hoverRegion||{}).name != (obj.coverRegion||{}).name) {
            this.hoverRegion = obj.coverRegion;
            this.updateCover();
        }
    }

    updateDrawing()  {
        const drawObjs = this.getDrawingInfo();
        if (!areSameDeep(this.drawObjs, drawObjs)) {
            this.drawLayer.destroyChildren();
            //console.log("updating drawing", drawObjs);
            this.drawObjs = drawObjs;

            for (let i in drawObjs) {
                const o= drawObjs[i];
                const clear = o.color=="clear";
                const drawGroup = new Konva.Group({
                    name:o.selected?"dls":"dl"
                });
                drawGroup.drawObj=o;

                drawGroup.add(new Konva.Line({
                    points:o.points,
                    lineCap:"round",
                    lineJoin:"round",
                    tension:.1,
                    stroke:clear?"white":o.color,
                    globalCompositeOperation:clear?"destination-out":"source-over",
                    strokeWidth:o.diameter,
                }));

                if (o.selected) {
                    drawGroup.add(new Konva.Line({
                        points:o.points,
                        lineCap:"round",
                        lineJoin:"round",
                        tension:.1,
                        stroke:clear?colorWhite:o.color,
                        opacity:0.4,
                        strokeWidth:o.diameter*3,
                        shadowBlur:2,
                        shadowColor:(o.color!=colorBlack)?colorBlack:colorWhite
                    }));
                } else {
                    drawGroup.add(new Konva.Line({
                        points:o.points,
                        lineCap:"round",
                        lineJoin:"round",
                        tension:.1,
                        stroke:"white"||o.color,
                        opacity:0.01,
                        strokeWidth:o.diameter*3,
                    }));
                }
    
                this.drawLayer.add(drawGroup);
            }
        }
    }

    getDrawingInfo() {
        const ret = [];
        const drawObjs = this.getDrawObjs();
        for (let i in drawObjs) {
            const d = Object.assign({index:i, selected:(this.drawSelected && this.drawSelected.includes(i))}, drawObjs[i]);
            ret.push(d);
        }
        if (this.newDraw) {
            ret.push(Object.assign({index:"new"},this.newDraw));
        }
        return ret;
    }

    selectDraw(add, noReset) {
        const newDraw = (noReset && this.drawSelected || []).concat(add||[]);
        if (!newDraw.length) {
            if (!this.drawSelected) {
                return;
            }
            this.drawSelected = null;
        } else {
            this.drawSelected = newDraw;
        }
        this.updateDrawing();
        this.updateSelection();
    }

    unselectDraw(index) {
        if (!this.drawSelected) {
            return;
        }
        const pos = this.drawSelected.indexOf(index);
        if (pos >= 0) {
            this.drawSelected.splice(pos,1);
            if (!this.drawSelected.length) {
                this.drawSelected=null;
            }
            
            this.updateDrawing();
            this.updateSelection();
        }
    }

    deleteSelectedDrawing() {
        const oldDrawing = this.getDrawObjs();
        if (!oldDrawing || !this.drawSelected) {
            return;
        }
        const newDrawing=[];
        for (let i in oldDrawing) {
            if (!this.drawSelected.includes(i)) {
                newDrawing.push(oldDrawing[i]);
            }
        }
        this.setMapInfo(null, newDrawing);
        this.selectDraw();
    }

    performDrawingUpdates(updates) {
        const drawing = this.getDrawObjs().concat([]);
        let foundUpdate;
        const selected = [];
        for (let u of updates) {
            const {drawObj} = u;
            const ndo = Object.assign({}, drawing[drawObj.index]);
            let {x,y} = u.position();
            x=this.roundXCoord(x);
            y=this.roundYCoord(y);
            if (x || y) {
                ndo.points = shiftPoints(x,y, ndo.points)

                drawing[drawObj.index] = ndo;
                foundUpdate=true;
            }
            selected.push(drawObj.index);
        }
        if (foundUpdate) {
            this.setMapInfo(null, drawing);
        }
        this.selectDraw(selected);

    }

    deleteSelectedPin() {
        if (this.selectedPin) {
            campaign.deleteCampaignContent("pins", this.selectedPin);
        }
    }

    incrementLoading() {
        this.numLoading = (this.numLoading||0)+1;
        let loading = this.stage.findOne(".loading");

        if (!loading) {
            loading = new Konva.Group({x:0,y:0, offsetX:20, offsetY:20, listening:false, name:"loading"});

            loading.add(new Konva.Circle({
                x: 20,
                y: 20,
                radius: 23,
                fill: "rgb(32,32,32,0.4)"
            }));
            loading.add(new Konva.Circle({
                x: 20,
                y: 20,
                radius: 20,
                stroke: 'white',
                strokeWidth: 2,
            }));
            loading.add(new Konva.Line({
                points:[20,20, 20,0],
                stroke: 'white',
            }));
            this.topLayer.add(loading);
    
            this.loadingAnim = new Konva.Animation(function(frame) {
                loading.rotate(frame.timeDiff/10);
            }, this.topLayer);
            this.loadingAnim.start();
        }
    }

    getPingList() {
        if (this.campaignMode) {
            return campaign.getPingList();
        }
        return this.localPingList;
    }

    deletePing(name) {
        if (this.campaignMode) {
            campaign.deleteCampaignContent("adventure", name)
        } else {
            const pos = this.localPingList.findIndex(function (f){return f.name==name})
            if (pos>=0) {
                this.localPingList = this.localPingList.concat([]);
                this.localPingList.splice(pos,1);
                this.startPingUpdate();
            }else {
                console.log("did not find ping", name, this.localPingList.concat([]));
            }
        }
    }

    cancelMeasureDistance() {
        if (this.measureDistance) {
            this.measureDistance.destroy();
            this.measureDistance=null;
        }
    }

    showMeasureDistance(radius) {
        this.cancelMeasureDistance();
        if (!radius || this.combatants.hasMultiSelection()) {
            return;
        }
        let includeCenter;
        const selected = this.combatants.hasSelection();
        let pos;
        if (selected) {
            const cInfo = this.combatants.getCombatant(selected);
            pos = {x:cInfo.tokenX,y:cInfo.tokenY};
        } else if (this.stageTap) {
            pos= this.screenToMap(this.stageTap);
            includeCenter=true;
        }
        if (pos) {
            this.measureDistance = this.genMeasureDistance(pos,radius||30,includeCenter);
        }
    }

    genMeasureDistance(pos,radius,includeCenter) {
        const group = new Konva.Group({listening:false});
        group.add(new Konva.Circle({
            position:pos,
            radius,
            stroke: measureColor,
            strokeWidth:0.3
        }));
        if (includeCenter) {
            group.add(new Konva.Circle({
                position:pos,
                radius:0.3,
                fill: measureColor
            }));
        }
        this.topLayer.add(group);
        return group;
    }

    setPing(ping) {
        if (this.campaignMode) {
            campaign.updateCampaignContent("adventure",ping);
        } else {
            this.localPingList = this.localPingList.concat([]);
            const pos = this.localPingList.findIndex(function (f){return f.name==ping.name})
            ping = Object.assign({},ping);
            ping.timestamp = Date.now();
            if (pos>=0) {
                this.localPingList[pos] = ping;
            } else {
                this.localPingList.push(ping);
                this.startPingUpdate();
            }
        }
    }

    startPingUpdate() {
        if (this.pingtimer) {
            return;
        }
        if (!this.getPingList().length) {
            this.pingGroup.destroyChildren();
            return;
        }
        const t=this;
        this.pingtimer = setInterval(function () {
            const np = {};
            const pp = t.pingProgress;
            const pl = t.getPingList();
            if (pl.length) {
                for (let i in pl) {
                    const p = pl[i];
                    const pv = (pp[p.version || p.name]||0)+1;
                    np[p.version || p.name] =pv;
                    if (pv >50) {
                        // delete old ping
                        delete np[p.version || p.name];
                        t.deletePing(p.name)
                    }
                }
                t.pingProgress=np;
            } else {
                clearInterval(t.pingtimer);
                t.pingtimer=null;
                t.pingProgress = {};
            }
            t.updatePing();
        },100);
    }

    updatePing() {
        const pingList = this.getPingList();
        const mult = (this.gridSizeInFeet||5)/5;

        if (!pingList.length) {
            this.pingtimer && clearInterval(this.pingtimer);
            this.pingtimer=null;
            this.pingGroup.destroyChildren();
            return;
        }

        removeFromGroup(this.pingGroup.getChildren(), pingList);
        const pp = this.pingProgress;

        for (let i in pingList) {
            const p = pingList[i];
            const pv = pp[p.version || p.name]||0;
            const f = this.pingGroup.findOne("."+p.name);

            if (!f || !areSameDeepIgnore(p, f.ping, ["version","timestamp"])) {
                f && f.destroy();
                if (p.measure) {
                    if (!this.localMeasure || (p.name != this.localMeasure.name)) {
                        const group = this.genMeasure(p);
                        if (group) {
                            this.pingGroup.add(group);
                            group.zIndex(Number(i));
                        }
                    }
                } else if (pv < 10) {
                    const circle = new Konva.Circle({
                        name:p.name,
                        radius:(pv||0)*this.gridSizeInFeet/5,
                        x:p.x,
                        y:p.y,
                        strokeScaleEnabled:false,
                        strokeWidth:5,
                        stroke:p.color||"red",
                        opacity:1
                    });
                    circle.ping = p;
                    this.pingGroup.add(circle);
                    circle.zIndex(Number(i));
                }
            } else if (!p.measure) {
                if (pv > 10) {
                    f.destroy();
                } else {
                    f.radius((pv||0)*this.gridSizeInFeet/5);
                }
            }
        }
    }

    genMeasure(p) {
        const dist = Math.trunc(Math.sqrt(Math.pow(p.startX-p.endX, 2)+Math.pow(p.startY-p.endY, 2)));
        let distTxt;
        if (dist < 1000) {
            distTxt = dist+(p.range?`/${p.range}`:"")+" ft";
        } else {
            distTxt = (dist/5280).toFixed(2)+" miles";
        }
        if (dist > 3) {
            const group = new Konva.Group({name:p.name, listening:false});
            group.ping = p;
            group.add(new Konva.Arrow({
                x:p.startX,
                y:p.startY,
                points:[0,0,p.endX-p.startX,p.endY-p.startY],
                stroke:p.color||"red",
                fill:p.color||"red",
                strokeWidth:2,
                strokeScaleEnabled:false,
                pointerLength:10/this.scale,
                pointerWidth:10/this.scale
            }),
            new Konva.Text({
                    text:distTxt,
                    x:p.startX+(p.endX-p.startX)/2,
                    y:p.startY+(p.endY-p.startY)/2,
                    scaleX:1/this.scale,
                    scaleY:1/this.scale,
                    fontSize:18, 
                    fontFamily:"Convergence, sans-serif",
                    shadowEnabled:true,
                    shadowColor:'black',
                    shadowBlur:10,
                    shadowOpacity:1,
                    fill:"white"
            }));
            return group;
        }
        return null;
    }

    startPingCheck() {
        const t=this;
        this.pingCheckTimer = setTimeout(function () {
            const pos = t.getPointerPosition();
            const obj = new Konva.Circle({
                radius:0,
                x:pos.x,
                y:pos.y,
                name:"ping",
                draggable:true,
            });
            obj.pointer = true;

            t.tokenLayer.add(obj);
            t.disableAutoDragEnd=true;
            if (t.dragging) {
                const dragobj = t.dragging[0];
                if (dragobj.selectRect) {
                    t.cancelDragSelect();
                }
                dragobj.stopDrag();
            }
            obj.startDrag();
            t.disableAutoDragEnd=false;
            t.dragging = [obj];

            t.startMeasureTool(obj,true);
            t.pingCheckTimer=null;
            t.moved = true;
        },500)
    }

    cancelPingCheck() {
        if (this.pingCheckTimer) {
            clearTimeout(this.pingCheckTimer);
            this.pingCheckTimer = null;
        }
    }

    startMeasureTool(obj, noMeasure) {
        const start = this.getPointerPosition();
        this.localMeasure = {
            name:campaign.newUid(),
            startX:start.x,
            startY:start.y,
            type:"ping",
            color:this.playerView?"orange":"red",
            measure:!noMeasure
        }
        this.localMeasureObj = obj;
        const {cInfo} = obj;
        if (cInfo) {
            const {startPos} = cInfo;
            if (startPos) {
                this.localMeasure.startX = startPos.x;
                this.localMeasure.startY = startPos.y;
                this.localMeasure.range = startPos.range;
            } else {
                this.localMeasure.startX = cInfo.tokenX;
                this.localMeasure.startY = cInfo.tokenY;
            }
        }

        if (noMeasure) {
            this.localMeasure.x = start.x;
            this.localMeasure.y = start.y;
            this.setPing(this.localMeasure);
        }
        //console.log("start measure tool", JSON.stringify(this.localMeasure), noMeasure)
        if (!this.measureTimer) {
            const t=this;
            this.measureTimer = setInterval(function () {
                if (t.localMeasure){
                    t.setPing(t.localMeasure)
                }
            },1000);
        }
    }

    updateMeasureTool() {
        if (this.localMeasureGroup) {
            this.localMeasureGroup.destroy();
            this.localMeasureGroup = null;
        }
        if (this.localMeasure) {
            this.localMeasure.endX = this.localMeasureObj.x();
            this.localMeasure.endY = this.localMeasureObj.y();
            this.localMeasure.measure=true;

            const group = this.genMeasure(this.localMeasure);
            if (group) {
                this.topLayer.add(group);
                this.localMeasureGroup = group;
            }
        }
    }

    cancelMeasureTool() {
        if (this.localMeasureGroup) {
            this.localMeasureGroup.destroy();
            this.localMeasureGroup = null;
        }
        if (this.measureTimer) {
            clearInterval(this.measureTimer);
            this.measureTimer=null;
        }
        
        if (this.localMeasure) {
            if (this.localMeasure.x == null) {
                this.deletePing(this.localMeasure.name);
            } else {
                delete this.localMeasure.measure;
                this.setPing(this.localMeasure);
            }
            this.localMeasure = null;
            this.localMeasureObj = null;
        }
    }
    
    decrementLoading() {
        if (this.numLoading) {
            this.numLoading--;
            if (!this.numLoading) {
                this.loadingAnim.stop();
                this.loadingAnim=null;
                this.destroyName(".loading");
            }
        }
    }

    getErrorFill() {
        if (!this.errorFill) {
            const fill = new Konva.Layer({width:10, height:10, listening:false});
            fill.add(new Konva.Rect({x:0, y:0, width:5, height:5, fill:'gray'}));
            fill.add(new Konva.Rect({x:5, y:5, width:5, height:5, fill:'gray'}));
            this.errorFill=fill.toCanvas({width:10, height:10});
            fill.destroy();
        }
        return this.errorFill;
    }

    setShowGrid(show) {
        if (!!show == !!this.showGrid) {
            return;
        }
        this.showGrid=show;
        this.genGridLines();
        this.updateSelection();
    }

    setDrawColor(color) {
        this.drawColor = color;
        this.updateSelection();
    }

    genGridLines() {
        this.gridGroup.destroyChildren();

        if (this.showGrid && this.mapInfo) {
            if (this.scale < 2) {
                //console.log("too small to show grid", 5*this.scale, this.scale);
                return;
            }

            let mapX = this.viewPort.left;
            let mapY = this.viewPort.top;
            let points = [];
        
            let xStart = mapX - (mapX%5) + (-this.gridxShift) % 5 - 20;
            let yStart = mapY - (mapY%5) + (-this.gridyShift) % 5 - 20;
            let xEnd = this.viewPort.right+20;
            let yEnd = this.viewPort.bottom+20;
            //console.log("need to gen grid", xStart, yStart, xEnd, yEnd, this.viewPort);
        
            let top;
            for (let x=xStart; x<= xEnd; x+=5){
                points.push(x);
                points.push(top?yStart:yEnd);
                points.push(x);
                points.push(top?yEnd:yStart);
                top = !top;
            }

            let left;
            for (let y=yStart; y<= yEnd; y+=5){
                points.push(left?xStart:xEnd);
                points.push(y);
                points.push(left?xEnd:xStart);
                points.push(y);
                left = !left;
            }

            let grid = new Konva.Line({
                points,
                stroke:"black",
                opacity:0.7,
                strokeScaleEnabled:true,
                strokeWidth:0.1,
                listening:false
            });
            this.gridGroup.add(grid);
        }
        
    }

    setPointer(x,y) {
        const pointer = this.topLayer.findOne(".pointer");
        if (x || y) {
            if (pointer) {
                pointer.x(x);
                pointer.y(y);
            } else {
                const pgroup = new Konva.Group({listening:false, x, y, name:"pointer"});
                pgroup.add(
                    new Konva.Circle({radius:8/this.scale, fill:"#b30000", opacity:0.7}),
                    new Konva.Circle({radius:3/this.scale, fill:"red", opacity:1})
                );
                this.topLayer.add(pgroup);
            }
        } else if (pointer) {
            pointer.destroy();
        }
    }

    getActionSelected(addSelection, includeSelection) {
        let selected = [], full=[], check=true;

        if (includeSelection) {
            selected = Object.keys(this.combatants.selected);
        }
        if (addSelection && !selected.includes(addSelection)) {
            selected.push(addSelection);
        }
        full = selected.concat([]);
        while (check) {
            check=false;
            const clist = this.combatantMapDetails||[];
            for (let c of clist) {
                if (c.anchorId) {
                    if (full.includes(c.id)) {
                        if (!full.includes(c.anchorId)) {
                            full.push(c.anchorId);
                            check=true;
                        }
                    } else if (full.includes(c.anchorId) && !full.includes(c.id)) {
                        full.push(c.id);
                        check=true;
                    }
                } 
            }
        }
        return {selected,full}
    }

    // stage interactions
    onMouseDown(e) {
        //console.log("on mouse down");
        const {ctrlKey,shiftKey}=e.evt;
        const ctrlOrShift = ctrlKey||shiftKey;
        const pos = this.stage.getPointerPosition();
        const obj = findObj(e);
        let newSelected = null;
        let preventDrag;
        this.moved = false;
        this.stageTap = null;
        this.noDragObj =null;
        if (!this.dragging) {
            this.dragging= [];
            this.unselectedObj = null;
            this.updateRegionHover(obj);
            //console.log("stage mouse down",e,obj);
            if (obj) {
                const {cInfo,drawObj} = obj;
                if (cInfo && (this.getCombatantDraggable(obj.cInfo)||cInfo.select||(cInfo.canMove&&ctrlOrShift&&["move","select"].includes(this.mode)))) {
                    if (ctrlOrShift && cInfo.select) {
                        // unselect
                        preventDrag = true;
                        this.combatants.unselect(cInfo.id);
                    } else {
                        const ns = this.getActionSelected(cInfo.id, (ctrlOrShift || cInfo.select));
                        newSelected = ns.selected;
                        this.dragging.push(obj);
                        obj.moveToTop();
                        this.startMeasureTool(obj);
                        for (let s of ns.full) {
                            const p = this.findCombatantObj(s);
                            if (p && p.cInfo && (cInfo.id != s)){
                                this.dragging.push(p);
                            }
                        }
                    }
                } else if (obj.pin && this.getPinDraggable(obj.pin)) {
                    if (obj.pin.selected) {
                        this.dragging.push(obj);
                        obj.moveToTop();
                    } else {
                        this.unselectedObj = obj;
                    }
                } else if (obj.handle) {
                    this.dragging.push(obj);
                } else if (obj.dragHandle) {
                    this.startDragHandle(this.dragging, obj);
                } else if (obj.unfogpolygon) {
                    this.saveFog();
                    this.cancelFog();
                    preventDrag=true;
                } else if (obj.regionPoint) {
                    this.startRegionPointDrag(this.dragging, obj);
                } else if (drawObj) {
                    this.moved=true;
                    if (ctrlOrShift && drawObj.selected) {
                        // unselect
                        preventDrag = true;
                        this.unselectDraw(drawObj.index);
                    } else {
                        this.dragging.push(obj);
                        obj.moveToTop();
                        if (ctrlOrShift || drawObj.selected) {
                            const drawSel = this.drawLayer.find(".dls");
                            if (drawSel) {
                                for (let d of drawSel) {
                                    if (d.drawObj.index != drawObj.index) {
                                        this.dragging.push(d)
                                    }
                                }
                            }
                        }
                    }
                }

                if ((this.mode == "region") && !this.fogTool && obj.coverRegion) {
                    this.selectRegion(obj)
                }
            }
            if (!this.dragging.length && !preventDrag) {
                if (this.getStageDraggable(e)) {
                    this.dragging.push(this.stage);
                    this.lastDistance = getDistance(e);
                    this.startPingCheck();
                    if (this.pointerHighlight) {
                        this.pointerHighlight.visible(false);
                    }
                    this.noDragObj=obj;
                } else {

                    switch (this.mode) {
                        case "move":
                        case "select":
                            this.startPingCheck();
                            this.startSelectDrag(this.dragging);
                            this.noDragObj=obj;
                            break;
                        case "region":
                        case "fog":
                            this.startFogDrag(this.dragging);
                            break;
                        case "draw":
                            if (shiftKey) {
                                this.startSelectDrag(this.dragging);
                            } else {
                                this.startDraw(this.dragging);
                            }
                            break;
                    }
                }
            }
            if (this.dragging.length) {
                this.freezeUpdates();
                for (let o of this.dragging) {
                    //console.log("start drag", o.x(), o.y(), o);
                    o.startDrag();
                }
                if (newSelected) {
                    this.clearSelection(true, "combatants");
                    this.combatants.select(newSelected);
                } 
                this.beginPos = pos;
                this.startDragPos = pos;
            }
        }
        this.updateSelection();
    }

    onMouseMove(e) {
        const obj = findObj(e);
        this.moved = true;
        //console.log("stage mouse move",e, obj);
        if (this.pointerHighlight) {
            this.pointerHighlight.position(this.getPointerPosition());
        }
        if (this.fogHitGroup) {
            this.updateRegionHover(obj);
        }
        if (this.eventSync) {
            if (obj.cInfo) {
                this.eventSync.emit("mapToken", obj.cInfo.index);
            } else {
                this.eventSync.emit("mapToken", -1);
            }
        }
        this.setPointerPos();
    }

    onMouseUp(e) {
        const obj = findObj(e);
        //console.log("stage mouse up",e);
        if (this.dragging) {
            if (e.evt?.touches?.length) {
                return;
            }
            //console.log("done dragging",e);

            if (!this.moved) {
                //console.log("stage tap", obj);
                if (this.dragging && this.dragging.length>=1) {
                    const {cInfo,stage,select,drawSelect}= this.dragging[0];
                    if (cInfo) {
                        this.events.emit("tap", cInfo, e);
                    } else if (stage || select) {
                        if (this.mode != "region") {
                            this.clearSelection(true);
                            if (this.unselectedObj) {
                                if (this.unselectedObj.pin) {
                                    this.selectPin(this.unselectedObj.pin.name);
                                }
                            } else {
                                this.events.emit("stagetap", e);
                                this.stageTap = this.stage.getPointerPosition();
                                //console.log("stageTap", this.stageTap);
                            }
                        } else if (!this.hoverRegion) {
                            this.clearSelection(true);
                        }
                    } else if (drawSelect) {
                        this.selectDraw();
                    }
                }
                this.cancelDrag();
            } else {
                this.finishDrag();
            }
        }
        this.measureTarget=null;
        this.cancelPingCheck();
        this.updateSelection();
        if (this.pointerHighlight) {
            this.pointerHighlight.visible(true);
        }
        this.setPointerPos();
    }

    onDragEnd(e) {
        //console.log("drag end");
        if (this.dragging && !this.disableAutoDragEnd) {
            this.onMouseUp(e);
        }
    }

    onDragMove(e) {
        const pos = this.stage.getPointerPosition();
        if (this.beginPos && (Math.abs(pos.x-this.beginPos.x)<2) && (Math.abs(pos.y-this.beginPos.y)<1)) {
            return;
        }
        this.beginPos = null;

        const obj = findObj(e);
        this.moved = true;
        //console.log("drag move",e,obj,!!this.dragging, obj&&obj.stage);
        if (this.pointerHighlight) {
            this.pointerHighlight.position(this.getPointerPosition());
        }

        if (this.dragging) {
            if (obj) {
                if (obj.stage) {
                    const nextDistance = getDistance(e);
                    //console.log("distance", this.lastDistance, nextDistance)
                    if (nextDistance && this.lastDistance) {
                        const mult = this.lastDistance/nextDistance;
                        this.doZoom(mult, this.startDragPos,pos.x-this.startDragPos.x, pos.y-this.startDragPos.y)
                        this.startDragPos = pos;
                    } else if (!nextDistance && !this.lastDistance) {
                        this.mapPos.x = (this.width/2-this.stage.x())/this.scale;
                        this.mapPos.y = (this.height/2-this.stage.y())/this.scale;
                        this.updateStageZoom();
                    } else {
                        this.startDragPos = pos;
                        this.disableAutoDragEnd = true;
                        obj.stopDrag();
                        // try to avoid jumping around
                        this.updateStageZoom();
                        obj.startDrag();
                        this.disableAutoDragEnd = false;
                    }
                    this.lastDistance = nextDistance;
                } else if (obj.handle) {
                    this.dragRotate(obj);
                } else if (obj.dragHandle) {
                    this.dragHandle(obj);
                } else if (obj.select) {
                    this.dragSelect();
                } else if (obj.regionPoint) {
                    this.dragRegionPoint(obj);
                } else if (obj.drawSelect) {
                    this.dragDraw();
                } else if (obj.fogSelect) {
                    this.dragFog();
                }
            }
        }
        this.cancelPingCheck();
        this.updateMeasureTool();
    }

    moveKey(selectionType,mode,ctrlKey,x,y) {
        const base = this.mapPos.diameter/20;

        if (!ctrlKey) {
            switch (selectionType) {
                case "combatants":{
                    const cupdates = [];
                    const selection = this.combatants.getSelection();

                    for (let t in selection) {
                        const p = this.findCombatantObj(t);
                        if (p) {
                            const pc = p.cInfo;
                            const upd = {id:pc.id, x:pc.tokenX+(5*x), y:pc.tokenY+(5*y)};
                            cupdates.push(upd);
                        }
                    }
                    if (cupdates.length) {
                        this.combatants.changeCombatantPositions(cupdates);
                    }
                    return;
                }
                case "pin":{
                    const pupdates = [];
                    for (let i in this.pins) {
                        const p = this.pins[i];
                        if (p.selected) {
                            pupdates.push({name:p.name, x:p.mapPos.x+(base*x), y:p.mapPos.y+(base*y)});
                        }
                    }
                    if (pupdates.length) {
                        this.changePins(pupdates);
                    }
                    return;
                }
                case "drawsel": {
                    const drawing = this.getDrawObjs().concat([]);
                    let foundUpdate;

                    for (let i in drawing) {
                        if ((this.drawSelected||[]).includes(i)) {
                            const o= Object.assign({},drawing[i]);
                            o.points = shiftPoints(x*base, y*base, o.points);
                            drawing[i]=o;
                            foundUpdate=true;
                        }
                    }
                    if (foundUpdate) {
                        this.setMapInfo(null, drawing);
                    }
                    return;
                }
                case "region":
                    return;
            }
        }

        this.mapPos.x -= (x*base);
        this.mapPos.y -= (y*base);
        if (ctrlKey) {
            this.updateSelection();
        } else {
            this.clearSelection();
        }
        this.updateStageZoom();

    }

    onWheel(e) {
        if (!this.dragging) {
            const mult = (e.evt.deltaY > 0)?1.1:1/1.1;
            this.doZoom(mult, this.stage.getPointerPosition());
        }
    }

    doZoom(mult, pivot, diffX, diffY) {
        const newMapPos = Object.assign({}, this.mapPos);
        newMapPos.diameter *= mult;

        const newX = ((this.width/2-pivot.x)*(1-mult)+(diffX||0))/this.scale;
        const newY = ((this.height/2-pivot.y)*(1-mult)+(diffY||0))/this.scale;

        newMapPos.x -=newX;
        newMapPos.y -=newY;
        this.mapPos = newMapPos;
        this.updateStageZoom();
    }

    onMouseEnter(e) {
        const obj = findObj(e);
        //console.log("mouse enter",e,obj);
        if (this.pointerHighlight) {
            this.pointerHighlight.position(this.getPointerPosition());
            this.pointerHighlight.visible(true);
        }
    }

    onMouseLeave(e) { 
        const obj = findObj(e);
        //console.log("mouse leave",e,obj);
        if (this.pointerHighlight) {
            this.pointerHighlight.visible(false);
        }
        clearPointer();
        if (this.eventSync) {
            this.eventSync.emit("mapToken", -1);
        }
    }

    setPointerPos() {
        if (this.variant=="gm") {
            const pos = this.getPointerPosition(1000);
            setPointer(pos.x,pos.y);
        }
    }

    clearPointer() {
        if (this.variant=="gm") {
            clearPointer();
        }
    }

    finishDrag() {
        //console.log("finish drag", this.dragging)
        if (this.dragging) {
            const cupdates = [];
            const pupdates = [];
            const dupdates = [];
            for (let o of this.dragging) {
                if (o.cInfo) {
                    cupdates.push({id:o.cInfo.id, x:o.x(), y:o.y()});
                } else if (o.handle) {
                    const {full} = this.getActionSelected(null, true);
                    for (let t of full) {
                        const p = this.findCombatantObj(t);
                        if (p) {
                            const pc = p.cInfo;
                            const upd = {id:pc.id, x:p.x(), y:p.y()};
                            if (pc.rotation !=null) {
                                upd.rotation=p.rotation();
                            }
                            //console.log("update pos", upd)
                            cupdates.push(upd);
                        }
                    }
                } else if (o.dragHandle) {
                    this.saveDragHandle(o);
                } else if (o.pin) {
                    pupdates.push({name:o.pin.name, x:o.x(), y:o.y()});
                } else if (o.fogSelect) {
                    this.saveFog();
                } else if (o.regionPoint) {
                    this.saveRegionPoint();
                } else if (o.drawSelect) {
                    this.saveDraw();
                } else if (o.drawObj) {
                    dupdates.push(o)
                }
                if (o.pointer) {
                    o.destroy();
                }
            }
            if (cupdates.length) {
                this.combatants.changeCombatantPositions(cupdates);
            }
            if (pupdates.length) {
                this.changePins(pupdates);
            }
            if (dupdates.length) {
                this.performDrawingUpdates(dupdates);
            }
            this.dragging=null;
        }
        this.cancelDrag();
    }

    cancelDrag() {
        const dragging = this.dragging;
        if (dragging) {
            this.dragging = null;
            for (let o of dragging) {
                o.stopDrag();
                if (o.pointer) {
                    o.destroy();
                }
            }
            this.dragging=null;
        }
        this.cancelMeasureDistance();
        this.cancelDragSelect();
        this.cancelDragHandle();
        this.cancelFog(true);
        this.cancelDraw();
        this.cancelMeasureTool();
        this.unfreezeUpdates();
        this.checkChangeMapPos();
    }

    startSelectDrag(dragging) {
        this.clearSelection();
        this.startDragSelect = this.getPointerPosition();
        const points = [this.startDragSelect.x,this.startDragSelect.y];
        const selectRect = new Konva.Line({
            points,
            closed:true,
            stroke:"red",
            strokeWidth:3,
            strokeScaleEnabled:false,
            dash:[6,3],
            listening:false,
        });
        this.selectRect = selectRect;
        this.topLayer.add(selectRect);

        const obj = new Konva.Circle({
            radius:0,
            position:this.startDragSelect,
            name:"select",
            draggable:true,
        });
        obj.select = true;

        this.backgroundLayer.add(obj);
        this.selectPoint = obj;
        dragging.push(obj);
    }

    dragSelect() {
        if (this.selectRect) {
            const pos = this.getPointerPosition();
            const {x,y} = this.startDragSelect;
            const minX = Math.min(x,pos.x), minY=Math.min(y,pos.y), maxX=Math.max(x,pos.x), maxY=Math.max(y,pos.y);
            const points = [x,y,x,pos.y,pos.x,pos.y, pos.x,y];
            this.selectRect.points(points);

            if (this.mode == "draw") {
                const selDraw = [];
                const drawing = this.getDrawingInfo()||[];
                for (let d of drawing) {
                    if (checkBounding(minX,minY, maxX, maxY, d.points)) {
                        selDraw.push(d.index);
                    }
                }
                this.selectDraw(selDraw);
            } else if (this.combatants) {
                const mc = this.combatantMapDetails||[];
                const list = [];
                for (let c of mc) {
                    if (this.getCombatantDraggable(c) &&
                        (c.tokenX <maxX) && (c.tokenX>minX) && (c.tokenY<maxY) && (c.tokenY>minY)
                    ) {
                        list.push(c.id);
                    }
                }
                this.combatants.select(list,false);
            }
        }
    }

    cancelDragSelect() {
        this.selectRect && this.selectRect.destroy();
        this.selectPoint && this.selectPoint.destroy();
        this.selectRect=null;
        this.selectPoint = null;
    }

    startFogDrag(dragging) {
        this.startDragSelect = this.getPointerPosition();
        let newFog;
        if (this.selectedRegion) {
            this.clearSelection();
        }

        switch (this.fogTool) {
            case "rect":
                newFog = {
                    type:"uncover",
                    width:0,
                    height:0,
                    x:this.startDragSelect.x,
                    y:this.startDragSelect.y,
                }
                break;
            case "brush":
                // brush happens immediately
                this.moved = true;
                if (this.hoverRegion) {
                    if (!this.isSelectedRegionUnfogged()){
                        newFog = {
                            type:"polygon",
                            points:this.hoverRegion.points,
                            circlePoints:[this.startDragSelect.x,this.startDragSelect.y],
                            diameter:this.fogDiameter
                        }
                    } else {
                        console.log("already uncovered do nothing");
                    }
                } else {
                    newFog = {
                        type:"circle",
                        points:[this.startDragSelect.x,this.startDragSelect.y],
                        diameter:this.fogDiameter
                    }
                }
                break;
            case "polygon": {
                if (!this.newFog) {
                    this.newFog = {
                        type:"polygon",
                        points:[]
                    }
                }
                const pos = this.snapPosition(this.coverRegions, this.startDragSelect, null, this.newFog.points,-1);
                this.newFog.points = this.newFog.points.concat([pos.x,pos.y]);
                this.updateFogPolygon();
                return;
                break;
            }
        }

        if (newFog) {
            this.newFog = newFog;
            const obj = new Konva.Circle({
                radius:0,
                position:this.startDragSelect,
                name:"fog",
                draggable:true,
            });
            obj.fogSelect = true;

            this.backgroundLayer.add(obj);
            obj.startDrag();
            this.selectPoint = obj;
            dragging.push(obj);
            if (this.moved) {
                // update fog if it should already look like it has moved.
                this.dragFog();
            }
        } else {
            console.log("no fog started",this.fogTool, this.hoverRegion);
        }
        //console.log("started fog", this.newFog);
    }

    isSelectedRegionUnfogged() {
        if (this.hoverRegion) {
            const matches = searchCover(this.coverObjs, this.hoverRegion);
            for (let m of matches) {
                if (!this.coverObjs[m].circlePoints) {
                    return true;
                }
            }
        }
        return false;
    }

    updateFogPolygon() {
        this.tempFog && this.tempFog.destroy();
        this.fogOutline && this.fogOutline.destroy();

        const {points} = this.newFog;
        const outline = new Konva.Group();

        const marker = new Konva.Circle({
            name:"unfogpolygon",
            x:points[0],
            y:points[1],
            radius:8/this.scale,
            stroke:"black",
            fill:"yellow",
            strokeScaleEnabled:false,
            strokeWidth:2
        });
        marker.unfogpolygon=true;

        outline.add(new Konva.Line({
            points:points,
            lineCap:"round",
            lineJoin:"round",
            tension:0,
            stroke:"rgba(255,0,0,0.8)",
            strokeWidth:2,
            strokeScaleEnabled:false,
            listening:false
        }),marker);

        this.tempFog = this.genCover(this.newFog);
        this.coverGroup.add(this.tempFog);
        this.topLayer.add(outline);
        this.fogOutline = outline;

    }

    dragFog() {
        const newFog = this.newFog;
        if (newFog) {
            const pos = this.getPointerPosition();
            this.tempFog && this.tempFog.destroy();
            this.fogOutline && this.fogOutline.destroy();
            switch (newFog.type) {
                case "uncover": {
                    const {x,y} = this.startDragSelect;
                    const minX = Math.min(x,pos.x), minY=Math.min(y,pos.y), maxX=Math.max(x,pos.x), maxY=Math.max(y,pos.y);
                    newFog.x = minX;
                    newFog.y = minY;
                    newFog.width = maxX-minX;
                    newFog.height = maxY-minY;

                    this.fogOutline = new Konva.Rect({
                        x:newFog.x,
                        y:newFog.y,
                        width:newFog.width,
                        height:newFog.height,
                        stroke:"red",
                        strokeWidth:3,
                        strokeScaleEnabled:false,
                        listening:false,
                    }); 
                    break;
                }
                case "circle": {
                    newFog.points = addOptimizeFogPoints((newFog.points||[]).concat(),newFog.diameter, pos);
                    break;
                }
                case "polygon":{
                    if (newFog.circlePoints) {
                        newFog.circlePoints = addOptimizeFogPoints((newFog.circlePoints||[]).concat(),newFog.diameter, pos);
                    }
                    break;
                }
            }
            this.tempFog = this.genCover(newFog);
            this.coverGroup.add(this.tempFog);
            if (this.fogOutline) {
                this.topLayer.add(this.fogOutline);
            }
        }
    }

    saveFog() {
        const newFog = this.newFog;
        if (newFog) {
            let isGood;
            switch (newFog.type) {
                case "uncover":
                    isGood = (newFog.width>1) && (newFog.height>1);
                    break;
                case "circle":
                    isGood=true;
                    break;
                case "polygon":
                    if (newFog.circlePoints) {
                        isGood=true;
                    } else {
                        if (newFog.points.length > 4) {
                            isGood=true;
                        }
                    }
            }

            if (isGood) {
                if (this.mode == "region") {
                    return this.saveNewRegion(newFog);
                }
                const cover = this.getCoverObjs().concat([newFog]);
                this.setMapInfo(cover);
                this.newFog = null;
            }
        }
    }

    cancelFog(notRegion) {
        //console.log("finish fog");
        if (notRegion && this.newFog && (this.newFog.type=="polygon") && !this.newFog.circlePoints) {
            return;
        }
        this.selectPoint && this.selectPoint.destroy();
        this.selectPoint = null;

        this.tempFog && this.tempFog.destroy();
        this.fogOutline && this.fogOutline.destroy();
        this.tempFog=null;
        this.fogOutline=null;
        this.newFog=null;
    }

    clearFog() {
        this.setMapInfo([{type:"uncover", x:-5000000, y:-5000000, width:100000000, height:100000000}]);
    }

    fogAll() {
        this.setMapInfo([]);
    }


    startDraw(dragging) {
        this.startDragSelect = this.getPointerPosition(10);
        const obj = new Konva.Circle({
            radius:0,
            position:this.startDragSelect,
            name:"draw",
            draggable:true,
        });
        obj.drawSelect = true;

        this.backgroundLayer.add(obj);
        obj.startDrag();
        this.selectPoint = obj;
        dragging.push(obj);
        this.newDraw = {
            color:this.drawColor,
            diameter:4/this.scale,
            points:[this.startDragSelect.x, this.startDragSelect.y]
        }
        this.updateDrawing();
    }

    dragDraw() {
        const pos = this.getPointerPosition(10);
        let {points,diameter} = this.newDraw;
        const {x,y} = pos;
        const x2=points[points.length-2],y2=points[points.length-1];

        const dist = Math.sqrt(Math.pow(x - x2,2) + Math.pow(y-y2, 2));
        if ((points.length < 2) || (dist>diameter)) {
            points = points.concat([x,y]);
            this.newDraw.points = points;
            this.updateDrawing();
        }
    }

    saveDraw() {
        const newDraw = this.newDraw;
        if (newDraw && newDraw.points.length>1) {
            const drawObjs = (this.getDrawObjs()).concat([this.newDraw]);
            this.setMapInfo(null, drawObjs);
        }
        this.selectDraw();
    }

    cancelDraw() {
        //console.log("finish fog");
        if (this.newDraw) {
            this.newDraw = null;
            this.selectPoint && this.selectPoint.destroy();
            this.selectPoint = null;
            this.updateDrawing();
        }
    }

    selectRegion(obj) {
        if (!obj) {
            if (this.selectedRegion) {
                this.selectedRegion = null;
                this.updateCover();
            }
            return;
        }
        if (!this.selectedRegion) {
            this.clearSelection();
        }
        this.selectedRegion = obj.coverRegion;
        this.updateCover();
        this.updateSelection();
    }

    dragRegionPoint(obj) {
        const {regionPoint} = obj;
        if (regionPoint) {
            const newMapInfo = Object.assign({}, this.mapInfo);
            const {name,coverRegions} = newMapInfo;
            newMapInfo.coverRegions = newMapInfo.coverRegions.concat();
            const newRegion = Object.assign({}, newMapInfo.coverRegions[regionPoint.region]);
            const pos = this.snapPosition(coverRegions, obj.position(), regionPoint.region,newRegion.points,regionPoint.point);
            newMapInfo.coverRegions[regionPoint.region] = newRegion;
            newRegion.points = newRegion.points.concat();
            newRegion.points[regionPoint.point] = pos.x;
            newRegion.points[regionPoint.point+1] = pos.y;
            this.mapInfo = newMapInfo;
            this.updateCover();
        }
    }

    startRegionPointDrag(dragging, selobj) {
        const {regionPoint} = selobj;
        if (regionPoint) {
            const obj = new Konva.Circle({
                radius:0,
                position:selobj.position(),
                name:"rp",
                draggable:true,
            });
            obj.regionPoint = regionPoint;

            this.backgroundLayer.add(obj);
            obj.startDrag();
            this.selectPoint = obj;
            dragging.push(obj);
        }
    }

    saveRegionPoint() {
        //console.log("save regions", this.mapInfo)
        campaign.updateCampaignContent("maps", this.mapInfo);
    }

    saveNewRegion(newFog) {
        let points;
        //console.log("new region", newFog);
        switch (newFog.type) {
            case "uncover": {
                const {x,y, width,height} = newFog;
                points = [x,y, x+width, y, x+width, y+height, x, y+height];
                break;
            }
            case "polygon": {
                points = newFog.points;
                break;
            }
        }

        if (points) {
            const newMapInfo = Object.assign({}, this.mapInfo);
            newMapInfo.coverRegions = (newMapInfo.coverRegions||[]).concat([{type:"polygon",points}]);
            const index = newMapInfo.coverRegions.length-1;
            this.selectedRegion = {
                name:"cr"+index,
                index
            }
            campaign.updateCampaignContent("maps", newMapInfo);
        }
    }

    deleteSelectedRegion() {
        if (this.selectedRegion) {
            const newMapInfo = Object.assign({}, this.mapInfo);
            newMapInfo.coverRegions = newMapInfo.coverRegions.concat();
            newMapInfo.coverRegions.splice(this.selectedRegion.index,1);
            this.selectedRegion = null;
            campaign.updateCampaignContent("maps", newMapInfo);
        }
    }

    toggleFog(selRegion) {
        if (selRegion) {
            const coverObjs = this.getCoverObjs().concat();
            const foundCover = searchCover(coverObjs, selRegion);
            if (foundCover.length) {
                while (foundCover.length) {
                    // remove old uncover
                    coverObjs.splice(foundCover.pop(),1);
                }
            } else {
                // add new uncover
                coverObjs.push({
                    type:"polygon",
                    points:selRegion.points
                });
            }
            this.setMapInfo(coverObjs);
        }
    }

    getPointerPosition(mult) {
        const pos = this.stage.getPointerPosition();
        if (!pos) {
            return {x:0,y:0}
        }

        return this.screenToMap(pos,mult);
    }

    screenToMap(pos,mult) {
        return {x:this.roundXCoord((pos.x/this.scale+this.viewPort.left),mult), y:this.roundYCoord((pos.y/this.scale+this.viewPort.top),mult)}
    }

    roundXCoord(v,mult) {
        mult=mult||2;
        return Number((Math.round(mult*(v+this.gridxShift))/mult-this.gridxShift).toFixed(3));
    }
    
    roundYCoord(v,mult) {
        mult=mult||2
        return Number((Math.round(mult*(v+this.gridyShift))/mult-this.gridyShift).toFixed(3));
    }
    

    updateSelection() {
        this.events.emit("selection");
    }

    clearSelection(noUpdate,ignore) {
        this.stageTap=null;
        this.cancelDragSelect();
        this.cancelDragHandle();
        if (ignore!="pins") {
            this.selectPin();
        }
        if (ignore!="regions") {
            this.selectRegion();
        }
        if (ignore != "drawing") {
            this.selectDraw();
        }
        if (ignore != "combatants") {
            this.combatants && this.combatants.select(null,null,noUpdate);
        }
        if (!noUpdate) {
            this.updateSelection();
        }
    }

    tabSelection(shiftKey) {
        let scrollIntoView;
        switch (this.mode) {
            case "move":
            case "select":{
                const maxIndex = 1000000;
                const clist = this.combatantMapDetails;
                if (clist && !this.selectedPin) {
                    const selected = this.combatants.hasSelection();
                    let selIndex = shiftKey?maxIndex:-1;
                    if (selected) {
                        const cInfo = clist.find(function (c) {return c.id==selected});
                        if (cInfo) {
                            selIndex = cInfo.index;
                        }
                    }
                    let mIndex = shiftKey?-1:maxIndex, mid;

                    while (!mid) {
                        for (let cInfo of clist) {
                            if (cInfo.canMove && (shiftKey?(cInfo.index < selIndex) && (cInfo.index > mIndex):(cInfo.index > selIndex) && (cInfo.index < mIndex))) {
                                mIndex = cInfo.index;
                                mid = cInfo.id;
                            }
                        }
                        if ((selIndex < 0) || (selIndex ==maxIndex)) {
                            break;
                        } else {
                            selIndex = shiftKey?maxIndex:-1;
                        }
                    }
                    if (mid) {
                        scrollIntoView = true;
                        this.selectCombatants([mid]);
                    }
                } else if (this.pins) {
                    const nPins = this.pins.length;
                    let pos = shiftKey?nPins-1:0;
                    const selected = this.selectedPin;
                    if (selected) {

                        const opos = this.pins.findIndex(function (p) {return p.name==selected});
                        if (opos >= 0) {
                            pos = opos+(shiftKey?-1:+1);
                        }
                        if (pos <0) {
                            pos = nPins-1;
                        } else if (pos >= nPins) {
                            pos=0;
                        }
                    }
                    this.selectPin(this.pins[pos].name);
                    scrollIntoView = true;
                }
                break;
            }
            case "draw": {
                const ndrawObjs = this.getDrawObjs().length;
                if (!ndrawObjs) {
                    return;
                }
                let curObj = shiftKey?ndrawObjs-1:0;
                if (this.drawSelected) {
                    curObj = Number(this.drawSelected[0])+(shiftKey?-1:1);
                }
                if (curObj >=ndrawObjs ) {
                    curObj = 0;
                } else if (curObj < 0) {
                    curObj = ndrawObjs-1;
                }
                this.selectDraw(curObj.toString());
                scrollIntoView=true;
                break;
            }

            case "fog":
                break;
            case "region":{
                if (this.newFog) {
                    this.cancelFog();
                }
                const ncover = this.coverRegions.length;
                if (!ncover) {
                    return;
                }
                let curObj = shiftKey?ncover-1:0;
                if (this.selectedRegion) {

                    curObj = Number(this.selectedRegion.index)+(shiftKey?-1:1);
                }
                if (curObj >=ncover ) {
                    curObj = 0;
                } else if (curObj < 0) {
                    curObj = ncover-1;
                }
                this.selectRegion({coverRegion:this.coverRegions[curObj]})
                scrollIntoView=true;

                break;
            }
        }
        if (scrollIntoView) {
            this.scrollIntoView();
        }
    }

    scrollIntoView() {
        const br = (this.getSelectedPosition()||{}).boundingRect;
        if (br) {
            let dx=0,dy=0;
            if (br.left < 0) {
                dx = (br.left)/this.scale;
            } else if (br.right > this.width) {
                dx = (br.right - this.width)/this.scale;
            }
            if (br.top < 0) {
                dy = br.top/this.scale;
            } else if (br.bottom > this.height) {
                dy = (br.bottom - this.height)/this.scale;
            }
            if (true && (dx || dy)) {
                this.mapPos.x+=dx;
                this.mapPos.y+=dy;
                this.updateStageZoom();
            }
        }
    }

    selectPin(name) {
        if (this.selectedPin != name) {
            if (name) {
                this.clearSelection(true);
            }
            this.selectedPin = name;
            this.updatePins();
            this.updateSelection();
        }
    }

    selectCombatants(list, dontReplace) {
        this.clearSelection(true, dontReplace?"combatants":null);
        this.combatants.select(list,dontReplace);
        this.updateSelection();
    }

    selectDiffCombatants(old) {
        const list=[];
        for (let c of this.combatants.combatants||[]) {
            if (!old[c.id]) {
                list.push(c.id);
            }
        }
        this.selectCombatants(list);
    }

    getCurrentCombatantIds() {
        const cur={};
        for (let c of this.combatants.combatants||[]) {
            cur[c.id]=1;
        }
        return cur;
    }


    getSelectionType() {
        if (this.dragging) {
            return null;
        }
        if (this.stageTap) {
            return "stage";
        }

        if (this.drawSelected) {
            return "drawsel";
        }

        if (this.combatants) {
            const selectCount = Object.keys(this.combatants.selected||{}).length;
            //console.log("select count", selectCount)
            if (selectCount > 0) {
                return "combatants";
            }
        }
        if (this.selectedPin) {
            return "pin";
        }
        if (this.selectedRegion) {
            return "region";
        }
    }

    getNoDragObject() {
        const cInfo = (this.noDragObj||{}).cInfo;
        if (cInfo && cInfo.ctype=="object") {
            return cInfo;
        }
    }

    getSelectedMapPos() {
        const mapPos = Object.assign({}, this.mapPos);
        if (this.stageTap) {
            Object.assign(mapPos, this.screenToMap(this.stageTap))
        }
        mapPos.mapName = this.mapInfo.name;
        return mapPos;
    }

    getSelectedPosition() {
        if (this.stageTap) {
            //console.log("stage tap pos", this.stageTap);
            return {x:this.stageTap.x, y:this.stageTap.y+5};
        }
        
        let boundingRect;

        if (this.drawSelected) {
            const drawSel = this.drawLayer.find(".dls");
            if (drawSel) {
                for (let d of drawSel) {
                    boundingRect = mergeBounding(boundingRect, d.getClientRect());
                }
            }
        }
        if (this.combatants) {
            for (let i in this.combatants.selected) {
                const p = this.findCombatantObj(i);
                if (p) {
                    boundingRect = mergeBounding(boundingRect, p.getClientRect({skipShaddow:true}));
                }
            }
        }
        
        if (this.selectedPin) {
            const p = this.pinGroup.findOne("."+this.selectedPin);
            if (p) {
                boundingRect = mergeBounding(boundingRect, p.getClientRect(),3);
            }
        }
        
        if (this.selectedRegion) {
            const r = this.fogHitGroup.findOne("."+this.selectedRegion.name);
            if (r) {
                boundingRect = mergeBounding(boundingRect, r.getClientRect(),8);
            }
        }

        //console.log("bounding rect", boundingRect);
        if (boundingRect) {
            let x = (boundingRect.left+boundingRect.right)/2;
            let y = boundingRect.bottom;
            return {x,y,boundingRect};
        }
        return null;
    }

    findOverlap(id) {
        if (!this.combatantMapDetails) {
            //console.log("no combatants")
            return null;
        }
        const cInfo = this.combatantMapDetails.find(function (c){return c.id==id});
        if (!cInfo){
            //console.log("could not find id", id);
            return null;
        }
        return findOverlap(cInfo, this.combatantMapDetails,true, cInfo.target);
    }

    snapPosition(coverRegions, pos, ignore, current,currentPos) {
        pos.x=this.roundXCoord(pos.x);
        pos.y=this.roundYCoord(pos.y);
        const {x,y} = pos;
        const distanceMin = Math.max(1.1, 7/this.scale);
    
        //check for a near point
        for (let rc in coverRegions) {
            if (rc != ignore) {
                const region = coverRegions[rc];
                const points = region.points;
                for (let i=0; i<points.length; i+=2) {
                    const dist = Math.sqrt(Math.pow(x - points[i], 2) + Math.pow(y - points[i+1], 2));
                    if (dist < distanceMin) {
                        return {x:points[i], y:points[i+1]};
                    }
                }
            }
        }

        // snap to close lines
        {
            let minX,minY, minXDistance=distanceMin, minYDistance=distanceMin;
            for (let rc in coverRegions) {
                if (rc != ignore) {
                    const region = coverRegions[rc];
                    const points = region.points;
                    let lastX = points[points.length-2], lastY = points[points.length-1];
                    for (let i=0; i<points.length; i+=2) {
                        const nextX = points[i], nextY=points[i+1];

                        if ((nextX == lastX) && between(pos.y, nextY, lastY)) {
                            const distX = Math.abs(pos.x-nextX);
                            if (distX < minXDistance) {
                                minX = nextX;
                                minXDistance = distX;
                            }
                        } else if ((nextY==lastY)&& between(pos.x,nextX, lastX)) {
                            const distY = Math.abs(pos.y-nextY);
                            if (distY < minYDistance) {
                                minY = nextY;
                                minYDistance = distY;
                            }
                        } else if (between(pos.y, nextY, lastY) && between(pos.x,nextX, lastX)) {
                            let slope=(nextY-lastY)/(nextX-lastX);
                            let yIntercept = nextY - nextX * slope;
                            let xIntercept = nextX - nextY / slope;

                            // check x distance
                            const xPos = xIntercept + pos.y /   slope;
                            const distX = Math.abs(pos.x-xPos);
                            if (distX < minXDistance) {
                                minX = xPos;
                                minXDistance = distX;
                            }

                            // check y distance
                            const yPos = yIntercept + pos.x * slope;
                            const distY = Math.abs(pos.y-yPos);
                            if (distY < minYDistance) {
                                minY = yPos;
                                minYDistance = distY;
                            }
                        }
                        lastX=nextX;
                        lastY=nextY;
                    }
                }
            }
            if (minXDistance < minYDistance) {
                if (minXDistance < distanceMin) {
                    pos.x = minX;
                    return pos;
                }
            } else if (minYDistance < distanceMin) {
                pos.y = minY;
                return pos;
            }

        }

        //check for vertical alignment
        if (current) {
            let minX,minY, minXDistance=distanceMin, minYDistance=distanceMin;

            for (let i=0; i<current.length; i+=2) {
                if (i != currentPos) {
                    const distX = Math.abs(x - current[i]);
                    const distY = Math.abs(y-current[i+1]);
                    if (distX < minXDistance) {
                        minX=current[i];
                        minXDistance=distX;
                    }
                    if (distY < minYDistance) {
                        minY=current[i+1];
                        minYDistance=distY;
                    }
                }
            }
            if (minXDistance < distanceMin) {
                pos.x = minX;
            }
            if (minYDistance < distanceMin) {
                pos.y = minY;
            }
        }
        return pos;
    }

    clearUndo() {
        this.drawUndo=[];
        this.drawRedo=[];
        this.coverUndo=[];
        this.coverRedo=[];
        this.updateSelection();
    }

    undoDrawing() {
        if (this.drawUndo.length) {
            this.drawRedo.push(this.getDrawObjs());
            this.setMapInfo(null, this.drawUndo.pop(),true);
            this.selectDraw();
            this.updateSelection();
        }
    }

    redoDrawing() {
        if (this.drawRedo.length) {
            this.drawUndo.push(this.getDrawObjs());
            this.setMapInfo(null, this.drawRedo.pop(),true);
            this.selectDraw();
            this.updateSelection();
        }
    }

    get hasUndoDrawing(){
        return this.drawUndo.length;
    }

    get hasRedoDrawing(){
        return this.drawRedo.length;
    }

    undoCover() {
        if (this.coverUndo.length) {
            this.coverRedo.push(this.getCoverObjs());
            this.setMapInfo(this.coverUndo.pop(), null,true);
            this.selectDraw();
            this.updateSelection();
        }
    }

    redoCover() {
        if (this.coverRedo.length) {
            this.coverUndo.push(this.getCoverObjs());
            this.setMapInfo(this.coverRedo.pop(), null,true);
            this.selectDraw();
            this.updateSelection();
        }
    }

    get hasUndoCover(){
        return this.coverUndo.length;
    }

    get hasRedoCover(){
        return this.coverRedo.length;
    }

    setMapInfo(coverObjs,drawObjs,noUndo) {
        if (drawObjs && !noUndo) {
            this.drawUndo.push(this.getDrawObjs());
            this.drawRedo=[];
            this.updateSelection();
        }
        if (coverObjs && !noUndo) {
            this.coverUndo.push(this.getCoverObjs());
            this.coverRedo=[];
            this.updateSelection();
        }
        if (!campaign.isCampaignGame()) {
            const mapInfo=campaign.getMapInfo(this.mapInfoName);
            if (!mapInfo) {
                console.log("why is mapinfo blank?", this.mapInfoName);
                return;
            }

            const newMapInfo=Object.assign({}, mapInfo);
            if (coverObjs != null) {
                newMapInfo.coverObjs = coverObjs;
            }
            if (drawObjs != null) {
                newMapInfo.drawObjs = drawObjs;
            }

            campaign.updateCampaignContent("maps", newMapInfo);
        } else {
            const newExtra=Object.assign({},campaign.getMapExtraInfo(this.mapInfoName)||{name:this.mapInfoName});
            if (coverObjs != null) {
                newExtra.coverObjs = coverObjs;
                this.events.emit("changeMapExtra");
            }
            if (drawObjs != null) {
                newExtra.drawObjs = drawObjs;
                this.events.emit("changeMapExtra");
            }

            newExtra.mapPos = this.mapPos||null;
            campaign.updateCampaignContent("mapextra", newExtra);
        }
    }
}

function searchCover(coverObjs, cr) {
    const list=[];
    if (cr) {
        for (let i in coverObjs) {
            const o = coverObjs[i];
            if (o.type=="polygon" && areSameDeep(o.points,cr.points)) {
                list.push(i);
            }
        }
    }
    return list;
}


function addOptimizeFogPoints(fogPoints, diameter, pos) {
    const usePos = roundPos(pos,diameter);
    if ((fogPoints.length <2) || (fogPoints[fogPoints.length-2]!=usePos.x) || (fogPoints[fogPoints.length-1]!=usePos.y)) {
        fogPoints = fogPoints.concat([usePos.x, usePos.y]);
    }
    if (fogPoints.length >= 6) {
        const minDist = diameter/20; //0.25 for 5 ft
        const closePoint = diameter/5; //1 for 5ft
        const p = fogPoints.length -6;
        const x1 = fogPoints[p];
        const y1 = fogPoints[p+1];
        const x2 = fogPoints[p+2];
        const y2 = fogPoints[p+3];
        const x3 = fogPoints[p+4];
        const y3 = fogPoints[p+5];
        const dx = x3-x1;
        const dy = y3-y1;
        const d2x = x2-x1;
        const d2y = y2-y1;

        if ((Math.abs(d2x)<=closePoint) && (Math.abs(d2y)<=closePoint)) {
            fogPoints.splice(p+2,2);
        } else if (Math.abs(dy)>Math.abs(dx)) {
            if ((dy>0)?((y2>=y1)&&(y3>=y2)):((y2<=y1)&&(y3<=y2))) {
                const cx = d2y*dx/dy;
                if (Math.abs(cx-d2x) < minDist) {
                    fogPoints.splice(p+2,2);
                }
            }
        } else {
            if ((dx>0)?((x2>=x1)&&(x3>=x2)):((x2<=x1)&&(x3<=x2))) {
                const cy = d2x*dy/dx;
                if (Math.abs(cy-d2y) < minDist) {
                    fogPoints.splice(p+2,2);
                }
            }
        }
    }
    return fogPoints;
}

function roundPos(pos,diameter){
    const round = Math.trunc(diameter/5);
    return {x:Math.round(pos.x*round)/round, y:Math.round(pos.y*round)/round};
}

function between(v,p1,p2) {
    return ((v>=Math.min(p1,p2)) && (v<=Math.max(p1,p2)));
}

function getDistance(e) {
    const {touches} = e.evt;
    if (touches) {
        const t1 = touches[0];
        const t2 = touches[1];

        if (t1 && t2) {
            return Math.sqrt(Math.pow(t2.screenX - t1.screenX, 2) + Math.pow(t2.screenY - t1.screenY, 2));
        }
    }
    return 0;
}

function checkBounding(minX, minY, maxX, maxY, points) {
    if (!points) {
        return false;
    }
    for (let i=0; i<points.length; i+=2) {
        const x=points[i], y=points[i+1];
        if ((x<minX)||(x>maxX)||(y<minY)||(y>maxY)) {
            return false;
        }
    }
    return true;
}

function mergeBounding(boundingRect, rect,adjust) {
    const right = rect.x+rect.width, bottom = rect.y+rect.height+(adjust||0);
    if (!boundingRect) {
        boundingRect = {left:rect.x, right, top:rect.y, bottom};
    } else {
        boundingRect.left = Math.min(boundingRect.left, rect.x)
        boundingRect.right = Math.max(boundingRect.right, right);
        boundingRect.top = Math.min(boundingRect.top,rect.y);
        boundingRect.bottom = Math.max(boundingRect.bottom, bottom);
    }
    return boundingRect;
}

function shiftPoints(x,y, points) {
    const newPoints = [];
    for (let i=0; i<points.length; i+=2) {
        newPoints.push(points[i]+x);
        newPoints.push(points[i+1]+y);
    }
    return newPoints;
}

function findObj(e) {
    let cur = e && e.target;
    while (cur && !cur.name()) {
        cur = cur.parent;
    }
    if (!cur) {
        return null;
    }
    //console.log("found obj", !!cur.cInfo, !!cur.pin, cur.name())
    return cur;
}

function removeFromGroup(children, list) {
    for (let chi=0; chi<children.length;) {
        const ch = children[chi];
        const id = ch.name();
        const c = list && list.find(function(a){return (a.id||a.name)==id});
        if (!c) {
            ch.destroy();
        } else {
            chi++;
        }
    }

}

class EncounterCombatants {
    constructor (name, playerView) {
        this.name = name;
        this.playerView = playerView;
        this.onCombatantsChange = this.onCombatantsChange.bind(this);
        this.onOtherChange = this.onOtherChange.bind(this);
        this.eventSync = new EventEmitter();

        if (name == "campaign") {
            globalDataListener.onChangeCampaignContent(this.onCombatantsChange, "adventure");
            globalDataListener.onChangeCampaignContent(this.onOtherChange, "players");
        } else {
            globalDataListener.onChangeCampaignContent(this.onCombatantsChange, "plannedencounters");

        }
        globalDataListener.onChangeCampaignContent(this.onOtherChange, "monsters");
        this.load();
        this.selected = {};
    }

    destroy() {
        if (this.name == "campaign") {
            globalDataListener.removeCampaignContentListener(this.onCombatantsChange, "adventure");
            globalDataListener.removeCampaignContentListener(this.onOtherChange, "players");
        } else {
            globalDataListener.removeCampaignContentListener(this.onCombatantsChange, "plannedencounters");
        }
        globalDataListener.removeCampaignContentListener(this.onOtherChange, "monsters");
        this.delay && clearTimeout(this.delay);
        this.eventSync.removeAllListeners();
    }

    changeEncounter(name) {
        if (this.name == name) {
            return;
        }
        if ((this.name == "campaign") || (name=="campaign")) {
            throw (new Error("cannot change from campaign"))
        }

        this.name=name;
        this.selected = {}
        this.load();
        this.eventSync.emit("change");
    }

    onCombatantsChange() {
        this.needLoad=1;
        this.delayChange();
    }

    delayChange() {
        if (!this.delay) {
            this.delay = setTimeout(this.doUpdate.bind(this),10);
        }
    }

    doUpdate(force) {
        let old;
        this.delay=null;
        if (this.needLoad||force) {
            old=this.combatants;
            this.load();
            this.needLoad=0;
        }
        if (this.alwaysLoad, !areSameDeep(old, this.combatants)) {
            this.eventSync.emit("change");
            this.alwaysLoad=0;
        }
    }

    onOtherChange() {
        this.alwaysLoad=1;
        this.delayChange();
    }

    load() {
        let combatants = [];
        if (this.name == "campaign") {
            const adventure = campaign.getAdventure();
            const positions = adventure.positions;
            combatants = adventure.combatants || [];

            if (positions) {
                combatants = combatants.concat();

                for (let i in combatants) {
                    let c = combatants[i];
                    const p = positions[c.id];
                    if (p) {
                        c = Object.assign({},c);
                        combatants[i]=c;
                        c.tokenX=p.tokenX;
                        c.tokenY=p.tokenY;
                        if ((c.tokenX==Number.POSITIVE_INFINITY) || (c.tokenY==Number.POSITIVE_INFINITY)) {
                            c.tokenMap=null;
                        }
                        if (p.rotation != null) {
                            c.rotation=p.rotation;
                        } else {
                            delete c.rotation;
                        }
                    }
                }
            }
        } else {
            const encounter = campaign.getPlannedEncounterInfo(this.name);
            if (encounter) {
                combatants = encounter.combatants||[];

                for (let c of combatants) {
                    delete (c||{}).cInfo;
                }
            }
        }
        //console.log("load combatants", combatants)
        this.combatants=combatants;
        if (this.selected) {
            for (let id in this.selected) {
                const index = combatants.findIndex(function (c) {return c.tokenMap && (c.id==id)});
                if (index < 0) {
                    delete this.selected[id];
                }    
            }
        }
    }

    getCombatant(id) {
        const combatants = this.combatants || [];
        for (let c of combatants) {
            if (c.id == id) {
                return c;
            }
        }
        return null;
    }

    setCombatant(cInfo) {
        const combatants = this.combatants.concat([]);
        const index = combatants.findIndex(function (c) {return c.id==cInfo.id});
        if (index >=0) {
            combatants[index] = cInfo;
        } else {
            combatants.push(cInfo);
        }
        this.changeCombatants(combatants);
    }

    deleteCombatant(id) {
        const combatants = this.combatants.concat([]);
        const index = combatants.findIndex(function (c) {return c.id==id});
        if (index >=0) {
            combatants.splice(index,1);
            this.changeCombatants(combatants);
        }
    }

    deleteSelected() {
        const combatants = this.combatants.concat([]);
        for (let id in this.selected) {
            const index = combatants.findIndex(function (c) {return c.id==id});
            if (index >=0) {
                combatants.splice(index,1);
            }    
        }
        this.changeCombatants(combatants);
        this.select();
    }

    updateSelected(field, val) {
        const combatants = this.combatants.concat([]);
        for (let i in combatants) {
            if (this.selected[combatants[i].id]) {
                const c = Object.assign({}, combatants[i]);
                c[field]=val;
                combatants[i] = c;
            }
        }
        this.changeCombatants(combatants);
    }

    adjustSelectedHP(adjust) {
        const combatants = this.combatants.concat([]);
        let changed;

        for (let i in combatants) {
            let cInfo = combatants[i];
            if (this.selected[cInfo.id]) {
                const crow = new Combatant(cInfo);
                cInfo = crow.adjustValue("hp", null, adjust)
                if (cInfo) {
                    changed=true;
                    combatants[i] = cInfo;
                }
            }
        }
        if (changed) {
            this.changeCombatants(combatants);
        }
    }

    addConditionSelected(conditionInfo) {
        const combatants = this.combatants.concat([]);
        let changed;

        for (let i in combatants) {
            let cInfo = combatants[i];
            if (this.selected[cInfo.id]) {
                const crow = new Combatant(cInfo);

                let c = Object.assign({}, crow.conditions||{});
                addConditionInfoToConditions(c, conditionInfo);
    
                cInfo = crow.changeValue("conditions", c)
                if (cInfo) {
                    changed=true;
                    combatants[i] = cInfo;
                }
            }
        }
        if (changed) {
            this.changeCombatants(combatants);
        }
    }

    doHuddle(mapPos, group) {
        const combatants = this.combatants.concat([]);
        const list = [];

        for (let i in combatants) {
            const ci = combatants[i];
            const cInfo = (new Combatant(ci)).cInfo;

            if ((group == cInfo.group)&&((ci.state||"active")=="active")) {
                const c = Object.assign({}, combatants[i]);
                c.tokenMap = mapPos.mapName;
                findFreeSpot(mapPos.mapName, combatants, mapPos.x, mapPos.y, c, i)
                combatants[i] = c;
                list.push(c.id);
            }
        }
        this.changeCombatants(combatants);
        this.select(list);
    }

    selectGroup(group) {
        const combatants = this.combatants;
        const list = [];

        for (let i in combatants) {
            const ci = combatants[i];
            const cInfo = (new Combatant(ci)).cInfo;

            if ((group == cInfo.group)&&((ci.state||"active")=="active")) {
                list.push(ci.id);
            }
        }
        this.select(list);
    }

    changeCombatants(newC) {
        //console.log("change combatants", newC)
        if (this.name == "campaign") {
            const adventure = Object.assign({},campaign.getAdventure());
            fixupCombatants(newC)
            adventure.combatants = newC;
            delete adventure.positions;
            campaign.updateCampaignContent("adventure", adventure);
        } else {
            const encounter = campaign.getPlannedEncounterInfo(this.name);
            if (encounter) {
                for (let e of newC||[]) {
                    delete (e||{}).cInfo;
                }
        
                encounter.combatants = newC;
                campaign.updateCampaignContent("plannedencounters", encounter);
            } else {
                console.log("encounter not found for update", this.name);
            }
        }
        this.combatants = newC;
    }

    changeCombatantPositions(vals) {
        //console.log("change pos", vals);
        const combatants = this.combatants.concat([]);
        const update={};

        for (let i in vals) {
            const a = vals[i];
            const index = combatants.findIndex(function (c) {return c.id == a.id});
            if (index>=0) {
                const c = Object.assign({},combatants[index]);
                combatants[index]=c;

                c.tokenX=a.x;
                c.tokenY=a.y;
                if (a.rotation != null) {
                    c.rotation=a.rotation;
                    update["positions."+c.id] = {tokenX:a.x, tokenY:a.y, rotation:a.rotation};
                } else {
                    c.rotation=(c.rotation!=null)?c.rotation:null;
                    update["positions."+c.id] = {tokenX:a.x, tokenY:a.y, rotation:c.rotation};
                }
            }
        }
        if (this.name == "campaign") {
            this.combatants=combatants;
            campaign.updateAdventure(update);
            this.eventSync.emit("change");
        } else {
            this.changeCombatants(combatants);
        }
    }

    hoverChange(index) {
        if (this.hoverIndex  != index) {
            this.hoverIndex = index;
            this.eventSync.emit("change");
        }
    }

    getMapDetails(mapName) {
        const settings = campaign.getGameState();
        const names = settings.names||"full";
        const shared = ((settings.sharing||"readonly")=="open");
        const retDead = [], retActive=[], retInactive = [], retTurn=[], retObj=[], retMove=[], retMoveObj=[];
        const playerView = this.playerView;

        let num=1;
        for (let i in this.combatants) {
            const c=this.combatants[i];
            let cnum=null;
    
            if ((c.ctype != "object") && (!c.state || (c.state=="active")) && !c.hidden) {
                cnum=num;
                num++;
            }
    
            if (((c.tokenMap||"").toLowerCase() == mapName) && (!c.hidden || !playerView)) {
                const info = {
                    id:c.id, 
                    index:Number(i),
                    number:cnum,
                    canMove:true,
                    hideName:c.hideName,
                    ctype:c.ctype,
                    type:c.type,
                    hidden:c.hidden,
                    tokenX:c.tokenX,
                    tokenY:c.tokenY,
                    height:c.height,
                    width:c.width,
                    diameter:c.diameter,
                    fill:c.fill,
                    opacity:c.opacity,
                    currentTurn:c.currentTurn,
                    rotation:c.rotation,
                    select:this.selected[c.id],
                    hover:i==this.hoverIndex,
                    extraOffset:c.extraOffset,
                    showNames:names,
                    startPos:c.startPos,
                    anchorId:c.anchorId,
                    target:c.target,
                    actionInfo:c.actionInfo
                };

                Object.assign(info, getTokenInfo(c));

                if (playerView) {
                    switch (c.ctype) {
                        case "object":{
                            if (!c.playerControlled) {
                                // objects that are not player controlled cannot be moved by players
                                info.canMove = false;
                            } else {
                                info.player = !!campaign.getMyCharacterInfo(c.characterName);
                            }
                            break;
                        }
                        case "pc":
                        case "cmonster": {
                            if (!shared) {
                                // if not shared then you can only move your own characters
                                info.canMove = !!campaign.getMyCharacterInfo(c.name);
                            }
                            info.player = info.canMove;
                            break;
                        }
                        default:
                            info.canMove = false;
                            break;
                    }
                }

                let ret;
                if (info.select || info.actionInfo) {
                    if (c.ctype == "object") {
                        if (!c.anchorId) {
                            ret=retMoveObj;
                        } else {
                            ret=retObj;
                        }
                    } else {
                        ret=retMove;
                    }
                } else if (c.ctype == "object") {
                    ret = retObj;
                } else {
                    switch (c.state) {
                        case "inactive":
                            ret = retInactive;
                            break;
                        case "active":
                        default:
                            if (c.type && (info.hp==0)) {
                                ret = retDead;
                            } else {
                                ret = retActive;
                            }
                            break;
                    }

                    if (c.currentTurn) {
                        ret = retTurn;
                    }
                }

                ret.push(info);
            }
        }
        //console.log("combatants",retObj.concat(retDead,retInactive,retActive,retTurn,retMoveObj,retMove));
        return retObj.concat(retDead,retInactive,retActive,retTurn,retMoveObj,retMove);
    }

    getSelection() {
        return this.selected;
    }

    hasSelection() {
        for (let i in this.selected) {
            return i;
        }
        return false;
    }

    hasMultiSelection() {
        let count=0;
        for (let i in this.selected) {
            count++;
            if (count > 1) {
                return true;
            }
        }
        return false;
    }

    select(selList, dontReplace, dontNotify) {
        if (!dontReplace) {
            this.selected = {};
        }
        if (selList) {
            for (let s of selList) {
                this.selected[s]=true;
            }
        }
        this.eventSync.emit("change",dontNotify);
    }

    unselect(id) {
        delete this.selected[id];
        this.eventSync.emit("change");
    }

    isSelected(id) {
        return !!this.selected[id];
    }

    addMonsters(selList, mapPos, monsterTokens,updateSelection) {
        const combatants = (this.combatants||[]).concat([]);

        addMonsters(combatants, selList, mapPos, mapPos.mapName,null, monsterTokens);
        this.changeCombatants(combatants);
    }
}

const warningSVGpaths = [
    {
        data:"m46.356 0.062c-1.504 0.232-2.826 1.121-3.582 2.438l-42.108 72.949c-0.88 1.533-0.895 3.441 0 4.986 0.896 1.545 2.559 2.514 4.358 2.512h84.216c1.801 0.002 3.463-0.967 4.359-2.512 0.895-1.545 0.879-3.453 0-4.986l-42.109-72.949c-1.035-1.803-3.085-2.76-5.134-2.438z",
        fill:"#FDEE1C"
    },{
        data:"m46.744 2.121c-0.814 0.127-1.508 0.617-1.9 1.301l-42.4 73.449c-0.465 0.809-0.466 1.846 0 2.65 0.474 0.816 1.348 1.35 2.3 1.35h84.801c0.951 0 1.826-0.533 2.299-1.35 0.467-0.805 0.465-1.842 0-2.65l-42.4-73.449c-0.545-0.95-1.598-1.475-2.7-1.301zm0.4 8.449l36 63.4h-72.051l36.051-63.4z",
        fill:"#010101"
    }, {
        data:"m46.932 34.322l-1.95 11.35-8-8.35 4.5 10.65-11.2-2.75 9.5 6.551-10.899 3.75 11.5 0.35-7.101 9.049 9.9-5.898-1.1 11.449 5.1-10.301 5.3 10.201-1.301-11.451 9.951 5.75-7.25-8.898 11.5-0.551-10.951-3.6 9.402-6.701-11.152 2.9 4.25-10.65-7.799 8.451-2.2-11.301zm0.2 11.701c3.514 0 6.35 2.836 6.35 6.35s-2.836 6.4-6.35 6.4c-3.515 0-6.351-2.887-6.351-6.4s2.837-6.35 6.351-6.35zm0 0.949c-3.002 0-5.4 2.398-5.4 5.4s2.398 5.449 5.4 5.449 5.451-2.447 5.451-5.449-2.449-5.4-5.451-5.4z",
        fill:"#010101"
    }
]

function addCondition(group) {
    for (let p of warningSVGpaths) {
        const path = new Konva.Path(p);
        group.add(path);
    }
    return group;
}

function showConditionIndicator(conditions) {
    for (let i in conditions) {
        const c = conditions[i];
        if (typeof c == "object") {
            if (!c.hideIndicator) {
                return true;
            }
        } else {
            return true;
        }
    }
    return false;
}

function findValue(values, value) {
    for (let v of values) {
        if (v.value == value) {
            return v.displayName||v.name;
        }
    }
}

const fogToolDiameters=[
    {name:"None",value:"none"},
    {name:"5ft diameter",value:5},
    {name:"10ft diameter",value:10},
    {name:"20ft diameter",value:20},
    {name:"40ft diameter",value:40},
    {name:"60ft diameter",value:60},
];

class ColorToken extends React.Component {

    render() {
        const {color,className, onClick} = this.props;

        return <span className={"dib "+(className||"")} onClick={onClick}><span className="grow-large" style={{display:"inline-block", height:16, width:16, borderRadius:8, borderStyle:"solid", borderWidth:2, borderColor:"white", backgroundColor:color}}/></span>;
    }
}

function updateSharedCampaign() {
    const curMapInfo = campaign.getAdventureView()||{};
    const selmap = (campaign.getPrefs().selectedMap||"").toLowerCase();
    const saveView = {};
    let update=false;

    const mapInfo = campaign.getMapInfo(selmap)||{};
    const mapExtra = campaign.getMapExtraInfo(selmap)||{};


    const art = campaign.getArtInfo(mapInfo.art)||mapInfo;
    //console.log("get art", art);
    let pixels = 70;
    let gridSize = 5;
    let pixelMult=1;
    
    if (mapInfo.pixelsPerGrid) {
        pixels = Number(mapInfo.pixelsPerGrid);
    }

    if (mapInfo.gridSize) {
        gridSize = Number(mapInfo.gridSize);
    }

    if (mapInfo.units == "miles") {
        gridSize = gridSize*5280;
    }

    if (art.originalWidth && (art.originalWidth != art.imgWidth)) {
        pixelMult = art.imgWidth/art.originalWidth;
    }

    pixels = pixels*pixelMult;
    const mult = gridSize/pixels;

    let pixelsPerFoot = 1/mult,
    gridxShift = (mapInfo.gridxShift||0)*mult*pixelMult,
    gridyShift = (mapInfo.gridyShift||0)*mult*pixelMult;

    let useArt=art;
    let altImage = mapExtra.altImage;
    if (altImage && (mapInfo.artList||[]).includes(altImage)) {
        useArt = campaign.getArtInfo(altImage) || art;
    }

    const coverObjs = mapExtra.coverObjs || mapInfo.coverObjs;
    if (!areSameDeep(curMapInfo.coverObjs,coverObjs)) {
        saveView.coverObjs = coverObjs||null;
        update=true;
    }

    const drawObjs = mapExtra.drawObjs || mapInfo.drawObjs;
    if (!areSameDeep(curMapInfo.drawObjs,drawObjs)) {
        saveView.drawObjs = drawObjs||null;
        update=true;
    }

    if (curMapInfo.imageName != selmap) {
        saveView.imageSrc = useArt.url;
        saveView.imageName = selmap || null;
        update=true;
    }

    if (curMapInfo.pixelsPerFoot != pixelsPerFoot) {
        saveView.pixelsPerFoot = pixelsPerFoot||0;
        update=true;
    }

    if (curMapInfo.gridSize != gridSize) {
        saveView.gridSize = gridSize||5;
        update=true;
    }

    if (curMapInfo.gridxShift != gridxShift) {
        saveView.gridxShift = gridxShift||0;
        update=true;
    }

    if (curMapInfo.gridyShift != gridyShift) {
        saveView.gridyShift = gridyShift||0;
        update=true;
    }

    let mapPos = mapExtra.mapPos||null;
    if (mapPos && !mapPos.mapName) {
        mapPos = Object.assign({mapName:selmap}, mapPos);
    }
    if (!areSameDeep(curMapInfo.mapPos,mapPos)) {
        saveView.mapPos =mapPos;
        update=true;
    }

    const pinList = [];
    const pins = campaign.getPins();
    for (let i in pins) {
        let p = pins[i];

        if ((p.mapPos.mapName||"").toLowerCase()==selmap) {
            pinList.push(p);
        }
    }

    if (!areSameDeep(curMapInfo.pinList,pinList)) {
        saveView.pinList =pinList||null;
        update=true;
    }

    if (update) {
        campaign.updateAdventureView(saveView);
    }

}

function getHealthGroup(hp, hpMax) {
    if (hpMax >= 1000) {
        return ""
    }
    const health = (hp||0)/(hpMax||1);
    if (health || hpMax) {
        if (health > 0.75) {
            //healthColor="green";
        } else if (health > 0.5) {
            //healthColor="gold";
        } else if (health > 0.25) {
            return "orange";
        } else {
            return "red";
            // don't let bar get too small
        }
    }
    return "";
}

const colorBlack = '#000000';
const colorWhite = '#ffffff';
const colorDrawList = [
    colorBlack, //Black
    '#8B4513', //Brown
    '#194d33', //Green
    '#653294', //Purple

    '#4d4d4d', //Gray30
    '#D2B48C', //Tan
    '#808900', //Olive
    '#0062b1', //Blue

    '#999999', //Gray60
    '#fe9200', //Orange
    '#68bc00', //Christi Green
    '#73d8ff', //Sky Blue

    colorWhite, //White
    '#fcc400', //Yellow
    '#d33115', //Red
    '#fda1ff', //Pink
];

const MapViewSize = sizeMe({monitorHeight:true, monitorWidth:true})(MapView);
export {
    MapViewSize as MapView
};
const React = require('react');
const {campaign} = require('../lib/campaign.js');
const docx = require("docx4js").docx;
const createDomPurify = require('dompurify');
const DOMPurify = createDomPurify();

import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

async function importBook(file) {
    //console.log("file", file.name, file.type);
    const batch=[];
    let ret;
    if (file.type == "text/html") {
        const children = await importHTML(file);
        ret = computeBook(null, null, children);
        //console.log("ret", ret);
        //throw new Error("nyi")
    } else {
        const dx = await docx.load(file)
        styleList={};
        ret = dx.render(nodeMapper);
        //console.log("styleList",styleList);
        styleList={};
    }

    const id=campaign.newUid();
    //console.log("rendered", ret);

    batch.push({contentType:"books", value:{name:id, type:"book", chapters:ret.chapters}});
    for (let i in ret.fragments) {
        batch.push({contentType:"books", value:ret.fragments[i]});
    }
    return batch;
}

async function importHTML(file) {
    let html = await file.text();
    const frame= document.createElement('div');
    html = DOMPurify.sanitize(html, { USE_PROFILES: { html: true }, FORBID_TAGS: ["form", "input", "label", "button", "canvas", "applet", "area","audio","dialog","embed","img","link","object","select","option","svg","video","track","style"] });
    frame.innerHTML = html;
    const children = calcChildren(frame);
    return children;
}

function calcChildren(frame) {
    const stack = [];
    const children=[];
    let acc="";
    enumSub(frame);
    return children;

    function enumSub(node) {
        const childNodes = node.childNodes;
        for (let i=0; i<childNodes.length; i++) {
            const cn = childNodes[i];
            let ntext;
            if (cn.nodeType==1) {
                switch (cn.tagName) {
                    case "SPAN":
                    case "DIV": {
                        const node = cn.tagName.toLowerCase();
                        acc=`${acc}<${node}>`;
                        stack.push(cn.tagName.toLowerCase());
                        enumSub(cn);
                        stack.pop();
                        ntext = `</${node}>`;
                        if (!stack.length) {
                            ntext = acc+ntext;
                            acc=""
                        }
                        break;
                    }
                    case "H1":
                    case "H2":
                    case "H3":
                    case "H4":
                    case "H5":
                    case "H6":{
                        if (stack.length) {
                            // create node before
                            const pre=[];
                            const post=[];
                            for (let n of stack) {
                                pre.push(`<${n}>`);
                                post.unshift(`</${n}>`);
                            }
                            children.push(stripOuter(`${acc}${post.join("")}`));
                            acc=pre.join("");
                        }
                        children.push({type:"heading",text:cn.textContent, outline:cn.tagName.substr(1)});
                        break;
                    }
                    default: {
                        ntext=cn.outerHTML;
                        break;
                    }
                }
            } else if (cn.nodeType==3) {
                ntext = cn.textContent;
                if (ntext) {
                    ntext=ntext.replace(/\n/g,"");
                }
            }
    
            if (ntext !=null) {
                if (stack.length) {
                    acc=acc+ntext;
                } else {
                    if (ntext.length) {
                        children.push(stripOuter(ntext));
                    }
                    acc="";
                }
            }
        }
    }
}

function stripOuter(html) {
    return html.replace(/(\<div\>\s*\<\/div\>|\<p\>\s*\<\/p\>)/g,"");
    const node= document.createElement('div');
    node.innerHTML = html;
    const children = node.childNodes;
    if ((children.length==1) && ["DIV","SPAN"].includes(children[0].tagName)) {
        //console.log("stripped", children[0].tagName, children[0].innerHTML)
        return stripOuter(children[0].innerHTML);
    } else {
        //console.log("skipping", children.length, (children.length>0)?children[0].tagName:"", html)
    }
    return html;
}

let styleList = {};

const mapTable = {
    hyperlink:textRun,
    t:textRun,
    r:formattedTextRun,
    p:paragraph,
    heading:heading,
    document:computeBook,
    section:collapselevel,
    style:styleElement,
    list:listElement,
    tbl:tableElement,
    tc:tableData,
    tr:tableRow,

    cr:function() {return '<br>'},
    br:function() {return '<br>'},
    softHyphen:function() {return '-'},
    noBreakHyphen:function() {return '&#8209;'},
    "control.docPartObj":empty,
    sym:empty,
    tab:function() {return '&nbsp;&nbsp;&nbsp;&nbsp;'},
    proofErr:emptyText,
    lastRenderedPageBreak:emptyText,
    instrText:emptyText,
    bookmarkStart:emptyText,
    bookmarkEnd:emptyText,
    AlternateContent:empty
}

function nodeMapper(type, props, children) {
    const fn = mapTable[type];
    //console.log("type", type, props, children);
    if (fn) {
        return fn(type,props,children);
    } else {
        if (props && props.node && (props.node.name == "w:fldChar")) {
            //console.log("ignore fldchar", type);
            return null;
        }
        //console.log("unhandled type", type);
        return {type, props, children};
    }
}

function childrenMerge(children, force) {
    let inside = "";
    let acc=[];
    
    for (let i in children) {
        let c;
        if (typeof children[i] == "string") {
            c = children[i];
        } else if (children[i].text) {
            c =  children[i].text;
        } else {
            console.log("unexpected object in paragraph", children[i]);
        }
        if (c) {
            if (c.includes("<")) {
                inside = inside+labelRolls(acc.join(""))+c;
                acc=[];
            } else {
                acc.push(c);
            }
        }
    }
    if (force || inside.includes("<")) {
        inside = inside+labelRolls(acc.join(""));
    } else {
        inside = inside+acc.join("");
    }
    return inside;
}

function paragraph(type,props, children) {
    let inside = childrenMerge(children);

    const style = getStyle(props.pr);
    const si = Object.assign({},styleList[style]||{});

    if (si?.heading) {
        return {type:"heading", outline:si.heading, text:getHeaderText(inside)}; 
    }
    if (style) {
        if (style.startsWith("Heading")) {
            const outline = Number(style.substr(7));
            if (outline>0) {
                return {type:"heading", outline, text:getHeaderText(inside)}; 
            }
        }
    }
    
    var align = getTextAlign(props.pr);
    if (!align && style == "center") {
        align="center";
    } else if (!align && style == "right") {
        align="right";
    }

    getEmbeddedStyleInfo(props.pr,si);
    if (si) {
        //console.log("paragraph style", style,si, inside);
        if (si.bold) {
            inside = "<b>"+inside+"</b>";
        }
        if (si.italic) {
            inside = "<i>"+inside+"</i>";
        }
        if (si.strike) {
            inside = "<s>"+inside+"</s>";
        }
        if (si.underscore) {
            inside = "<u>"+inside+"</u>";
        }
    }

    inside = (align?'<p style="text-align:'+align+'">':'<p>')+inside+"</p>";
    
    if (si && si.inset) {
        inside = "<blockquote>"+inside+"</blockquote>";
    }
    return inside;
}

function getEmbeddedStyleInfo(pr, style) {
    if (!pr || !pr.children) {
        return null;
    }
    for (let i in pr.children) {
        if (pr.children[i].name="w:pStyle") {
            const paraChildren = pr.children[i].children;

            const found = {};
            for (let x in paraChildren) {
                const f = paraChildren[x];
                switch (f.name) {
                    case "w:u":
                        style.underscore = true;
                        break;
                    case "w:strike":
                    case "w:dstrike":
                        style.strike=true;
                        break;
                    case "w:i":
                        style.italic = true;
                        break;
                    case "w:b":
                        style.bold=true;
                        break;
                    case "w:top":
                        found.top=true;
                        break;
                    case "w:bottom":
                        found.bottom=true;
                        break;
                    case "w:left":
                        found.left=true;
                        break;
                    case "w:right":
                        found.right=true;
                        break;
                    default:
                        break;
                }
            }
            if (found.top && found.bottom && found.left && found.right) {
                style.inset=true;
            }
        }
    }
}

function getStyle(pr) {
    if (!pr || !pr.children) {
        return null;
    }
    for (let i in pr.children) {
        if (pr.children[i].name="w:pStyle") {
            return pr.children[i].attribs["w:val"];
        }
    }
    return null;
}

function getTextAlign(pr) {
    if (!pr || !pr.children) {
        return null;
    }
    for (let i in pr.children) {
        if (pr.children[i].name=="w:jc") {
            const val =pr.children[i].attribs && pr.children[i].attribs["w:val"];
            if (val == "center") {
                return "center";
            } else if (val=="right" || val=="end") {
                return "right";
            }
            return null;
        }
    }
    return null;
}

function styleElement(type, props, children) {
    const name = props.id;
    const style = {}

    if (name) {
        if (name.startsWith("Heading")) {
            const outline = Number(name.substr(7));
            if (outline>0) {
                style.heading=outline;
            }
        }
    }

    for (let i in children) {
        if (children[i].type == "pPr") {
            const paraChildren = children[i].children;
            const found = {};

            for (let x in paraChildren) {
                const type = paraChildren[x].type;
                switch (type) {
                    case "pBdr":{
                        const bdrChildren = paraChildren[x].children;
                        for (let y in bdrChildren) {
                            const c = bdrChildren[y];
                            found[c.type] = true;
                        }
                        break;
                    }
                }
            }
            if (found.top && found.bottom && found.left && found.right) {
                style.inset=true;
            }
        } else if (children[i].type == "rPr") {
            checkStyle(children[i],style);
            const paraChildren = children[i].children;
            const found = {};
            for (let x in paraChildren) {
                const pc = paraChildren[x];
                const type = pc.type;
                const skip = pc.props && pc.props.node && pc.props.node.attribs && (pc.props.node.attribs["w:val"]=="0");
                if (!skip) {
                    switch (type) {
                        case "b": 
                            style.bold=true;
                            break;
                        case "i": 
                            style.italic=true;
                            break;
                        case "u": 
                            style.underscore=true;
                            break;
                        case "strike":
                        case "dstrike":
                            style.strike=true;
                            break;
                        case "pBdr":{
                            const bdrChildren = paraChildren[x].children;
                            for (let y in bdrChildren) {
                                const c = bdrChildren[y];
                                found[c.type] = true;
                            }
                            break;
                        }
                        default:
//                          console.log("t:", type);
                            break;
                    }
                }
            }
            if (found.top && found.bottom && found.left && found.right) {
                style.inset=true;
            }
        } else if (children[i].type == "basedOn") {
            const pc = children[i];
            const pstyle = pc?.props?.node?.attribs && pc.props.node.attribs["w:val"];
            if (pstyle && pstyle.startsWith("Heading")) {
                const outline = Number(pstyle.substr(7));
                if (outline>0) {
                    style.heading=outline;
                }
            }
        }
    }

    styleList[name] = style;
    //console.log("style", name, style, children);
    return {type, props, children};
}

function checkStyle(pr, style) {
    if (!pr || pr.type!="rPr") {
        return
    }

    const paraChildren = pr.children;
    const found = {};
    for (let x in paraChildren) {
        const pc = paraChildren[x];
        const type = pc.type;
        const skip = pc.props && pc.props.node && pc.props.node.attribs && (pc.props.node.attribs["w:val"]=="0");
        if (!skip) {
            switch (type) {
                case "b": 
                    style.bold=true;
                    break;
                case "i": 
                    style.italic=true;
                    break;
                case "u": 
                    style.underscore=true;
                    break;
                case "strike":
                case "dstrike":
                    style.strike=true;
                    break;
                case "pBdr":{
                    const bdrChildren = paraChildren[x].children;
                    for (let y in bdrChildren) {
                        const c = bdrChildren[y];
                        found[c.type] = true;
                    }
                    break;
                }
                default:
//                  console.log("t:", type);
                    break;
            }
        }
    }
    if (found.top && found.bottom && found.left && found.right) {
        style.inset=true;
    }
}

function tableElement(type,props, children) {
    let inside =childrenMerge(children);
    
    return "<table>"+labelRolls(inside)+"</table>";
}

function tableData(type,props, children) {
    let inside =childrenMerge(children);

    return "<td>"+labelRolls(inside)+"</td>";
}

function tableRow(type,props, children) {
    let inside =childrenMerge(children);
    return "<tr>"+labelRolls(inside)+"</tr>";
}

function computeBook(type, props, children) {
    const res = collapselevel(type, props, children);
    children = res.children;
    const chapters = [];
    var curChapter = null;
    var curSection = null;
    var curSubsection = null;
    var curFragment = null;
    var fragments = [];

    //console.log("document", props.styles, props);


    for (let i in children) {
        const e = children[i];

        //console.log("book content", e.type, e);
        if (e.type == "heading") {
            if (!e.text || (e.text=="")) {
                continue;
            }
            if (curFragment && !curChapter) {
                // need a default chapter
                curChapter = {name:"Title", sections:[], fragment:curFragment.name};
                chapters.push(curChapter);
                fragments.push(curFragment);
            }

            switch (e.outline) {
                case "1":
                case 1:
                    //chapter
                    curFragment = {name:"f"+campaign.newUid(), type:"fragment", entry:{type:"html", html:""}};
                    fragments.push(curFragment);

                    curChapter = {name:e.text, sections:[], fragment:curFragment.name};
                    chapters.push(curChapter);

                    curSection = null;
                    curSubsection = null;
                    break;

                case "2":
                case 2:
                    //section
                    if (!curChapter) {
                        // need a default chapter
                        const chaptFragment = {name:"f"+campaign.newUid(), type:"fragment", entry:{type:"html", html:""}};
                        fragments.push(chaptFragment);
                        curChapter = {name:"Title", sections:[], fragment:chaptFragment.name};
                        chapters.push(curChapter);
                    }
        
                    curFragment = {name:"f"+campaign.newUid(), type:"fragment", entry:{type:"html", html:""}};
                    fragments.push(curFragment);

                    curSection = {name:e.text, subsections:[], fragment:curFragment.name};
                    curChapter.sections.push(curSection);

                    curSubsection = null;
                    break;

                case "3":
                case 3:
                default:
                    //subsection
                    const onum = Number(e.outline)-3;
                    curFragment = {name:"f"+campaign.newUid(), type:"fragment", entry:{type:"html", html:""}};
                    fragments.push(curFragment);

                    if (!curSection) {
                        curSection = {name:"untitled", subsections:[], fragment:curFragment.name};
                        curChapter.sections.push(curSection);

                        curFragment = {name:"f"+campaign.newUid(), type:"fragment", entry:{type:"html", html:""}};
                        fragments.push(curFragment);
                    }

                    curSubsection = {name:e.text, subsections:[], fragment:curFragment.name};
                    if (Number.isInteger(onum)&& (onum <4)) {
                        curSubsection.depth = onum;
                    }
                    curSection.subsections.push(curSubsection);
                    break;
                case "alternative header handling":
                {
                    const addHtml = "<h3>"+e.text+"</h3>";
                    if (!curFragment) {
                        curFragment = {name:"f"+campaign.newUid(), type:"fragment", entry:{type:"html", html:addHtml}};
                    } else {
                        curFragment.entry.html = curFragment.entry.html + addHtml;
                    }
                    break;
                }
            }
        } else if (typeof e === "string") {
            if (!curFragment) {
                curFragment = {name:"f"+campaign.newUid(), type:"fragment", entry:{type:"html", html:e}};
            } else {
                curFragment.entry.html = curFragment.entry.html + e;
            }
        } else {
            console.log("unknown node type", e);
        }
    }

    if (curFragment && !curChapter) {
        // need a default chapter
        curChapter = {name:"Title", sections:[], fragment:curFragment.name};
        chapters.push(curChapter);
        fragments.push(curFragment);
    }

    return {chapters, fragments};
}

function collapselevel(type,props, children) {
    var newChildren;

    newChildren = convertLists(children);

    var retChildren = [];
    var acc = null;

    for (let i in newChildren) {
        const c = newChildren[i];

        if (typeof c === "string") {
            if (acc) {
                acc = acc+c;
            } else {
                acc = c;
            }
        } else {
            if (acc) {
                retChildren.push(acc);
                acc=null;
            }

            if (c.type == "section") {
                retChildren = retChildren.concat(c.children);
            } else {
                retChildren.push(c);
            }
        }
    }
    if (acc) {
        retChildren.push(acc);
    }


    return {type, children:retChildren};
}

function convertLists(c) {
    var inList=false;
    const ret = [];
    var listStack = [];

    for (let i in c) {
        const e = c[i];

        if (e.type == "list") {
            const add = "<li>"+e.text+"</li>";
            if (inList) {
                if (e.level == (listStack.length-1)) {
                    listStack[e.level] = listStack[e.level]+add;
                } else if (e.level >= listStack.length) {
                    listStack[e.level] = add;
                } else {
                    // need to close levels first
                    for (let l=(listStack.length-1); l > e.level; l--) {
                        if (!listStack[l-1]) {
                            listStack[l-1]="";
                        }

                        listStack[l-1] = listStack[l-1]+"<ul>"+(listStack[l]||"")+"</ul>";
                        listStack.splice(l,1);
                    }
                    listStack[e.level] = listStack[e.level]+add;
                }
            } else {
                listStack[e.level] = add;
                inList = true;
            }
        } else {
            if (inList) {
                // need to close all levels
                for (let l=(listStack.length-1); l > 0; l--) {
                    if (!listStack[l-1]) {
                        listStack[l-1]="";
                    }

                    listStack[l-1] = listStack[l-1]+"<ul>"+(listStack[l]||"")+"</ul>";
                    listStack.splice(l,1);
                }
                ret.push("<ul>"+listStack[0]+"</ul>");
                listStack=[];
                inList=false;
            }
            ret.push(e);
        }
    }
    return ret;
}

function listElement(type,props, children) {
    let inside =childrenMerge(children);
    
    return {type, level:props.level, numId:props.numId, text:labelRolls(inside)};
}

function getHeaderText(header) {
    const wrapper= document.createElement('div');
    wrapper.innerHTML= header;
    return wrapper.innerText;
}

function heading(type,props, children) {
    let inside = "";
    
    for (let i in children) {
        if (typeof children[i] == "string") {
            inside = inside + children[i];
        } else {
            console.log("unexpected object in heading", children[i]);
        }
    }
    const text=getHeaderText(inside);

    //console.log("heading props", props, inside, text, children);

    return {type:"heading", outline:props.outline, text};
}

function textRun(type,props, children) {
    return children.join("");
}

// dup in searchfs.jsx
function formattedTextRun(type, props, children) {
    const f = props.pr||{};

    let inside =childrenMerge(children);

    for (let i in f.children) {
        switch (f.children[i].name) {
            case "w:u":
                if (f.children[i].attribs && (f.children[i].attribs["w:val"]!='0')) {
                    inside = "<u>"+labelRolls(inside)+"</u>";
                }
                break;
            case "w:strike":
            case "w:dstrike":
                if (f.children[i].attribs && (f.children[i].attribs["w:val"]!='0')) {
                    inside = "<s>"+labelRolls(inside)+"</s>";
                }
                break;
            case "w:i":
                if (f.children[i].attribs && (f.children[i].attribs["w:val"]!='0')) {
                    inside = "<i>"+labelRolls(inside)+"</i>";
                }
                break;
            case "w:rStyle":{
                const style = f.children[i].attribs["w:val"];
                const si = styleList[style];
                if (si) {
                    //console.log("style", style, inside);
                    if (si.bold) {
                        inside = "<b>"+labelRolls(inside)+"</b>";
                    }
                    if (si.italic) {
                        inside = "<i>"+labelRolls(inside)+"</i>";
                    }
                    if (si.strike) {
                        inside = "<s>"+labelRolls(inside)+"</s>";
                    }
                    if (si.underscore) {
                        inside = "<u>"+labelRolls(inside)+"</u>";
                    }
                    //inside = '<span class="'+style+'">'+inside+"</span>"
                }
                break;
            }
            case "w:b":
                if (f.children[i].attribs && (f.children[i].attribs["w:val"]!='0')) {
                    inside = "<b>"+labelRolls(inside)+"</b>";
                }
                break;
            default:
                //console.log("unknown format", f.children[i].name);
                break;
        }
    }

    return inside;
}

const diceDetectPattern = /(\d*d(4|6|8|10|12|20)\s*\+\s*\d+|\d*d(4|6|8|10|12|20)\s*\-\s*\d+|\d*d(4|6|8|100|12|20|10|00)|\+\d+|\s\-\d+)/ig;
function labelRolls(text) {
    if (!text) {
        return text;
    }
    if (text.includes("<")) {
        return text;
    }
    const fixed = text.replace(diceDetectPattern, function (x){return "<b>"+x+"</b>"});
/*
    if (text != fixed) {
        console.log("bolded", text, "****", fixed)
    }
*/
    return fixed;
}


function empty(){
    return null;
}

function emptyText() {
    return "";
}

export {
    importBook
}
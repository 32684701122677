const React = require('react');
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {firebase} = require("./firebase.jsx");
import { getStorage, ref, uploadBytes } from "firebase/storage";
const {displayMessage} = require('./notification.jsx');
import Button from '@material-ui/core/Button';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import sizeMe from 'react-sizeme';
import Popover from '@material-ui/core/Popover';
import { Input } from '@material-ui/core';
import { ContactSupportOutlined } from '@material-ui/icons';
const {TextBasicEdit, TextVal,DeleteWithConfirm, SelectVal, CheckVal, defaultSourceFilter,defaultGamesystemAnyFilter, defaultBookFilter,defaultGamesystemFilter, windowSize } = require('./stdedit.jsx');
const {getBookContentTypes,contentTypeMap} = require('./contentmap.jsx');
const {ItemBuyPicker,ItemPicker,mergeItemList} = require('./items.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {importBook} = require('./importbook.jsx');
const {BookConvertMenus} = require('./bookconvert.jsx');
const {cleanFilename} = require('./renderart.jsx');
const {doRoll,getDiceFromString} = require('../src/diceroller.jsx');
const {Chat} = require('../lib/chat.js');
const {gamesystemAnyOptions} = require('../lib/stdvalues.js');
import {htmlFromEntry} from "../lib/entryconversion.js";
import ReactToPrint from 'react-to-print';

const {campaign,globalDataListener,areSameDeep,addCampaignToPath,getDirectDownloadUrl} = require('../lib/campaign.js');

class BookBase extends React.Component {
    constructor(props) {
        super(props);
        getBookContentTypes();  // make sure types are loaded
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.baseId = Math.trunc(Math.random()*10000)+"id.";
        this.state= {bookinfo:{},selectedChapter:0, sectionSave:-1, subsectionSave:-1};

        this.onClickFn = this.onClick.bind(this)
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "books");
        this.computeBookIndex();
        const bookinfo = campaign.getBookInfo(this.props.bookname);
        if (bookinfo) {
            campaign.addUserMRUList("mruBooks", {description:bookinfo.displayName, book:bookinfo.name});
        }
    }

    componentDidUpdate(prevProps) {

        if ((this.props.bookname != prevProps.bookname) || 
            (this.props.chapter != prevProps.chapter) ||
            (this.props.section != prevProps.section) ||
            (this.props.subsection != prevProps.subsection)) {
            this.computeBookIndex();
            const bookinfo = campaign.getBookInfo(this.props.bookname);
            if (bookinfo) {
                campaign.addUserMRUList("mruBooks", {description:bookinfo.displayName, book:bookinfo.name});
            }
        }
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "books");
    }

    onDataChange() {
        this.setState({bookinfo:campaign.getBookInfo(this.props.bookname)||{}})
    }

    saveScrollElement(r) {
        this.scrollElement=r;
    }

	render() {
        const alwaysShowTOC = this.props.size.width > 750;
        const size={height:this.props.size.height, width:this.props.size.width*(alwaysShowTOC?0.66:1)};
        if (size.width > 832) {
            size.width = 832;
        }

        return <div className={(campaign.isDarkMode?"darkT ":"lightT ")+"flex items-start h-100 w-100 flex-auto "+(this.props.showHero?"":"defaultbackground")}>
            {alwaysShowTOC?<div className="flex-auto"/>:null}
            {alwaysShowTOC?<div className="overflow-y-auto mw6 w-33 flex-auto br3 ma1" style={{maxHeight:size.height-10}}>
                <BookTOC bookinfo={this.state.bookinfo} liteBackground onGoToBook={this.props.onGoToBook} chapter={this.state.selectedChapter} section={this.state.sectionSave} subsection={this.state.subsectionSave} onClick={this.onClickFn}/>
            </div>:null}
            <div style={{width:size.width}} className={"defaultbackground h-100 flex flex-column"+(alwaysShowTOC?" ":"")} key={(this.props.bookname||"")+(this.state.selectedChapter||"")}>
                {this.getContentHeader(alwaysShowTOC)}
                <div id={"scroll"+this.baseId} className="flex-auto w-100 overflow-y-auto overflow-x-hidden stdcontent" ref={this.saveScrollElement.bind(this)} onScroll={this.onScroll.bind(this)}>
                    <BookContents
                        character={this.props.character} 
                        bookname={this.props.bookname} 
                        chapter={this.state.selectedChapter} 
                        editable={this.props.editable} 
                        baseId={this.baseId} 
                        onClick={this.onClickFn}
                        pageSync={this.props.pageSync}
                        onClickLink={this.props.onClickLink}
                        onClickEncounter={this.props.onClickEncounter}
                        onFocus={this.onFocus.bind(this)}
                        rollable={this.props.rollable}
                        addSpellToken={this.props.addSpellToken}
                        handleHref={this.props.handleHref}
                        size={size}
                        addToEncounter={this.props.addToEncounter}
                    />
                </div>
            </div>
            {alwaysShowTOC?<div className="flex-auto"/>:null}
            <Popover
                anchorEl={this.state.tocEl}
                open={(!alwaysShowTOC && this.state.showTOC)||false}
                onClose={this.closeTOC.bind(this)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <BookTOC bookinfo={this.state.bookinfo} onGoToBook={this.props.onGoToBook} chapter={this.state.selectedChapter} section={this.state.sectionSave} subsection={this.state.subsectionSave} onClick={this.onClickFn}/>
            </Popover>
        </div>;

    }

    onClick(chapter, section, subsection) {
        let selectedTOC = this.baseId+"booktitle";

        if ((chapter != null) && (section >= 0)) {
            if (subsection >=0) {
                selectedTOC = this.baseId+"section."+chapter+"."+section+"."+subsection;
            } else {
                selectedTOC = this.baseId+"section."+chapter+"."+section;
            }
        }

        setTimeout(function () {
            const element = document.getElementById(selectedTOC);
            if (element) {
                element.scrollIntoView({behavior: "smooth"});
            }
        },10);

        this.setState({selectedChapter:chapter, sectionSave:section, subsectionSave:subsection});
        if (this.props.onClick) {
            this.props.onClick(chapter,section,subsection);
        }
        this.setState({showTOC:false});
    }

    onFocus(chapter, section, subsection) {
        this.setState({selectedChapter:chapter, sectionSave:section, subsectionSave:subsection});
        if (this.props.onClick) {
            this.props.onClick(chapter, section, subsection);
        }
    }

    getContentHeader() {
        const selectedChapter = Number(this.state.selectedChapter);
        const chapters = this.state.bookinfo.chapters||[];
        const prevChapter = ((selectedChapter > 0)&&chapters[selectedChapter-1])?chapters[selectedChapter-1].name:null;
        const nextChapter = (((selectedChapter+1) < chapters.length)&& chapters[selectedChapter+1])?chapters[selectedChapter+1].name:null;

        return <div className="flex items-center chapterNavBar ph1 minh2">
            <div className="w-40 truncate flex-auto chapternav" onClick={prevChapter?this.clickChapter.bind(this, selectedChapter-1):null}>
                {prevChapter?<span className="fas fa-arrow-left pr1"/>:null}
                {prevChapter||""}
            </div>
            <div className="w2 tc chapternav" onClick={this.clickTOC.bind(this)}><span className="fas fa-book-open"/></div>
            <div className="w-40 flex-auto tr chapternav flex" onClick={nextChapter?this.clickChapter.bind(this, selectedChapter+1):null}>
                <div className="truncate flex-auto">{nextChapter||""}</div>
                {nextChapter?<div className="fas fa-arrow-right chapternav pl1"/>:null}
            </div>
        </div>;
    }

    clickTOC(event) {
        this.setState({showTOC:true, tocEl:event.target});
    }

    closeTOC() {
        this.setState({showTOC:false});
    }

    clickChapter(i) {
        if (this.props.onClick) {
            this.props.onClick(i, -1, -1);
        }
        this.setState({showTOC:false, selectedChapter:i, sectionSave:-1, subsectionSave:-1});
    }

    onScroll(event) {
        const element = event.target;
        if (element.id != ("scroll"+this.baseId)) {
            return;
        }
        this.scrollElement = element;
        const t= this;

        if (this.scrollTimer) {
            clearTimeout(this.scrollTimer);
        }

        this.scrollTimer = setTimeout(function (){
            const chapters = t.state.bookinfo.chapters;
            let topId = t.baseId+"booktitle";
            const topScroll = element.scrollTop;
            let sectionSave=-1, subsectionSave=-1; 
            const i = t.state.selectedChapter;
            const chapter = chapters[i];

            t.scrollTimer=null;
            if (!chapter.sections || !chapter.sections.length) {
                return;
            }

            // check current position
            let testTOC = t.baseId+"booktitle";
            if ((t.state.sectionSave >= 0)) {
                if (t.state.subsectionSave >=0) {
                    testTOC = t.baseId+"section."+i+"."+t.state.sectionSave+"."+t.state.subsectionSave;
                } else {
                    testTOC = t.baseId+"section."+i+"."+t.state.sectionSave;
                }
            }
            const testEl = document.getElementById(testTOC);
            if (testEl) {
                const pos = ((testEl.offsetTop || 0)-topScroll);
                if ((pos <=100) && ((pos > 0) || ((pos+testEl.offsetHeight) >0))) {
                    // current section header is off screen but content still shown
                    return;
                }
                if ((pos >=0) && (element.scrollTop+element.clientHeight >= (element.scrollHeight-1))) {
                    //scrolled to bottom and current section in view
                    return;
                }
            }

            //check the top section to see if it is in range
            const sectionId = t.baseId+"section."+i+"."+0;
            const sectionEl = document.getElementById(sectionId);
            const sectionOffset = (sectionEl && sectionEl.offsetTop || 0)-topScroll;
            if (sectionOffset <= 100) {
                // top section is in range so find correct section
                let low =0;
                let high =chapter.sections.length-1;

                while (low < high) {
                    const mid = Math.trunc((low+high+1)/2);
                    const sectionId = t.baseId+"section."+i+"."+mid;
                    const sectionEl = document.getElementById(sectionId);
                    const sectionOffset = (sectionEl && sectionEl.offsetTop || 0)-topScroll;
        
                    if (sectionOffset <= 100) {
                        low=mid;
                    } else {
                        high=mid-1;
                    }
                }
        
                sectionSave = low;
                topId = t.baseId+"section."+i+"."+sectionSave;

                const section = chapter.sections[sectionSave];
                if (section && section.subsections && section.subsections.length) {
                    const subsectionId = t.baseId+"section."+i+"."+sectionSave+"."+0;
                    const subsectionEl = document.getElementById(subsectionId);
                    const subsectionOffset = (subsectionEl && subsectionEl.offsetTop || 0)-topScroll;
                    if (subsectionOffset <=100) {
                        // there is a valid subsection
                        low =0;
                        high =section.subsections.length-1;
                
                        while (low < high) {
                            const mid = Math.trunc((low+high+1)/2);
                            const subsectionId = t.baseId+"section."+i+"."+sectionSave+"."+mid;
                            const subsectionEl = document.getElementById(subsectionId);
                            const subsectionOffset = (subsectionEl && subsectionEl.offsetTop || 0)-topScroll;
                
                            if (subsectionOffset <= 100) {
                                low=mid;
                            } else {
                                high = mid-1;
                            }
                        }
                        subsectionSave = low;
                        topId = t.baseId+"section."+i+"."+sectionSave+"."+subsectionSave;
                    }
                }
            }

            if (t.state.selectedTOC != topId) {
                t.setState({sectionSave, subsectionSave});

                if (t.props.onClick) {
                    t.props.onClick(t.state.selectedChapter, sectionSave, subsectionSave);
                }
            }
        }, 150);
    }

    computeBookIndex() {
        const t=this;
        const bookinfo = campaign.getBookInfo(this.props.bookname)||{};
        let selectedTOC = this.baseId+"booktitle";
        let chapter = this.props.chapter||0;
        let section=-1;
        let subsection=-1;

        if ((chapter != null) && (this.props.section >= 0)) {
            section = this.props.section;
            if (this.props.subsection >=0) {
                subsection=this.props.subsection;
                selectedTOC = this.baseId+"section."+chapter+"."+this.props.section+"."+this.props.subsection;
            } else {
                selectedTOC = this.baseId+"section."+this.props.chapter+"."+this.props.section;
            }
        }
        if ((this.props.bookname!=this.state.bookname) || (this.state.selectedChapter != chapter) || (this.state.sectionSave!=section) || (this.state.subsectionSave!=subsection)) {
            setTimeout(function () {
                const element = document.getElementById(selectedTOC);
                if (element) {
                    if (t.scrollElement) {
                        const topScroll = t.scrollElement.scrollTop;
                        const pos = ((element.offsetTop || 0)-topScroll);
                        if ((pos <=100) && ((pos > 0) || ((pos+element.offsetHeight) >0))) {
                            return;
                        }
                    }

                    element.scrollIntoView();
                }
            }, 50);
        }
        this.setState({bookinfo, bookname:this.props.bookname, selectedChapter:chapter, sectionSave:section, subsectionSave:subsection});
    }
}

class SubBookBase extends React.Component {
    constructor(props) {
        super(props);
        getBookContentTypes();  // make sure types are loaded
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.baseId = Math.trunc(Math.random()*10000)+"id.";
        this.state= {bookinfo:{}};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "books");
        this.computeBookIndex();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.bookname != prevProps.bookname) || 
            (this.props.fragment != prevProps.fragment)) {
            this.computeBookIndex();
        }
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "books");
    }

    onDataChange() {
        this.computeBookIndex();
    }

	render() {
        const size={height:Math.min(window.innerHeight*0.8,this.props.size.height), width:this.props.size.width};

        return <div style={{width:size.width}} className={"defaultbackground h-100 flex flex-column"}>
            <div className="flex-auto w-100 overflow-y-auto overflow-x-hidden stdcontent">
                <BookContents 
                    bookinfo={this.state.bookinfo} 
                    baseId={this.baseId} 
                    size={size}
                    rollable={this.props.rollable}
                    character={this.props.character} 
                />
            </div>
        </div>;

    }

    computeBookIndex() {
        const {showName} = this.props;
        const {chapter, section, subsection} = getChapterInfoFromFragment(this.props.bookname, this.props.fragment)
        const bookinfo = Object.assign({},campaign.getBookInfo(this.props.bookname)||{});
        const blank = {name:"", contentType:null, contentId:null, contentList:null, fragment:null};
        if (!showName) {
            blank.name="";
        }
        bookinfo.chapters = [Object.assign({},bookinfo.chapters[chapter])];

        if (section>=0) {
            Object.assign(bookinfo.chapters[0], blank);
            bookinfo.chapters[0].sections = [Object.assign({},bookinfo.chapters[0].sections[section])];
            if (subsection >=0) {
                Object.assign(bookinfo.chapters[0].sections[0], blank);
                const subsections = bookinfo.chapters[0].sections[0].subsections;
                const sub =Object.assign({},subsections[subsection]);
                sub.name="";
                const newsubs = [sub]

                for (let i=Number(subsection)+1; i<subsections.length; i++) {
                    if (subsections[i].depth > sub.depth){
                        newsubs.push(Object.assign({},subsections[i]));
                    } else {
                        break;
                    }
                }
                bookinfo.chapters[0].sections[0].subsections = newsubs;
            } else {
                if (!showName) {
                    bookinfo.chapters[0].sections[0].name="";
                }
            }
        } else {
            if (!showName) {
                bookinfo.chapters[0].name="";
            }
        }

        this.setState({bookinfo});
    }
}

class BookTOC extends React.Component {
    constructor(props) {
        super(props);
        this.state= {expanded:{}};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.bookinfo && prevProps.bookinfo && (this.props.bookinfo.name != prevProps.bookinfo.name)) || (this.props.chapter != prevProps.chapter)) {
            this.setState({expanded:{}});
        } else if ((this.props.section != prevProps.section) || (this.props.subsection!=prevProps.subsection)){
            if ((this.props.subsection >= 0) && (this.props.section >=0) && !this.state.expanded[this.props.section]) {
                const expanded = Object.assign({},this.state.expanded);

                expanded[this.props.section] = true;
                this.setState({expanded});        
            }
        }
    }

	render() {
        const toc=[];
        const {bookinfo,liteBackground,showAll} = this.props;
        const chapters = (bookinfo||{}).chapters||[];
        const selchapter = this.props.chapter||0;
        const selsection = this.props.section;
        const selsubsection = this.props.subsection;
        const expanded = this.state.expanded;
        const collection = [];

        if (this.props.onGoToBook && bookinfo.collectionId) {
            const books = campaign.getBookList();
            for (let i in books)  {
                const b = books[i];
                if (b.collectionId == bookinfo.collectionId) {
                    collection.push(b);
                }
            }
        }
        if (!collection.length) {
            collection.push(bookinfo);
        }
        collection.sort(function (a,b) { return (a.collectionSortKey||a.displayName||"").toLowerCase().localeCompare((b.collectionSortKey||b.displayName||"").toLowerCase())});

        for (let x in collection) {
            const c=collection[x];
            if (collection.length>1) {
                toc.push(<div key={"book~~"+x} className="chapterhover f2 pa1 bg-gray-20 shadow-1" onClick={this.clickBook.bind(this, c.name)}>
                    <span className="minh1"><span className="fas fa-book"/> {c.displayName||"(book)"}</span>
                </div>);
            }
            if ((c.name==bookinfo.name) || showAll) {
                for (let i in chapters) {
                    const chapter=chapters[i];
                    toc.push(<div key={i} className={((i == selchapter)&&(selsection <0)?"selectTOC":"chapterhover")+" f2 pa1 bg-gray-20 shadow-1"} onClick={this.clickChapter.bind(this, i)}>
                        <span className="minh1">{chapter.name||"(chapter)"}</span>
                    </div>);
                    
                    if ((i == selchapter || showAll)) {
                        for (let x in chapter.sections) {
                            const section =chapter.sections[x];
                            if (section.name) {
                                let sectionClass = (expanded[x])?"hoverhighlight fas fa-angle-down ph1 titlecolor":"hoverhighlight fas fa-angle-right ph1 titlecolor";

                                toc.push(<div className={((x==selsection)&&(selsubsection<0)?"selectTOC":"chapterhover")+" pl2 f3 pa1 flex"} key={i+"."+x} onClick={this.clickScroll.bind(this,i, x, -1)}>
                                    <span className="flex-auto minh1">{section.name}</span>
                                    {(section.subsections||[]).length?<span className={sectionClass} onClick={this.clickSectionExpand.bind(this, x)}/>:null}
                                </div>);

                                for (let y in section.subsections) {
                                    const subsection = section.subsections[y];

                                    if ((expanded[x] || showAll) && subsection.name) {
                                        toc.push(<div className={((x==selsection)&&(selsubsection==y)?"selectTOC":"chapterhover")+" pl4 f4 pa1"} key={i+"."+x+"."+y} onClick={this.clickScroll.bind(this, i, x, y)}>
                                            <span className="minh1">{depthMap[subsection.depth||0]}{subsection.name}</span>
                                        </div>);
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }

        return <div className={"titletext titlecolor "+(liteBackground?"defaultlitebackground":"defaultbackground")}>{toc}</div>;
    }

    clickBook(name) {
        this.props.onGoToBook(name, 0, -1, -1);
    }

    clickChapter(i) {
        if (this.props.onClick) {
            this.props.onClick(i, -1, -1);
        }
    }

    clickSectionExpand(i, e) {
        const expanded = Object.assign({},this.state.expanded);

        e.preventDefault();
        e.stopPropagation();
        expanded[i] = !expanded[i];
        this.setState({expanded});
    }

    clickScroll(chapter, section, subsection) {
        if (this.props.onClick) {
            this.props.onClick(chapter, section, subsection);
        }
    }
}

class BookContents extends React.Component {
    constructor(props) {
        super(props);
        getBookContentTypes();  // make sure types are loaded
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.state= {bookinfo:{}};
        
        this.editContentListFn = this.editContentList.bind(this);
        this.onChangeTitleFn = this.onChangeTitle.bind(this);
        this.onDeleteFn = this.onDelete.bind(this);
        this.showMenuFn = this.showMenu.bind(this);
        this.moveUpFn = this.moveSection.bind(this, -1);
        this.moveDnFn = this.moveSection.bind(this, 1);
        this.demoteSectionFn = this.demoteSection.bind(this);
        this.promoteSectionFn = this.promoteSection.bind(this);
        this.demoteChapterFn = this.demoteChapter.bind(this);
        this.promoteSubsectionFn = this.promoteSubsection.bind(this);
        this.adjustDepthPromoteFn = this.adjustDepth.bind(this, -1);
        this.adjustDepthDemoteFn = this.adjustDepth.bind(this,1);
        this.nextChapterFn=this.nextChapter.bind(this);
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "books");
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "plannedencounters");
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "pins");
        this.computeBookIndex();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.bookname != prevProps.bookname)||(this.props.bookinfo!=prevProps.bookinfo)) {
            this.computeBookIndex();
        }
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "books");
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "plannedencounters");
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "pins");
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.performSave();
        }
    }

    onDataChange() {
        if (!this.timeout) {
            // only update if changes are not queued
            this.computeBookIndex();
        }
    }

	render() {
        const baseId = this.props.baseId;
        const content = [];
        const editable = this.props.editable;
        const chapters = (this.state.bookinfo||{}).chapters||[];
        const linkRefs = this.state.linkRefs||{};
        const selectedChapter = this.props.chapter||0;
        const pageSync = this.props.pageSync;
        const rollable=this.props.rollable;
        const addSpellToken=this.props.addSpellToken;
        const size = this.props.size;
        
        for (let i in chapters) {
            const chapter=chapters[i];
            
            if (i == selectedChapter) {
                if (editable) {
                    content.push(<div key="add_chapter">
                        <Button color="secondary" size="small" className="mt1" variant="outlined" onClick={this.addSection.bind(this, "chapter", i)}>Add Chapter</Button>
                    </div>);
                }
                content.push(<BookPortion 
                    key="booktitle" 
                    id={baseId+"booktitle"}
                    title={chapter.name}
                    character={this.props.character} 
                    bookname={this.props.bookname} 
                    showMenu={this.showMenuFn}
                    contentType={chapter.contentType}
                    showList={chapter.showList}
                    contentId={chapter.contentId}
                    contentList={chapter.contentList}
                    fragment={chapter.fragment} 
                    hideDescription={chapter.hideDescription}
                    links={linkRefs[chapter.fragment]} 
                    onChangeTitle={this.onChangeTitleFn} 
                    onClickLink={this.props.onClickLink}
                    onClickEncounter={this.props.onClickEncounter}
                    editable={editable} 
                    pageSync={pageSync}
                    moveUp={i>0?this.moveUpFn:null} 
                    moveDn={i<(chapters.length-1)?this.moveDnFn:null}
                    demoteSection={i>0?this.demoteChapterFn:null}
                    level="title"
                    chapter={i}
                    section={-1}
                    subsection={-1}
                    onFocus={this.props.onFocus}
                    rollable={rollable}
                    addSpellToken={addSpellToken}
                    handleHref={this.props.handleHref}
                    size={size}
                    addToEncounter={this.props.addToEncounter}
                />);
                if (editable) {
                    content.push(<div  key="add_topsection">
                        <span className="f3 fas fa-plus titlecolor hoverhighlight pa1" onClick={this.addSection.bind(this, "section", i, 0)}/>
                    </div>);
                }
                for (let x in chapter.sections) {
                    const section =chapter.sections[x];
                    const sectionId = baseId+"section."+i+"."+x;

                    content.push(<BookPortion 
                        key={section.fragment} 
                        id={sectionId} 
                        title={section.name} 
                        character={this.props.character} 
                        bookname={this.props.bookname} 
                        showMenu={this.showMenuFn}
                        contentType={section.contentType}
                        contentId={section.contentId}
                        contentList={section.contentList}
                        showList={section.showList}
                        fragment={section.fragment} 
                        hideDescription={section.hideDescription}
                        links={linkRefs[section.fragment]} 
                        onChangeTitle={this.onChangeTitleFn} 
                        onClickLink={this.props.onClickLink}
                        onClickEncounter={this.props.onClickEncounter}
                        editable={editable} 
                        pageSync={pageSync}
                        level="section"
                        moveUp={x>0?this.moveUpFn:null} 
                        moveDn={x<(chapter.sections.length-1)?this.moveDnFn:null} 
                        demoteSection={x>0?this.demoteSectionFn:null}
                        promoteSection={this.promoteSectionFn}
                        chapter={i}
                        section={x}
                        subsection={-1}
                        onFocus={this.props.onFocus}
                        addSpellToken={addSpellToken}
                        rollable={rollable}
                        handleHref={this.props.handleHref}
                        size={size}
                        addToEncounter={this.props.addToEncounter}
                    />);

                    let lastSubsection, lastSubsectionDepth;
                    for (let y in section.subsections) {
                        const subsection = section.subsections[y];
                        const subsectionId = baseId+"section."+i+"."+x+"."+y;

                        if (editable) {
                            content.push(<div  key={"add_"+subsectionId}>
                                <span className="f3 fas fa-plus titlecolor hoverhighlight pa1" onClick={this.addSection.bind(this, "subsection", i, x, y,lastSubsectionDepth)}/>
                            </div>);
                        }
                        lastSubsection=y;
                        lastSubsectionDepth = subsection.depth||0;

                        content.push(<BookPortion 
                            key={subsection.fragment} 
                            id={subsectionId} 
                            title={subsection.name}
                            character={this.props.character} 
                            bookname={this.props.bookname} 
                            showMenu={this.showMenuFn}
                            fragment={subsection.fragment} 
                            hideDescription={subsection.hideDescription}
                            contentType={subsection.contentType}
                            contentId={subsection.contentId}
                            contentList={subsection.contentList}
                            showList={subsection.showList}
                            links={linkRefs[subsection.fragment]} 
                            onChangeTitle={this.onChangeTitleFn} 
                            onClickLink={this.props.onClickLink}
                            onClickEncounter={this.props.onClickEncounter}
                            editable={editable} 
                            pageSync={pageSync}
                            level="subsection"
                            moveUp={y>0?this.moveUpFn:null} 
                            moveDn={y<(section.subsections.length-1)?this.moveDnFn:null} 
                            promoteSection={subsection.depth?this.adjustDepthPromoteFn:this.promoteSubsectionFn}
                            demoteSection={((subsection.depth||0)<maxIndentDepth)?this.adjustDepthDemoteFn:null}
                            chapter={i}
                            section={x}
                            subsection={y}
                            onFocus={this.props.onFocus}
                            addSpellToken={addSpellToken}
                            rollable={rollable}
                            handleHref={this.props.handleHref}
                            size={size}
                            addToEncounter={this.props.addToEncounter}
                        />);
                    }
                    if (editable) {
                        if (lastSubsection!=null) {
                            content.push(<div  key={"add_"+sectionId}>
                                <span className="f3 fas fa-plus titlecolor hoverhighlight pa1" onClick={this.addSection.bind(this, "subsection", i, Number(x), Number(lastSubsection)+1, lastSubsectionDepth)}/>
                            </div>);
                        } else {
                            content.push(<div  key={"add_"+sectionId}>
                                <span className="f3 fas fa-plus titlecolor hoverhighlight pa1" onClick={this.addSection.bind(this, "section", i, Number(x)+1)}/>
                            </div>);
                        }
                    }
                }
                if (editable) {
                    content.push(<div key="add_chapter_end">
                        <Button color="secondary" size="small" className="mt1" variant="outlined" onClick={this.addSection.bind(this, "chapter", Number(i)+1)}>Add Chapter</Button>
                    </div>);
                }
                if (this.props.onClick&& (Number(i) < (chapters.length-1))) {
                    content.push(<div key="next_chapter" className="mv2 i">
                        <a onClick={this.nextChapterFn}>Next Chapter: {chapters[Number(i)+1].name}</a>
                    </div>)
                }
            }
        }

        return <div className="stdcontent defaultbackgroundtexture pa1">
            {content}
            {this.state.showMenu?<Menu open anchorEl={this.state.anchorEl} onClose={this.hideMenu.bind(this)}>
                <MenuItem onClick={this.showPickContent.bind(this)}>Pick Content</MenuItem>
                <DeleteWithConfirm useMenu name={this.state.deleteText} onClick={this.onDelete.bind(this, this.state.menuChapter, this.state.menuSection, this.state.menuSubsection)} altText="Delete"/>
                <BookConvertMenus bookinfo={this.state.bookinfo} chapter={this.state.menuChapter} section={this.state.menuSection} subsection={this.state.menuSubsection} onClose={this.hideMenu.bind(this)}/>
                {(this.state.menuSection <0)?<MenuItem onClick={this.showInsertBook.bind(this,true)}>Insert Book</MenuItem>:null}
            </Menu>:null}
            {this.getPickContent()}
            {this.state.editElement}
            {this.state.showInsertBook?<BookInsertDialog open bookinfo={this.state.bookinfo} chapter={this.state.menuChapter} onClose={this.showInsertBook.bind(this,false)}/>:null}
        </div>;
    }

    showInsertBook(showInsertBook) {
        this.setState({showMenu:false, showInsertBook});
    }

    showMenu(menuChapter, menuSection, menuSubsection, e, deleteText) {
        const chapters = this.state.bookinfo.chapters||[];
        let sectInfo;

        if (menuSubsection < 0) {
            if (menuSection < 0) {
                sectInfo = chapters[menuChapter];
            } else {
                sectInfo = chapters[menuChapter].sections[menuSection];
            }
        } else {
            sectInfo = chapters[menuChapter].sections[menuSection].subsections[menuSubsection];
        }

        this.setState({menuChapter, menuSection, menuSubsection, anchorEl:e.target, deleteText,showMenu:true,sectInfo});
    }

    showPickContent() {
        this.setState({showPickContent:true, showMenu:false});
    }

    hideMenu() {
        this.setState({showMenu:false})
    }

    getPickContent() {
        if (!this.state.showPickContent) {
            return null;
        }
        const frag = this.state.sectInfo;

        const selected = {};
        let mode="any";
        let contentType;

        if (frag.contentType) {
            contentType = frag.contentType
            const cm = contentTypeMap[contentType];
            const info = cm.get(frag.contentId).value;

            if (info) {
                selected[info.name.toLowerCase()]= {displayName:info.displayName||info.name, name:info.name, id:frag.contentId};
            }
            mode="shared";
        } else if (frag.contentList) {
            const l = frag.contentList;
            for (let i in l) {
                const it=l[i];
                contentType = it.contentType;
                const cm = contentTypeMap[contentType];

                const info=cm.get(it.contentId).value;
                if (info) {
                    selected[info.name.toLowerCase()]= {displayName:info.displayName||info.name, name:info.name, id:it.contentId};
                }
            }
            mode="list";
        }

        return <ContentPicker open onClose={this.closeContentPicker.bind(this)} mode={mode} selected={selected} shareHeader={!!frag.contentType} showList={frag.showList} selectedType={contentType} hideDescription={frag.hideDescription}/>;
    }

    
    closeContentPicker(selection, contentType, shareHeader, showList, hideDescription) {
        if (selection) {
            this.editContentList(this.state.menuChapter, this.state.menuSection, this.state.menuSubsection, selection, contentType, shareHeader, showList, hideDescription);
        }
        this.setState({showPickContent:false});
    }


    nextChapter(){
        this.props.onClick(Number(this.props.chapter)+1, -1,-1);
    }

    onChangeTitle(chapter, section, subsection, newTitle) {
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);

        if (section < 0 && subsection < 0) {
            newChapters[chapter].name = newTitle;
        } else if (subsection < 0) {
            newChapters[chapter].sections[section].name = newTitle;
        } else {
            newChapters[chapter].sections[section].subsections[subsection].name = newTitle;
        }
        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo);
    }

    onDelete(chapter, section, subsection) {
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);

        if (section < 0 && subsection < 0) {
            for (let s in newChapters[chapter].sections) {
                const sec = newChapters[chapter].sections[s];
                for (let ss  in sec.subsections) {
                    campaign.deleteCampaignContent("books", sec.subsections[ss].fragment);
                }
                campaign.deleteCampaignContent("books", sec.fragment);
            }
            campaign.deleteCampaignContent("books", newChapters[chapter].fragment);
            newChapters.splice(chapter, 1);
            if (newChapters.length == 0) {
                newChapters.push({name:"Introduction", fragment:campaign.newUid()})
            }
        } else if (subsection < 0) {
            const sec = newChapters[chapter].sections[section];
            for (let ss  in sec.subsections) {
                campaign.deleteCampaignContent("books", sec.subsections[ss].fragment);
            }
            campaign.deleteCampaignContent("books", sec.fragment);
            newChapters[chapter].sections.splice(section,1);
        } else {
            campaign.deleteCampaignContent("books", newChapters[chapter].sections[section].subsections[subsection].fragment);
            newChapters[chapter].sections[section].subsections.splice(subsection,1);
        }
        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo)
        this.setState({showMenu:false});
    }

    moveSection(direction, chapter, section, subsection) {
        chapter = Number(chapter);
        section = Number(section);
        subsection = Number(subsection);
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);

        if (section < 0 && subsection < 0) {
            const save = newChapters[chapter];
            newChapters[chapter] = newChapters[chapter+direction];
            newChapters[chapter+direction] = save;
            this.delayClickScroll(chapter+direction, -1, -1);
        } else if (subsection < 0) {
            const save = newChapters[chapter].sections[section];
            newChapters[chapter].sections[section] = newChapters[chapter].sections[section+direction];
            newChapters[chapter].sections[section+direction] = save;
            this.delayClickScroll(chapter, section+direction, subsection);
        } else {
            const save = newChapters[chapter].sections[section].subsections[subsection];
            newChapters[chapter].sections[section].subsections[subsection] = newChapters[chapter].sections[section].subsections[subsection+direction];
            newChapters[chapter].sections[section].subsections[subsection+direction] = save;
            this.delayClickScroll(chapter, section, subsection+direction);
        }
        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo)
    }

    delayClickScroll(chapter, section, subsection) {
        const t=this;
        setTimeout(function (){
            t.props.onClick(chapter, section, subsection);
        },5);
    }

    demoteSection(chapter, section) {
        chapter = Number(chapter);
        section = Number(section);
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);
        const selChapter = Object.assign({},newChapters[chapter]);
        newChapters[chapter]=selChapter;

        if (!selChapter.sections) {
            selChapter.sections=[];
        }

        const mergeSection = selChapter.sections[section-1];
        const oldSection = selChapter.sections[section];
        if (!oldSection.subsections) {
            oldSection.subsections=[];
        }
        if (!mergeSection.subsections) {
            mergeSection.subsections = [];
        }
        const subsectionAdds = oldSection.subsections||[];
        this.delayClickScroll(chapter, section-1, mergeSection.subsections.length);

        selChapter.sections.splice(section,1);
        delete oldSection.subsections;
        oldSection.depth = 0;
        mergeSection.subsections.push(oldSection);
        for (let i in subsectionAdds) {
            const subsec = Object.assign({}, subsectionAdds[i]);
            subsec.depth = Math.min((subsec.depth||0)+1, maxIndentDepth);
            mergeSection.subsections.push(subsec);
        }

        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo)
    }


    demoteChapter(chapter) {
        chapter = Number(chapter);
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);
        const mergeChapter = Object.assign({},newChapters[chapter-1]);
        newChapters[chapter-1]=mergeChapter;
        const selChapter = Object.assign({},newChapters[chapter]);;

        if (!mergeChapter.sections) {
            mergeChapter.sections =[];
        }
        this.delayClickScroll(chapter-1, mergeChapter.sections.length, -1);

        const newSubsection = [];
        for (let s in selChapter.sections) {
            const sec = selChapter.sections[s];
            const newSec = Object.assign({}, sec);
            delete newSec.subsections;
            newSubsection.push(newSec);

            for (let ss in sec.subsections) {
                const newSubsec = Object.assign({}, sec.subsections[ss]);
                newSubsec.depth = Math.min((newSubsec.depth||0)+1, maxIndentDepth);
                newSubsection.push(newSubsec);
            }
        }
        delete selChapter.sections;
        selChapter.subsections = newSubsection;
        mergeChapter.sections.push(selChapter);
        newChapters.splice(chapter, 1);
        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo);
    }

    promoteSection(chapter, section) {
        chapter = Number(chapter);
        section = Number(section);
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);
        const selChapter = Object.assign({},newChapters[chapter]);;
        newChapters[chapter]=selChapter;
        const newChapter = Object.assign({}, selChapter.sections[section]);

        this.delayClickScroll(chapter+1, -1, -1);

        const oldSubsections = newChapter.subsections;
        newChapter.sections = [];
        delete newChapter.subsections;
        let lastSec = null;
        for (let i in oldSubsections) {
            const sec = Object.assign({},oldSubsections[i]);
            if (!sec.depth || !lastSec) {
                lastSec = sec;
                sec.subsections = [];
                delete sec.depth;
                newChapter.sections.push(sec);
            } else {
                sec.depth = sec.depth-1;
                lastSec.subsections.push(sec);
            }
        }
        newChapters.splice(chapter+1,0, newChapter);
        newChapter.sections = newChapter.sections.concat(selChapter.sections.slice(section+1));
        selChapter.sections.splice(section, selChapter.sections.length-section)

        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo);
    }

    promoteSubsection(chapter, section, subsection) {
        chapter = Number(chapter);
        section = Number(section);
        subsection = Number(subsection);
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);
        const selChapter = bookinfo.chapters[chapter];
        if (!selChapter.sections) {
            selChapter.sections=[];
        }else {
            selChapter.sections.concat([]);
        }
        const oldSection = Object.assign({}, selChapter.sections[section]);
        selChapter.sections[section]=oldSection;
        if (!oldSection.subsections){
            oldSection.subsections = [];
        } else {
            oldSection.subsections = oldSection.subsections.concat([]);
        }
        const newSection = Object.assign({}, oldSection.subsections[subsection]);

        newSection.subsections = oldSection.subsections.splice(subsection+1, oldSection.subsections.length-subsection-1);
        oldSection.subsections.pop(); // remove the promoted subsection
        for (let i in newSection.subsections) {
            const sub = Object.assign({}, newSection.subsections[i]);
            if (sub.depth) {
                newSection.subsections[i]=sub;
                sub.depth = sub.depth-1;
            } else {
                break;
            }
        }

        selChapter.sections.splice(section+1,0, newSection);

        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo)
    }

    adjustDepth(direction, chapter, section, subsection) {
        chapter = Number(chapter);
        section = Number(section);
        subsection = Number(subsection);
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);
        const selChapter = bookinfo.chapters[chapter];
        const selSection = Object.assign({}, selChapter.sections[section]);
        selChapter.sections[section] = selSection;
        selSection.subsections = selSection.subsections.concat([]);

        let depth;
        let first = true;
        for (let i=subsection; i<selSection.subsections.length; i++) {
            const sub = Object.assign({}, selSection.subsections[i]);
            if (first || ((sub.depth||0)>depth)) {
                if (first) {
                    depth=sub.depth||0;
                    first =false
                }
                sub.depth = (sub.depth||0)+direction;
                selSection.subsections[i]=sub;
            } else {
                break;
            }
        }

        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo)
    }

    editContentList(chapter, section, subsection, selection, contentType, shareHeader, showList, hideDescription) {
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);
        let frag;
        const list = [];

        if (subsection < 0) {
            if (section < 0) {
                frag = newChapters[chapter];
            } else {
                frag = newChapters[chapter].sections[section];
            }
        } else {
            frag = newChapters[chapter].sections[section].subsections[subsection];
        }

        let displayName;
        for (let i in selection) {
            const s =selection[i];
            displayName=s.displayName;
            list.push({displayName,contentType, contentId:s.id||s.name});
        }

        const cm = contentTypeMap[contentType];
        list.sort(function(a,b) {
            const av = cm.get(a.contentId);
            const bv = cm.get(b.contentId);
            return (av?.value.displayName||"").toLowerCase().localeCompare((bv?.value.displayName||"").toLowerCase())
        });

        if (!list.length) {
            delete frag.contentType;
            delete frag.contentId;
            delete frag.contentList;
        } else if (shareHeader) {
            if (list.length==1 && frag.contentType) {
                delete frag.contentList;
                frag.name=displayName;
                frag.contentType = contentType;
                frag.contentId = list[0].contentId;
                frag.hideDescription = hideDescription;
            } else {
                const slist = [];
                delete frag.contentType;
                delete frag.contentId;
                delete frag.contentList;
                for (let i in list) {
                    const s =list[i];
                    slist.push({name:s.displayName, contentType, contentId:s.contentId, fragment:campaign.newUid(), hideDescription});
                }
    
                if ((section <0) && (subsection < 0)) {
                    newChapters[chapter].sections=slist.concat(newChapters[chapter].sections||[]);
                } else {
                    if (!newChapters[chapter].sections[section].subsections) {
                        newChapters[chapter].sections[section].subsections=[];
                    }
                    if (subsection < 0) {
                        subsection = 0;
                    } else {
                        subsection = subsection +1;
                    }
                    
                    for (let i in slist) {
                        newChapters[chapter].sections[section].subsections.splice(subsection, 0, slist[i]);
                        subsection++;
                    }
                }
            }
        } else {
            delete frag.contentId;
            delete frag.contentType;
            frag.contentList=list;
            frag.hideDescription = hideDescription;
            frag.showList = showList;
        }

        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo);

    }

    addSection(type, chapter, section, subsection, depth) {
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);
        const fragment = campaign.newUid();

        switch (type) {
            case "chapter":
                newChapters.splice(chapter, 0, {name:"", fragment, sections:[]});
                this.delayClickScroll(chapter, -1,-1);
                break;
            case "section":
                if (!newChapters[chapter].sections) {
                    newChapters[chapter].sections=[];
                }
                newChapters[chapter].sections.splice(section, 0, {name:"", fragment, subsections:[]});
                break;
            case "subsection":
                if (!newChapters[chapter].sections[section].subsections) {
                    newChapters[chapter].sections[section].subsections=[];
                }
                newChapters[chapter].sections[section].subsections.splice(subsection, 0, {name:"", fragment, depth:depth||0});
                break;
            default:
                console.log("unknown add section type", type);
                break;
        }
        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo);
    }

    saveResults(bookinfo) {
        const t=this;
        if (this.timeout){
            clearTimeout(this.timeout);
        }
        delete bookinfo.buildMap;
        t.bookinfo=bookinfo;
        this.timeout = setTimeout(function () {
            t.performSave();
        }, 200);

        this.setState({bookinfo})
    }

    performSave(){
        this.timeout = null;
        campaign.updateCampaignContent("books", this.bookinfo);
        this.bookinfo=null;
    }

    computeBookIndex() {
        const bookinfo = this.props.bookinfo || campaign.getBookInfo(this.props.bookname)||{};
        const bookname = (this.props.bookname||"").toLowerCase();
        const chapters = bookinfo.chapters||[];
        const linkRefs = {};

        const pins = campaign.getPins();
        
        for (let i in pins) {
            const p = pins[i];

            for (let l in p.links) {
                const pl =p.links[l];

                if ((pl.type == "book") && pl.book && (pl.book.toLowerCase() == bookname)) {
                    appendLink(pl, {type:"pin", name:p.name, displayName:p.displayName});
                }
            }
        }

        const encounters = campaign.getPlannedEncounters();

        for (let i in encounters) {
            const e = encounters[i];

            if (e.bookReference && (e.bookReference.book.toLowerCase() == bookname)) {
                appendLink(e.bookReference, {type:"encounter", displayName:e.displayName, name:e.name});
            }
        }

        this.setState({bookinfo, linkRefs});

        function appendLink(ref, data) {
            const chapter=ref.chapter||0;
            const section=ref.section;
            const subsection=ref.subsection;

            let fragment;
            if (ref.fragment) {
                fragment=ref.fragment;
            } else {
                const chapterInfo = (chapters[chapter]||{});
                if (section >= 0) {
                    const sectionInfo = (chapterInfo.sections||[])[section]||{};
                    if (subsection >=0) {
                        const subsectionInfo = (sectionInfo.subsections||[])[subsection]||{};
                        
                        fragment = subsectionInfo.fragment;
                    } else {
                        fragment = sectionInfo.fragment;
                    }
                } else {
                    fragment = chapterInfo.fragment;
                }
            }
            if (fragment) {
                linkRefs[fragment] = (linkRefs[fragment]||[]).concat([data]);
            }
        }
    }

    onReplaceAfterCreate() {
        const chapter=Number(this.state.menuChapter);
        const section=Number(this.state.menuSection);
        const subsection=Number(this.state.menuSubsection);
        const bookinfo = Object.assign({},this.state.bookinfo);
        const newChapters = (bookinfo.chapters||[]).concat([]);

        if (section < 0 && subsection < 0) {
            for (let s in newChapters[chapter].sections) {
                const sec = newChapters[chapter].sections[s];
                for (let ss  in sec.subsections) {
                    campaign.deleteCampaignContent("books", sec.subsections[ss].fragment);
                }
                campaign.deleteCampaignContent("books", sec.fragment);
            }
            campaign.deleteCampaignContent("books", newChapters[chapter].fragment);
            newChapters[chapter] = {name:newChapters[chapter].name, contentId:this.state.contentInfo.contentId, contentType:this.state.contentInfo.contentType, showHeader:true, fragment:campaign.newUid()};
        } else if (subsection < 0) {
            const sec = newChapters[chapter].sections[section];
            for (let ss  in sec.subsections) {
                campaign.deleteCampaignContent("books", sec.subsections[ss].fragment);
            }
            campaign.deleteCampaignContent("books", sec.fragment);
            newChapters[chapter].sections[section] = {name:sec.name, contentId:this.state.contentInfo.contentId, contentType:this.state.contentInfo.contentType, showHeader:true, fragment:campaign.newUid()};
        } else {
            const sec = newChapters[chapter].sections[this.state.menuSection];
            const subsections = sec.subsections;

            while (( (subsection+1) < subsections.length) && ((subsections[subsection+1].depth||0) > (subsections[subsection].depth||0))) {
                campaign.deleteCampaignContent("books", subsections[subsection+1].fragment);
                subsections.splice(subsection+1,1);
            }

            campaign.deleteCampaignContent("books", subsections[subsection].fragment);
            subsections[subsection] = {name:subsections[subsection].name, contentId:this.state.contentInfo.contentId, contentType:this.state.contentInfo.contentType, showHeader:true, fragment:campaign.newUid()};
        }
        bookinfo.chapters = newChapters;
        this.saveResults(bookinfo)
        this.setState({showMenu:false});
    }
}

class BookInsertDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            className="nodrag"
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Insert Book</DialogTitle>
            <DialogContent>
                <div className="hk-well mb1">Pick a book in insert after the selected chapter.  Any location pins or encounters from the inserted will be updated to point to the inserted chapters.  There is no easy undo of this operation.</div>
                {!this.state.newBookInfo?<div className="tc">
                    <Button onClick={this.showPickBook.bind(this)} color="primary" variant="outlined">Pick Book</Button>
                </div>:<div>
                    <BookTOC bookinfo={this.state.newBookInfo} showAll/>
                </div>}
            </DialogContent>
            <DialogActions>
                {this.state.newBookInfo?<Button onClick={this.doInsert.bind(this)} color="primary">
                    Save Changes
                </Button>:null}
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
            <BookPicker open={this.state.showPickBook} onClose={this.onClosePickBook.bind(this)}/>
            <Dialog open={this.state.loading||false}>
                <DialogContent>
                    Updating book...
                </DialogContent>
            </Dialog>
        </Dialog>;
    }

    onClose() {
        this.props.onClose();
    }

    showPickBook() {
        this.setState({showPickBook:true});
    }

    doInsert() {
        const t=this;
        this.setState({loading:true});
        campaign.batchUpdateCampaignContent(this.state.batch).then(function (){
            t.setState({loading:false, batch:null, newBookInfo:null});
            t.props.onClose();
        }, function (err) {
            displayMessage("Error updating book: "+err.message);
            t.setState({loading:false});
        });
    }

    onClosePickBook(selectedBook) {
        if (selectedBook) {
            selectedBook=selectedBook.toLowerCase();
        }
        if (selectedBook == this.props.bookinfo.name.toLowerCase()) {
            displayMessage("Cannot insert a book into itself.");
        } else if (selectedBook) {
            const merge = campaign.getBookInfo(selectedBook);
            const nb = Object.assign({}, this.props.bookinfo);
            const batch = [];
            const addChapters = [];
            const mc = merge.chapters||[];
            const fragmap={};

            for (let c in mc) {
                const chapt = mc[c];
                const sections = chapt.sections;
                const nc = Object.assign({}, chapt);
                const ofrag = campaign.getBookFragment(chapt.fragment);
                nc.fragment=campaign.newUid();
                fragmap[chapt.fragment] = nc.fragment;
                if (ofrag) {
                    const nf = Object.assign({}, ofrag);
                    nf.name = nc.fragment;
                    batch.push({contentType:"books", value:nf});
                }
                nc.sections=[];
                for (let s in sections) {
                    const sec = sections[s];
                    const subsections = sec.subsections;
                    const ns = Object.assign({}, sec);
                    const sfrag = campaign.getBookFragment(sec.fragment);
                    ns.fragment=campaign.newUid();
                    fragmap[sec.fragment] = ns.fragment;
                    if (sfrag) {
                        const nf = Object.assign({}, sfrag);
                        nf.name = ns.fragment;
                        batch.push({contentType:"books", value:nf});
                    }
                    ns.subsections = [];
                    for (let ss in subsections) {
                        const subsec = subsections[ss];
                        const nss = Object.assign({}, subsec);
                        const ssfrag = campaign.getBookFragment(subsec.fragment);
                        nss.fragment=campaign.newUid();
                        fragmap[subsec.fragment] = nss.fragment;
                        if (ssfrag) {
                            const nf = Object.assign({}, ssfrag);
                            nf.name = nss.fragment;
                            batch.push({contentType:"books", value:nf});
                        }
                        ns.subsections.push(nss);
                    }

                    nc.sections.push(ns);
                }
                addChapters.push(nc);
            }
            const chaptPos = Number(this.props.chapter);
            nb.chapters= nb.chapters.slice(0, chaptPos+1).concat(addChapters).concat(nb.chapters.slice(chaptPos+1));

            const pins = campaign.getPins();
        
            for (let i in pins) {
                const p = Object.assign({}, pins[i]);
    
                if (p.links) {
                    let set;
                    p.links=p.links.concat([]);
                    for (let l in p.links) {
                        const pl = Object.assign({},p.links[l]);
        
                        if ((pl.type == "book") && pl.book && (pl.book.toLowerCase() == selectedBook)) {
                            pl.fragment = fragmap[pl.fragment||getFragmentFromChapterSection(selectedBook,pl.chapter, pl.section, pl.subsection)];
                            pl.book=nb.name;
                            delete pl.chapter;
                            delete pl.section;
                            delete pl.subsection;
                            set = true;                        
                            p.links[l]=pl;
                        }
                    }
                    if (set) {
                        batch.push({contentType:"pins", value:p});
                    }
                }
            }
    
            const encounters = campaign.getPlannedEncounters();
    
            for (let i in encounters) {
                const e = Object.assign({},encounters[i]);
    
                if (e.bookReference && (e.bookReference.book.toLowerCase() == selectedBook)) {
                    const br = Object.assign({}, e.bookReference);
                    e.bookReference = br;
                    br.fragment = fragmap[br.fragment||getFragmentFromChapterSection(selectedBook,br.chapter, br.section, br.subsection)];
                    br.book = nb.name;
                    delete br.chapter;
                    delete br.section;
                    delete br.subsection;
                    batch.push({contentType:"plannedencounters", value:e});
                }
            }
           
            batch.push({contentType:"books", value:nb});
            this.setState({batch, newBookInfo:nb});
        }
        this.setState({showPickBook:false});
    }


}

const depthMap = [
    null,
    "- ",
    <span className="ml2">&gt; </span>,
    <span className="ml4">+ </span>,
];
const maxIndentDepth = depthMap.length-1;

class BookPortion extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.onchangefn = this.onChange.bind(this);
        let entry=null;

        if (props.fragment) {
            const bf = campaign.getBookFragment(props.fragment);
            if (bf) {
                entry=bf.entry;
            }
        }
        this.state= {title:props.title, entry};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fragment != this.props.fragment) {
            let entry = {};
            if (this.props.fragment) {
                const bf = campaign.getBookFragment(this.props.fragment);
                if (bf) {
                    entry = bf.entry;
                }
            }
            if (this.saveTitle){
                clearTimeout(this.saveTitle);
                this.saveTitle=null;
            }
            this.setState({entry, title:this.props.title});
        }
        if ((prevProps.title != this.props.title) && !this.saveTitle) {
            this.setState({title:this.props.title});
        }
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "books");
    }

    componentWillUnmount() {
        if (this.saveTitle){
            clearTimeout(this.saveTitle);
            this.saveTitle=null;
        }
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "books");
    }

    onDataChange() {
        let entry = {};
        if (this.editorRef.current && this.editorRef.current.isDirty()) {
            return;
        }
        if (this.props.fragment) {
            const bf = campaign.getBookFragment(this.props.fragment);
            if (bf) {
                entry = bf.entry;
            }
        }
        if (!areSameDeep(entry,this.state.entry)) {
            this.setState({entry});
        }
    }

    onChange(entry) {
        campaign.updateCampaignContent("books", {name:this.props.fragment, type:"fragment", entry});
        this.setState({entry:entry});
    }

	render() {
        const t=this;
        const {editable,contentList,character}=this.props;
        const {showShop,showTreasurePicker, restrictItems} = this.state;
        let title;
        let icon;
        let location,encounter;
        const links=[];
        let content=[];
        let showHeader=false;
        const onFocusFn = this.onFocus.bind(this);
        const onClickContentFn = this.onClickContent.bind(this);

        if (this.props.contentType) {
            const cm = contentTypeMap[this.props.contentType];
            if (cm) {
                const editContent = (editable || cm.alwaysEditable) && cm.edit;
                showHeader = cm.showHeader;

                content.push(<div key="all" className={editContent?"overflow-auto hoverborder":"overflow-auto"} onClick={editContent?this.onClickEditContent.bind(this,this.props.contentType, this.props.contentId):onClickContentFn}>
                    {contentTypeMap[this.props.contentType].show(this.props.contentId,this.props.pageSync, true, this.props.hideDescription,this.props.size, this.props.addToEncounter,editContent)}
                </div>);
            }
        } else if (contentList) {
            if (this.props.showList && (contentList.length>0) && contentTypeMap[contentList[0].contentType] && contentTypeMap[contentList[0].contentType].list) {
                const contentType = contentList[0].contentType;
                if (contentType == "Items"){
                    if (character) {
                        content.push(<div key="shop"><Button onClick={this.onShowItemPicker.bind(this,true)} size="small" color="primary" variant="outlined">Shop</Button></div>);
                    } else if (!campaign.isPlayerMode()) {
                        content.push(<div key="shop"><Button onClick={this.onShowTreasurePicker.bind(this,true, null)} size="small" color="primary" variant="outlined">Grant Treasure</Button></div>);
                    }
                }
                content.push(<div key="list">{contentTypeMap[contentType].list(contentList, this.props.pageSync)}</div>);
            } else {
                let tile;
                for (let i in contentList) {
                    const ce = contentList[i];
                    const cm = contentTypeMap[ce.contentType];
                    if (cm) {
                        const editce = (editable || cm.alwaysEditable) && !!cm.edit;
                        if (cm.tile) {
                            tile = true;
                        }
                        
                        content.push(<div key={i} className={editce?"hoverborder overflow-auto":(tile?"ma1 overflow-auto":"overflow-auto")} onClick={editce?this.onClickEditContent.bind(this,ce.contentType, ce.contentId):onClickContentFn}>
                            {cm.show(ce.contentId,this.props.pageSync, false, this.props.hideDescription, this.props.size, this.props.addToEncounter,editce)}
                        </div>);
                    }
                }
                if (tile) {
                    content = <div className="flex flex-wrap">{content}</div>
                }
            }
        }

        if (this.props.links && this.props.links.length) {
            for (let i in this.props.links){
                const l = this.props.links[i];

                if (((l.type == "pin")||(l.type == "book")) && this.props.onClickLink) {
                    location = true;
                    links.push(<MenuItem key={l.name} onClick={this.onClickLink.bind(this, l)}>
                        <span className="fas fa-map-marked-alt mr1"/>
                        {l.displayName}
                    </MenuItem>);
                } else if ((l.type == "encounter") && this.props.onClickEncounter) {
                    encounter = true;
                    links.push(<MenuItem key={l.name} onClick={this.onClickLink.bind(this, l)}>
                        <span className="fas fa-dragon mr1"/>
                        {l.displayName}
                    </MenuItem>);
                }
            }
            icon = (location||encounter)?<span  className="nowrap hoverhighlight titlecolor f3" onClick={this.clickLinks.bind(this)}>
                {location?<span className="fas fa-map-marked-alt pa1"/>:null}
                {encounter?<span className="fas fa-dragon pa1"/>:null}
            </span>:null;
        }

        if (editable) {
            let insideTitle;
            let delPrefix;
            switch (this.props.level) {
                case "title":
                    delPrefix = "chapter ";
                    insideTitle=<Input 
                        className="bb titleborder f1 b small-caps titletext titlecolor flex-auto" 
                        value={this.state.title||""} 
                        variant="standard" 
                        fullWidth 
                        disableUnderline
                        onChange={this.onChangeTitle.bind(this)}
                        onFocus={onFocusFn}
                        placeholder="chapter"/>;
                    break;
                case "section":
                    delPrefix = "section ";
                    insideTitle=<Input  
                        className="bb titleborder f2 b small-caps titletext titlecolor flex-auto" 
                        value={this.state.title||""} 
                        variant="standard" 
                        fullWidth 
                        disableUnderline
                        onChange={this.onChangeTitle.bind(this)}
                        onFocus={onFocusFn}
                        placeholder="section"/>;
                    break;
                case "subsection":
                    delPrefix = "subsection ";
                    insideTitle=<Input  
                        className="f3 b small-caps titletext titlecolor flex-auto" 
                        value={this.state.title||""} 
                        variant="standard" 
                        fullWidth 
                        disableUnderline
                        onChange={this.onChangeTitle.bind(this)}
                        onFocus={onFocusFn}
                        placeholder="subsection"/>;
                    break;
                default:
                    throw new Error("unknown level type "+this.props.level);
                    break;
            }
            title=<div className="flex items-center mv1">
                {icon}
                {insideTitle}
                <span className="fas fa-bars pa1 ml--2 hoverhighlight" onClick={showMenu}/>
                <span className={(this.props.moveUp?"hoverhighlight":"gray-80")+" fas fa-arrow-up pa1 ml--2"} onClick={this.props.moveUp?moveUp:null}/>
                <span className={(this.props.moveDn?"hoverhighlight":"gray-80")+" fas fa-arrow-down pa1 ml--2"} onClick={this.props.moveDn?moveDn:null}/>
                <span className={(this.props.promoteSection?"hoverhighlight":"gray-80")+" fas fa-arrow-left pa1 ml--2"} onClick={this.props.promoteSection?promoteSection:null}/>
                <span className={(this.props.demoteSection?"hoverhighlight":"gray-80")+" fas fa-arrow-right pa1 ml--2"} onClick={this.props.demoteSection?demoteSection:null}/>
            </div>;

            function showMenu(e) {t.props.showMenu(t.props.chapter, t.props.section, t.props.subsection, e, delPrefix+t.props.title)}
            function moveUp() {t.props.moveUp(t.props.chapter, t.props.section, t.props.subsection)}
            function moveDn() {t.props.moveDn(t.props.chapter, t.props.section, t.props.subsection)}
            function promoteSection() {t.props.promoteSection(t.props.chapter, t.props.section, t.props.subsection)}
            function demoteSection() {t.props.demoteSection(t.props.chapter, t.props.section, t.props.subsection)}
        } else {
            switch (this.props.level) {
                case "title":
                    if (icon||this.props.title) {
                        title=<h1 className="bb titleborder" onClick={onClickContentFn}>{icon}{this.props.title}</h1>;
                    }
                    break;
                case "section":
                    if (icon||this.props.title) {
                        title=<h2 onClick={onClickContentFn}>{icon}{this.props.title}</h2>;
                    }
                    break;
                case "subsection":
                    if (icon||this.props.title) {
                        title=<h3 onClick={onClickContentFn}>{icon}{this.props.title}</h3>;
                    }
                    break;
                default:
                    throw new Error("unknown level type "+this.props.level);
                    break;
            }
        }

        this.editorRef = React.createRef();

        const rollable = this.props.rollable;

        return <div id={this.props.id}>
            {title}
            {showHeader?content:null}
            {!editable?<Renderentry 
                className="overflow-x-auto"
                entry={this.state.entry} 
                depth={1} 
                pageSync={this.props.pageSync} 
                onClick={onClickContentFn} 
                getDiceRoller={rollable?this.getDiceRoller.bind(this):null} 
                doRoll={rollable?this.doTextRoll.bind(this):null} 
                doSubRoll={rollable?this.doSubRoll.bind(this):null} 
                addSpellToken={this.props.addSpellToken}
                handleHref={this.props.handleHref}
                allowCombatRolls={campaign.isGMCampaign()}
                addToEncounter={this.props.addToEncounter}
                character={character} 
            />
                :
            <EntityEditor ref={this.editorRef} onChange={this.onchangefn} entry={this.state.entry} onFocus={onFocusFn}/>}
            {!showHeader?content:null}
            {this.state.editContent}
            {links.length?<Menu open={this.state.showLinkList||false} anchorEl={this.state.anchorEl} onClose={this.closeLinks.bind(this)}>
                {links}
            </Menu>:null}
            {showShop?<ItemBuyPicker 
                open
                onClose={this.onShowItemPicker.bind(this,false)}
                character={character}
                restrictItems={restrictItems}
            />:null}
            {showTreasurePicker?<ItemPicker 
                open
                onClose={this.onShowTreasurePicker.bind(this,false)}
                restrictItems={restrictItems}
                noNew
            />:null}

        </div>;
    }

    onShowItemPicker(showShop,evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        const restrictItems = {};
        if (showShop) {
            const {contentList}=this.props;
            for (let i in contentList) {
                restrictItems[contentList[i].contentId.toLowerCase()]=1;
            }
        }
        this.setState({showShop,restrictItems});
    }

    onShowTreasurePicker(showTreasurePicker,items, evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        const restrictItems = {};
        if (showTreasurePicker) {
            const {contentList}=this.props;
            for (let i in contentList) {
                restrictItems[contentList[i].contentId.toLowerCase()]=1;
            }
        } else if (items) {
            const newst =  mergeItemList(items, campaign.getSharedTreasure().treasure);

            campaign.updateCampaignContent("adventure", {name:"sharedtreasure", treasure: newst});
        }
        this.setState({showTreasurePicker,restrictItems});
    }

    onFocus() {
        this.props.onFocus(this.props.chapter, this.props.section, this.props.subsection);
    }

    onClickContent(event) {
        if (this.props.onFocus) {
            this.props.onFocus(this.props.chapter, this.props.section, this.props.subsection);
        }
        if (this.props.pageSync) {
            this.props.pageSync.emit("clickfragment", {fragment:this.props.fragment, bookname:this.props.bookname, title:this.props.title, chapter:this.props.chapter, section:this.props.section, subsection:this.props.subsection, event});
        }
    }

    onChangeTitle(event) {
        const t=this;
        const newTitle = event.target.value;
        if (this.saveTitle){
            clearTimeout(this.saveTitle);
        }
        this.saveTitle = setTimeout(function () {
            t.saveTitle = null;
            t.props.onChangeTitle(t.props.chapter, t.props.section, t.props.subsection, newTitle);
        }, 200);
        this.setState({title:newTitle});
    }

    clickLinks(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({showLinkList:true, anchorEl:event.target});
    }

    closeLinks() {
        this.setState({showLinkList:false});
    }

    onClickLink(link) {
        if (link.type=="pin") {
            const p = campaign.getPinInfo(link.name);
            this.props.onClickLink(p.mapPos);
        } else {
            this.props.onClickEncounter(link.name);
        }
        this.setState({showLinkList:false});
    }

    onClickEditContent(contentType, contentId) {
        this.setState({showLinkList:false, editContent:contentTypeMap[contentType].edit(contentId, this.doneEditContent.bind(this))});
    }

    doneEditContent() {
        this.setState({editContent:null});
    }

    getDiceRoller() {
        const {ChatButton} = require('./renderchat.jsx');
        return <ChatButton/>;
    }

    doSubRoll(name, text){
        const dice = getDiceFromString(text);
        const {rolls} = doRoll(dice);

        const newRoll = {dice, rolls, source:name, action:null};

        Chat.addGMRoll(newRoll);
        return newRoll;
    }

    doTextRoll(text){
        return this.doSubRoll("Book", text);
    }
}


class PickBookDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {pickBook:!props.bookname, selectedReference:props.bookname, chapter:props.chapter||0, section:(props.section!==null)?props.section:-1, subsection:(props.subsection!==null)?props.subsection:-1};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({pickBook:!this.props.bookname, selectedReference:this.props.bookname || campaign.getUserSettings().selectedReference, chapter:this.props.chapter||0, section:(this.props.section!==null)?this.props.section:-1, subsection:(this.props.subsection!==null)?this.props.subsection:-1});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        const v = this.state.selectedReference;
        const bookinfo = campaign.getBookInfo(v);
        let chapter, section, subsection;
        
        if (bookinfo) {
            const chapterInfo = (bookinfo.chapters||[])[this.state.chapter||0];
            if (chapterInfo) {
                chapter=chapterInfo.name;
                if (this.state.section >=0) {
                    const sectionInfo = (chapterInfo.sections||[])[this.state.section];
                    if (sectionInfo) {
                        section=sectionInfo.name;
                        if (this.state.subsection >=0) {
                            const subsectionInfo = (sectionInfo.subsections||[])[this.state.subsection];
                            if (subsectionInfo) {
                                subsection = subsectionInfo.name;
                            }
                        }
                    }
                }
            }
        }

        return <Dialog
            open
            scroll="paper"
            maxWidth="md"
            fullWidth
            classes={{paper:"minvh-80"}}
            >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Book Reference
                {bookinfo?": "+bookinfo.displayName:null}
                {v?<div className="i f3 ttn">{chapter?chapter:null}{section?(" / "+section):null}{subsection?(" / "+subsection):null}</div>:null}
            </DialogTitle>
            <div className="h8 flex-auto overflow-hidden w-100 ph2 pt2">
                {bookinfo?<Book bookname={v} chapter={this.state.chapter} section={this.state.section} subsection={this.state.subsection} tocOnly onClick={this.clickSection.bind(this)}/>:null}
            </div>
            <DialogActions>
                <Button onClick={this.pickBook.bind(this)} color="primary">
                    Pick Book
                </Button>
                {v?<Button onClick={this.selectBook.bind(this)} color="primary">
                    Save
                </Button>:null}
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <BookPicker open={this.state.pickBook} noNew onClose={this.closePickBook.bind(this)}/>
        </Dialog>;
    }

    pickBook() {
        this.setState({pickBook:true});
    }

    closePickBook(selectedReference) {
        if (!selectedReference && !this.state.selectedReference) {
            this.props.onClose();
        } else if (selectedReference && (selectedReference != this.state.selectedReference)) {
            this.setState({selectedReference, chapter:0, section:-1, subsection:-1});
        }
        this.setState({pickBook:false});
    }

    clickSection(chapter, section, subsection) {
        this.setState({chapter, section, subsection})
    }

    getBooksSelection() {
        const books = campaign.getBookList();
        let ret=[];
    
        for (let i in books) {
            const b=books[i];
    
            ret.push(<MenuItem key={b.name} value={b.name}>{b.displayName}</MenuItem>);
        }

        return <Select
            value={this.state.selectedReference||""}
            className="w-100 f1 titlecolor titletext"
            renderValue={renderValue}
            displayEmpty
            onChange={this.changeEncounterReference.bind(this)}
        >
            {ret}
        </Select>;

        function renderValue(value) {
            const bookInfo = campaign.getBookInfo(value);
            if (!bookInfo) {
                return <span className="black-30">Select Book</span>;
            }
            return <span>{bookInfo.displayName}</span>
        }
    }

    changeEncounterReference(event) {
        this.setState({selectedReference:event.target.value, chapter:0, section:-1, subsection:-1});
    }

    onClose() {
        this.props.onClose();
    }

    selectBook() {
        this.props.onClose(this.state.selectedReference, this.state.chapter, this.state.section, this.state.subsection);
    }
}


class BookPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render () {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            open
            maxWidth="sm"
            classes={{paper:"minvh-80"}}
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}>{this.props.title||"Pick Book"}</DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={this.props.bookList||campaign.getBookList()}
                    onClick={this.pickBook.bind(this,false)}
                    filters={bookListFilters}
                />
            </DialogContent>
            <DialogActions>
                {!this.props.noNew?<Button color="primary" onClick={this.onClickNewBook.bind(this)} size="small">New Book</Button>:null}
                <Button onClick={this.onClose.bind(this,null)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <NewBook open={this.state.showNewBook} onClose={this.pickBook.bind(this,true)}/>
        </Dialog>;
    }

    onClickNewBook() {
        this.setState({showNewBook:true});
    }

    onClose() {
        this.props.onClose();
    }

    pickBook(newbook, book) {
        this.setState({showNewBook:false});
        this.props.onClose(book, newbook);
    }
}


class BooksPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({selected:this.props.selected||{}})
        }
    }    

    render () {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Pick Books</DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={campaign.getBookList()}
                    onClick={this.pickBook.bind(this,false)}
                    onSelectedChange={this.onSelectedChange.bind(this)}
                    select="list"
                    selected={this.state.selected}
                    filters={bookListFilters}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this,true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.onClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <NewBook open={this.state.showNewBook} onClose={this.pickBook.bind(this,true)}/>
        </Dialog>;
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }

    onClose(save) {
        this.props.onClose(save?this.state.selected:null);
    }

    pickBook(book) {
        this.setState({showBook:true, book});
    }
}

class BookDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        let {bookname, addToEncounter, editable, fragment, chapter, section,subsection} = this.props;
        const bookInfo = campaign.getBookInfo(bookname);
        let gotoBook;

        if (fragment && bookInfo) {
            const chaptInfo = getChapterInfoFromFragment(bookname, fragment);
            chapter=chaptInfo.chapter;
            section=chaptInfo.section;
            subsection=chaptInfo.subsection;
        }

        if (globalDataListener.pageSync.listenerCount("setcampaignbook")) {
            gotoBook=<Button onClick={this.showBook.bind(this,bookname, chapter, section,subsection)} color="primary">
                show in campaign
            </Button>;
        }

        return <Dialog
            className="nodrag"
            open
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            maxWidth="md"
            fullWidth
            classes={{paper:"minvh-80"}}
        >
            <DialogTitle onClose={this.onClose.bind(this)}>{bookInfo?.displayName || bookname}</DialogTitle>
            <div className="flex-auto w-100 h4 overflow-hidden ph2 relative ignoreDrag">
                <div className="absolute t00 h-100 w-100 pr4">
                    <Book editable={editable} bookname={bookname} chapter={chapter} section={section} subsection={subsection} pageSync={globalDataListener.pageSync} addToEncounter={addToEncounter}/>
                </div>
            </div>
            <DialogActions>
                {gotoBook}
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    showBook(bookname, chapter, section,subsection) {
        globalDataListener.pageSync.emit("setcampaignbook", bookname, chapter, section,subsection);
        this.props.onClose();
    };

    onClose() {
        this.props.onClose();
    }
}

class SubBookDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        const {bookname, fragment, displayName, rollable, open, character} = this.props;
        if (!open) {
            return null;
        }

        return <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}>{displayName || "Book"}</DialogTitle>
            <DialogContent>
                <SubBook bookname={bookname} fragment={fragment} rollable={rollable} character={character}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onClose() {
        this.props.onClose();
    }
}

class BookPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.baseId = Math.trunc(Math.random()*10000)+"id.";
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        const bookInfo = campaign.getBookInfo(this.props.bookname)||{};
        const html = doPrintBook(this.props.bookname);
        const blob = new Blob([htmlHdr,html,htmlFtr], {
            type: "text/html",
          });
        const dataUrl =URL.createObjectURL(blob);

        return <Dialog
            className="nodrag"
            open
            scroll="paper"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}></DialogTitle>
            <DialogContent>
                <div className="stdcontent" ref={ref => this.ref=ref}>
                    <h1 className="tc">{bookInfo.displayName}</h1>
                    <div dangerouslySetInnerHTML={{__html:html}}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button component="a" href={dataUrl} download={bookInfo.displayName} color="primary">
                    Download
                </Button>
                <ReactToPrint
                    trigger={() => {
                        return <Button color="primary">Print</Button>;
                    }}
                    content={() => this.ref}
                    pageStyle="@page {margin: 0.5in}"
                    onAfterPrint={() => this.props.onClose()}
                />

                <Button onClick={this.onClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onClose() {
        this.props.onClose();
    }
}

class ContentPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:props.selected||{}, selectedType:props.selectedType||getBookContentTypes()[0], shareHeader:props.shareHeader||false, showList:props.showList, hideDescription:this.props.hideDescription||false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({selected:this.props.selected||{}, selectedType:this.props.selectedType||this.state.selectedType, shareHeader:this.props.shareHeader||false, showList:this.props.showList||false, hideDescription:this.props.hideDescription||false});
        }
    }

    handleClose(save) {
        if (save) {
            this.props.onClose(this.state.selected, this.state.selectedType, this.state.shareHeader||false, this.state.showList||false, this.state.hideDescription||false);
        } else {
            this.props.onClose();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        const selectedType = this.state.selectedType || getBookContentTypes()[0];
        const {loader,createNew, newButton,useImages, listFilters} = contentTypeMap[selectedType];
        const mode=this.props.mode;

        const l = loader();
        
        return <Dialog
            scroll="paper"
            maxWidth="md"
            fullWidth
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Pick Content
            </DialogTitle>
            <DialogContent>
                <div className="minvh-80">
                    <div className="notecontent mb1">
                        <SelectVal label="Content Type" values={getBookContentTypes()} value={selectedType} onClick={this.pickType.bind(this)} fullWidth/>
                    </div>
                    <ListFilter
                        list={l}
                        select="list"
                        single={this.props.mode=="shared"}
                        filters={listFilters?listFilters:defaultContentListFilters}
                        extraSelectInfo
                        selected={this.state.selected}
                        onSelectedChange={this.selectChange.bind(this)}
                        onClick={this.onShowDetails.bind(this)}
                        showThumbnails={useImages}
                        selectAll
                    />
                </div>
            </DialogContent>
            <DialogActions classes={{root:"flex-wrap"}}>
                <CheckVal value={this.state.hideDescription||false} label="Hide descriptions" onChange={this.toggleDescription.bind(this)}/>
                {!this.state.shareHeader?<CheckVal className="mr2" value={this.state.showList||false} label="Show as List" onChange={this.toggleListMode.bind(this)}/>:null}
                {mode=="any"?<CheckVal value={this.state.shareHeader||false} label="Separate Sections" onChange={this.toggleShareHeader.bind(this)}/>:null}
                {createNew?<Button onClick={this.newContent.bind(this)} color="primary">
                    New
                </Button>:newButton?newButton(this.onCreateNew.bind(this)):null}
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            {this.state.showNewContent&&createNew?createNew(this.onCreateNew.bind(this)):null}
            {this.state.showEditContent?contentTypeMap[selectedType].edit(this.state.showEditInfo.id||this.state.showEditInfo.name, this.doneEditContent.bind(this)):null}
        </Dialog>;
    }

    selectChange(selected) {
        this.setState({selected})
    }

    setFilter(filter, val) {
        const filterList = Object.assign({}, this.state.filterList);
        if (val == "all") {
            val=null;
        }
        filterList[filter]=val;
        this.setState({filterList});
    }
    
    showAll() {
        this.setState({showAll:true});
    }

    onCreateNew(info, noEdit) {
        if (info) {
            const selected = Object.assign({}, this.state.selected||{});

            selected[info.name.toLowerCase()] = info;
            this.setState({selected, showNewContent:false, showEditContent:!noEdit, showEditInfo:info, filter:{name:info.displayName||info.name}});
        } else {
            this.setState({showNewContent:false});
        }
    }

    onShowDetails(name, id) {
        if (name) {
            this.setState({showEditContent:true, showEditInfo:{name,id:id||null}});
        }
    }

    toggleEdited() {
        this.setState({edited:!this.state.edited});
    }

    toggleShareHeader() {
        this.setState({shareHeader:!this.state.shareHeader});
    }

    toggleListMode() {
        this.setState({showList:!this.state.showList});
    }

    toggleDescription() {
        this.setState({hideDescription:!this.state.hideDescription});
    }

    doneEditContent() {
        this.setState({showEditContent:false});
    }

    pickType(selectedType) {
        this.setState({selectedType, selected:{}, filter:{name:""},showAll:false});
    }

    newContent() {
        this.setState({showNewContent:true});
    }
}

const defaultContentListFilters = [
    defaultSourceFilter,
    defaultBookFilter,
    defaultGamesystemFilter,
];


class NewBook extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.idKey = campaign.newUid();
    }

    createBook() {
        const t=this;
        const name = this.state.name;
        const importBatch = this.state.importBatch;
        const selectedBook = this.state.selectedBook;
        let batch=[];

        if (importBatch) {
            batch=importBatch;
        } else if (selectedBook) {
            const oldBook = campaign.getBookInfo(selectedBook);
            const book = JSON.parse(JSON.stringify(oldBook));  // brute force copy
            book.name = campaign.newUid();

            for (let c in book.chapters) {
                const chapter = book.chapters[c];
                const ofrag = campaign.getBookFragment(chapter.fragment);
                chapter.fragment = campaign.newUid();
                if (ofrag) {
                    const frag = Object.assign({}, ofrag);
                    frag.name=chapter.fragment;
                    batch.push({contentType:"books", value:frag});
                }
                for (let s in chapter.sections) {
                    const section = chapter.sections[s];
                    const ofrag = campaign.getBookFragment(section.fragment);
                    section.fragment = campaign.newUid();
                    if (ofrag) {
                        const frag = Object.assign({}, ofrag);
                        frag.name=section.fragment;
                        batch.push({contentType:"books", value:frag});
                    }

                    for (let ss in section.subsections) {
                        const subsection = section.subsections[ss];
                        const ofrag = campaign.getBookFragment(subsection.fragment);
                        subsection.fragment = campaign.newUid();
                        if (ofrag) {
                            const frag = Object.assign({}, ofrag);
                            frag.name=subsection.fragment;
                            batch.push({contentType:"books", value:frag});
                        }
                    }    
                }
            }
            batch.unshift({contentType:"books", value:book});

        } else {
            batch.push({contentType:"books", value:{name:campaign.newUid(), type:"book", chapters:[{name:"Introduction", fragment:campaign.newUid()}]}});
        }

        batch[0].value.displayName=name;

        setTimeout( function () {
            campaign.batchUpdateCampaignContent(batch).then(function () {
                t.props.onClose(batch[0].value.name);
                t.setState({loading:false});
            }).catch(function (err) {
                displayMessage("Error loading book: "+err.message);
                t.setState({loading:false});
            });
        },10);

        this.setState({loading:true})

    }

    onClose() {
        this.props.onClose();
    }

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({name:event.target.value});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({name:"",importBatch:null});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const books = campaign.getBookList();
        const list={};
        const selectedBook = this.state.selectedBook;
        const importBatch = this.state.importBatch;
        const bookinfo = campaign.getBookInfo(selectedBook);
        const name=this.state.name||"";

        list.Empty="Empty";
        for (let i in books){
            list[books[i].name]=books[i].displayName;
        }

        return <Dialog
            className="nodrag"
            open
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Create Book
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={name}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    label="Book Name"
                    fullWidth
                />
                {bookinfo?<div>Create based on {bookinfo.displayName}</div>:null}
                {importBatch?<div>Create based on imported file {this.state.importFileName}</div>:null}
                {!bookinfo&&!importBatch?<div className="hk-well">
                    <div className="tl mb1">
                        <p>You can import books from a .docx file, the format saved by Microsoft Word or Google Docs.
                        Header styles are used to differentiate chapters and sections.</p>
                        <div>H1: Creates new chapters</div>
                        <div>H2: Creates new sections</div>
                        <div>H3+: Creates new subsections</div>
                    </div>
                    <input
                        key={this.idKey}
                        accept="*"
                        className="dn"
                        id="new-book-import-file"
                        type="file"
                        onChange={this.importBook.bind(this)}
                    />
                    <label htmlFor="new-book-import-file">
                        <Button color="primary" variant="outlined" component="span">
                            Import Book
                        </Button>
                    </label>
                </div>:null}
            </DialogContent>
            <DialogActions>

                <Button disabled={!!this.state.importBatch} onClick={this.pickBook.bind(this)} color="primary">
                    Pick book to copy
                </Button>
                <Button disabled={!name || name==""} onClick={this.createBook.bind(this)} color="primary">
                    Create
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <Dialog open={this.state.importing||false}>
                <DialogContent>
                    Importing book...
                </DialogContent>
            </Dialog>
            <Dialog open={this.state.loading||false}>
                <DialogContent>
                    Creating book...
                </DialogContent>
            </Dialog>
            <BookPicker open={this.state.showPickBook} onClose={this.onClosePickBook.bind(this)}/>
        </Dialog>;
    }

    importBook(e) {
        this.idKey=campaign.newUid();
        if (e.target.files.length == 1) {
            const t=this;
            // import file
            const file = e.target.files[0];
            this.setState({importing:true, name:this.state.name||cleanFilename(file.name)});

            // delay to render importing
            setTimeout(function () {
                importBook(file).then(function (batch){
                    t.setState({importing:false, importBatch:batch, importFileName:file.name});
                }, function (err) {
                    displayMessage("Error importing book: "+err.message);
                    t.setState({importing:false, importBatch:null})
                });
            }, 10);
        }

    }

    pickBook() {
        this.setState({showPickBook:true});
    }

    onClosePickBook(selectedBook) {
        if (selectedBook && !this.state.name) {
            const bookinfo = campaign.getBookInfo(selectedBook);

            return this.setState({showPickBook:false, selectedBook, name:bookinfo.displayName + " copy"});
        }
        return this.setState({showPickBook:false, selectedBook:selectedBook||this.state.selectedBook});
    }
}

class BookHeader extends React.Component {
    constructor(props) {
        super(props);

        let filter = {name:""}

        this.state= {filter:filter, selected:[], selectedType:getBookContentTypes()[0], shareHeader:true};
    }

    render() {
        const {showBuildPkg} = this.state;
        const bookinfo = campaign.getBookInfo(this.props.bookname);
        const showMap = campaign.getUserSettings().showMapInBookEdit;
        const wideEnough = (windowSize.innerWidth > 800);
        const favorites = campaign.getUserSettings().bookFavorites||{};
        const bookPkg = this.getBookPackage();
        const publisher = campaign.publisher;

        const {BuildPackageDialog} = showBuildPkg?require('./packages.jsx'):{};

        return <span>
            {(bookinfo && bookinfo.displayName) || this.props.bookname}
            {wideEnough?<Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={this.setShowMap.bind(this, !showMap)}>{showMap?"Hide Map":"Show Map"}</Button>:null}
            {this.props.editable?<Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={navTo.bind(null, "/#book?id=", this.props.bookname)}>Done Editing</Button>:null}
            {!this.props.editable?<Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={navTo.bind(null, "/#book?mode=edit&id=", this.props.bookname)}>Edit</Button>:null}
            {bookinfo?<Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={this.clickSettings.bind(this)}>Settings</Button>:null}
            {bookPkg?<Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={this.showBuildPkg.bind(this,bookPkg)}>Package</Button>:null}
            {bookinfo?<Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={this.showPrintBook.bind(this,campaign.getSourcePreventEmbedding(bookinfo.source),true)}>Export</Button>:null}
            {false && bookinfo?.pdf?<Button className="ml1 minw2" color="primary" component="a" variant="outlined" size="small" href={bookinfo.pdf} target="_blank">PDF</Button>:null}
            <span className={favorites[bookinfo.name]?"pa1 fas fa-star":"pa1 far fa-star"} onClick={this.toggleFavorite.bind(this)}/>
            {bookinfo&&publisher?<Tooltip title="Set sources on spells in book based on the classes"><Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={this.convertSpells.bind(this)}>Spell Sources</Button></Tooltip>:null}
            <BookSettings open={this.state.showSettings} bookname={this.props.bookname} onClose={this.closeSettings.bind(this)}/>
            {showBuildPkg?<BuildPackageDialog id={showBuildPkg} open onClose={this.showBuildPkg.bind(this,null)}/>:null}
            {this.state.printBook?<BookPrint bookname={this.props.bookname} open={this.state.printBook} onClose={this.showPrintBook.bind(this,false)}/>:null}
        </span>;
    }

    showBuildPkg(showBuildPkg) {
        this.setState({showBuildPkg});
    }

    getBookPackage() {
        const pkgs = campaign.getSortedMyPackages();
        const {bookname} = this.props;

        for (let i in pkgs) {
            const p = pkgs[i];

            let selected = p.pkgOptions?.selectedBooks;
            for (let i in selected) {
                if (selected[i]==bookname) {
                    return p.id;
                }
            }
        }
    }

    showPrintBook(preventEmbedding, printBook) {
        if (printBook) {
            if (preventEmbedding) {
                displayMessage("The publisher of this book has disallowed republishing.");
                return;
            } else if (!campaign.allowExport) {
                displayMessage(<span>You do not have permissions to export books.<br/>See <a href="/marketplace#shardsubscriptions">subscriptions</a> to enable this feature.</span>);
                return;
            }
        }
        this.setState({printBook});
    }

    setShowMap(show) {
        campaign.updateUserSettings({showMapInBookEdit:show});
        this.setState({showMapInBookEdit:show});
    }

    clickSettings() {
        this.setState({showSettings:true});
    }

    closeSettings() {
        this.setState({showSettings:false});
    }

    toggleFavorite() {
        const name = this.props.bookname;
        const bookFavorites = Object.assign({},campaign.getUserSettings().bookFavorites||{});
        if (bookFavorites[name]) {
            delete bookFavorites[name];
        } else {
            bookFavorites[name]=true;
        }
        campaign.updateUserSettings({bookFavorites});
        this.setState({bookFavorites})
    }

    convertSpells() {
        const bookinfo = campaign.getBookInfo(this.props.bookname);
        
        for (let c in bookinfo.chapters) {
            const chapter = bookinfo.chapters[c];
            fixupSpells(chapter);
            for (let s in chapter.sections) {
                const section = chapter.sections[s];
                fixupSpells(section);

                for (let ss in section.subsections) {
                    const subsection = section.subsections[ss];
                    fixupSpells(subsection);
                }
            }
        }
        //console.log("new bookinfo", bookinfo);

        function fixupSpells(f) {
            if (f.contentType == "Spells") {
                //console.log("found spell", f.contentType, f.contentId);
                updateSpell(f.contentId);
            }
            if ((f.contentList||[])[0]?.contentType == "Spells") {
                f.contentList = f.contentList.concat();
                for (let i in f.contentList) {
                    updateSpell(f.contentList[i].contentId);
                }
                //console.log("found spells list", f.contentList);
            }
        }
    }
}

const spellClassMap = {
    bard:"Arcane",
    cleric:"Divine",
    druid:"Primordial",
    paladin:"Divine",
    ranger:"Primordial",
    sorcerer:"Arcane",
    warlock:"Wyrd",
    wizard:"Arcane"
}

function updateSpell(id) {
    const spell = campaign.getSpell(id);
    if (spell) {
        //console.log(`found spell "${spell.displayName}"`);
        const spellSources = (spell.spellSources||[]).concat();
        let updated;

        for (let cls of spell.classes) {
            const source = spellClassMap[cls.toLowerCase()];
            if (source) {
                if (!spellSources.includes(source)) {
                    updated=true;
                    spellSources.push(source);
                }
            } else {
                //console.log(`could not find source "${cls}`);
            }
        }
        if (updated) {
            const newSpell = Object.assign({}, spell);
            Object.assign(newSpell, {spellSources})
            //console.log("new spell", newSpell);
            campaign.updateCampaignContent("spells", newSpell);
        }
    }
}

class BookSettings extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
        this.idKey = campaign.newUid();
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({bookinfo:campaign.getBookInfo(this.props.bookname)});
        }
    }

    render() {
        const bookinfo=this.state.bookinfo;
        if (!this.props.open || !bookinfo) {
            return null;
        }

        this.values = {"new":"new...", "none":"none"}
        if (bookinfo.collectionId){
            this.values[bookinfo.collectionId]=bookinfo.collectionDisplayName;
        }
        const books = campaign.getBookList();
        for (let i in books) {
            const b= books[i];
            if (b.collectionId && !this.values[b.collectionId]) {
                this.values[b.collectionId]=b.collectionDisplayName;
            }
        }
        
        return <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>Book Settings</DialogTitle>
            <DialogContent>
                <TextVal className="mb2" text={bookinfo.displayName||""} onChange={this.onChangeField.bind(this,"displayName")} helperText="Book Name" fullWidth/>
                <SelectVal className="mb2" helperText="Collection" values={this.values} value={bookinfo.collectionId||"none"} onClick={this.setCollection.bind(this)} fullWidth/>
                {bookinfo.collectionId?<TextVal text={bookinfo.collectionSortKey||""} onChange={this.onChangeField.bind(this,"collectionSortKey")} helperText="Collection Sort Key" fullWidth/>:null}
                <SelectVal className="mb2" fullWidth value={bookinfo.gamesystem||"any"} values={gamesystemAnyOptions} onClick={this.onChangeField.bind(this,"gamesystem")} helperText="Game System"/>
                {false?<div>
                    <input
                        key={this.idKey}
                        accept="application/pdf"
                        className="dn"
                        id={"pdf-file-upload"+this.idKey}
                        type="file"
                        onChange={this.selectFile.bind(this)}
                        multiple
                    />
                    <label htmlFor={"pdf-file-upload"+this.idKey}>
                        <Button className="ml2 minw2" color="secondary" variant="outlined" size="small"  component="span">
                            Upload Pdf
                        </Button>
                    </label>  {bookinfo.pdfName}
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onSave.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <TextBasicEdit show={this.state.newCollection} label="Collection Name" onChange={this.onNewCollection.bind(this)} />
            <Dialog open={this.state.loading||false}>
                <DialogContent>
                    {this.state.loadingMessage||"Loading..."}
                </DialogContent>
            </Dialog>
        </Dialog>;
    }

/*
    async selectFile(e) {
        this.idKey = campaign.newUid();
        if (e.target.files.length == 1) {
            // upload file
            try {
                const userId = campaign.currentUser.uid;
                const storage = getStorage(firebase);
                const bookinfo = Object.assign({}, this.state.bookinfo);
                const file = e.target.files[0];

                this.setState({loading:true, 
                    loadingMessage:"Loading File "+file.name
                });

                const path = "users/"+userId+"/pdf/"+this.idKey+"/"+bookinfo.displayName+".pdf";
                const fileRefThumb = ref(storage,path);

                await uploadBytes(fileRefThumb, file, {contentType:"application/pdf",cacheControl: "public,max-age=4838400"});
                const downloadThumbURL = getDirectDownloadUrl(path);

                bookinfo.pdf=downloadThumbURL;
                bookinfo.pdfName = file.name;
        
                this.onChangeField("pdf",downloadThumbURL);
                this.setState({loading:false,bookinfo});
            } catch (err) {
                this.errorSelectingFile("Error uploading file", err);
                this.setState({loading:false});
            }
       }
    }

    errorSelectingFile(msg, err) {
        displayMessage(msg+"\nError:"+err);
        console.log(msg, err);
        this.setState({loading:false});
    }
*/

    onNewCollection(collection) {
        if (collection) {
            const bookinfo = Object.assign({}, this.state.bookinfo);
            bookinfo.collectionDisplayName = collection;
            bookinfo.collectionId = campaign.newUid();
            this.setState({bookinfo,newCollection:false});
        } else {
            this.setState({newCollection:false});
        }
    }

    setCollection(collectionId) {
        if (collectionId == "new") {
            this.setState({newCollection:true});
        } else {
            const bookinfo = Object.assign({}, this.state.bookinfo);
            if (collectionId == "none") {
                delete bookinfo.collectionDisplayName;
                delete bookinfo.collectionId;
            } else {
                bookinfo.collectionDisplayName = this.values[collectionId];
                bookinfo.collectionId = collectionId;
            }
            this.setState({bookinfo});
        }
    }

    onChangeField(prop,name) {
        const bookinfo = Object.assign({}, this.state.bookinfo);
        bookinfo[prop]=name;
        this.setState({bookinfo});
    }

    onSave() {
        campaign.updateCampaignContent("books", this.state.bookinfo);
        this.props.onClose();
    }
}

class RenderBooks extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getSpellListByName()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "books");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "books");
    }

	render() {
        const bookList = campaign.getBookList();
        const favorites = campaign.getUserSettings().bookFavorites||{};
        const mru = campaign.getUserMRUList("mruBooks")||[];

        const list = [];
        for (let i in bookList) {
            const b=bookList[i];
            const fav = favorites[b.name];
            const collections = [];
            if (fav) {
                collections.push("Favorite");
            }
            if (b.collectionId && b.collectionDisplayName) {
                collections.push(b.collectionDisplayName);
            } else {
                collections.push("None");
            }

            if (mru.findIndex(function (a) {return a.book==b.name})>=0) {
                collections.push("Recenty Used");
            }
            list.push({name:b.name, displayName:(b.collectionId?((b.collectionDisplayName||"")+": "):"")+(b.displayName||""), source:b.source, fav, collections, edited:b.edited, gamesystem:b.gamesystem});
        }
        list.sort(function (a,b) {return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())});
        return <div className="notecontent" key={this.props.id}>
            <ListFilter 
                list={list}
                filters={bookListFilters}
                onClick={this.onClick.bind(this)}
                render={this.renderBook.bind(this)}
                select="click"
            />
        </div>;
    }

    onClick(name) {
        window.location.href=addCampaignToPath("#book?id="+encodeURIComponent(name),true);
    }

    toggleFavorite(name, e) {
        e.preventDefault();
        e.stopPropagation();
        const bookFavorites = Object.assign({},campaign.getUserSettings().bookFavorites||{});
        if (bookFavorites[name]) {
            delete bookFavorites[name];
        } else {
            bookFavorites[name]=true;
        }
        campaign.updateUserSettings({bookFavorites});
        this.setState({bookFavorites})
    }

    renderBook(book) {
        const favorites = campaign.getUserSettings().bookFavorites||{};
        return <span><span className={favorites[book.name]?"pa1 fas fa-star":"pa1 far fa-star"} onClick={this.toggleFavorite.bind(this,book.name)}/>{book.collectionId?((book.collectionDisplayName||"")+": "):null} {book.displayName}</span>
    }
}


const bookListFilters = [
    {
        filterName:"Collection",
        fieldName:"collections",
        convertField(collections, it) {
            return collections || it.collectionDisplayName;
        }
    },
    defaultSourceFilter,
    {
        filterName:"Publisher",
        fieldName:"publisher",
        convertField(publisher, it) {
            return campaign.getSourcePublisher(it.source) || "None";
        }
    },
    defaultGamesystemAnyFilter
];

class LibraryList extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    render() {
        const t=this;
        const books = campaign.getBookList();
        const favorites = campaign.getUserSettings().bookFavorites||{};
        const mru = campaign.getUserMRUList("mruBooks");
        const mrulist = [];
        const favs = []
        let list = [];
        const collections = {};
        const included = {};
        let toomany;

        for (let i in mru) {
            const b = campaign.getBookInfo(mru[i].book);
            if (b && mrulist.length < 3) {
                const fav = favorites[b.name];

                if (!included[b.name]) {
                    mrulist.push(<div key={b.name} className="mb1">
                        <span className={fav?"ph1 fas fa-star":"ph1 far fa-star"} onClick={this.toggleFavorite.bind(this, b.name)}/>
                        <a href={addCampaignToPath("#book?id="+encodeURIComponent(b.name),true)}>
                            {b.displayName}
                        </a>
                    </div>);
                }
                included[b.name]=true;
            }
        }

        for (let i in books) {
            const b=books[i];
            const fav = favorites[b.name];
            const l = fav?favs:list;

            if (fav || !b.collectionId || !collections[b.collectionId]) {
                if (!fav && b.collectionId) {
                    // add a collection instead
                    const cfav = favorites[b.collectionId];
                    const cl = cfav?favs:list;
                    let minb = b;
                    for (let x in books) {
                        const bx = books[x];
                        if ((bx.collectionId==b.collectionId) && (((bx.collectionSortKey||bx.displayName||"").toLowerCase().localeCompare((minb.collectionSortKey||minb.displayName||"").toLowerCase()) < 0))) {
                            minb=bx;
                        }
                    }

                    cl.push({displayName:b.collectionDisplayName, name:minb.name, fav:cfav, id:b.collectionId});
                    collections[b.collectionId]=minb;
                } else if (!included[b.name]) {
                    l.push({displayName:b.displayName, name:b.name, fav, id:b.name});
                }
            }
        }

        favs.sort(function(a,b) {return (a.displayName||"").toLowerCase().localeCompare((b.displayName||"").toLowerCase())});
        list.sort(function(a,b) {return (a.displayName||"").toLowerCase().localeCompare((b.displayName||"").toLowerCase())});

        if ((mrulist.length + favs.length +list.length)>10) {
            const base = mrulist.length + favs.length;
            if (base < 10) {
                list = list.slice(0, 10-base);
            } else {
                list=[];
            }
            toomany = true;
        }
        return <div>
            {mrulist.length?<div className="titlebordercolor bb mb1">
                <div className="b">Recently viewed</div>
                {mrulist}
            </div>:null}
            {favs.length?<div className="titlebordercolor bb mb1">
                <div className="b">Favorites</div>
                {favs.map(function(b){
                    return <div key={b.name} className="mb1">
                        <span className={b.fav?"ph1 fas fa-star":"ph1 far fa-star"} onClick={t.toggleFavorite.bind(t, b.id)}/>
                        <a href={addCampaignToPath("#book?id="+encodeURIComponent(b.name),true)}>
                            {b.displayName}
                        </a>
                    </div>;
                })}
            </div>:null}
            {list.map(function(b){
                return <div key={b.name} className="mb1">
                    <span className={b.fav?"ph1 fas fa-star":"ph1 far fa-star"} onClick={t.toggleFavorite.bind(t, b.id)}/>
                    <a href={addCampaignToPath("#book?id="+encodeURIComponent(b.name),true)}>
                        {b.displayName}
                    </a>
                </div>;
            })}
            {toomany?<div>
                <a href={addCampaignToPath("#book")}>
                    more...
                </a>
            </div>:null}
        </div>;
    }

    toggleFavorite(name) {
        const bookFavorites = Object.assign({},campaign.getUserSettings().bookFavorites||{});
        if (bookFavorites[name]) {
            delete bookFavorites[name];
        } else {
            bookFavorites[name]=true;
        }
        campaign.updateUserSettings({bookFavorites});
        this.setState({bookFavorites})
    }
}

class BooksHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onNew() {
        this.setState({showNew:true});
    }

    render() {
        return <span>
            Library
            {campaign.isDefaultCampaign()?<Button className="ml1 minw2" variant="outlined" size="small" onClick={this.onNew.bind(this)}>New</Button>:null}
            <NewBook open={this.state.showNew} onClose={this.closeNewBook.bind(this)}/>
        </span>;
    }

    closeNewBook(name){
        if (name) {
            navTo("/#book?mode=edit&id=", name);
        }
        this.setState({showNew:false});
    }
}

function doPrintBook(name) {
    const bookinfo = campaign.getBookInfo(name);
    const chapters = bookinfo.chapters || [];
    const list = [];

    for (let i in chapters) {
        const chapter = chapters[i];
        list.push(getPrintSection(chapter,1));
        for (let s in chapter.sections) {
            const sec = chapter.sections[s];
            list.push(getPrintSection(sec,2));
            for (let ss in sec.subsections) {
                const subSec = sec.subsections[ss];
                const depth = 3+(subSec.depth||0);
                list.push(getPrintSection(subSec,depth));
            }
        }
        list.push('<div style="page-break-after: always"></div>')
    }
    const ret = list.join("\n");
    //console.log("html", ret)
    return ret;
}

function getPrintSection(sec, depth) {
    const list=[];
    const clist=[];
    if ((depth==1) || sec.name) {
        list.push(`<h${depth}>${sec.name}</h${depth}>`);
        depth++;
    }
    const bf = campaign.getBookFragment(sec.fragment);
    let showHeader=false;

    const {contentType, contentList, contentId,showList} = sec;

    if (contentType && contentId) {
        const cm = contentTypeMap[contentType];
        if (cm?.print) {
            showHeader = cm.showHeader;
            clist.push(cm.print(contentId, true, depth));
        }
    } else if (contentList && (contentList.length>0)) {
        if (sec.showList && contentTypeMap[contentList[0].contentType]?.listPrint) {
            const contentType = contentList[0].contentType;
            clist.push(contentTypeMap[contentType].listPrint(contentList, depth));
        } else {
            for (let i in contentList) {
                const ce = contentList[i];
                const cm = contentTypeMap[ce.contentType];
                if (cm?.print) {
                   clist.push(cm.print(ce.contentId, false, depth));
                }
            }
        }
    }

    if (showHeader) {
        list.push(clist.join("\n"));
    }
    if (bf?.entry) {
        list.push(htmlFromEntry(bf.entry));
    }
    if (!showHeader) {
        list.push(clist.join("\n"));
    }
    return removeShardLinks(list.join("\n"));
}

function removeShardLinks(html) {
    const wrapper= document.createElement('div');
    wrapper.innerHTML= html;


    const alist=wrapper.getElementsByTagName("a");
    const ignoreHosts=["play.shardtabletop.com", "beta.shardtabletop.com", "troll.shardtabletop.com",window.location.host];

    for (let a=alist.length-1; a>=0; a--){
        const anchor = alist[a];
        if (ignoreHosts.includes(anchor.host)) {
            const replacement= document.createElement('span');
            replacement.innerHTML=anchor.innerHTML;
            anchor.parentNode.replaceChild(replacement, anchor);
        }
    }


    return wrapper.innerHTML;
}

function deleteBook(name) {
    const bookinfo = campaign.getBookInfo(name);
    const chapters = bookinfo.chapters || [];
    const deleteList = []

    for (let i in chapters) {
        for (let s in chapters[i].sections) {
            const sec = chapters[i].sections[s];
            for (let ss  in sec.subsections) {
                if (sec.subsections[ss].fragment) {
                    deleteList.push({contentType:"books", delete:true, name:sec.subsections[ss].fragment});
                }
            }
            if (sec.fragment) {
                deleteList.push({contentType:"books", delete:true, name:sec.fragment});
            }
        }
        if (chapters[i].fragment) {
            deleteList.push({contentType:"books", delete:true, name:chapters[i].fragment});
        }
    }
    deleteList.push({contentType:"books", delete:true, name:name});
    return campaign.batchUpdateCampaignContent(deleteList);
}

function getChapterInfoFromFragment(name, fragment) {
    const bookinfo = campaign.getBookInfo(name);
    if (bookinfo) {
        const chapters = bookinfo.chapters || [];

        for (let i in chapters) {
            const chapt = chapters[i];
            if (chapt.fragment==fragment) {
                return {chapter:i, section:-1, subsection:-1};
            }
            for (let s in chapt.sections) {
                const sec = chapt.sections[s];
                if (sec.fragment==fragment) {
                    return {chapter:i, section:s, subsection:-1};
                }
                for (let ss  in sec.subsections) {
                    const subsec = sec.subsections[ss];
                    if (subsec.fragment==fragment) {
                        return {chapter:i, section:s, subsection:ss};
                    }
                }
            }
        }
    }
    return {chapter:0, section:-1, subsection:-1};
}

function getFragmentFromChapterSection(book, chapter, section, subsection) {
    const bookinfo = campaign.getBookInfo(book);
    if (!bookinfo) {
        return null;
    }
    const chapters = bookinfo.chapters || [];
    const c = chapters[chapter];
    if (c) {
        if (section >=0){
            const s = (c.sections||[])[section];
            if (s) {
                if (subsection >= 0) {
                    const ss=(s.subsections||[])[subsection];
                    if (ss) {
                        return ss.fragment;
                    } else {
                        return s.fragment;
                    }
                } else {
                    return s.fragment;
                }
            } else {
                return c.fragment;
            }
        } else {
            return c.fragment;
        }
    }
    return null;
}


function getBookDescription(book, chapter, section, subsection) {
    const bookinfo = campaign.getBookInfo(book);
    if (!bookinfo) {
        return "(unknown)";
    }
    const chapters = bookinfo.chapters || [];
    let name = bookinfo.displayName;
    const c = chapters[chapter];
    if (c && c.name) {
        name=name+": "+c.name;
        if (section >=0){
            const s = (c.sections||[])[section];
            if (s && s.name) {
                name = name+" / "+s.name;
                if (subsection >= 0) {
                    const ss=(s.subsections||[])[subsection];
                    if (ss && ss.name) {
                        name = name+" / "+ss.name;
                    }
                }
            }
        }
    }
    return name;
}


function navTo(base, name) {
    window.location.href = addCampaignToPath(base+(name?encodeURIComponent(name):""),true);
}

const htmlHdr = '<!DOCTYPE html><html lang="en" xmlns="http://www.w3.org/1999/xhtml" style="font-family: helvetica, Roboto, sans-serif">\
<head><style>\
body {font-family: "Libre Baskerville", serif; font-size: 13px;} \
h1 {font-variant: small-caps; font-size: 22px;font-weight: bold; font-family: "Convergence", sans-serif;} \
h2 {font-variant: small-caps; font-size: 17px; font-weight: bold; border-bottom-style: solid; border-bottom-width: 1px; margin-bottom: 2px; font-family: "Convergence", sans-serif;} \
h3 {font-variant: small-caps; font-size: 15px;font-weight: bold;font-family: "Convergence", sans-serif;} \
h4 {font-variant: small-caps; font-size: 14px;font-weight: bold;font-family: "Convergence", sans-serif;} \
h5 {font-variant: small-caps; font-size: 14px;font-weight: bold;font-family: "Convergence", sans-serif;} \
h6 {font-variant: small-caps; font-size: 13px;font-weight: bold;font-family: "Convergence", sans-serif;} \
h7 {font-variant: small-caps; font-size: 13px;font-weight: bold;font-family: "Convergence", sans-serif;} \
h8 {font-variant: small-caps; font-size: 13px;font-weight: bold;font-family: "Convergence", sans-serif;} \
p {margin-bottom: 7px;margin-top: 1px;}\
table, th, td {border: 1px solid black;}\
blockquote {margin: 8px;padding: 8px;font-family: "Convergence", sans-serif;} \
table {overflow: auto;border-collapse: collapse;background-color: transparent;font-family: "Convergence", sans-serif;width:100%;}\
tr {margin-top:1px;margin-bottom:1px;}\
tr:nth-child(even) {background-color: rgba(100, 95, 85, 0.5);}\
td {padding-left:2px;padding-right:2px;}\
th {padding-left:2px;padding-right:2px;}\
.itemHeader {font-variant: small-caps;font-weight: bold;}\
</style><meta http-equiv="content-type" content="text/html; charset=UTF-8"/></head>\
<body><base href="https://play.shardtabletop.com/" />';



const htmlFtr = '</body></html>';

const Book = sizeMe({monitorHeight:true, monitorWidth:true})(BookBase);
const SubBook = sizeMe({monitorHeight:true, monitorWidth:true})(SubBookBase);

export {
    Book,
    SubBook,
    BookHeader,
    PickBookDialog,
    BookPicker,
    BookDialog,
    SubBookDialog,
    deleteBook,
    NewBook,
    RenderBooks,
    BooksHeader,
    LibraryList,
    BooksPicker,
    getBookDescription,
    getChapterInfoFromFragment,
    getFragmentFromChapterSection
}
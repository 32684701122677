const React = require('react');
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { campaign } from '../lib/campaign.js';
import {getBookMapDetals} from './stdedit.jsx';

function RenderHref(props) {
    if (!props.open) {
        return null;
    }


    const {SpellDetails} = require('./renderspell.jsx');
    const {MonsterDetails} = require('./rendermonster.jsx');
    const {ItemDialog} = require('./items.jsx');
    const {ShowClass,ShowSubclass} = require('./renderclass.jsx');
    const {RaceDialog} = require('./renderraces.jsx');
    const {FeatDialog} = require('./renderfeats.jsx');
    const {BackgroundDialog} = require('./renderbackgrounds.jsx');
    const {GenericDialog} = require('./generic.jsx');
    const {CustomDialog} = require('./customtable.jsx');
    const {BookDialog} = require('./book.jsx');
    const {DialogPlayer} = require('./renderplayers.jsx');
    const {EncounterDialog} = require('./encounterview.jsx');
    const {ShardSubscriptionsDialog} = require('./products.jsx');
    const {RenderCharacterFeature} = require('./features.jsx');
    const {AudioDialog} = require('./renderaudio.jsx');

    const page = getLocationInfo(props.href||"");
    var ret = null;

    if (page.page=="conditions") {
        page.id = conditionMap[page.id?.toLowerCase()];
        page.page="customlist";
        page.type="Conditions";
    }

    switch(page.page) {
        case "shardsubscriptions":
            ret= <ShardSubscriptionsDialog open onClose={props.onClose}/>
            break;
        case "charfeature":
            ret= <RenderCharacterFeature open href={props.href} fname={page.fname} fhtml={page.fhtml} usageId={page.id} characterId={page.cid} onClose={props.onClose} doSubRoll={props.doSubRoll} addSpellToken={props.addSpellToken} getDiceRoller={props.getDiceRoller} previousDice={props.previousDice} addToEncounter={props.addToEncounter}/>
            break;
        case "item":
            ret= <ItemDialog open editable={!page.cid} name={page.id} characterId={page.cid} itemId={page.iid} onClose={props.onClose} doSubRoll={props.doSubRoll} addSpellToken={props.addSpellToken} getDiceRoller={props.getDiceRoller} previousDice={props.previousDice} addToEncounter={props.addToEncounter} character={props.character}/>
            break;
        case "monster":
            ret= <MonsterDetails open monster={page.id} onClose={props.onClose} previousDice={props.previousDice} addToEncounter={props.addToEncounter} doSubRoll={props.doSubRoll} getDiceRoller={props.getDiceRoller} addSpellToken={props.addSpellToken} character={props.character}/>
            break;
        case "class":
            if (page.subclass) {
                ret= <ShowSubclass open cclass={page.class} subclass={page.subclass} search={page.search} onClose={props.onClose}/>
            } else {
                ret= <ShowClass open cclass={page.class} subclass={page.subclass} search={page.search} onClose={props.onClose}/>
            }
            break;
        case "spell":
            ret= <SpellDetails open spell={page.id} onClose={props.onClose} doSubRoll={props.doSubRoll} addSpellToken={props.addSpellToken} getDiceRoller={props.getDiceRoller} disableEdit={props.disableEdit}/>
            break;
        case "race":
            ret= <RaceDialog open race={page.id} onClose={props.onClose}/>
            break;
        case "book":
            ret = <BookDialog open onClose={props.onClose} bookname={page.id} chapter={page.chapter} section={page.section} subsection={page.subsection} fragment={page.fragment} addToEncounter={props.addToEncounter} character={props.character}/>;
            break
        case "mycharacters":
            ret = <DialogPlayer show onClose={props.onClose} type="mycharacters" name={page.id}/>;
            break
        case "players":
            ret = <DialogPlayer show onClose={props.onClose} type="players" name={page.id}/>;
            break
        case "plannedencounter":
            ret = <EncounterDialog open onClose={props.onClose} encounter={page.id}/>;
            break

        case "feats":
            ret = <FeatDialog open feat={page.id} onClose={props.onClose}/>;
            break;

        case "audio":
            ret = <AudioDialog open name={page.id} onClose={props.onClose} linkElement={props.linkElement}/>;
            break;

        case "backgrounds":
            ret = <BackgroundDialog open background={page.id} onClose={props.onClose}/>;
            break;

        case "conditions":
        case "skills":
        case "actions":
        case "senses":
        case "coins":
                ret = <GenericDialog open type={page.page} id={page.id} onClose={props.onClose} doSubRoll={props.doSubRoll} previousDice={props.previousDice} addToEncounter={props.addToEncounter} character={props.character} getDiceRoller={props.getDiceRoller}/>;
            break;

        case "customlist":
            ret = <CustomDialog open type={page.type} id={page.id} editable onClose={props.onClose} previousDice={props.previousDice} addToEncounter={props.addToEncounter} doSubRoll={props.doSubRoll} addSpellToken={props.addSpellToken} getDiceRoller={props.getDiceRoller}/>;
            break;
        default:
            console.log("render unknown href", page.page, page.id);
            break;
    }
    return ret;
}

const conditionMap = {
    "blinded": "hm9mtg9ffw5qp78o",
    "charmed": "2cniuqz4ovwf1044",
    "deafened": "5051czf7e5akoog5",
    "exhaustion":"cvgmhh2jwzl4j1wv",
    "frightened": "s2w2ccxvohsc5plb",
    "grappled": "a6e4lwi1jotp3mq5",
    "incapacitated": "jhsnov3jlzaa62ub",
    "invisible": "46cvqrzwgk1fwl6f",
    "paralyzed": "kggadddfcqwjvbmg",
    "petrified": "q60jrolyn4btr38i",
    "poisoned": "azq2e3z55z2fgs2q",
    "prone": "0jn05cqnggx41gu9",
    "restrained": "pzyh8kckn2x92l3p",
    "stunned": "8mrl00ipelcjvww7",
    "unconscious": "z79ymun0kjbrrd2r",
}

function getSpellFromLink(href) {
    if (!href) {
        return null;
    }
    const page=getLocationInfo(href);
    if (page.page=="spell") {
        return page?.id?.toLowerCase()||null;
    }
    return null;
}

class LinkHref extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        let href = this.props.href||"";

        if (href[href.length-1] == '/'){
            href = href.substr(0,href.length-1);
        } else if (href.startsWith("/#")) {
            href = href.substr(1);
        }

        const localRef = href.startsWith("#");

        return <span onClick={eatClicks} >
            <a className={this.props.className} href={this.props.href} onClick={(localRef)?this.onAnchorClick.bind(this):null}>{this.props.children}</a>
            {localRef?<RenderHref open={this.state.showDialogHref} href={href} onClose={this.closeHref.bind(this)}  addToEncounter={this.props.addToEncounter} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} character={this.props.character} disableEdit/>:null}
        </span>;
    }
    
    onAnchorClick(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({showDialogHref:true});
    }

    closeHref(evt) {
        const {onClose} = this.props;
        onClose && onClose();
        this.setState({showDialogHref:false});
    }

}

class AddChatEntry extends React.Component {
    constructor(props) {
        super(props);

        const {href}=props;
        let bmdVal;

        if (href) {
            const bmd = getBookMapDetals();
            bmdVal=bmd[stringFromHref(href)];
        }

        this.state= {bmdVal};
    }

	render() {
        if (campaign.getWatchKey()) {
            return null;
        }
        const {bmdVal,bval,showBooks,anchorEl} = this.state;
        const BookDialog=bval?require('./book.jsx').BookDialog:null;
        const list=[];

        if (showBooks) {
            let last;
            for (let i in bmdVal) {
                const b = bmdVal[i];
                let display = b.displayName;
                if (last == b.name) {
                    const bookInfo = campaign.getBookInfo(b.name);
                    if (bookInfo) {
                        const chapter = bookInfo.chapters[b.chapter];
                        if (chapter?.name) {
                            display +=": "+chapter.name;
                        }
                        if (b.section>=0) {
                            const section = chapter.sections[b.section];
                            if (section?.name) {
                                display +=": "+section.name;
                            }
                            if (b.subsection >=0) {
                                const subsection = section.subsections[b.subsection];
                                if (subsection?.name) {
                                    display +=": "+subsection?.name;
                                }
                            }
                        }
                    }
                }
                list.push(<MenuItem key={i} onClick={this.showBook.bind(this, b)}>{display}</MenuItem>);
                last = b.name;
            }
        }

        return <span>
            <Tooltip title="add to chat"><span onClick={this.onClick.bind(this)} className="f2 br2 titlecolor hoverhighlight far fa-comment-alt pa1"/></Tooltip>
            {bmdVal?<Tooltip title="book references"><span onClick={this.onClickBook.bind(this)} className="f2 br2 titlecolor hoverhighlight fas fa-book pa1"/></Tooltip>:null}
            {showBooks?<Menu 
                open 
                anchorEl={anchorEl} 
                onClose={this.showBook.bind(this,null)}
            >
                {list}
            </Menu>:null}
            {bval?<BookDialog open bookname={bval.name} chapter={bval.chapter} section={bval.section} subsection={bval.subsection} onClose={this.showBook.bind(this,null)}/>:null}
        </span>;
    }

    onClickBook(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const {bmdVal} = this.state;
        this.setState({showBooks:true, anchorEl:evt.target});
    }

    showBook(bval) {
        this.setState({showBooks:false, bval});
    }
    
    onClick(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        const {Chat} = require('../lib/chat.js');
        const {character, type, displayName, href, spellId}=this.props;
        Chat.addEntryRef(character, type, displayName, href, spellId);
    }
}

function stringFromHref(href) {
    const page = getLocationInfo(href||"");
    var ret = null;

    if (page.page=="conditions") {
        page.id = conditionMap[page.id?.toLowerCase()];
        page.page="customlist";
        page.type="Conditions";
    }

    switch(page.page) {
        case "item":
        case "monster":
        case "spell":
        case "race":
        case "backgrounds":
        case "feats":
        case "audio":
        case "book":
        case "mycharacters":
        case "players":
            return (page.id||"").toLowerCase();
            break;
        case "class":
            ret= (page.class?"c:"+page.class.toLowerCase():"")+
                 (page.subclass?"sc:"+page.subclass.toLowerCase():"");
            break;
        case "customlist":
            ret= (page.id?"i:"+page.id.toLowerCase():"");
            break;
        default:
            break;
    }
    return ret;
}


function eatClicks(evt) {
    evt.stopPropagation();
}

function getLocationInfo(hash) {
    if (hash.endsWith("/")) {
        hash = hash.substr(0,hash.length-1);
    }
    let split = hash.split('?');
    let result = {hash:hash};
    
    if (split.length == 0) return result;

    result.page = split[0].substr(1);
    if (split.length == 2) {
        var params = split[1].split('&');
        var i;

        for (i in params) {
            var values=params[i].split('=');

            if (values.length > 2){
                console.log("bad value too many '=' "+params[i]);
            } else {
                if (values.length==2){
                    result[values[0]] = decodeURIComponent(values[1]);
                } else {
                    result[values[0]] = true;
                }
            }
        }
    } if (split.length > 2) {
        console.log("too many '?' in url "+hash);
    }
    return result;
}

function getBasicHref(type, id) {
    return "#"+type+"?id="+encodeURIComponent(id);
}

function getItemHref(id, cid, iid) {
    if (id) {
        return "#item?id="+encodeURIComponent(id);
    }
    return "#item?iid="+encodeURIComponent(iid)+"&cid="+encodeURIComponent(cid)
}

function getTypeHref(type, id, idVal) {
    return "#"+type+"?"+(idVal||"id")+"="+encodeURIComponent(id);
}

function getClassHref(cclass, subclass) {
    return "#class?class="+encodeURIComponent(cclass)+(subclass?("&subclass="+encodeURIComponent(subclass)):"");
}

function getCustomHref(type, id) {
    return "#customlist?type="+encodeURIComponent(type)+"&id="+encodeURIComponent(id);
}

function getFirstBook(href) {
    const bmd = getBookMapDetals();
    const bmdVal=bmd[stringFromHref(href)];
    if (bmdVal && bmdVal.length) {
        return bmdVal[0].displayName;
    }
    return null;
}

export {
    RenderHref,
    LinkHref,
    getLocationInfo,
    getBasicHref,
    getTypeHref,
    getClassHref,
    getCustomHref,
    getItemHref,
    getSpellFromLink,
    AddChatEntry,
    getFirstBook
}
const React = require('react');
const {campaign} = require('../lib/campaign.js');
const {displayMessage} = require('./notification.jsx');
const Parser = require("../lib/dutils.js").Parser;
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {EntityEditor,Renderstring,Renderentry} = require('./entityeditor.jsx');
const { NumberAdjust, AskYesNo, PickVal, MaxNumberAdjust, SelectVal, alwaysArray} = require('./stdedit.jsx');
const {Character, getFeaturesTable,getMergedCustomLevels, itemMatchFilter,nameFromFeatureParams, getTextFromDistanceStruct, isCarried} = require('../lib/character.js');
const {RenderFeature,hasFeatureConfig, FeatureConfigure,ConfigureAlert,ActionConfig} = require('./features.jsx');
const {SpellPicker,getSpellAttributes} = require('./renderspell.jsx');
const { ItemCreatePicker} = require('./items.jsx');
const {MonsterPicker, simplifyMonster,matchRestriction} = require("./rendermonster.jsx");
const {LinkHref}=require('./renderhref.jsx');
const {getDiceFromString, doRoll} = require('./diceroller.jsx');
const {Chat} = require('../lib/chat.js');
const {ChatButton} = require('./renderchat.jsx');
const {PickCondition,getDurationFromText,Condition,getConditionStruc,addCondition,addConditionInfoToConditions} = require('./conditions.jsx');
const {AddObject} = require('./objects.jsx');

const {
    pluralString,
    actionTypeMap,
    upperActionTypeMap,
    zeroTo20,
} = require('../lib/stdvalues.js');

const selectTypes = {
    "freeform":"Freeform",
    "points":"Point Buy",
    "random":"Roll Scores",
    "fixed":"Standard Array",
}
const rollValues=["-",18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3];
const abilityList = ["str", "int", "dex", "wis", "con", "cha"];

class UsageSlots extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

    render() {
        const props = this.props;
        const {numOnly,useNumbers} = props;
        const character=props.character;
        const usage=props.usage;
        if (!usage) return null;
        const level = props.level;
        let max=0;
        let displayLevels;
        let saveDC;

        if (!props.noDetails) {
            if (usage.displayLevels) {
                let ds = usage.displaySuffix;
                let ps = usage.displayPrefix;
                let lv = usage.valueName?character.namedValues[usage.valueName.trim().toLowerCase()]:usage.displayLevels[level||1];
                let roll=false;

                if (lv) {
                    if (ds && ds.startsWith("d")) {
                        const pos = ds.indexOf(" ");
                        if (pos >0) {
                            lv = lv+ds.substr(0,pos);
                            ds = ds.substr(pos);
                        } else {
                            lv = lv+ds;
                            ds=null;
                        }
                        roll=true;
                    } else if (ps && ps.match(/^(\d*d\d*\+?)$/i)) {
                        lv = ps+lv;
                        ps=null;
                        roll=true;
                    }

                    if (roll) {
                        displayLevels = <span> {useNumbers?null:"("}{ps}<b className="dodieroll" onClick={this.doRoll.bind(this, lv)}>{lv}</b>{ds}{useNumbers?null:")"}</span>;
                    } else {
                        displayLevels = <span> {useNumbers?null:"("}{ps}{lv}{ds}{useNumbers?null:")"}</span>;
                    }
                }
            } else if (usage.displaySuffix) {
                displayLevels = <span> {usage.displaySuffix}</span>;
            }

            if (usage.saveDC) {
                let ability;
                const saveDCL = usage.saveDC;
                if (Array.isArray(saveDCL)) {
                    for (let i in saveDCL) {
                        const s = saveDCL[i];
                        if (!ability) {
                            ability = s;
                        } else if (character.getAbility(s).modifier > character.getAbility(ability).modifier) {
                            ability = s;
                        }
                    }
                } else {
                    ability=saveDCL;
                }
        
                saveDC = <span> {ability.toUpperCase()} DC{character.getAbility(ability).modifier+8+character.proficiency}</span>;
            }
        }

        const inside = <span>
            {displayLevels}
            {saveDC}
            {props.children}        
        </span>;

        max = character.getUsageMax(usage,level, null, !this.props.showAlways);
        if (!max || props.noCounts) {
            return inside;
        }
        if (numOnly) {
            return <span>{inside} {max}</span>
        }
        let value = character.getUsageVal(usage, props.id);

        return <MaxNumberAdjust max={max} value={value} inverse={this.props.inverse} useNumbers={useNumbers} readonly={this.props.readonly} onAdjustValue={this.setValue.bind(this)}>
            {inside}
        </MaxNumberAdjust>
    }

    doRoll(text) {
        this.props.doRoll(text);
    }

    setValue(value) {
        const {usage, character, id}= this.props;

        character.setUsageVal(usage, id, value);
    }

}

class MonsterPickChange extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        const {open}=this.props;

        if (open && (open != prevProps.open)) {
            const options = this.getOptions();

            this.setState({options, showPickMenu:!!options, showPickMonster:!options});
        }
    }

    getOptions() {
        const {restriction,selected,history}=this.props;
        const options=[];

        for (let m in selected) {
            const mon = campaign.getMonster(m);
            if (mon && (!restriction || matchRestriction(restriction, mon))) {
                options.push(mon);
            }
        }

        for (let i in history) {
            const m = history[i];
            if (!selected || !selected[m.toLowerCase()]) {
                const mon = campaign.getMonster(m);
                if (mon && (!restriction || matchRestriction(restriction, mon))) {
                    options.push(mon);
                }
            }
        }
        return (options.length)?options:null;
    }

    render() {
        const {restriction,lastSelected,open}=this.props;
        if (!open) {
            return null;
        }

        const pickMenu = [];
        const {showPickMenu,showPickMonster,options}=this.state;

        if (showPickMenu) {
            pickMenu.push(<MenuItem key="pick" className="pv2" onClick={this.showMonsterPicker.bind(this)}>Pick...</MenuItem>);
            for (let i in options) {
                const mon = options[i];

                if (mon) {
                    const cr = mon.cr;
                    const swim = (mon.speed||{}).swim;
                    const fly = (mon.speed||{}).fly;

                    pickMenu.push(<MenuItem key={i} className="pv2" onClick={this.pickMonster.bind(this, mon.name)}>{mon.displayName} (CR {cr}) {swim?"swimming ":""}{fly?"flying":""}</MenuItem>);
                }
            }
        }

        return <span onClick={eatEvents} onKeyDown={eatEvents} onKeyUp={eatEvents} onKeyPress={eatEvents}>
            <MonsterPicker open={showPickMonster} scrollToSelected restriction={restriction} selected={lastSelected} onClose={this.pickMonster.bind(this)}/>

            <Menu
                anchorEl={this.props.anchorEl}
                open={this.state.showPickMenu||false}
                onClose={this.pickMonster.bind(this,null)}
            >
                {pickMenu}
            </Menu>
        </span>;
    }
    

    showMonsterPicker(event) {
        this.setState({showPickMonster:true, showPickMenu:false});
    }

    pickMonster(selected) {
        this.props.onClose(selected);
        this.setState({showPickMonster:false, showPickMenu:false});
    }
}

const abilityScorePoints = {
    8:0,
    9:1,
    10:2,
    11:3,
    12:4,
    13:5,
    14:7,
    15:9,
    16:11,
    17:13,
    18:16
}

function getDamagesInfo(damages) {
    let damageTypes=null, dmgList=[];
    for (let i in damages) {
        const d = damages[i];
        if (d) {
            if (d.dmgType) {
                damageTypes = (damageTypes?(damageTypes+", "):"")+d.dmgType;
            }
            dmgList.push(<div key={i}>{d.dmg}</div>);
        }
    }
    return {damageTypes, dmgList};
}

function eatEvents(e) {
    e.stopPropagation();
}

function calcMonRestriction(restriction, level) {
    if (!restriction) {
        return null;
    }
    let ret = restriction[0];
    for (let i=1; i<restriction.length; i++) {
        const r = restriction[i];
        if (level >= r.level) {
            ret = r;
        }
    }
    return ret;
}

const actionPickVals={
    all:"No Filter",
    A:"Actions",
    ATK:"Attack",
    BA:"Bonus Actions",
    RA:"Reactions",
    FA:"Free Actions",
    LR:"Long Rest",
    SR:"Short Rest"
}

class ActionBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state= {};
    }

    render() {
        const {readonly,character,excludeActions,small,demoMode} = this.props;
        const {showCreatePicker, showActivateAction, createItemInfo, activateUsageId, activateAction,showSelectMonster, selectHistory, selectMonsterRestriction,lastSelected,actionFilter,anchorEl} = this.state;
        const useGroups={}, counts={"spell slots":2, "hit dice":2};
        let ret = [], cret=[], sret=[], eret=[];
        let hasrange, haseffect, hasattack,hasaction=!!actionFilter;

        const {actions, elist, clist, slist} = character.getActions();
        if (demoMode) {
            if (!actions.length) {
                actions.push(demoAction)
            }
            if (!elist.length) {
                elist.push(demoEffect)
            }
        }
        actions.sort(function (a,b) {return (a.featureName?.localeCompare(b.featureName))});
        elist.sort(function (a,b) {return (a.params.feature.effects.name||"").localeCompare(b.params.feature.effects.name||"")});
        slist.sort(function (a,b) {return (a.spell.displayName||"").localeCompare(b.spell.displayName||"")});
        clist.sort(function (a,b) {return (a.name||"").localeCompare(b.name||"")});

        for (let a of actions) {
            const action = a.params?.feature?.action;
            if (action) {
                hasattack = hasattack|| !!(action.attackRoll || action.save)
                hasrange = hasrange || !!action.range;
                haseffect = haseffect || !!action.dice || !!action.temphp;
                hasaction = hasaction || !!action.usageType || !!action.activationType;

                if (a.useGroupName) {
                    counts[a.useGroupName.toLowerCase()] = (counts[a.useGroupName.toLowerCase()]||0)+1;
                }
            }
            const createitem = a.params?.feature?.createitem;
            if (createitem) {
                hasaction = hasaction || !!createitem.usageType;
                if (a.useGroupName) {
                    counts[a.useGroupName.toLowerCase()] = (counts[a.useGroupName.toLowerCase()]||0)+1;
                }
            }
        }

        for (let s of slist) {
            const spell = s.spell;
            hasattack = hasattack|| !!(spell.attackRoll || spell.saveAbility)
            hasrange = hasrange || !!spell.range;
            haseffect = haseffect || !!(spell.damages||spell.altdamages);
            hasaction = hasaction || !!actionTypeMap[spell.actionType];
        }

        for (let i in actions) {
            let {params, featureName, href, type, enableAction, useGroupName, active, damages, temphp, attackRoll, duration,foundTypes} = actions[i];
            if (!excludeActions || !excludeActions.includes(type)) {
                const {feature, level,usageId} = params;
                let useGroup = useGroupName?.toLowerCase();

                switch (type) {
                    case "action":{
                        const {action,usage}=feature;
                        const extraNotesList = [];
                        let usageSlots;
                        let attackCol, effectCol, preNotes;
                        let showRevertShape;
                        let actionTypeVal = action.usageType;
                        let actionType = actionTypeMap[actionTypeVal]||null;
                        let clickAction = enableAction?this.clickAction.bind(this, params, href, actionTypeVal,null):null;
                        let actionButton="use";

                        if (usage && ((action.consumeUsage && ((action.consumeType||"uses")=="uses") || (action.actionOperation=="convert")))) {
                            const max = character.getUsageMax(usage,level-1)||0;
                            if (max) {
                                usageSlots = <UsageSlots
                                    character={character} 
                                    readonly={readonly}
                                    usage={usage} 
                                    level={level-1}
                                    noDetails
                                    showAlways
                                    inverse={feature.name=="Luck"}
                                    id={usageId}
                                />;
                            }
                        }

                        let nodiv=true;
                        if (action.extraNotes) {
                            extraNotesList.push(<Renderentry key="e" nodiv entry={action.extraNotes} doRoll={this.doTextRoll.bind(this,featureName)} doSubRoll={this.doTextRoll.bind(this)} character={character} diceMap={character?.namedValues}/>);
                            nodiv=false;
                        }
                        if (duration) {
                            if (!isNaN(duration)) {
                                duration=duration+" "+pluralString("round",duration);
                            }
                        }

                        switch (action.actionOperation) {
                            default:
                            case "attack": {
                                const {damageTypes, dmgList} = getDamagesInfo(damages);
                                attackCol=<td className="tc vat">
                                    <div className="hoverroll b" onClick={attackRoll?this.doUrlAttack.bind(this, href, featureName, attackRoll, damages,character.attackDice,null):null}>{attackRoll}</div>
                                    {action.save?<div><span className="f7 ttu">{alwaysArray(action.save).join("/")}</span>&nbsp;{character.getSaveDCVal(action.saveDC)}</div>:null}
                                </td>;
                                effectCol = <td className="tc vat ">
                                    <div className="hoverroll b" onClick={this.doUrlDamage.bind(this,href, featureName, damages,null,null)}>{dmgList}</div>
                                    {temphp?<div className="hoverroll" onClick={this.doUrlTempHP.bind(this,href, featureName, temphp, character.replaceMetawords(action.temphp.duration))}><b>{temphp}</b>&nbsp;<span className="f7">Temp&nbsp;HP</span></div>:null}
                                </td>
                                preNotes=damageTypes?<i>{damageTypes}</i>:null;
                                active=false;
                                break;
                            }
                            case "activate":{
                                if (active) {
                                    clickAction = this.clickDeactivate.bind(this, params);
                                    actionButton="end";
                                    actionType=null;
                                    if (foundTypes) {
                                        let addC;
                                        for (let c of foundTypes) {
                                            if (addC) {
                                                extraNotesList.push(", ")
                                            }
                                            extraNotesList.push(<LinkHref key={c.name} href={`#customlist?type=${encodeURIComponent(c.type)}&id=${c.name}`}>{c.displayName}</LinkHref>);
                                            addC=true;
                                        }
                                    }
                                }
                                break;
                            }
                            case "summon": {
                                if (active) {
                                    clickAction = this.clickDeactivate.bind(this, params);
                                    actionButton="end";
                                    actionType=null;
                                }
                                break;
                            }
                            case "shape": {
                                if (character.shape) {
                                    showRevertShape=true;
                                    clickAction = this.revertShape.bind(this)
                                    actionButton="end";
                                    actionType=null;
                                }
                                break;
                            }
                            case "convert": {
                                active=false;
                                clickAction = this.getPickConvertMenuItems(params).length?this.clickShowConvertOptions.bind(this,params):null;
                                break;
                            }
                        }

                        if ((!actionFilter || (actionType==actionFilter)) && (!excludeActions || !excludeActions.includes(action.actionOperation))) {
                            if (useGroup && ((counts[useGroup]||0)>1)) {
                                if (!useGroups[useGroup]){
                                    useGroups[useGroup] = {name:useGroupName, usage:usageSlots||null, list:[]};
                                }
                                if (!useGroups[useGroup].usage) {
                                    useGroups[useGroup].usage=usageSlots;
                                }
                                usageSlots=null;
                            } else {
                                useGroup = null;
                            }
                            const extra = <span>{usageSlots} {preNotes} {preNotes&&extraNotesList.length?"|":null} {extraNotesList}</span>

                            const row = <tr key={usageId} >
                                <td className={(clickAction?"hoverhighlight":"fdarkgray")+" tc mw3"} onClick={clickAction}>
                                    {actionButton?<span className={clickAction?"f8 ba titleborder br1 ph--2":"f8"}>{actionButton}</span>:null}
                                </td>
                                <td>
                                    {active?<span className="red fas fa-bolt"/>:showRevertShape?<span className="titlecolor fas fa-cat mr--2"/>:null}
                                    <LinkHref href={href} doSubRoll={this.doTextRoll.bind(this)} getDiceRoller={this.props.readonly?null:this.getDiceRoller.bind(this)} character={character}>{featureName}
                                    </LinkHref> {duration?<PickVal value={duration} values={zeroTo20} onClick={this.onSetDuration.bind(this,usageId)}><span className="inversespecial nowrap">{duration}</span></PickVal>:null}
                                    {small?<div className="f8">{extra}</div>:null}
                                </td>
                                {hasaction?(actionType?<td className="tc" onClick={clickAction}><div><span className={clickAction?"ba titleborder br1 ph1 hoverhighlight":"fdarkgray"}>{actionType}</span></div></td>:<td/>):null}
                                {hasrange?<td className="tc">{action.range}</td>:null}
                                {hasattack?(attackCol||<td/>):null}
                                {haseffect?(effectCol||<td/>):null}
                                {small?null:<td className="vat">
                                    {extra}
                                </td>}
                            </tr>;

                            if (useGroup) {
                                useGroups[useGroup].list.push(row);
                            } else {
                                ret.push(row);
                            }
                        }
                        break;
                    }
                    case "createitem":{
                        const {createitem,usage}=feature;
                        let usageSlots;
                        let actionTypeVal = createitem?.usageType;
                        let actionType = actionTypeMap[actionTypeVal]||null;
                        let clickAction = enableAction?(active?this.onRemoveCreateItem.bind(this, usageId):this.clickCreateItem.bind(this, params)):null;
                        let actionButton=active?"end":"use";

                        if (usage && (createitem?.consumeUsage && (createitem?.consumeType||"uses")=="uses")) {
                            const max = character.getUsageMax(usage,level-1)||0;
                            if (max) {
                                usageSlots = <UsageSlots
                                    character={character} 
                                    readonly={readonly}
                                    usage={usage} 
                                    level={level-1}
                                    noDetails
                                    showAlways
                                    id={usageId}
                                />;
                            }
                        }

                        if (duration) {
                            if (!isNaN(duration)) {
                                duration=duration+" "+pluralString("round",duration);
                            }
                        }

                        if (!actionFilter || (actionType==actionFilter)) {
                            if (useGroup && ((counts[useGroup]||0)>1)) {
                                if (!useGroups[useGroup]){
                                    useGroups[useGroup] = {name:useGroupName, usage:usageSlots||null, list:[]};
                                }
                                if (!useGroups[useGroup].usage) {
                                    useGroups[useGroup].usage=usageSlots;
                                }
                                usageSlots=null;
                            } else {
                                useGroup = null;
                            }
                            const extra = <span>{usageSlots} {createitem.extraNotes?<Renderentry nodiv entry={createitem.extraNotes} doRoll={this.doTextRoll.bind(this,featureName)} doSubRoll={this.doTextRoll.bind(this)} character={character}  diceMap={character?.namedValues}/>:null}</span>

                            const row = <tr key={"ci"+usageId} >
                                <td className={(clickAction?"hoverhighlight":"fdarkgray")+" tc mw3"} onClick={clickAction}>
                                    {actionButton?<span className={clickAction?"f7 ba titleborder br1 ph--2":"f7"}>{actionButton}</span>:null}
                                </td>
                                <td>
                                    {active?<span className="red fas fa-bolt"/>:null}
                                    <LinkHref href={href} doSubRoll={this.doTextRoll.bind(this)} getDiceRoller={this.props.readonly?null:this.getDiceRoller.bind(this)} character={character}>
                                        {featureName}
                                    </LinkHref> {duration?<span className="inversespecial nowrap">{duration}</span>:null}
                                    {small?<div className="f8">{extra}</div>:null}
                                </td>
                                {hasaction?(actionType?<td className="tc" onClick={clickAction}><div><span className={clickAction?"ba titleborder br1 ph1 hoverhighlight":"fdarkgray"}>{actionType}</span></div></td>:<td/>):null}
                                {hasrange?<td/>:null}
                                {hasattack?<td/>:null}
                                {haseffect?<td/>:null}
                                {small?null:<td className="vat">
                                    {extra}
                                </td>}
                            </tr>;
                            if (useGroup) {
                                useGroups[useGroup].list.push(row);
                            } else {
                                ret.push(row);
                            }
                        }                    
                        break;
                    }
                }
            }
        }

        for (let i in useGroups) {
            const ug = useGroups[i];
            
            if (ret.length %2 == 0) {
                ret.push(<tr key={"sp."+i}/>);
            }
            ret.push(<tr key={"ug."+i}>
                <td colSpan="7"><b>{ug.name}</b> {ug.usage}</td>
            </tr>);
            ret=ret.concat(ug.list);
        }

        const addSpellToken=readonly?null:this.props.addSpellToken;
        for (let i in slist) {
            const {spell, condition, href, name} = slist[i];
            const actionType =  actionTypeMap[spell.actionType]||null;
            const dInfo = getDamagesInfo(spell.damages);
            const adInfo = getDamagesInfo(spell.altdamages);
            const clickSpell = this.clickSpell.bind(this, spell, href, spell.damages, spell.altdamages, spell.conditions);
            let sa = {};
            if (addSpellToken) {
                const spellInfo = campaign.getSpell(spell.spellId);
                if (spellInfo) {
                    sa = getSpellAttributes(spellInfo);
                }
            }

            if (!actionFilter || (actionType==actionFilter)) {
                const extra = <span>
                    {(this.props.addSpellToken)?<span className="titlecolor" key="aoe"> <AddObject noSize color="primary" variant="text" defaultName={spell.displayName} defaultShape={sa.shape} defaultLength={sa.length} character={character} spell={spell.spellId} onAddObject={addSpellToken} useIcon defaultType="Spell Token" playerControlled/></span>:null}
                    <i>{dInfo.damageTypes}{adInfo.damageTypes?("; "+adInfo.damageTypes):null}</i> {dInfo.damageTypes && spell.extraNotes?"|":null}
                    {spell.extraNotes?<Renderentry nodiv entry={{entries:spell.extraNotes.entries}} doRoll={this.doTextRoll.bind(this,spell.displayName)} doSubRoll={this.doTextRoll.bind(this)} character={character} diceMap={character?.namedValues}/>:null}
                </span>;

                sret.push(<tr key={"cond."+name} >
                    <td className="tc mw3 hoverhighlight" onClick={this.onChangeCondition.bind(this, name,null)}>
                        <span className="f7 ba titleborder br1 ph--2 hoverhighlight">end</span>
                    </td>
                    <td className="vat">
                        <Condition name={name} condition={condition} separateName character={character} onChange={this.onChangeCondition.bind(this, name)}/>
                        {spell.concentration?<span className="inversespecial ml1 f7 b">C</span>:null}
                        {small?<div className="f8">{extra}</div>:null}
                    </td>
                    {hasaction?(actionType?<td className="tc" onClick={clickSpell}><div><span className="ba titleborder br1 ph1 hoverhighlight">{actionType}</span></div></td>:<td/>):null}
                    {hasrange?<td className="tc vat">{spell.range}</td>:null}
                    {hasattack?<td className="tc vat">
                        <div className="hoverroll b" onClick={clickSpell}>{spell.attackRoll}</div>
                        {spell.saveAbility?<div><span className="f7 ttu">{spell.saveAbility}</span>&nbsp;{spell.saveVal}</div>:null}
                    </td>:null}
                    {haseffect?<td className="tc vat ">
                        {dInfo.dmgList.length?<div className="hoverroll b" onClick={this.doUrlDamage.bind(this,href, spell.spellName, spell.damages,spell.saveAbility,spell.saveVal)}>{dInfo.dmgList}</div>:null}
                        {adInfo.dmgList.length?<div className="hoverroll b" onClick={this.doUrlDamage.bind(this,href, spell.spellName, spell.altdamages,spell.saveAbility,spell.saveVal)}>{adInfo.dmgList}</div>:null}
                    </td>:null}
                    {small?null:<td className="vat">
                        {extra}
                    </td>}
                </tr>);
            }
        }

        for (let i in clist) {
            const {condition, name} = clist[i];
            const c=condition;
            const extra = <span>{c.level?<span>Level<MaxNumberAdjust max={c.maxLevel||9} useNumbers value={c.level} readonly={this.props.readonly} onAdjustValue={this.onChangeConditionVal.bind(this, name, "level")}/></span>:null}</span>

            cret.push(<tr key={"cond."+name} >
                <td className="tc mw3 hoverhighlight" onClick={this.onChangeCondition.bind(this, name,null)}>
                    <span className="f7 ba titleborder br1 ph--2 hoverhighlight">end</span>
                </td>
                <td className="vat">
                    <Condition name={name} condition={condition} separateName character={character} onChange={this.onChangeCondition.bind(this, name)}/>
                    {small?<div className="f8">{extra}</div>:null}
                </td>
                {hasaction?<td/>:null}
                {hasrange?<td className="tc vat"/>:null}
                {hasattack?<td className="tc vat"/>:null}
                {haseffect?<td/>:null}
                {small?null:<td className="vat">
                    {extra}
                </td>}
            </tr>);
        }

        if (sret.length) {
            if (ret.lengh %2 == 0) {
                ret.push(<tr key={"spellsp"}/>);
            }
            ret.push(<tr key={"spells."}>
                <td colSpan="7" id={this.idBase+"spellcondition"}><b>{character.t("Spells")}</b></td>
            </tr>);
            ret = ret.concat(sret);
        }

        if (cret.length) {
            if (ret.lengh %2 == 0) {
                ret.push(<tr key={"conditionsp"}/>);
            }
            ret.push(<tr key={"conditions."}>
                <td colSpan="7"><b>Conditions</b></td>
            </tr>);
            ret = ret.concat(cret);
        }

        for (let i in elist) {
            const {params,href, active} = elist[i];
            const {feature, fid} = params;
            const {effects}=feature;
            const featureName = effects.name||"";

            eret.push(<div key={fid} className="ml1">
                <span className={(active?"far fa-check-square hoverhighlight":"far fa-square hoverhighlight")+" f4 pa1"} onClick={this.clickActivateEffect.bind(this,params, !active)}/>
                <LinkHref href={href} doSubRoll={this.doTextRoll.bind(this)} getDiceRoller={this.props.readonly?null:this.getDiceRoller.bind(this)} character={character}>{featureName}</LinkHref>
            </div>);
        }

        return <div className="weaponsBlock avoidBreak tourCharacterActionBlock" id={this.idBase+"actionblock"}>
            <div>
                <div className="overflow-x-auto stdtight">
                    {ret.length||actionFilter?<table className="w-100 stdcontent">
                        <tbody>
                            <tr>
                                <td className="tc f7 w3 b"/>
                                <td></td>
                                {hasaction?<td className="b tc w3 f7">
                                    <PickVal onClick={this.onSetActionFilter.bind(this)} values={actionPickVals}>
                                        <a>action{actionFilter?"*":null}</a>
                                    </PickVal>
                                </td>:null}
                                {hasrange?<td className="b tc w3 f7">range</td>:null}
                                {hasattack?<td className="b tc w3 f7">hit/dc</td>:null}
                                {haseffect?<td className="b tc w3 f7">effect</td>:null}
                                {small?null:<td className="b tl f7">notes</td>}
                            </tr>
                            {ret}
                        </tbody>
                    </table>:null}
                    {eret.length?<div>
                        <div className="b f4">Toggle Effects</div>
                        <div className="flex flex-wrap">
                            {eret}
                        </div>
                    </div>:null}
                </div>
                {readonly?null:<div className="flex flex-wrap items-center">
                    <span className="flex-auto ttitle"></span>
                    <div><PickCondition useButton showAdd onAddCondition={this.closeAddCondition.bind(this)}/></div>
                    <span className="flex-auto ttitle"></span>
                </div>}
            </div>
            <ActionConfig open={showActivateAction} character={character} usageId={activateUsageId} action={activateAction} onClose={this.hideActivateAction.bind(this)}/>
            <MonsterPickChange open={showSelectMonster} restriction={selectMonsterRestriction} history={selectHistory} selected={lastSelected} onClose={this.selectMonster.bind(this)} anchorEl={anchorEl}/>
            {this.getUseCountMenu()}
            {this.getPickConvertMenu()}
            {this.getCreateItemMenu()}
            {showCreatePicker?<ItemCreatePicker open filter={createItemInfo} equipment={character.equipment} onClose={this.onClosePickItemCreate.bind(this)}/>:null}
        </div>;
    }

    getDiceRoller() {
        if (this.props.getDiceRoller) {
            return this.props.getDiceRoller();
        }
        return <ChatButton character={this.props.character}/>;
    }

    changeAttribute(attribute, value){
        this.props.character.setProperty(attribute, value);
    }

    doUrlAttack(href, displayName, bonus, damages, d20Bonus, otherDamages){
        const character = this.props.character;
        const dice = getDiceFromString("1d20"+(bonus?"+"+bonus:""),0,false);
        const {rolls} = doRoll(dice);
        const roll = {dice, rolls, source:displayName, sourceHref:href||null, action:dice?"to hit":null, damages:damages||null};
        if (otherDamages) {
            roll.otherDamages=otherDamages;
        }
        if (d20Bonus) {
            Chat.addD20BonusRolls(character, roll, d20Bonus);
        }

        if (character.isMon) {
            roll.sourceHref = roll.sourceHref;
            this.props.doSubRoll(roll);
        } else {
            character.addRoll(roll);
        }
    }

    doUrlDamage(href, displayName, damages,saveAbility, saveVal) {
        const {character,baseCharacter} = this.props;
        if (damages.length) {
            if (character.isMon) {
                Chat.addMonsterDamageRoll(character, character.crow, baseCharacter, displayName, href, damages, null, saveAbility, saveVal);
            } else {
                Chat.addCharacterDamageRoll(character, displayName, href||null, damages, null, null,saveAbility, saveVal);
            }
        }
    }

    doUrlTempHP(href, displayName, dice, duration) {
        const {character,baseCharacter} = this.props;
        if (dice) {
            if (character.isMon) {
                Chat.addMonsterTempHPRoll(character.state, character.crow,baseCharacter, displayName, href, dice, duration);
            } else {
                Chat.addCharacterTempHPRoll(character, displayName, href||null, dice, duration);
            }
        }
    }

    doTextRoll(name, text){
        const {character} = this.props;
        if (character.isMon) {
            return this.props.doSubRoll(name, text);
        }
        if (name && (typeof name == "object")) {
            // if name is an object then it actually contains the roll
            Chat.addCharacterRoll(character, name);
            return name;
        }
        const dice = getDiceFromString(text);

        return character.doRoll(dice, name,null);
    }

    setUsageValue(prop, value) {
        this.props.character.setFeatureUsage(prop, value);
    }

    addSpellToken(ao) {
        this.props.addSpellToken(ao);
    }

    onSetActionFilter(actionFilter) {
        if (actionFilter == "all") {
            actionFilter=null;
        }
        this.setState({actionFilter});
    }

    clickCreateItem(params,evt) {
        if (params) {
            const {feature} = params;
            const {createitem, altcreateitem, alt2createitem}=feature;
            if (createitem&&!altcreateitem&&!alt2createitem) {
                this.onCreateItem(params, 0);
                return;
            } else if (!createitem) {
                return;
            }
        }
        this.setState({showCreateItems:params, anchorEl:evt?.currentTarget});
    }

    getCreateItemMenu() {
        const {showCreateItems, anchorEl} = this.state;
        if (!showCreateItems) {
            return null;
        }
        const {feature} = showCreateItems;
        const {createitem, altcreateitem, alt2createitem}=feature;

        return <Menu
            anchorEl={anchorEl||null}
            open
            onClose={this.clickCreateItem.bind(this, null)}
        >
            {createitem?<MenuItem onClick={this.onCreateItem.bind(this, showCreateItems, 0)}>{createitem.buttonName||"Create"}</MenuItem>:null}
            {altcreateitem?<MenuItem onClick={this.onCreateItem.bind(this, showCreateItems, 1)}>{altcreateitem.buttonName||"Create"}</MenuItem>:null}
            {alt2createitem?<MenuItem onClick={this.onCreateItem.bind(this, showCreateItems, 2)}>{alt2createitem.buttonName||"Create"}</MenuItem>:null}
        </Menu>;
    }

    onSetDuration(usageId, val) {
        const {character} = this.props;
        character.setFeatureUsage(usageId+".duration", val);
    }

    getUseCountMenu() {
        const {character}=this.props;
        const {showPickActionUses, anchorEl} = this.state;
        if (!showPickActionUses) {
            return null;
        }

        const ret=[];
        for (let i in showPickActionUses) {
            const uo = showPickActionUses[i];
            let name;
            switch (uo.type) {
                case "uses": {
                    name = uo.level +" "+ (uo.usageName||pluralString("use",uo.level));
                    break;
                }
                case "hd":{
                    name = uo.level + "d"+uo.faces+" Hit Dice";
                    break;
                }
                case "spellpoints": {
                    name = uo.points+" "+character.t("Spell Points");
                    break;
                }
                case "spellslot":{
                    name =Parser.spLevelToFull(uo.level)+character.spellLevelName("-", " ")+character.t("Spell");
                    break;
                }
                case "pact":{
                    name =Parser.spLevelToFull(uo.level)+character.spellLevelName("-", " ")+character.t("Pact");
                    break;
                }
            }
            ret.push(<MenuItem key={i} onClick={this.clickActionUseCount.bind(this, uo)}>{name}</MenuItem>);
        }
        return <Menu
            anchorEl={anchorEl||null}
            open
            onClose={this.clickActionUseCount.bind(this, null)}
        >
            {ret}
        </Menu>;
    }

    getPickConvertMenu() {
        const {showPickConvert, actionUseParams, anchorEl} = this.state;
        if (!showPickConvert) {
            return null;
        }

        const ret=this.getPickConvertMenuItems(actionUseParams);
        return <Menu
            anchorEl={anchorEl||null}
            open
            onClose={this.clickShowConvertOptions.bind(this, null)}
        >
            {ret}
        </Menu>;
    }

    getPickConvertMenuItems(actionUseParams) {
        const {character} = this.props;
        const {feature, usageId, level} = actionUseParams;
        const {action,usage}=feature;
        const {toSpells, counts} = action;
        const ret=[];
        const uname = usage&&usage.usageName||((feature.name||"")+" uses");

        const tuv = character.getUsageVal(usage, usageId)||0;
        if (toSpells) {
            const uo = character.getSpellGainOptions(1)||[];
            for (let u of uo) {
                const c = counts[u.level-1];
                if (c && c <= tuv) {
                    let t;
                    switch (u.type) {
                        case "spellpoints":
                            t="Level "+u.level+" "+character.t("Spell Points")+"("+u.points+")";
                            break;
                        case "spellslot":
                            t="Level "+u.level+" "+character.t("Spell")+" slot";
                            break;
                        case "pact":
                            t="Level "+u.level+" "+character.t("Pact Slots");
                            break;
                        default:
                            console.log("unknown spell type", u);
                            break;
                    }
                    ret.push(<MenuItem key={t} onClick={this.clickCreateSpellSlot.bind(this, u)}>{c} {uname} -&gt; {t}</MenuItem>);
                }
            }
        } else {
            const max = character.getUsageMax(usage, level-1)||0;
            const gap = max-tuv;
            const uo = character.getSpellUsageOptions(1)||[];
            for (let u of uo) {
                const c = counts[u.level-1];
                if (c && (gap>0)) {
                    let t;
                    switch (u.type) {
                        case "spellpoints":
                            t="Level "+u.level+" "+character.t("Spell Points")+"("+u.points+")";
                            break;
                        case "spellslot":
                            t="Level "+u.level+" "+character.t("Spell")+" slot";
                            break;
                        case "pact":
                            t="Level "+u.level+" "+character.t("Pact Slots");
                            break;
                        default:
                            console.log("unknown spell type", u);
                            break;
                    }
                    ret.push(<MenuItem key={t} onClick={this.clickCreateUses.bind(this, u)}>{t} -&gt; {Math.min(c, gap)} {uname}</MenuItem>);
                }
            }
        }
        return ret;
    }

    clickCreateUses(u) {
        const {character}=this.props;
        const {actionUseParams} = this.state;
        const {feature, usageId, level} = actionUseParams;
        const {action,usage}=feature;
        const {counts} = action;

        const tuv = character.getUsageVal(usage, usageId)||0;
        const max = character.getUsageMax(usage, level-1)||0;
        const newusage = Math.min(max, tuv+counts[u.level-1]);

        switch (u.type){
            case "spellslot": {
                const uses = character.GetAvailableSpellSlots(u.level-1)-1;
                character.SetAvailableSpellSlots(u.level-1, uses);
                break;
            }
            case "pact": {
                let pactSlots = character.pactSlots - 1;
                character.pactSlots = pactSlots;
                break;
            }
            case "spellpoints": {
                const availableSpellPoints = character.availableSpellPoints - u.points;
                character.availableSpellPoints = availableSpellPoints;
                break;
            }
        }
        character.setUsageVal(usage, usageId, newusage);
        this.setState({showPickConvert:false});
    }

    clickCreateSpellSlot(u) {
        const {character}=this.props;
        const {actionUseParams} = this.state;
        const {feature, usageId} = actionUseParams;
        const {action,usage}=feature;
        const {counts} = action;

        const tuv = character.getUsageVal(usage, usageId)||0;
        const newusage = tuv-counts[u.level-1];

        switch (u.type){
            case "spellslot": {
                const uses = character.GetAvailableSpellSlots(u.level-1)+1;
                character.SetAvailableSpellSlots(u.level-1, uses);
                break;
            }
            case "pact": {
                let pactSlots = character.pactSlots + 1;
                character.pactSlots = pactSlots;
                break;
            }
            case "spellpoints": {
                const availableSpellPoints = character.availableSpellPoints + u.points;
                character.availableSpellPoints = availableSpellPoints;
                break;
            }
        }
        character.setUsageVal(usage, usageId, newusage);
        this.setState({showPickConvert:false});
    }

    clickActionUseCount(useageOption) {
        const {actionUseParams, actionUseHref, actionUseActionTypeVal} = this.state;
        if (useageOption) {
            this.clickAction(actionUseParams, actionUseHref, actionUseActionTypeVal, useageOption);
        }
        this.setState({showPickActionUses:null})
    }

    revertShape() {
        this.props.character.shape=null;
    }

    onChangeCondition(i, c) {
        const {character}= this.props;
        if (!c) {
            character.removeCondition(i, true);
        } else {
            const conditions = Object.assign({}, character.conditions||{});
            conditions[i]=c;
            character.setProperty("conditions",conditions);
        }
    }

    onChangeConditionVal(i, prop, val) {
        const {character}= this.props;
        const conditions = Object.assign({}, character.conditions||{});
        if (["level", "maxHP"].includes(prop) && !val) {
            character.removeCondition(i, true);
        } else {
            const c = Object.assign({}, getConditionStruc(conditions[i]));
            c[prop]=val;
            conditions[i]=c;
        }
        character.setProperty("conditions",conditions);
    }

    clickShowConvertOptions(params, evt) {
        this.setState({showPickConvert:params?true:false, actionUseParams:params, anchorEl:evt&&evt.currentTarget});
    }

    clickSpell(spell, href, damages, altdamages, conditions) {
        const {character,baseCharacter} = this.props;
        if (character.isMon) {
            if (damages&&damages.length && !altdamages && !spell.attackRoll && !conditions) {
                Chat.addMonsterDamageRoll(character, character.crow, baseCharacter, spell.spellName, href, damages,null, spell.saveAbility, spell.saveVal);
            } else {
                Chat.addMonsterAction(character, character.crow, baseCharacter, spell.spellName, href, spell.attackRoll, damages, spell.saveAbility, spell.saveVal, spell.spellLevel?(Parser.spLevelToFull(spell.spellLevel) + character.spellLevelName("-", " Spell")):"Spell", conditions, null, altdamages&&altdamages.length?altdamages:null, true);
            }
        } else {
            if (damages&&damages.length && !altdamages && !spell.attackRoll && !conditions) {
                Chat.addCharacterDamageRoll(character, spell.spellName, href, damages,null, null, spell.saveAbility, spell.saveVal);
            } else {
                Chat.addAction(character, spell.spellName, href, spell.attackRoll, damages, spell.saveAbility, spell.saveVal, spell.spellLevel?(Parser.spLevelToFull(spell.spellLevel) + character.spellLevelName("-", " Spell")):"Spell", conditions, null, altdamages&&altdamages.length?altdamages:null, true);
            }
        }
    }

    clickAction(params, href, actionTypeVal, usageOption, evt) {
        const {character,baseCharacter} = this.props;
        const {feature, usageId, level} = params;
        const {action}=feature;
        const upperActionType = actionTypeVal?upperActionTypeMap[actionTypeVal]:null;
        const featureName = nameFromFeatureParams(params);
        const estats={};
        let useCount=0,selectHistory;

        if (action.consumeUsage) {
            if (!usageOption) {
                const opts = character.getActionUsageOptions(feature, usageId);

                if (!opts.length) {
                    return;  // no usage options available
                } else if (opts.length == 1) {
                    usageOption=opts[0];
                } else {
                    this.setState({showPickActionUses:opts, actionUseParams:params, actionUseHref:href, actionUseActionTypeVal:actionTypeVal, anchorEl:evt&&evt.currentTarget});
                    return; // need to pick uses
                }
            }
            useCount = usageOption.level;
            if (usageOption.faces) {
                estats["hit dice"]=usageOption.faces;
            }
        }

        switch (action.actionOperation) {
            default:
            case "attack": {
                if (!useCount && (action.useDamageBonus || action.useTemphpBonus)) {
                    this.setState({showPickActionUses:true, actionUseParams:params, actionUseHref:href, actionUseActionTypeVal:actionTypeVal, anchorEl:evt&&evt.currentTarget});
                    return;
                }
                const {damages, attackRoll, directDamage, directTempHP,temphp} = character.performAction(params, usageOption);
                if (character.isMon) {
                    if (directDamage) {
                        Chat.addMonsterDamageRoll(character, character.crow, baseCharacter, featureName, href, damages, null, action.save, action.saveDC);
                    } else if (directTempHP) {
                        Chat.addMonsterTempHPRoll(character.state, character.crow,baseCharacter, featureName, href, temphp.hp, temphp.duration);
                    } else {
                        Chat.addMonsterAction(character, character.crow, baseCharacter, featureName, href, attackRoll, damages, action.save, action.saveDC,upperActionType, action.conditions, temphp);
                    }
                } else {
                    if (directDamage) {
                        Chat.addCharacterDamageRoll(character, featureName, href, damages, null, null, action.save, action.saveDC);
                    } else if (directTempHP) {
                        Chat.addCharacterTempHPRoll(character, featureName, href, temphp.hp, temphp.duration);
                    } else {
                        Chat.addAction(character, featureName, href, attackRoll, damages, action.save, action.saveDC,upperActionType, character.usageConditions(action.conditions,usageOption), temphp);
                    }
                }
    
                break;
            }
            case "activate":{
                if (action.consumeUsage) {
                    character.consumeActionUses(feature, usageId, usageOption);
                }
                character.setFeatureUsage(character.getActiveId(action, usageId), 1);
                let d = action.duration;
                if (d) {
                    character.setFeatureUsage(usageId+".duration", getDurationFromText(character.replaceMetawords(d,estats)));
                }
                if (action.enableFeature && character.checkActionForConfig(feature, usageId, level)) {
                    this.setState({showActivateAction:true, activateUsageId:usageId, activateAction:action.enableFeature})
                }

                let temphp;
                if (action.temphp){
                    temphp = {hp:character.replaceMetawords(action.temphp.hp,estats)};
                    if (action.temphp.duration) {
                        temphp.duration = character.replaceMetawords(action.temphp.duration,estats);
                    }
                }
                if (character.isMon) {
                    Chat.addMonsterAction(character, character.crow, baseCharacter, featureName, href, null, null, null, null, upperActionType, action.conditions, temphp);
                } else {
                    Chat.addAction(character, featureName, href, null, null, null, null,upperActionType, character.usageConditions(action.conditions,usageOption), temphp);
                }
                break;
            }
            case "shape":
                selectHistory=character.shapeHistory;
            case "summon": {
                const selected = character.getUsageVal(null, usageId+".m");
                let lastSelected;
                if (selected) {
                    lastSelected={};
                    lastSelected[selected]=1;
                }

                this.setState({showSelectMonster:true, selectMonsterParams:params, lastSelected, selectMonsterUses:usageOption, selectMonsterRestriction:calcMonRestriction(action.monsterSelection, level), selectHistory, anchorEl:evt.currentTarget})
                break;
            }
        }
    }

    selectMonster(selection) {
        if (selection) {
            const {character}=this.props;
            const {selectMonsterUses, selectMonsterParams,selectMonsterRestriction} = this.state;
            const {feature, usageId,level} = selectMonsterParams;
            const {action}=feature;

            if (action.consumeUsage) {
                character.consumeActionUses(feature, usageId, selectMonsterUses);
            }

            character.setUsageVal(null, usageId+".m", selection.toLowerCase());
            switch (action.actionOperation) {
                case "shape": {
                    const mon = campaign.getMonster(selection);
                    simplifyMonster(mon, true);
                    character.setShapeWithHistory(mon,selectMonsterRestriction,level);
                    character.conditions = addCondition( character.conditions, "Shape Change", character.replaceMetawords(action.duration));
                    break;
                }
                case "summon": {
                    const selList = {};
                    let count=1;
                    if (action.countByCr) {
                        const mon = campaign.getMonster(selection);
                        if (mon) {
                            const pos = Parser.crsortVals.indexOf(mon.crsort);
                            count = action.countByCr[pos]||1;
                        }
                    }

                    let d = action.duration;
                    if (d) {
                        character.setFeatureUsage(character.getActiveId(action, usageId), 1);
                        character.setFeatureUsage(usageId+".duration", getDurationFromText(character.replaceMetawords(d)));
                    }
    
                    selList[selection.toLowerCase()]=count;
                    character.addCompanions(selList, usageId,selectMonsterRestriction);
                    if (this.props.gotoSection) {
                        this.props.gotoSection("traits");
                    }
                    break;
                }
                default:
                    console.log("unknown action type for selectMonster", action);
            }
        }
        this.setState({showSelectMonster:false});
    }

    clickDeactivate(params) {
        const {character} = this.props;
        character.deactivateFeature(params);
    }

    clickActivateEffect(params, active) {
        const {character} = this.props;
        const {feature, usageId, level} = params;
        const activeId = usageId+".toggle";

        if (active) {
            character.setFeatureUsage(activeId, 1);
            if (feature.effects && character.checkEffectsForConfig(feature.effects, usageId+".togglef", level)) {
                this.setState({showActivateAction:true, activateUsageId:usageId+".e", activateAction:feature.effects});
            }
        } else {
            character.deactivateFeature(params, true);
        }
    }
    
    hideActivateAction() {
        this.setState({showActivateAction:false})
    }

    onCreateItem(params, itemNum) {
        const {feature, typeValue, level, usageId} = params;
        const createitem = itemNum?((itemNum==1)?feature.altcreateitem:feature.alt2createitem):feature.createitem;
        if (createitem.type=="fixed") {
            if (createitem.item) {
                this.doCreateNewItem(typeValue, level, feature, createitem, usageId, createitem.item);
            }
        } else if (createitem.type=="pick") {
            this.setState({showCreatePicker:true, createParams:params, createItemInfo:createitem})
        }
        this.setState({showCreateItems:null})
    }

    onClosePickItemCreate(selid, type) {
        if (selid) {
            const {character} = this.props;
            const params = this.state.createParams;

            const {feature, usageId, typeValue,level} = params;
            const createitem = this.state.createItemInfo;
            
            character.doCreateItem(createitem, selid, type, feature.name || typeValue?.displayName || typeValue?.name, feature.entries, usageId, level);
            this.finishCreateItem(feature, usageId);
        }
        this.setState({showCreatePicker:false})
    }

    finishCreateItem(feature, createId) {
        const {character} = this.props;
        const createitem = feature.createitem;
        if (createitem && createitem.consumeUsage) {
            const options = character.getActionUsageOptions(feature, createId, true);
            if (options.length) {
                character.consumeActionUses(feature, createId, options[0]);
            }
        }
        let d = createitem?.duration;
        if (d) {
            character.setFeatureUsage(createId+".cduration", getDurationFromText(character.replaceMetawords(d)));
        }

    }

    doCreateNewItem(baseitem, level, feature, createitem, createId, item) {
        const {character} = this.props;

        character.doCreateItem(createitem, item, "iteminfo", feature.name || baseitem.displayName || baseitem.name, feature.entries, createId, level);
        this.finishCreateItem(feature, createId);
    }

    onRemoveCreateItem(createId) {
        this.props.character.removeCreateItem(createId);
    }

    clickRoll(rollText, feature, event) {
        this.doTextRoll(feature.name||null, rollText);
        event.preventDefault();
        event.stopPropagation();
    }

    onAdjustFeature(id, feature) {
        const {character} = this.props;
        const newIt = Object.assign({}, character.getEquipmentItem(id));
        newIt.feature = feature;
        character.setEquipmentItem(newIt, id);
    }

    onAdjustExtraFeature(id, i, feature) {
        const {character} = this.props;
        const newIt = Object.assign({}, character.getEquipmentItem(id));

        newIt.extraFeatures = Object.assign({},newIt.extraFeatures);
        newIt.extraFeatures[i]=feature;
        character.setEquipmentItem(newIt, id);
    }

    closeAddCondition(conditionInfo) {
        if (conditionInfo) {
            const character = this.props.character;
            let c = Object.assign({}, character.getProperty("conditions")||{});
            addConditionInfoToConditions(c, conditionInfo);
            character.setProperty("conditions", c);
        }
        this.setState({showcharactermenu:false});
    }
}

const demoAction = {
    featureName:"Demo Action",
    type:"action",
    actionTypeVal:"action",
    attackRoll:"+2",
    damages:[{dmg:"1d6", dmgType:"lightning"}],
    enableAction:true,
    actionType:"A",
    params:{
        feature:{
            action:{
                "consumeUsage": 0,
                "dice": "1d6",
                "attackRoll": "+2",
                "usageType": "action"
            }
        },
        fid:"demoaction",
        usageId:"demoaction"
    }
};

const demoEffect = {
    active:0,
    activeId:"demo",
    params:{
        feature:{
            effects:{
                id:"demotoggle",
                name:"Demo Toggle Ability"
            }
        },
        usageId:"demotoggle",
        fid:"demotoggle"
    }
}

export {
    ActionBlock,
    UsageSlots,
    getDamagesInfo
}
const {campaign,areSameDeep,getLastModified} = require('../lib/campaign.js');
const React = require('react');
const {displayMessage} = require('./notification.jsx');
const {Rendersource} = require("./rendersource.jsx");
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
const {NumberAdjustPlusMinus,escapeRegExp,SelectMultiVal, SelectVal,getAnchorPos} = require('./stdedit.jsx');
import sizeMe from 'react-sizeme';

const defaultStartLen=20;
/*
    filterColumn:
        filterName: name to display for filter field
        fieldName: name of field 
        getFieldDisplayName: (optional) fn to convert field into display value
        convertField: (optional) convert the value of the field into a usable value
        sortFn: (optional) sort fn to order the values
    filter props:
        list: list of items to filter
        filters: list of filterColumns
        select: 
            "click" (default): call onClick when item clicked
            "list": return selected list with 0:1
            "counted": return a counted selected list with a count of each selected
        render: field name or fn to render item in list
        defaultFilter: (optional) object with fieldName and values for initial filter
        groupBy: (optional) field name to group by
        groupByPrefix: (optional) prefix text before group info
        ??? showDetails ??? fn to show item details

        onClick: callback function when an item is clicked
        onSelectedChange: callback function when selected list is changed
*/
class ListFilterBase extends React.Component {
    constructor(props) {
        super(props);

        this.state= {filter:props.defaultFilter|| {}, mode:"list", showAll:props.showAll||false, showLen:props.showAll?-1:defaultStartLen, sizeWidth:(props.size && props.size.width) || 1, expanded:{}};
        this.checkScrollFn = this.checkScroll.bind(this);
    }

    changeFilter(prop, e) {
        let filter = Object.assign({}, this.state.filter)
        filter[prop] = e.target.value;
        this.doFilterChange(filter);
    }

    doFilterChange(filter) {
        if (this.props.onChangeFilter) {
            this.props.onChangeFilter(filter);
        }
        const {showAll} = this.props;
        this.setState({filter, showAll:showAll||false, showLen:showAll?-1:defaultStartLen})
    }

    componentDidMount() {
        if (this.props.getListRef) {
            this.props.getListRef(this);
        }
        this.findParentScroll();
        if (this.parentScroll) {
            this.parentScroll.addEventListener('scroll', this.checkScrollFn);
        }
        if (this.props.scrollToSelected) {
            this.scrollToSelected();
        }
    }
    
    componentWillUnmount() {
        if (this.scrollTimer) {
            clearTimeout(this.scrollTimer);
            this.scrollTimer=null;
        }
        if (this.selectTimer) {
            clearTimeout(this.selectTimer);
            this.selectTimer=null;
        }
        if (this.parentScroll) {
            this.parentScroll.removeEventListener('scroll', this.checkScrollFn);
        }
    }

    componentDidUpdate(prevProps) {
        const oldWidth=this.state.sizeWidth||0;
        if ((this.props.size.width < oldWidth) || (this.props.size.width - oldWidth) > 30) {
            this.setState({sizeWidth:this.props.size.width});
        }
        if (this.props.open != prevProps.open) {
            this.setState({filter:this.props.defaultFilter|| {}, mode:"list", showAll:this.props.showAll||false,showLen:this.props.showAll?-1:defaultStartLen, expanded:{}})
        } else if (!areSameDeep(this.props.defaultFilter,prevProps.defaultFilter)) {
            this.setState({filter:this.props.defaultFilter||{}});
        }
    }

	render() {
        const filters = this.props.filters;
        const {mode, showAdvanced}=this.state;
        const showCheck = ["list","counted"].includes(this.props.select);
        const showCount = ["counted"].includes(this.props.select);
        const filterData = this.state.filter;
        const filterMenus = [];
        let inside;

        const {il, filterVals,all,count, groups} = this.getFilteredList();
        this.listLength = count;

        if (this.props.showThumbnails) {
            inside = this.getListGeneric("thumb",il, showCheck, showCount, groups, this.state.showLen);
        } else if (this.props.showCards) {
            inside = this.getCards(il,this.state.showLen);
        } else {
            inside = this.getListGeneric("row",il, showCheck, showCount, groups, this.state.showLen);
        }

        let enableAdvanced;
        for (let i in filters) {
            const filter=filters[i];
            const fInfo = filterData[filter.fieldName];
            if (!filter.advancedOnly || fInfo) {
                const vals = filterVals[i];
                const sortedVals = Object.keys(vals);
                sortedVals.sort(filter.sortFn||function (a,b){return (a||"").toLowerCase().localeCompare((b||"").toLowerCase())});

                if (filter.useRange) {
                    const {min,max}=fInfo||{};
                    const minVal=[{name:"min", value:"min"}], maxVal=[{name:"max", value:"max"}];

                    for (let fv of sortedVals) {
                        if (Number(fv)>0) {
                            const v = {name:filter.getFieldDisplayName?filter.getFieldDisplayName(fv):fv, value:Number(fv)}
                            minVal.push(v);
                            maxVal.push(v);
                        }
                    }

                    filterMenus.push(<FormControl variant="standard" className="mr1 tc ba b--gray-60 ph--2 pv--1" key={i}>
                        <span>
                            <SelectVal value={min||"min"} values={minVal} onClick={this.setMinMax.bind(this, "min", filter.fieldName)}/>--&nbsp;
                            <SelectVal value={max||"max"} values={maxVal} onClick={this.setMinMax.bind(this, "max", filter.fieldName)}/>
                        </span>
                        <FormHelperText className="tc">
                            {filter.filterName}
                        </FormHelperText>
                    </FormControl>);
                    enableAdvanced=true;
                } else {
                    const afilterData = getArrayValue(fInfo);

                    if ((sortedVals.length > 1) || afilterData?.length) {
                        filterMenus.push(<SelectMultiVal
                            className="mr1"
                            key={i}
                            value={afilterData}
                            values={sortedVals}
                            helperText={filter.filterName}
                            getOptionLabel={filter.getFieldDisplayName}
                            onChange={this.onUpdateFilter.bind(this, filter.fieldName)}
                        />);
                        enableAdvanced=true;
                    }
                }
            } else {
                enableAdvanced=true;
            }
        }
        this.checkScroll();

        return <div className={this.props.showCards?null:"notecontent"} key="items" ref={this.saveElement.bind(this)}>
            {(this.props.children||(showCheck&&!this.props.hideSelected)||((!showCheck || mode=="list")&&!this.props.hideSearch) || this.props.selectAll)?<div className={"defaultbackground sticktop pl1 mb1 z-5 "+(this.props.headerOutline?"shadow-3 br3":"")}>
                {this.props.children}
                {(showCheck&&!this.props.hideSelected)?<div className="tc mb2 mt--1">
                    <ButtonGroup size="small" color="primary">
                        <Button variant={(mode=="list")?"contained":null} onClick={this.clickMode.bind(this, "list")}>List</Button>
                        <Button variant={(mode=="selected")?"contained":null} onClick={this.clickMode.bind(this, "selected")}>Selected</Button>
                    </ButtonGroup>
                    {this.props.extraButtons}
                </div>:null}
                {((!showCheck || mode=="list")&&!this.props.hideSearch)?<div className="flex notetext flex-wrap f2 items-center">
                    <TextField 
                        className="flex-auto mr1"
                        helperText="Name"
                        placeholder="search"
                        defaultValue={this.state.filter.name}
                        onChange={this.changeFilter.bind(this,"name")}
                    />
                    {filterMenus}
                    {enableAdvanced?<Button onClick={this.showAdvanced.bind(this)} color="primary" size="small">more...</Button>:null}
                    {!showCheck&&this.props.extraButtons}
                </div>:null}
                {this.props.extraHeader}
                {this.props.selectAll?<div>
                    <span onClick={this.selectAll.bind(this, !all)} className={this.getSelClass(all)}/>all&nbsp;
                </div>:null}
            </div>:null}
            <div className="f3">
                {il.length?inside:this.props.emptyInfo||<div className="pa2 defaultbackground">
                    {(mode=="selected")?"Nothing selected":"Empty List"}
                </div>}
            </div>
            <FilterDialog 
                filters={filters}
                filterVals={filterVals}
                filter={filterData}
                open={showAdvanced}
                onClose={this.onCloseFilter.bind(this)}
            />
            {this.getSelMenu()}
        </div>;
    }

    showAdvanced() {
        this.setState({showAdvanced:true});
    }

    onCloseFilter(filter) {
        if (filter) {
            this.setState({filter})
        }
        this.setState({showAdvanced:false});
    }
    
    onUpdateFilter(prop, value) {
        const filter = Object.assign({}, this.state.filter)
        filter[prop]=value;
        this.doFilterChange(filter);
    }

    setMinMax(mprop,prop,val) {
        const filter = Object.assign({}, this.state.filter)
        if (["min","max"].includes(val)) {
            val = null;
        }
        filter[prop] = Object.assign({},filter[prop]||{});
        filter[prop][mprop] = Number(val);

        this.doFilterChange(filter);
    }

    saveElement(e) {
        this.element = e;
    }

    findParentScroll() {
        if (!this.element) {
            console.log("no element value");
            return;
        }
        let pel = this.element.parentElement;
        while (pel && !["auto", "scroll"].includes(window.getComputedStyle(pel).overflowY)) {
            pel = pel.parentElement;
        }
        this.parentScroll = pel;
    }

    scrollToSelected() {
        const {selected} = this.props;
        this.selectTimer=null;
        if (selected && Object.keys(selected).length && this.parentScroll) {
            const sel = this.parentScroll.getElementsByClassName("listSelected");
            if (sel.length) {
                setTimeout(function(){
                    sel[0].scrollIntoView({behavior: "smooth"});
                },100);
            } else {
                if (this.state.showLen>0 && this.state.showLen<this.listLength) {
                    const showLen = this.state.showLen+defaultStartLen;
                    this.setState({showLen});
                    this.selectTimer = setTimeout(this.scrollToSelected.bind(this), 10);
                }
            }
        }
    }

    checkScroll() {
        const t=this;
        if (this.scrollTimer) {
            clearTimeout(this.scrollTimer);
        }

        this.scrollTimer = setTimeout(function (){
            const element = t.parentScroll;
            if (element) {
                const close = (element.scrollHeight-element.scrollTop-element.clientHeight*2)<0;
                if (close && t.state.showLen>0 && t.state.showLen<t.listLength) {
                    const showLen = t.state.showLen+defaultStartLen;
                    t.setState({showLen});
                }
            }
            t.scrollTimer=null;
        },100);
    }

    getSelClass(sel) {
        if (this.props.useStar) {
            return sel?"pa1 hoverhighlight fas fa-star":"pa1 hoverhighlight far fa-star";
        }
        return sel?"pa1 hoverhighlight far fa-check-square":"pa1 hoverhighlight far fa-square";
    }

    getCards(il, maxRows) {
        const renderFn = this.props.render;
        const selected = this.props.selected||{};

        const cols = Math.max(1, Math.trunc(this.state.sizeWidth/(this.props.largeCard?350:this.props.smallCard?250:300)));
        const width = Math.trunc(this.state.sizeWidth/cols);
        const groupa={list:[], row:[]};
        const groupb={list:[], row:[]};
        const groupc={list:[], row:[]};

        for (let i=0; i<(Math.min((maxRows<0)?il.length:maxRows, il.length)); i++) {
            const it = il[i];
            let group;
            let bc;
            let sel;

            sel = selected[it.name.toLowerCase()];

            switch (it.entryScore||0){
                case -1:
                    group=groupa;
                    bc="greenbackground";
                break;
                case 0:
                    group=groupb;
                break;
                case 1:
                    group=groupc;
                    bc="redbackground";
                break;
                default:
                    continue;
            }

            group.row.push(renderFn(it, width, bc, sel, this.clickItem.bind(this,it.name, it.id), this.selectItem.bind(this,it.name, it.displayName, it.id, it.level) ));
            if (group.row.length == cols) {
                group.list.push(<div key={i} className="flex items-stretch w-100">
                    {group.row}
                </div>);
                group.row=[];
            }
        }


        if (groupa.row.length) {
            groupa.list.push(<div key="enda" className="flex items-stretch w-100">
                {groupa.row}
            </div>);
        }
        if (groupb.row.length) {
            groupb.list.push(<div key="enda" className="flex items-stretch w-100">
                {groupb.row}
            </div>);
        }
        if (groupc.row.length) {
            groupc.list.push(<div key="enda" className="flex items-stretch w-100">
                {groupc.row}
            </div>);
        }

        return <div>
            {groupa.list}
            {(groupa.list.length && groupb.list.length)?<div className="mv1 titletext titlecolor f2 bb bb titleborder">Uncommon</div>:null}
            {groupb.list}
            {groupc.list.length?<div className="mv1 titletext titlecolor f2 b bb titleborder">Ask Gamemaster</div>:null}
            {groupc.list}
        </div>;
    }

    getListGeneric(dtype, il, showCheck, showCount,groups, maxRows) {
        const renderFn = this.props.render;
        const {sizeWidth} = this.state;
        const {renderExtraCols, groupBy, inverted, border,slim,largeCard, alwaysSelected,convertGroup, collapseDups, onClick,defaultThumb} = this.props;
        const selected = this.props.selected||{};

        let lastGroup = null;
        let shown=0;
        let showGroup=true;

        const widthCalc = (sizeWidth>400)?(largeCard && (sizeWidth>660)?220:175):150;
        const cols = Math.max(1, Math.trunc(sizeWidth/widthCalc));
        const width = Math.trunc(sizeWidth/cols)-10-((border && !slim)?10:0);


        const list=[];
        for (let i=0; (i<il.length) && (maxRows<0 || shown<maxRows); i++) {
            const it = il[i];
            let sel,always;
            let lowName= it.name.toLowerCase();
            sel = selected[lowName];
            if ((showCheck || showCount)&&inverted) {
                sel=!sel;
            }
            if (alwaysSelected) {
                always = alwaysSelected[lowName];
            }

            const cgv = convertGroup?convertGroup(it[groupBy]):it[groupBy];
            const groupVal = groupBy && (cgv||"none");
            if (groupBy && ((groupVal != lastGroup) || (lastGroup==null))) {
                lastGroup = groupVal;
                if (lastGroup !== null) {
                    const expanded = this.getExpanded(lastGroup);
                    switch (dtype) {
                        case "thumb":
                        case "card":
                            list.push(<div key={"group"+lastGroup} className="w-100 f2 b titlebackground-i titlecolorcontrast">
                                <span className={"pa1 far "+(expanded?"fa-minus-square":"fa-plus-square")} onClick={this.toggleGroup.bind(this, lastGroup)}/>
                                {lastGroup}
                                &nbsp;({groups[lastGroup]})
                            </div>);
                            break;
                        default:
                            list.push(<tr key={"group"+lastGroup} className="f2 b titlebackground-i"><td colSpan="3">
                                <span className="titlecolorcontrast">
                                    <span className={"pa1 far "+(expanded?"fa-minus-square":"fa-plus-square")} onClick={this.toggleGroup.bind(this, lastGroup)}/>
                                    {lastGroup}
                                    &nbsp;({groups[lastGroup]})
                                </span>
                            </td></tr>);
                    }
                    showGroup=expanded;
                }
            }
    
            if (showGroup) {
                let check=i+1;
                let next = il[check];
                let matched;

                if (collapseDups) {
                    matched = [it];
                    while (next?.displayName && (next.displayName.toLowerCase()==it.displayName.toLowerCase())) {
                        const nlowName = next.name.toLowerCase();
                        const nsel = selected[nlowName]

                        check++;
                        matched.push(next);

                        if (!sel) {
                            if (nsel) {
                                sel=nsel;
                                lowName=nlowName;
                                it = next;
                            }
                        }
                        if (!always && alwaysSelected && alwaysSelected[nlowName]) {
                            always=true;
                            sel=nsel;
                            lowName=nlowName;
                            it = next;
                        }
                        next = il[check];
                    }
                    if (matched.length > 1) {
                        i += (matched.length-1);
                        if (always) {
                            matched=null;
                        }
                    } else {
                        matched=null;
                    }
                }

                shown++;
                const clickFn = onClick?(matched?this.clickSelect.bind(this, matched, false):this.clickItem.bind(this,it.name, it.id)):null;
                const checkFn = (!always||sel)?((!sel&&matched)?this.clickSelect.bind(this, matched, true):this.selectItem.bind(this,it.name, it.displayName, it.id, it.level)):null;
                switch (dtype) {
                    case "thumb":{
                        const av = it.art||it.tokenArt;
                        const art = av?campaign.getArtInfo(av):it;
                        let imageEl;
                        if (art && (art.thumb || art.url)) {
                            if (art.imgHeight>art.imgWidth) {
                                imageEl = <img className="db center" src={art.thumb || art.url} height={width} alt="Loading..."/>;
                            } else {
                                imageEl = <img className="db center" src={art.thumb || art.url} width={width} alt="Loading..."/>;
                            }
                        } else {
                            imageEl = <img className="db center o-40" src={defaultThumb || "/noimage.png"} width={width}/>;
                        }
                        list.push(<div key={it.name} className={(border?"shadow-3 br3 ma1 ":"")+(slim?"pb1 ":"pa1 ")+"dib relative defaultbackground flex flex-column overflow-hidden"+(this.props.hoverhighlight?" hoverhighlight":"")+(sel?" listSelected":"")} style={{width:width+(!slim?10:0)}}>
                            {cols>1?<div className="dib relative w-100">
                                <div onClick={clickFn} style={{minHeight:width}}>
                                    {imageEl}
                                </div>
                            </div>:null}
                            <div className="flex-auto flex items-center">
                                {showCheck?<span className={this.getSelClass(sel)} onClick={checkFn}/>:null}
                                <div className="truncate pv1 tc f3 flex-auto" onClick={clickFn}>{renderFn?renderFn(it,clickFn,i):it.displayName}</div>
                            </div>
                            {showCount?<div className="tc">{(!it.unique && sel)?<NumberAdjustPlusMinus value={sel} onChange={this.onAdjustCount.bind(this, it.name)}/>:null}</div>:null}
                        </div>);
                        break;
                    }
                    case "card":
                        break;
        
                    default:
                        list.push(<tr key={lastGroup?lastGroup+it.name:it.name} className={sel?"listSelected":null}>
                            {showCheck?<td className="w2" onClick={checkFn}><span className={this.getSelClass(sel||always)+(always&&!sel?" gray-80":"")}/></td>:null}
                            {showCount?<td className="tc">{(!it.unique && sel)?<NumberAdjustPlusMinus value={sel} onChange={this.onAdjustCount.bind(this, it.name)}/>:null}</td>:null}
                            {renderExtraCols&&renderExtraCols(it,sel)}
                            <td className={onClick?"hoverhighlight w-100":"w-100"} onClick={clickFn}>
                                {renderFn?renderFn(it):it.displayName||it.name}
                            </td>
                        </tr>)
                }
            }
        }

        switch (dtype) {
            case "thumb":
                return <div>
                    <div className="flex flex-wrap justify-left w-100">
                        {list}
                    </div>
                </div>

            default:
                return <table className="stdlist w-100">
                    <tbody>
                        {list}
                    </tbody>
                </table>
        }
    }

    getSelMenu() {
        const {showSelectMenu, anchorPos, matched, doMenuSelect} = this.state;
        const selected = this.props.selected||{};

        if (!showSelectMenu) {
            return null;
        }

        const list=[];
        for (let it of matched) {
            list.push(<MenuItem key={it.name} onClick={this.clickMenuSelect.bind(this, it)}><b>{selected[it.name.toLowerCase()]?"*":null}</b><Rendersource noClick className=" " entry={it}/>&nbsp;{it.edited?getLastModified(it):null}</MenuItem>)
        }

        return <Menu 
            open 
            anchorPosition={anchorPos} anchorReference="anchorPosition" 
            onClose={this.hideSelMenu.bind(this)}
        >
            {list}
        </Menu>
    }

    clickMenuSelect(it) {
        const {doMenuSelect} = this.state;

        if (doMenuSelect) {
            this.selectItem(it.name, it.displayName, it.id, it.level);
        } else {
            this.clickItem(it.name, it.id);
        }
        this.hideSelMenu();
    }

    hideSelMenu() {
        this.setState({showSelectMenu:false});
    }

    clickSelect(matched, doMenuSelect, e) {
        this.setState({showSelectMenu:true, anchorPos:getAnchorPos(e), matched, doMenuSelect});
    }

    getExpanded(group) {
        const e = this.state.expanded[group];
        if (e) {
            return true;
        }
        if (e==false) {
            return false;
        }
        return !this.props.defaultCollapsed;
    }

    toggleGroup(group) {
        const expanded = Object.assign({},this.state.expanded);
        expanded[group] = !this.getExpanded(group);
        this.setState({expanded});
    }

    getNextPrev(name) {
        const {il} = this.getFilteredList();
        let prev=null;
        let next=null;
        let last = null;
        let found = false;

        for (let i in il) {
            const it = il[i];
            if (found) {
                next = it;
                return {next,prev};
            }
            if ((it.name||"").toLowerCase() == (name||"").toLowerCase()) {
                found = true;
                if (last) {
                    prev = last;
                }
            }
            last = it;
        }
        return {next,prev};
    }

    getFilteredList() {
        const {groupBy,entryCheckFn,getExtraSearchFn,filters,inverted,collate,list,noResort} = this.props;
        const selected = this.props.selected||{};
        const mode=this.state.mode;
        const filterData = this.state.filter;
        const filterVals = [];
        const il = [];
        const groups = {};
        let all=true;
        let count=0;
        let f;
        const name =filterData.name;
    
        if (name)
            f=new RegExp(escapeRegExp(name), "i");
    
        for (let x in filters) {
            filterVals[x] = {};
        }
    
        for (let i in list) {
            const it = list[i];
            if (entryCheckFn && (entryCheckFn(it)>1)) {
                continue;
            }
            const fmatch = (!f || f.test(it.displayName || it.name) || (getExtraSearchFn && f.test(getExtraSearchFn(it))));
            let matchFields = true;
            for (let x in filters) {
                const filter = filters[x];
                const vals = filterVals[x];
    
                if (matchFields) {
                    let fval = filterData[filter.fieldName];
                    if (fval) {
                        let mf = it[filter.fieldName];
                        if (filter.convertField) {
                            mf = filter.convertField(mf, it);
                        }
                        if (mf === undefined) {
                            matchFields = false;
                        } else if (filter.useRange) {
                            const {min,max}=fval||{};
                            if ((min && (Number(mf)<min)) || (max && (Number(mf)>max))) {
                                matchFields = false;
                            }
                        } else {
                            const fvals = Array.isArray(fval)?fval:[fval];
                            let foundMatch;
                            for (let val of fvals) {
                                if (Array.isArray(mf)) {
                                    if (mf.includes(val)) {
                                        foundMatch=true;
                                    }
                                } else {
                                    if (mf == val) {
                                        foundMatch=true;
                                    }
                                }
    
                            }
                            if (!foundMatch) {
                                matchFields = false;
                            }
                        }
                    }
                }
    
                if (fmatch) {
                    let v = it[filter.fieldName];
                    if (filter.convertField) {
                        v = filter.convertField(v,it);
                    }
                    if (v != undefined) {
                        if (Array.isArray(v)) {
                            v.forEach(function (av) {
                                if (av) {
                                    av = av.toString();
                                    vals[av] = 1;
                                }
                            })
                        } else {
                            if (typeof v == "object") {
                                console.log("weird val", v);
                            }
                            v = v.toString();
                            vals[v] = 1;
                        }
                    }
                }
            }
    
            let sel;
            sel = selected[it.name.toLowerCase()];
            if (inverted) {
                sel=!sel;
            }

    
            const cgv = this.props.convertGroup?this.props.convertGroup(it[groupBy]):it[groupBy];
            if (mode=="selected") {
                if (sel) {
                    il.push(it);
                    count++;
                    if (groupBy) {
                        groups[cgv]=(groups[cgv]||0)+1;
                    }
                }
            } else if (matchFields && fmatch) {
                if (!sel) {
                    all=false;
                }
                il.push(it);
                count++;
                if (groupBy) {
                    groups[cgv]=(groups[cgv]||0)+1;
                }
            }
        }
        if (all && !il.length) {
            all=false;
        }
        const ret = {il, filterVals, all,count,groups}
    
        if (entryCheckFn && !noResort) {
            for (let i in il) {
                const entryScore= entryCheckFn(il[i]);
                if (entryScore) {
                    il[i]=Object.assign({entryScore}, il[i]);
                }
            }
            il.sort(function (a,b){return (a.entryScore||0)-(b.entryScore||0)});
        }
        if (collate) {
            for (let i=il.length-1; i>=0; i--) {
                let it = il[i];
                const base = collate(it,filterData);
                if (base) {
                    let found =-1;
                    if ((it.entryScore||0)<2) {
                        for (let x in il) {
                            const ic = il[x];
                            if (ic.name == base) {
                                found = x;
                            }
                        }
                    }
                    if (found >= 0) {
                        const rc = Object.assign({}, il[found]);
                        il[found]=rc;
                        if (rc.collateList) {
                            rc.collateList.push(it);
                        } else {
                            rc.collateList=[it];
                        }
                        rc.entryScore = Math.min(rc.entryScore||0, it.entryScore||0);
                        il.splice(i,1);
                    }
                }
            }
        }

        return ret;
    }

    showMore() {
        this.setState({showAll:true});
    }

    clickItem(name,id) {
        if (this.props.onClick) {
            this.props.onClick(name,id);
        }
    }

    selectItem(name, displayName, id, level, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        let pick;
        const {single, showCards,onSelectedChange} = this.props;
        const selected = single?{}:Object.assign({}, this.props.selected||{});
        name = name.toLowerCase();
        if (selected[name] || (!showCards && single && (this.props.selected||{})[name])) {
            delete selected[name];
        } else if (this.props.extraSelectInfo) {
            selected[name] = {name, displayName:displayName||null};
            if (id) {
                selected[name].id=id;
            }
            if (level) {
                selected[name].level=level;
            }
            pick=true;
        } else {
            selected[name] = 1;
            pick=true;
        }
        onSelectedChange(selected);
        return pick;
    }

    selectAll(select) {
        const selected = Object.assign({}, this.props.selected||{});
        const inverted = this.props.inverted;
        const {il} = this.getFilteredList();
        const del = (!select && !inverted) || (select && inverted);
        for (let i in il) {
            const it = il[i];
            const name = it.name.toLowerCase();

            if (del) {
                delete selected[name];
            } else {
                if (this.props.extraSelectInfo) {
                    const s = {name, displayName:it.displayName}
                    if (it.id) {
                        s.id = it.id;
                    }
                    if (it.level) {
                        s.level = it.level;
                    }
                    selected[name] = s;
                } else {
                    selected[name] = 1;
                }
            }
        }
        this.props.onSelectedChange(selected);
    }

    onAdjustCount(name, count) {
        const selected = Object.assign({}, this.props.selected||{});
        name = name.toLowerCase();
        selected[name] = count;
        this.props.onSelectedChange(selected);
    }

    clickMode(mode) {
        this.setState({mode});
    }
}

class FilterDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {filter:props.filter};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({filter:this.props.filter})
        }
    }

    onClose(save) {
        this.props.onClose(save?this.state.filter:null);
    }

	render() {
        const {filterVals, filters, open} = this.props;

        if (!open) {
            return null;
        }
        const filterData = this.state.filter;
        const filterMenues=[];

        for (let i in filters) {
            const filter=filters[i];
            const vals = filterVals[i];
            const fInfo = filterData[filter.fieldName];
            const sortedVals = Object.keys(vals);
            sortedVals.sort(filter.sortFn||function (a,b){return (a||"").toLowerCase().localeCompare((b||"").toLowerCase())});

            if (sortedVals.length>1) {
                if (filter.useRange) {
                    const {min,max}=fInfo||{};
                    const minVal=[{name:"min", value:"min"}], maxVal=[{name:"max", value:"max"}];

                    for (let fv of sortedVals) {
                        if (Number(fv)>0) {
                            const v = {name:filter.getFieldDisplayName?filter.getFieldDisplayName(fv):fv, value:Number(fv)}
                            minVal.push(v);
                            maxVal.push(v);
                        }
                    }

                    filterMenues.push(<div key={i}>
                        <FormControl variant="standard" className="tc">
                            <span>
                                <SelectVal value={min||"min"} values={minVal} onClick={this.setMinMax.bind(this, "min", filter.fieldName)}/>&nbsp;--&nbsp;
                                <SelectVal value={max||"max"} values={maxVal} onClick={this.setMinMax.bind(this, "max", filter.fieldName)}/>
                            </span>
                            <FormHelperText className="tc">
                                {filter.filterName}
                            </FormHelperText>
                        </FormControl>
                    </div>);
                } else {
                    const afilterData = getArrayValue(fInfo);

                    filterMenues.push(<SelectMultiVal
                        key={i}
                        value={afilterData}
                        values={sortedVals}
                        helperText={filter.filterName}
                        fullWidth
                        getOptionLabel={filter.getFieldDisplayName}
                        onChange={this.onUpdateFilter.bind(this, filter.fieldName)}
                    />);
                }
            }
        }

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this, false)}>Advanced Filter</DialogTitle>
            <DialogContent>
                {filterMenues}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.clear.bind(this)} color="primary">
                    clear all
                </Button>
                <Button onClick={this.onClose.bind(this, true)} color="primary">
                    ok
                </Button>
                <Button onClick={this.onClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    }
    
    onUpdateFilter(prop, value) {
        const filter = Object.assign({}, this.state.filter)
        filter[prop]=value;
        this.setState({filter});
    }

    setMinMax(mprop,prop,val) {
        const filter = Object.assign({}, this.state.filter)
        if (["min","max"].includes(val)) {
            val = null;
        }
        filter[prop] = Object.assign({},filter[prop]||{});
        filter[prop][mprop] = Number(val);
        this.setState({filter});
    }



    clear() {
        this.props.onClose({});
    }
}

function getArrayValue(v) {
    if (!v) {
        return [];
    }

    if (!Array.isArray(v)) {
        return [v];
    }
    return v;
}

const ListFilter = sizeMe({monitorHeight:false, monitorWidth:true})(ListFilterBase);
export {
    ListFilter
}


const React = require('react');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {displayMessage} = require('./notification.jsx');
const {campaign,globalDataListener, areSameDeep} = require('../lib/campaign.js');
const Parser = require("../lib/dutils.js").Parser;
const {Chat} = require('../lib/chat.js');
const {findOverlap} = require('./mapengine.jsx');
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {addTempHPCondition,addConditionsToConditions,addMaxHPToConditions} = require('./conditions.jsx');
const {getStringFromConditionsStruct} = require('./features.jsx');
const {DiceRollChat, DiceDamageRollChat, DiceRollD20, DiceRoller, doRoll,getDiceFromString,getRollSum, actionColors, getActionFromDamages,getActionFromDmgType,getHrefSpellInfo} = require('./diceroller.jsx');
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {alwaysArray, getAnchorPos,windowSize} = require('./stdedit.jsx');
const {LinkHref,getBasicHref, getSpellFromLink,getLocationInfo} = require('./renderhref.jsx');
const {ItemDialog} = require('./items.jsx');
import sizeMe from 'react-sizeme';
const {signedNum,abilityNames,damageTypesList,upperFirst} = require('../lib/stdvalues.js');
const {AddObject} = require('./objects.jsx');
const {diceSounds} = require('./dicetower.jsx');

const distanceFromTop = 100;

class RenderChat extends React.Component {
    constructor(props) {
        super(props);

        this.state= {chatList:[], maxRows:5};
        this.chatChangeFn = this.chatChange.bind(this);
        this.editorRef = React.createRef();
        this.lastRoll = 0;
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.chatChangeFn, "chat");
        this.chatChange();
    }

    componentWillUnmount() {
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }
        if (this.extendTimeout) {
            clearTimeout(this.extendTimeout);
        }
        globalDataListener.removeCampaignContentListener(this.chatChangeFn, "chat");
    }

    chatChange() {
        const {chatList,fullChatList,} = this.state;
        const {allowDiceSounds}=this.props;
        const newChatList = campaign.getChat();

        //console.log("new chat", newChatList);
        if (!chatList || !areSameDeep(newChatList, fullChatList)) {
            const filteredChatList = Chat.getFilteredChatList(newChatList);
            if (filteredChatList?.length) {
                const last = filteredChatList[filteredChatList.length-1];

                if (allowDiceSounds && (!this.lastChatShown || this.lastChatShown.name!=last.name) && last.roll && (last.time.seconds > this.lastPlayedRoll) && this.lastRoll) {
                    //console.log("changed", last.roll, last.time.seconds,this.lastPlayedRoll, this.lastRoll, last, this.lastChatShown);
                    if (campaign.userId != last.userId) {
                        diceSounds.playFullRoll();
                    }
                }
                this.lastRoll=last.time.seconds;
                this.lastPlayedRoll=last.time.seconds
                this.lastChatShown=last;
            }
            this.setState({fullChatList:newChatList, chatList:filteredChatList});
            if (!filteredChatList || !chatList || !areSameDeep(filteredChatList[filteredChatList.length-1],chatList[chatList.length-1])){
                this.scrollToEnd();
            }
        }
    }

    scrollToEnd() {
        const t=this;
        if (!this.scrollTimeout) {
            this.scrollTimeout = setTimeout(function () {
                const parent = t.ref;
                if (parent) {
                    const ns = parent.scrollHeight - parent.clientHeight;
                    parent.scrollTop = ns;
                    if (ns < distanceFromTop) {
                        t.onCheckScroll();
                    }
                }
                t.scrollTimeout=null;
            },10);
        }
    }

    render() {
        const {EditJournal} = require('./renderjournal.jsx');
        const list = [];
        const {chatList,text,maxRows, showDice,showAddEmoji,anchorPos} = this.state;
        let {width,allSmall,addToEncounter,addSpellToken,character,selected,softSelected,readonly,allowDiceSounds}=this.props;
        const now = new Date();
        const today = now.toLocaleDateString();
        const length = chatList?chatList.length:0;
        const startRow = Math.max(0,length-maxRows);
        let lastName, lastSeconds=0;
        const diceRoller=this.getDiceRoller.bind(this);
        const doSubRoll = this.doSubRoll.bind(this);
        const playerMode = campaign.isPlayerMode();
        const editAll = campaign.getGameState().sharing=="open";
        const userId = campaign.userId;
        const allowClick = selected || softSelected || (character && !character.readOnly);
        this.lastLength = length;

        width = Math.max(0,(width||0)-25); // 10 for borders and 15 for scrollbar

        if (startRow > 0) {
            list.push(<div key="placeholder">
                <div className="pa--2 bg-mid-gray-10 br3 chat ma--2 minh2"></div>
                <div className="pa--2 bg-mid-gray-10 br3 chat ma--2 minh2"></div>
            </div>);
        }
        for (let i=startRow; i<length; i++) {
            const c = chatList[i];
            let name = (!c.hideName || !playerMode)?c.actor:"";

            if (c.userType == "gm") {
                name = (name||"")+" (GM)";
            }

            const time = c.time.toDate();
            const ds = time.toLocaleDateString();
            const timestring = ((ds==today)?"today":ds)+ " "+time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit'});
            const editable=(!playerMode || ((editAll || (c.userId==userId))&&(c.userType!="gm")))&&!readonly;
            const last = i==(chatList.length-1);
            const extra = playerMode?null:<RollPermission rollPermission={c.permissions} changePermission={this.setRollPermissions.bind(this,c.name)}/>;
            const hideDetails = (playerMode && !editable && c.permissions!="public");
            let inside;

            switch (c.type) {
                case "roll":
                    if (c.roll && (c.roll.canAdvantage||!c.roll.rolls)) {
                        inside = <div className="mh--2 pa--2 b-chat ba br3 bkchat" onClick={editable?this.showChatMenu.bind(this,true,c.name):null}>
                            <DiceRollD20 
                                roll={c.roll}
                                readonly={readonly} 
                                changeRoll={editable?this.changeRoll.bind(this, c.name):null}
                                deleteRoll={editable?this.deleteRoll.bind(this, c.name):null}
                                hwidth={width}
                                small={allSmall || !last}
                                animate={last}
                                addToEncounter={addToEncounter}
                                doSubRoll={doSubRoll}
                                addSpellToken={addSpellToken}
                                getDiceRoller={diceRoller}
                                character={character}
                                getActionCharacter={this.getActionCharacter.bind(this)}
                                hideDetails={hideDetails}
                                onClickDamage={allowClick?this.onClickDamage.bind(this,c):null}
                                extra={extra}
                                onAddExtraDice={this.addExtraDice.bind(this, c,hideDetails)}
                            />
                        </div>
                    } else {
                        inside = <div className="mh--2 pa--2 b-chat ba br3 bkchat" onClick={editable?this.showChatMenu.bind(this,true,c.name):null}>
                            <DiceRollChat 
                                chat={c}
                                readonly={readonly} 
                                roll={c.roll}
                                changeRoll={editable?this.changeRoll.bind(this, c.name):null}
                                deleteRoll={editable?this.deleteRoll.bind(this, c.name):null}
                                hwidth={width}
                                small={allSmall || !last}
                                animate={last}
                                addToEncounter={addToEncounter}
                                doSubRoll={doSubRoll}
                                addSpellToken={addSpellToken}
                                getDiceRoller={diceRoller}
                                character={character}
                                getActionCharacter={this.getActionCharacter.bind(this)}
                                hideDetails={hideDetails}
                                extra={extra}
                                onClickSum={allowClick?this.onClickSum.bind(this,c):null}
                                onAddExtraDice={this.addExtraDice.bind(this, c,hideDetails)}
                            />
                        </div>
                    }
                    break;
                case "droll":
                    inside = <div className="mh--2 pa--2 b-chat ba br3 bkchat" onClick={editable?this.showChatMenu.bind(this,true,c.name):null}>
                        <DiceDamageRollChat 
                            chat={c}
                            roll={c.roll}
                            readonly={readonly} 
                            changeRoll={editable?this.changeRoll.bind(this, c.name):null}
                            small={allSmall || !last}
                            hwidth={width}
                            animate={last}
                            addToEncounter={addToEncounter}
                            doSubRoll={doSubRoll}
                            addSpellToken={addSpellToken}
                            getDiceRoller={diceRoller}
                            character={character}
                            getActionCharacter={this.getActionCharacter.bind(this)}
                            hideDetails={hideDetails}
                            extra={extra}
                            onClickSum={allowClick?this.onClickSum.bind(this,c):null}
                            onClickSave={this.onClickSave.bind(this,c)}
                            onAddExtraDice={this.addExtraDice.bind(this, c,hideDetails)}
                            getAoeSelect={this.getAoeSelect.bind(this)}
                        />
                    </div>
                    break;
                case "spell":
                    inside = this.getSpellInfo(c, editable,width,allSmall,last);
                    break;
                case "action":
                    inside = this.getActionInfo(c, editable,width,allSmall,last,allowClick,extra,hideDetails);
                    break;
                case "equipment":
                    inside = this.getEquipmentInfo(c, editable,width,allSmall,last);
                    break;
                case "ref":
                    inside = this.getRefInfo(c,editable);
                    break;
                case "handout":
                    inside = this.getHandoutInfo(c,editable);
                    break;
                case "journal":
                    inside = this.getJournalInfo(c,editable);
                    break;
                case "text":
                    inside = <div className="pa--2 textc br3 mh--2 chat" onClick={editable?this.showChatMenu.bind(this,true,c.name):null}><span className="stdcontent"><Renderentry entry={{type:"html", html:c.html||""}}/></span></div>
                    break;
                case "safety":
                    if (!editable) {
                        name="Anonymous";
                    }
                    inside = this.getSafetyInfo(c,editable);
                    break;
                default:
                    console.log("unknown chat type")
                    break;
            }
            const showName = ((name != lastName) || ((c.time.seconds - lastSeconds) > 120));
            if (showName) {
                lastName=name;
                lastSeconds = c.time.seconds;
            }
        
            list.push(<div key={c.name} className="f3 pb--3">
                {showName?<div className="pt1 ph1 pb--2"><b>{name}</b> <span className="f7 dimchat">{timestring}</span></div>:null}
                {inside}
            </div>)
        }

        return <div className="diceTrayBox w-100 bkchatsemi h-100 colorchat flex flex-column" >
            <div className="overflow-x-hidden overflow-y-auto flex-auto" ref={this.saveRef.bind(this)} onScroll={this.onCheckScroll.bind(this)}>
                {list}
                {!list.length?<div className="tc pa2 f2">Dice rolls, messages and shared information will show here.<br/><a href="https://shardtabletop.com/howto/chat" target="_blank">Learn more</a></div>:null}
            </div>
            
            {readonly?null:<div className="pl--3 pt--3 pb1 flex items-start bkchat">
                <div className="flex-auto chat ml--3 br2 pa--2 mr--2">
                    {showDice?<DiceRoller doRoll={this.doCustomRoll.bind(this)} character={character} width={width}/>:<div className="overflow-auto maxh3">
                        <EntityEditor saveDelay={10} shortOptions onChange={this.changeText.bind(this)} entry={text} placeholder="Message" noSearch enterHandler={this.onKeyPress.bind(this)} ref={this.editorRef}/>
                    </div>}
                </div>
                {!showDice?<div className="f2 pa--3 hoverhighlight br3 fa fa-smile" onClick={this.showAddEmoji.bind(this,true)}/>:null}
                {!playerMode&&!showDice?<div className="f2"><RollPermission rollPermission={Chat.getRollPermissions()} changePermission={this.setGMRollPermissions.bind(this)}/></div>:null}
                {text?<div className="f2 pa--3 mr2 hoverhighlight br3 fa fa-arrow-right bg-blue" onClick={this.onKeyPress.bind(this,null)}/>:
                    <div className={"f2 pa--3 mr--2 hoverhighlight br3 fa "+(showDice?"fa-times":"fa-dice-d20")} onClick={this.toggleDice.bind(this)}/>
                }
            </div>}
            <EmojiAdd open={showAddEmoji} anchorPos={anchorPos} onClose={this.showAddEmoji.bind(this,false)} addEmoji={this.addEmoji.bind(this)} showSafety={!text} addSafety={this.addSafety.bind(this)}/>
            {this.getSumMenu()}
            {this.getSaveMenu()}
            {this.getChatMenu()}
            {this.getItemDetails()}
            <EditJournal character={character} open={this.state.showJournalName} journal={this.state.showJournalName} onClose={this.showJournal.bind(this,null)}/>
        </div>;
    }

    showChatMenu(showChatMenu,chatId, evt) {
        this.setState({showChatMenu, chatId, anchorPos:evt&&getAnchorPos(evt)})
    }

    selectOverlap(cInfo,evt) {
        console.log("select overlap", cInfo)
        const {getTokenInfo} = require('./encountermonsterlist.jsx');
        const {combatants, setSelection}=this.props;

        const base = Object.assign({},cInfo);
        Object.assign(base, getTokenInfo(cInfo));
        const set = [];

        for (let c of combatants) {
            if ((c.tokenMap||"").toLowerCase() == cInfo.tokenMap.toLowerCase()) {
                const add = Object.assign({}, c);
                Object.assign(add, getTokenInfo(c));
                set.push(add);
            }
        }
        const overlap = findOverlap(base, set,true);
        console.log("overlap", overlap);
        if (overlap) {
            const selected={};
            for (let s of overlap) {
                selected[s]=true;
            }
            setSelection(selected);
        } else {
            displayMessage("No creatures in area of effect");
        }
        evt.preventDefault();
        evt.stopPropagation();
    }

    onClickSum(chatAction, sum, evt) {
        this.setState({showSumMenu:true, actionSum:sum, anchorPos:getAnchorPos(evt), chatAction, actionConditions:null});
    }

    onClickConditions(chatAction, actionConditions, evt) {
        if (evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        this.setState({showSumMenu:true, actionConditions, actionSum:0, anchorPos:getAnchorPos(evt), chatAction});
    }

    onClickTempHP(chatAction, evt) {
        if (evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        const thp = chatAction.temphp && chatAction.temphp.hp ||"";
        if (!(thp).toLowerCase().includes("d")) {
            const c = Object.assign({},c);
            c.roll = {action:"Temp HP"};
            c.duration = chatAction.temphp.duration;
            this.onClickSum(c, Number(thp), evt);
            return;
        }

        const {character} = this.props;
        Chat.addCharacterTempHPRoll(character, chatAction.refDisplayName||null, chatAction.href||null, chatAction.temphp.hp, chatAction.temphp.duration);
    }

    onClickDamage(c,damages) {
        const {character} = this.props;
        Chat.addCharacterDamageRoll(character, c.roll.source||null, c.roll.sourceHref||null, damages, c.duration, c.origin||c.name, c.saveAbility, c.saveVal);
    }

    getChatMenu() {
        const {showChatMenu, anchorPos} = this.state;

        if (!showChatMenu) {
            return null;
        }

        return <Menu open disableAutoFocusItem anchorPosition={anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.showChatMenu.bind(this,false,null, null)}>
            <MenuItem onClick={this.doDeleteChat.bind(this)}>Delete</MenuItem>
        </Menu>;
    }

    doDeleteChat() {
        campaign.deleteCampaignContent("chat", this.state.chatId);
        this.setState({showChatMenu:false});
    }

    getSumMenu() {
        if (!this.state.showSumMenu) {
            return null;
        }

        const {character} = this.props;
        const {actionSum, anchorPos, chatAction, actionConditions} = this.state;
        const action = chatAction.roll?.action;
        const source = chatAction.refDisplayName || chatAction.roll?.source;
        const laction =action?.toLowerCase();

        const spell = getHrefSpellInfo(chatAction.href || chatAction.roll?.sourceHref);
        const noHalfDamage = spell && !spell.level;

        const halfDamage = noHalfDamage?0:Math.trunc(actionSum/2);
        const showMaxHP = (laction == "max hp")&&actionSum;
        const showTempHP = (laction == "temp hp")&&actionSum;
        const showDamage=(action!="heal")&&!showTempHP&&actionSum, showHeal=(action=="heal")&&actionSum;
        const showConditions = actionConditions&& getStringFromConditionsStruct(actionConditions);

        if (character) {
            return <Menu open disableAutoFocusItem anchorPosition={anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.hideMenu.bind(this)}>
                <div className="ph1 pb1 f5 tc titlecolor titleborder bb">{character.displayName}</div>
                {chatAction.saveAbility&&actionSum?<MenuItem onClick={this.doCharacterSave.bind(this,chatAction.saveAbility, chatAction.saveVal,chatAction.name,actionSum,null,noHalfDamage)}><span className="flex-auto mr2">Save + Damage</span>{actionSum} / {halfDamage} HP</MenuItem>:null}
                {chatAction.saveAbility&&showConditions?<MenuItem onClick={this.doCharacterSave.bind(this,chatAction.saveAbility, chatAction.saveVal,chatAction.name,null,actionConditions,false)}><div className="tc flex-auto"><div className="titlecolor">Save + Apply Condition</div>{showConditions}</div></MenuItem>:null}
                {showMaxHP?<MenuItem onClick={this.doCharacterMaxHP.bind(this, source, actionSum, chatAction.duration)}><span className="flex-auto mr2">Max &amp; Current HP</span>{actionSum} HP</MenuItem>:null}
                {showHeal?<MenuItem onClick={this.doCharacterDamage.bind(this, actionSum)}><span className="flex-auto mr2">Heal</span>{actionSum} HP</MenuItem>:null}
                {showTempHP?<MenuItem onClick={this.doCharacterTempHP.bind(this, actionSum, chatAction.duration)}><span className="flex-auto mr2">Temp HP</span>{actionSum}</MenuItem>:null}
                {actionSum?<MenuItem onClick={this.doCharacterDamage.bind(this, -actionSum)}><span className="flex-auto mr2">Damage</span>{actionSum} HP</MenuItem>:null}
                {halfDamage?<MenuItem onClick={this.doCharacterDamage.bind(this, -halfDamage)}><span className="flex-auto mr2">Half Damage</span>{halfDamage} HP</MenuItem>:null}
                {(!showHeal&&actionSum)?<MenuItem onClick={this.doCharacterDamage.bind(this, actionSum)}><span className="flex-auto mr2">Heal</span>{actionSum} HP</MenuItem>:null}
                {(!showTempHP&&actionSum)?<MenuItem onClick={this.doCharacterTempHP.bind(this, actionSum, null)}><span className="flex-auto mr2">Temp HP</span>{actionSum}</MenuItem>:null}
                {showConditions?<MenuItem onClick={this.doCharacterConditions.bind(this, actionConditions)}><div className="tc flex-auto"><div className="titlecolor">Apply Condition</div>{showConditions}</div></MenuItem>:null}
            </Menu>;
        } else {
            const saves = this.getOriginMatch(chatAction.origin||chatAction.name);

            return <Menu open disableAutoFocusItem anchorPosition={anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.hideMenu.bind(this)}>
                <div className="ph1 pb1 f5 tc titlecolor titleborder bb truncate mw55">{this.getSelectedList()}</div>
                {showDamage&&chatAction.saveAbility&&!saves?<MenuItem onClick={this.doSelectedSave.bind(this,chatAction.saveAbility, chatAction.saveVal,chatAction.name,actionSum,null,noHalfDamage)}><span className="flex-auto mr2">Save + Damage</span>{actionSum} / {halfDamage} HP</MenuItem>:null}
                {showConditions&&chatAction.saveAbility&&!saves?<MenuItem onClick={this.doSelectedSave.bind(this,chatAction.saveAbility, chatAction.saveVal,chatAction.name,null,actionConditions,false)}><div className="tc flex-auto"><div className="titlecolor">Save + Apply Condition</div>{showConditions}</div></MenuItem>:null}
                {showMaxHP?<MenuItem onClick={this.doMaxHP.bind(this, source, actionSum,chatAction.duration)}><span className="flex-auto mr2">Max &amp; Current HP</span>{actionSum} HP</MenuItem>:null}
                {showHeal?<MenuItem onClick={this.doDamage.bind(this, actionSum,0,null)}><span className="flex-auto mr2">Heal</span>{actionSum} HP</MenuItem>:null}
                {showTempHP?<MenuItem onClick={this.doTempHP.bind(this, actionSum, chatAction.duration)}><span className="flex-auto mr2">Temp HP</span>{actionSum}</MenuItem>:null}
                {actionSum?<MenuItem onClick={this.doDamage.bind(this, -actionSum,0,null)}><span className="flex-auto mr2">Damage</span>{actionSum} HP</MenuItem>:null}
                {halfDamage?<MenuItem onClick={this.doDamage.bind(this, -halfDamage,0,null)}><span className="flex-auto mr2">Half Damage</span>{halfDamage} HP</MenuItem>:null}
                {showDamage&&saves?<MenuItem onClick={this.doDamage.bind(this, -actionSum, -halfDamage, saves)}><span className="flex-auto mr2">Save Damage</span>{actionSum} / {halfDamage} HP</MenuItem>:null}
                {(!showHeal&&actionSum)?<MenuItem onClick={this.doDamage.bind(this, actionSum,0,null)}><span className="flex-auto mr2">Heal</span>{actionSum} HP</MenuItem>:null}
                {(!showTempHP&&actionSum)?<MenuItem onClick={this.doTempHP.bind(this, actionSum, chatAction.duration)}><span className="flex-auto mr2">Temp HP</span>{actionSum}</MenuItem>:null}
                {showConditions?<MenuItem onClick={this.doConditions.bind(this, actionConditions,null)}><div className="tc flex-auto"><div className="titlecolor">Apply Condition</div>{showConditions}</div></MenuItem>:null}
                {showConditions&&saves?<MenuItem onClick={this.doConditions.bind(this, actionConditions,saves)}><div className="tc flex-auto"><div className="titlecolor">Apply Condition to Failed Saves</div>{showConditions}</div></MenuItem>:null}
            </Menu>;
        }
    }

    getActionCharacter() {
        const {character,selected, softSelected} = this.props;
        if (character) {
            return character;
        }
        const sel = selected || {};

        const encounter = campaign.getAdventure();
        const combatants = encounter.combatants||[];
        const found = [];

        if (!selected && softSelected) {
            sel[softSelected]=true;
        }
        for (let i in  combatants) {
            const c = combatants[i];

            if (sel[c.id]) {
                found.push(c);
            }
        }
        if (found.length == 1) {
            const {Combatant,fixupCombatants} = require('./encountermonsterlist.jsx');
            const comb = new Combatant(found[0]);
            const monObj = comb.monObj;
            if (monObj) {
                if (monObj.crow && !monObj.onChangeMon && !monObj.onChangeCrow) {
                    monObj.onChangeCrow = function (crow) {
                        const encounter = Object.assign({},campaign.getAdventure());
                        let members = (encounter.combatants||[]).concat([]);
                        let f=-1;
                        for (let i in members) {
                            const m=members[i];
                
                            if (m.id==crow.id) {
                                f=i;
                            }
                        }
                        if (f>=0) {
                            members[f]=crow;
                            fixupCombatants(members);
                            encounter.combatants = members;
                            campaign.updateCampaignContent("adventure", encounter);
                        }
                    }
                }
                return monObj;
            }
            return comb.characterObj||null;
        }
        return null;
    }

    doDamage(damage,halfDamage, saves) {
        const {Combatant,fixupCombatants} = require('./encountermonsterlist.jsx');
        const {selected, softSelected} = this.props;
        const sel = selected || {};

        const encounter = campaign.getAdventure();
        const combatants = (encounter.combatants||[]).concat();
        let dirty;

        if (!selected && softSelected) {
            sel[softSelected]=true;
        }
        for (let i in  combatants) {
            const c = combatants[i];

            if (sel[c.id]) {
                const comb = new Combatant(c);
                let d = damage;
                if (saves && (saves[c.id]=="passed")) {
                    d= halfDamage;
                }

                const newHp = Math.max(0, comb.hp+d);
                const newC = comb.adjustValue("hp", newHp,d);
                if (newC) {
                    combatants[i] = newC;
                    dirty=true;
                }
            }
        }
        if (dirty) {
            const newE = Object.assign({}, encounter);
            fixupCombatants(combatants);
            newE.combatants = combatants;
            campaign.updateCampaignContent("adventure", newE);
        }

        this.setState({showSumMenu:false});
    }

    doMaxHP(source,hp,duration) {
        const {Combatant,fixupCombatants} = require('./encountermonsterlist.jsx');
        const {selected, softSelected} = this.props;
        const sel = selected || {};

        const encounter = campaign.getAdventure();
        const combatants = (encounter.combatants||[]).concat();
        let dirty;

        if (!selected && softSelected) {
            sel[softSelected]=true;
        }
        for (let i in  combatants) {
            const c = combatants[i];

            if (sel[c.id]) {
                const comb = new Combatant(c);
                const conditions = addMaxHPToConditions(comb.conditions, source?(source + " Max HP"):"Max HP", hp, duration);
                const newHp = Math.max(0, comb.hp+hp);
                let newC = comb.changeValue("conditions", conditions);
                newC = comb.adjustValue("hp", newHp,hp) || newC;
                if (newC) {
                    combatants[i] = newC;
                    dirty=true;
                }
            }
        }
        if (dirty) {
            const newE = Object.assign({}, encounter);
            fixupCombatants(combatants);
            newE.combatants = combatants;
            campaign.updateCampaignContent("adventure", newE);
        }

        this.setState({showSumMenu:false});
    }

    doTempHP(temphp, duration) {
        const {Combatant,fixupCombatants} = require('./encountermonsterlist.jsx');
        const {selected, softSelected} = this.props;
        const sel = selected || {};

        const encounter = campaign.getAdventure();
        const combatants = (encounter.combatants||[]).concat();
        let dirty;

        if (!selected && softSelected) {
            sel[softSelected]=true;
        }
        for (let i in  combatants) {
            const c = combatants[i];

            if (sel[c.id]) {
                const comb = new Combatant(c);
                let newC = comb.changeValue("temphp", temphp);
                if (duration) {
                    const conditions = addTempHPCondition(comb.conditions, duration);
                    if (conditions) {
                        newC = comb.changeValue("conditions", conditions);
                    }
                }
                if (newC) {
                    combatants[i] = newC;
                    dirty=true;
                }
            }
        }
        if (dirty) {
            const newE = Object.assign({}, encounter);
            fixupCombatants(combatants);
            newE.combatants = combatants;
            campaign.updateCampaignContent("adventure", newE);
        }

        this.setState({showSumMenu:false});
    }

    doConditions(newconditions, saves) {
        const {Combatant,fixupCombatants} = require('./encountermonsterlist.jsx');
        const {selected, softSelected} = this.props;
        const sel = selected || {};

        const encounter = campaign.getAdventure();
        const combatants = (encounter.combatants||[]).concat();
        let dirty;

        if (!selected && softSelected) {
            sel[softSelected]=true;
        }
        for (let i in  combatants) {
            const c = combatants[i];

            if (sel[c.id] && (!saves || (saves[c.id]=="failed"))) {
                const comb = new Combatant(c);
                const conditions = addConditionsToConditions(comb.conditions, newconditions);
                if (conditions) {
                    const newC = comb.changeValue("conditions", conditions);
                    if (newC) {
                        combatants[i] = newC;
                    }
                    dirty=true;
                }
            }
        }
        if (dirty) {
            const newE = Object.assign({}, encounter);
            fixupCombatants(combatants);
            newE.combatants = combatants;
            campaign.updateCampaignContent("adventure", newE);
        }

        this.setState({showSumMenu:false});
    }

    doCharacterDamage(damage) {
        const {character} = this.props;
        character.damageHeal(damage);
        this.setState({showSumMenu:false});
    }

    doCharacterMaxHP(source, hp, duration) {
        const {character} = this.props;
        character.conditions = addMaxHPToConditions(character.conditions, source?(source + " Max HP"):"Max HP", hp, duration);
        character.damageHeal(hp);
        this.setState({showSumMenu:false});
    }

    doCharacterTempHP(temphp, duration) {
        const {character} = this.props;
        const set = {temphp};
        const conditions = addTempHPCondition(character.conditions, duration);
        if (conditions) {
            set.conditions=conditions;
        }
        character.setProperty(set);
        this.setState({showSumMenu:false});
    }

    doCharacterConditions(newconditions) {
        const {character} = this.props;
        const conditions = addConditionsToConditions(character.conditions, newconditions);
        if (conditions) {
            character.setProperty("conditions", conditions);
        }
        this.setState({showSumMenu:false});
    }

    hideMenu() {
        this.setState({showSumMenu:false, showSaveMenu:false})
    }

    showAddEmoji(showAddEmoji, evt) {
        this.setState({showAddEmoji,anchorPos:evt?getAnchorPos(evt):null});
        if (!showAddEmoji) {
            this.editorRef.current.contentRef.current.focus();
        }
    }

    addEmoji(e) {
        if (this.state.text) {
            const editor = this.editorRef.current.editor;
            editor.model.change( writer => {
                const insertPosition = editor.model.document.selection.getFirstPosition();
                writer.insertText( e, insertPosition );
            } );
        } else {
            const html = "<p>"+e+"</p>";
            this.setState({text:{type:"html", html}});
        }
        //this.showAddEmoji(false);
    }

    addSafety(code) {
        Chat.addSafety(code);
        this.showAddEmoji(false);
    }

    setGMRollPermissions(p) {
        Chat.setRollPermissions(p);
        this.setState({displayGMRolls:Chat.getRollPermissions()});
    }

    doSubRoll(name, text){
        let roll;
        if (typeof name == "object") {
            // if name is an object then it actually contains the roll
            roll = name;
        } else {
            const dice = getDiceFromString(text);
            const {rolls} = doRoll(dice);
            roll = {dice, rolls};
            if (name) {
                rolls.source = name;
            }
        }
        return this.doRoll(roll);
    }

    doRoll(newRoll, origin) {
        const {character} = this.props;
        if (character) {
            return Chat.addCharacterRoll(character, newRoll,origin);
        } else {
            return Chat.addGMRoll(newRoll,origin)
        }
    }

    doCustomRoll(dice) {
        if (dice) {
            const {rolls} = doRoll(dice);
            const newRoll = {dice, rolls};
            this.doRoll(newRoll);
        }

        this.setState({showDice:false});
    }

    toggleDice() {
        this.setState({showDice:!this.state.showDice});
    }

    onCheckScroll() {
        const parent = this.ref;
        const scrollTop = parent.scrollTop;
        const {maxRows} = this.state;

        if (!this.extendTimeout && (maxRows < this.lastLength) && (scrollTop<distanceFromTop)) {
            const t=this;
            const fromBottom = parent.scrollHeight- scrollTop;
            
            t.setState({maxRows:t.state.maxRows+10});
            this.extendTimeout = setTimeout(function () {
                t.extendTimeout = null;
                parent.scrollTop = parent.scrollHeight - fromBottom;
                t.onCheckScroll();
            }, 200);
        }
    }

    onKeyPress(evt) {
        if (evt) {
            evt.preventDefault();
        }
        const {text}=this.state;
        if (text && text.html) {
            const {character} = this.props;
            if (character) {
                Chat.addCharacterChatText(character, autoAnchorURLs(text.html));
            } else {
                Chat.addChatText(null, autoAnchorURLs(text.html));
            }
        }
        this.setState({text:null});
    }

    changeText(text) {
        this.setState({text});
    }

    saveRef(r) {
        this.ref = r;
    }

    changeRoll(name, newRoll) {
        const oldChat = campaign.getChatInfo(name);
        if (!oldChat) {
            return;
        }

        Chat.changeRoll(oldChat, newRoll);
    }

    deleteRoll(name) {
        campaign.deleteCampaignContent("chat", name)
    }

    setRollPermissions(name,permissions) {
        const oldChat = campaign.getChatInfo(name);
        if (!oldChat) {
            return;
        }
        const newChat = Object.assign({},oldChat);
        newChat.permissions=permissions;

        campaign.updateCampaignContent("chat", newChat);
    }

    addExtraDice(c, hideDetails, roll) {
        if (hideDetails) {
            c = Object.assign({}, c);
            if (c.roll) {
                c.roll = Object.assign({}, c.roll);
                delete c.roll.source;
            }
            delete c.saveVal;
            delete c.damages;
            delete c.otherDamages;
            delete c.conditions;
            delete c.saveAbility;
            delete c.temphp;
            delete c.actionType;
            delete c.href;
            delete c.refDisplayName;
        }
        Chat.addExtraRoll(this.props.character, c, roll);
    }

    getSpellInfo(c,editable,width,allSmall, last) {
        const {addToEncounter,addSpellToken,character,readonly}=this.props;
        const spellHref = c.spellHref||getBasicHref("spell", c.spellId);
        const showExpanded =(c.saveAbility||(typeof c.attackRoll == "number")||c.action||c.altaction||c.extraNotes);
        const showSpellLevel = typeof c.spellLevel == "number";
        const spellTag = readonly?null:this.getSpellTag(c, spellHref);

        if (!showExpanded) {
            return <div className="pa--2 spellc br3 mh--2 tc" onClick={this.showChatMenu.bind(this,true,c.name)}>
                <div className="f6">
                    {c.itemHref?<LinkHref href={c.itemHref}  doSubRoll={readonly?null:this.doSubRoll.bind(this)} character={character} addSpellToken={readonly?null:addSpellToken} addToEncounter={readonly?null:addToEncounter} getDiceRoller={readonly?null:this.getDiceRoller.bind(this)}><i>{c.itemName}</i> </LinkHref>:null}
                    {showSpellLevel?"Cast ":null}Spell: <LinkHref href={spellHref} doSubRoll={readonly?null:this.doSubRoll.bind(this)} character={character} addSpellToken={readonly?null:addSpellToken} addToEncounter={readonly?null:addToEncounter} getDiceRoller={readonly?null:this.getDiceRoller.bind(this)}>{c.spellName}</LinkHref> {showSpellLevel?<span>{Parser.spLevelToFull(c.spellLevel) + (c.spellLevel?"-level":"")} </span>:null} {spellTag} 
                    {addSpellToken&&!readonly?<span> &nbsp;<AddObject className="pa1" noSize color="primary" variant="text" defaultName={c.spellName} defaultShape={c.shape} defaultLength={c.length} character={character} spell={c.spellId} onAddObject={addSpellToken} useIcon defaultType="Spell Token" playerControlled/></span>:null}
                </div>
            </div>;
        }
        let altactionType;
        const action = getActionFromDmgType(c.actionType);
       
        if (c.altactionType) {
            if (!Parser.damageHealingNames[c.altactionType]) {
                altactionType = c.actionType || c.altactionType;
            } else {
                altactionType = c.altactionType;
            }
        }
            
        return <div className="pa--2 spellc br3 mh--2 tc" onClick={editable?this.showChatMenu.bind(this,true,c.name):null}>
            <div className="tc pb--2 f6 flex flex-wrap justify-center items-center">
                <div>
                    Cast {showSpellLevel?<span>{Parser.spLevelToFull(c.spellLevel) + (c.spellLevel?"-level Spell":"")}</span>:"Spell"}{": "}
                    {c.itemHref?<span><LinkHref href={c.itemHref}  doSubRoll={readonly?null:this.doSubRoll.bind(this)} character={character} addSpellToken={readonly?null:addSpellToken} addToEncounter={readonly?null:addToEncounter} getDiceRoller={readonly?null:this.getDiceRoller.bind(this)}><i>{c.itemName}</i></LinkHref> </span>:null}
                    <LinkHref href={spellHref} doSubRoll={readonly?null:this.doSubRoll.bind(this)} character={character} addSpellToken={readonly?null:addSpellToken} addToEncounter={readonly?null:addToEncounter} getDiceRoller={readonly?null:this.getDiceRoller.bind(this)}>{c.spellName}</LinkHref> {spellTag}&#32;
                    {addSpellToken&&!readonly?<span> &nbsp;<AddObject className="pa--2" noSize color="primary" variant="text" defaultName={c.spellName} defaultShape={c.shape} defaultLength={c.length} character={character} spell={c.spellId} onAddObject={addSpellToken} useIcon defaultType="Spell Token" playerControlled/> </span>:null}
                </div>

                {c.saveAbility?<div className="green" onClick={readonly?null:this.onClickSave.bind(this,c,c.saveAbility, c.saveVal, c.origin||c.name,0)}> <a className="pa--2"><span className="ttu">Save {c.saveAbility}</span>&nbsp;{c.saveVal}</a> </div>:null}
                {(typeof c.attackRoll == "number")?<div className="blue" onClick={readonly?null:this.doAttack.bind(this, c.spellName, spellHref,c.attackRoll, c.name)}> <a className="pa--2">To Hit {signedNum(c.attackRoll)}</a> </div>:null}

                {c.action?<div className="pl1"><span className="ttc">{action}</span>
                    <span className={actionColors[action]} onClick={readonly?null:this.doAction.bind(this, c.spellName, spellHref, c.actionType, c.action, c.name)}> <a className="pa--2">{c.action} {action!=c.actionType?<i>{c.actionType}</i>:null}</a></span>
                    {c.altaction?<span className={actionColors[getActionFromDmgType(altactionType)]}> <a className="pa--2" onClick={readonly?null:this.doAction.bind(this,c.spellName, spellHref, altactionType, c.altaction, c.name)}>{c.altaction} <i>{c.altactionType}</i></a></span>:null}
                </div>:null}
            </div>
            {c.roll?<DiceRollD20 
                roll={c.roll}
                changeRoll={editable?this.changeRoll.bind(this, c.name):null}
                hwidth={width}
                small={allSmall || !last}
                animate={last}
                noDetails
                character={character}
                getActionCharacter={this.getActionCharacter.bind(this)}
                readonly={readonly} 
                onAddExtraDice={this.addExtraDice.bind(this, c, false)}
            />:null}
        </div>;
    }

    getEquipmentInfo(c,editable,width,allSmall, last) {
        const {addToEncounter,addSpellToken,character,readonly}=this.props;
        const eList=this.getEquipmentList(c.equipment);
        const sList=this.getEquipmentList(c.sell);

        return <div className="pa--2 equipc br3 mh--2 f6" onClick={editable?this.showChatMenu.bind(this,true,c.name):null}>
            <span className="ttc">{c.eAction}</span>
            {campaign.isPlayerMode()?null:<EquipmentPermission rollPermission={c.permissions} changePermission={this.setRollPermissions.bind(this,c.name)}/>}
            {c.targets?" ("+c.targets+")":null} {eList}{sList?" for ":null}{sList}
        </div>;
    }

    getEquipmentList(equipment) {
        const eList=[];

        for (let i in equipment) {
            const it = equipment[i];
            if (eList.length) {
                eList.push(", ");
            }
            eList.push(<a key={i} onClick={this.setShowItem.bind(this,it)}>{(it.quantity>1||it.coin)?it.quantity+" ":null}{it.displayName||it.name}</a>);
        }
        if (eList.length) {
            return eList;
        }
        return null;
    }


    getItemDetails() {
        const {addToEncounter, character} = this.props;
        const {showItem} =this.state;
        return <div>
            <ItemDialog open={showItem} item={showItem} character={character} onClose={this.setShowItem.bind(this,null)} addToEncounter={addToEncounter}/>
        </div>
    }

    setShowItem(showItem, evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        this.setState({showItem});
    }

    getActionInfo(c,editable,width,allSmall, last, allowClick,extra,hideDetails) {
        const {addToEncounter,addSpellToken,character,readonly, combatants, setSelection}=this.props;
        const {conditions, damages, otherDamages} = c;
        const dmgList = [];

        const action = getActionFromDamages(damages);
        if (!hideDetails) {
            if (damages) {
                const dmgStr = getDmgString(damages);
                dmgList.push(<span key="d" className={actionColors[action]} onClick={readonly?null:this.doActionDamage.bind(this, c, damages)}> <a className="pa--2">{dmgStr}</a></span>);
            }
            for (let i in otherDamages) {
                const {damages}=otherDamages[i];
                const dmgStr = getDmgString(damages);
                dmgList.push(<span key={i} className={actionColors[action]} onClick={readonly?null:this.doActionDamage.bind(this, c, damages)}>, <a className="pa--2">{dmgStr}</a></span>);
            }
        }

        const cList = [];
        if (conditions) {
            if (conditions.conditions) {
                const selected = conditions.conditions;
                for (let i in selected) {
                    const ci = campaign.getCustom("Conditions", i);
                    if (ci && ci.displayName) {
                        const newSel = {};
                        newSel[i]=1;
                        const newConditions = {duration:conditions.duration||null, conditions:newSel};
                        if (cList.length) {
                            cList.push(", ");
                        }
                        cList.push(<span key={"c."+i} className="blue" onClick={readonly?null:this.onClickConditions.bind(this, c, newConditions)}><a className="pa--2">{ci.displayName}</a></span>);
                    }
                }
            }
            if (conditions.features) {
                for (let f of conditions.features) {
                    if (f.name) {
                        const newConditions = {duration:conditions.duration||null, features:[f]};
                        if (cList.length) {
                            cList.push(", ");
                        }
                        cList.push(<span key={"f."+f.name} className="blue" onClick={readonly?null:this.onClickConditions.bind(this, c, newConditions)}><a className="pa--2">{f.name}</a></span>);
                    }
                }
            }
        
        } else if (c.href && !readonly) {
            const spellTag = this.getSpellTag(c,c.href);
            if (spellTag) {
                if (cList.length) {
                    cList.push(", ");
                }
                cList.push(spellTag);
            }
        }
        let objTarget;

        if (c.token && !readonly && !hideDetails) {
            if (c.token.id) {
                // has a AOE
                objTarget = this.getAoeSelect(c.token.id);
            } else {
                objTarget=<span> &nbsp;<AddObject className="pa--2" noSize color="primary" variant="text" defaultName={c.refDisplayName} defaultShape={c.token.shape} defaultLength={c.token.length} character={character} spell={getSpellFromLink(c.href)} onAddObject={addSpellToken} useIcon defaultType="Spell Token" playerControlled/> </span>;
            }
        }

        return <div className="pa--2 spellc br3 mh--2 tc" onClick={editable?this.showChatMenu.bind(this,true,c.name):null}>
            <div className="tc pb--2 f6 flex flex-wrap justify-center items-center">
                {hideDetails?null:<div>
                    {(c.actionType!=" ")?(c.actionType||"Feature")+": ":null}
                    {c.href?<LinkHref href={c.href} doSubRoll={readonly?null:this.doSubRoll.bind(this)} character={character} addSpellToken={readonly?null:addSpellToken} addToEncounter={readonly?null:addToEncounter} getDiceRoller={readonly?null:this.getDiceRoller.bind(this)}>{c.refDisplayName}</LinkHref>:c.refDisplayName}&#32;
                </div>}
                {objTarget}
                {c.saveAbility?<div className="green" onClick={readonly?null:this.onClickSave.bind(this,c,c.saveAbility, hideDetails?0:c.saveVal, c.origin||c.name,0)}> <a className="pa--2"><span className="ttu">Save {alwaysArray(c.saveAbility).join("/")}</span>&nbsp;{hideDetails?"":c.saveVal}</a> </div>:null}
                {(!hideDetails&&(typeof c.attackRoll == "number"))?<div className="blue" onClick={readonly?null:this.doAttack.bind(this, c.refDisplayName, c.href,c.attackRoll, c.name)}> <a className="pa--2">To Hit {c.attackRoll}</a> </div>:null}
                {dmgList.length?<div className="pl1"><span className="ttc">{action}</span>
                    {dmgList}
                </div>:null}
                {cList.length?<div className="pl1">Condition: {cList}</div>:null}
                {c.temphp?<div className="pl1">Temp HP <span className="b" onClick={readonly?null:this.onClickTempHP.bind(this, c)}> <a className="pa--2">{c.temphp.hp}</a></span></div>:null}
                {extra}
            </div>
            {c.roll?<DiceRollD20
                readonly={readonly} 
                roll={c.roll}
                changeRoll={editable?this.changeRoll.bind(this, c.name):null}
                hwidth={width}
                small={allSmall || !last}
                animate={last}
                noDetails
                hideDetails={hideDetails}
                character={character}
                getActionCharacter={this.getActionCharacter.bind(this)}
                onClickDamage={allowClick?this.onClickDamage.bind(this,c):null}
                onAddExtraDice={this.addExtraDice.bind(this, c,hideDetails)}
            />:null}
        </div>;
    }

    getAoeSelect(id) {
        const {combatants, setSelection}=this.props;

        if (combatants) {
            const cInfo = combatants.find(function (f) {return f.id == id});
            if (cInfo && cInfo.tokenMap) {
                // found AOE
                return <span> &nbsp;<span className="pa--2 fas fa-crosshairs hoverhighlight" onClick={this.selectOverlap.bind(this, cInfo)}/> </span>
            }
        }
    }

    getSpellTag(c, href) {
        const spell = getHrefSpellInfo(href);
        if (spell){
            const {getDurationFromSpell} = require('./renderspell.jsx');
            const {getDurationFromText} = require('./conditions.jsx');

            const durationText = getDurationFromSpell(spell);
            if (durationText) {
                const newConditions = {duration:durationText, spells:[{name:"Tag: "+spell.displayName, id:spell.name}]};
                return <span key="spelltag" className="blue" onClick={this.onClickConditions.bind(this, c, newConditions)}><a className="pa--2">Spell Tag</a></span>;
            }
        }
    }

    doActionDamage(c,damages, evt) {
        evt.stopPropagation();
        evt.preventDefault();
        if (damages && damages.length==1 && !(damages[0].dmg||"").toLowerCase().includes("d")) {
            const chatAction = Object.assign({},c);
            chatAction.roll = {action:damages[0].dmgType};
            this.onClickSum(chatAction, Number(damages[0].dmg||""), evt);
            return;
        }
        const {character} = this.props;
        Chat.addCharacterDamageRoll(character, c.refDisplayName, c.href, damages, c.duration, c.origin||c.name,c.saveAbility, c.saveVal,c.token?.id);
    }

    onClickSave(chat,saveType, saveVal, origin, sum, evt) {
        if (evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }
        const spell = getHrefSpellInfo(chat.href || chat.roll?.sourceHref || chat.spellHref||(chat.spellId&&getBasicHref("spell", chat.spellId))||null);

        this.setState({showSaveMenu:true, saveType, saveVal, origin, saveDamage:sum, noHalfDamage:spell&&!spell.level, anchorPos:getAnchorPos(evt)});
    }

    getSaveMenu() {
        if (!this.state.showSaveMenu) {
            return null;
        }

        const {character} = this.props;
        const {anchorPos, saveType, saveVal,saveDamage, origin,noHalfDamage} = this.state;
        const halfDamage=noHalfDamage?0:Math.trunc(saveDamage/2);

        if (character) {
            return <Menu open disableAutoFocusItem anchorPosition={anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.hideMenu.bind(this)}>
                <div className="ph1 pb1 f5 tc titlecolor titleborder bb">{character.displayName}</div>
                <MenuItem onClick={this.doCharacterSave.bind(this,saveType, saveVal,origin,0,null,false)}>{upperFirst(abilityNames[saveType])} Save</MenuItem>
                {saveDamage?<MenuItem onClick={this.doCharacterSave.bind(this,saveType, saveVal,origin,saveDamage,null,noHalfDamage)}>{upperFirst(abilityNames[saveType])} Save + {saveDamage} / {halfDamage} Damage</MenuItem>:null}
            </Menu>;
        } else {

            return <Menu open disableAutoFocusItem anchorPosition={anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={this.hideMenu.bind(this)}>
                <div className="ph1 pb1 f5 tc titlecolor titleborder bb truncate mw55">{this.getSelectedList()}</div>
                <MenuItem onClick={this.doSelectedSave.bind(this,saveType, saveVal,origin,0,null,false)}>{upperFirst(abilityNames[saveType])} Save</MenuItem>
                {saveDamage?<MenuItem onClick={this.doSelectedSave.bind(this,saveType, saveVal,origin,saveDamage,null,noHalfDamage)}>{upperFirst(abilityNames[saveType])} Save + {saveDamage} / {halfDamage} Damage</MenuItem>:null}
            </Menu>;
        }
    }

    doSelectedSave(savesType, saveVal,origin, saveDamage,newconditions,noDamageOnSave) {
        const {selected, softSelected} = this.props;
        doSelectedSave(selected, softSelected, savesType, saveVal,origin, saveDamage,newconditions,noDamageOnSave);
        this.setState({showSaveMenu:false,showSumMenu:false});
    }

    doCharacterSave(savesType, saveVal,origin,saveDamage,newconditions,noDamageOnSave) {
        const {character} = this.props;
        const halfDamage=noDamageOnSave?0:Math.trunc(saveDamage/2);
        const saveType = character.getBestSave(savesType);
        const abilities = character.abilities;
        const a = abilities[saveType];
        const bonus = a.spellSave||0;
        const dice = {"D20":1, bonus};
        const {rolls} = doRoll(dice);
        const roll = {dice, rolls, source:abilityNames[saveType], action:"Save", pass:saveVal};
        const sum = getRollSum(roll);
        if (saveDamage) {
            const d = (sum>=saveVal)?-halfDamage:-saveDamage;
            character.damageHeal(d);
            roll.damageVal = -d;
        } else if (newconditions && (sum<saveVal)) {
            const conditions = addConditionsToConditions(character.conditions, newconditions);
            if (conditions) {
                character.setProperty("conditions", conditions);
            }
        }

        Chat.addCharacterRoll(character, roll,origin);

        this.setState({showSaveMenu:false,showSumMenu:false});
    }

    getSelectedList() {
        const {Combatant} = require('./encountermonsterlist.jsx');

        const {selected, softSelected} = this.props;
        
        const sel = selected || {};
        const encounter = campaign.getAdventure()||{};
        const combatants = encounter.combatants||[];
        const names = {};

        if (!selected && softSelected) {
            sel[softSelected]=true;
        }
        for (let c of combatants) {
            if (sel[c.id]) {
                const comb = new Combatant(c);
                names[comb.displayName]=true;
            }
        }

        return Object.keys(names).join(", ");
    }

    getOriginMatch(origin) {
        const {selected, softSelected} = this.props;

        const chatList = campaign.getChat();
        const found={};
    
        for (let i in chatList) {
            const c = chatList[i];
            if ((c.origin == origin) && c.cid && c.roll?.action=="Save" && c.roll.pass) {
                const sum = getRollSum(c.roll)
                found[c.cid] = (sum>=c.roll.pass)?"passed":"failed";
            }
        }

        const sel = selected || {};
        const encounter = campaign.getAdventure()||{};
        const combatants = encounter.combatants||[];

        if (!selected && softSelected) {
            sel[softSelected]=true;
        }
        for (let c of combatants) {
            if (sel[c.id]) {
                if (!found[c.id]) {
                    return null;
                }
            }
        }

        return found;
    }

    getRefInfo(c,editable) {
        const {addToEncounter,addSpellToken,character,readonly}=this.props;

        return <div className="pa--2 refc br3 mh--2 tc" onClick={editable&&!readonly?this.showChatMenu.bind(this,true,c.name):null}>
            <div className="f6">
                {c.refType}: <LinkHref href={c.href} doSubRoll={readonly?null:this.doSubRoll.bind(this)} character={character} addSpellToken={readonly?null:addSpellToken} addToEncounter={readonly?null:addToEncounter} getDiceRoller={readonly?null:this.getDiceRoller.bind(this)}>{c.refDisplayName}</LinkHref> 
            </div>
        </div>;
    }

    getHandoutInfo(c,editable) {
        const {ChatHandout} = require('./handouts.jsx');

        return <div className="pa--2 handoutc br3 mh--2 tc" onClick={editable?this.showChatMenu.bind(this,true,c.name):null}>
            <ChatHandout character={this.props.character} chat={c} onDelete={editable?this.deleteChat.bind(this,c.name):null}/>
        </div>;
    }

    getJournalInfo(c,editable) {

        return <div className="pa--2 handoutc br3 mh--2 tc" onClick={editable?this.showChatMenu.bind(this,true,c.name):null}>
            <a onClick={this.showJournal.bind(this, c.journalName)}>Journal entry {c.newJournal?"added":"updated"}: {c.journalDisplayName}</a>
        </div>;
    }

    showJournal(showJournalName, evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        this.setState({showJournalName});
    }

    getSafetyInfo(c, editable) {
        const {readonly}=this.props;
        switch (c.code) {
            case "X":
                return <div className="bg-dark-red f3 pa1 colorchat" onClick={editable&&!readonly?this.showChatMenu.bind(this,true,c.name):null}><b>X</b> Stop</div>;
            case "N":
                return <div className="bg-orange f3 pa1 colorchat" onClick={editable&&!readonly?this.showChatMenu.bind(this,true,c.name):null}><b>N</b> Slow Down</div>;
            case "O":
                return <div className="bg-dark-green f3 pa1 colorchat" onClick={editable&&!readonly?this.showChatMenu.bind(this,true,c.name):null}><b>O</b> Keep Going</div>;
            default:
                console.log("unknown saftey code", c.code);
        }
    }

    deleteChat(name) {
        campaign.deleteCampaignContent("chat", name);
    }

    getDiceRoller() {
        return <ChatButton character={this.state.character}/>;
    }

    doAction(spellName, spellHref, action, text, origin, evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        const dice = getDiceFromString(text);
        const {rolls} = doRoll(dice);
        const roll = {dice, rolls,source:spellName,sourceHref:spellHref};
        if (damageTypesList.includes(action)) {
            roll.action = "damage";
            roll.actionDetail = action;
        } else {
            roll.action = action;
        }

        this.doRoll(roll,origin);
    }

    doAttack(spellName, spellHref, bonus, origin, evt){
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }

        const dice = {"D20":1, bonus:bonus};
        const {rolls} = doRoll(dice);
        const roll = {dice, rolls,source:spellName,sourceHref:spellHref, action:"to hit"};

        this.doRoll(roll,origin);
    }
}

function doSelectedSave(selected, softSelected, savesType, saveVal,origin, saveDamage,newconditions,noDamageOnSave) {
    const {Combatant,fixupCombatants} = require('./encountermonsterlist.jsx');
    const sel = selected || {};
    const halfDamage=noDamageOnSave?0:Math.trunc(saveDamage/2);
    savesType = alwaysArray(savesType);

    const encounter = campaign.getAdventure();
    const combatants = (encounter.combatants||[]).concat();
    let dirty;

    if (!selected && softSelected) {
        sel[softSelected]=true;
    }
    for (let i in  combatants) {
        const c = combatants[i];

        if (sel[c.id]) {
            const comb = new Combatant(c);
            let saveType, bonus;
            for (let s of savesType) {
                const  b= comb.getSave(s);
                if (!saveType || (b>bonus)) {
                    saveType = s;
                    bonus = b;
                }
            }

            const dice = {"D20":1, bonus};
            const {rolls} = doRoll(dice);

            const roll = {dice, rolls, source:abilityNames[saveType], action:"Save", pass:saveVal};
            Chat.addD20BonusRolls(comb.characterObj, roll, comb.d20Bonuses.saveDice);
            const sum = getRollSum(roll);
            if (saveDamage) {
                const d = (sum>=saveVal)?-halfDamage:-saveDamage;
                const newHp = Math.max(0, comb.hp+d);
                const newC = comb.adjustValue("hp", newHp,d);
                if (newC) {
                    combatants[i] = newC;
                    dirty=true;
                }
                roll.damageVal = -d;
            } else if (newconditions && (sum<saveVal)) {
                const conditions = addConditionsToConditions(comb.conditions, newconditions);
                if (conditions) {
                    const newC = comb.changeValue("conditions", conditions);
                    if (newC) {
                        combatants[i] = newC;
                    }
                    dirty=true;
                }
            }
            Chat.addRoll(c, roll, origin)
        }
    }
    if (dirty) {
        const newE = Object.assign({}, encounter);
        fixupCombatants(combatants);
        newE.combatants = combatants;
        campaign.updateCampaignContent("adventure", newE);
    }
}

function getDmgString(damages) {
    const dmgStrs = [];
    for (let i in damages) {
        const d = damages[i];
        dmgStrs.push(d?.dmg||"");
    }
    return dmgStrs.length?dmgStrs.join("+"):null;
}

function autoAnchorURLs(html) {
    const newHtml = html.replace(/\W(http:\/\/|https:\/\/)\S*/ig, function (x){
        const first = x[0];
        if (first=='"') {
            return x;
        }
        let rest = x.substring(1);
        let end = ""
        const pos = rest.indexOf("<");
        if (pos>0) {
            end = rest.substr(pos);
            rest = rest.substr(0, pos);
        }
        return first+'<a href="'+rest+'">'+rest+'</a>'+end
    });
    return newHtml;
}

class RollPermission extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render() {
        const {rollPermission} = this.props;
        const {showPick,anchorPos} = this.state;
        let displayClass;

        switch (rollPermission) {
            case "public":
                displayClass="far fa-eye";
                break;
            case "roll":
                displayClass="fas fa-low-vision";
                break;
            
            default:
            case "gm":
                displayClass="far fa-eye-slash";
                break;
        }

        return <span onClick={function(evt){evt.stopPropagation();evt.preventDefault();}}>
            <span className={"pa--3 hoverhighlight br3 "+displayClass} onClick={this.showPick.bind(this,true)}/>
            {showPick?<Menu open disableAutoFocusItem anchorPosition={anchorPos} anchorReference="anchorPosition" transformOrigin={{ vertical: 'center', horizontal: 'center'}} onClose={this.showPick.bind(this,false)}>
                <MenuItem onClick={this.setRollPermission.bind(this,"public")}>Show Roll Details</MenuItem>
                <MenuItem onClick={this.setRollPermission.bind(this,"roll")}>Hide Details</MenuItem>
                <MenuItem onClick={this.setRollPermission.bind(this,"gm")}>Hide Roll</MenuItem>
            </Menu>:null}
        </span>;
    }

    setRollPermission(rollPermission) {
        this.props.changePermission(rollPermission);
        this.setState({showPick:false});
    }

    showPick(showPick,evt){
        this.setState({showPick,anchorPos:showPick&&evt?getAnchorPos(evt):null});
    }
}

class EquipmentPermission extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render() {
        const {rollPermission} = this.props;
        const {showPick,anchorPos} = this.state;
        let displayClass;

        switch (rollPermission) {
            default:
            case "roll":
            case "public":
                displayClass="far fa-eye";
                break;
            
            case "gm":
                displayClass="far fa-eye-slash";
                break;
        }

        return <span onClick={function(evt){evt.stopPropagation();evt.preventDefault();}}>
            <span className={"pa--3 hoverhighlight br3 "+displayClass} onClick={this.showPick.bind(this,true)}/>
            {showPick?<Menu open disableAutoFocusItem anchorPosition={anchorPos} anchorReference="anchorPosition" transformOrigin={{ vertical: 'center', horizontal: 'center'}} onClose={this.showPick.bind(this,false)}>
                <MenuItem onClick={this.setRollPermission.bind(this,"public")}>Show Details</MenuItem>
                <MenuItem onClick={this.setRollPermission.bind(this,"gm")}>Hide Details</MenuItem>
            </Menu>:null}
        </span>;
    }

    setRollPermission(rollPermission) {
        this.props.changePermission(rollPermission);
        this.setState({showPick:false});
    }

    showPick(showPick,evt){
        this.setState({showPick,anchorPos:showPick&&evt?getAnchorPos(evt):null});
    }
}

class ChatButton extends React.Component {
    constructor(props) {
        super(props);

        this.state= { };
    }

    render() {
        return <span className="flex-auto">
            <span className="hoverhighlight dib pt1"><span className="dice-icon-red dice--D20" onClick={this.showTray.bind(this,true)}/></span>
            {this.state.showTray?<Dialog
                open
                fullWidth
                maxWidth="sm"
                onClose={this.showTray.bind(this,false)}
                fullScreen={windowSize.innerHeight<550}
            >
                <DialogTitle onClose={this.showTray.bind(this,false)}>Chat</DialogTitle>
                <ChatWrap {...this.props}/>
            </Dialog>:null}
        </span>
    }

    showTray(showTray) {
        this.setState({showTray});
    }
}

class ChatWrapBase extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return <div className="h8 flex-auto pa1 titlebackground pa1 ma2 ignoreDrag">
            <RenderChat width={this.props.size.width} {...this.props}/>
        </div>;
    }
}

class EmojiAdd extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    render() {
        const {open, showSafety, onClose, anchorPos} = this.props;

        if (!open){
            return null;
        }
        const list=[];
        for (let i in emojiList) {
            const e = emojiList[i];
            list.push(<div key={i} className="pa1 hoverhighlight ma--1" onClick={this.addEmoji.bind(this,e)}>{e}</div>);
        }

        return <Popover
            anchorPosition={anchorPos} 
            anchorReference="anchorPosition"
            open
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            classes={{paper:"whiteshadow"}}
        >
            <div className="mw55 pa1 flex flex-wrap f1 bkchat">
                {list}
                {showSafety?<div className="bg-dark-red nowrap flex-auto f3 mr1 pa1 colorchat hoverhighlight" onClick={this.clickSafety.bind(this,"X")}><b>X</b> Stop</div>:null}
                {showSafety?<div className="bg-orange nowrap flex-auto f3 mr1 pa1 colorchat hoverhighlight" onClick={this.clickSafety.bind(this,"N")}><b>N</b> Slow Down</div>:null}
                {showSafety?<div className="bg-dark-green nowrap flex-auto f3 pa1 colorchat hoverhighlight" onClick={this.clickSafety.bind(this,"O")}><b>O</b> Keep Going</div>:null}
            </div>
        </Popover>
    }

    addEmoji(e) {
        this.props.addEmoji(e);
    }

    clickSafety(code) {
        this.props.addSafety(code);
    }
}

const emojiList = [
    '\u{1F602}',
    '\u{1F60D}',
    '\u{1F923}',
    '\u{1F60A}',
    '\u{1F64F}',
    '\u{1F495}',
    '\u{1F62D}',

    '\u{1F525}',
    '\u{1F618}',
    '\u{1F44D}',
    '\u{1F44E}',
    '\u{1F970}',
    '\u{1F60E}',
    '\u{1F606}',

    '\u{1F601}',
    '\u{1F609}',
    '\u{1F914}',
    '\u{1F605}',
    '\u{1F614}',
    '\u{1F644}',
    '\u{1F61C}',

    '\u{1F612}',
    '\u{1F629}',
    '\u{1F601}',
    '\u{1F92F}',
    '\u{1F44C}',
    '\u{1F44F}',
    '\u{1F494}',
    //'\u{1F496}',

    '\u2764\u{FE0F}',
    '\u{1F49C}',
    '\u{1F499}',
    '\u{1F622}',
    '\u{1F4AA}',
    '\u{1F917}',
    '\u{1F60E}',

    '\u{1F607}',
    '\u{1F973}',
    '\u{1F389}',
    '\u{1F937}',
    '\u{1F631}',
    '\u{1F60C}',
    '\u{1F338}',

    '\u{1F64C}',
    '\u{1F60B}',
    '\u{1F4AF}',
    '\u{1F4A5}',
    '\u{1F446}',
    '\u{1F9DF}',
    '\u{1F480}',

    '\u{1F608}',
    '\u{1F440}',
    '\u{1F340}',
    '\u{1F30A}',
    '\u{1F52E}',
    '\u{1F48E}',
    '\u{1FA93}\u{FE0F}',

    '\u26CF\u{FE0F}',
    '\u2692\u{FE0F}',
    '\u{1F5E1}\u{FE0F}',
    '\u2694\u{FE0F}',
    '\u{1F3F9}',
    '\u{1F6E1}\u{FE0F}',
    '\u{2696}\u{FE0F}',

];


const ChatWrap = sizeMe({monitorHeight:false, monitorWidth:true})(ChatWrapBase);

export {
    RenderChat,
    ChatButton,
    doSelectedSave,
    getDmgString
};
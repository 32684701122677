const React = require('react');
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
const EventEmitter = require('events');

function PaperComponent(props) {
    const handleId = props.handleId;
    const newProps = Object.assign({}, props);
    delete newProps.handleId;
    return <Draggable handle={"."+handleId} cancel={'[class*="MuiDialogContent-root"], .ignoreDrag'}>
        <Paper {...newProps} className={(props.className||"")+" "+handleId}/>
    </Draggable>;
}

const dialogEvents = new EventEmitter();
dialogEvents.setMaxListeners(100);
let dialogCount = 0;
let noRollDialogCount = 0;

class ResponsiveDialog extends React.Component {
    constructor(props) {
        super(props);

        this.resizeFn = this.resize.bind(this);

        this.state= {fullScreen:props.open&&this.resizeCheck() };
        this.id="handle"+Math.trunc(Math.random()*1000000);
        this.ref = React.createRef();
    }

    componentDidMount() {
        if (this.props.open) {
            dialogCount++;
            if (!this.props.showRolls) {
                noRollDialogCount++;
            }
        }
        dialogEvents.emit("changeDialogCount", dialogCount, noRollDialogCount);
        window.addEventListener('resize', this.resizeFn);
    }
  
    componentWillUnmount() {
        if (this.props.open) {
            dialogCount--;
            if (!this.props.showRolls) {
                noRollDialogCount--;
            }
        }
        dialogEvents.emit("changeDialogCount", dialogCount, noRollDialogCount);
        window.removeEventListener('resize', this.resizeFn);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open != this.props.open) {
            if (this.props.open) {
                dialogCount++;
                if (!this.props.showRolls) {
                    noRollDialogCount++;
                }
                this.setState({fullScreen:this.resizeCheck()});
            } else {
                dialogCount--;
                if (!this.props.showRolls) {
                    noRollDialogCount--;
                }
            }
            dialogEvents.emit("changeDialogCount", dialogCount, noRollDialogCount);
        }
    }

    resizeCheck() {
        return ((window.innerWidth < (this.props.minSize||740)) || (window.innerHeight<370));
    }

    resize() {
        const fullScreen = this.resizeCheck();
        this.setState({fullScreen});
    }

    onKeyDown(evt) {
        if (["Escape"/*,"Enter"*/].includes(evt.key)) {
            const {ok,cancel} = findDialogButtons(this.ref.current);
            let click;
            if ((evt.key == "Enter") && (evt.target.nodeName != "TEXTAREA")) {
                click=ok;
            } else if (evt.key == "Escape") {
                click=cancel;
            }
            if (click) {
                click.click();
            }
        }
        evt.stopPropagation();
    }

    render() {
        const fullScreen = ((this.props.fullWidth && (this.props.maxWidth!="xs" || this.props.allowFullscreen)) && this.state.fullScreen) || this.props.fullScreen;
        const modProps = Object.assign({}, this.props);
        delete modProps.noDrag;
        delete modProps.minSize;
        delete modProps.showRolls;
        delete modProps.allowFullscreen;

        if (fullScreen || this.props.noDrag) {
            return <Dialog className={this.props.className} {...modProps}  fullScreen={fullScreen} onClick={ignoreEvents} onKeyDown={this.onKeyDown.bind(this)} onKeyPress={ignoreEvents} onKeyUp={ignoreEvents} ref={this.ref}>
                {this.props.children}
            </Dialog>;

        } else {
            return <Dialog PaperComponent={PaperComponent} PaperProps={{handleId:this.id}} className={this.props.className}  {...modProps} onClick={ignoreEvents} onKeyDown={this.onKeyDown.bind(this)} onKeyPress={ignoreEvents} onKeyUp={ignoreEvents} ref={this.ref}>
                {this.props.children}
            </Dialog>;
        }
    }
}

function findDialogButtons(root) {
    const buttons = root.getElementsByClassName("MuiButton-root");
    const ret = {};
    for (let i=0; i<buttons.length; i++) {
        const b = buttons[i];
        const t = (b.innerText||"").toLowerCase();

        switch (t) {
            case "save":
            case "ok":
            case "yes":
                ret.ok = b;
                break;
            case "cancel":
            case "close":
            case "done":
            case "no":
                ret.cancel = b;
                break;

        }
    }
    return ret;
}

function ignoreEvents(event) {
    event.stopPropagation();
}

function DialogTitle(props) {
    let tour;
    const {tourSet, onClose, children} = props;
    if (tourSet) {
        const {ShowTour}= require('./tours.jsx');
        tour = <ShowTour defaultDialog tourSet={tourSet}/>
    }
    return <MuiDialogTitle disableTypography>
        <div className="flex">
            <div className="f1 titletext titlecolor flex-auto mr2">{children}</div>
            {tour?<span className="f2">{tour}</span>:null}
            {onClose ? (<span className="fas fa-times f2 hoverhighlight pa1" onClick={onClose}/>) : null}
        </div>
  </MuiDialogTitle>;
}

function DialogContentAdjust(props) {
    return <DialogContent {...props}>
        <div className="mb--1">
            {props.children}
        </div>
        </DialogContent>
}
function getDialogCount() {
    return dialogCount;
}

function getNoRollDialogCount() {
    return noRollDialogCount;
}

export {
    ResponsiveDialog as Dialog,
    DialogTitle,
    DialogActions,
    DialogContentAdjust as DialogContent,
    dialogEvents,
    getDialogCount,
    getNoRollDialogCount
};
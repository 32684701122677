const {serverTimestamp} = require("../src/firebase.jsx");
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {abilityModFromSpellDC,extraEffectsList,damageTypesList,coinNames} = require('../lib/stdvalues.js');
const {getDiceFromString,getRollSum, doRoll} = require('../src/diceroller.jsx');

let diceTowerCallback = null;
class Chat {
    constructor() {
        //this.chatList = campaign.getChat();
    }

    static setDiceTowerCallback(callback) {
        if (diceTowerCallback) {
            console.log("overwritting callback");
        }
        //console.log("callback registered");
        diceTowerCallback=callback;
    }

    static removeDiceTowerCallback(callback) {
        if (diceTowerCallback != callback) {
            console.log("removing wrong callback");
        } else {
            //console.log("callback removed");
            diceTowerCallback = null;
        }
    }

    static updateChatRoll(c, prev, del) {
        if (diceTowerCallback && c.roll) {
            diceTowerCallback(c, prev, function () {
                campaign.updateCampaignContent("chat", c);
                if (del) {
                    campaign.deleteCampaignContent("chat", del);
                }
            });
        } else {
            campaign.updateCampaignContent("chat", c);
            if (del) {
                campaign.deleteCampaignContent("chat", del);
            }
        }
    }

    static displayGMRolls() {
        return campaign.getCampaignDice().display;
    }

    static getRollPermissions() {
        let display = campaign.getPrefs().rollPermissions;
        if (!display) {
            display = campaign.getCampaignDice().display?"public":"gm";
        }
        return display;
    }

    static setRollPermissions(display) {
        campaign.setPrefs({rollPermissions:display});
    }

    static setCampaignDiceUrl(diceUrl) {
        const campaignDice = Object.assign({}, campaign.getCampaignDice());

        campaignDice.diceUrl = diceUrl;
        campaign.updateCampaignContent("adventure", campaignDice);
    }

    static setDisplayGMRolls(display) {
        const campaignDice = Object.assign({}, campaign.getCampaignDice());

        campaignDice.display = display;
        campaign.updateCampaignContent("adventure", campaignDice);
    }

    static addAction(character, featureName, href, attackRoll, damages, save, saveDC,actionTypeVal, conditions,temphp, otherDamages, useSpell,token,duration) {
        if (character?.readOnly) {
            return;
        }
        const {c} = getCharacterBaseInfo(character);
        return Chat.finishAction(c, character, character.state.diceUrl||null, featureName, href, attackRoll, damages, save, saveDC,actionTypeVal, conditions,temphp, otherDamages, useSpell,token,duration);
    }

    static finishAction(c, character, diceUrl, featureName, href, attackRoll, damages, save, saveDC,actionTypeVal, conditions,temphp, otherDamages, useSpell,token,duration) {
        c.type = "action";
        c.refType = "action";
        c.href = href||null;
        c.refDisplayName = featureName||null;
        if (actionTypeVal) {
            c.actionType = actionTypeVal;
        }
        if (token) {
            c.token=token;
        }
        if (duration) {
            c.duration=duration;
        }
        
        if (conditions) {
            c.conditions = character?character.resolveConditions(conditions):conditions;
        }
        if (character && temphp && temphp.hp) {
            temphp = Object.assign({}, temphp);
            temphp.hp = character.replaceMetawords(temphp.hp)
        }
        if (temphp) {
            c.temphp = temphp;
        }

        if (save) {
            c.saveAbility = save;
            c.saveVal = character?character.getSaveDCVal(saveDC):saveDC;
        }

        if (attackRoll) {
            c.attackRoll = attackRoll;

            const dice = getDiceFromString("1d20+"+attackRoll,0,false);
            const {rolls} = doRoll(dice);

            c.roll = {dice, rolls, damages:damages||null, otherDamages:otherDamages||null, source:c.refDisplayName, sourceHref:c.href, canAdvantage:true};
            if (character) {
                c.roll.diceUrl = diceUrl||null;
                Chat.addD20BonusRolls(character, c.roll, useSpell?character.spellDice:character.attackDice);
            } else {
                c.roll.diceUrl = campaign.getCampaignDice().diceUrl || null
            }
                
        } else {
            if (damages) {
                c.damages = damages;
            }
            if (otherDamages) {
                c.otherDamages = otherDamages;
            }
        }

        Chat.updateChatRoll(c);
        return c;
    }

    static addMonsterAction(monObj, crow, character, featureName, href, attackRoll, damages, save, saveDC,actionTypeVal, conditions,temphp, otherDamages, useSpell,token,duration) {
        let c=null,diceUrl=null;
        if (crow) {
            c= getChatBaseInfo(crow,true);
            diceUrl = campaign.getCampaignDice().diceUrl;
        } else if (character) {
            const obj = getCharacterBaseInfo(character);
            c = obj.c;
            diceUrl=obj.diceUrl;
            c.actor = monObj.displayName;
        }

        return Chat.finishAction(c, monObj, diceUrl||null, featureName, href, attackRoll, damages, save, saveDC,actionTypeVal, conditions,temphp, otherDamages, useSpell,token,duration);
    }

    static addEquipment(character, eAction, equipment, coins, targets, sellCoins) {
        if (character?.readOnly) {
            return;
        }
        const {c} = getCharacterBaseInfo(character);
        c.type = "equipment";
        let found;

        c.eAction = eAction || "dropped";
        c.equipment = Object.assign({}, equipment||{});

        for (let i in coins) {
            c.equipment[i] = {coin:true, coinType:i, quantity:coins[i], displayName:coinNames[i]}
        }
        
        if (!Object.keys(c.equipment).length) {
            return;
        }

        if (sellCoins) {
            c.sell = sellCoins;
        }

        if (targets) {
            c.targets=targets;
        }

        Chat.updateChatRoll(c);
        return c;
    }

    static addEntryRef(character, type, displayName, href, spellId) {
        const c= {name:campaign.newUid(), userId:campaign.userId, time:serverTimestamp(), type:"ref", permissions:"public"};

        c.userType = campaign.isPlayerMode()?"player":"gm";
        if (character?.readOnly) {
            return;
        }

        if (character && !character.readOnly) {
            c.characterId = character.name;
            c.actor = character.displayName || character.playerName || campaign.displayName;
        } else {
            c.actor = campaign.displayName;
        }

        c.refType = type;
        if (spellId) {
            c.type="spell";
            c.spellId=spellId;
            c.spellName = displayName||null;
        } else {
            c.href = href||null;
            c.refDisplayName = displayName||null;
        }
        campaign.updateCampaignContent("chat", c);
    }

    static addExtraRoll(character, chat, extraRoll) {
        const isPlayerMode = campaign.isPlayerMode();
        const c= Object.assign({},chat);
        c.name = campaign.newUid();
        c.time = serverTimestamp();
        c.userId = campaign.userId;
        c.roll = Object.assign({},c.roll||{});

        if (character?.readOnly) {
            return;
        }

        if (isPlayerMode) {
            c.userType = "player";
            c.permissions = "public";
        } else {
            c.userType = "gm";
            c.permissions = Chat.getRollPermissions();
        }
        if (character&&!character.readOnly) {
            c.characterId = character.name;

            c.actor = character.displayName || character.playerName || campaign.displayName;
        } else {
            c.actor = campaign.displayName;
        }

        if (!extraRoll.rolls && !extraRoll.damages) {
            const dice = Chat.minusAdjust(extraRoll.dice);
            const {rolls} = doRoll(dice);
            extraRoll = {dice, rolls};
            if (character) {
                extraRoll.diceUrl = character.state.diceUrl||null;
            } else if (!isPlayerMode) {
                extraRoll.diceUrl = campaign.getCampaignDice().diceUrl || null;
            }
        }
        switch (c.type) {
            case "spell":
            case "action":
            case "roll":{
                if (extraRoll.damages) {
                    c.roll.extraRolls = (c.roll.extraRolls||[]).concat(extraRoll.damages);
                } else {
                    c.roll.extraRolls = (c.roll.extraRolls||[]).concat([extraRoll]);

                    if (c.roll.source == "Initiative") {
                        if (c.characterId) {
                            const {getCharacterFromId} = require('../lib/character.js');
                            const character = getCharacterFromId(c.characterId);
                            if (character) {
                                character.initiative = getRollSum(c.roll);
                            }
                        }
                    }
    
                }

                break;
            }
            case "droll": {
                if (extraRoll.damages) {
                    c.roll.damages = (c.roll.damages||[]).concat(extraRoll.damages);
                } else {
                    c.roll.damages = (c.roll.damages||[]).concat([extraRoll]);
                }
                break;
            }
            default:
                console.log("unknown roll type");
        }
        Chat.updateChatRoll(c, chat,(chat.characterId == c.characterId)&&chat.name);
    }

    static addD20BonusRolls(character, roll, addD20BonusRolls) {
        if (character?.readOnly) {
            return;
        }

        //console.log("add d20", roll, addD20BonusRolls);
        let min = 0;
        for (let i in addD20BonusRolls) {
            const br = addD20BonusRolls[i];
            const ds = character?character.resolveDice(br.dice):br.dice;
            let dice = getDiceFromString(ds,null,true);
            if (dice.min && !Object.keys(dice).find(function (d) {return dice[d] && d!="min"})) {
                min = Math.max(min, dice.min);
            } else {
                if (br.minus) {
                    dice.minus=true;
                    dice = Chat.minusAdjust(dice);
                }
                const {rolls} = doRoll(dice);
                roll.extraRolls = (roll.extraRolls||[]).concat([{dice,rolls}]);
            }
        }
        if (min) {
            roll.rolls[0]=Math.max(roll.rolls[0]||0, Math.min(20, min));
            roll.dice.min = Math.max(roll.dice.min||0, min);
        }
    }

    static minusAdjust(dice) {
        if (dice.minus) {
            dice = Object.assign({},dice);
            delete dice.minus;
            for (let i in dice) {
                dice[i]*=-1;
            }
        }
        return dice;
    }

    static addGMRoll(roll, origin) {
        const c= {name:campaign.newUid(), time:serverTimestamp()};
        
        c.userType = "gm";
        c.permissions = Chat.getRollPermissions();
        c.userId = campaign.userId;
        c.actor = campaign.displayName;
        c.type = "roll";
        c.roll = Object.assign({},roll);

        const {isDamage, canAdvantage} = getAdvantageDamage(roll);
        if (isDamage) {
            c.roll.isDamage=1;
        } else if (canAdvantage) {
            c.roll.canAdvantage=1;
        }

        c.roll.diceUrl = campaign.getCampaignDice().diceUrl || null;
        if (origin) {
            c.origin = origin;
        }

        Chat.updateChatRoll( c);
        return c;
    }

    static addCharacterRoll(character, roll, origin,noAuto) {
        const c= {name:campaign.newUid(), time:serverTimestamp()};
        const isPlayerMode = campaign.isPlayerMode();

        if (character?.readOnly) {
            return;
        }
        
        if (isPlayerMode) {
            c.userType = "player";
            c.permissions = "public";
        } else {
            c.userType = "gm";
            c.permissions = Chat.getRollPermissions();
        }

        c.userId = campaign.userId;
        if (!character.readOnly) {
            c.characterId = character.name;

            c.actor = character.displayName || character.playerName || campaign.displayName;
        } else {
            c.actor = campaign.displayName;
        }

        if (origin) {
            c.origin = origin;
        }

        if (!noAuto) {
            if (roll.source == "Initiative") {
                Chat.addD20BonusRolls(character, roll, character.initiativeDice);
            } else if (roll.action == "Check") {
                Chat.addD20BonusRolls(character, roll, character.getAbilityD20Modifiers(roll.source));
            } else if (roll.action == "Save") {
                Chat.addD20BonusRolls(character, roll, character.saveDice);
            }
        }
        
        c.type = "roll";
        c.roll = Object.assign({},roll);
        const {isDamage, canAdvantage} = getAdvantageDamage(roll);
        if (isDamage) {
            c.roll.isDamage=1;
        } else if (canAdvantage) {
            c.roll.canAdvantage=1;
        }

        c.roll.diceUrl = character.state.diceUrl||null;

        Chat.updateChatRoll( c);
        return c;
    }

    static addCharacterDamageRoll(character, source, sourceHref, damagesBase,duration,origin, save, saveDC, aoeid) {
        if (character?.readOnly) {
            return;
        }
        const {c, diceUrl} =  getCharacterBaseInfo(character);
        Chat.finishDamageRoll(c, diceUrl, source, sourceHref, damagesBase, duration, origin, save,(save&&character)?character.getSaveDCVal(saveDC):saveDC, character?character.getDiceStats():null,aoeid );
    }

    static addMonsterDamageRoll(mon, crow, character, source, sourceHref, damagesBase,duration, save, saveDC) {
        let c,diceUrl=null;
        if (crow) {
            c= getChatBaseInfo(crow,true);
            diceUrl = campaign.getCampaignDice().diceUrl || null;
        } else if (character) {
            const obj = getCharacterBaseInfo(character);
            c = obj.c;
            diceUrl=obj.diceUrl;
            c.actor=mon.displayName;
        }
        //console.log("monster roll", c, source, diceUrl, damagesBase, save, saveDC)
        Chat.finishDamageRoll(c, diceUrl, source, sourceHref, damagesBase,duration,null, save, (save&&mon)?mon.getSaveDCVal(saveDC):saveDC);
    }

    static finishDamageRoll(c, diceUrl, source, sourceHref, damagesBase,duration,origin, save, saveDC, stats,aoeid) {
        if (duration) {
            c.duration=duration;
        }
        if (save) {
            c.saveAbility = save;
            c.saveVal = saveDC;
        }

        if (origin) {
            c.origin=origin;
        }
        c.type = "droll";
        if (aoeid) {
            c.aoeid = aoeid;
        }

        let isDamage=false, action;
        const min = getMinFromDamages(damagesBase,stats);
        const damages = damagesBase.concat([]);
        for (let i in damages) {
            const d = Object.assign({}, damages[i]);
            damages[i]=d;

            d.dice = getDiceFromString(d.dmg,0,true,stats);
            d.dice.min = min;
            const {rolls} = doRoll(d.dice);
            d.rolls = rolls;
            if (extraEffectsList.includes(d.dmgType)) {
                action = d.dmgType;
            } else if (damageTypesList.includes(d.dmgType)) {
                d.isDamage=1;
                isDamage=true;
            }
        }

        c.roll = {damages, source, sourceHref, action:isDamage?"damage":action||"roll",diceUrl};
    
        Chat.updateChatRoll( c);
    }

    static addCharacterTempHPRoll(character, source, sourceHref, rollstr, duration) {
        if (character?.readOnly) {
            return;
        }
        const {c,diceUrl} = getCharacterBaseInfo(character);
        c.type = "roll";
        if (duration) {
            c.duration = duration;
        }

        const dice = getDiceFromString(rollstr, 0, true);
        const {rolls} = doRoll(dice);
        c.roll = {source, sourceHref, action:"Temp HP",diceUrl, dice, rolls};
    
        Chat.updateChatRoll( c);
    }

    static addMonsterTempHPRoll(mon, crow, character, source, sourceHref, rollstr, duration) {
        let c,diceUrl=null;
        if (crow) {
            c= getChatBaseInfo(crow,true);
            diceUrl = campaign.getCampaignDice().diceUrl || null;
        } else if (character) {
            const obj = getCharacterBaseInfo(character);
            c = obj.c;
            diceUrl=obj.diceUrl;
            c.actor=mon.displayName;
        }
        c.type = "roll";
        if (duration) {
            c.duration = duration;
        }

        const dice = getDiceFromString(rollstr, 0, true);
        const {rolls} = doRoll(dice);
        c.roll = {source, sourceHref, action:"Temp HP",diceUrl, dice, rolls};
    
        Chat.updateChatRoll( c);
    }



    static addChatText(crow, html) {
        const c= getChatBaseInfo(crow);

        c.type = "text";
        c.html = html;
        c.permissions = "public";

        campaign.updateCampaignContent("chat", c);
    }

    static addSafety(code) {
        const c= {name:campaign.newUid(), time:serverTimestamp()};

        c.userType = campaign.isPlayerMode()?"player":"gm";
        c.permissions = "public";

        c.userId = campaign.userId;
        c.actor = campaign.displayName;;

        c.type = "safety";
        c.code = code;
        c.permissions = "public";

        campaign.updateCampaignContent("chat", c);
    }

    static addHandout(handout) {
        const c= {name:campaign.newUid(), time:serverTimestamp()};

        c.userType = "gm";
        c.permissions = "public";

        c.userId = campaign.userId;
        c.actor = campaign.displayName;;

        c.type = "handout";
        c.handout = handout;

        campaign.updateCampaignContent("chat", c);
    }

    static addJournal(character, journalName, journalDisplayName, newJournal) {
        const c= {name:campaign.newUid(), time:serverTimestamp()};

        c.userType = campaign.isPlayerMode()?"player":"gm";
        c.permissions = "public";

        c.userId = campaign.userId;
        if (character) {
            c.characterId = character.name;

            c.actor = character.displayName || character.playerName || campaign.displayName;
        } else {
            c.actor = campaign.displayName;
        }

        c.type = "journal";
        c.journalName = journalName;
        c.journalDisplayName = journalDisplayName||null;
        c.newJournal = !!newJournal;

        campaign.updateCampaignContent("chat", c);
    }

    static addCharacterChatText(character, html) {
        const c= {name:campaign.newUid(), time:serverTimestamp()};

        if (character?.readOnly) {
            return;
        }
        c.userType = campaign.isPlayerMode()?"player":"gm";
        c.permissions = "public";

        c.userId = campaign.userId;
        if (!character.readOnly) {
            c.characterId = character.name;

            c.actor = character.displayName || character.playerName || campaign.displayName;
        } else {
            c.actor = campaign.displayName;
        }

        c.type = "text";
        c.html = html;

        campaign.updateCampaignContent("chat", c);
    }

    static addRoll(crow, roll, origin) {
        const c= getChatBaseInfo(crow,roll);

        c.type = "roll";
        c.roll = Object.assign({},roll);
        if (origin) {
            c.origin = origin;
        }
        const {isDamage, canAdvantage} = getAdvantageDamage(roll);
        if (isDamage) {
            c.roll.isDamage=1;
        } else if (canAdvantage) {
            c.roll.canAdvantage=1;
        }

        c.roll.diceUrl = campaign.getCampaignDice().diceUrl || null;

        Chat.updateChatRoll( c);
        return c;
    }

    static changeRoll(oldChat, newRoll) {
        const c = Object.assign({}, oldChat);
        c.roll = newRoll;
        if (newRoll.source == "Initiative") {
            if (c.characterId) {
                const {getCharacterFromId} = require('../lib/character.js');
                const character = getCharacterFromId(c.characterId);
                if (character) {
                    character.initiative = getRollSum(newRoll);
                }
            }
        }
        Object.assign(c, {name:campaign.newUid(), time:serverTimestamp()});
        //console.log("change roll", c, oldChat);
        Chat.updateChatRoll(c, oldChat,oldChat.name);
    }

    static getFilteredChatList(chatList) {
        if (campaign.isDefaultCampaign() || !campaign.isPlayerMode() || !chatList) {
            return chatList;
        }
        const ret = [];

        for (let c of chatList) {
            if (c.permissions!='gm') {
                ret.push(c);
            }
        }
        return ret;
    }
}

function getMinFromDamages(damages,stats) {
    let min=0;
    for (let i in damages) {
        const d = damages[i];

        const dice = getDiceFromString(d.dmg,0,true,stats);
        min = Math.max(min, dice.min||0);
    }
    return min;
}

function getChatBaseInfo(crow, roll) {
    const c= {name:campaign.newUid(), time:serverTimestamp()};
    
    c.userType = campaign.isPlayerMode()?"player":"gm";
    c.permissions = Chat.getRollPermissions();
    c.userId = campaign.userId;
    if (roll && crow) {
        c.cid = crow.id||null;
        if (crow.ctype=="pc") {
            const player = campaign.getPlayerInfo(crow.name);
            if (player) {
                c.actor = player.displayName || player.playerName;
                c.characterId = crow.name;
            }
        } else if (crow.ctype=="cmonster") {
            const player = campaign.getPlayerInfo(crow.name);
            if (player) {
                c.actor = player.displayName || player.playerName;
                c.characterId = crow.name;
                const companion = (player.companions||{})[crow.companionId];
                if (companion) {
                    c.companionId = crow.companionId;
                    c.actor = companion.displayName;
                }
            }
        } else if (crow.type) {
            c.actor = crow.name;
            const mon = campaign.getMonster(crow.type);
            if (mon) {
                if (mon.unique||mon.npc) {
                    c.actor=mon.displayName;
                }
                if (mon.npc) {
                    c.characterId = mon.name;
                } else {
                    c.monsterId = mon.name;
                }
            }
            c.hideName=crow.hideName||false;
        } else {
            c.actor=crow.name;
        }
    }
    if (!c.actor) {
        c.actor = campaign.displayName;
    }
    return c;
}

function getCharacterBaseInfo(character) {
    const c= {name:campaign.newUid(), time:serverTimestamp()};
    const isPlayerMode = campaign.isPlayerMode();
    let diceUrl=null;

    if (isPlayerMode) {
        c.userType = "player";
        c.permissions = "public";
    } else if (character) {
        c.userType = "gm";
        c.permissions = (character.characterType=="monsters")?Chat.getRollPermissions():"public";
    } else {
        diceUrl = campaign.getCampaignDice().diceUrl || null;
        c.userType = "gm";
        c.permissions = Chat.getRollPermissions();
    }

    c.userId = campaign.userId;
    if (character && !character.readOnly) {
        c.characterId = character.name;

        c.actor = character.displayName || character.playerName || campaign.displayName;
        diceUrl = character.state.diceUrl||null;
    } else {
        c.actor = campaign.displayName;
    }
    return {c, diceUrl};
}

function getAdvantageDamage(roll) {
    const dice = roll.dice||{};

    let isDamage = ["damage","roll"].includes((roll.action||"roll").toLowerCase()) && !dice.D20 && !dice.D100;
    let canAdvantage= (roll.action != "Temp HP");
    let found = false;

    for (let d in dice){
        const c = dice[d]||0;
        if (d.startsWith("D") && c) {
            if ((d != "D20")||c>1) {
                canAdvantage=false;
            }
            found=true;
        }
    }

    if (!found) {
        isDamage=canAdvantage=false;
    }

    return {isDamage, canAdvantage};
}

export {
    Chat
};
'use strict';

import {htmlFromEntry} from "../lib/entryconversion.js";
const stdvalues = require('../lib/stdvalues.js');
const {campaign,upgradeItem, replaceMetawords, areSameDeep,areSameDeepInst,getExtensionEntryCheckFn,extensionsFromSaved} = require('../lib/campaign.js');
const Parser = require("../lib/dutils.js").Parser;
const {doRoll,getStringFromDice,getRollSum,getDiceFromString,damagesFromExtraDamage} = require('../src/diceroller.jsx');
const {snackMessage,displayMessage} = require('../src/notification.jsx');
const {Chat} = require('../lib/chat.js');

function proficiencyMerge(base, adjust) {
    if (!adjust) {
        return base;
    }

    if (base == "expert" || adjust == "expert") {
        return "expert";
    }
    
    switch (base) {
        default:
            if (base) {
                console.log("Something went wrong unknown base "+base);
            }
        case "half":
            if ((adjust == "proficientplus")||(adjust=="proficient")) {
                return adjust;
            }
            return "half";

        case "proficient":
            if (adjust == "proficientplus") {
                return "expert";
            }
            return "proficient";
        case "proficientplus":
            if ((adjust == "proficientplus")||(adjust=="proficient")) {
                return "expert";
            }
            return "proficientplus";
    }
}

function mergeAbilityBonus(bonusAbilities, abilityMod) {
    if (!abilityMod){
        return;
    }
    for (let i in abilityMod) {
        if (!bonusAbilities[i]) {
            bonusAbilities[i]={};
        }
        if (i=="all") {
            Object.assign(bonusAbilities.all, abilityMod[i].all);
        } else {
            for (let x in abilityMod[i]) {
                if (x == "all") {
                    bonusAbilities[i].all = (bonusAbilities[i].all||0) + abilityMod[i].all;
                } else {
                    bonusAbilities[i][x] = true;
                }
            }
        }
    }
}

function computeSpeedFromShape(speed) {
    const retSpeed = {};

    if (typeof speed != "object") {
        retSpeed.walk= {number:speed};
        return retSpeed;
    } 

    for (let i in speed) {
        const s = speed[i];

        if (typeof s == 'object') {
            retSpeed[i]={number:s.number||0};
        } else {
            retSpeed[i] = {number:s};
        }
    }
    return retSpeed;
}

function mergeBasic(mod, base, add) {
    if (add.missingConfig) {
        mod.missingConfig=true;
        delete add.missingConfig;
    }
    Object.assign(base, add);
    return base;
}

function mergeProficiency(mod, base, add) {
    if (add.missingConfig) {
        mod.missingConfig=true;
        delete add.missingConfig;
    }
    for (let i in add) {
        if (base[i]) {
            base[i].proficiency = proficiencyMerge(base[i].proficiency, add[i].proficiency);
        } else {
            base[i] = add[i];
        }
    }
    return base;
}

function proficiencyBonus(pBonus, proficiency) {
    switch (proficiency) {
        default:
            return 0;
        case "half":
            return Math.trunc(pBonus/2);

        case "proficientplus":
        case "proficient":
            return pBonus;

        case "expert":
            return 2*pBonus;
    }
}

class Character {
    constructor(state, type, readOnly) {
        if (state) {
            this.state = state;
            this.readOnly=readOnly;
            if (!state.baseAbilities) {
                state.baseAbilities = {str:0, dex:0, con:0, int:0, wis:0, cha:0};
            }
            if (!state.gamesystem) {
                //console.log("set default gamesystem", campaign.defaultGamesystem);
                state.gamesystem = campaign.defaultGamesystem;
            }

            this.characterType = type||"players";
            this.computeValues();
            const levels = state.levels
            for (let i in levels) {
                const l=levels[i];
                delete l.savedState;
            }
            if (!readOnly) {
                this.checkUpdateNames();
            }
            this.fixupCoins();
        }
    }

    get gamesystem() {
        return this.state.gamesystem || "5e";
    }

    get is5E() {
        return this.gamesystem=="5e";
    }

    get is5E24() {
        return this.gamesystem=="5e24";
    }

    get isBF() {
        return this.gamesystem=="bf";
    }

    get raceText() {
        return this.is5E24?"Species":"Race";
    }

    fixupCoins() {
        const state = this.state;
        for (let c in stdvalues.coinNames) {
            if (state[c]) {
                if (!state.equipment) {
                    state.equipment={};
                }
                if (state.equipment[c]) {
                    state.equipment[c].quantity = (Number(state.equipment[c].quantity)||1)+Number(state[c]);
                } else {
                    state.equipment[c]={quantity:state[c], coin:true, coinType:c, displayName:stdvalues.coinNames[c]};
                }
                delete state[c];
            }
        }
    }

    checkUpdateNames() {
        const s = this.state;
        const set = {};
        let needSet = false;

        if (!this.displayName) {
            // not a complete character
            return;
        }
        if (this.is5E && !s.raceDisplayName) {
            const r = campaign.getRaceInfo(s.race);
            if (r) {
                set.raceDisplayName = r.displayName;
                needSet = true;
            }
        }

        if (!s.originDisplayName) {
            const originDisplayName = this.getOriginDisplayName();
            if (originDisplayName) {
                set.originDisplayName = originDisplayName;
                needSet=true;
            }
        }

        if (!s.backgroundDisplayName) {
            const b = campaign.getBackgroundInfo(s.background);
            if (b) {
                set.backgroundDisplayName = b.displayName;
                needSet = true;
            }
        }
        if (!s.classDisplayNames) {
            const classDisplayNames=[];
            let skipClass = false;
            let level = 0;

            for (let i in s.classes) {
                const clsInfo = s.classes[i];
                const c = campaign.getClassInfo(clsInfo.cclass);
                level += (clsInfo.level||0);
                if (!c) {
                    skipClass=true;
                } else {
                    classDisplayNames.push(c.displayName);
                }
            }
            if (!skipClass && classDisplayNames.length) {
                needSet = true;
                set.classDisplayNames = classDisplayNames.join("/");
                set.level = level
            }
        }

        if (!this.state.tokenArt) {
            const tokenArt = findUrlArtwork(this.state.imageURL);
            if (tokenArt) {
                this.state.tokenArt=tokenArt;
                this.state.artList=[tokenArt];
            }
        }

        if (needSet) {
            this.setProperty(set);
        }
    }

    partialComputeValues(skipmod) {
        const t=this;
        const abilities = {};
        const skills = {};
        const vulnerable={};
        const resist={};
        const immune={};
        const conditionImmune={};
        const languages={};
        const armor={};
        const weapons={};
        const tools={};
        const senses={};
        let speed={};
        let proficiency=0;
        let level = 0;
        let hpMod = 0;
        let maxhp=0;
        let acBonus=0;
        let noArmorBonus=0;
        let noArmorShieldBonus=0;
        let lightArmorBonus=0;
        let mediumArmorBonus=0;
        let heavyArmorBonus=0;
        let anyArmorBonus=0;
        let speedBonus=0;
        let damageBonus=0;
        let noArmorSpeedBonus=0;
        let noArmorShieldSpeedBonus=0;
        let lightArmorSpeedBonus=0;
        let mediumArmorSpeedBonus=0;
        let spellAttackBonus=0;
        let spellDCBonus=0;
        let savingThrowBonus=0;
        let savingThrowAbilityBonus={};
        let skillCheckBonus=0;
        let skillCheckAbilityBonus={};
        let perceptionBonus=0;
        let initiativeBonus=0;
        const initiativeAbilityBonus={};
        const namedValues={};
        let unarmedAttacks = [];
        let mediumArmorMax=2;
        let extraAttacks = 0;
        let dueling = 0;
        let dualACBonus=0;
        let attuneCount = 0;
        let ritualCaster=false;
        let attuneSaveBonus=false;
        let spellLevelAdvance={};
        let addSpellSlots = [0,0,0,0,0,0,0,0,0];
        let addPactSlots = 0;
        let sizeAdjust=0;
        let customMods = {};
        let initiativeDice=[], saveDice=[], skillDice=[], attackDice=[], spellDice=[], abilitiesDice={};
        const modifiers = Object.assign({}, this.state.modifiers||{});
        let baseACs = [{ac:10, ability:["dex"], allowShield:true}]; // include default AC
        const shape = this.state.shape;

        stdvalues.abilities.forEach(function(ability){
            const mods = Number(t.state.baseAbilities["mod"+ability]||0);
            abilities[ability] = {
                score:(t.state.baseAbilities[ability]||10)+mods,
                mods:mods,
                max:Number(t.state.baseAbilities["max"+ability]||20),
            };
        });

        stdvalues.skillVals.forEach(function(skill){
            skills[skill.skill] = {
                skill:skill.skill,
                modifier:0,
                ability:skill.mod
            }
        });
        const extraSkills = this.extraSkills;
        for (let i in extraSkills) {
            skills[i] = {
                skill:i,
                modifier:0,
                ability:extraSkills[i]
            }
        }

        // setup level for modifiers that depend on level
        if (!this.level) {
            this.level = this.state.level;
        }
        this.getAllModifiers(modifiers);

        let expertProficient;

        for (let i in modifiers) {
            if (i == skipmod) {
                continue;
            }
            const mod = modifiers[i];

            ritualCaster = ritualCaster || mod.ritualCaster;

            if (!shape?.excludeProficiency) {
                if (mod.abilityScores) {
                    for (let x in mod.abilityScores) {
                        const am = mod.abilityScores[x];
                        const ab = abilities[x];

                        if (ab) {
                            ab.score += (am.modifier||0);
                            ab.mods += (am.modifier||0);
                            ab.minValue = Math.max(ab.minValue||0, am.minValue||0 );
                            ab.max = Math.max(ab.max||0, am.maxValue||0 );
                            ab.proficiency = proficiencyMerge(ab.proficiency, am.proficiency);
                        }
                    }
                }

                if (mod.skills) {
                    for (let x in mod.skills) {
                        const s = mod.skills[x];

                        if (skills[x]) {
                            skills[x].modifier += (s.modifier||0);
                            skills[x].proficiency = proficiencyMerge(skills[x].proficiency, s.proficiency);
                        }
                    }
                }

                if (mod.checks) {
                    for (let x in mod.checks) {
                        const s = mod.checks[x];

                        abilities[x].checkProficiency = proficiencyMerge(abilities[x].checkProficiency, s.proficiency);
                    }
                }


                if (mod.tools) {
                    for (let x in mod.tools) {
                        const t = mod.tools[x];
                        let proficiency = "proficient";
                        if ((typeof t == "object") && t.proficiency) {
                            proficiency = t.proficiency;
                        }

                        if (x=="All Proficient") {
                            expertProficient = true;
                        } else if (tools[x]) {
                            tools[x].proficiency = proficiencyMerge(tools[x].proficiency, proficiency);
                        } else {
                            tools[x] = {proficiency};
                        }
                    }
                }

                if (mod.baseACs && mod.baseACs.length) {
                    baseACs = baseACs.concat(mod.baseACs);
                }

                if (mod.vulnerable) {
                    Object.assign(vulnerable, mod.vulnerable);
                }
                if (mod.resist) {
                    Object.assign(resist, mod.resist);
                }
                if (mod.immune) {
                    Object.assign(immune, mod.immune);
                }
                if (mod.conditionImmune) {
                    Object.assign(conditionImmune, mod.conditionImmune);
                }

                if (mod.languages) {
                    Object.assign(languages, mod.languages);
                }

                if (mod.armor) {
                    Object.assign(armor, mod.armor);
                }

                if (mod.weapons) {
                    Object.assign(weapons, mod.weapons);
                }

                if (mod.senses) {
                    for (let x in mod.senses) {
                        const s = mod.senses[x];
                        const cs = senses[x];
    
                        if (!cs) {
                            senses[x] = Object.assign({},s);
                        } else {
                            if (s.number) {
                                cs.number = Math.max(cs.number||0, s.number);
                            }
                        }
                    }
                }
            }

            if (mod.speed) {
                for (let x in mod.speed) {
                    const s = mod.speed[x];
                    const cs = speed[x];

                    if (!cs) {
                        speed[x] = Object.assign({},s);
                    } else {
                        if (s.number) {
                            cs.number = Math.max(cs.number||0, s.number);
                        }
                        if (s.walking) {
                            cs.walking=true;
                        }
                    }
                }
            }

            if (mod.attunement) {
                attuneCount += (mod.attunement.extra||0);
                attuneSaveBonus |= (mod.attunement.saveBonus||0);
            }

            if (mod.spellLevelAdvance) {
                for (let i in mod.spellLevelAdvance) {
                    spellLevelAdvance[i] = (spellLevelAdvance[i]||0)+mod.spellLevelAdvance[i];
                }
            }

            addPactSlots += (mod.pactSlots||0);
            if (mod.slots) {
                for (let i=0; i<9; i++) {
                    addSpellSlots[i] += (mod.slots[i]||0)
                }
            }

            spellAttackBonus += mod.spellAttackBonus||0;
            spellDCBonus += mod.spellDCBonus||0;
            if (!shape?.excludeProficiency) {
                savingThrowBonus += mod.savingThrowBonus||0;
                mergeAbilityBonus(savingThrowAbilityBonus, mod.savingThrowBonusAbilities);
                skillCheckBonus += mod.skillCheckBonus||0;
                mergeAbilityBonus(skillCheckAbilityBonus, mod.skillCheckBonusAbilities);
                perceptionBonus += mod.perceptionBonus||0;
                initiativeBonus += mod.initiativeBonus||0;
                if (mod.initiativeAbilityBonus) {
                    Object.assign(initiativeAbilityBonus, mod.initiativeAbilityBonus);
                }
            }
            proficiency += mod.proficiencyBonus||0;

            if (mod.unarmedAttacks) {
                unarmedAttacks = unarmedAttacks.concat(mod.unarmedAttacks);
            }
            extraAttacks = Math.max(mod.extraAttack || 0, extraAttacks);
            dueling += mod.dueling||0;
            dualACBonus += mod.dualACBonus||0;

            damageBonus += mod.damageBonus||0;
    
            hpMod += (mod.hpMod||0);

            if (mod.initiativeDice) {
                initiativeDice = initiativeDice.concat(mod.initiativeDice);
            }
            if (mod.saveDice) {
                saveDice = saveDice.concat(mod.saveDice);
            }
            if (mod.skillDice) {
                skillDice = skillDice.concat(mod.skillDice);
            }
            if (mod.attackDice) {
                attackDice = attackDice.concat(mod.attackDice);
            }
            if (mod.spellDice) {
                spellDice = spellDice.concat(mod.spellDice);
            }
            if (mod.abilitiesDice) {
                for (let abName in mod.abilitiesDice) {
                    abilitiesDice[abName] = (abilitiesDice[abName]||[]).concat(mod.abilitiesDice[abName])
                }
            }
            if (mod.sizeAdjust) {
                sizeAdjust += mod.sizeAdjust;
            }
            if (mod.customMods) {
                for (let c in mod.customMods) {
                    customMods[c] = (customMods[c]||"")+","+mod.customMods[c];
                }
            }

            if (mod.skillAlt) {
                for (let sa of mod.skillAlt) {
                    for (let s in sa.skills) {
                        const skill = skills[s];
                        if (skill) {
                            skill.alts = Object.assign(skill.alts||{}, sa.abilities);
                        }
                    }
                }
            }
        }

        if (attuneSaveBonus) {
            let numAttune=0;
            const attuned = this.getProperty("attuned")||{};

            const e = this.state.equipment;

            for (let i in e) {
                if (attuned[i]) {
                    numAttune++;
                }
            }
            savingThrowBonus+=numAttune;
        }

        if (expertProficient && !shape?.excludeProficiency) {
            for (let i in tools) {
                const t = tools[i];
                if (["proficient", "proficientplus"].includes(t.proficiency)) {
                    t.proficiency = "expert";
                }
            }
        }

        for (let i in this.state.classes) {
            const c = this.state.classes[i] || {};
            level += c.level||0;

            const cls = campaign.getClassInfo(c.cclass);
            if (cls && cls.displayName) {
                namedValues[cls.displayName.toLowerCase().trim()+" level"] = c.level||0;
            }
            for (let l=0; l<c.level; l++) {
                const h = c.hp[l] || (Number(c.faces)/2+1);
                maxhp += Number(h);
            }
        }

        if (level > 0) {
            proficiency += Math.trunc((level-1)/4) +2;
        }

        attuneCount += this.isBF?proficiency:3;
        
        if (shape?.excludeProficiency) {
            const {getProficiency} = require("../src/rendermonster.jsx");
            proficiency = getProficiency(shape);
        }


        if (initiativeAbilityBonus.prof2) {
            initiativeBonus+= (2*proficiency);
        } else if (initiativeAbilityBonus.prof) {
            initiativeBonus+= (proficiency);
        }

        stdvalues.abilities.forEach(function(ability){
            const a = abilities[ability];
            const maxVal = a.max||20;
            if (a.score > maxVal) {
                a.score = maxVal;
            }
            if (a.score < a.minValue) {
                a.score = a.minValue;
            }
            a.modifier = Math.floor(a.score/2)-5;
        });

        if (shape) {
            for (let aname of shape.shapeAbilities ?? stdvalues.defaultShapeAbilities) {
                const a= abilities[aname];
                a.score = shape[aname]||10;
                a.modifier = Math.floor(a.score/2)-5;
            }
        }

        stdvalues.abilities.forEach(function(ability){
            const a = abilities[ability];
            let stbonus = 0;
            for (let x in savingThrowAbilityBonus[ability]) {
                if (x=="all") {
                    stbonus += savingThrowAbilityBonus[ability][x];
                } else if (abilities[x].modifier> 0) {
                    stbonus += (abilities[x].modifier);
                }
            }
            a.spellSave = a.modifier + proficiencyBonus(proficiency, a.proficiency)+savingThrowBonus+stbonus;
            if (initiativeAbilityBonus[ability]) {
                if (a.modifier >0) {
                    initiativeBonus+=a.modifier;
                }
            }
        });

        for (let i in skills) {
            const s = skills[i];

            let scbonus = skillCheckBonus;
            for (let x in skillCheckAbilityBonus[i]) {
                if (x=="all") {
                    scbonus += skillCheckAbilityBonus[i][x];
                } else if (abilities[x].modifier> 0) {
                    scbonus += (abilities[x].modifier);
                }
            }

            const ability = abilities[s.ability];

            s.proficiency = proficiencyMerge(s.proficiency, ability.checkProficiency);
            s.modifier = s.modifier + ability.modifier + proficiencyBonus(proficiency, s.proficiency) + scbonus;
        }

        const acAbilities={};
        const namedUsageMax = {luck:5};
        for (let i in modifiers) {
            if (i == skipmod) {
                continue;
            }
            const mod = modifiers[i];

            acBonus += (calcAcBonus(mod.acBonus)+ calcAbilityBonus(mod.allArmorAbilityBonus));
            noArmorBonus += (calcAcBonus(mod.noArmorBonus) + calcAbilityBonus(mod.noArmorAbilityBonus));
            noArmorShieldBonus += (calcAcBonus(mod.noArmorShieldBonus) + calcAbilityBonus(mod.noArmorShieldAbilityBonus));
            lightArmorBonus += (mod.lightArmorBonus||0 + calcAbilityBonus(mod.lightArmorAbilityBonus));
            mediumArmorBonus += (mod.mediumArmorBonus||0 + calcAbilityBonus(mod.mediumArmorAbilityBonus));
            heavyArmorBonus += (mod.heavyArmorBonus||0 + calcAbilityBonus(mod.heavyArmorAbilityBonus));
            anyArmorBonus += (mod.anyArmorBonus||0 + calcAbilityBonus(mod.anyArmorAbilityBonus));

            speedBonus +=mod.speedBonus||0;
            noArmorSpeedBonus+=mod.noArmorSpeedBonus||0;
            noArmorShieldSpeedBonus+=mod.noArmorShieldSpeedBonus||0;
            lightArmorSpeedBonus+=mod.lightArmorSpeedBonus||0;
            mediumArmorSpeedBonus+=mod.mediumArmorSpeedBonus||0;
            mediumArmorMax = Math.max(mediumArmorMax || 0, mod.mediumArmorMax ||0);

            function calcAcBonus(bonus) {
                if (typeof(bonus)==="string") {
                    if (!acAbilities[bonus]) {
                        acAbilities[bonus]=true;
                        return abilities[bonus].modifier;
                    }
                    return 0;
                }
                return bonus || 0;
            }

            function calcAbilityBonus(aca) {
                let bonus=0;
                for (let a in aca) {
                    if (a=="proficiency") {
                        bonus+=proficiency;
                    } else {
                        bonus+=abilities[a].modifier;
                    }
                }
                return bonus;
            }

            for (let i in mod.levelValues) {
                namedValues[i] = Math.max(namedValues[i]||0, mod.levelValues[i]||0);
            }
        }
        const walk = speed.walk;
        if (walk && walk.number) {
            const wn = walk.number
            for (let i in speed) {
                const sp = speed[i];
                if (sp.walking) {
                    sp.number = Math.max(sp.number||0, wn);
                }
            }
        }

        if (shape) {
            for (let ms in shape.skill) {
                const cs = stdvalues.monsterSkillsMap[ms];
                if (!skills[cs]) {
                    skills[cs]={modifier:0};
                }
                skills[cs].modifier = Math.max(Number(shape.skill[ms]||0), skills[cs].modifier);
            }

            for (let ms in shape.save) {
                abilities[ms].spellSave = Math.max(abilities[ms].spellSave, Number(shape.save[ms]||0));
            }
            
            speed = computeSpeedFromShape(shape.speed || "");
        }

        for (let i in skills) {
            const s  = skills[i];
            namedValues[i.toLowerCase()] = s.modifier;
            namedValues[i.toLowerCase()+"1"] = Math.max(1, s.modifier);
        }

        for (let i in abilities) {
            const a  = abilities[i];
            namedValues[i.toLowerCase()] = a.modifier;
            namedValues[i.toLowerCase()+"1"] = Math.max(1, a.modifier);
        }
        namedValues.proficiency= proficiency;
        namedValues.level = level;

        if (this.conditions) {
            for (let c in this.conditions) {
                const cond = this.conditions[c];
                namedValues["condition."+c.toLowerCase()]=cond.level||1
            }
        }

        for (let i in modifiers) {
            if (i == skipmod) {
                continue;
            }
            const mod = modifiers[i];

            for (let uv in mod.usageValues) {
                const uvals = mod.usageValues[uv];
                for (let x in uvals) {
                    const uv = uvals[x];
                    if (uv.usage.usageName) {
                        const name = uv.usage.usageName.toLowerCase();
                        const max = this.getUsageMax(uv.usage, uv.level-1, abilities, false,proficiency, namedValues);
                        namedUsageMax[name] = Math.max(namedUsageMax[name]||0, max||0);
                    }
                }
            }
        }
        for (let i in modifiers) {
            if (i == skipmod) {
                continue;
            }
            const mod = modifiers[i];

            for (let un in mod.usageBonuses) {
                const uval = mod.usageBonuses[un];
                const name = un.toLowerCase();
                namedUsageMax[name] = (namedUsageMax[name]||0)+uval;
                if (namedValues[name]!=null) {
                    namedValues[name] += uval;
                }
            }
        }
        for (let i in modifiers) {
            if (i == skipmod) {
                continue;
            }
            const mod = modifiers[i];
            if (mod.maxHP) {
                const dice = getDiceFromString(mod.maxHP,0,true,namedValues);
                hpMod += dice.bonus;
            }
        }

        maxhp = Math.max(0,maxhp + hpMod+ (level * (Math.floor(abilities.con.score/2)-5)));
        namedValues["max hp"]=maxhp||0;

        return {
            abilities,
            skills,
            vulnerable,
            resist,
            immune,
            conditionImmune,
            languages,
            armor,
            weapons,
            tools,
            senses,
            speed,
            spellAttackBonus,
            proficiency,
            level,
            maxhp,
            acBonus,
            noArmorBonus,
            noArmorShieldBonus,
            lightArmorBonus,
            mediumArmorBonus,
            heavyArmorBonus,
            anyArmorBonus,
            speedBonus,
            noArmorSpeedBonus,
            noArmorShieldSpeedBonus,
            lightArmorSpeedBonus,
            mediumArmorSpeedBonus,
            spellDCBonus,
            perceptionBonus,
            initiativeBonus,
            unarmedAttacks,
            mediumArmorMax,
            extraAttacks,
            dueling,
            dualACBonus,
            baseACs,
            damageBonus,
            attuneCount,
            ritualCaster,
            spellLevelAdvance,
            addSpellSlots,
            addPactSlots,
            namedValues,
            namedUsageMax,
            hpMod,
            initiativeDice,
            saveDice,
            skillDice,
            attackDice,
            spellDice,
            abilitiesDice,
            sizeAdjust,
            savingThrowBonus,
            customMods
        };
    }

    computeValues() {
        const {
            abilities,
            skills,
            vulnerable,
            resist,
            immune,
            conditionImmune,
            languages,
            armor,
            weapons,
            tools,
            senses,
            speed,
            spellAttackBonus,
            proficiency,
            level,
            maxhp,
            acBonus,
            noArmorBonus,
            noArmorShieldBonus,
            lightArmorBonus,
            mediumArmorBonus,
            heavyArmorBonus,
            anyArmorBonus,
            speedBonus,
            noArmorSpeedBonus,
            noArmorShieldSpeedBonus,
            lightArmorSpeedBonus,
            mediumArmorSpeedBonus,
            spellDCBonus,
            perceptionBonus,
            initiativeBonus,
            extraAttacks,
            unarmedAttacks,
            mediumArmorMax,
            dueling,
            dualACBonus,
            baseACs,
            damageBonus,
            attuneCount,
            ritualCaster,
            spellLevelAdvance,
            addSpellSlots,
            addPactSlots,
            namedValues,
            namedUsageMax,
            hpMod,
            initiativeDice,
            saveDice,
            skillDice,
            attackDice,
            spellDice,
            abilitiesDice,
            sizeAdjust,
            savingThrowBonus,
            customMods
        } = this.partialComputeValues();
        const shape=this.shape;

        this.level = level;
        this.maxHP=maxhp;
        if (!shape && (this.state.hp > maxhp)){
            this.state.hp = maxhp;
        }
        this.hpMod = hpMod;
        this.senses=senses;
        this.abilities = abilities;
        this.skills = skills;
        this.proficiency = proficiency;
        this.damageBonus = damageBonus;
        this.attuneCount = attuneCount;
        this.spellLevelAdvance = spellLevelAdvance;
        this.addSpellSlots = addSpellSlots;
        this.addPactSlots = addPactSlots;
        this.namedValues = namedValues;
        this.namedUsageMax = namedUsageMax;
        this.allSavingThrowBonus=savingThrowBonus;
        this.customMods = customMods;

        this.initiativeDice=initiativeDice;
        this.saveDice=saveDice;
        this.skillDice=skillDice;
        this.attackDice=attackDice;
        this.spellDice=spellDice;
        this.abilitiesDice=abilitiesDice;

        this.sizeAdjust=sizeAdjust;

        //console.log("values", namedValues, namedUsageMax);

        this.vulnerable = Object.keys(vulnerable).sort().join(", ")||"";
        this.selectedVulnerable = vulnerable;
        this.resist = Object.keys(resist).sort().join(", ")||"";
        this.selectedResist = resist;
        this.immune = Object.keys(immune).sort().join(", ")||"";
        this.selectedImmune = immune;
        this.conditionImmune = Object.keys(conditionImmune).sort().join(", ")||"";
        this.selectedConditionImmune = conditionImmune;
        this.languages = Object.keys(languages).sort().join(", ")||"";
        this.selectedLanguages = languages;
        this.armorList = Object.keys(armor);
        this.armor = this.armorList.sort().join(", ")||"";
        this.selectedArmor = armor;
        this.weaponsList = Object.keys(weapons);
        this.weapons = this.weaponsList.sort().join(", ")||"";
        this.selectedWeapons = weapons;
        this.tools = Object.keys(tools).sort().join(", ")||"";
        this.selectedTools = tools;

        this.spellAttackBonus=spellAttackBonus;
        this.spellDCBonus = spellDCBonus;

        this.acBonus=acBonus;
        this.noArmorBonus=noArmorBonus;
        this.noArmorShieldBonus=noArmorShieldBonus;
        this.lightArmorBonus =lightArmorBonus;
        this.mediumArmorBonus =mediumArmorBonus;
        this.heavyArmorBonus =heavyArmorBonus;
        this.anyArmorBonus =anyArmorBonus;

        this.baseACs = baseACs;
        const armorInfo = this.getArmorInfo();
        this.mediumArmorMax = mediumArmorMax;
        this.dualACBonus = dualACBonus;
        this.ac = this.computeAC(armorInfo);

        this.speedBonus=speedBonus;
        this.noArmorSpeedBonus=noArmorSpeedBonus;
        this.noArmorShieldSpeedBonus=noArmorShieldSpeedBonus;
        this.lightArmorSpeedBonus=lightArmorSpeedBonus;
        this.mediumArmorSpeedBonus=mediumArmorSpeedBonus;
        this.speed=this.computeSpeed(armorInfo, speed);

        this.unarmedAttacks = unarmedAttacks;
        this.dueling = dueling;
        this.extraAttacks = extraAttacks;
        this.ritualCaster = ritualCaster;

        this.passiveInvestigate=0;
        this.passiveInsight=0;
        this.passive = 10 + this.skills["Perception"].modifier + perceptionBonus;
        if (shape) {
            const {getPassive} = require('../src/rendermonster.jsx');
            this.baseSize = shape.size;
            this.passive = Math.max(getPassive(shape), this.passive);
        } else {
            if (this.is5E) {
                this.baseSize = this.state.size;
            } else {
                this.baseSize = "M";
            }
        }
        this.passiveInvestigate = 10 + this.skills["Investigation"].modifier;
        this.passiveInsight = 10 + this.skills["Insight"].modifier;
        this.initiativeBonus = this.abilities["dex"].modifier + proficiencyBonus(proficiency, this.abilities["dex"].checkProficiency) + initiativeBonus;

        this.computeSpellCasting();
        //console.log("computed state", this);
    }

    get size() {
        if (!this.sizeAdjust) {
            return this.baseSize;
        }
        const {sizeList} = stdvalues;
        const size=this.baseSize;
        let pos = sizeList.findIndex(function(f){return f.value==size});
        if (pos <0) {
            pos = 2; // medium size if not found
        }
        pos += this.sizeAdjust;
        if (pos <= 0) {
            return "T"; // tiny is smallest
        }
        if (pos >= sizeList.length) {
            return "G"; // Gargantuan is largest
        }
        return sizeList[pos].value;
    }

    get race() {
        return campaign.getRaceInfo(this.state.race)?.name;
    }

    get baserace() {
        return this.state.baserace;
    }

    get classDisplayNames() {
        return this.state.classDisplayNames;
    }

    get background() {
        return campaign.getBackgroundInfo(this.state.background)?.name;
    }

    get lineage() {
        return campaign.getCustom("Lineages", this.state.lineage)?.id;
    }

    get heritage() {
        return campaign.getCustom("Heritages", this.state.heritage)?.id;
    }

    get classes() {
        return this.state.classes||[];
    }

    getAbilityModifier(ability, options) {
        let amod = {};
        let i;
        if (!ability) {
            return {};
        }

        for (i in ability){
            if (i == 'choose'){
            } else {
                amod[i]={modifier:ability[i]};
            }
        }

        if (ability.choose) {
            let anum=1;

            for (let y in ability.choose) {
                const c = ability.choose[y];

                for (let x=0; x<c.count; x++) {
                    const a = options["ability"+anum];
                    if (a) {
                        if (amod[a]) {
                            amod[a].modifier += Number(c.amount||1);
                        } else {
                            amod[a] = {modifier:Number(c.amount||1)};
                        }
                    } else {
                        //console.log("ability option not specified", c, x, y);
                    }
                    anum++;
                }
            }
        }
        return amod||{};
    }

    getAllModifiers(modifiers) {
        const t=this;
        this.missingAnyConfig=false;

        // gamesystem
        {
            modifiers.gamesystem=this.getModFromGamesystem();
            this.missingGamesystemConfig = !!modifiers.gamesystem.missingConfig;
        }

        // origin
        {
            modifiers.origin=this.getModFromOrigin();
            this.missingOriginConfig = !!modifiers.origin.missingConfig;
            if (modifiers.origin.missingConfig) {
                this.missingAnyConfig = true;
            }
        }

        // background
        {
            modifiers.background=this.getModFromBackground();
            this.missingBackgroundConfig = !!modifiers.background.missingConfig;
            if (modifiers.background.missingConfig) {
                this.missingAnyConfig = true;
            }
        }

        //hero abilities
        {
            const mod = {};
            this.traverseFeatures(function (params) {
                const {feature, optionFeature, noOption, fid, options, underActive} = params
                const saveMissing = mod.missingConfig;
                t.updateModFromFeature(noOption?optionFeature:feature, mod, options, fid, false);
                if (underActive) {
                    mod.missingConfig = saveMissing;
                }
            }, ["hero"]);

            modifiers.hero=mod;
            this.missingHeroConfig = !!modifiers.hero.missingConfig;
        }

        // equipment
        {
            const itemMods = this.getItemMods();
            const attuned = this.getProperty("attuned")||{};
            const e = this.state.equipment;

            for (let i in modifiers) {
                if (i.startsWith("item")) {
                    delete modifiers[i];
                }
            }
            const mod = {};
            for (let i in e) {
                const it = e[i];
                if (it.equip && (!it.reqAttune || (it.reqAttune && attuned[i]))) {
                    this.getModFromItem(it,mod,itemMods);
                }
            }
            modifiers.itemsall = mod;
        }

        // classes
        {
            let missingClassConfig = false;
            const classes = this.state.classes||[];
    
            for (let ci=0; ci<20; ci++) {
                if (classes.length>ci) {
                    const clsInfo = classes[ci];
                    const c = campaign.getClassInfo(clsInfo.cclass);
    
                    let subclassInfo = campaign.getSubclassInfo(clsInfo.subclass);
                    if (!c || ((clsInfo.level>=(c.startSubclass||1)) && ((c.startSubclass||1)>0) && !subclassInfo)) {
                        missingClassConfig=true;
                    }
    
                    if (c) {
                        modifiers["class"+ci]=this.getModFromClass(c, clsInfo, (ci>0));
                        missingClassConfig = missingClassConfig || !!modifiers["class"+ci].missingConfig;
                    } else {
                        delete modifiers["class"+ci];
                    }
                } else {
                    delete modifiers["class"+ci];
                }
            }
            this.missingClassConfig = missingClassConfig;
            if (missingClassConfig) {
                this.missingAnyConfig = true;
            }
        }

        // get extension modifiers
        {
            const charOpts = this.characterOptions;
            const mod = {};
            const missingExtensionConfig=[];
            if (charOpts) {
                for (let x in charOpts) {
                    const o = charOpts[x];

                    this.traverseFeatures(function (params) {
                        const {feature, options, optionFeature, noOption, fid, underActive,level} = params
                        const saveMissing = mod.missingConfig;
                        t.updateModFromFeature(noOption?optionFeature:feature, mod, options, fid, false, level);
                        if (underActive) {
                            mod.missingConfig = saveMissing;
                        }
                    }, ["extensions"], o);
                    if (mod.missingConfig) {
                        if (o.required) {
                            missingExtensionConfig.push({name:o.name, extensionId:o.extensionId});
                        }
                        mod.missingConfig=false;
                    }
                    if (o.type=="counter") {
                        if (!mod.levelValues) {
                            mod.levelValues={};
                        }
                        const oname = (o.name||"").toLowerCase().trim();
                        mod.levelValues[oname] = Math.max(mod.levelValues[oname]||0, this.getExtensionVal(o.extension,o.name));
                    }
                }
                modifiers.extensions = mod;
                this.missingExtensionConfig=missingExtensionConfig;
            }
        }

        this.addConditionModifiers(modifiers);
    }

    checkActionForConfig(e, usageId,level) {
        return this.checkEffectsForConfig(e.action.enableFeature, usageId+".activef", level);
    }

    checkEffectsForConfig(e, usageId,level) {
        const mod={};
        const t=this;

        this.walkFeatures(function (params) {
            const {feature, options, optionFeature, noOption, fid, underActive, level} = params
            if (!underActive) {
                t.updateModFromFeature(noOption?optionFeature:feature, mod, options, fid, false, level);
            }
        }, [e], usageId, "", level, {}, "feature", e, null, false);
        return mod.missingConfig;
    }

    addConditionModifiers(modifiers) {
        const t=this;
        const conditions = this.conditions;
        if (conditions) {
            const mod = {};
            for (let i in conditions) {
                const c = conditions[i];
                if (c.acBonus) {
                    mod.acBonus = (mod.acBonus||0)+c.acBonus;
                }
                if (c.damageBonus) {
                    mod.damageBonus = (mod.damageBonus||0)+c.damageBonus;
                }
                if (c.maxHP) {
                    mod.hpMod = (mod.hpMod||0)+c.maxHP;
                }
            }

            //condition abilities
            this.traverseFeatures(function (params) {
                const {feature, optionFeature, noOption, fid, options, underActive} = params
                const saveMissing = mod.missingConfig;
                t.updateModFromFeature(noOption?optionFeature:feature, mod, options, fid, false);
                if (underActive) {
                    mod.missingConfig = saveMissing;
                }
            }, ["conditions"]);

            modifiers.conditions=mod;
            this.missingConditionsConfig = !!modifiers.conditions.missingConfig;
        }
    }

    getSpeedMod(speed) {
        const smod={};
    
        if (typeof speed != 'object'){
            if (!isNaN(speed)) {
                smod.walk = {number:Number(speed)};
            }
        } else {
            if (speed.walk && !isNaN(speed.walk)) {
                smod.walk = {number:Number(speed.walk)};
            }
            if (speed.fly && !isNaN(speed.fly)) {
                smod.fly = {number:Number(speed.fly)};
            }
            if (speed.swim && !isNaN(speed.swim)) {
                smod.swim = {number:Number(speed.swim)};
            }
            if (speed.climb && !isNaN(speed.climb)) {
                smod.climb = {number:Number(speed.climb)};
            }
            if (speed.burrow && !isNaN(speed.burrow)) {
                smod.burrow = {number:Number(speed.burrow)};
            }
        }
        return smod;
    }
    
    getModFromOrigin() {
        const mod = {};
        const t=this;
        let list;
        switch (this.gamesystem){
            case "5e24":
            case "5e":
                list=["race"];
                break;
            case "bf":
                list = ["heritage","lineage"];
                break;
        }
        this.traverseFeatures(function (params) {
            const {feature, options, optionFeature, noOption, fid, underActive, level} = params
            const saveMissing = mod.missingConfig;
            t.updateModFromFeature(noOption?optionFeature:feature, mod, options, fid, t.allowRaceOverride, level);
            if (underActive) {
                mod.missingConfig = saveMissing;
            }
        }, list);

        return mod;
    }

    getModFromGamesystem() {
        const mod = {abilityScores:{}};
        const {selectType,bfBonus1,bfBonus2} = (this.state.baseAbilities||{});

        if (this.isBF && (selectType == "random")) {
            if (bfBonus1==bfBonus2){
                mod.abilityScores[bfBonus2]={modifier:3};
            } else {
                if (bfBonus1) {
                    mod.abilityScores[bfBonus1]={modifier:1};
                }
                if (bfBonus2) {
                    mod.abilityScores[bfBonus2]={modifier:2};
                }
            }
        }

        return mod;
    }

    getModFromItem(it, mod, itemMods) {
        for (let i in itemMods) {
            const im = itemMods[i];
            if (im.acBonus && itemMatchFilter(it, im)) {
                mod.acBonus = (mod.acBonus||0)+im.acBonus;
            }
        }
        if (it.feature) {
            this.updateModFromFeature(it.feature, mod, {}, "");
        }
        if (it.extraFeatures) {
            for (let x in it.extraFeatures) {
                this.updateModFromFeature(it.extraFeatures[x], mod, {}, "");
            }
        }
    }

    getModFromBackground() {
        const mod = {};
        const t=this;
        this.traverseFeatures(function (params) {
            const {feature, options, optionFeature, noOption, fid, underActive, level} = params
            const saveMissing = mod.missingConfig;
            t.updateModFromFeature(noOption?optionFeature:feature, mod, options, fid, false,level);
            if (underActive) {
                mod.missingConfig = saveMissing;
            }
        }, ["background"]);
        return mod;
    }

    setRace(race, options) {
        const r = campaign.getRaceInfo(race);
        let size = "M";

        if (!options) {
            options = {};
        }
        if (r) {
            if (r.size == "V") {
                size=options.raceSize || "M";
            } else {
                size=r.size || "M";
            }
        }

        this.setProperty({
            race:race,
            raceDisplayName:r?.displayName,
            size:size,
            raceOptions:options
        });
        this.rest(true, true);
    }

    setOrigin(type, val) {
        const set = {};
        set[type] = val;
        this.setProperty(set);
        this.rest(true,true);
    }

    setBackground(background, options) {
        const b = campaign.getBackgroundInfo(background);

        if (!options) {
            options = {};
        }

        this.setProperty({
            background,
            backgroundDisplayName:b?b.displayName:null,
            backgroundOptions:options,
        });
        this.rest(true, true);
    }

    setHeroAbilities(heroAbilities, options) {
        if (!options) {
            options = {};
        }

        this.setProperty({
            heroAbilities,
            heroOptions:options,
        });
        this.rest(true, true);
    }

    getModFromClass(c, clsInfo, additionalClass) {
        const t=this;
        const level = clsInfo.level;
        const mod = {baseACs:[]};
        const subclassInfo = campaign.getSubclassInfo(clsInfo.subclass);
        const customLevels = getMergedCustomLevels(c, subclassInfo);
        let proficiencies;

        if (c.ritualCaster || (subclassInfo && subclassInfo.ritualCaster)) {
            mod.ritualCaster=true;

        }
        for (let i in customLevels) {
            const cl = customLevels[i];
            if (cl.name) {
                const lv = (cl.levelsCount||[])[level-1]||0;
                const featureName = cl.name.toLowerCase().trim();
                if (lv) {
                    switch (cl.attributeType) {
                        case "display":
                            if (!mod.levelValues) {
                                mod.levelValues={};
                            }
                            mod.levelValues[featureName] = Math.max(mod.levelValues[featureName]||0, lv);
                            break;
                        case "points":
                            if (!mod.usageValues) {
                                mod.usageValues={};
                            }
                            if (!mod.usageValues[featureName]) {
                                mod.usageValues[featureName]=[];
                            }
                            mod.usageValues[featureName].push({usage:{baseCount:lv, usageName:featureName}});
                            break;
                    }
                }
            }
        }

        if (additionalClass) {
            if (c.multiclassing) {
                proficiencies = c.multiclassing.proficienciesGained;
            }
        } else {
            proficiencies = c.startingProficiencies;
        }

        if (proficiencies) {
            this.updateModFromFeature(proficiencies, mod, clsInfo.options, "");
        }

        if (!additionalClass) {
            mod.abilityScores = {}
            for (let i in c.proficiency) {
                mod.abilityScores[c.proficiency[i]] = {proficiency:"proficient"};
            }
        }

        this.traverseFeatures(function (params) {
            const {feature, options, optionFeature, noOption, fid, level, underActive} = params
            const saveMissing = mod.missingConfig;
            t.updateModFromFeature(noOption?optionFeature:feature, mod, options, fid, false, level);
            if (underActive) {
                mod.missingConfig = saveMissing;
            } 
        }, ["class"], clsInfo.cclass);


        return mod;
    }

    updateModFromFeature(rf, mod, options, baseName, abilityOverride,level) {
        if (!this.checkRestriction(rf.restriction)){
            return;
        }
        getModFromFeature(rf, mod, options, baseName, abilityOverride,level, this.level)
    }

    setClasses(classes, levels) {
        let level=0;
        let maxHitDice = {},
            classDisplayNames=[];

        for (let ci=0; ci<20; ci++) {
            if (classes.length>ci) {
                const clsInfo = classes[ci];
                const c = campaign.getClassInfo(clsInfo.cclass);
                level += (clsInfo.level||0);

                if (c && !classDisplayNames.includes(c.displayName)) {
                    classDisplayNames.push(c.displayName);
                }

                if (c) {
                    maxHitDice[c.hd.faces] = (maxHitDice[c.hd.faces]||0)+clsInfo.level;
                }
            }
        }

        const setp = {
            classes,
            classDisplayNames:classDisplayNames.join("/"),
            level,
            maxHitDice
        };

        if (levels) {
            setp.levels = levels;
        }
 
        this.setProperty(setp);
    }

    computeSpellCasting() {
        const classes = this.classes;
        let spellSlots = null;
        let mcSpellLevel = 0;
        let mcPactLevel =0;
        let mcPactDownLevel = 0;
        let numClasses = 0;
        let numPactClasses = 0;
        let maxPactSlots=0;
        let pactLevel=0;
        let useSpellPoints=false;
        let slotSpellPoints = 0;
        let spellcastAbilityBonus = 0;

        for (let ci=0; ci<20; ci++) {
            if (classes.length>ci) {
                const clsInfo = classes[ci];
                const c = campaign.getClassInfo(clsInfo.cclass);
                if (c) {
                    let clevel = Math.min(20,clsInfo.level + (this.spellLevelAdvance[(c.displayName||"").toLowerCase()]||0));

                    if (c.spellcaster && c.abilityDC) {
                        spellcastAbilityBonus = Math.max(spellcastAbilityBonus, this.getAbility(c.abilityDC).modifier||0);
                    }
                    let subclassInfo = campaign.getSubclassInfo(clsInfo.subclass);
                    clsInfo.attributes = {};
                    if (subclassInfo?.spellcaster && subclassInfo?.abilityDC) {
                        spellcastAbilityBonus = Math.max(spellcastAbilityBonus, this.getAbility(subclassInfo.abilityDC).modifier||0);
                    }

                    if (c.useSpellPoints || (subclassInfo&&subclassInfo.useSpellPoints)) {
                        useSpellPoints=true;
                    }

                    let mcDelta = 0;
                    switch (c.spellcaster || (subclassInfo&&subclassInfo.spellcaster)) {
                        case "full":
                            mcDelta = clevel;
                            numClasses++;
                            Object.assign(clsInfo.attributes, stdvalues.fullSpellInfo[clevel-1]);
                            spellSlots = clsInfo.attributes.spellSlots;
                            break;

                        case "half":
                            mcDelta = Math.trunc(clevel/2);
                            numClasses++;
                            Object.assign(clsInfo.attributes, stdvalues.halfSpellInfo[clevel-1]);
                            spellSlots = clsInfo.attributes.spellSlots;
                            break;

                        case "halfplus":
                            mcDelta = Math.trunc((clevel+1)/2);
                            numClasses++;
                            Object.assign(clsInfo.attributes, stdvalues.halfPlusSpellInfo[clevel-1]);
                            spellSlots = clsInfo.attributes.spellSlots;
                            break;
        
                        case "third":
                            mcDelta = Math.trunc(clevel/3);
                            numClasses++;
                            Object.assign(clsInfo.attributes, stdvalues.thirdSpellInfo[clevel-1]);
                            spellSlots = clsInfo.attributes.spellSlots;
                            break;

                        case "pact":
                            mcPactLevel +=clevel;
                            mcPactDownLevel +=clevel;
                            numPactClasses++;
                            break;

                        case "halfpact":
                            mcPactLevel += Math.trunc(clevel/2+.9);
                            mcPactDownLevel += Math.trunc(clevel/2);
                            numPactClasses++;
                            break;

                        case "thirdpact":
                            mcPactLevel += Math.trunc(clevel/3+.9);
                            mcPactDownLevel += Math.trunc(clevel/3);
                            numPactClasses++;
                            break;

                        default:
                            break;
                    }
                    mcSpellLevel += mcDelta;

                    clsInfo.attributes.knownSpells = (c.knownSpells && c.knownSpells[clevel-1]) || (subclassInfo?.knownSpells && subclassInfo.knownSpells[clevel-1]) || 0;
                    clsInfo.attributes.knownRituals = (c.knownRituals && c.knownRituals[clevel-1]) || (subclassInfo?.knownRituals && subclassInfo.knownRituals[clevel-1]) || 0;
                    clsInfo.attributes.knownCantrips = (c.knownCantrips && c.knownCantrips[clevel-1]) || (subclassInfo?.knownCantrips && subclassInfo.knownCantrips[clevel-1]) || 0;
                }
            }
        }

        if (mcSpellLevel > 0) {
            if (mcSpellLevel >20){
                mcSpellLevel=20;
            }
            if (numClasses > 1) {
                spellSlots = stdvalues.multiClassSpellSlots[mcSpellLevel-1];
            }
        }

        if (numPactClasses) {
            if (mcPactDownLevel>20){
                mcPactDownLevel=20;
            }
            if (mcPactLevel>20){
                mcPactLevel=20;
            }
            maxPactSlots = (mcPactDownLevel>=1)?stdvalues.pactSpellInfo[mcPactDownLevel-1].pactSlots:0;
            if (numPactClasses == 1) {
                pactLevel = (mcPactLevel>=1)?stdvalues.pactSpellInfo[mcPactLevel-1].pactLevel:0;
            } else {
                pactLevel = (mcPactDownLevel>=1)?stdvalues.pactSpellInfo[mcPactDownLevel-1].pactLevel:0;
            }
            for (let ci=0; ci<20; ci++) {
                if (classes.length>ci) {
                    const clsInfo = classes[ci];
                    const c = campaign.getClassInfo(clsInfo.cclass);
                    if (c) {
                        let clevel = Math.min(20,clsInfo.level + (this.spellLevelAdvance[(c.displayName||"").toLowerCase()]||0));

                        let subclassInfo = campaign.getSubclassInfo(clsInfo.subclass);
        
                        switch (c.spellcaster || (subclassInfo&&subclassInfo.spellcaster)) {
                            case "pact":
                                clsInfo.attributes.maxSpellLevel = stdvalues.pactSpellInfo[clevel-1].maxSpellLevel;
                                break;
        
                            case "halfpact":{
                                clsInfo.attributes.maxSpellLevel = stdvalues.halfPactSpellInfo[clevel-1].maxSpellLevel;
                            }
                                break;
        
                            case "thirdpact":{
                                clsInfo.attributes.maxSpellLevel = stdvalues.thirdPactSpellInfo[clevel-1].maxSpellLevel;
                            }
                                break;
        
                            default:
                                break;
                        }
                    }
                }
            }
        }

        if (this.addPactSlots) {
            maxPactSlots += this.addPactSlots;
            if (!pactLevel) {
                pactLevel=1;
            }
        }

        let maxSpellCastLevel = 0;
        spellSlots = (spellSlots||[]).concat([]);
        for (let i=0; i<9; i++) {
            spellSlots[i] = (spellSlots[i]||0)+(this.addSpellSlots[i]||0);

            if (spellSlots[i]){
                maxSpellCastLevel=i+1;
            }
            slotSpellPoints += (spellSlots[i]*stdvalues.spellPointsByLevel[i+1]);
        }

        this.defaultUseSpellPoints = useSpellPoints;
        switch (this.state.spellcastingOverride) {
            case "spellpoints":
                useSpellPoints=true;
                break;
            case "spellslots":
                useSpellPoints=false;
                break;
        }

        this.maxSpellCastLevel = Math.max(maxSpellCastLevel, pactLevel);
        this.isSpellCaster = numClasses||numPactClasses;
        this.spellSlots = spellSlots;
        this.maxPactSlots = maxPactSlots;
        this.pactLevel = pactLevel;
        this.useSpellPoints = useSpellPoints;
        this.slotSpellPoints = slotSpellPoints;
        this.pactSpellPoints = maxPactSlots*(stdvalues.spellPointsByLevel[pactLevel]||0);
        this.spellcastAbilityBonus = spellcastAbilityBonus;
        this.namedValues["spellcasting mod"]=spellcastAbilityBonus;
    }

    get extensions() {
        if (!campaign.isDefaultCampaign()) {
            return extensionsFromSaved(campaign.getGameState().extensions||[]);
        }
        return extensionsFromSaved(this.state.extensions||[]);
    }

    set extensions(extensions) {
        this.setProperty("extensions",extensions);
    }

    get characterOptions() {
        const extensions = this.extensions;
        const options = [];

        for (let i of extensions) {
            const e = campaign.getExtensionInfo(i);
            if (e?.options) {
                for (const o of e.options){
                    const so = Object.assign({},o);
                    so.extensionId = e.name;
                    options.push(so);
                }
            }
        }
        return options;
    }

    get characterExtensionsOptions() {
        const extensions = this.extensions;
        const list = [];

        for (let i of extensions) {
            const e = campaign.getExtensionInfo(i);
            if (e?.options) {
                const eInfo = {extension:e, features:[]}
                for (const o of e.options){
                    const so = Object.assign({},o);
                    so.extensionId = e.name;
                    eInfo.features.push(so);
                }
                if (eInfo.features.length) {
                    list.push(eInfo);
                }
            }
        }
        return list;
    }

    get activeOptions() {
        return this.state.activeOptions||{};
    }

    findCharacterOptionIndex(extension, name) {
        for (const i in extension.options){
            const o = extension.options[i];
            if (o.name == name) {
                return i;
            }
        }
        return -1;
    }

    checkRestriction(restriction) {
        if (!restriction) {
            return true;
        }
        const {extension, activateName, min, max, characterOption,classes} = restriction;
        const restrictType=["custom","level","item"].includes(extension)?extension:extension?"custom":"none";

        let val=0;
        if (activateName && 
            !this.getFeatureUsage("ActionActivate."+(activateName||"").toLowerCase().trim()) &&
            !(this.shape && (activateName.toLowerCase().trim()=="shape change"))
        ) {
            return false;
        }
        
        switch (restrictType) {
            case "custom":{
                val = this.getExtensionVal(extension,characterOption);
                break;
            }
            case "level":{
                if (classes) {
                    const chclasses = this.state.classes;
                    for (let i in chclasses) {
                        const cls = chclasses[i];
                        const c = campaign.getClassInfo(cls.cclass);
                        const name = (c?.displayName||"").toLowerCase();
                        let baseName;
                        if (c?.variantBase) {
                            const cb = campaign.getClassInfo(c.variantBase)
                            baseName = ((cb && cb.displayName)||"").toLowerCase();
                        }

                        for (let rc of classes) {
                            const lc = rc.toLowerCase();
                            if ((name == lc)||(baseName==lc)) {
                                val += cls.level;
                            }
                        }
                    }
                } else {
                    val = this.level||0;
                }
                break;
            }
            case "item":{
                const eq = this.equipment;
                for (let i in eq) {
                    const it=eq[i];
                    if (it.equip && itemMatchFilter(it, restriction.itemFilter||{})) {
                        return true;
                    }
                }
                return false;
            }
            default:{
                return true;
            }
        }
        if ((val >= (min||0)) && ((!max&&min) || (val <= (max||0)))) {
            return true;
        }
        return false;
    }
    
    get extraSkills() {
        const extensions = this.extensions;
        const skills = {};

        for (let i of extensions) {
            const e = campaign.getExtensionInfo(i);
            if (e && e.skills) {
                for (let s of e.skills) {
                    skills[s.name]=s.ability;
                }
            }
        }
        return skills;
    }

    get removeSkills() {
        const extensions = this.extensions;
        let skills = [];

        for (let i of extensions) {
            const e = campaign.getExtensionInfo(i);
            if (e && e.removeSkills) {
                skills=skills.concat(e.removeSkills);
            }
        }
        return skills;
    }

    getExtensionAltSkills() {
        const extensions = this.extensions;
        const alts = {};

        for (let i of extensions) {
            const e = campaign.getExtensionInfo(i);
            if (e && e.skills) {
                for (let s of e.skills) {
                    for (let x in s.alts) {
                        if (!alts[x]) {
                            alts[x]=[];
                        }
                        alts[x].push(s.name);
                    }
                }
            }
        }
        return alts;
    }

    get allSkillsList() {
        const skillsList =stdvalues.skillsList.concat(Object.keys(this.extraSkills));
        const removeSkills = this.removeSkills;
        for (let s of removeSkills) {
            const pos = skillsList.indexOf(s);
            if (pos >=0) {
                skillsList.splice(pos,1);
            }
        }
        skillsList.sort(function (a,b) {return a.toLowerCase().localeCompare(b.toLowerCase())});
        return skillsList;
    }

    get allSkillsWithAbilities() {
        const extraSkills = this.extraSkills;
        const removeSkills = this.removeSkills;
        let skills = [];

        for (let i in extraSkills) {
            skills.push({mod:extraSkills[i], skill:i});
        }

        if (skills.length || removeSkills.length) {
            skills = stdvalues.skillVals.concat(skills);

            for (let s of removeSkills) {
                const pos = skills.findIndex(function (a) {return a.skill==s});
                if (pos >=0) {
                    skills.splice(pos,1);
                }
            }
            skills.sort(function (a,b){return (a.skill||"").toLowerCase().localeCompare((b.skill||"").toLowerCase())});
            return skills;
        }
        return stdvalues.skillVals;
    }

    get allLanguagesList() {
        const extensions = this.extensions;
        let languages = stdvalues.languagesList.concat([]);

        const allLang = [];
        const excludeLanguages={};

        for (let i in extensions) {
            const e = campaign.getExtensionInfo(extensions[i]);
            if (e?.languages) {
                const langs = e.languages.split(",").map(function (a){return a.trim()});
                for (let l of langs) {
                    if (!languages.includes(l)){
                        languages.push(l);
                    }
                }
            }
            if (e?.excludeLanguages) {
                e.excludeLanguages.map(function (l) {
                    excludeLanguages[l.toLowerCase()]=1;
                });
            }
        }
        for (let l of languages) {
            if (!excludeLanguages[l.toLowerCase()]) {
                allLang.push(l);
            }
        }

        return allLang;
    }

    set shape(monster) {
        this.setShapeWithHistory(monster);
    }

    get shape() {
        return this.state.shape;
    }

    set allowRaceOverride(v) {
        this.setProperty("allowRaceOverride", !!v);
    }

    get allowRaceOverride() {
        return this.state.allowRaceOverride;
    }

    setShapeWithHistory(monster,selectMonster) {
        const setProp = {shape:monster}
        if (monster) {
            const shapeHP = selectMonster?.shapeHP||"default";
            const mon = Object.assign({}, monster);
            setProp.shape=mon;

            switch (shapeHP) {
                default:
                case "default":
                    mon.curHP = Number(mon.hp.average)||1;
                    break;
                case "size":
                    mon.curHP = (sizeHPList[mon.size]||12)+this.proficiency;
                    break;
                case "keep":
                    mon.curHP=1;
                    break;
            }
            setProp.shapehp =setProp.shapemaxhp = mon.maxHP = mon.curHP;
            mon.shapeHP=shapeHP;
            mon.excludeProficiency = selectMonster?.excludeProficiency||false;
            mon.shapeAbilities = selectMonster?.shapeAbilities ?? stdvalues.defaultShapeAbilities;

            const hpDice = getDiceFromString(mon.hp.formula||"",0,true);
            setProp.shapeMaxHitDice = {};
            for (let d in hpDice) {
                if (d.startsWith("D")) {
                    setProp.shapeMaxHitDice[Number(d.substr(1))] = hpDice[d]||0;
                }
            }
            setProp.shapeHitDice = setProp.shapeMaxHitDice;

            let history = this.shapeHistory.concat([]);
            const pos = history.indexOf(monster.name);
    
            if (pos >= 0) {
                history.splice(pos, 1);
            }
            if (history.length > 5) {
                history.pop();
            }
            history.unshift(monster.name);
    
            setProp.shapeHistory = history;
    
        } else {
            setProp.shape=null;

            if (this.conditions && this.conditions["Shape Change"]) {
                const conditions = Object.assign({}, this.conditions);
                delete conditions["Shape Change"];
                if (Object.keys(conditions).length) {
                    setProp.conditions = conditions;
                } else {
                    setProp.conditions=null;
                }
            }
        }

        this.setProperty(setProp);
    }

    get shapeHistory() {
        return this.state.shapeHistory||[];
    }

    get sensesText() {
        return this.state.shape?(this.state.shape.senses || " "):null;
    }

    getModifier(name) {
        return (this.state.modifiers||{})[name] || {};
    }

    setModifier(name, mod) {
        const modifiers = Object.assign({}, this.state.modifiers||{});

        if (mod) {
            modifiers[name]=mod;
        } else {
            delete modifiers[name];
        }

        this.setProperty({
            modifiers:modifiers
        });
    }

    getAbilityD20Modifiers(name) {
        if (!name) {
            return null;
        }
        let aname =stdvalues.abilityLongNames[name.toLowerCase()];
        if (!aname) {
            let skill = this.skills[name];
            if (skill) {
                aname = skill.ability;
            }
        }
        if (aname) {
            return (this.abilitiesDice||{})[aname];
        }
        return this.skillDice;
    }

    getArmorInfo() {
        return this.state.armorInfo||{};
    }

    hasArmorInfo() {
        const armorInfo = this.state.armorInfo;
        if (!armorInfo) {
            return false;
        } 

        return (armorInfo.armor || armorInfo.armorExtraBonus);
    }

    computeAC(armorInfo) {
        let ai;
        let ac = 10;
        let noArmor = true;
        let baseACs = this.baseACs;

        if (this.state.shape) {
            let acinfo = Parser.acToStruc(this.state.shape.ac);

            return Number(acinfo.ac) + this.acBonus + this.noArmorShieldBonus + this.noArmorBonus;
        }

        if (armorInfo.armorItem) {
            ai = armorInfo.armorItem;
        }

        if (!ai) {
            if (armorInfo.armor == "Mage Armor") {
                baseACs = baseACs.concat([{ac:13, ability:["dex"], allowShield:true}]); // add mage armor to base ACs
            }
        } else {
            noArmor = false;
        }

        const noShield = !armorInfo.shieldItem;
        if (noArmor) {
            let bestAC = 0;
            for (let i in baseACs) {
                const bac = baseACs[i];
                if (bac.allowShield || noShield) {
                    let calcAC = bac.ac;
                    for (let x in (bac.ability||[])) {
                        calcAC += this.getAbility(bac.ability[x]).modifier||0;
                    }
                    for (let x in (bac.halfAbility||[])) {
                        calcAC += Math.floor((this.getAbility(bac.halfAbility[x]).modifier||0)/2);
                    }
                    if (calcAC > bestAC) {
                        bestAC = calcAC;
                    }
                }
            }

            ac = bestAC + this.noArmorBonus;
            if (noShield) {
                ac += this.noArmorShieldBonus;
            }
        } else {
            ac = ai.ac || 10;
            if (ai.type == "LA") {
                ac += (this.getAbility("dex").modifier||0) + this.lightArmorBonus;
            } else if (ai.type == "MA") {
                ac += Math.min(this.mediumArmorMax||2, this.getAbility("dex").modifier||0)+this.mediumArmorBonus;
            } else if (ai.type == "HA") {
                ac += this.heavyArmorBonus;
            }

            ac += this.anyArmorBonus;
        }

        if (this.dualACBonus) {
            const eq = this.equipment;
            let phw=false, ohw=false;

            for (let i in eq) {
                const it = eq[i];
                if (((it.dmg1 || it.weapon) && (it.equip=="OH"))) {
                    ohw=true;
                } else if (((it.dmg1 || it.weapon) && (it.equip=="PH"))) {
                    phw=true;
                }
            }
            if (ohw&&phw) {
                ac += this.dualACBonus;
            }
        }

        
        if (armorInfo.shieldItem) {
            ac += ((typeof armorInfo.shieldItem.ac == "number")?armorInfo.shieldItem.ac:2) +(armorInfo.shieldBonus||0);
        }
        ac += armorInfo.armorExtraBonus||0;
        ac += this.acBonus;

        return ac;
    }

    computeSpeed(armorInfo, baseSpeed) {
        const ai = armorInfo.armorItem;
        const noArmor = !ai;
        const noShield = !armorInfo.shieldItem;
        let speedBonus = this.speedBonus||0;
        const newSpeed = Object.assign({}, baseSpeed);

        if (noArmor) {
            speedBonus += (this.noArmorSpeedBonus+this.lightArmorSpeedBonus + this.mediumArmorSpeedBonus);
            if (noShield) {
                speedBonus += this.noArmorShieldSpeedBonus;
            }
        } else {
            if (ai.type == "LA") {
                speedBonus += (this.lightArmorSpeedBonus + this.mediumArmorSpeedBonus);
            } else if (ai.type == "MA") {
                speedBonus += this.mediumArmorSpeedBonus;
            }
        }

        if (speedBonus) {
            for (let i in newSpeed) {
                if (newSpeed[i] && newSpeed[i].number) {
                    newSpeed[i] = {number:Number(newSpeed[i].number)+speedBonus};
                }
            }
        }

        return newSpeed;
    }

    classInfo(cls) {
        for (let i in this.classes) {
            const c = this.classes[i];
            if (c.cclass == cls) {
                return c;
            }
        }
        return null;
    }

    get levels() {
        if (!this.state.levels) {
            const classes = this.classes;
            const levels = [{}];
    
            for (let i in classes) {
                const c = classes[i];
    
                for (let l=0; l<c.level; l++) {
                    levels.push({cclass:c.cclass, level:(l+1)});
                }
            }
            return levels;
        }
        return this.state.levels;
    }

    getMaxClassLevel(cclass) {
        let max = 0;
        const levels = this.levels;
        cclass = cclass && cclass.toLowerCase();

        for (let x in levels) {
            const level = levels[x];
            if (level.cclass && (level.cclass.toLowerCase() == cclass)) {
                if (!max || max.level < level.level) {
                    max = level;
                }
            }
        }
        return max;
    }

    setLevels(inlevels) {
        const classes = this.classes.concat([]);
        const levels = [{}];
        const mappedLevels = {};

        for (let i=1; i<inlevels.length; i++) {
            const l=inlevels[i];
            const c = mappedLevels[l.cclass.toLowerCase()];

            if (!c || (c.level < l.level)) {
                levels.push(l);
                mappedLevels[l.cclass.toLowerCase()] = l; 
            } else {
                console.log("out of order level", inlevels);
            }
        }
        // remove any unreferenced classes
        for (let i=classes.length-1; i>=0; i--) {
            const c = classes[i];
            if (!mappedLevels[c.cclass.toLowerCase()]) {
                classes.splice(i, 1);
            }
        }

        for (let i in mappedLevels) {
            const l = mappedLevels[i];
            let classIndex = -1;

            for (let x in classes) {
                const c = classes[x];
                if (c.cclass.toLowerCase() == i) {
                    classIndex = x;
                }
            }

            let setClass;

            if (classIndex >= 0) {
                setClass = Object.assign({}, classes[classIndex]);
                setClass.level = l.level;
                setClass.hp = setClass.hp.slice(0,l.level);
                classes[classIndex] = setClass; 
            } else {
                const classInfo = campaign.getClassInfo(l.cclass);
                setClass = {cclass:l.cclass, level:l.level, options:{}, faces:classInfo?classInfo.hd.faces:8};

                if (l.cclass == levels[1].cclass) {
                    setClass.hp=[setClass.faces];
                } else {
                    setClass.hp=[null];
                }
                classes.push(setClass);
            }
        }

        //console.log("setClasses", classes, levels);
        this.setClasses(classes, levels);
        this.rest(true, true);
    }

    get name() {
        return this.state.name;
    }

    set name(v) {
        throw new Error("cannot change name");
    }

    get displayName() {
        return this.state.displayName;
    }

    set displayName(v) {
        this.setProperty("displayName",v);
    }

    get playerName() {
        return this.state.playerName;
    }

    set playerName(v) {
        this.setProperty("playerName",v);
    }

    get isShared() {
        return !!this.state.shareCampaign;
    }

    get initiative() {
        return this.state.initiative;
    }

    set initiative(v) {
        this.setProperty("initiative", v);
    }

    get str() {
        return this.getAbility("str").score;
    }
    get dex() {
        return this.getAbility("dex").score;
    }
    get con() {
        return this.getAbility("con").score;
    }
    get int() {
        return this.getAbility("int").score;
    }
    get wis() {
        return this.getAbility("wis").score;
    }
    get cha() {
        return this.getAbility("cha").score;
    }

    get conditions() {
        return this.getProperty("conditions");
    }

    set conditions(v) {
        return this.setProperty("conditions",v);
    }

    removeCondition(c, all) {
        if (this.conditions) {
            const conditions = Object.assign({}, this.conditions);
            const cv = conditions[c];
            if (cv) {
                let deleted;
                if (cv.level && !all) {
                    const ncv = Object.assign({}, cv);
                    ncv.level--;
                    if (ncv.level) {
                        conditions[c]=ncv
                    } else {
                        deleted=true;
                    }
                } else {
                    deleted=true;
                }
                if (deleted) {
                    if (c=="Temp HP" && this.temphp) {
                        this.setProperty("temphp",0);
                    } else if ((c=="Shape Change")&&this.shape) {
                        this.shape=null;
                    }
                    if (conditions[c]?.spell) {
                        this.removeFeatureCompanions("spellmonster."+conditions[c].spell.spellId);
                    }

                    delete conditions[c];
                    this.cleanupCondition(c);
                }
                this.conditions = Object.keys(conditions).length?conditions:null;
            }
        }
    }

    getAbility(ability) {
        return this.abilities[ability] || {score:10, modifier:0, spellSave:0};
    }

    get hp() {
        if (this.state.shape && (this.state.shape.shapeHP != "keep")) {
            return this.state.shapehp;
        }
        return this.getProperty("hp") || 0;
    }

    get maxhp() {
        if (this.state.shape && (this.state.shape.shapeHP != "keep")) {
            return this.state.shapemaxhp;
        }
        return Number(this.maxHP) || 0;
    }

    get temphp() {
        return this.getProperty("temphp") || 0;
    }

    get xp() {
        return Number(this.state.xp||0);
    }

    set xp(n) {
        n = Number(n);
        if (n < 0) {
            n=0;
        }

        this.setProperty("xp", n);
    }

    get pactSlots() {
        return Number(this.state.pactSlots||0);
    }

    set pactSlots(n) {
        n = Number(n);
        if (n < 0) {
            n=0;
        }

        if (n > this.maxPactSlots) {
            n = this.maxPactSlots;
        }

        this.setProperty("pactSlots", n);
    }

    setCustomPoints(className, type, value) {
        this.setFeatureUsage(type.toLowerCase(), value)
        //this.setProperty("customPoints"+className.toLowerCase()+type, value);
    }

    getCustomPoints(className, type) {
        let fu = this.usage[type?.toLowerCase()];
        if (fu === undefined) {
            fu = this.state["customPoints"+(className||"").toLowerCase()+type];
        }
        return fu || 0;
    }

    get equipment() {
        return this.state.equipment||{};
    }

    set equipment(e) {
        this.setEquipment(e);
    }

    setEquipment(e, newAttuned) {
        const armorInfo = Object.assign({}, this.getArmorInfo());
        delete armorInfo.shieldItem;
        delete armorInfo.armorItem;

        for (let i in e) {
            const it = e[i];
            if (it.equip) {
                if (it.equip == "A") {
                    armorInfo.armorItem = it;
                } else if (it.equip && (it.type=="S")){
                    armorInfo.shieldItem = it;
                }
            }
        }
        const props = {equipment:e, armorInfo};
        if (newAttuned) {
            props.attuned = newAttuned;
        }

        this.setProperty(props);
    }

    mergeEquipment(newItems, added) {
        const {mergeItemList} = require('../src/items.jsx');
        this.equipment = mergeItemList(newItems, this.equipment, added, this.attuned);
    }

    getEquipmentItem(id) {
        const split = id.split(".");
        let e = this.equipment;
        let ret;
        for (let nid of split) {
            ret = e&&e[nid];
            e = ret?.contained;
        }
        return ret;
    }

    allowItemQuantity(e, id) {
        const r= (!e.container && !e.equip && (!id || !this.attuned[id])) || ((e.quantity??1) != 1);
        return r;
    }

    setEquipmentItem(itemNew, id, itemNew2, id2) {
        const attuned = this.attuned;
        const equipment = Object.assign({}, this.equipment);

        setEquipmentItem(equipment,itemNew, id, attuned);
        if (id2) {
            setEquipmentItem(equipment,itemNew2, id2, attuned);
        }
        this.equipment = equipment;
    }

    removeCreateItem(createId) {
        const equipment = Object.assign({}, this.equipment);
        let found;
        for (let i in equipment) {
            const it = Object.assign({},equipment[i]);
            for (let cid in it.extraFeatures) {
                if (cid.startsWith(createId)) {
                    if (it.extraFeatures[cid].newCreation) {
                        delete equipment[i];
                    } else {
                        it.extraFeatures = Object.assign({}, it.extraFeatures);
                        delete it.extraFeatures[cid];
                        if (!Object.keys(it.extraFeatures).length) {
                            it.reqAttune = it.origAttune||false;
                            delete it.origAttune;
                            delete it.extraFeatures;
                        }
                        equipment[i]=it;
                    }
            
                    found=true;
                    break;
                }   
            }
        }

        if (found) {
            this.equipment = equipment;
        }
    }

    getCarriedWeight() {
        const {getTotalWeight} = require("../src/items.jsx");

        const equipment = Object.assign({}, this.equipment);
        let weight=0;

        for (let i in equipment) {
            const e=equipment[i];
            if (isCarried(e)) {
                weight += getTotalWeight(e)*(e.quantity||1);
            }
        }
        return weight;
    }

    getCarryBase() {
        const multMap={
            T:0.5,
            S:1,
            M:1,
            L:2,
            H:4,
            G:8
        };

        let base = (this.str*5)* (multMap[this.size]||1);
        return base;
    }

    get attuned() {
        return this.getProperty("attuned")||{};
    }

    set attuned(attuned) {
        this.setEquipment(this.equipment, attuned);
    }

    get companions() {
        return this.state.companions||{};
    }

    get companionsList() {
        const comp = this.companions;
        const list = [];
        for (let i in comp) {
            list.push(comp[i]);
        }
        list.sort(function (a,b) {return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())});
        return list;
    }

    set companions(companions) {
        this.setProperty("companions", companions);
    }

    addCompanions(selList, usageId, selectMonster) {
        const {setupCompanion} = require("../src/rendermonster.jsx");

        const companions = Object.assign({}, this.companions);
        let foundNPC;

        for (let i in selList) {
            const m = campaign.getMonster(i);
            if (!m.npc) {
                for (let x=0; x<selList[i]; x++) {
                    const newm = Object.assign({},m);
                    newm.hp=Object.assign({},newm.hp);
                    if (!newm.unique) {
                        newm.hp.maxHP =m.hp.average;
                        newm.unique=true;
                    }
                    if (usageId) {
                        newm.fromFeature = usageId;
                    }
                    if (selectMonster?.adjustMon) {
                        newm.companion = true;
                        newm.cbase = selectMonster.cbase||0;
                        newm.cability = selectMonster.cability||null;
                        newm.cmultiple = selectMonster.cmultiple||0;
                        newm.class = selectMonster.class||null;
                    }
                    setupCompanion(newm, this);
                    newm.hp.curHP = (newm.hp.maxHP||1);
                    newm.id = campaign.newUid();
                    companions[newm.id] = newm;
                }
            } else {
                foundNPC=true;
            }
        }

        if (foundNPC) {
            displayMessage("NPCs with character sheets cannot be companions.")
        }
        this.companions=companions;
    }

    removeFeatureCompanions(usageId) {
        const companions = Object.assign({}, this.companions);
        let changed;
        for (let i in companions) {
            const m = companions[i];
            if (m.fromFeature==usageId) {
                changed=true;
                delete companions[i];
            }
        }
        if (changed) {
            this.companions=companions;
        }
    }

    GetAvailableSpellSlots(level) {
        return Number((this.state.availableSpellSlots||[])[level]||0);
    }

    SetAvailableSpellSlots(level, n) {
        n = Math.max(0, n);
        n = Math.min(n, this.spellSlots[level]);
        const newss = (this.state.availableSpellSlots||[]).concat([]);

        newss[level]=n;
        this.setProperty("availableSpellSlots", newss);
    }

    get availableSpellPoints() {
        return (this.state.availableSpellPoints||0);
    }

    get maxSpellPoints() {
        return (this.slotSpellPoints||0)+(this.pactSpellPoints||0);
    }

    set availableSpellPoints(points) {
        points = Math.max(0, points);
        points = Math.min(points, (this.slotSpellPoints||0)+(this.pactSpellPoints||0));

        this.setProperty("availableSpellPoints", points);
    }

    get hitDice() {
        if (this.state.shape && (this.state.shape.shapeHP != "keep")) {
            return this.state.shapeHitDice;
        }
        return this.state.hitDice||{};
    }

    setHitDice(faces, count) {
        const hitDice = Object.assign({}, this.hitDice);
        count = Number(count);
        if (count < 0) {
            count=0;
        }

        if (count > this.maxHitDice[faces]) {
            count = this.maxHitDice[faces];
        }
        hitDice[faces] = count;

        if (this.state.shape && (this.state.shape.shapeHP != "keep")) {
            this.setProperty("shapeHitDice", hitDice);
        } else {
            this.setProperty("hitDice", hitDice);
        }
    }

    get maxHitDice() {
        if (this.state.shape && (this.state.shape.shapeHP != "keep")) {
            return this.state.shapeMaxHitDice||{};
        } else {
            return this.state.maxHitDice||{};
        }
    }

    restFeatures(long) {
        const t=this;
        const fList = [];
        const eList = [];
        const cList = [];

        const classes = this.classes;

        for (let c in classes) {
            const clsInfo = classes[c] || {};
            const cls = campaign.getClassInfo(clsInfo.cclass);

            if (cls) {
                const subclassInfo = campaign.getSubclassInfo(clsInfo.subclass);
                const customLevels = getMergedCustomLevels(cls, subclassInfo);

                for (let x in customLevels) {
                    const cl = customLevels[x];
                    if ((cl.attributeType == "points") && (long || !cl.longRest)) {
                        cList.push({cclass:clsInfo.cclass, displayName:cls.displayName, name:cl.name});
                    }
                }
            }
        }

        this.traverseFeatures(function (params) {
            const {options, level}=params;
            let e = params.feature;
            if (params.type == "item") {
                const newFeature = itemFeatureUsage(e);
                if (newFeature) {
                    if (!eList.includes(params.id)) {
                        eList.push(params.id);
                    }
                }
            } else if (params.type == "itemextra") {
                const newFeature = itemFeatureUsage(e);
                if (newFeature) {
                    if (!eList.includes(params.id.id)) {
                        eList.push(params.id.id);
                    }
                }
            } else {
                let add = false;
                if (e.usage && (long || !e.usage.longRest) && !(e.usage.longRest <0) && (e.usage.restore != "none")) {
                    const usage=e.usage;
                    let max=0;

                    if (usage.calcCount) {
                        const c = t.replaceMetawords(usage.calcCount);
                        if (!isNaN(c)) {
                            max = Number(c);
                        }
                    } else if (usage.baseCount) {
                        max=usage.baseCount;
                    } else if (usage.levelsCount) {
                        max = usage.levelsCount[level-1];
                    } else if (usage.ability) {
                        max = t.getAbility(usage.ability).modifier+ (usage.abilityBonus||0);
                        if (max < 1) {
                            max=1;
                        }
                    } else if (usage.proficiency) {
                        max = t.proficiency+(usage.abilityBonus||0);
                        if (max < 1) {
                            max=1;
                        }
                    }

                    if (max) {
                        add=true;
                    }
                }
                if (resetFeatureSpells(e, params.fid, params.usageId, options)) {
                    add=true;
                }
                if (add) {
                    if (e.name || !params.typeValue) {
                        fList.push(e);
                    } else {
                        const ne = Object.assign({}, e);
                        ne.name = params.typeValue.displayName;
                        fList.push(ne);
                    }
                }
            }
        });

        return {equipmentList:eList, classColumns:cList, features:fList};

        function itemFeatureUsage(e) {
            const recoveryType = e.recoveryType||"day";

            if (e && 
                ((e.usage && e.usage.baseCount && (long || e.usage.restore=="short") && (e.usage.restore != "none")) || 
                 (long && e.castableSpells && recoveryType=="day")
                )
             ) {
                return true;
            }
        }
        
        function resetFeatureSpells(feature, baseName, optionsBase, options) {
            const selectedSpells = feature.pickedSpells||[];
            const castableSpells = feature.castableSpells||{};
            let add = false;
            for (let i in selectedSpells) {
                const s = selectedSpells[i];
                if (s.level) {
                    add=true;
                }
            }
            if (long || feature.recoveryType=="short") {
                for (let i in castableSpells) {
                    const s = castableSpells[i];
                    if (s.level) {
                        add=true;
                    }
                }
            }

            if (feature.spellPick && (long || feature.spellPick.recoveryType=="short")) {
                const spellPick = (options||{})[baseName+".spellPick"]||[];
                for (let i in spellPick) {
                    const s = spellPick[i];
                    if (s.level) {
                        add=true;
                    }
                }
            }
            return add;
        }
    }

    getUsageMax(usage,level,abilities, blanks, proficiency,namedValues) {
        let max = 0;
        if (!usage) {
            return max;
        }

        if (usage.calcCount) {
            const c = this.replaceMetawords(usage.calcCount, namedValues||null);
            if (!isNaN(c)) {
                max = Number(c);
            }
        } else if (usage.baseCount) {
            max=usage.baseCount;
        } else if (usage.levelsCount) {
            max = usage.levelsCount[level];
        } else if (usage.ability) {
            if (!abilities) {
                abilities = this.abilities;
            }
            max = ((abilities[usage.ability]||{}).modifier||0) + (usage.abilityBonus||0);
            if (max < 1) {
                max=1;
            }
        } else if (usage.proficiency) {
            max = (abilities?proficiency:this.proficiency)+(usage.abilityBonus||0);
            if (max < 1) {
                max=1;
            }
        }
        if ((max || !blanks) && usage.usageName && !abilities) {
            // only return a usage max if the feature actually has a usage
            return (this.namedUsageMax||{})[usage.usageName.toLowerCase().trim()]||max;
        }
        return max;
    }

    getUsageVal(fusage, id) {
        const usage = this.usage;
        let v = usage[id];
        if (fusage && fusage.usageName) {
            const fv = usage[fusage.usageName.toLowerCase().trim()];
            if (fv != null) {
                v= fv;
            }
        }
        if (v == null) {
            return this.getUsageMax(fusage);
        }
        return v;
    
    }

    setUsageVal(fusage, id, val) {
        this.setFeatureUsage((fusage&&fusage.usageName&&fusage.usageName.toLowerCase().trim())||id, val);
    }

    getActionUses(feature, usageId) {
        const {action, usage} = feature;
        switch (action.consumeType) {
            default:
            case "uses": {
                return this.getUsageVal(usage, usageId)||0;
            }
            case "spell":{
                // return the maximum level available
                let level=0;
                if (this.useSpellPoints) {
                    const availableSpellPoints=this.availableSpellPoints;
    
                    for (let y=1; y<=this.maxSpellCastLevel; y++) {
                        if ((stdvalues.spellPointsByLevel[y] <= availableSpellPoints)) {
                            level=y;
                        }
                    }
                } else {
                    for (let y=1; y<=9; y++) {
                        if (this.GetAvailableSpellSlots(y-1)) {
                            level=y;
                        }
                    }
                    const pactLevel = this.pactLevel;
                    if ((pactLevel > level) && this.pactSlots){
                        level = pactLevel;
                    }
                }
                return level;
            }
            case "hd":{
                // sum up counts for each die type.
                const hitDice = this.hitDice;
                let count=0;
                for (let i in hitDice) {
                    count += (hitDice[i]||0);
                }
                return count;
            }
        }
    }

    consumeActionUses(feature, usageId, usageOption) {
        const {usage} = feature;
        switch (usageOption.type) {
            case "uses": {
                this.setUsageVal(usage, usageId, Math.max(0, (this.getUsageVal(usage, usageId)||0)-usageOption.level));
                break;
            }
            case "hd":{
                const hitDice = this.hitDice;
                this.setHitDice(usageOption.faces, (hitDice[usageOption.faces]||0) - usageOption.level);
                break;
            }
            case "spellpoints":{
                this.availableSpellPoints = this.availableSpellPoints-usageOption.points;
                break;
            }
            case "spellslot":{
                this.SetAvailableSpellSlots(usageOption.level-1, this.GetAvailableSpellSlots(usageOption.level-1)-1);
                break;
            }
            case "pact":{
                this.pactSlots = this.pactSlots-1;
                break;
            }
        }
    }

    usageConditions(conditions, usageOption) {
        if (!conditions || !conditions.levelDurations || !usageOption) {
            return conditions;
        }
        conditions = Object.assign({}, conditions);
        const level = usageOption.level;
        let duration = conditions.duration||null;

        if (level) {
            for (let i=1; i<=level; i++) {
                duration = conditions.levelDurations[i]||duration;
            }
        }
        conditions.duration = duration;
        delete conditions.levelDurations;
        return conditions;
    }

    performAction(params, usageOption) {
        const {feature, usageId} = params;
        const {action}=feature;
        const estats={};
        let useCount=0;

        if (action.consumeUsage && usageOption) {
            useCount = usageOption.level;
            if (usageOption.faces) {
                estats["hit dice"]=usageOption.faces;
            }
        }

        const damages = damagesFromExtraDamage(addExtraDice(action.dice,action.useDamageBonus,useCount, action.consumeUsage), action.type, action.extraDamage||{});
        this.resolveDamages(damages,estats);
        const attackRoll = this.resolveDice(action.attackRoll,estats);
        const directDamage = damages&&damages.length&&!action.temphp&&!action.conditions&&!attackRoll;
        const directTempHP = action.temphp&&(!damages||!damages.length)&&!action.conditions&&!attackRoll&&!action.save;

        let temphp;
        if (action.temphp){
            temphp = {hp:this.resolveDice(this.replaceMetawords(addExtraDice(action.temphp.hp,action.useTemphpBonus,useCount, action.consumeUsage),estats),true)};
            if (action.temphp.duration) {
                temphp.duration = this.replaceMetawords(action.temphp.duration,estats);
            }
        }

        if (action.consumeUsage) {
            this.consumeActionUses(feature, usageId, usageOption);
        }
        if (action.removeCondition) {
            this.removeCondition(action.removeCondition);
        }
        if (action.removeActivated) {
            this.deactivateNamedAbility(action.removeActivated);
        }
        if (action.addUses) {
            const useName = (action.addUses.useName||"").trim().toLowerCase();
            const max = this.namedUsageMax[useName];
            const curUsage = this.getUsageVal(null, useName);
            const newUses = Math.min(max, curUsage+this.getBonusMod(action.addUses.useCount));
            if (newUses) {
                this.setUsageVal(null, useName, newUses);
            }
        }
        return {damages, attackRoll, directDamage, directTempHP,temphp};
    }

    getActiveId(action, id) {
        let name;
        if (action && ["activate","summon"].includes(action.actionOperation) && action.actionName) {
            name = "ActionActivate."+action.actionName.toLowerCase().trim();
        } else {
            name = id+".active";
        }
        return name;
    }

    getActionUsageOptions(feature, usageId, useCreateItem) {
        let levelOpts=[];
        const {action, createitem, usage} = feature;
        const {consumeType, consumeUsage,useDamageBonus,useTemphpBonus,actionOperation,conditions} = useCreateItem?(createitem||{}):action;
        let maxConsume = 50;
        const limitOne = useCreateItem || ((!actionOperation||(actionOperation=="attack")) && !useDamageBonus && !useTemphpBonus && !conditions?.levelDurations) ||
            ((actionOperation=="activate") && !conditions?.levelDurations) || ["shape","summon"].includes(actionOperation);

        if (limitOne) {
            maxConsume = consumeUsage;
        }

        switch (consumeType) {
            default:
            case "uses": {
                const tuv = this.getUsageVal(usage, usageId)||0;
                for (let i=consumeUsage; i<=Math.min(tuv,maxConsume); i++) {
                    levelOpts.push({level:i, type:"uses", usageName:usage.usageName});
                }
                break;
            }
            case "spell":{
                levelOpts= this.getSpellUsageOptions(consumeUsage, false, null, limitOne);
                break;
            }
            case "hd":{
                const hitDice = this.hitDice;
                for (let faces=1; faces<=20; faces++) {
                    const hdc = hitDice[faces];
                    if (hdc && hdc >= consumeUsage) {
                        for (let i=consumeUsage; i<=Math.min(hdc, maxConsume); i++) {
                            levelOpts.push({level:i, type:"hd", faces});
                        }
                    }
                }
                break;
            }
        }
        return levelOpts;
    }

    getSpellUsageOptions(level, ritual, time, limitOne) {
        const levelOpts=[];

        if (ritual) {
            levelOpts.push({level:level, type:"ritual", time});
        }

        if (this.useSpellPoints) {
            const availableSpellPoints=this.availableSpellPoints;

            for (let y=level; y<=this.maxSpellCastLevel; y++) {
                if ((stdvalues.spellPointsByLevel[y] <= availableSpellPoints)) {
                    levelOpts.push({level:y, type:"spellpoints", points:stdvalues.spellPointsByLevel[y]});
                }
            }
        } else {
            for (let y=level; y<=9; y++) {
                if (this.GetAvailableSpellSlots(y-1)) {
                    levelOpts.push({level:y, type:"spellslot"});
                    if (limitOne) {
                        break;
                    }
                }
            }
            const pactLevel = this.pactLevel;
            if ((pactLevel >= level) && this.pactSlots){
                levelOpts.push({level:pactLevel, type:"pact"});
            }
        }
        return levelOpts;
    }

    getSpellGainOptions() {
        const levelOpts=[];

        if (this.useSpellPoints) {
            const gap = this.maxSpellPoints - this.availableSpellPoints;

            for (let y=1; y<=this.maxSpellCastLevel; y++) {
                if ((stdvalues.spellPointsByLevel[y] <= gap)) {
                    levelOpts.push({level:y, type:"spellpoints", points:stdvalues.spellPointsByLevel[y]});
                }
            }
        } else {
            for (let y=1; y<=9; y++) {
                if (this.GetAvailableSpellSlots(y-1) < this.spellSlots[y-1]) {
                    levelOpts.push({level:y, type:"spellslot"});
                }
            }
            const pactLevel = this.pactLevel;
            if (pactLevel >=1) {
                if (this.pactSlots < this.maxPactSlots){
                    levelOpts.push({level:pactLevel, type:"pact"});
                }
            }
        }
        return levelOpts;
    }

    deactivateFeature(params, toggle) {
        const t=this;
        const {usageId, feature,typeValue,level} = params;
        const {action, effects} = feature;
        if (!toggle && action) {
            const aid = this.getActiveId(action, usageId);
            if (this.getFeatureUsage(aid)) {
                if (("activate"==action.actionOperation)) {
                    const aname = (action.actionName||"").toLowerCase().trim();
                    if (aname.length) {
                        this.traverseFeatures(function (tparams){
                            const {restriction} = tparams.feature;
                            if (restriction && restriction.activateName && ((restriction.activateName||"").toLowerCase().trim()==aname)) {
                                disableSubFeatures(tparams);
                            }
                        });
                    }
                    if (action.enableFeature) {
                        const actionOptions = this.activeOptions[usageId]||{};
                        this.walkFeatures(disableSubFeatures, [action.enableFeature], usageId+".activef", "", level, actionOptions, "action", typeValue, null, true, null);
                    }
                    if (action.deactivateDice || action.deactivateConditions) {
                        const href = this.getFeatureHref(feature, usageId);
                        let damages;
                        if (action.deactivateDice) {
                            damages = [{dmgType:action.deactivateType||"", dmg:this.resolveDice(action.deactivateDice)}];
                        }
                        Chat.addAction(this, (feature&&feature.name || ""), href, null, damages, null, null, "Deactivate", action.deactivateConditions, null);
                    }
                }
                this.setFeatureUsage(aid, 0);
                this.removeFeatureCompanions(usageId);
            }
        }

        if (toggle && effects) {
            const activeId = usageId+".toggle";
            if (this.getFeatureUsage(activeId)) {
                const actionOptions = this.activeOptions[usageId]||{};
                this.walkFeatures(disableSubFeatures, [effects], usageId+".togglef", "", level, actionOptions, "effects", typeValue, null, true, null);
                this.setFeatureUsage(activeId, 0);
            }
        }

        function disableSubFeatures(newparams) {
            const {action, effects,createitem,altcreateitem,alt2createitem} = newparams.feature;
            if (t.usage[newparams.usageId] != null) {
                t.setFeatureUsage(newparams.usageId, null);
            }

            if (createitem||altcreateitem||alt2createitem) {
                t.removeCreateItem(newparams.usageId);
            }
    
            if (action) {
                t.deactivateFeature(newparams, false);
            }
            if (effects) {
                t.deactivateFeature(newparams, true);
            }
        }
    }

    deactivateNamedAbility(aname) {
        const t=this;
        this.traverseFeatures(function (params){
            const {action} = params.feature;
            if (action?.actionName == aname) {
                t.deactivateFeature(params);
            }
        });
    }

    get usage() {
        //console.log("usage val", this.state.usage, this.getProperty("usage"));
        return this.getProperty("usage")||{};
    }

    getFeatureUsage(id) {
        return this.usage[id]||0;
    }

    setFeatureUsage(id, value) {
        const nfu = Object.assign({}, this.usage);
        if (value == null) {
            delete nfu[id];
        } else {
            if (value < 0) {
                value=0;
            }
            nfu[id] = value;
        }
        this.setProperty("usage", nfu);
    }

    getExtensionVal(extName, oName) {
        if (oName.startsWith("condition.")) {
            const cname = oName.substr(10).toLowerCase().trim();
            const conditions = this.conditions||{};
            for (let c in conditions) {
                if (c.toLowerCase()==cname) {
                    return conditions[c].level||1;
                }
            }
        } else {
            const usage = this.usage;
            const usageOpt = "charopt.c."+extName+"."+oName;
            const usageName = this.getExtensionValId(oName)
            let v = usage[usageOpt];
            const fv = usage[usageName];
            if (fv != null) {
                v= fv;
            }
            if (v == null) {
                return (this.namedUsageMax||{})[usageName];
            }
            return v||0;
        }
        return 0;
    }

    getExtensionValId(oName) {
        return oName&&oName.toLowerCase().trim();
    }

    rest(long, allHitDice, spentHitDice, hp) {
        const t=this;
        const props = {
            initiative:null
        };

        let nu = Object.assign({}, this.usage);
        let equipment = Object.assign({}, this.equipment);
        const classes = this.classes;

        if (this.state.classes != undefined) {
            props.pactSlots = this.maxPactSlots;
            if (long) {
                const hitDice = Object.assign({}, this.hitDice);
                const maxHitDice = this.maxHitDice;

                for (let faces in maxHitDice) {
                    hitDice[faces] = (hitDice[faces]||0)+(Math.trunc(maxHitDice[faces]/2)||1);
                    if (allHitDice || (hitDice[faces] > maxHitDice[faces])) {
                        hitDice[faces] = maxHitDice[faces];
                    }
                }

                Object.assign(props, {
                    availableSpellSlots:(this.spellSlots||[]).concat([]),
                    availableSpellPoints:(this.slotSpellPoints||0)+(this.pactSpellPoints||0),
                    hp:this.state.maxhp||0,
                    hitDice,
                    temphp:0,
                    defaultHitDice:allHitDice||false
                });
            } else {
                props.availableSpellPoints = Math.min((this.state.availableSpellPoints||0)+this.pactSpellPoints, this.slotSpellPoints+this.pactSpellPoints);

                if (spentHitDice) {
                    const hitDice = Object.assign({}, this.hitDice);
                    const maxHitDice = this.maxHitDice;

                    for (let faces in spentHitDice) {
                        hitDice[faces] = (hitDice[faces]||0)-(spentHitDice[faces]||0);
                        if ((hitDice[faces] > maxHitDice[faces])) {
                            hitDice[faces] = maxHitDice[faces];
                        } else if (hitDice[faces]<0) {
                            hitDice[faces]=0;
                        }
                    }

                    Object.assign(props, {
                        hp:Math.min(this.state.maxhp, this.hp+(hp||0)),
                        hitDice,
                    });
                }
            }

            for (let c in classes) {
                const clsInfo = classes[c] || {};
                const cls = campaign.getClassInfo(clsInfo.cclass);

                if (cls) {
                    const subclassInfo = campaign.getSubclassInfo(clsInfo.subclass);
                    const customLevels = getMergedCustomLevels(cls, subclassInfo);

                    for (let x in customLevels) {
                        const cl = customLevels[x];
                        if ((cl.attributeType == "points") && (long || !cl.longRest)) {
                            const lv = (cl.levelsCount||[])[clsInfo.level-1]||0;
                            if (cl.name) {
                                const usageName = cl.name.toLowerCase();
                                if ((nu[usageName]||0) < lv) {
                                    nu[usageName]=lv;
                                }
                            }
                        }
                    }
                }
            }
        }
       
        this.traverseFeatures(function (params) {
            const {options, level, feature, usageId}=params;
            let e = params.feature;
            const {action,createitem} = e;
            if (params.type == "item") {
                const newFeature = itemFeatureUsage(e);
                if (newFeature) {
                    const it = equipment[params.id];
                    const newIt = Object.assign({},it);
                    newIt.feature = newFeature;
                    equipment[params.id] = newIt;
                }
            } else if (params.type == "itemextra") {
                const newFeature = itemFeatureUsage(e);
                if (newFeature) {
                    const it = equipment[params.id.id];
                    const newIt = Object.assign({},it);
                    newIt.extraFeatures= Object.assign({}, newIt.extraFeatures);
                    newIt.extraFeatures[params.id.createId] = newFeature;
                    equipment[params.id.id] = newIt;
                }
            } else {
                if (e.usage && (long || !e.usage.longRest)  && !(e.usage.longRest <0) && (e.usage.restore != "none")) {
                    const usage=e.usage;
                    const max = t.getUsageMax(usage, level-1, null, true);
                    const uname = (usage && usage.usageName)?usage.usageName.toLowerCase():params.usageId;

                    if (usage.resetToZero) {
                        nu[uname] = 0;
                    } else if (max && ((nu[uname]||0)<max)) {
                        nu[uname] = max;                                
                    }
                }
                if (e.recoverUses) {
                    const max = (t.namedUsageMax||{})[e.recoverUses.useName];
                    if ((nu[e.recoverUses.useName] != null) && max) {
                        nu[e.recoverUses.useName] = Math.min((e.recoverUses.useCount||0)+nu[e.recoverUses.useName], max);
                    }
                }
                resetFeatureSpells(e, params.fid, params.usageId, options);
            }
            if (action && action.duration  && ["activate","summon"].includes(action.actionOperation)) {
                const usageIdDuration = usageId+".duration";
                let turns = t.getFeatureUsage(usageIdDuration);
                let deactivate;
                if (turns) {
                    deactivate=true;
                } else {
                    deactivate = shouldDurationExpire(action.duration,long);
                }
                if (deactivate) {
                    // hack since deactivate feature reads and sets usage
                    // set state before and restore nu after
                    t.state.usage=nu||null;
                    t.state.equipment=equipment||null;
                    t.deactivateFeature(params);
                    nu = t.state.usage||null;
                    equipment=t.state.equipment||null;
                }
            }
            if (createitem && createitem.duration) {
                const usageIdDuration = usageId+".cduration";
                let turns = t.getFeatureUsage(usageIdDuration);
                let deactivate;
                if (turns) {
                    deactivate=true;
                } else {
                    deactivate = shouldDurationExpire(createitem.duration,long);
                }
                if (deactivate) {
                    // hack since deactivate feature reads and sets usage
                    // set state before and restore nu after
                    t.state.equipment=equipment||null;
                    t.removeCreateItem(usageId);
                    equipment=t.state.equipment||null;
                }
            }
        });

        if (this.conditions) {
            let cond = Object.assign({}, this.conditions);
            for (let c in cond) {
                if (cond[c]) {
                    let deactivate;
                    if ((typeof cond[c] == "object")) {
                        const {duration, durationText} = cond[c];
                        if (duration) {
                            deactivate=true;
                        } else if (durationText) {
                            deactivate = shouldDurationExpire(durationText, long);
                        }
                    } else {
                        deactivate=true;
                    }
                    if (deactivate){
                        if (c=="Temp HP") {
                            props.temphp=0;
                        }
                        if ((c=="Shape Change")&&this.shape) {
                            this.shape=null;
                        }
                        if (!!cond[c]?.spell) {
                            // hack since removeFeature modifies companions
                            // set state before and restore after
                            this.state.companions = props.companions||this.state.companions||null;
                            this.removeFeatureCompanions("spellmonster."+cond[c].spell.spellId);
                            props.companions = this.state.companions||null;
                        }
    
                        delete cond[c];
                        // hack since cleanupCondition reads and sets usage
                        // set state before and restore nu after
                        t.state.usage=nu||null;
                        t.state.equipment=equipment||null;

                        t.cleanupCondition(c);
                        nu = this.state.usage||null;
                        equipment=t.state.equipment||null;
                    }
                }
            }
            props.conditions = cond;
        }

        if (this.state.companions) {
            const {MonObj} = require('./monobj.js');
            const {setupCompanion} = require('../src/rendermonster.jsx');
            
            const companions=Object.assign({}, this.companions);
            for (let i in companions) {
                const monObj = new MonObj(companions[i], null, true, "noupdate");
                monObj.rest(long);
                companions[i] = monObj.state;
                if (long) {
                    setupCompanion(companions[i], this);
                }
            }
            props.companions=companions;
        }

        if (this.shape) {
            const shape = Object.assign({},this.shape);
            if (shape.usages) {
                const newUsages = [];
                const usages = shape.usages;
                for (let i in usages) {
                    const u = Object.assign({}, usages[i]);
                    if (long || !u.longrest) {
                        if (!u.hidden) {
                            u.current=u.maximum||1;
                            newUsages.push(u);
                        }
                    } else {
                        newUsages.push(u);
                    }
                }
                shape.usages = newUsages;
            }
            
            const maxHP = this.maxhp;
            let hp = this.hp;
    
            if (long) {
                hp = maxHP;
            } else {
                hp = (hp||0) + Math.trunc((maxHP||1)/2);
                hp = Math.min(hp, maxHP);
            }
            props.shapehp =hp;
            props.shape = shape;
        }

        if (long) {
            props.temphp=0;
        }

        props.usage = nu||null;
        props.equipment = equipment;


        this.setProperty(props);

        function itemFeatureUsage(e) {
            if (e && e.usage && e.usage.baseCount && (long || e.usage.restore=="short") && (e.usage.restore != "none")) {
                let uses = e.uses;
                e = Object.assign({}, e);
                if (e.usage.restore=="random") {
                    if (uses === undefined) {
                        uses = e.usage.baseCount;
                    } else {
                        let randomBonus = e.usage.randomBonus;
                        if (randomBonus == null) {
                            randomBonus = e.usage.baseCount-e.usage.random;
                        }
            
                        uses += Math.trunc(Math.random()*e.usage.random)+1+randomBonus;
                        if (uses > e.usage.baseCount) {
                            uses = e.usage.baseCount;
                        }
                    }
                } else {
                    uses = e.usage.baseCount;
                }
                e.uses = uses;
                return e;
            }
            const recoveryType = e.recoveryType||"day";
            if (long && e.castableSpells && recoveryType=="day") {
                e.itemUsage = {};
                for (let i in e.castableSpells) {
                    e.itemUsage[e.castableSpells[i].name]=1;
                }
            }
            return null;
        }
        
        function resetFeatureSpells(feature, baseName, optionsBase, options) {
            const castableSpells = feature.castableSpells||{};

            if (long || feature.recoveryType=="short") {
                for (let i in castableSpells) {
                    const s = castableSpells[i];
                    if (s.level) {
                        nu[optionsBase+".spellPick."+(s.name||"").toLowerCase()]=1;
                    }
                }
            }

            if (feature.spellPick && (long || feature.spellPick.recoveryType=="short")) {
                const spellPick = (options||{})[baseName+".spellPick"]||[];
                for (let i in spellPick) {
                    const s = spellPick[i];
                    if (s.level) {
                        nu[optionsBase+".spellPick."+(s.name||"").toLowerCase()]=1;
                    }
                }
            }
        }
    }

    cleanupCondition(c) {
        const nfu = Object.assign({}, this.usage);
        let updated;
        const b1="cond."+c;
        const b2="fcond."+c;

        for (let i in nfu) {
            if (i.startsWith(b1) || i.startsWith(b2)) {
                delete nfu[i];
                updated=true;
            }
        }
        if (updated) {
            this.setProperty("usage", nfu||null);
        }
        this.removeCreateItem(b1);
        this.removeCreateItem(b2);
    }

    advanceTurn() {
        const t=this;

        this.traverseFeatures(function (params) {
            const {feature,usageId}=params;
            const {action,createitem} = feature;
            if (action && action.duration && ["activate","summon"].includes(action.actionOperation)) {
                const usageIdDuration = usageId+".duration";
                let turns = t.getFeatureUsage(usageIdDuration);
                if (turns) {
                    turns--;
                    if (turns <=0) {
                        t.deactivateFeature(params);
                    } else {
                        t.setFeatureUsage(usageIdDuration, turns);
                    }
                }
            }
            if (createitem && createitem.duration) {
                const usageIdDuration = usageId+".cduration";
                let turns = t.getFeatureUsage(usageIdDuration);
                if (turns) {
                    turns--;
                    if (turns <=0) {
                        t.removeCreateItem(usageId);
                    } else {
                        t.setFeatureUsage(usageIdDuration, turns);
                    }
                }
            }
        });


        if (this.conditions) {
            const props={};
            let cond = Object.assign({}, this.conditions);
            for (let c in cond) {
                if (cond[c]) {
                    let count = 1;
                    if ((typeof cond[c] == "object")) {
                        if (cond[c].duration) {
                            cond[c] = Object.assign({},cond[c]);
                            cond[c].duration--;
                            count = cond[c].duration;
                        }
                    } else {
                        cond[c]--;
                        count = cond[c];
                    }
                    if (!count){
                        if (c=="Temp HP") {
                            props.temphp=0;
                        }
                        if ((c=="Shape Change")&&t.shape) {
                            t.shape=null;
                        }

                        if (cond[c]?.spell) {
                            t.removeFeatureCompanions("spellmonster."+cond[c].spell.spellId);
                        }

                        delete cond[c];
                        t.cleanupCondition(c);
                    }
                }
            }
            props.conditions = cond;
            this.setProperty(props);
        }
    }

    getCharacterPackage(pkg) {
        const typeMap ={feat:"feats", race:"races", background:"backgrounds", custom:"customTypes"};

        const classes=this.classes;

        for (let c in classes) {
            const clsInfo = classes[c] || {};
            const cls = campaign.getClassInfo(clsInfo.cclass);
            const subclassInfo = campaign.getSubclassInfo(clsInfo.subclass);

            addType("classes",cls);
            if (subclassInfo) {
                addType("classes", subclassInfo);
            }

            if (clsInfo.selectedSpells) {
                for (let i in clsInfo.selectedSpells) {
                    const spell = campaign.getSpell(i);
                    addType("spells", spell);
                }
            }
        }

        if (this.state.tokenArt) {
            addType("art", campaign.getArtInfo(this.state.tokenArt));
        }
        if (this.state.charactersheetTheme?.backgroundArt) {
            addType("art", campaign.getArtInfo(this.state.charactersheetTheme.backgroundArt));
        }
        if (this.state.artList) {
            const artList = this.state.artList;
            for (let i in artList) {
                addType("art", campaign.getArtInfo(artList[i]));
            }
        }
        if (this.state.shape) {
            if (this.state.shape.tokenArt) {
                addType("art", campaign.getArtInfo(this.state.shape.tokenArt));
            }
            if (this.state.shape.artList) {
                const artList = this.state.shape.artList;
                for (let i in artList) {
                    addType("art", campaign.getArtInfo(artList[i]));
                }
            }
        }
        const companions = this.companions;
        for (let i in companions) {
            const c = companions[i];
            if (c.tokenArt) {
                addType("art", campaign.getArtInfo(c.tokenArt));
            }
            if (c.artList) {
                const artList = c.artList;
                for (let i in artList) {
                    addType("art", campaign.getArtInfo(artList[i]));
                }
            }
        }

        this.traverseFeatures(function (params) { 
            const {type, typeValue, feature, fid, options} = params;
            const typeM = typeMap[type];
            addType(typeM, typeValue);

            if (feature.selectableSpells) {
                for (let i in feature.selectableSpells) {
                    const spell = campaign.getSpell(feature.selectableSpells[i]);
                    addType("spells", spell);
                }
            }
            if (feature.extraSpells) {
                for (let i in feature.extraSpells) {
                    const spell = campaign.getSpell(feature.extraSpells[i]);
                    addType("spells", spell);
                }
            }

            if (feature.spellPick) {
                const spellPick = (options||{})[fid+".spellPick"]||[];
                for (let i in spellPick) {
                    const s = spellPick[i];
                    const spell = campaign.getSpell(s.name);
                    addType("spells", spell);
                }
            }
        });

        function addType(typeM, typeValue) {
            if (typeValue && typeM && !typeValue.characterPackage && (typeValue.edited || !campaign.isCampaignPackage(typeValue.source))){
                typeValue = Object.assign({}, typeValue);
                typeValue.characterPackage=true;
                if (!pkg[typeM]) {
                    pkg[typeM]=[typeValue];
                } else {
                    const pos = pkg[typeM].findIndex(function (a) {return a.name.toLowerCase()==typeValue.name.toLowerCase()});
                    if (pos <0) {
                        pkg[typeM].push(typeValue);
                    }
                }
            }
        }
    }

    traverseFeatures(callback, types, cclass, ignoreRestrictions) {
        const classes=this.classes;
        const extraRoot = {};

        if (!types || types.includes("class")) {
            for (let c in classes) {
                const clsInfo = classes[c] || {};
                const cls = campaign.getClassInfo(clsInfo.cclass);

                if (cls && (!cclass||(cclass == clsInfo.cclass))) {
                    const subclassInfo = (clsInfo.level>(cls.startSubclass||1))?campaign.getSubclassInfo(clsInfo.subclass):null;
                    const customLevels = getMergedCustomLevels(cls, subclassInfo);
                    const {table} = getFeaturesTable(cls, clsInfo.subclass);
                    extraRoot.type = "class";
                    extraRoot.val = clsInfo;

                    for (let x in customLevels) {
                        const cl = customLevels[x];
                        if (cl.attributeType == "select") {
                            const selected = (clsInfo.customSelected && clsInfo.customSelected[cl.name])||{};
    
                            for (let s in selected) {
                                const it = campaign.getCustom(cl.name, s);
                                if (it) {
                                    this.walkFeatures(callback,it.features||[],"custom."+clsInfo.cclass+"."+cl.name+"."+s, "custom."+clsInfo.cclass+"."+cl.name+"."+s, clsInfo.level, clsInfo.options, "custom", it, {type:cl.name, name:s},false, extraRoot,ignoreRestrictions);
                                }
                            }
                        }
                    }

                    for (let l=0; l<clsInfo.level; l++) {
                        this.walkFeatures(callback,table[l]||[],clsInfo.cclass+"."+l, "feature"+l, clsInfo.level, clsInfo.options, "class", subclassInfo || cls, clsInfo.subclass||clsInfo.cclass,false, extraRoot,ignoreRestrictions);
                    }
                }
            }
        }

        switch (this.gamesystem) {
            case "5e24":
            case "5e":{
                if (!types || types.includes("race")) {
                    const race = campaign.getRaceInfo(this.race);
                    if (race) {
                        extraRoot.type = "race";
                        extraRoot.val = race;
        
                        this.walkFeatures(callback,race.raceFeatures||[],"race."+race.name,"race."+race.name, this.level, this.raceOptions, "race", race, this.race,false, extraRoot,ignoreRestrictions);
                    }
                }
                break;
            }
            case "bf":{
                if (!types || types.includes("lineage")) {
                    const lineage = campaign.getCustom("Lineages", this.lineage);
                    if (lineage) {
                        extraRoot.type = "lineage";
                        extraRoot.val = lineage;
        
                        this.walkFeatures(callback,lineage.features||[],"lineage."+lineage.name,"lineage."+lineage.name, this.level, this.lineageOptions, "lineage", lineage, this.lineage,false, extraRoot,ignoreRestrictions);
                    }
                }
                if (!types || types.includes("heritage")) {
                    const heritage = campaign.getCustom("Heritages", this.heritage);
                    if (heritage) {
                        extraRoot.type = "heritage";
                        extraRoot.val = heritage;
        
                        this.walkFeatures(callback,heritage.features||[],"heritage."+heritage.name,"heritage."+heritage.name, this.level, this.heritageOptions, "heritage", heritage, this.heritage,false, extraRoot,ignoreRestrictions);
                    }
                }
                break;
            }
        }

        if (!types || types.includes("background")) {
            const background = campaign.getBackgroundInfo(this.background);
            if (background) {
                extraRoot.type = "background";
                extraRoot.val = background;

                this.walkFeatures(callback,background.features||[],"background."+background.name,"background."+background.name, this.level, this.backgroundOptions, "background", background, this.background,false, extraRoot,ignoreRestrictions);
            }
        }

        if (!types || types.includes("hero")) {
            const heroAbilities = this.heroAbilities;
            if (heroAbilities) {
                const options = this.heroOptions;

                for (let x in heroAbilities) {
                    const h = heroAbilities[x];
                    let it;

                    if (h.customType=="Feats") {
                        it = campaign.getFeatInfo(h.customId);
                    } else {
                        it = campaign.getCustom(h.customType, h.customId);
                    }

                    if (it) {
                        extraRoot.type = "hero";
                        extraRoot.val = h;
    
                        this.walkFeatures(callback,it.features||[],"hero."+h.id, "hero."+h.id, this.level, options, h.customType=="Feats"?"feat":"custom", it, h.customType=="Feats"?h.customId:{type:h.customType, name:h.customId},false, extraRoot,ignoreRestrictions);
                    }
                }
            }
        }

        if (!types || types.includes("extensions")) {
            const charOpts = this.characterOptions;
            if (charOpts) {
                const options = this.extensionOptions;

                for (let x in charOpts) {
                    const o = charOpts[x];
                    if (!cclass || ((cclass.extensionId==o.extensionId) && (cclass.name==o.name))) {
                        const baseCOpt = "charopt.c."+o.extensionId+"."+o.name;
                        extraRoot.type = "extension";
                        extraRoot.val = o;

                        if (o.type == "select") {
                            const selected = this.getExtensionCharacterOption(baseCOpt);
                            const selectedVals = Object.keys(selected||{});
                            const ct = campaign.getCustom(o.customType, selectedVals[0]);
                            if (ct) {
                                this.walkFeatures(callback, ct.features||[],baseCOpt+".customtable", baseCOpt+".customtable", this.level, options, "custom", ct, {type:o.customType, name:selectedVals[0]},false, extraRoot);
                            }
                        }
            
                        this.walkFeatures(callback,o.features||[],baseCOpt, baseCOpt, this.level, options, "extension", o, o.name,false, extraRoot,ignoreRestrictions);
                    }
                }
            }
        }

        if (!types || types.includes("conditions")) {
            const conditions = this.conditions;
            if (conditions) {
                for (let c in conditions) {
                    const cond = conditions[c];
                    if (cond) {
                        if ((typeof cond == "object")) {
                            const conditionInfo = campaign.getCustom("Conditions", cond.selectedCondition);
                            if (conditionInfo) {
                                this.walkFeatures(callback,conditionInfo.features||[],"cond."+c, "cond."+c, this.level, {}, "custom", conditionInfo, {type:"Conditions", name:cond.selectedCondition},false, extraRoot,ignoreRestrictions);
                            }
                            if (cond.feature) {
                                this.walkFeatures(callback,[cond.feature],"fcond."+c, "fcond."+c, this.level, {}, "embed", null, null,false, extraRoot,ignoreRestrictions);
                            }
                        }
                    }
                }
            }
        }

        if (!types || types.includes("item")) {
            const equipment = Object.assign({}, this.equipment);
            for (let i in equipment) {
                const it = equipment[i];
                extraRoot.type = "item";
                extraRoot.val = it;

                upgradeItem(it);
                this.walkFeatures(callback,[it.feature], "", "", this.level, {}, "item", it, i,false, extraRoot);
                if (it.extraFeatures) {
                    for (let x in it.extraFeatures) {
                        this.walkFeatures(callback,[it.extraFeatures[x]], "", "", this.level, {}, "itemextra", it, {id:i, createId:x},false, extraRoot,ignoreRestrictions);
                    }
                }

            }
        }

    }

    walkFeatures(callback, features, baseName, optionsBase, level, options, type, typeValue, id, underActive, extraRoot,ignoreRestrictions) {
        for (let index in features) {
            let e = features[index];
            if (!ignoreRestrictions&&!this.checkRestriction(e.restriction)) {
                continue;
            }
            const fname=e.id||e.name||"";
            let usageId = baseName+"."+fname;
            if (e.options) {
                const fid = optionsBase+"."+fname + ".selected";
                const v = options[fid];
                let selected;
    
                for (let i in e.options) {
                    const f = e.options[i];
                    if ((f.name||i) == v) {
                        selected = f;
                    }
                    if (ignoreRestrictions) {
                        const lusageId = baseName+"."+(f.id||((e.name||typeValue&&typeValue.name||"")+": "+(f.name||"")));
                        callback({feature:f, optionFeature:e, options, fid:optionsBase+"."+fname, lusageId, type, typeValue, id, level,underActive, extraRoot});
                    }
                }
                //console.log("traverse", e, options, fid, selected)
                if (!selected || ignoreRestrictions) {
                    callback({feature:{}, noOption:true, optionFeature:e, options, fid:optionsBase+"."+fname, usageId, type, typeValue, id, level,underActive, extraRoot});
                    if (!selected) {
                        continue;
                    }
                }
                usageId = baseName+"."+(selected.id||((e.name||typeValue&&typeValue.name||"")+": "+(selected.name||"")));

                if (!ignoreRestrictions&&!this.checkRestriction(selected.restriction)) {
                    continue;
                }
                callback({feature:selected, optionFeature:e, options, fid:optionsBase+"."+fname, usageId, type, typeValue, id, level,underActive, extraRoot});
                e = selected;
            } else {
                callback({feature:e, fid:optionsBase+"."+fname, options, usageId, type, typeValue,id,level,underActive, extraRoot});
            }

            if (e.pickFeat || e.grantFeat) {
                const fid = optionsBase+"."+fname + ".selectedFeat";
                const featName = e.grantFeat||options[fid];
                const feat = campaign.getFeatInfo(featName);
                if (feat && featName!=id) { // prevent recursion
                    this.walkFeatures(callback, feat.features, baseName+"."+fname+".feat", optionsBase+"."+fname+".feat", level, options, "feat", feat, featName,underActive, extraRoot,ignoreRestrictions);
                } else {
                    //console.log("skipping feat", feat, featName, features);
                }
            }

            if (e.customPick) {
                const fid = optionsBase+"."+fname + ".customPick";
                let pickedCustom = options[fid];
                if ((e.customPick.customOptions && (e.customPick.customOptions.length==1))) {
                    pickedCustom={};
                    pickedCustom[e.customPick.customOptions[0]]=1;
                }
    
                if (pickedCustom) {
                    for (let i in pickedCustom) {
                        const ct = campaign.getCustom(e.customPick.customTable, i);
                        if (ct) {
                            this.walkFeatures(callback, ct.features, baseName+"."+fname+".customtable"+i, optionsBase+"."+fname+".customtable"+i, level, options, "custom", ct, {type:e.customPick.customTable, name:i}, underActive, extraRoot,ignoreRestrictions);
                        }
                    }
                }
            }

            if (e.subfeatures) {
                this.walkFeatures(callback, e.subfeatures, baseName+"."+fname+".sub", optionsBase+"."+fname+".sub", level, options,type, typeValue, id,underActive, extraRoot,ignoreRestrictions);
            }

            if (e.action && ("activate" == e.action.actionOperation) && e.action.enableFeature && this.getFeatureUsage(this.getActiveId(e.action, usageId))) {
                //callback({feature:e.action.enableFeature, fid:optionsBase+".activef", options, usageId:usageId+".activef", type, typeValue,id,level});
                const actionOptions = this.activeOptions[usageId]||{};
                this.walkFeatures(callback, [e.action.enableFeature], usageId+".activef", "", level, actionOptions, "action", typeValue, null, true, extraRoot,ignoreRestrictions);
            }
            if (e.effects && this.getFeatureUsage(usageId+".toggle")) {
                //callback({feature:e.action.enableFeature, fid:optionsBase+".activef", options, usageId:usageId+".activef", type, typeValue,id,level});
                const effectsOptions = this.activeOptions[usageId+".e"]||{};
                this.walkFeatures(callback, [e.effects], usageId+".togglef", "", level, effectsOptions, "effects", typeValue, null, true, extraRoot,ignoreRestrictions);
            }
        }
    }

    damageHeal(adjust) {
        let temphp = this.temphp;
        let hp=this.hp;
        let revert;

        if (adjust > 0) {
            hp = hp+adjust;
            if (hp > this.maxhp) {
                hp = this.maxhp;
            }
        } else {
            if (temphp) {
                if ((temphp + adjust) < 0) {
                    adjust = adjust + temphp;
                    temphp = 0;
                } else {
                    temphp = temphp+adjust;
                    adjust = 0;
                }
            }
            hp = hp + adjust;
            if (hp <= 0){
                if (this.state.shape) {
                    if (this.state.shape.shapeHP != "keep") {
                        hp = this.state.hp + hp;
                        if (hp < 0) {
                            hp = 0;
                        }
                        // revert shape on 
                        this.setProperty({
                            hp,
                            temphp,
                            shape:null,
                            deathSaves:3,
                            deathFails:3,
                        });
                        return;
                    }else {
                        revert=true;
                    }
                }
                hp = 0;
            }
        }

        let setProps = {hp, temphp};
        if (this.state.shape && (this.state.shape.shapeHP != "keep")) {
            setProps = {shapehp:hp, temphp};
        }
        if (revert) {
            setProps.shape=null;
        }
        if (this.hp && !hp) {
            setProps.deathSaves = 3;
            setProps.deathFails = 3;
        }
        this.setProperty(setProps);
    }

    getCoins(coin) {
        let sum = 0;

        getSubCount(this.equipment);
        return sum;

        function getSubCount(contained) {
            for (let i in contained) {
                const it =contained[i];
                if ((it.coin || (it.type=="coin")) && it.coinType==coin) {
                    sum += (it.quantity||0);
                }
                if (it.container) {
                    getSubCount(it.contained);
                }
            }
        }
    }

    findCoins() {
        const coins = {};

        getCoins(this.equipment);
        return coins;

        function getCoins(contained) {
            for (let i in contained) {
                const it =contained[i];
                upgradeItem(it);
                if (it.coinType && (it.coin || (it.type=="coin"))) {
                    if (coins[it.coinType]) {
                        coins[it.coinType].quantity += (it.quantity||0);
                    } else {
                        coins[it.coinType] = Object.assign({quantity:0}, it);
                    }
                }
                if (it.container) {
                    getCoins(it.contained);
                }
            }
        }
    }

    adjustCoins(coin, adjust, coinIt) {
        let sequipment = Object.assign({}, this.equipment||{});
        let {equipment, added} = adjustEquipmentCoins(sequipment, coin, adjust, coinIt);

        this.equipment = equipment;
        return added;
    }

    getOption(opt) {
        const o = this.state.options || {};
        return o[opt];
    }

    setOption(opt, value) {
        const o = Object.assign({}, this.state.options || {});
        o[opt] = value;
        this.setProperty("options", o);
    }

    get d20Bonuses() {
        return {initiativeDice:this.initiativeDice,
            saveDice:this.saveDice,
            skillDice:this.skillDice,
            attackDice:this.attackDice,
            spellDice:this.spellDice
        }
    }

    get artList() {
        if (this.state.shape) {
            return this.state.shape.artList;
        } else {
            return this.state.artList;
        }
    }

    get tokenArt() {
        if (this.state.shape) {
            return this.state.shape.tokenArt;
        } else {
            return this.state.tokenArt;
        }
    }

    setArtwork(artList, tokenArt) {
        const art = campaign.getArtInfo(tokenArt);
        if (!art && tokenArt) {
            return;
        }
        if (this.state.shape) {
            const shape = Object.assign({}, this.state.shape);
            shape.artList = artList||null;
            shape.tokenArt = tokenArt||null;
            if (tokenArt) {
                shape.imageURL = art.url;
            } else {
                delete shape.imageURL;
            }
            this.setProperty({shape});
        } else {
            this.setProperty({artList, tokenArt, imageURL:(art && art.url)||null});
        }
    }

    get imageURL() {
        return getImageUrlFromCharacter(this.state);
    }

    set imageURL(v) {
        this.setProperty("imageURL", v);
    }

    get deathSaves() {
        return this.state.deathSaves||0;
    }

    set deathSaves(v) {
        this.setProperty("deathSaves", v);
    }

    get deathFails() {
        return this.state.deathFails||0;
    }

    set deathFails(v) {
        this.setProperty("deathFails", v);
    }

    get raceOptions() {
        return this.state.raceOptions||{};
    }

    get heritageOptions() {
        return this.state.heritageOptions||{};
    }

    get lineageOptions() {
        return this.state.lineageOptions||{};
    }

    get raceDisplayName() {
        const it = campaign.getRaceInfo(this.race);
        if (it) {
            return it.displayName;
        }
        return null;
    }

    get heritageDisplayName() {
        const it = campaign.getCustom("Heritages", this.state.heritage);
        if (it?.displayName) {
            if (it.displayName.toLowerCase().endsWith(" heritage")) {
                return it.displayName.substr(0, it.displayName.length-9);
            }
            return it.displayName;
        }
        return null;
    }

    get lineageDisplayName() {
        const it = campaign.getCustom("Lineages", this.state.lineage);
        return it?.displayName;
    }

    getOriginDisplayName() {
        switch (this.gamesystem) {
            case "5e24":
            case "5e":{
                const r = campaign.getRaceInfo(this.race);
                return r?.displayName;
            }
            case "bf":{
                const hit = campaign.getCustom("Heritages", this.heritage);
                let name=null;

                if (hit?.displayName) {
                    name = hit.displayName;
                    if (name.toLowerCase().endsWith(" heritage")) {
                        name = name.substr(0, name.length-9);
                    }
                }
                const lit = campaign.getCustom("Lineages", this.lineage);
                if (lit?.displayName) {
                    name = (name?(name+" "):"")+lit.displayName;
                }
                if (name && name.length) {
                    return name;
                }
            }
        }
        return null;
    }

    get backgroundDisplayName() {
        const it = campaign.getBackgroundInfo(this.background);
        if (it) {
            return it.displayName;
        }
        return null;
    }

    get heroAbilities() {
        return this.state.heroAbilities||[];
    }

    get classOptions() {
        return this.state.classOptions||{};
    }

    get backgroundOptions() {
        return this.state.backgroundOptions||{};
    }

    get heroOptions() {
        return this.state.heroOptions||{};
    }

    setExtensionCharacterOption(name, val) {
        const options = this.extensionOptions;
        
        const opts = Object.assign({}, options);
        opts[name] = val;

        this.setProperty("extensionOptions",opts);
    }

    getExtensionCharacterOption(name) {
        return this.extensionOptions[name];
    }

    get extensionOptions() {
        return this.state.extensionOptions||{};
    }

    setProperty(prop, v, p2, v2, p3, v3) {
        if (this.readOnly) {
            snackMessage("Character is read only");
            return;
        }
        const newState = Object.assign({}, this.state);

        if (typeof prop == "object") {
            for (let i in prop) {
                newState[i] = prop[i];
            }
        } else {
            newState[prop] =v;
            if (p2) {
                newState[p2] = v2;
            }
            if (p3) {
                newState[p3] = v3;
            }
        }

        const logId = this.getCurrentSessionLog(newState);

        if ((this.state.levels != newState.levels) || 
            //(this.state.cp != newState.cp) ||
            //(this.state.sp!= newState.sp) ||
            //(this.state.gp != newState.gp) ||
            //(this.state.ep != newState.ep) ||
            //(this.state.pp != newState.pp) ||
            //(this.state.equipment != newState.equipment) ||
            (this.state.xp != newState.xp) ||
            !newState.sessionLog ||
            !newState.sessionLog[logId]) {
            newState.sessionLog = Object.assign({}, newState.sessionLog||{});
            const logEntry = Object.assign({}, newState.sessionLog[logId]||{});
            this.pruneSessionLog(newState.sessionLog);
            newState.sessionLog[logId] = logEntry;

            // cleanup old equipment that has item details to instead just have name and quantity
            {
                const sessionLog = newState.sessionLog;
                for (let i in sessionLog) {
                    let le = sessionLog[i];
                    let equipment = le.equipment;
                    let updated=false;

                    if (equipment) {
                        for (let x in equipment){
                            let e = equipment[x];
                            if (e.name) {
                                e = {displayName:e.displayName||null, quantity:e.quantity||0};
                                if (!updated) {
                                    updated=true;
                                    le= Object.assign({},le);
                                    le.equipment = Object.assign({},equipment);
                                    sessionLog[i]=le;
                                }
                                le.equipment[x]=e;
                            }
                        }
                    }
                }
            }
            
/*
            logEntry.coins = Object.assign({},logEntry.coins||{});
            for (let i in stdvalues.coinNames) {
                const diff = (newState[i]||0)- (this.state[i]||0);
                if (diff) {
                    logEntry.coins[i] = (logEntry.coins[i]||0) + diff;
                }
            }

            const oe = this.state.equipment || {};
            const ne = newState.equipment || {};
            logEntry.equipment = Object.assign({},logEntry.equipment||{});

            for (let i in oe) {
                if (!ne[i]) {
                    let it = logEntry.equipment[i];
                    if (!it) {
                        it = logEntry.equipment[i] = {displayName:oe[i].displayName, quantity:-(oe[i].quantity||1)};
                    } else {
                        it.quantity -= (oe[i].quantity||1);
                    }
                    if (!it.quantity) {
                        delete logEntry.equipment[i];
                    }
                }
            }
            for (let i in ne) {
                if (!oe[i] || (oe[i].quantity != ne[i].quantity)) {
                    let it = logEntry.equipment[i];
                    if (!it) {
                        it = logEntry.equipment[i] = {displayName:ne[i].displayName, quantity:ne[i].quantity||1};
                    } else {
                        logEntry.equipment[i].quantity += (ne[i].quantity||1);
                    }
                    if (oe[i]) {
                        it.quantity -= (oe[i].quantity || 1);
                        if (!it.quantity) {
                            delete logEntry.equipment[i];
                        }
                    }
                } 
            }
*/

            const ol = (this.state.levels||[]).length;
            const nl = (newState.levels||[]).length;
            
            logEntry.levels = (logEntry.levels||0)+nl-ol;
            logEntry.xp = (logEntry.xp||0)+(newState.xp||0)-(this.state.xp||0);
        }

        this.state = newState;
        this.computeValues();
        this.state.passive = this.passive;
        this.state.level = this.level;
        if (newState.npc) {
            Object.assign(newState, stdvalues.crLevelMap[this.level]); 
        }
        this.state.originDisplayName = this.getOriginDisplayName();
        if (!this.state.shape) {
            this.state.maxhp = this.maxHP;
        }
        this.state.ac = this.ac;
        delete this.state.diceRolls;
        const modifiers = this.state.modifiers;
        if (modifiers) {
            delete modifiers.background;
            delete modifiers.hero;
            delete modifiers.race;
            delete modifiers["items.all"];
            delete modifiers.class0;
            delete modifiers.class1;
            delete modifiers.class2;
            delete modifiers.class3;
            delete modifiers.class4;
            delete modifiers.class5;
        }

        let characterType = this.characterType;
        if (characterType=="monsters" && campaign.isCampaignGame()) {
            characterType="npcs";
        }
        campaign.updateCampaignContent(characterType, this.state);
    }

    pruneSessionLog(sl){
        for (let i in sl) {
            if (Object.keys(sl[i]).length == 0) {
                delete sl[i];
            }
        }
    }

    getCurrentSessionLog(state) {
        if (!state) {
            state= this.state;
        }
        let maxDate = 0;
        const log = state.sessionLog || {};
        const curDate = (new Date()).getTime();
        
        for (let i  in log) {
            maxDate = Math.max(maxDate, i);
        }
        if ((maxDate+(12*60*60*1000)) < curDate) {
            maxDate = curDate;
            if (!this.state.sessionLog) {
                this.state.sessionLog={};
            }
            this.state.sessionLog[maxDate] = {};
        }
        return maxDate;
    }

    get gender() {
        return this.state.gender;
    }

    get sessionLog() {
        return this.state.sessionLog||{};
    }

    set sessionLog(newsl) {
        this.setProperty("sessionLog", newsl);
    }

    getSessionLog(id) {
        return this.sessionLog[id]||{};
    }

    setSessionLog(id, data) {
        const sl = Object.assign(this.sessionLog);
        this.pruneSessionLog(sl);
        sl[id] = data;
        this.setProperty("sessionLog", sl);
    }

    newSessionLog(data) {
        const id = (new Date()).getTime();
        this.setSessionLog(id, data||{});
    }

    getProperty(prop) {
        return this.state[prop]||null;
    }

    get sharedAdventure() {
        return this.state.sharedAdventureName;
    }

    setSharedAdventure(name) {
        throw new Error("cannot set adventure on character");
    }

    addRoll(roll, prop, value) {
        if (this.readOnly) {
            return;
        }

        const c = Chat.addCharacterRoll(this, roll);
        if (prop) {
            if (value==null) {
                value = getRollSum(c.roll);
            }
            this.setProperty(prop,value);
        }
        return Object.assign((this.characterType=="monsters")?{monsterName:this.state.name}:{playerName:this.state.name}, c.roll);
    }

    doRoll(counts, source, action, prop) {
        const {rolls} = doRoll(counts);
        const roll = {dice:counts, rolls, source, action};
        return this.addRoll(roll,prop,null);
    }

    getDiceStats() {
        return this.namedValues;
    }

    replaceMetawords(str, estats) {
        if (!str || (typeof str != "string")) {
            return str;
        }
    
        let ret = replaceMetawords(str, this.namedValues, estats);
        let start,end;
        const tail = ret.search(/\s+(minute|minutes|hour|hours|round|rounds|day|days|second|seconds)$/i)
        if (tail==0) {
            return ret;
        } else if (tail>0) {
            start=ret.substring(0,tail);
            end = ret.substring(tail);
        } else {
            start = ret;
            end="";
        }
        if (start.match(/[^\d\s\+-]/)) {
            return ret;
        }
        const dice = getDiceFromString(start,0,true);
        ret= (dice.bonus||0)+end;
        return ret;
    }

    resolveDamages(damages, estats) {
        const stats = this.getDiceStats();
        for (let i in damages) {
            const d = damages[i];
            if (d) {
                d.dmg = getStringFromDice(getDiceFromString(d.dmg,0,true,stats, estats));
                if (isNaN(d.dmg) && d.dmg.startsWith("+")) {
                    d.dmg = d.dmg.substring(1);
                }
            }    
        }
    }

    resolveDice(dice, noplus) {
        if (!dice) {
            return dice;
        }
        const stats = this.getDiceStats();
        let ret = getStringFromDice(getDiceFromString(dice,0,true,stats));
        if (!ret.length) {
            ret = "+0"
        }
        if (noplus && ret.startsWith("+")) {
            ret = ret.substring(1);
        }
        return ret;
    }

    resolveFeatureDamages(damages,estats) {
        if (!damages) return null;
        damages=Object.assign({},damages)
        for (let i in damages) {
            damages[i] = this.replaceMetawords(damages[i], estats);
        }
        return damages;
    }

    resolveConditions(conditions,estats) {
        conditions = Object.assign({},conditions);
        if (conditions.duration) {
            conditions.duration = this.replaceMetawords(conditions.duration,estats);
        }

        if (conditions.features) {
            conditions.features = conditions.features.concat([]);

            for (let i in conditions.features) {
                conditions.features[i]=this.resolveFeature(conditions.features[i]);
            }
        }
        return conditions;
    }

    resolveFeature(f,estats) {
        if (!f) {
            return null;
        }
        f = Object.assign({},f);

        if (f.itemmod && f.itemmod.extraDamage) {
            f.itemmod = Object.assign({}, f.itemmod);
            f.itemmod.extraDamage = this.resolveFeatureDamages(f.itemmod.extraDamage, estats);
        }

        if (f.action) {
            f.action = Object.assign({},f.action);
            const {action}=f;
            if (action.dice) {
                action.dice=this.replaceMetawords(action.dice,estats);
            }
            if (action.extraDamage) {
                action.extraDamage = this.resolveFeatureDamages(action.extraDamage,estats);
            }
            if (action.temphp) {
                action.temphp=Object.assign({}, action.temphp);
                const {temphp}=action;
                if (temphp.hp) {
                    temphp.hp = this.resolveDice(temphp.hp);
                }
                if (temphp.duration) {
                    temphp.duration = this.replaceMetawords(temphp.duration, estats);
                }
            }
            if (action.conditions && action.conditions.duration) {
                action.conditions.duration = this.replaceMetawords(action.conditions.duration, estats);
            }

            if (action.duration) {
                action.duration = this.replaceMetawords(action.duration,estats);
            }

            if (action.enableFeature) {
                action.enableFeature=this.resolveFeature(action.enableFeature,estats);
            }
        }
        if (f.effects) {
            f.effects = this.resolveFeature(f.effects,estats);
        }
        if (f.d20Bonus){
            f.d20Bonus = Object.assign({},f.d20Bonus);
            f.d20Bonus.dice = this.replaceMetawords(f.d20Bonus.dice, estats);
        }
        return f;
    }

    getSpellTokenMRUList(spell, ignore) {
        let mru = (this.state.spelltokens||{})[spell]||[];

        if (ignore) {
            const fi = mru.findIndex(function (m) {return m.description==ignore});
            if (fi >= 0) {
                mru = mru.concat([]);
                mru.splice(fi, 1);
            }
        }
        return mru;
    }

    addSpellTokenMRUList(spell, a) {
        const spelltokens = Object.assign({}, this.state.spelltokens||{});
        let mru = (spelltokens[spell]||[]).concat([]);

        const fi = mru.findIndex(function (m) {return m.description==a.description});
        if (fi >= 0) {
            mru.splice(fi, 1);
        }
        mru.unshift(a);
        if (mru.length > 10) {
            mru = mru.slice(0,9);
        }
        spelltokens[spell] = mru;
        this.setProperty("spelltokens", spelltokens);
    }

    getItemMods() {
        const itemmods = [];
        const attuned = this.getProperty("attuned")||{};
        this.traverseFeatures(function (params) {
            const type = params.type;

            if (type == "item" || type=="itemextra") {
                // only include items if they are equiped and attuned if necessary
                const it = params.typeValue;
                if (!it.equip) {
                    return;
                }
                if (it.reqAttune) {
                    const id = (type=="item")?params.id:params.id.id;
                    if (!attuned[id]) {
                        return;
                    }
                }
            }

            const feature = params.feature;
            fixupFeature(feature);
            if (feature.itemmod) {
                itemmods.push(feature.itemmod);
            }
        });
        if (itemmods.length) {
            return itemmods;
        }
        return null;
    }

    getSpellMods() {
        const mods = [];
        const attuned = this.getProperty("attuned")||{};
        this.traverseFeatures(function (params) {
            const type = params.type;

            if (type == "item" || type=="itemextra") {
                // only include items if they are equiped and attuned if necessary
                const it = params.typeValue;
                if (!it.equip) {
                    return;
                }
                if (it.reqAttune) {
                    const id = (type=="item")?params.id:params.id.id;
                    if (!attuned[id]) {
                        return;
                    }
                }
            }

            const feature = params.feature;
            fixupFeature(feature);
            if (feature.spellmod) {
                mods.push(feature.spellmod);
            }
        });
        if (mods.length) {
            return mods;
        }
        return null;
    }

    getFeatureHref(feature, usageId) {
        if (this.isMon) {
            return "#charfeature?fname="+encodeURIComponent(feature.name||"")+"&fhtml="+encodeURIComponent(htmlFromEntry(feature.entries));
        }
        return "#charfeature?id="+encodeURIComponent(usageId)+"&cid="+encodeURIComponent(this.name);
    }

    getActions() {
        const t=this;
        const actions=[], elist=[], slist=[], clist=[];

        this.traverseFeatures(function (params) { 
            const {feature, usageId} = params;

            if (feature) {
                const {action, effects, createitem, altcreateitem, alt2createitem,usage}=feature;
                const href = t.getFeatureHref(feature, usageId);
                if (action) {
                    const actionTypeVal = action.usageType||null;
                    const actionType = stdvalues.actionTypeMap[actionTypeVal]||null;

                    const a = {params,enableAction:true, type:"action", href, featureName:nameFromFeatureParams(params),actionTypeVal,actionType};
                    let active;

                    if (usage?.usageName) {
                        a.useGroupName = usage.usageName;
                    }
                    if (action.consumeUsage) {
                        switch (action.consumeType) {
                            default:
                            case "uses": {
                                break;
                            }
                            case "spell":{
                                a.useGroupName="Spell Slots";
                                break;
                            }
                            case "hd":{
                                a.useGroupName="Hit Dice";
                                break;
                            }
                        }
                        if (!t.getActionUsageOptions(feature, usageId).length) {
                            a.enableAction=false;
                        }
                    }

                    active = t.getFeatureUsage(t.getActiveId(action, usageId)) || null;

                    switch (action.actionOperation) {
                        default:
                        case "attack": {
                            let damages = damagesFromExtraDamage(action.dice, action.type, action.extraDamage||{});
                            t.resolveDamages(damages);
                            if (damages) {
                                a.damages = damages;
                            }
                            if (action.temphp) {
                                a.temphp= t.resolveDice(action.temphp.hp,true);
                            }
                            if (action.attackRoll) {
                                a.attackRoll = t.resolveDice(action.attackRoll);
                            }
                            active=false;
                            if (action.save) {
                                a.save=action.save;
                                a.saveDC = action.saveDC||null;
                            }
                            break;
                        }
                        case "summon":
                        case "activate":{
                            if (active) {
                                if (action.duration) {
                                    const turns = t.getFeatureUsage(usageId+".duration");
                                    a.duration=turns||t.replaceMetawords(action.duration);;
                                }
                            }
                            break;
                        }
                        case "shape":
                        case "convert": {
                            break;
                        }
                    }

                    a.active = active;
                    actions.push(a);
                }

                if (createitem||altcreateitem||alt2createitem) {
                    const c = {params, type:"createitem", active:false,href, enableAction:true, featureName:nameFromFeatureParams(params)}
                    const equipment = t.equipment;
                    for (let i in equipment) {
                        const it = equipment[i];
                        if (it.extraFeatures && it.extraFeatures[usageId]) {
                            c.active = true;
                        }   
                    }


                    if (usage?.usageName) {
                        c.useGroupName = usage.usageName;
                    }
                    if (!c.active) {
                        if (createitem?.consumeUsage) {
                            switch (createitem?.consumeType) {
                                default:
                                case "uses": {
                                    break;
                                }
                                case "spell":{
                                    c.useGroupName="Spell Slots";
                                    break;
                                }
                                case "hd":{
                                    c.useGroupName="Hit Dice";
                                    break;
                                }
                            }
                            if (!t.getActionUsageOptions(feature, usageId,true).length) {
                                c.enableAction=false;
                            }
                        }
                    } else {
                        if (createitem?.duration) {
                            const turns = t.getFeatureUsage(usageId+".cduration");
                            c.duration=turns||t.replaceMetawords(createitem.duration);
                        }
                    }

                    actions.push(c);
                }
                if (effects) {
                    const activeId = usageId+".toggle";
                    const active = t.getFeatureUsage(activeId);
                    const href = t.getFeatureHref(effects, usageId+".togglef");

                    const e = {params, activeId, active, href};
        
                    elist.push(e);
                }
            }

        });

        const conditions = t.conditions;
        for (let cond in conditions) {
            let c = conditions[cond];
            if (typeof c != "object") {
                c = {duration:c};
            }
            const {spell}=c;

            if (spell) {
                const href = "#spell?id="+encodeURIComponent(spell.spellId);
                slist.push({spell, name:cond, condition:c, href});
            } else {
                clist.push({condition:c, name:cond})
            }
        }
        //console.log("actions", actions,elist, slist, clist);
        return {actions, elist, slist, clist};
    }

    getSaveDCVal(saveDC) {
        let ret = 0;
        if (!saveDC) {
            saveDC = "str";
        } else if (!Array.isArray(saveDC)) {
            saveDC=[saveDC];
        }
        for (let s of saveDC) {
            const val = isNaN(s)?(((this.getAbility(s||"str")||{}).modifier||0)+8+this.proficiency):Number(s);
            if (val > ret) {
                ret=val;
            }
        }
        return ret;
    }

    getBestSave(saves) {
        let bestVal,save;

        if (!saves) {
            return saves;
        } else if (!Array.isArray(saves)) {
            saves=[saves];
        }
        for (let s of saves) {
            const a =this.abilities[s];
            if (!save || (a.spellSave > bestVal)) {
                bestVal=a.spellSave;
                save= s;
            }
        }
        return save;
    }

    getBonusMod(bonus) {
        if (isNaN(bonus)) {
            return Number(this.namedValues[bonus] || 0);
        } else {
            return Number(bonus||0);
        }
    }

    t(str) {
        if (!this.tlTable) {
            const tlTable = {};
            const classes = {};
    
            for (let x in this.classes) {
                const cls = this.classes[x];
                const cclass = (cls.cclass||"").toLowerCase();
                const subclass = (cls.subclass||"").toLowerCase();
                if (!classes[cclass]) {
                    classes[cclass]=true;
                    const classInfo = campaign.getClassInfo(cclass);
                    if (classInfo && classInfo.translations) {
                        Object.assign(tlTable, classInfo.translations)
                    }
                }

                if (subclass && !classes[subclass]){
                    classes[subclass]=true;
                    const classInfo = campaign.getSubclassInfo(subclass);
                    if (classInfo && classInfo.translations) {
                        Object.assign(tlTable, classInfo.translations)
                    }
                }
            }
            this.tlTable = tlTable;
        }
        return this.tlTable[str]||str;
    }

    spellLevelName(prefix, post) {
        const r = this.isBF?"Circle":"level";
        return (prefix||"")+r+(post||"");
    }

    getCharacterWebSearchDefault() {
        let search = "";
        if (this.is5E && this.race) {
            const raceInfo = campaign.getRaceInfo(this.race);
            if (raceInfo) {
                if (raceInfo.baserace) {
                    const br = campaign.getRaceInfo(raceInfo.baserace);
                    search=(br?.displayName)||raceInfo.displayName||"";
                } else {
                    search=raceInfo.displayName||"";
                }
            }
        }
        if (this.levels) {
            const l1 = this.levels[1];
            if (l1 && l1.cclass) {
                const clsInfo = campaign.getClassInfo(l1.cclass);
                search = search + " "+((clsInfo?.displayName)||"");
            }
        }
        const gender=this.getProperty("gender");
        if (gender) {
            search=search+" " + gender;
        }
        return search;
    }
}

function getFeaturesTable(cls, subclass, options) {
    let fluff =null;
    const table = [];
    let sub = null;

    if (subclass) {
        sub=campaign.getSubclassInfo(subclass);
        if (sub) {
            fluff = {name:sub.name, entries:[sub.fluff]};
        }
    }

    for (let r=0; r<20; r++){
        const row=[];
        const baseName = "feature"+r+".";

        for (let f in cls.classFeatures[r]){
            getOptionFeature(row, cls.classFeatures[r][f], baseName+(cls.classFeatures[r][f].name||""), options);
        }
        if (sub) {
            for (let sf in sub.classFeatures[r]) {
                getOptionFeature(row, sub.classFeatures[r][sf], baseName+(sub.classFeatures[r][sf].name||""), options);
            }
        }
        table.push(row);
    }

    return {table:table, fluff:fluff};
}

function getOptionedFeatures(features, baseName, options) {
    const ret = []
    for (let f in features){
        getOptionFeature(ret, features[f], baseName+(features[f].name||""), options);
    }
    return ret;
}

function getOptionFeature(ret, feature, baseName, options) {
    if (!options) {
        ret.push(feature);
        return;
    }

    if (feature.spellPick) {
        const pickedSpells = options[baseName+".spellPick"];
        if (pickedSpells) {
            feature = Object.assign({pickedSpells}, feature);
        }
    }

    if (feature.customPick) {
        let pickedCustom = options[baseName+".customPick"];
        if ((feature.customPick.customOptions && (feature.customPick.customOptions.length==1))) {
            pickedCustom={};
            pickedCustom[feature.customPick.customOptions[0]]=1;
        }

        if (pickedCustom) {
            feature = Object.assign({pickedCustom}, feature);
        }
    }

    if (!feature.options) {
        ret.push(feature);
        getFeatOptions(ret, feature, baseName, options);
        return;
    }
    const fid = baseName+".selected";
    const sel = options[fid];
    if (!sel) {
        ret.push(feature);
        getFeatOptions(ret, feature, baseName, options);
        return;
    }
    const option = feature.options.find(function (f) {return f.name==sel});
    if (!option) {
        ret.push(feature);
        getFeatOptions(ret, feature, baseName, options);
        return;
    }

    const newFeature = Object.assign({}, option);
    newFeature.name = feature.name +": " +option.name;
    newFeature.entries = (feature.entries||[]).concat([option]);
    newFeature.isOption = true;

    if (option.spellPick) {
        const pickedSpells = options[baseName+".spellPick"];
        if (pickedSpells) {
            newFeature.pickedSpells = pickedSpells;
        }
    }

    ret.push(newFeature);
    getFeatOptions(ret, newFeature, baseName, options);
}

function getFeatOptions(ret, feature, baseName, options) {
    if (!feature.grantFeat && !feature.pickFeat) {
        return;
    }
    const feat = campaign.getFeatInfo(feature.grantFeat || options[baseName+".selectedFeat"]);
    if (!feat) {
        return;
    }

    for (let i in feat.features) {
        let f = feat.features[i];
        const featBaseName = baseName+".feat."+(f.name||"");

        if (!f.name) {
            f = Object.assign({}, f);
            f.name = feat.displayName;
        }
        getOptionFeature(ret, f, featBaseName, options)
    }
}

function getMergedCustomLevels(classInfo, subclassInfo) {
    if (!subclassInfo || !subclassInfo.customLevels) {
        return classInfo.customLevels||[];
    }

    let mergedCustomLevels = (classInfo.customLevels||[]).concat([]);
    const subCustomLevels = subclassInfo.customLevels || [];

    for (let i in subCustomLevels) {
        const cl = subCustomLevels[i];
        const pos = mergedCustomLevels.findIndex(function(f) {return f.name == cl.name});
        if (pos >= 0) {
            mergedCustomLevels[pos] = Object.assign({}, mergedCustomLevels[pos]);
            mergedCustomLevels[pos].levelsCount = mergedCustomLevels[pos].levelsCount.concat([]);
            const ml = mergedCustomLevels[pos].levelsCount;
            const sl = cl.levelsCount;
            for (let i in ml) {
                ml[i] += sl[i];
            }
        } else {
            mergedCustomLevels.push(cl);
        }
    }
    return mergedCustomLevels;
}

function fixupFeature(feature) {
    if (!feature) {
        return;
    }
    if (feature.rangedAttackBonus) {
        feature.itemmod = {
            attackBonus:feature.rangedAttackBonus,
            itemType:["R"]
        }
        delete feature.rangedAttackBonus;
    }
    if (feature.twoWeaponFighting) {
        if (feature.itemmod) {
            console.log("conflicting feature!!");
        }
        feature.itemmod = {
            itemType:["M"],
            secondWeaponBonus:true
        }
        delete feature.twoWeaponFighting;
    }
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function itemMatchFilter(it, filter) {
    if (!filter) {
        return true;
    }
    const matchSome=filter.matchSome;

    if (filter.excludeProperties) {
        for (let i in it.property) {
            const p=it.property[i];
            if (filter.excludeProperties.includes(p)) {
                return false;
            }
        }
    }

    if (filter.name) {
        let matchFilter;
        let vals = filter.name.split(',');
        for (let i in vals) {
            const reg = new RegExp(escapeRegExp(vals[i].trim()), "i");
            if (reg.test(it.displayName||"") || reg.test(it.weaponProficiency)) {
                matchFilter=true;
                break;
            }
        }
        if (matchFilter) {
            if (matchSome) {
                return true;
            }
        } else if (!matchSome) {
            return false;
        }
    }

    if (filter.rarity?.length) {
        if ((filter.rarity.includes(it.rarity) && !(it.rarity=="None" && it.extraFeatures)) || (filter.rarity.includes("Magical")&&(it.rarity!="None" || it.extraFeatures))) {
            if (matchSome) {
                return true;
            }
        } else if (!matchSome) {
            return false;
        }
    }

    if (filter.weaponCategory?.length) {
        if (filter.weaponCategory.includes(it.weaponCategory)) {
            if (matchSome) {
                return true;
            }
        } else if (!matchSome) {
            return false;
        }
    }

    if (filter.itemType?.length) {
        if (filter.itemType.includes(getItemType(it))) {
            if (matchSome) {
                return true;
            }
        } else if (!matchSome) {
            return false;
        }
    }

    if (filter.properties?.length) {
        let match=false;
        for (let i in it.property) {
            const p=it.property[i];
            if (filter.properties.find(function (m) {return p==m || p?.name==m?.name})) {
                match=true;
            }
        }
        if (match) {
            if (matchSome) {
                return true;
            }
        } else if (!matchSome) {
            return false;
        }
    }

    if (matchSome) {
        return false;
    }
    return true;
}

function getItemType(it) {
    if (it.type) {
        return it.type;
    }

    if (it.coin) {
        return "coin";
    }

    if (it.nametype) {
        return it.nametype;
    }

    if (it.weapon || it.dmg1) {
        return "M";
    }
    if (it.armor || it.shield) {
        return "LA";
    }

    if (it.wondrous) {
        return "WON";
    }

    //console.log("unknown type", it.displayName, it);
    return "";
}

function getModFromFeature(rf, mod, options, baseName, abilityOverride,level, characterLevel) {
    if (rf.options) {
        const sel = options[baseName+".selected"];
        if (!sel) {
            //console.log("missing", rf, options, baseName+".selected");
            mod.missingConfig = true;
            return;
        }
        const option = rf.options.find(function (f) {return f.name==sel});
        if (!option) {
            //console.log("missing couldn't find feature", rf.options, sel);
            mod.missingConfig = true;
            return;
        }
        rf = option;
    }

    if ((rf.pickFeat || rf.grantFeat)) {
        const feat = campaign.getFeatInfo(rf.grantFeat || options[baseName+".selectedFeat"]);
        if (!feat) {
             mod.missingConfig=true;
        }
    }

    if (rf.customAttribute) {
        if (!options[baseName+".te."+rf.customAttribute]) {
            mod.missingConfig=true;
        }
    }

    if (rf.languages) {
        mod.languages = mergeBasic(mod, mod.languages||{},getFullModsProficiency(rf.languages, baseName+"language",options));
    }

    if (rf.skills) {
        mod.skills = mergeProficiency(mod, mod.skills||{}, getFullModsProficiency(rf.skills, baseName+"skill",options,true));
    }

    if (rf.checks) {
        mod.checks = mergeProficiency(mod, mod.checks||{}, getFullModsProficiency(rf.checks, baseName+"check",options,true));
    }

    if (rf.abilitySaves) {
        mod.abilityScores = mergeProficiency(mod, mod.abilityScores||{}, getFullModsProficiency(rf.abilitySaves, baseName+"abilitySaves",options,true));
    }

    if (rf.tools) {
        mod.tools =  mergeProficiency(mod, mod.tools||{}, getFullModsProficiency(rf.tools, baseName+"tool",options, true));
    }
    
    if (rf.weapons) {
        mod.weapons =  mergeBasic(mod, mod.weapons||{}, getFullModsProficiency(rf.weapons, baseName+"weapon",options));
    }

    if (rf.armor) {
        mod.armor =  mergeBasic(mod, mod.armor||{}, getFullModsProficiency(rf.armor, baseName+"armor",options));
    }

    if (rf.resist) {
        mod.resist =  mergeBasic(mod, mod.resist||{}, getFullModsProficiency(rf.resist, baseName+"resist",options));
    }

    if (rf.immune) {
        mod.immune =  mergeBasic(mod, mod.immune||{}, getFullModsProficiency(rf.immune, baseName+"immune",options));
    }

    if (rf.vulnerable) {
        mod.vulnerable =  mergeBasic(mod, mod.vulnerable||{}, getFullModsProficiency(rf.vulnerable, baseName+"vulnerable",options));
    }

    if (rf.conditionImmune) {
        mod.conditionImmune =  mergeBasic(mod, mod.conditionImmune||{}, getFullModsProficiency(rf.conditionImmune, baseName+"conditionImmune",options));
    }

    if (rf.unarmedAttack) {
        if (!mod.unarmedAttacks) {
            mod.unarmedAttacks = [];
        }
        mod.unarmedAttacks.push(rf.unarmedAttack);
    }

    if (rf.hpLevelMod) {
        mod.hpMod = (rf.hpLevelMod * characterLevel) + (mod.hpMod||0);
    }

    if (rf.maxHP) {
        if (typeof rf.maxHP == "string") {
            mod.maxHP = mod.maxHP?mod.maxHP+"+"+rf.maxHP:rf.maxHP;
        } else {
            mod.hpMod = rf.maxHP + (mod.hpMod||0);
        }
    }

    if (rf.attunement) {
        if (!mod.attunement) {
            mod.attunement={};
        }
        mod.attunement.saveBonus = rf.attunement.saveBonus||mod.attunement.saveBonus||0;
        mod.attunement.extra = (mod.attunement.extra||0)+(rf.attunement.extra||0);
    }

    switch (rf.acBonusType) {
        case "noarmornoshield":
            mod.noArmorShieldBonus = (mod.noArmorShieldBonus||0) + (rf.acBonus||0);
            mod.noArmorShieldAbilityBonus = mergeFeatureMod(mod.noArmorShieldAbilityBonus, rf.acAbilitiesBonus);
            break;
        case "noarmor":
            mod.noArmorBonus = (mod.noArmorBonus||0) + (rf.acBonus||0);
            mod.noArmorAbilityBonus = mergeFeatureMod(mod.noArmorAbilityBonus, rf.acAbilitiesBonus);
            break;
        case "uptolight":
            mod.noArmorBonus = (mod.noArmorBonus||0) + (rf.acBonus||0);
            mod.noArmorAbilityBonus = mergeFeatureMod(mod.noArmorAbilityBonus, rf.acAbilitiesBonus);
            mod.lightArmorBonus = (mod.lightArmorBonus||0) + (rf.acBonus||0);
            mod.lightArmorAbilityBonus = mergeFeatureMod(mod.lightArmorAbilityBonus, rf.acAbilitiesBonus);
            break;
        case "light":
            mod.lightArmorBonus = (mod.lightArmorBonus||0) + (rf.acBonus||0);
            mod.lightArmorAbilityBonus = mergeFeatureMod(mod.lightArmorAbilityBonus, rf.acAbilitiesBonus);
            break;
        case "medium":
            mod.mediumArmorBonus = (mod.mediumArmorBonus||0) + (rf.acBonus||0);
            mod.mediumArmorAbilityBonus = mergeFeatureMod(mod.mediumArmorAbilityBonus, rf.acAbilitiesBonus);
            break;
        case "heavy":
            mod.heavyArmorBonus = (mod.heavyArmorBonus||0) + (rf.acBonus||0);
            mod.heavyArmorAbilityBonus = mergeFeatureMod(mod.heavyArmorAbilityBonus, rf.acAbilitiesBonus);
            break;
        case "any":
            mod.anyArmorBonus = (mod.anyArmorBonus||0) + (rf.acBonus||0);
            mod.anyArmorAbilityBonus = mergeFeatureMod(mod.anyArmorAbilityBonus, rf.acAbilitiesBonus);
            break;
        default:
            if (rf.acBonusType) {
                console.log("unknown acBonusType", rf.acBonusType);
            }
            mod.acBonus = (mod.acBonus||0) + (rf.acBonus||0);
            mod.allArmorAbilityBonus = mergeFeatureMod(mod.allArmorAbilityBonus, rf.acAbilitiesBonus);
            break;
    }

    if (rf.spellPick) {
        const spellPick = rf.spellPick;
        let cantrips=0, spells=0;
        const selected=options[baseName+".spellPick"];
        for (let i in selected) {
            if (selected[i].level) {
                spells++;
            } else {
                cantrips++;
            }
        }
        if ((spellPick.spells&&(spells<spellPick.spells)) || (spellPick.cantrips&&(cantrips<spellPick.cantrips))) {
            if ((spellPick.spellLevel!="any") || (((spellPick.spells||0)+(spellPick.cantrips||0))<(spells+cantrips))) {
                mod.missingConfig=true;
            }
            //console.log("missing spell config");
        }
    }

    if (rf.addSpellSlots) {
        if (!mod.slots) {
            mod.slots=[0,0,0,0,0,0,0,0,0];
        }
        for (let i in rf.addSpellSlots.slots) {
            mod.slots[i] += (rf.addSpellSlots.slots[i]||0);
        }
        mod.pactSlots = (mod.pactSlots||0)+(rf.addSpellSlots.pactSlots||0);
    }

    if (rf.spellLevelAdvance) {
        let cname; 
        if (rf.spellLevelAdvance == "any") {
            cname = options[baseName+".spellLevelAdvance"];
        } else {
            cname = rf.spellLevelAdvance;
        }
        if (cname) {
            if (!mod.spellLevelAdvance) {
                mod.spellLevelAdvance = {};
            }
            mod.spellLevelAdvance[cname.toLowerCase()] = (mod.spellLevelAdvance[cname.toLowerCase()]||0)+1;
        } else {
            mod.missingConfig=true;
        }
    }

    if (rf.customPick) {
        const customPick = rf.customPick;
        let count=0;
        const selected=options[baseName+".customPick"];
        for (let i in selected) {
            count++;
        }
        if (customPick.count&&(count < customPick.count)&&(!customPick.customOptions || (customPick.customOptions.length!=1))){
            mod.missingConfig=true;
        }
    }

    if (rf.customPickMod) {
        const {customPickMod} = rf;
        if (!mod.customMods) {
            mod.customMods={};
        }
        mod.customMods[customPickMod.customTable] = (mod.customMods[customPickMod.customTable]||"")+","+customPickMod.keywords;
    }

    if (rf.extraAttack) {
        mod.extraAttack = (mod.extraAttack||0) + 1;
    }
    mod.dueling = (mod.dueling||0)+(rf.dueling||0);
    mod.dualACBonus = (mod.dualACBonus||0) + (rf.dualACBonus||0);
    mod.mediumArmorMax = Math.max(mod.mediumArmorMax||0, Number(rf.mediumArmorMax||0));
    
    mod.perceptionBonus = (mod.perceptionBonus||0)+(rf.perceptionBonus||0);
    mod.proficiencyBonus = (mod.proficiencyBonus||0)+(rf.proficiencyBonus||0);
    if (rf.initiativeBonus) {
        if (isNaN(rf.initiativeBonus)) {
            if (!mod.initiativeAbilityBonus) {
                mod.initiativeAbilityBonus={};
            }
            mod.initiativeAbilityBonus[rf.initiativeBonus]=true;
        } else {
            mod.initiativeBonus = (mod.initiativeBonus||0)+(rf.initiativeBonus||0);
        }
    }
    mod.spellDCBonus = (mod.spellDCBonus||0)+(rf.spellDCBonus||0);
    mod.spellAttackBonus = (mod.spellAttackBonus||0)+(rf.spellAttackBonus||0);
    if (rf.savingThrowBonus) {
        const stb = rf.savingThrowBonus;
        if (!mod.savingThrowBonusAbilities) {
            mod.savingThrowBonusAbilities={};
        }
        if (rf.savingThrowBonusAbilities) {
            for (let i in rf.savingThrowBonusAbilities) {
                const stba = rf.savingThrowBonusAbilities[i];
                if (!mod.savingThrowBonusAbilities[stba]) {
                    mod.savingThrowBonusAbilities[stba]={};
                }
                if (isNaN(stb)) {
                    mod.savingThrowBonusAbilities[stba][stb]=true;
                } else {
                    mod.savingThrowBonusAbilities[stba].all=(mod.savingThrowBonusAbilities[stba].all||0) + (stb||0);
                }
            }
        } else {
            if (isNaN(stb)) {
                if (!mod.savingThrowBonusAbilities.all) {
                    mod.savingThrowBonusAbilities.all={};
                }
                mod.savingThrowBonusAbilities.all[stb]=true;
            } else {
                mod.savingThrowBonus = (mod.savingThrowBonus||0)+(stb||0);
            }
        }
    }

    if (rf.skillCheckBonus) {
        const stb = rf.skillCheckBonus;
        if (!mod.skillCheckBonusAbilities) {
            mod.skillCheckBonusAbilities={};
        }
        if (rf.skillCheckBonusAbilities) {
            for (let i in rf.skillCheckBonusAbilities) {
                const stba = rf.skillCheckBonusAbilities[i];
                if (!mod.skillCheckBonusAbilities[stba]) {
                    mod.skillCheckBonusAbilities[stba]={};
                }
                if (isNaN(stb)) {
                    mod.skillCheckBonusAbilities[stba][stb]=true;
                } else {
                    mod.skillCheckBonusAbilities[stba].all=(mod.skillCheckBonusAbilities[stba].all||0) + (stb||0);
                }
            }
        } else {
            if (isNaN(stb)) {
                if (!mod.skillCheckBonusAbilities.all) {
                    mod.skillCheckBonusAbilities.all={};
                }
                mod.skillCheckBonusAbilities.all[stb]=true;
            } else {
                mod.skillCheckBonus = (mod.skillCheckBonus||0)+(stb||0);
            }
        }
    }

    if (rf.baseAC) {
        if (!mod.baseACs) {
            mod.baseACs=[];
        }
        mod.baseACs.push(rf.baseAC);
    }

    if (rf.speed) {
        if (!mod.speed) {
            mod.speed = {};
        }
        for (let x in rf.speed) {
            const s = rf.speed[x];
            const cs = mod.speed[x];

            if (!cs) {
                mod.speed[x] = Object.assign({},s);
            } else {
                if (s.number) {
                    cs.number = Math.max(cs.number||0, s.number);
                }
                if (s.walking) {
                    cs.walking = true;
                }
            }
        }
    }

    if (rf.senses) {
        if (!mod.senses) {
            mod.senses = {};
        }
        for (let x in rf.senses) {
            const s = rf.senses[x];
            const cs = mod.senses[x];

            if (!cs) {
                mod.senses[x] = Object.assign({},s);
            } else {
                if (s.number) {
                    cs.number = Math.max(cs.number||0, s.number);
                }
            }
        }
    }
    
    switch (rf.speedAvailability) {
        case "noarmornoshield":
            mod.noArmorShieldSpeedBonus = (mod.noArmorShieldSpeedBonus||0) + (rf.speedAdjustment||0);
            break;
        case "noarmor":
            mod.noArmorSpeedBonus = (mod.noArmorSpeedBonus||0) + (rf.speedAdjustment||0);
            break;
        case "light":
            mod.lightArmorSpeedBonus = (mod.lightArmorSpeedBonus||0) + (rf.speedAdjustment||0);
            break;
        case "medium":
            mod.mediumArmorSpeedBonus = (mod.mediumArmorSpeedBonus||0) + (rf.speedAdjustment||0);
            break;
        default:
            if (rf.speedAvailability) {
                console.log("unknown speedAvailability", rf.speedAvailability);
            }
            mod.speedBonus = (mod.speedBonus||0) + (rf.speedAdjustment||0);
            break;
    }

    if (rf.ritualCaster) {
        mod.ritualCaster=true;
    }

    if (rf.ability) {
        if (!mod.abilityScores) {
            mod.abilityScores = {};
        }

        for (let a in rf.ability) {
            if (["str", "dex", "con", "int", "wis", "cha"].includes(a)) {
                let useAbility = a;
                if (abilityOverride) {
                    useAbility = options[baseName+".abilitymod."+a]||a;
                }
                if (!mod.abilityScores[useAbility]) {
                    mod.abilityScores[useAbility]={};
                }
                const amod = mod.abilityScores[useAbility];
                if (!amod.modifier) {
                    amod.modifier=rf.ability[a];
                } else {
                    amod.modifier += rf.ability[a];
                }
            }
            if (["minstr", "mindex", "mincon", "minint", "minwis", "mincha"].includes(a)) {
                const aonly = a.substr(3,3);
                if (!mod.abilityScores[aonly]) {
                    mod.abilityScores[aonly]={};
                }
                const amod = mod.abilityScores[aonly];
                amod.minValue = Math.max(amod.minValue||0, rf.ability[a]||0);
            }
            if (["maxstr", "maxdex", "maxcon", "maxint", "maxwis", "maxcha"].includes(a)) {
                const aonly = a.substr(3,3);
                if (!mod.abilityScores[aonly]) {
                    mod.abilityScores[aonly]={};
                }
                const amod = mod.abilityScores[aonly];
                amod.maxValue = Math.max(amod.maxValue||0, rf.ability[a]||0);
            }
        }

        if (rf.ability.choose) {
            const amount = rf.ability.amount||1;
            for (let loop=1; loop<=rf.ability.choose; loop++) {
                const a = options[baseName+".abilitymod."+loop];

                if (a) {
                    if (!mod.abilityScores[a]) {
                        mod.abilityScores[a]={};
                    }
                    const amod = mod.abilityScores[a];
                    if (!amod.modifier) {
                        amod.modifier=amount;
                    } else {
                        amod.modifier +=amount;
                    }
                } else {
                    mod.missingConfig=true;
                }
            }
        }
    }

    let {usageName,valueName} = (rf.usage ||{});
    if (usageName) {
        if (!mod.usageValues) {
            mod.usageValues={};
        }
        if (!mod.usageValues[usageName]) {
            mod.usageValues[usageName]=[];
        }
        mod.usageValues[usageName].push({usage:rf.usage, level});
    }
    if (rf.addUses) {
        if (!mod.usageBonuses) {
            mod.usageBonuses={};
        }
        mod.usageBonuses[rf.addUses.useName||""]=(mod.usageBonuses[rf.addUses.useName||""]||0)+(rf.addUses.useCount||0);
    }
    if (valueName) {
        valueName=valueName.toLowerCase().trim();
        if (rf.usage.displayLevels) {
            const lv = rf.usage.displayLevels[(level||1)-1];
            if (!mod.levelValues) {
                mod.levelValues={};
            }
            mod.levelValues[valueName] = Math.max(mod.levelValues[valueName]||0, lv);
        }

    }

    if (rf.d20Bonus && rf.d20Bonus.dice) {
        let {dice,minus, initiative, save,skill,attack,spell, abilities} = rf.d20Bonus;
        const v = [{dice,minus}];
        if (initiative) {
            mod.initiativeDice = (mod.initiativeDice||[]).concat(v);
        }
        if (save) {
            mod.saveDice = (mod.saveDice||[]).concat(v);

        }

        if (skill) {
            mod.skillDice = (mod.skillDice||[]).concat(v);

            if (!mod.abilitiesDice) {
                mod.abilitiesDice={};
            }
            for (let abName of ["str","dex", "con","wis","int","cha"]) {
                mod.abilitiesDice[abName] = (mod.abilitiesDice[abName]||[]).concat(v);
            }
        } else if (abilities) {
            if (!mod.abilitiesDice) {
                mod.abilitiesDice={};
            }
            for (let abName of abilities) {
                mod.abilitiesDice[abName] = (mod.abilitiesDice[abName]||[]).concat(v);
            }
        }

        if (attack) {
            mod.attackDice = (mod.attackDice||[]).concat(v);
        }
        if (spell) {
            mod.spellDice = (mod.spellDice||[]).concat(v);
        }
    }

    if (rf.sizeAdjust) {
        mod.sizeAdjust = (mod.sizeAdjust||0)+rf.sizeAdjust;
    }

    if (rf.skillAlt) {
        mod.skillAlt = (mod.skillAlt||[]).concat([rf.skillAlt]);
    }
}

function getFullModsProficiency(l, baseName, options, useProficiency) {
    if (!l) {
        return {};
    }
    const mods = {};
    if (l.choose) {
        const count = l.choose;

        for (let i=0; i< count; i++){
            const v = options[baseName+"."+i] || options[baseName+(i+1)]; // or because of capatability mode with previous lang usage
            if (v){
                if (useProficiency) {
                    mods[v] = {proficiency:l.proficient || "proficient"};
                } else {
                    mods[v] = true;
                }
            } else {
                //console.log("missing selection", options, baseName+"."+i);
                mods.missingConfig = true;
            }
        }
    }
    const defaults = Array.isArray(l)?l:(l.defaults||[]);
    for (let i in defaults){
        const d = defaults[i];
        if (useProficiency) {
            mods[d] = {proficiency:l.proficient || "proficient"};
        } else {
            mods[d] = true;
        }
    }
    return mods;
}


function mergeFeatureMod(mf, list) {
    if (!mf) {
        mf={};
    }
    for (let i in list) {
        mf[list[i]]=true;
    }
    return mf;
}

function getImageUrlInfoFromCharacter(state, noShape) {
    let tokenArt;
    if (state.shape && !noShape) {
        if (state.shape.tokenArt) {
            tokenArt = state.shape.tokenArt;
        } else {
            return {url:state.shape.imageURL || state.imageURL};
        }
    } else {
        tokenArt = state.tokenArt;
    }
    if (tokenArt) {
        const art = campaign.getArtInfo(tokenArt);
        if (art) {
            return {url:art.url, art};
        }
    }
    return {url:state.imageURL};

}

function getImageUrlFromCharacter(state, noShape) {
    return getImageUrlInfoFromCharacter(state, noShape).url;
}

function findUrlArtwork(url) {
    const artlist = campaign.getArt();
    for (let i in artlist) {
        const art = artlist[i];
        if (art.url==url){
            return art.name;
        }
    }
    return null;
}

function getCharacterFromId(characterId) {
    let charInfo,type, readOnly=!campaign.adminStatus.level;
    charInfo = campaign.getMyCharacterInfo(characterId);
    if (campaign.isDefaultCampaign()) {
        type = "mycharacters";
        readOnly=false;
    } else{
        if (!charInfo) {
            charInfo = campaign.getPlayerInfo(characterId);
        } else {
            readOnly=false; // not read only since it is your character (found in my characters above)
        }
        type = "players";
    }
    if (!charInfo) {
        charInfo = campaign.getMonsterInfo(characterId);
        if (charInfo && charInfo.npc) {
            type = "monsters";
        } else {
            // don't update the character if it isn't an NPC
            charInfo=null;
        }
    }
    if (campaign.isGMCampaign() || ((type=="players") && ((campaign.getGameState().sharing||"readonly")=="open"))) {
        readOnly=false;
    }
    if (charInfo) {
        const character = new Character(charInfo, type, readOnly);
        return character;
    }
    return null;
}

function shouldDurationExpire(duration, long) {
    if (!duration) {
        return false;
    }

    let ret = 0;
    if (duration.match(/\s*\d+\s*rounds?\s*/i)) {
        const l = duration.match(/\d+/);
        ret= Number(l[0]);
    } else if (duration.match(/\s*\d+\s*turns?\s*/i)) {
        const l = duration.match(/\d+/);
        ret= Number(l[0]);
    } else if (duration.match(/\s*\d+\s*minutes?\s*/i)) {
        const l = duration.match(/\d+/);
        return Number(l[0])<=(long?24*60:60)
    } else if (duration.match(/\s*\d+\s*min\s*/i)) {
        const l = duration.match(/\d+/);
        return Number(l[0])<=(long?24*60:60)
    } else if (duration.match(/\s*\d+\s*hours?\s*/i)) {
        const l = duration.match(/\d+/);
        return Number(l[0])<=(long?24:1)
    } else if (duration.match(/\s*\d+\s*days?\s*/i)) {
        const l = duration.match(/\d+/);
        return Number(l[0])<=(long?1:0)
    } else {
        return false;
    }

    return ret < (long?8*600:600);
}

function nameFromFeatureParams(params) {
    const {feature, typeValue} = params;
    let fn;
    if (feature) {
        const {usage} = feature;

        fn = feature?.name || (typeValue?.displayName) || "";
        if (usage && usage.usageName) {
            if (fn.toLowerCase().startsWith(usage.usageName.toLowerCase()+":")) {
                fn = fn.substring(usage.usageName.length+1).trim();
            }
        }
    }
    return fn;
}

function addExtraDice(base, extra, useCount, baseUseCount) {
    if (!extra || !baseUseCount){
        return base;
    }
    for (let i=baseUseCount; i<useCount; i++) {
        base = (base||"")+"+"+(extra||"");
    }
    return base;
}

function isCarried(it) {
    if (it.carried == null) {
        const itemType = getItemType(it);
        return !(["MNT", "VEH", "SHP", "TAH"].includes(itemType));
    } else {
        return it.carried;
    }
}

function setEquipmentItem(equipment,itemNew, id, attuned) {
    const {mergeItemList,itemsEquivalent} = require('../src/items.jsx');
    const split = id.split(".");
    let nc = equipment;
    let found = nc;
    let nid;

    for (let i in split) {
        nid = split[i];
        if (!nc) {
            return;
        }
        found =nc;
        if ((i < (split.length -1)) && nc[nid]?.container) {
            const update = Object.assign({}, nc[nid]||{});
            if (update.quantity >1) {
                const splitNew = Object.assign({}, update);
                splitNew.quantity --;
                delete splitNew.equip;

                update.quantity=1;
                nc[campaign.newUid()] = splitNew;
            }
            nc[nid] = update;
            nc = Object.assign({}, update.contained||{});
            update.contained = nc;
        } else {
            nc=null;
        }
    }
    if (itemNew) {
        const f = found[nid];
        if (f) {
            if (((f.quantity??1) == 1) || itemsEquivalent(f, itemNew)) {
                found[nid] = itemNew;
            } else {
                itemNew = Object.assign({}, itemNew);
                const splitNew = Object.assign({}, f);
                splitNew.quantity --;
                delete splitNew.equip;

                itemNew.quantity=1;
                found[nid] = itemNew;
                found[nid+campaign.newUid()] = splitNew;
            }
        } else {
            mergeItemList({a:itemNew}, found, null, attuned, true);
        }
    } else {
        delete found[nid];
    }
}

function removeItemSuffix(e) {
    const suffix = " ("+e.quantity+")";

    if (e.quantity && e.displayName.endsWith(suffix)) {
        e.displayName = e.displayName.substr(0, e.displayName.length - suffix.length);
        if (e.count) {
            e.quantity *= e.count;
            delete e.count;
        }
    }
}

function adjustEquipmentCoins(equipment, coin, adjust, coinIt) {
    let found;
    let added;

    for (let i in equipment) {
        const it = equipment[i];
        if ((it.coin || (it.type=="coin")) && (it.coinType==coin) && (adjust>0 || isCarried(it))) {
            found=i;
        }
    }

    if (found) {
        equipment[found] = Object.assign({}, equipment[found]);
        const fit = equipment[found];
        const nq = fit.quantity + adjust;
        added = Object.assign({}, fit);
        if (nq <= 0) {
            added.quantity = fit.quantity;
            adjust += fit.quantity;
            delete equipment[found];
        } else {
            fit.quantity =nq;
            added.quantity = Math.abs(adjust);
            adjust = 0;
        }
    }
    if (adjust) {
        if (adjust > 0) {
            const add = coinIt?Object.assign({}, coinIt):{coin:true, coinType:coin, displayName:stdvalues.coinNames[coin]||coin};
            add.quantity=adjust;
            equipment[coin] = add;
            added = add;
        } else if (adjust !=0) {
            let sub = adjustContainer(equipment, true);
            if (sub) {
                equipment = sub;
            }
            if (adjust<0) {
                sub = adjustContainer(equipment);
                if (sub) {
                    equipment = sub;
                }
            }
        }
    }
    return {added, equipment};

    function adjustContainer(container, carried) {
        let nc;
        for (let i in container) {
            const it = container[i];
            if (!carried || isCarried(it)) {
                if ((it.coin || (it.type=="coin")) && it.coinType==coin) {
                    if (!nc) {
                        nc = Object.assign({},container);
                    }
                    nc[i] = Object.assign({}, nc[i]);
                    const fit = nc[i];
                    const nq = fit.quantity +adjust;
                    if (!added) {
                        added = Object.assign({}, fit);
                        added.quantity = 0;
                    }
                    if (nq <= 0) {
                        added.quanity += fit.quantity;
                        adjust += fit.quantity;
                        delete nc[i];
                    } else {
                        fit.quantity = nq;
                        added.quantity+=Math.abs(adjust);
                        adjust=0;
                        return nc;
                    }
                } else if (it.container && it.contained) {
                    const sub = adjustContainer(it.contained);
                    if (sub) {
                        if (!nc) {
                            nc = Object.assign({},container);
                        }
                        nc[i] = Object.assign({}, nc[i]);
                        const fit = nc[i];
                        fit.contained = sub;                            
                    }
                }
                if (adjust >=0) {
                    return nc;
                }
            }
        }
        return nc;
    }
}

function getTextFromDistanceStruct(s) {
    const ret=[];
    
    for (let i in s) {
         if (s[i].number) {
             ret.push(i + " "+s[i].number+"ft.");
         }
    }
    ret.sort();
    return ret.join(", ")||"";
}

const sizeHPList={
    T:2,
    S:5,
    M:12,
    L:22,
    H:84,
    G:84,
};

export {
    Character, 
    getFeaturesTable,
    getMergedCustomLevels,
    getOptionedFeatures,
    fixupFeature,
    itemMatchFilter,
    getItemType,
    extensionsFromSaved,
    getImageUrlFromCharacter,
    getImageUrlInfoFromCharacter,
    getCharacterFromId,
    getModFromFeature,
    shouldDurationExpire,
    nameFromFeatureParams,
    isCarried,
    removeItemSuffix,
    adjustEquipmentCoins,
    computeSpeedFromShape,
    mergeAbilityBonus,
    proficiencyMerge,
    addExtraDice,
    proficiencyBonus,
    getTextFromDistanceStruct
}
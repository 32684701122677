const React = require('react');
const {campaign,globalDataListener,areSameDeep } = require('../lib/campaign.js');
const {extensionsFromSaved} = require('../lib/character.js');
const {displayMessage} = require('./notification.jsx');
const {SendInvitation, SendWatchInvitation,generateWatchKey} = require("./invite.jsx");
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

const {DeleteWithConfirm, getAnchorPos, TextBasicEdit, CheckVal, SelectVal, TextVal} = require('./stdedit.jsx');
const {defaultRuleset,gamesystemOptions} = require('../lib/stdvalues.js');
const {PackagePicker} = require('./packages.jsx');
const {DiceMenu,DiceValue,defaultDiceURL, getDiceFromString, doRoll} = require('./diceroller.jsx');
const {DiceTower} = require('./dicetower.jsx');
const {Chat} = require('../lib/chat.js');
const {ExtensionsPicker,ContentSelector} = require('./renderextensions.jsx');
const {ArtPicker,isNoArt} = require('./renderart.jsx');
const {getMatchingCampaigns} = require("./renderplayers.jsx");

class CampaignConfig extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

    render() {
        const {open, rulesetOnly} = this.props;
        if (!open) {
            return null;
        }

        const gameState = campaign.getGameState();
        const exlist=[];
        const extensions = extensionsFromSaved(gameState.extensions);
        for (let eid of extensions) {
            const e=campaign.getExtensionInfo(eid);
            if (e) {
                exlist.push(e.displayName)
            }
        }
        const preferred = Object.keys(gameState.preferred||{}).length;
        const disallowed = Object.keys(gameState.disallowed||{}).length;
        const cSelected = Object.keys(gameState.selectedPackages||{}).length;
        const cDeactivated = Object.keys(gameState.deactivatedPackages||{}).length;
        const art = campaign.getArtInfo(gameState.wallpaper);

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClick={this.props.onClose}>{rulesetOnly?"Ruleset":"Campaign Settings"}</DialogTitle>
            <DialogContent>
                <div className="f3 mb2">
                    <b>Name: </b>{gameState.displayName}&nbsp;&nbsp;
                    <Button onClick={this.changeName.bind(this)} variant="outlined" size="small">Change Name</Button>
                </div>
                <SelectVal className="mb2" fullWidth value={campaign.defaultGamesystem} values={gamesystemOptions} onClick={this.setSetting.bind(this,"defaultGamesystem")} helperText="Default Game System"/>
                {rulesetOnly?null:<div>
                    <SelectVal className="mb2" fullWidth values={sharingModes} value={gameState.sharing||"readonly"} onClick={this.setSetting.bind(this, "sharing")}/>
                    <SelectVal className="mb2" fullWidth values={nameModes} value={gameState.names||"full"} onClick={this.setSetting.bind(this, "names")}/>
                    <SelectVal className="mb2" fullWidth isNum values={monsterInitiativeVals} value={gameState.differentMonsterInitiatives||0} onClick={this.setSetting.bind(this, "differentMonsterInitiatives")}/>
                    <div>
                        <CheckVal value={gameState.showMonsterNames||false} label="Show names when adding monsters" onChange={this.onChangePref.bind(this, "showMonsterNames")}/>
                    </div>
                    <div className="hk-well mb2">
                        <div className="mb2">Send an invitation for players to join your campaign.</div>
                        <div>
                            <Button onClick={this.onSendInvitation.bind(this)} variant="outlined" size="small">Invite</Button>
                        </div>
                    </div>
                </div>}

                <div className="mv1">
                    <b>Wallpaper Image:</b> {art?.displayName}
                    <Button className="mh2" onClick={this.onPickWallpaper.bind(this)} color="primary" size="small" variant="outlined">Pick</Button>
                    <Button onClick={this.onChangePref.bind(this,"wallpaper", null)} color="primary" size="small" variant="outlined">Clear</Button>
                </div>

                <div  className="hk-well mb2">
                    <div className="mb1">Select extensions to enable extended features for campaign characters.</div>
                    {exlist.join(", ")} <Button onClick={this.showExtensionsPicker.bind(this)} variant="outlined" size="small">Pick Extensions</Button>
                </div>
                <div className="hk-well mb2">
                    <div className="mb2">Select which packages of content are available for use in your campaign.  If no packages are selected then all available packages will be used.  Disabled packages are never allowed.</div>
                    <div>
                        {cSelected?cSelected+" selected":null} <Button className="mr2" onClick={this.showPackagePicker.bind(this, false)} variant="outlined" size="small">Selected Packages</Button>
                        {cDeactivated?cDeactivated+" disabled":null} <Button onClick={this.showPackagePicker.bind(this, true)} variant="outlined" size="small">Disabled Packages</Button>
                    </div>
                </div>
                <div  className="hk-well mb2">
                    Preferred content will show above other content when users make selections while disallowed content will not show as an option.
                    <div className="mt1">
                        {preferred?<span className="nowrap">{preferred} entries preferred </span>:null} <Button onClick={this.clickPreferred.bind(this,true)} variant="outlined" size="small">Preferred Content</Button>{" "}
                        {disallowed?<span className="nowrap">{disallowed} entries disallowed </span>:null} <Button onClick={this.clickPreferred.bind(this,false)} variant="outlined" size="small">Disallowed Content</Button>
                    </div>
                </div>
                {rulesetOnly?null:<div className="hk-well mb2">
                    <div className="mb2">Watch mode provides a view to watch a campaign being played. You can use this view to broadcast or send a link for people to watch your game.</div>
                    <div>
                        <Button onClick={this.viewLink.bind(this, gameState.watchKey)} variant="outlined" size="small">View Watch Mode</Button>
                        <Button className="ml2" onClick={this.onSendWatchInvitation.bind(this)} variant="outlined" size="small">Get Watch Link</Button>
                        {gameState.watchKey?<Button className="ml2" onClick={this.cancelInvitation.bind(this)} variant="outlined" size="small">Revoke Watch Link</Button>:null}
                    </div>
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <SendInvitation show={this.state.showInvitation||false} onClose={this.onCloseSendInvitation.bind(this)}/>
            <SendWatchInvitation show={this.state.showWatchInvitation||false} onClose={this.onCloseSendWatchInvitation.bind(this)}/>
            <PackagePicker all title={this.state.showDisabledPackages?"Disabled Packages":"Selected Packages"} open={this.state.showPackagePicker} selected={(this.state.showDisabledPackages?gameState.deactivatedPackages:gameState.selectedPackages)||{}} onClose={this.onClosePicker.bind(this)}/>
            <TextBasicEdit show={this.state.changeName} label="New Display Name" text={gameState.displayName} onChange={this.onNewName.bind(this)} />
            <ExtensionsPicker open={this.state.showExtensionsPicker} selected={gameState.extensions} onClose={this.closeExtensionsPicker.bind(this)}/>
            <ContentSelector open={this.state.showContentSelector} preferred={this.state.preferred} label={this.state.preferred?"Pick Preferred":"Pick Disallowed"} selected={this.state.preferred?gameState.preferred:gameState.disallowed} onClose={this.closeContentSelector.bind(this)}/>
            <ArtPicker defaultType="Wallpaper" open={this.state.showPickWallpaper} onClose={this.pickWallpaper.bind(this)}/>

        </Dialog>;
    }

    onPickWallpaper() {
        this.setState({showPickWallpaper:true})
    }

    pickWallpaper(art) {
        if (art) {
            this.onChangePref("wallpaper", art.name);
        }
        this.setState({showPickWallpaper:false})
    }

    clickPreferred(preferred) {
        this.setState({showContentSelector:true, preferred});
    }

    onClosePicker(selected) {
        if (selected) {
            if (Object.keys(selected).length ==0) {
                selected = null;
            }
            if (this.state.showDisabledPackages) {
                campaign.setPrefs({deactivatedPackages:selected});
            } else {
                campaign.setPrefs({selectedPackages:selected});
            }
        }
        this.setState({showPackagePicker:false});
    }

    showPackagePicker(showDisabledPackages) {
        this.setState({showPackagePicker:true, showDisabledPackages});
    }

    closeContentSelector(selected) {
        if (selected) {
            const settings = campaign.getGameState();
            let preferred;
            let disallowed;
            let a;
            let b;

            if (this.state.preferred) {
                preferred = selected;
                if (settings.disallowed) {
                    disallowed=Object.assign({}, settings.disallowed);
                }
                a=preferred;
                b=disallowed;
            } else {
                disallowed = selected;
                if (settings.preferred) {
                    preferred=Object.assign({}, settings.preferred);
                }
                b=preferred;
                a=disallowed;
            }
            if (a && b) {
                for (let i in a) {
                    delete b[i];
                }
            }

            this.setSettings("preferred", preferred||null,"disallowed", disallowed||null);
        }
        this.setState({showContentSelector:false});
    }

    showExtensionsPicker() {
        this.setState({showExtensionsPicker:true});
    }

    closeExtensionsPicker(selected) {
        if (selected) {
            this.setSetting("extensions", selected);
        }
        this.setState({showExtensionsPicker:false});
    }

    viewLink(watchKey) {
        if (campaign.watchModeAllowed < 1) {
            displayMessage(<span>Watch mode is not enabled.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to enable.</span>);
            return;
        }

        if (!watchKey) {
            watchKey=generateWatchKey();
        }

        window.open(window.location.origin+"/#watch?id="+watchKey);
    }

    setSetting(name, value) {
        this.setSettings(name, value);
    }

    setSettings(name, value, name2, value2) {
        const set = {};
        set[name]=value;
        if (name2) {
            set[name2]=value2;
        }
        campaign.updateGameState(set);
        this.setState({settings:set});
    }

    setDiceDisplay(display) {
        Chat.setDisplayGMRolls(display);
        this.setState({display});
    }

    onSendInvitation() {
        this.setState({showInvitation:true});
    }
    
    onCloseSendInvitation() {
        this.setState({showInvitation:false});
    }

    onSendWatchInvitation() {
        if (campaign.watchModeAllowed < 2) {
            displayMessage(<span>Watch mode sharing is not enabled.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to enable.</span>);
            return;
        }

        this.setState({showWatchInvitation:true});
    }

    onCloseSendWatchInvitation() {
        this.setState({showWatchInvitation:false});
    }

    cancelInvitation() {
        campaign.resetCampaignWatchInvite();
        this.setState({prefs:campaign.getPrefs()});
    }

    changeName() {
        this.setState({changeName:true});
    }

    onNewName(displayName) {
        if (displayName) {
            campaign.setPrefs({displayName});
        }
        this.setState({changeName:false});
    }

    onChangePref(name, value) {
        const newPref = {};
        newPref[name]=value;
        campaign.setPrefs(newPref);
        this.setState(newPref);
    }

    clickActivatePackages(){
        this.setState({showActivatePackageDialog:true});
    }

    onCloseActivatePackages(){
        this.setState({showActivatePackageDialog:false});
    }
}

const sharingModes = {
    open:"Players can view and modify all characters",
    readonly:"Players can view but not modify all characters",
    closed:"Players can only view their own characters"
}

const nameModes = {
    full:"Display complete character names",
    abbrev:"Abbreviate character names",
    hide:"Hide character names"
}

const monsterInitiativeVals = {
    0:"Group monster initiatives",
    1:"All monsters get their own initiative",
}

class DiceConfig extends React.Component {
    constructor(props) {
        super(props);

        this.state= {diceVolume:campaign.diceVolume};
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const {character} = this.props;
        const {diceVolume, chatRoll} = this.state;
        const base = character?character.state:campaign.getCampaignDice();
        const {diceUrl=defaultDiceURL, showAnnimatedDice=true, showDiceBackground=true, showDiceBackgroundId} = base;
        const artDiceTray = !isNoArt("Dice Tray");
        const playSounds=campaign.playSounds;
    
        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClick={this.props.onClose}>Dice Settings</DialogTitle>
            <DialogContent>
                <div className="flex items-center mb2">
                    <Button className="mr2" onClick={this.showDicePicker.bind(this)} variant="outlined">Pick Dice</Button>
                    <span className="dib pa2 bkdice mr2 truncate">
                        <DiceValue diceUrl={diceUrl} dieType="D20" value={20}/>
                        <DiceValue diceUrl={diceUrl} dieType="D12" value={12}/>
                        <DiceValue diceUrl={diceUrl} dieType="D10" value={10}/>
                        <DiceValue diceUrl={diceUrl} dieType="D8" value={8}/>
                        <DiceValue diceUrl={diceUrl} dieType="D6" value={6}/>
                        <DiceValue diceUrl={diceUrl} dieType="D4" value={4}/>
                    </span>
                </div>
                <div className="flex items-center pt1">
                    <div>
                        Dice Sound
                        <span className={playSounds?"fas fa-volume-up f3 hoverhighlight pa1 mh1":"fas fa-volume-mute f3 hoverhighlight pa1"} onClick={this.setPlaySounds.bind(this,!playSounds)}/>
                    </div>
                    <Slider className="mw5 mh3" disabled={!playSounds} value={diceVolume||0} min={0} onChange={this.onChangeVolume.bind(this)} onChangeCommitted={this.onCommitChangeVolume.bind(this)}/>
                </div>
                <div>
                    <CheckVal value={!!showAnnimatedDice} label="Animate Dice" onChange={this.setSettings.bind(this, "showAnnimatedDice", showAnnimatedDice?null:"Flat Dice", null, null)}/>
                    {showAnnimatedDice&&artDiceTray?<span className="ml2">
                        <CheckVal label="Show dice tray" value={showDiceBackground} onChange={showDiceBackgroundId||showDiceBackground||!artDiceTray?this.setSettings.bind(this,"showDiceBackground", !showDiceBackground, null, null):this.showPickTray.bind(this)}/>
                        <Button className="ml2" color="primary" variant="outlined" onClick={this.showPickTray.bind(this)}>Pick Dice Tray</Button>
                        {showAnnimatedDice&&showDiceBackground?<Button className="ml2" color="primary" variant="outlined" onClick={this.testRoll.bind(this)}>TestRoll</Button>:null}
                    </span>:null}
                </div>
                {showAnnimatedDice&&showDiceBackground?<div className="pv5 overflow-hidden mw-100 flex">
                    <div className="flex-auto"/>
                    <DiceTower showInline chatRoll={chatRoll} character={character}/>
                    <div className="flex-auto"/>
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>

            <DiceMenu open={this.state.showDicePicker} onClose={this.pickDice.bind(this)} anchorEl={this.state.showDicePickerAnchorEl}/>
            <ArtPicker noCreate={!campaign.allowSpecialArtTypes} restrictType="Dice Tray" defaultType="Dice Tray" open={this.state.showPickTray} onClose={this.pickTray.bind(this)}/>
        </Dialog>;
    }

    testRoll() {
        const {character} = this.props;
        const base = character?character.state:campaign.getCampaignDice();
        const {diceUrl=defaultDiceURL} = base;

        const chatRoll={};
        const dice = getDiceFromString("2d20+1d100+2d12+2d8+2d6+2d4",0,false);
        const {rolls} = doRoll(dice);

        chatRoll.roll = {dice, rolls,diceUrl};
        this.setState({chatRoll});
    }

    onChangeVolume(evt, diceVolume) {
        this.setState({diceVolume});
    }

    onCommitChangeVolume(evt, diceVolume) {
        campaign.updateUserSettings({diceVolume});
    }

    setPlaySounds(playSounds) {
        campaign.updateUserSettings({playSounds});
        this.setState({playSounds});
    }

    showPickTray(){
        this.setState({showPickTray:true});
    }
    
    pickTray(artwork) {
        if (artwork) {
            this.setSettings("showDiceBackgroundId",artwork.name, "showDiceBackground",true);
            this.setState({update:campaign.newUid()});
        }
        this.setState({showPickTray:false});
    }

    setSettings(prop, val, prop2, val2) {
        const {character}=this.props;
        if (character) {
            character.setProperty(prop,val, prop2, val2);
        } else {
            const campaignDice = Object.assign({}, campaign.getCampaignDice());

            campaignDice[prop] = val;
            if (prop2) {
                campaignDice[prop2]=val2;
            }
            campaign.updateCampaignContent("adventure", campaignDice);
        }
        this.setState({update:campaign.newUid()});
    }

    showDicePicker(e){
        this.setState({showDicePicker:true, showDicePickerAnchorEl:e.currentTarget});
    }

    pickDice(diceUrl) {
        if (diceUrl) {
            this.setSettings("diceUrl", diceUrl);
        }
        this.setState({showDicePicker:false});
    }
}

class NewCampaign extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const {displayName, template, adventureBook, templates} = this.state;
            const {ruleset} = this.props;
            const tInfo = findTemplate(templates, template);
            const book = campaign.getBookInfo(adventureBook);

            const name =campaign.newUid();
            let source;

            const newCamp={};

            if (tInfo?.cInfo) {
                source = tInfo.cInfo.name;
                Object.assign(newCamp, tInfo.cInfo);
            } else if (tInfo?.extension){
                newCamp.extensions=[tInfo.extension.name];
            }

            Object.assign(newCamp,{
                name,
                displayName,
                playerMode:ruleset?"ruleset":false, 
                userDisplayName:campaign.displayName, 
                email:campaign.email,
                joinKey:null,
                watchKey:null,
                activeTime:0,
                addedBooks:null
            });

            if (!ruleset && book) {
                Object.assign(newCamp,{
                    selectedReference:adventureBook,
                    chapter:0,
                    section:-1,
                    subsection:-1,
                    selectedMap:getAdventureDefaultMap(book)
                });
            }

            campaign.createCampaign(newCamp);
            if (source) {
                campaign.copyGameSettings(source, name);
            }
            window.location.href = (ruleset?"#home?cid=":"#adventure?cid=")+encodeURIComponent(name);
        }
        this.props.onClose();
    };

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            if (!this.props.ruleset && (campaign.getGMCampaignsList().length >= campaign.maxCampaigns)) {
                displayMessage(<span>You have reached your limit of {campaign.maxCampaigns} campaigns that you can create.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to change your limits.</span>);
                this.props.onClose();
                return;
            }
    
            const templates = getTemplateList();
            this.setState({displayName:"", templates, template:findCurrentTemplate(templates), adventures:getAdventureBooks()});
        }
    }

    render() {
        const {open, ruleset} = this.props;

        if (!open) {
            return null;
        }
        const {BookPicker}=require('./book.jsx');

        const {displayName, template, templates,adventureBook,showPickBook,adventures} = this.state;
        const tInfo = findTemplate(templates, template);
        const book = campaign.getBookInfo(adventureBook);
        const pickAdventure= !ruleset && adventures && adventures.length;
    
        return <Dialog
            open
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Create {ruleset?"Ruleset":"Campaign"}
            </DialogTitle>
            <DialogContent>
                <TextVal text={displayName||""} fullWidth helperText={ruleset?"Ruleset Name":"Campaign Name"} onChange={this.setDisplayName.bind(this)}/>
                <div className="mv1">
                    <SelectVal fullWidth noteText helperText="Template" value={template||"empty"} values={templates} onClick={this.changeTemplate.bind(this)}/>
                </div>
                {ruleset?<div className="hk-well mb1">
                    A ruleset allows you to configure prefered packages and extensions for building characters and grouping content.
                </div>:pickAdventure?<div className="hk-well mb1">
                    <div className="b mb1">Adventure to Start</div>
                    <div className="mv1 i">{book?.displayName||"None"}</div>
                    <Button onClick={this.showPickBook.bind(this)} color="primary" variant="outlined" size="small">Pick Adventure</Button>
                    <BookPicker open={showPickBook} noNew bookList={adventures} title="Pick Adventure" onClose={this.pickBook.bind(this)}/>
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button disabled={!displayName || displayName==""} onClick={this.handleClose.bind(this, true)} color="primary">
                    OK
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    showPickBook() {
        this.setState({showPickBook:true});
    }

    pickBook(adventureBook) {
        if (adventureBook) {
            this.setState({adventureBook});
            if (!this.state.displayName) {
                const book = campaign.getBookInfo(adventureBook);
                this.setState({displayName:book?.displayName});
            }
        }
        this.setState({showPickBook:false});
    }

    setDisplayName(displayName) {
        this.setState({displayName});
    }

    changeTemplate(template) {
        if (template == "empty") {
            template=null;
        }
        this.setState({template});
    }
}

function getAdventureDefaultMap(b) {
    let mapRef;

    for (let c of (b.chapters||[])) {
        mapRef = hasMapRef(c);
        if (mapRef) {return mapRef}

        for (let s of (c.sections||[])){
            mapRef = hasMapRef(s);
            if (mapRef) {return mapRef}
            for (let ss of (s.subsections||[])){
                mapRef = hasMapRef(ss);
                if (mapRef) {return mapRef}
            }
        }
    }
    return null;
}

function getAdventureBooks() {
    const books = campaign.getBookList();
    const encounters = campaign.getPlannedEncounters();
    const list=[];
    
    for (let b of books) {
        const bookname = b.name.toLowerCase();
        const hasEncounter = encounters.find(function(e){
            return e.bookReference && (e.bookReference.book.toLowerCase() == bookname);
        });

        if(hasEncounter) {
            let hasMap;
            for (let c of (b.chapters||[])) {
                hasMap ||= hasMapRef(c);
                for (let s of (c.sections||[])){
                    hasMap ||= hasMapRef(s);
                    for (let ss of (s.subsections||[])){
                        hasMap ||= hasMapRef(ss);
                    }
                }
            }
            if (hasMap) {
                list.push(b)
            }
        }
    }
    return list;
}

function hasMapRef(section) {
    if (section.contentType=="Maps"){
        return section.contentId;
    }
    for (let c of section.contentList||[]) {
        if (c.contentType=="Maps") {
            return c.contentId;
        }
    }
    return null;
}


function getTemplateList() {
    const campaigns = campaign.getCampaignsList();
    const list = [];

    for (let i in campaigns) {
        const c = campaigns[i];
        if ((!c.playerMode || (c.playerMode == "ruleset")) && !c.shareCampaign) {
            list.push({
                name:(c.playerMode?"Ruleset: ":"Campaign: ")+(c.displayName||""),
                cInfo:c
            });
        }
    }

    const extensions = campaign.getAllExtensions();

    for (let i in extensions) {
        const e = extensions[i];
        if (e?.template) {
            list.push({
                name:"Template: "+(e.displayName||""),
                extension:e
            })
        }
    }

    list.sort(function (a,b) {return a.name.toLowerCase().localeCompare(b.name.toLowerCase())});

    for (let i in list) {
        const l = list[i];
        l.value = "c"+i;
    }

    list.unshift({name:"Blank", value:"empty"});
    return list;
}

function findCurrentTemplate(list) {
    const cur = campaign.getCurrentCampaign();

    for (let l of list) {
        if (l.cInfo?.name == cur) {
            return l.value;
        }
    }
    return null;
}

function findTemplate(list, cur) {
    for (let l of (list||[])) {
        if (l.value == cur) {
            return l;
        }
    }
    return null;
}

class RulesetHeader extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

    render() {
        const cinfo = campaign.getPrefs();
        const allowTemplates = campaign.allowTemplates;
        const isDefault = campaign.isDefaultCampaign();
        const isRuleset = (cinfo.playerMode=="ruleset");
        const canDelete = !isDefault && (allowTemplates||!(campaign.getExtensionInfo(campaign.getCurrentCampaign())?.template));

        return <div className="flex flex-wrap justify-center ph1">
            <Paper className="w-40 minw55 mw65 mh1 overflow-hidden flex flex-column stdcontent defaultbackground br3" elevation={4} square>
                <div className="tc f2 hoverhighlight pa1 titlecolor" onClick={this.showMenu.bind(this,true)}>
                    {isDefault?"Viewing all your content":<span>{isRuleset?"Ruleset":"Campaign"}: {cinfo.displayName}</span>}
                    <span className="fr fas fa-caret-down pa1"/>
                </div>
                {this.getMenu(isRuleset, canDelete,cinfo.displayName, allowTemplates)}
            </Paper>
            <Dialog open={this.state.deleting||false}>
                <DialogContent>
                    Deleting...
                </DialogContent>
            </Dialog>
            <CampaignConfig rulesetOnly open={this.state.showSettings} onClose={this.showSettings.bind(this,false)}/>
        </div>;
    }

    showMenu(showMenu, e) {
        this.setState({showMenu, anchorEl:showMenu&&e.target});
    }

    getMenu(isRuleset,canDelete,displayName, allowTemplates) {
        const {showMenu, anchorEl} = this.state;

        if (!showMenu) {
            return null;
        }

        const extensions = isRuleset?extensionsFromSaved(campaign.getGameState()?.extensions):null;
        const campaigns = campaign.getCampaignsList();
        const matchingCampaigns = getMatchingCampaigns(extensions);

        const list1 = [];
        const list2 = [];
        for (let i in campaigns) {
            const cInfo = campaigns[i];

            if (cInfo.playerMode == "ruleset") {
                let list = matchingCampaigns[cInfo.name]?list1:list2;
                list.push(<MenuItem key={cInfo.name} onClick={this.gotoCampaign.bind(this, cInfo.name)}>Ruleset: {cInfo.displayName}</MenuItem>)
            }
        }

        return <Menu
            anchorEl={anchorEl}
            open
            onClose={this.showMenu.bind(this,false)}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuItem onClick={this.back.bind(this)}>Show all your content</MenuItem>
            {list1}
            {list1.length && list2.length?<Divider/>:null}
            {list2}
            {isRuleset||canDelete?<Divider/>:null}
            {isRuleset?<MenuItem onClick={this.showSettings.bind(this,true)}>Settings</MenuItem>:null}
            {canDelete?<DeleteWithConfirm useMenu altText="Delete Ruleset" name={displayName} onClick={this.deleteCampaign.bind(this)}/>:null}
        </Menu>
    }

    back() {
        this.showMenu(false);
        location.href = "#home";
    }

    gotoCampaign(id) {
        this.showMenu(false);
        location.href = "#home?cid="+encodeURIComponent(id);
    }

    showSettings(showSettings) {
        this.setState({showSettings, showMenu:false});
    }

    deleteCampaign() {
        const t=this;
        campaign.deleteCampaign(campaign.getCurrentCampaign()).then(function (){
            t.setState({deleting:false});
            t.back();
        }, function (err) {
            t.setState({deleting:false});
            displayMessage("Error deleting campaign "+name+": "+err.message);
        })
        this.setState({deleting:true});
    }

}

let updateTimer;
function updateCampaign() {
    if (updateTimer) {
        clearTimeout(updateTimer);
    }
    updateTimer = setTimeout(doUpdateCampaign, 100);
}

function doUpdateCampaign(){
    updateTimer=null;
    const prefs = campaign.getPrefs();

    if (campaign.isDefaultCampaign()) {
        const createdExtensions = Object.assign({}, campaign.getUserSettings().createdExtensions||{});
        const extensions = campaign.getAllExtensions();
        let updated;

        for (let i in extensions) {
            const e = extensions[i];
            if (e?.template && (!createdExtensions[e.name]||(!campaign.allowTemplates&&!campaign.getCampaignInfo(e.name)))) {
                const newCamp = {
                    name:e.name,
                    displayName:e.displayName||"Ruleset",
                    playerMode:"ruleset", 
                    userDisplayName:campaign.displayName, 
                    email:campaign.email,
                    extensions:[e.name]
                };
    
                campaign.createCampaign(newCamp);
                createdExtensions[e.name]=1;
                updated=true;
            }
        }
        if (updated) {
            campaign.updateUserSettings({createdExtensions});
        }
    } else if (campaign.instance && !prefs.shareCampaign) {
        //console.log("checking update");
        let updated, setState={};
        const curDisabled  = Object.assign({},((campaign.getUserSettings().disabledPackages||{}).owned||{}));
        const {disabled, selectedPackages,extensions, gamesystems} = campaign.getGameState();

        const extSelected = {};
        const newGamesystems = (gamesystems||[]).concat([]);

        //console.log("extensions", extensions, campaign.getAvailablePackageList());
        const es = extensionsFromSaved(extensions);

        for (let i in es) {
            const e = campaign.getExtensionInfo(es[i]);
            if (e?.template) {
                //console.log("found template extension", e);
                addSelected(e, extSelected);
                if (e.gamesystem && !newGamesystems.includes(e.gamesystem)) {
                    newGamesystems.push(e.gamesystem);
                }
            }
        }

        newGamesystems.sort();
        if (!areSameDeep(newGamesystems,gamesystems||[])) {
            campaign.setPrefs({gamesystems:newGamesystems});
        }

//        console.log("selected", extSelected);
        if (Object.keys(extSelected).length) {
            Object.assign(extSelected, prefs.selectedPackages);
            if (!areSameDeep(prefs.selectedPackages, extSelected)) {
                //console.log("update selectedPackages", extSelected);
                campaign.setPrefs({selectedPackages:extSelected});
            }
        }

        Object.assign(curDisabled, prefs.deactivatedPackages||{});

        if (!areSameDeep(disabled, curDisabled)) {
            //console.log("diff", !areSameDeep(disabled, curDisabled),!areSameDeep(selectedPackages, curSelectedPackages), disabled, curDisabled, selectedPackages, curSelectedPackages);
            setState.disabled = curDisabled;
            updated=true;
        }

        if (updated) {
            //console.log("updating game state");
            campaign.updateGameState(setState);
        }
    }
}

function addSelected(e, selected) {
    Object.assign(selected, e.packagesSelected||{});
    const pkgs = campaign.getAvailablePackageList();

    for (let i in pkgs) {
        const p = pkgs[i];
        const hasOther = e.packageType || e.packageGenre || e.packageSetting || e.packageStoryline;
        const matchOther = matchTextArray(p.type, e.packageType) || matchTextArray(p.genre, e.packageGenre) || matchTextArray(p.setting, e.packageSetting) || matchTextArray(p.storyline, e.packageStoryline);

        if (e.packageRulesets) {
            if (matchTextArray(p.ruleset||defaultRuleset, e.packageRulesets) && (!hasOther || matchOther)) {
                selected[p.id]=1;
            }
        } else if (matchOther) {
            selected[p.id]=1;
        }
    }
}

function matchTextArray(t, a){
    if (!t || !a){
        return false;
    }
    const list = t.toLowerCase().split(",");
    const res=  !!list.find(function (le){
        le = le.trim();
        return a.find(function (b){
            return b.toLowerCase().trim() == le;
        })
    })
    //console.log("check match",res, t, a);
    return res;
}

globalDataListener.onChangeCampaignContent(updateCampaign, "extensions");
globalDataListener.onChangeCampaignSettings(updateCampaign);
globalDataListener.onChangeCampaignContent(updateCampaign, "adventure");


export {
    DiceConfig,
    CampaignConfig,
    RulesetHeader,
    NewCampaign
}
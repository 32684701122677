const React = require('react');
const {campaign,areSameDeep} = require('../lib/campaign.js');
const {sizeScaleMap} = require('../lib/stdvalues.js');
import { Engine, Render, Runner, Bodies, Composite, Events,Query} from 'matter-js';

function findOverlap(token, combatants,combatOnly) {
    const overlap=[];
    const tokenBody = getTokenBody(token,true);
    if (!tokenBody) {
        //console.log("no body", tokenBody, token);
        return overlap;
    }
    const blist=[];

    for (let i in combatants) {
        const c = combatants[i];
        const testBody = getTokenBody(c);
        if (testBody && (c.id != token.id) && (!combatOnly ||(c.canMove && ((c.ctype != "object") || c.showAsToken)))) {
            blist.push(testBody);
        }
    }

    const depth =["circle","cylinder","sphere","nametoken"].includes(token.tokenType)?1.5:0.5;
    const collisions = Query.collides(tokenBody, blist);

    for (let c of collisions) {
        if (c.depth >=depth) {
            overlap.push(c.bodyB.cInfo.id);
        } 
    }
    //console.log("potential matches", depth, tokenBody, blist, collisions,overlap);
    return overlap.length?overlap:null;
}

function getTokenBody(c,testGroup) {
    let body;
    const collisionFilter =testGroup?{category:2,mask:1}:{category:1,mask:2};
    const options = {cInfo:c,collisionFilter};
    const angle = (c.rotation||0)*Math.PI/180;

    switch (c.tokenType) {
        case "image":
        case "imagetoken":
        case "rectangle":
            options.angle = angle;
            body = Bodies.rectangle(c.tokenX, c.tokenY, Number(c.width||5),Number(c.height||5),options); 
            break;
        case "cube":
        case "line": {
            const height = Number(c.width||5);
            const width = (c.tokenType=="cube")?height:5;
            const radius = height/2;
            options.angle = angle;
            body = Bodies.rectangle(c.tokenX+Math.cos(angle-Math.PI/2)*radius, c.tokenY+Math.sin(angle-Math.PI/2)*radius, width, height,options); 
            break;
        }
        case "cone":{
            const width = Number(c.width||5);
            const radius = width*0.666;
            const points=[{x:width/2,y:width},{x:0,y:0},{x:width,y:0}];
            options.angle = angle;
            body = Bodies.fromVertices(c.tokenX+Math.cos(angle-Math.PI/2)*radius, c.tokenY+Math.sin(angle-Math.PI/2)*radius,points,options); 
            break;
        }
        case "circle":{
            const radius = Number(c.diameter||5)/2;
            body = Bodies.circle(c.tokenX, c.tokenY, radius,options);
            break;
        }
        case "cylinder":
        case "sphere":{
            const radius = Number(c.width||2.5);
            body = Bodies.circle(c.tokenX, c.tokenY, radius,options);
            break;
        }
        case "nametoken": {
            const radius = (sizeScaleMap[c.tokenSize]||1)*5/2;
            body = Bodies.circle(c.tokenX, c.tokenY, radius,options);
            break;
        }
    }
    return body;
}

export {
    findOverlap
};
const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
const {SelectVal, TextVal, defaultSourceFilter,defaultBookFilter} = require('./stdedit.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {MapPicker,MapDialogReadOnly} = require('./rendermaps.jsx');
const {ListFilter} = require('./listfilter.jsx');

let EncounterDialog, getDefaultBookName, PlannedEncounterPicker;
function loadEncounterStuff() {
    if (!EncounterDialog) {
        const ev = require('./encounterview.jsx');
        EncounterDialog = ev.EncounterDialog;
        getDefaultBookName = ev.getDefaultBookName;
        PlannedEncounterPicker = ev.PlannedEncounterPicker;
    }
}

class PinObj {
    constructor(pin) {
        this.pinData =pin;
    }

    get type() {
        return this.pinData.type;
    }
}

function getPinEncounters(pin) {
    const res = [];
    for (let i in pin.links) {
        const pl = pin.links[i];
        if (pl.type == "encounter") {
            const einfo = campaign.getPlannedEncounterInfo(pl.name);
            if (einfo) {
                res.push(einfo);
            }
        }
    }

    const encounters =  campaign.getPlannedEncounters();
    for (let i in encounters) {
        const e = encounters[i];
        if (e.pinName == pin.name) {
            res.push(e);
        }
    }

    if (res.length) {
        return res;
    }
    return null;
}

function getPinBooks(pin,directOnly) {
    const {getChapterInfoFromFragment,getBookDescription} = require('./book.jsx');
    const res = [];

    if (!directOnly) {
        const pine = getPinEncounters(pin);
        for (let i in pine) {
            const einfo = pine[i];
            const br=einfo.bookReference;
            if (br) {

                let chapter,section,subsection;
                if (br.fragment) {
                    const chaptInfo = getChapterInfoFromFragment(br.book, br.fragment);

                    chapter=chaptInfo.chapter;
                    section=chaptInfo.section;
                    subsection=chaptInfo.subsection;
                } else {
                    chapter=br.chapter;
                    section=br.section;
                    subsection=br.subsection;
                }

                res.push({book:br.book, chapter, section, subsection, displayName:einfo.displayName});
            }
        }
    }

    for (let i in pin.links) {
        const pl = pin.links[i];
        if (pl.type == "book") {
            const bookInfo = campaign.getBookInfo(pl.book);
            if (bookInfo) {
                let chapter,section,subsection;
                if (pl.fragment) {
                    const chaptInfo = getChapterInfoFromFragment(pl.book, pl.fragment);
                    chapter=chaptInfo.chapter;
                    section=chaptInfo.section;
                    subsection=chaptInfo.subsection;
                } else {
                    chapter=pl.chapter;
                    section=pl.section;
                    subsection=pl.subsection;
                }
        
                res.push({index:i, book:pl.book, chapter, section, subsection, displayName:getBookDescription(pl.book, chapter, section, subsection)});
            }
        }
    }

    if (res.length) {
        return res;
    }
    return null;
}

function getPinMaps(pin) {
    const res = [];
    if (pin.mapName) {
        const map=campaign.getMapInfo(pin.mapName);
        if (map) {
            res.push(map);
        }
    }

    for (let i in pin.links) {
        const pl = pin.links[i];
        if (pl.type == "map") {
            const map=campaign.getMapInfo(pl.name);
            if (map) {
                res.push(map);
            }
        }
    }
    if (res.length) {
        return res;
    }
    return null;
}

class RenderPins extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getPins()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "pins");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "pins");
    }

	render() {
        const pins = campaign.getPins();
        const list = [];
        for (let i in pins) {
            const p = Object.assign({}, pins[i]);
            p.mapname=mapNameFromPin(p);
            list.push(p);
        }
        list.sort(function (a,b){
            const mapsort = a.mapname.toLowerCase().localeCompare(b.mapname.toLowerCase());
            if (mapsort != 0) {
                return mapsort;
            }
            const mapidsort = (a.mapPos.mapName||"").localeCompare(b.mapPos.mapName||"");
            if (mapidsort != 0) {
                return mapidsort;
            }
            return (a.displayName||"").toLowerCase((b.displayName||"").toLowerCase());
        });

        return <div className="notecontent">
            <ListFilter 
                list={list}
                filters={pinListFilters}
                groupBy="mapname"
                onClick={this.onClick.bind(this)}
                select="click"
            />
            <PinDialog open={this.state.show} id={this.state.showName} onClose={this.hideDetails.bind(this)}/>
        </div>;
    }

    onClick(name) {
        this.setState({show:true, showName:name});
    }

    hideDetails() {
        this.setState({show:false});
    }
}

const pinBookFilter = {
    filterName:"Books",
    fieldName:"bookref",
    convertField(bookref, pin) {
        const books = getPinBooks(pin, true);
        const list = [];
        for (let i in books) {
            const b=campaign.getBookInfo(books[i].book);
            if (b && b.displayName) {
                list.push(b.displayName);
            }
        }
        return list.length?list:["none"];
    }
}

const pinListFilters = [
    {
        filterName:"Map",
        fieldName:"mapname",
    },
    defaultSourceFilter,
    pinBookFilter
];

function mapNameFromPin(pin) {
    const map = campaign.getMapInfo(pin.mapPos.mapName);
    if (map) {
        return map.displayName||"Unknown";
    }
    return "Unknown";
}

class PinMenu extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const pin = this.props.pin;
        const hasEncounters = getPinEncounters(pin);
        const hasBooks = getPinBooks(pin);
        const hasMaps = getPinMaps(pin);

         return <span>
            <Menu open
                anchorPosition={this.props.anchorPos} 
                anchorReference="anchorPosition"
                onClose={this.props.onClose}
                anchorOrigin={{vertical: 'bottom',horizontal: 'center',}}
                transformOrigin={{vertical: 'top',horizontal: 'center',}}
            >
                <div className="ph1 pb1 f5 tc titlecolor titleborder bb" disabled>{pin.displayName}</div>

                {this.props.onAddEncounter && hasEncounters?<MenuItem onClick={this.addEncounter.bind(this)}>Start Encounter</MenuItem>:null}
                {this.props.onGoToEncounter && hasEncounters?<MenuItem onClick={this.showEncounterClick.bind(this)}>View Encounter</MenuItem>:null}
                {this.props.onGoToBook && hasBooks?<MenuItem onClick={this.gotoBook.bind(this)}>Go to Book</MenuItem>:null}
                {hasMaps?<MenuItem key="gotomap" onClick={this.gotoMapPin.bind(this)}>Go to Map</MenuItem>:null}
                <MenuItem onClick={this.toggleShowPlayers.bind(this, pin.name)}>{pin.showPlayers?"Hide Pin":"Show Pin"}</MenuItem>
                <MenuItem onClick={this.showEdit.bind(this,true)}>Edit Pin</MenuItem>
            </Menu>
            <PinDialog 
                open={this.state.showEdit} 
                pin={pin} 
                onClose={this.showEdit.bind(this, false)}
                bookname={this.props.bookname}
                chapter={this.props.chapter}
                section={this.props.section}
                subsection={this.props.subsection}
            />
            {this.state.showSubMenu?<Menu open
                anchorEl={this.state.anchorEl}
                onClose={this.onCloseSub.bind(this)}
                anchorOrigin={{vertical: 'top',horizontal: 'right',}}
                transformOrigin={{vertical: 'top',horizontal: 'left',}}
            >
                {this.state.subMenuList}
            </Menu>:null}
        </span>
    }

    onCloseSub() {
        this.setState({showSubMenu:false, subMenuList:null});
        this.props.onClose();
    }

    showEdit(showEdit) {
        if (!showEdit) {
            this.props.onClose();
        }
        this.setState({showEdit});
    }

    addEncounter() {
        const encounters = getPinEncounters(this.props.pin);
        if (encounters.length == 1) {
            this.props.onAddEncounter(encounters[0].name);
            this.props.onClose();
        } else {
            const subMenuList = [];
            for (let i in encounters) {
                const e = encounters[i];
                subMenuList.push(<MenuItem key={i} onClick={this.doAddEncounter.bind(this, e.name)}>{e.displayName}</MenuItem>);
            }
            this.setState({anchorEl:event.target, showSubMenu:true, subMenuList});
        }
    }

    doAddEncounter(name) {
        this.setState({showSubMenu:false, subMenuList:null});
        this.props.onClose();
        this.props.onAddEncounter(name);
    }

    showEncounterClick(event) {
        const pin = this.props.pin;
        const encounters = getPinEncounters(pin);
        if (encounters.length == 1) {
            this.props.onClose();
            this.props.onGoToEncounter(encounters[0].name, pin.mapPos);
        } else {
            const subMenuList = [];
            for (let i in encounters) {
                const e = encounters[i];
                subMenuList.push(<MenuItem key={i} onClick={this.doGoToEncounter.bind(this, e.name, pin.mapPos)}>{e.displayName}</MenuItem>);
            }
            this.setState({anchorEl:event.target, showSubMenu:true, subMenuList});
        }
    };

    doGoToEncounter(name, mapPos) {
        this.setState({showSubMenu:false, subMenuList:null});
        this.props.onClose();
        this.props.onGoToEncounter(name, mapPos);
    }

    gotoBook(e) {
        const books = getPinBooks(this.props.pin);
        if (books.length==1) {
            this.props.onClose();
            const pl = books[0];
            this.props.onGoToBook(pl.book, pl.chapter, pl.section, pl.subsection);
        } else {
            const subMenuList = [];
            for (let i in books) {
                const pl = books[i];
                subMenuList.push(<MenuItem key={i} onClick={this.doGoToBook.bind(this, pl.book, pl.chapter, pl.section, pl.subsection)}>{pl.displayName}</MenuItem>);
            }
            this.setState({anchorEl:e.target, showSubMenu:true, subMenuList});
        }
    }

    doGoToBook(name, chapter, section, subsection) {
        this.setState({showSubMenu:false, subMenuList:null});
        this.props.onClose();
        this.props.onGoToBook(name, chapter, section, subsection);
    }

    gotoMapPin(e) {
        const maps = getPinMaps(this.props.pin);
        if (maps.length == 1) {
            this.props.onClose();
            this.props.onGoToMap(maps[0].name);
        } else {
            const subMenuList = [];
            for (let i in maps) {
                const m = maps[i];
                subMenuList.push(<MenuItem key={i} onClick={this.doGoToMap.bind(this, m.name)}>{m.displayName}</MenuItem>);
            }
            this.setState({anchorEl:e.target, showSubMenu:true, subMenuList});
        }
    }

    doGoToMap(name) {
        this.setState({showSubMenu:false, subMenuList:null});
        this.props.onClose();
        this.props.onGoToMap(name);
    }


    toggleShowPlayers(name) {
        const pin = Object.assign({}, campaign.getPinInfo(name));
        pin.showPlayers = !pin.showPlayers;
        campaign.updateCampaignContent("pins",pin);
        this.props.onClose();
    }
}


class PinDialog extends React.Component {
    constructor(props) {
        super(props);
        loadEncounterStuff();

        this.state= this.getState(props);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState(this.getState(this.props));
        }
    }

    getState(props) {
        const pin = props.pin || campaign.getPinInfo(props.id);
        return {pin}
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {PickBookDialog,getBookDescription} = require('./book.jsx');
        const pin = this.state.pin||{};
        const elist=[];
        const blist=[];
        const mlist=[];
        const encounters = getPinEncounters(pin);
        const books = getPinBooks(pin, true);
        const maps = getPinMaps(pin);

        for (let i in encounters) {
            const e=encounters[i];
            elist.push(<tr key={i} onClick={this.onGoToEncounter.bind(this,e.name)} className="hoverhighlight"><td>{e.displayName}</td></tr>);
        }

        for (let i in books) {
            const bi = books[i];
            const b=campaign.getBookInfo(bi.book);
            if (b) {
                blist.push(<tr key={i} className="hoverhighlight"><td className="w-100" onClick={this.onEditBookRef.bind(this,books[i].index)}>{getBookDescription(bi.book, bi.chapter, bi.section, bi.subsection)}</td><td><span className="pa1 fas fa-trash hoverhighlight" onClick={this.deletePinLink.bind(this,"book",null, bi.index)}/></td></tr>);
            }
        }

        for (let i in maps) {
            const m= maps[i];
            mlist.push(<tr key={i} className="hoverhighlight"><td className="w-100" onClick={this.setShowMapName.bind(this,m.name)}>{m.displayName}</td><td><span className="pa1 fas fa-trash hoverhighlight" onClick={this.deletePinLink.bind(this,"map",m.name)}/></td></tr>);
        }

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Edit Pin
            </DialogTitle>
            <DialogContent>
                <div className="stdcontent f4" key={this.props.id}>
                    <TextVal    
                        text={pin.displayName||""}
                        fullWidth
                        helperText="Name"
                        onChange={this.onChangeField.bind(this,"displayName")}
                    />
                    <SelectVal fullWidth helperText="Label Size" value={pin.scale||"am"} values={pinScaleValues} onClick={this.onChangeField.bind(this,"scale")}/>
                    <SelectVal fullWidth helperText="Marker" value={pin.marker||"circle"} values={pinMarkerValues} onClick={this.onChangeField.bind(this,"marker")}/>
                    <div className="flex items-center">
                        <h2 className="flex-auto mr2">Encounters</h2>
                        <Button className="mr1" onClick={this.onNewEncounter.bind(this)} color="primary" variant="outlined" size="small">
                            New
                        </Button>
                        <Button onClick={this.showAddEncounter.bind(this)} color="primary" variant="outlined" size="small">
                            Add
                        </Button>
                    </div>
                    <table>
                        <tbody>
                            {elist}
                        </tbody>
                    </table>
                    <div className="flex items-center">
                        <h2 className="flex-auto mr2">Map References</h2>
                        <Button onClick={this.onAddMapRef.bind(this)} color="primary" variant="outlined" size="small">
                            Add
                        </Button>
                    </div>
                    <table>
                        <tbody>
                            {mlist}
                        </tbody>
                    </table>
                    <div className="flex items-center">
                        <h2 className="flex-auto mr2">Book References</h2>
                        <Button onClick={this.onAddBookRef.bind(this)} color="primary" variant="outlined" size="small">
                            Add
                        </Button>
                    </div>
                    <table>
                        <tbody>
                            {blist}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.deletePin.bind(this)} color="primary">
                    Delete
                </Button>
                <Button disabled={!(pin && pin.displayName && pin.displayName.length)} onClick={this.doSave.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <EncounterDialog 
                encounter={this.state.encounterDetails} 
                open={this.state.showEncounter} 
                onClose={this.onCloseEncounter.bind(this)} 
                onGoToEncounter={this.onGoToEncounter.bind(this)}
                createNew={this.state.createNew} 
                pin={pin}
                startingBook={this.props.bookname||null}
                startingChapter={this.props.chapter}
                startingSection={this.props.section}
                startingSubsection={this.props.subsection}
            />
            <MapPicker onClose={this.closeMapPinPicker.bind(this)} open={this.state.pickMapForPin}/>
            <PickBookDialog
                open={this.state.showPickBook}
                onClose={this.onClosePickBook.bind(this)}
                bookname={this.state.bookname||null}
                chapter={this.state.chapter}
                section={this.state.section}
                subsection={this.state.subsection}
            />
            <MapDialogReadOnly open={this.state.showMapName} name={this.state.showMapName} onClose={this.setShowMapName.bind(this,null)}/>
            <PlannedEncounterPicker open={this.state.addEncounter} onClose={this.onCloseAddEncounter.bind(this)}/>
        </Dialog>;
    }

    onCloseAddEncounter(name) {
        if (name) {
            const einfo = Object.assign({}, campaign.getPlannedEncounterInfo(name));
            if (!einfo.mapName) {
                const pin=campaign.findEncounterPin(name);
                if (pin) {
                    const newPin = Object.assign({}, pin);
                    newPin.links = (newPin.links||[]).concat([]);
                    for (let i in pin.links) {
                        const pl = pin.links[i];
                        if (pl.type == "encounter") {
                            pin.links.splice(i,1);
                        }
                    }
                    campaign.updateCampaignContent("pins", newPin);
                }
            }
            einfo.pinName = this.state.pin.name;
            campaign.updateCampaignContent("plannedencounters",einfo);
        }
        this.setState({addEncounter:false})
    }

    showAddEncounter() {
        this.setState({addEncounter:true});
    }

    setShowMapName(showMapName) {
        this.setState({showMapName});
    }

    onClose() {
        this.props.onClose();
    }

    doSave() {
        campaign.updateCampaignContent("pins", this.state.pin);
        this.props.onClose(this.state.pin);
    }

    onChangeField(name, value) {
        const pin = Object.assign({}, this.state.pin)
        pin[name] = value; 
        this.setState({pin})
    }

    deletePin() {
        const pin = this.state.pin;
        deletePin(pin.name);
        this.props.onClose();
    }

    onGoToEncounter(encounter) {
        this.setState({encounterDetails:encounter, showEncounter:true, createNew:false})
    }

    onCloseEncounter() {
        this.setState({showEncounter:false})
    }

    onNewEncounter() {
        const pin = this.state.pin;

        this.setState({showEncounter:true, encounterDetails:pin.displayName, createNew:true});
    }

    onAddMapRef(){
        this.setState({pickMapForPin:true});
    }

    closeMapPinPicker(mapInfo) {
        if (mapInfo) {
            const pin = Object.assign({}, this.state.pin)
            pin.links = (pin.links||[]).concat([]);
            if (pin.mapName) {
                pin.links.push({name:pin.mapName, type:"map"});
                delete pin.mapName;
            }
            pin.links.push({type:"map", name:mapInfo.name});
    
            this.setState({pin})
        }
        this.setState({pickMapForPin:false});
    }

    onAddBookRef() {
        this.setState({showPickBook:true, bookRefIndex:-1, bookname:this.props.bookname||null, chapter:this.props.chapter, section:this.props.section, subsection:this.props.subsection});
    }
    
    onEditBookRef(index) {
        const {getChapterInfoFromFragment} = require('./book.jsx');
        const pl = this.state.pin.links[index];
        let chapter,section,subsection;
        if (pl.fragment) {
            const chaptInfo = getChapterInfoFromFragment(pl.book, pl.fragment);
            chapter=chaptInfo.chapter;
            section=chaptInfo.section;
            subsection=chaptInfo.subsection;
        } else {
            chapter=pl.chapter;
            section=pl.section;
            subsection=pl.subsection;
        }
        this.setState({showPickBook:true, bookRefIndex:index, bookname:pl.book, chapter, section, subsection});
    }

    onClosePickBook(bookname, chapter, section, subsection) {
        if (bookname) {
            const bookRefIndex=this.state.bookRefIndex;
            const {getFragmentFromChapterSection} = require('./book.jsx');
            const fragment = getFragmentFromChapterSection(bookname, chapter, section, subsection);
            const newLink = {type:"book", book:bookname, fragment};
            const pin = Object.assign({}, this.state.pin)
            pin.links = (pin.links||[]).concat([]);

            if (bookRefIndex ==-1) {
                pin.links.push(newLink);
            } else {
                pin.links[bookRefIndex]=newLink;
            }
            this.setState({pin});
        }
        this.setState({showPickBook:false});
    }

    deletePinLink(type, name,index) {
        const pin = Object.assign({}, this.state.pin)
        pin.links = (pin.links||[]).concat([]);
        if (pin.mapName) {
            pin.links.push({name:pin.mapName, type:"map"});
            delete pin.mapName;
        }
        if (name) {
            for (let i in pin.links) {
                const p = pin.links[i];
                if ((p.type==type)&&(p.name==name)) {
                    pin.links.splice(i,1);
                }
            }
        } else {
            pin.links.splice(index,1);
        }
        
        this.setState({pin})
    }
}

const pinScaleValues=[
    {value:"axs", name:"Extra Small"},
    {value:"as", name:"Small"},
    {value:"am", name:"Normal"},
    {value:"al", name:"Large"},
    {value:"axl", name:"Extra Large"},
    {value:"8", name:"8 Point"},
    {value:"12", name:"12 Point"},
    {value:"16", name:"16 Point"},
    {value:"24", name:"24 Point"},
    {value:"32", name:"32 Point"},
    {value:"48", name:"48 Point"},
    {value:"64", name:"64 Point"},
];

const pinMarkerValues=[
    {value:"circle", name:"Circle"},
    {value:"diamond", name:"Diamond"},
    {value:"square", name:"Square"},
    {value:"star", name:"Star"},
    {value:"triangle", name:"Triangle"},
    {value:"none", name:"None"},
];

function deletePin(name) {
    const pin = campaign.getPinInfo(name);
    if (pin) {
        if (pin.edited) {
            campaign.deleteCampaignContent("pins", name);
        } else {
            const newPin = Object.assign({}, pin);
            newPin.mapPos = {}
            newPin.links=[];
            campaign.updateCampaignContent("pins",newPin);
        }
    }
}

export {
    PinObj,
    PinMenu,
    PinDialog,
    deletePin,
    RenderPins,
    getPinMaps,
    getPinBooks,
    getPinEncounters,
    pinScaleValues,
    pinMarkerValues
}
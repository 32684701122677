const {campaign,shardHandbookId,globalDataListener,getExtensionEntryCheckFn} = require('../lib/campaign.js');
const React = require('react');
const Parser = require("../lib/dutils.js").Parser;
const {EntityEditor,Renderentry,entryToText, entryToElement} = require('./entityeditor.jsx');
const {Rendersource} = require("./rendersource.jsx");
import {htmlFromEntry} from "../lib/entryconversion.js";
import TextField from '@material-ui/core/TextField';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
const {LinkHref,AddChatEntry} = require('./renderhref.jsx');
const {escapeRegExp, SelectVal, CheckVal, SelectTextVal, SelectMultiTextVal, TextVal, DeleteEntry, defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter,NumberAdjust} = require('./stdedit.jsx');
const {ListFilter} = require('./listfilter.jsx');
const stdvalues = require('../lib/stdvalues.js');
const {signedNum,pluralString,upperFirst,joinCommaAnd,gamesystemOptions} = stdvalues;
const {getDiceFromString, doRoll} = require('../src/diceroller.jsx');
const {AddObject} = require('./objects.jsx');
const {displayMessage} = require('./notification.jsx');


class Renderspells extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getSpellListByName()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "spells");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "spells");
    }

	render() {
        const {convertItemGroup, filters} = getLevelVersion(getDefaultLevelStr());

        return <div className="notecontent" key={this.props.id}>
            <ListFilter 
                list={campaign.getSpellListByName()}
                render={spellListRender}
                filters={filters.concat({
                    filterName:"Manual Configuration",
                    fieldName:"manualSet",
                    advancedOnly:true,
                    convertField: function (v,it) {
                        return v?"Manual":"Automatic";
                    }
                })}
                groupBy="level"
                noResort
                collapseDups
                convertGroup={convertItemGroup}
                onClick={this.onClick.bind(this)}
                select="click"
                getListRef={this.saveRef.bind(this)}
                entryCheckFn={getExtensionEntryCheckFn(null,true)}
                headerOutline
            />
            <SpellDetails open={this.state.showSpell} spell={this.state.showSpellName} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.hideSpellDetails.bind(this)}/>
        </div>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(spell) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(spell))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.onClick.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.onClick.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    onClick(name) {
        this.setState({showSpell:true, showSpellName:name});
    }

    hideSpellDetails() {
        this.setState({showSpell:false});
    }
}

function spellListRender(s) {
    return <span>{s.displayName} <span className="i f6 near-black">{spSchoolAbvToFull(s.school, s.schoolName)}</span></span>
}

function getLevelVersion(levelStr) {
    let filters=spellListFilters;
    if (levelStr && (levelStr != "level")) {
        filters = filters.concat([]);
        for (let i in filters) {
            const f = Object.assign({}, filters[i]);
            if (f.filterName=="Level") {
                f.filterName = levelStr;
                filters[i]=f;
            }
        }
    }

    return {convertItemGroup, filters};

    function convertItemGroup(level) {
        if (!level) {
            return "Cantrips";
        } else {
            return Parser.spLevelToFull(level)+"-"+(levelStr||"level");
        }
    }
    
}

function getDefaultLevelStr(spell) {
    if ((spell?.gamesystem=="bf")||(campaign.defaultGamesystem=="bf")) {
        return "Circle"
    }
    return "level";
}

function spSchoolAbvToFull(school, schoolName) {
    return schoolName || schoolsOfMagic[school] || "";
}

function getSchoolsOfMagic() {
    const spellList = campaign.getSpellListByName();
    const list = ["Abjuration","Evocation","Enchantment","Illusion","Divination","Necromancy","Transmutation","Conjuration"];
    for (let i in spellList) {
        const s= spellList[i];
        const schoolList=spSchoolAbvToFull(s.school,s.schoolName).toLowerCase().split(",").map(function (a) { return a.trim()});

        for (const school of schoolList) {
            if (!list.find(function (a){return a.toLowerCase()==school.toLowerCase()})) {
                list.push(school);
            }
        }
    }
    list.sort(function (a,b){return a.toLowerCase().localeCompare(b.toLowerCase())});
    return list;
}

const spellListFilters = [
    {
        filterName:"Action",
        fieldName:"ritual",
        advancedOnly:true,
        convertField: function (v,it) {
            const l = [v?"ritual":"not ritual"];
            if (["action","bonus action","reaction"].includes(it.time?.unit)) {
                l.push(it.time.unit);
            }
            return l;
        }
    },
    {
        filterName:"School",
        fieldName:"school",
        convertField: function (v,it) {
            const s = spSchoolAbvToFull(v, it.schoolName).toLowerCase();
            const ret= s.split(",").map(function (a){return a.trim()});
            return ret;
        }
    },
    {
        filterName:"Level",
        fieldName:"level",
        getFieldDisplayName: function (v) {
            if (!v) {
                return "Cantrips";
            } else {
                return Parser.spLevelToFull(Number(v));
            }
        }
    },
    {
        filterName:"Class/Source",
        fieldName:"classes",
        convertField: function (classes,it) {
            let list=[];
            if (it.spellSources) {
                list = it.spellSources.concat([]);
            }
            const all = campaign.getClassesListByName();
            for (let i in classes) {
                const c=classes[i];
                if (all.findIndex(function (f) { return c == f.displayName}) >=0) {
                    list.push(c);
                }
            }
            return list;
        }
    }, 
    {
        filterName:"Casting Time",
        fieldName:"time",
        advancedOnly:true,
        convertField: function (time) {
            if (time?.number && time?.unit) {
                return time.number +" "+ pluralString(time.unit, time.number);
            }
            return null;
        }
    },
    {
        filterName:"Save Required",
        fieldName:"save",
        advancedOnly:true,
        convertField: function (save, spell) {
            return (save || getSpellAttributes(spell).save)?"yes":"no";
        }
    },
    {
        filterName:"Attack Required",
        fieldName:"attack",
        advancedOnly:true,
        convertField: function (attack, spell) {
            return (attack || getSpellAttributes(spell).attack)?"yes":"no";
        }
    },
    {
        filterName:"Damage Type",
        fieldName:"action",
        advancedOnly:true,
        convertField: function (action, spell) {
            const v =  action?.type || getSpellAttributes(spell).action?.type;
            if (v && stdvalues.damageTypesList.includes(v)) {
                return v;
            }
            return null;
        }
    },
    {
        filterName:"Concentration",
        fieldName:"concentration",
        advancedOnly:true,
        convertField: function (concentration, spell) {
            return (concentration || getSpellAttributes(spell).concentration)?"yes":"no";
        }
    },
    defaultSourceFilter,
    defaultBookFilter,
    defaultGamesystemFilter
];



const levelsList = {
    "0":"Cantrips",
    "1":"1st",
    "2":"2nd",
    "3":"3rd",
    "4":"4th",
    "5":"5th",
    "6":"6th",
    "7":"7th",
    "8":"8th",
    "9":"9th",
}

const levelPickList = {
    "0":"Cantrip",
    "1":"1st",
    "2":"2nd",
    "3":"3rd",
    "4":"4th",
    "5":"5th",
    "6":"6th",
    "7":"7th",
    "8":"8th",
    "9":"9th",
}


const schoolsOfMagic = {
    "A":"Abjuration",
    "C":"Conjuration",
    "D":"Divination",
    "E":"Enchantment",
    "V":"Evocation",
    "I":"Illusion",
    "N":"Necromancy",
    "T":"Transmutation",
}

function cleanupSelectedSpells(selectedSpells,restrictedSpells,character) {
    let newSelectedSpells;
    const extensionEntryCheckFn = getExtensionEntryCheckFn(character,true);

    for (let i in selectedSpells) {
        const spell = campaign.getSpell(i,true);
        if (!spell || (extensionEntryCheckFn && (extensionEntryCheckFn(spell)>1)) ||(restrictedSpells && !restrictedSpells[i.toLowerCase()])) {
            if (!newSelectedSpells) {
                newSelectedSpells = Object.assign({}, selectedSpells);
            }
            delete newSelectedSpells[i];
        }
    }
    return newSelectedSpells||selectedSpells;
}

class SpellPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selectedSpells:{}};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({selectedSpells:cleanupSelectedSpells(this.props.selectedSpells||{}, this.props.restrictedSpells, this.props.character), showCharacterPlus:false});
        }
    }

    handleClose(save) {
        if (save) {
            this.props.onClose(this.state.selectedSpells);
        } else {
            this.props.onClose();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {selectableSpells,restrictedSpells, single,extraSpells,character, levelStr,knownCantrips}=this.props;
        const extensionEntryCheckFn = getExtensionEntryCheckFn(character,true);

        const maxSpellLevel = this.props.maxSpellLevel||0;
        const cls = this.props.cclass;
        let {level, school, ritualOnly,spellSources,noRituals,spellActivate} = this.props;

        const hasCharacterList = (maxSpellLevel < 9) || selectableSpells || cls || spellSources;
        const {showCharacterPlus,selectedSpells} = this.state;

        const spellList = campaign.getSpellListByName();
        const availSpellList = showCharacterPlus?spellList:[];
        let gamesystem;

        if (!showCharacterPlus) {
            const filterLevel=(level !=null);
            const clsList = getLowerArray(cls);
            const matchSchoolList = getLowerArray(school);
            const matchSpellSources = getLowerArray(spellSources);
            level = Number(level);

            for(let i in spellList) {
                const s=spellList[i];
                let schoolList;
                if (school) {
                    schoolList=spSchoolAbvToFull(s.school,s.schoolName).toLowerCase().split(",").map(function (a) { return a.trim()});
                }
                if ((s.level<=maxSpellLevel) && (!filterLevel || (s.level==level)) && (s.level || (knownCantrips!=0)) && 
                    (!school || listMatchList(schoolList, matchSchoolList)) &&
                    (((cls==null)&&(spellSources==null)) || listHasClass(s, clsList) || (selectableSpells && selectableSpells[s.name.toLowerCase()]) || (spellSources && listMatchLowerList(s.spellSources, matchSpellSources)))&&
                    (!ritualOnly || s.ritual) && 
                    (!noRituals || !s.ritual) &&
                    (!restrictedSpells || restrictedSpells[s.name.toLowerCase()]) && 
                    matchActivate(spellActivate, s) && 
                    (!extensionEntryCheckFn || (extensionEntryCheckFn(s)<=1))
                ) {
                    availSpellList.push(s);
                }
            }
            if (character) {
                gamesystem = character.gamesystem;
            }
        }
        const {convertItemGroup, filters} = getLevelVersion(levelStr);
        
        return <Dialog
            scroll="paper"
            maxWidth="md"
            fullWidth
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Pick {single?"Spell":"Spells"}
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={availSpellList}
                    render={spellListRender}
                    filters={filters}
                    selected={selectedSpells}
                    groupBy="level"
                    noResort
                    collapseDups
                    convertGroup={convertItemGroup}
                    onClick={this.showSpell.bind(this)}
                    extraSelectInfo
                    single={single}
                    selectAll={!single}
                    select="list"
                    getListRef={this.saveRef.bind(this)}
                    entryCheckFn={getExtensionEntryCheckFn(null,true)}
                    alwaysSelected={extraSpells}
                    extraHeader={this.props.hideCounts?null:this.getCounts()}
                    onSelectedChange={this.onSelectedChange.bind(this)}
                    renderExtraCols={this.props.prepareSpells&&this.props.knownSpells?this.getPrep.bind(this):null}
                    extraButtons={hasCharacterList?<span onClick={this.toggleCharacterOnly.bind(this)} className="titlecolor hoverhighlight ml2"><span className={showCharacterPlus?"far fa-square pa1":"far fa-check-square pa1"}/>Character options only</span>:null}
                    gamesystemPref={gamesystem}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <SpellDetails levelStr={levelStr} open={this.state.showSpell||false} spell={this.state.selectedSpell} onClose={this.hideSpell.bind(this)} extraButtonsFn={this.getExtraButtons.bind(this)} disableEdit/>
        </Dialog>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(spell) {
        spell = spell.toLowerCase();
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(spell))||{});
        const {selectedSpells} = this.state;
        const {extraSpells,single}=this.props;

        return <span>
            {single?null:<Button disabled={(extraSpells||{})[spell]} onClick={this.toggleSpell.bind(this,spell)} color="primary">{selectedSpells[spell]?"Remove":"Select"}</Button>}
            <Button disabled={!prev} onClick={prev?this.showSpell.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.showSpell.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    toggleSpell(name) {
        const selectedSpells = Object.assign({},this.state.selectedSpells);
        const spell = campaign.getSpell(name);
        if (spell) {
            if (selectedSpells[name]) {
                delete selectedSpells[name];
            } else {
                selectedSpells[name]= {name:name, displayName:spell.displayName, level:spell.level||0}
            }
            this.setState({selectedSpells});
        }
    }

    getPrep(s,sel) {
        const {extraSpells} = this.props;
        const lowName = s.name.toLowerCase();
        if (s.level) {
            if (false && extraSpells&&extraSpells[lowName]&&!sel) {
                return <td><span className="gray-80 truncate f6"><span className="pa1 f3 far fa-check-square"/>prep</span></td>
            } else if (sel || extraSpells&&extraSpells[lowName]) {
                return <td><span className="truncate f6" onClick={this.toggleSpellPrepared.bind(this, s.name)}><span className={sel?.prepared?"pa1 hoverhighlight f3 far fa-check-square":"pa1 f3 hoverhighlight far fa-square"}/>prep</span></td>
            }
        }
        return <td><span className="black-00 truncate f6"><span className="pa1 f3 far fa-check-square"/>prep</span></td>;
    }

    toggleCharacterOnly() {
        this.setState({showCharacterPlus:!this.state.showCharacterPlus});
    }

    onSelectedChange(selectedSpells) {
        this.setState({selectedSpells})
    }

    getCounts() {
        const {selectedSpells} = this.state;
        const {knownCantrips,knownSpells,prepareSpells,spellStr,cantripStr,extraSpells} = this.props;
        let numCantrips = 0,
        numSpells=0,
        prepared=0;

        for (let i in selectedSpells) {
            let s=selectedSpells[i];
            if (s.level) {
                if (!s.inExtra) {
                    numSpells++;
                }
                if (s.prepared || !knownSpells) {
                    prepared++;
                }
            } else if (!s.inExtra){
                numCantrips++;
            }
        }

        if ((knownCantrips||0)>100 || (knownSpells||0)>100) {
            return;
        }

        return <div className="f3 notetext titlecolor">
            {numCantrips||knownCantrips?<span>{cantripStr||"Cantrips"} {numCantrips}{knownCantrips?("/"+knownCantrips):""} </span>:null}
            {knownSpells?<span>Known {spellStr||"Spells"} {numSpells}{knownSpells?("/"+knownSpells):""} </span>:null}
            {prepareSpells?<span> Prepared {spellStr||"Spells"} {prepared}/{prepareSpells}</span>:null}
        </div>;
    }

    toggleSpellPrepared(name) {
        const {extraSpells}=this.props;
        const selectedSpells = Object.assign({}, this.state.selectedSpells);
        const lowerName = name.toLowerCase();
        const inExtra = extraSpells && extraSpells[lowerName]||false;
        const curSpell = selectedSpells[lowerName];
        if (curSpell) {
            if (curSpell.prepared && inExtra) {
                delete selectedSpells[lowerName];
            } else {
                selectedSpells[lowerName] = Object.assign({}, curSpell);
                selectedSpells[lowerName].prepared = !curSpell.prepared;
            }
        } else {
            const spell = campaign.getSpell(name);

            selectedSpells[lowerName] = {name:name, level:spell.level, prepared:true, inExtra};
        }
        this.setState({selectedSpells});
    }

    showSpell(spell) {
        this.setState({showSpell:true, selectedSpell:spell});
    }

    hideSpell() {
        this.setState({showSpell:false});
    }
    
}

function matchActivate(spellActivate, s) {
    if (!spellActivate || spellActivate.includes(s.time?.unit) || spellActivate.includes(s.ritual?"ritual":"not ritual")) {
        return true;
    }
    return false;
}


class SpellDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({editable:false});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        if (this.state.editable) {
            return <EditSpell open spell={this.props.spell} onClose={this.props.onClose}/>
        }
        const spell = campaign.getSpell(this.props.spell);
        const {character,extraButtonsFn, levelStr} = this.props;
        let defaultShape, defaultLength;
        const showEdit = !campaign.isSharedCampaign()&&!this.props.disableEdit;
        const doRolls = character || this.props.doSubRoll;

        const spellInfo = getSpellAttributes(spell);
        defaultShape=spellInfo.shape;
        defaultLength = spellInfo.length;

        const levelStrV=levelStr || (character?character.spellLevelName():null);

        return <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth={true}
            showRolls={character || this.props.doSubRoll}
            open
        >
            <DialogTitle onClose={this.props.onClose}>{spell?.displayName}</DialogTitle>
            <DialogContent>
                <SpellBlock noTitle levelStr={levelStrV} character={character} doRoll={doRolls?this.doTextRoll.bind(this):null} doSubRoll={doRolls?this.doSubRoll.bind(this):null} spell={this.props.spell}/>
            </DialogContent>
            <DialogActions>
                {this.props.getDiceRoller?this.props.getDiceRoller():null}
                <AddChatEntry character={character} type="Spell" displayName={spell?.displayName} spellId={spell?.name}/>
                {extraButtonsFn && extraButtonsFn(this.props.spell)}
                {this.props.addSpellToken?<div className="mv1">
                    <AddObject color="primary" variant="text" defaultName={spell?.displayName} defaultShape={defaultShape} defaultLength={defaultLength} character={this.props.character} spell={this.props.spell} onAddObject={this.props.addSpellToken} buttonName="Add Spell Token" defaultType="Spell Token" playerControlled/>
                </div>:null}
                {showEdit?<DeleteEntry type="spells" entry={spell} onClose={this.props.onClose}/>:null}
                {showEdit?<Button onClick={this.clickEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
    
    doTextRoll(text){
        if (!text){
            return;
        }
        const dice = getDiceFromString(text);
        const {rolls} = doRoll(dice);
        const roll = {dice, rolls};

        const spell = campaign.getSpell(this.props.spell);
        if (spell) {
            getRollInfoForSpell(spell, text, roll);
        }
        return this.doSubRoll(roll);
    }

    doSubRoll(name,text) {
        if (this.props.character) {
            if (typeof name == "object") {
                this.props.character.addRoll(name);
            } else {
                const dice = getDiceFromString(text);
                return this.props.character.doRoll(dice, name,null);
            }
        } else if (this.props.doSubRoll) {
            return this.props.doSubRoll(name, text);
        }
    }

    clickEdit() {
        this.setState({editable:true});
    }
}

function getCastLevels(level) {
    const vals=[];
    for (let i =level; i<=9; i++){
        vals.push(i);
    }
    return vals;
}

const spellClearAttributes=["modifier", "save", "attack", "concentration", "shape", "length", "extraSpellLevels", "action", "altaction",
    "conditionLevel", "conditionAcBonus", "conditionDamageBonus", "conditionMaxHP", "conditions","noShowDuration","levelDurations"];
const shortSaveMap = {
    "str save":"strength",
    "dex save":"dexterity",
    "con save":"constitution",
    "int save":"intelligence",
    "wis save":"wisdom",
    "cha save":"charisma"
}
let spellAttributesCache = {};
function getSpellAttributes(spell) {
    if (!spell) {
        return {};
    }
    if (spell.manualSet) {
        return spell;
    }
    const cache = spellAttributesCache[spell.name];
    if (cache && (cache.timestamp == spell.timestamp)) {
        return cache.sa;
    }
    const spellElement = entryToElement({entries:spell.entries});
    const spellText = spellElement.textContent||"";
    const ret = {};

    const save = spellText.match(/(strength|dexterity|constitution|intelligence|wisdom|charisma)/i);
    if (save) {
        ret.save = save[0].toLowerCase();
    } else {
        const shortsave = spellText.match(/(str save|dex save|con save|int save|wis save|cha save)/i);
        if (shortsave) {
            ret.save = shortSaveMap[shortsave[0].toLowerCase()];
        }
    }

    if (spellText.match(/spell attack/i)) {
        ret.attack = true;
    }

    if ((spell.duration||"").match(/concentration/i)) {
        ret.concentration = true;
    }

    let aoe = spellText.match(/(cone|cube|cylinder|sphere|radius|square)/i);
    if (aoe) {
        const start = spellText.substr(0, aoe.index);
        const nums = start.match(/\d+/g);
        if (nums && nums.length) {
            const last = Number(nums[nums.length-1]);
            if (last >= 5) {
                ret.shape = aoe[0].toLowerCase();
                ret.length = last;
                if (ret.shape == "radius") {
                    ret.shape = "sphere";
                }
                if (ret.shape == "square") {
                    ret.shape = "cube";
                }
            }
        }
    }

    const extraSpellLevels = spellText.match(/(at higher (levels|circles)|Using a Higher.Level Spell Slot)/i)
    if (extraSpellLevels) {
        ret.extraSpellLevels=true;
    }

    const dieMatch =/[\s\+-dD\d]+/;
    const numMatch=/\d+/;
    const list=spellElement.getElementsByTagName("*");
    for (let i=0; i<list.length; i++){
        const el = list[i];
        if ((el.tagName=="B") || (el.tagName=="STRONG")) {
            const inner = el.textContent;
            if (inner.match(numMatch) && (inner.match(dieMatch)==inner)) {
                checkDiceElement(list[i]);
            }
        }
    }

    const acBonus = spellText.match(/\s*[\+-]\d+\s*bonus to AC\s*/i);
    if (acBonus) {
        const l = acBonus[0].match(/[\+-]\d+/);
        if (!isNaN(l[0])) {
            ret.conditions={duration:getDurationFromSpell(spell), features:[{name:spell.displayName, acBonus:Number(l[0])}]};
            ret.noShowDuration=true;
        }
    }

    spellAttributesCache[spell.name] = {timestamp:spell.timestamp, sa:ret};
    //console.log("spell info", spell.displayName, ret);
    return ret;

    function checkDiceElement(element) {
        const next = element.nextSibling;
        const prev = element.previousSibling;
        const dice = element.textContent;
        let bonus=false;
        let nt = ((next&&next.textContent)||"").trim().toLowerCase();
        let pt = ((prev&&prev.textContent)||"").trim().toLowerCase();

        //console.log("check dice", dice, pt,"-", nt);

        if (nt.startsWith("+ your spellcasting ability modifier")||nt.startsWith("plus your spellcasting ability modifier")) {
            bonus = true;
        }
        
        if (ret.action) {
            for (let i in levelDamageBonusText) {
                if (pt.endsWith(i)) {
                    ret.action.levels[levelDamageBonusText[i]]=dice;
                    if (ret.altaction && nt=="or") {
                        const nextf = next.nextSibling;
                        const nft = ((nextf&&nextf.textContent)||"");
                        if (nft.match(dieMatch)==nft) {
                            ret.altaction.levels[levelDamageBonusText[i]]=nft;
                        }
                    }
                    return;
                }
            }
        }

        if (!ret.action) {
            if (pt.endsWith("hit points equal to")) {
                ret.action = {dice, bonus, type:"heal", levels:{}};
                return
            }
        }

        if (nt.startsWith(", ") || (nt.startsWith(") "))){
            nt = nt.substr(2);
        }

        let damage;
        if (nt.startsWith("damage")) {
            damage={dice, bonus, type:"damage", levels:{}};
        } else {
            for (let i in stdvalues.damageTypesList) {
                const dt = stdvalues.damageTypesList[i];
                if (nt.startsWith(dt+" damage")) {
                    damage={dice, bonus, type:dt, levels:{}};
                }
            }
        }
        if (!damage && pt.endsWith("base damage is")) {
            damage={dice, bonus, type:"damage", levels:{}};
        }
        if (damage) {
            if (!ret.action) {
                ret.action=damage;
                return;
            } else if (!ret.altaction && (ret.action.dice != damage.dice)) {
                ret.altaction=damage;
                return;
            }
        }

        if (extraSpellLevels && nt.match(/^for each (spell )?(slot|circle)/) && ret.action){
            if (ret.altaction) {
                const endDice = dice.substr(-2);
                if (!ret.action.levelBonus && (endDice == ret.action.dice.substr(-2))){
                    ret.action.levelBonus=dice;
                } else if (endDice == ret.altaction.dice.substr(-2)) {
                    ret.altaction.levelBonus=dice;
                } else {
                    ret.action.levelBonus = dice;
                }
            } else {
                ret.action.levelBonus = dice;
            }
            return;
        }

        //console.log("checkDiceElement", element.innerText, prev&&prev.textContent, "***", next&&next.textContent)
    }
    
}

const levelDamageBonusText = {
    " 5th level (":5,
    " 5 (":5,
    " 11th level (":11,
    " 11 (":11,
    " 17th level (":17,
    " 17 (":17
}

function getRollInfoForSpell(spell, text, roll){
    const spellInfo = getSpellAttributes(spell);
    const {action, altaction} = spellInfo;
    
    (action && matchAction(action)) || (altaction && matchAction(altaction));
    roll.source = spell.displayName;
    roll.sourceHref = "#spell?id="+encodeURIComponent(spell.name);

    function matchAction(action) {
        if ((action.dice && (action.dice == text)) || matchLevel(action.levels, text))  {
            // found default action
            if (action.type != "heal") {
                roll.action = "damage";
                if (action.type) {
                    roll.actionDetail = action.type;
                }
            } else {
                roll.action = "heal";
            }
            return true;
        }
    }

    function matchLevel(levels) {
        for (let i in levels) {
            if (levels[i]==text) {
                return true;
            }
        }
        return false;
    }
}


class SpellBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state= {};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({spell:campaign.getSpell(this.props.spell)})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "spells");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "spells");
    }


    render() {
        const spell = campaign.getSpell(this.props.spell);
        if (!spell) {
            return <div>Unknown Spell {this.props.spell}</div>
        }

        const time = spell.time||{};
        const spellLists = getClasses(spell.classes);

        return <div className="stdcontent f4">
            {this.props.noTitle?null:<h3>{spell.displayName}</h3>}
            <div className="i pb1">{getSpellTypeInfo(spell, this.props.levelStr)}</div>
            <div className="i pb1"><b>Casting Time:</b> {time.number} {pluralString(time.unit, time.number)}{time.condition?(", "+time.condition):null}</div>
            <div className="i pb1"><b>Range:</b> {spell.range}</div>
            <div className="i pb1"><b>Components:</b> {Parser.spComponentsToFull(spell.components)}</div>
            <div className="i pb1"><b>Duration:</b> {spell.duration}</div>
            <Renderentry entry={{entries:spell.entries}} depth={2} showInstantRoll doRoll={this.props.doRoll} doSubRoll={this.props.doSubRoll} getDiceRoller={this.props.getDiceRoller} character={this.props.character}/>
            {spellLists?<div className="i pv1"><b>Classes:</b> {spellLists}</div>:null}
            {this.props.noSource?null:<Rendersource entry={spell}/>}
        </div>
    }
}

function printSpell(id, noTitle, header) {
    const list=[];
    const spell = campaign.getSpell(id);
    if (!spell) {
        return;
    }
    if (!noTitle) {
        list.push(`<h${header}>${spell.displayName}</h${header}>`);
    }

    if (campaign.getSourcePreventEmbedding(spell.source)) {
        list.push("<p>Not allowed to publish.</p>");
    } else {
        const time = spell.time||{};
        const spellLists = getClasses(spell.classes);

        list.push(`<p><i>${getSpellTypeInfo(spell)}</i></p>`);
        list.push(`<p><i><b>Casting Time:</b> ${time.number||""} ${pluralString(time.unit, time.number)||""}${time.condition?(", "+time.condition):""}</i></p>`);
        list.push(`<p><i><b>Range:</b> ${spell.range||""}</i></p>`);
        list.push(`<p><i><b>Components:</b> ${Parser.spComponentsToFull(spell.components)}</i></p>`);
        list.push(`<p><i><b>Duration:</b> ${spell.duration||""}</i></p>`);
        list.push(htmlFromEntry({entries:spell.entries},2));
        if (spellLists) {
            list.push(`<p><i><b>Classes:</b> ${spellLists}</i></div>`);
        }
    }

    return list.join("\n");
}

function getSpellTypeInfo(spell, levelStr) {
    if (!levelStr) {
        levelStr = getDefaultLevelStr(spell);
    }
    let typeStr;
    const school = spSchoolAbvToFull(spell.school, spell.schoolName);
    const levelVal = (spell.level>0)?Parser.spLevelToFull(spell.level) + "-"+levelStr:"";

    if (spell.spellSources) {
        const spellSources = joinCommaAnd(spell.spellSources);
        typeStr = (spell.level===0)?(spellSources+" Cantrip"):levelVal+" "+spellSources;
        if(spell.ritual) {
            typeStr += " Ritual"
        }
        if (school && school.length) {
            typeStr += " ("+school+")"
        }
    } else {
        typeStr = (spell.level==0)?(school+" cantrip"):(levelVal+" "+school.toLowerCase());

        if (spell.ritual) {
            typeStr += " (ritual)"
        }
    }
    return typeStr;
}

class EditSpell extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {spellInfo:campaign.getSpell(props.spell)};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.spell != prevProps.spell)||(this.props.open && this.props.open!= prevProps.open)) {
            this.setState({spellInfo:campaign.getSpell(this.props.spell)});
        }
    }

    render() {
        const spell = this.state.spellInfo;
        if (!this.props.open || !spell) {
            return null;
        }
        const time = spell.time||{};
        const components = spell.components||{};
        const action = spell.action||{};
        const altaction = spell.altaction||{};
        const {ExtraDamage,ActionConditions,MonsterSelector,PickCountsByCR,EditFeatureOptions,DurationByLevel} = require('./features.jsx');

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
                <TextVal    
                    text={spell.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeField.bind(this,"displayName")}
                />
            </DialogTitle>
            <DialogContent className="stdcontent">
                <div>
                    <SelectMultiTextVal fullWidth freeSolo helperText="Spell Sources" values={getAllSpellSources()} value={spell.spellSources} onChange={this.onChangeField.bind(this, "spellSources")}/>{" "}
                </div>
                <div className="mb2 flex items-end">
                    <SelectVal isNum helperText={upperFirst(getDefaultLevelStr(spell))} className="minw3 mb--4" values={levelPickList} value={spell.level} onClick={this.onChangeField.bind(this, "level")}/>{" "}
                    <SelectTextVal helperText="School" className="mb--4 w6" values={getSchoolsOfMagic()} text={spSchoolAbvToFull(spell.school,spell.schoolName)} onChange={this.onChangeField.bind(this, "schoolName")}/>{" "}
                    <CheckVal value={spell.ritual} onChange={this.onChangeField.bind(this, "ritual")} label="Ritual" className="pbcheck"/>
                </div>
                <div className="b mb1">Casting&nbsp;Time</div>
                <div className="mb2 flex items-end">
                    <TextVal helperText="Number" className="minw2" isNum text={time.number||0} onChange={this.onChangeSubField.bind(this, "time", "number")}/>
                    <SelectVal helperText="Unit" className="minw45 mh2" values={spellTimeUnits} value={time.unit||""} onClick={this.onChangeSubField.bind(this, "time", "unit")}/>
                    <TextVal helperText="Condition" fullWidth text={time.condition||""} onChange={this.onChangeSubField.bind(this, "time","condition")}/>
                </div>
                <div className="b mb1">Range</div>
                <div className="mb2">
                    <TextVal className="mw6" fullWidth text={spell.range||""} onChange={this.onChangeField.bind(this, "range")}/>
                </div>
                <div className="b">Components</div>
                <div className="mb1 flex items-end">
                    <CheckVal value={components.v} onChange={this.onChangeSubField.bind(this, "components", "v")} label="Verbal " className="pbcheck"/>
                    <CheckVal value={components.s} className="mh2 pbcheck" onChange={this.onChangeSubField.bind(this, "components", "s")} label="Somatic "/>
                    <TextVal className="mb--4" helperText="Material components" fullWidth text={Parser.spMaterialComponents(components)} onChange={this.onChangeSubField.bind(this, "components","m")}/>
                </div>
                <div className="b mb1">Duration </div>
                <div className="mb2">
                    <TextVal className="mw6" fullWidth text={spell.duration||""} onChange={this.onChangeField.bind(this, "duration")}/>
                </div>
                <div className="b mb1">Classes</div>
                <div className="mb2">
                    <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.showPickClasses.bind(this)}>
                        Pick Classes
                    </Button> {(spell.classes||[]).join(", ")}
                    <PickClasses open={this.state.showPickClasses} classList={spell.classes} onClose={this.closePickClasses.bind(this)}/>
                </div>

                <EntityEditor onChange={this.onChangeFluff.bind(this)} entry={(spell.entries&&(spell.entries.length==1)&&(spell.entries[0].type=="html"))?spell.entries[0]:spell.entries?{entries:spell.entries}:null}/>
                <div className="b mt1">Extra Notes</div>
                <EntityEditor onChange={this.onChangeField.bind(this,"extraNotes")} entry={spell.extraNotes}/>
                <div className="ba br1 titleborder pa1 mt1">
                    <CheckVal value={spell.manualSet||false} label="Manually set casting details" onChange={this.onToggleManualSet.bind(this)}/>
                    {spell.manualSet?<div className="mt1">
                        <SelectVal value={spell.save||"none"} includeVal="none" values={stdvalues.abilityNamesFull} helperText="Save Type" onClick={this.onChangeField.bind(this, "save")}/>
                        <Button className="ml1" onClick={this.showEditFeatureOptions.bind(this)} size="small" color="primary" variant="outlined">
                            Spell Active Abilities
                        </Button>
                        <div>
                            <CheckVal value={spell.attack||false} label="Show attack " onChange={this.onChangeField.bind(this, "attack")} className="mr2"/>
                            <CheckVal value={spell.concentration||false} label="Requires concentration" onChange={this.onChangeField.bind(this, "concentration")} className="mr2"/>
                            <CheckVal value={spell.noShowDuration||false} label="Don't show cast spell as active" onChange={this.onChangeField.bind(this, "noShowDuration")}/>
                        </div>
                        <div>
                            <DurationByLevel durations={spell.levelDurations} onChange={this.onChangeField.bind(this, "levelDurations")} label="Durations by Level"/>
                        </div>
                        <div className="b mv1 bb">Action</div>
                        <div  className="mb1 flex items-end">
                            <TextVal helperText="Effect" text={action.dice||""} onChange={this.onChangeSubField.bind(this, "action", "dice")} className="w5"/>
                            <SelectTextVal helperText="Effect Type" text={action.type||""} values={stdvalues.damageTypesList.concat(stdvalues.extraEffectsList).concat(["temp hp"])} onChange={this.onChangeSubField.bind(this, "action", "type")} className="flex-auto mh1"/>
                            <CheckVal value={action.bonus||false} label="Add spell modifier " onChange={this.onChangeSubField.bind(this, "action", "bonus")} className="pbcheck"/>
                        </div>
                        {spell.level?<div>
                            <TextVal helperText="Uplevel effect" text={action.levelBonus} onChange={this.onChangeSubField.bind(this, "action", "levelBonus")}/>
                            &nbsp;
                            <SelectVal selectClass=" " value={action.levelCount||1} isNum values={perLevelValues} helperText="Per level" onClick={this.onChangeSubField.bind(this, "action","levelCount")}/>
                        </div>:<div className="mb1 flex">
                            <TextVal helperText="5th level" text={(action.levels||[])[5]} onChange={this.onChangeLevels.bind(this, "action", 5)} className="flex-auto"/>
                            <TextVal helperText="11th level" text={(action.levels||[])[11]} onChange={this.onChangeLevels.bind(this, "action", 11)} className="flex-auto mh1"/>
                            <TextVal helperText="17th level" text={(action.levels||[])[17]} onChange={this.onChangeLevels.bind(this, "action", 17)} className="flex-auto"/>
                        </div>}
                        <div className="mb1">
                            <ExtraDamage damage={action.extraDamage} onChange={this.onChangeSubField.bind(this,"action","extraDamage")}/>
                        </div>
                        <div className="b mv1 bb">Alternate Action</div>
                        <div  className="mb1 flex items-end">
                            <TextVal helperText="Effect" text={altaction.dice||""} onChange={this.onChangeSubField.bind(this, "altaction", "dice")} className="w5"/>
                            <SelectTextVal helperText="Effect Type" text={altaction.type||""} values={stdvalues.damageTypesList.concat(stdvalues.extraEffectsList).concat(["temp hp"])} onChange={this.onChangeSubField.bind(this, "altaction", "type")} className="flex-auto mh1"/>
                            <CheckVal value={altaction.bonus||false} label="Add spell modifier " onChange={this.onChangeSubField.bind(this, "altaction", "bonus")} className="pbcheck"/>
                        </div>
                        {spell.level?<div>
                            <TextVal helperText="Uplevel effect" text={altaction.levelBonus} onChange={this.onChangeSubField.bind(this, "altaction", "levelBonus")}/>
                            &nbsp;
                            <SelectVal selectClass=" " value={altaction.levelCount||1} isNum values={perLevelValues} helperText="Per level" onClick={this.onChangeSubField.bind(this, "altaction","levelCount")}/>
                        </div>:<div className="mb1 flex">
                            <TextVal helperText="5th level" text={(altaction.levels||[])[5]} onChange={this.onChangeLevels.bind(this, "altaction", 5)} className="flex-auto"/>
                            <TextVal helperText="11th level" text={(altaction.levels||[])[11]} onChange={this.onChangeLevels.bind(this, "altaction", 11)} className="flex-auto mh1"/>
                            <TextVal helperText="17th level" text={(altaction.levels||[])[17]} onChange={this.onChangeLevels.bind(this, "altaction", 17)} className="flex-auto"/>
                        </div>}
                        <div className="mb1">
                            <ExtraDamage damage={altaction.extraDamage} onChange={this.onChangeSubField.bind(this,"altaction","extraDamage")}/>
                        </div>
                        <div className="b mv1 bb">Conditions</div>
                        <div className="mv1">
                            <ActionConditions conditions={spell.conditions} onChange={this.onChangeField.bind(this, "conditions")}/>
                        </div>
                        <div className="mv1 bt pt1 flex">
                            <MonsterSelector name="Summon Monsters" spell summon selection={spell.summonMonsters} onChange={this.onChangeField.bind(this, "summonMonsters")}/>
                        </div>
                    </div>:null}
                </div>
                <div className="pt2">
                    <SelectVal value={spell.gamesystem||"5e"} values={gamesystemOptions} onClick={this.onChangeField.bind(this,"gamesystem")} helperText="Game System"/>
                </div>
            </DialogContent>
            <DialogActions>
                <DeleteEntry type="spells" entry={spell} onClose={this.props.onClose}/>
                <Button onClick={this.saveData.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <EditFeatureOptions
                noShow
                noFeats
                open={this.state.showEditFeatureOptions} 
                feature={spell.enableFeature||{id:campaign.newUid()}} 
                onClose={this.closeEditFeatureOptions.bind(this)}
            />
        </Dialog>;
    }

    showEditFeatureOptions() {
        this.setState({showEditFeatureOptions:true});
    }

    closeEditFeatureOptions(feature) {
        this.setState({showEditFeatureOptions:false});
        if (feature) {
            this.onChangeField("enableFeature",feature);
        }
    }

    saveData() {
        let spell = Object.assign({},this.state.spellInfo);
        if (spell.manualSet) {
            if (spell.action && !spell.action.dice) {
                delete spell.action;
            }
            if (spell.altaction && !spell.altaction.dice) {
                delete spell.altaction;
            }
            if (spell.action?.levelBonus || spell.altaction?.levelBonus ||
                ((spell.summonMonsters?.length||1)>1) || spell.levelDurations) {
                spell.extraSpellLevels=true;
            } else {
                delete spell.extraSpellLevels;
            }
        }

        campaign.updateCampaignContent("spells", spell);
        this.props.onClose();
    }

    showPickClasses() {
        this.setState({showPickClasses:true});
    }

    closePickClasses(classList) {
        if (classList) {
            this.onChangeField("classes", classList);
        }
        this.setState({showPickClasses:false});
    }

    onChangeField(field,val) {
        const spellInfo = Object.assign({}, this.state.spellInfo);
        if (val == "none") {
            val = null;
        } else if (val == "true") {
            val=true;
        } else if (val == "false") {
            val=false;
        }
        spellInfo[field] = val;
        if (field=="schoolName") {
            delete spellInfo.school;
        }
        this.setState({spellInfo});
    }

    onChangeLevels(subfield, index, value) {
        let sub = Object.assign({}, this.state.spellInfo[subfield]||{});
        sub.levels = Object.assign({}, sub.levels||{});
        sub.levels[index]=value;
        this.onChangeField(subfield, sub);
    }

    onToggleManualSet() {
        const spellInfo = Object.assign({}, this.state.spellInfo);
        if (!spellInfo.manualSet) {
            const sa = getSpellAttributes(spellInfo);
            Object.assign(spellInfo, sa);
        } else {
            for (const cl of spellClearAttributes) {
                delete spellInfo[cl];
            }
        }
        spellInfo.manualSet = !spellInfo.manualSet;
        this.setState({spellInfo});
    }

    onChangeSubField(subfield, field, val) {
        let sub = Object.assign({}, this.state.spellInfo[subfield]||{});
        sub[field]=val;
        this.onChangeField(subfield, sub);
    }

    onChangeFluff(entry) {
        this.onChangeField("entries",entry?[entry]:null);
    }

}

const perLevelValues={
    1:"Every 1 level",
    2:"Every 2 levels",
    3:"Every 3 levels",
}
const spellTimeUnits = ["action", "bonus action", "reaction", "minute", "hour"];
const spellSources = ["Arcane", "Divine", "Primordial", "Wyrd"];

function getAllSpellSources() {
    const all = spellSources.concat([]);
    const spells = campaign.getAllSpells();
    for (let i in spells) {
        const s = spells[i];
        const sources = s.spellSources;
        for (let x in sources){
            const sv = sources[x];
            if (sv && sv.length && !all.find(function (t){ return t.toLowerCase()==sv.toLowerCase()})){
                all.push(sv);
            }
        }
    }
    all.sort(function(a,b){return a.toLowerCase().localeCompare(b.toLowerCase())});
    return all;
}

class PickClasses extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.classList != prevProps.classList)||(this.props.open && this.props.open!= prevProps.open)) {
            this.setState({classList:this.props.classList||[]});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            this.props.onClose(this.state.classList);
        } else {
            this.props.onClose();
        }
    };

    toggleClass(c){
        const classList = this.state.classList.concat([]);
        const pos = classList.indexOf(c);

        if (pos >=0) {
            classList.splice(pos,1);
        } else {
            classList.push(c);
        }
        this.setState({classList});
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const vals = [];
        const allClasses = campaign.getClassesListByName();
        const found={};
        const classList = this.state.classList||[];

        for (let i in allClasses) {
            let c = allClasses[i];
            if (!found[c.displayName]) {
                vals.push(<div key={i} className="w-50">
                    <CheckVal label={c.displayName} value={classList.includes(c.displayName)} onChange={this.toggleClass.bind(this, c.displayName)}/>
                </div>);
                found[c.displayName]=true;
            }
        }

        for (let i in classList) {
            const cd= classList[i];
            if (!found[cd]) {
                vals.push(<div key={i} className="w-50">
                    <CheckVal label={cd} value={classList.includes(cd)} onChange={this.toggleClass.bind(this, cd)}/>
                </div>);
                found[cd]=true;
            }
        }
        
        return <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Choose Classes</DialogTitle>
            <DialogContent>
                <div className="flex flex-wrap">
                    {vals}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
}


class NewSpell extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    createSpell() {
        const name = this.state.name;
        const selected = this.state.selected;
        let newSpell = Object.assign({}, selected?campaign.getSpell(selected):emptySpell);

        newSpell.displayName=name;
        if (!selected) {
            newSpell.gamesystem=campaign.defaultGamesystem;
        }
        newSpell.name = campaign.newUid();
        campaign.updateCampaignContent("spells", newSpell);
        this.props.onClose(newSpell.name);
    }

    onClose() {
        this.props.onClose();
    }

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({name:event.target.value});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({name:"", selected:null});
        }
    }

    render() {
        if (!this.props.open)
            return null;

        const spell = campaign.getSpell(this.state.selected);
        const name=this.state.name||"";

        return <Dialog
            open={this.props.open||false}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Create Spell
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={name}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    label="Spell Name"
                    fullWidth
                />
                <div className="mv1">
                    <Button className="mr2" onClick={this.onShowPickSpell.bind(this)} color="primary" variant="outlined">
                        Pick a Base Spell
                    </Button>
                    {spell?("Create spell based on "+spell.displayName):null}
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={!name || name==""} onClick={this.createSpell.bind(this)} color="primary">
                    New
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <SpellPicker 
                open={this.state.showPickSpell} 
                maxSpellLevel={20}
                selectedSpells={null}
                single
                hideCounts 
                onClose={this.closePicker.bind(this)}
            />
        </Dialog>;
    }

    onShowPickSpell() {
        this.setState({showPickSpell:true});
    }

    changeSpell(selected) {
        if (selected=="Empty Template") {
            selected = null;
        }
        this.setState({selected});
    }

    closePicker(spells) {
        if (spells) {
            let spellname;
            for (let i in spells) {
                spellname = i;
            }
            this.setState({selected:spellname || null})
        }
        this.setState({showPickSpell:false});
    }
}

const emptySpell = {
    level:0,
    time:{number:1,unit:"action"},
    range:"Touch",
    school:"E",
    components:{s:true, v:true},
    duration:"Instantaneous",
    classes:[],
    entries:[""]
}


class SpellsHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onNew() {
        this.setState({showNew:true});
    }

    render() {
        return <span>
            Spell List
            {!campaign.isSharedCampaign()?<Button className="ml2 minw2" color="primary" variant="outlined" size="small" onClick={this.onNew.bind(this)}>New</Button>:null}
            {campaign.isDebugVersion?<Button className="ml2 minw2" color="primary" variant="outlined" size="small" onClick={fixupManualConfig.bind(null)}>Copy Config</Button>:null}
            <NewSpell open={this.state.showNew} onClose={this.closeNew.bind(this)}/>
            <EditSpell spell={this.state.newSpell} open={this.state.showEdit} onClose={this.doneEditing.bind(this)}/>
        </span>;
    }

    closeNew(newSpell) {
        this.setState({showNew:false});
        if (newSpell) {
            this.setState({newSpell, showEdit:true})
        }
    }

    doneEditing() {
        this.setState({showEdit:false});
    }
}

function fixupManualConfig() {
    const spells = campaign.getAllSpells();
    const modeledSpells = {};

    for (let i in spells) {
        const s = spells[i];
        if (s.manualSet && s.source==shardHandbookId) {
            modeledSpells[s.displayName.toLowerCase().trim()] = s;
        }
    }
    let found = 0;
    for (let i in spells) {
        const s = spells[i];
        const ms = modeledSpells[(s.displayName||"").toLowerCase().trim()];
        if (!s.manualSet && s.source!=shardHandbookId && ms) {
            //console.log("found spell to model", s.displayName, s.source);
            campaign.updateCampaignContent("spells", fixupManual(s, ms))
            found++;
        }
    }
    displayMessage(`Found ${found} spells to set manual config.`)
}

function fixupManual(spell, cSpell) {
    const spellInfo = Object.assign({}, spell);
    spellInfo.manualSet = true;
    spellInfo.enableFeature = cSpell.enableFeature||null;
    spellInfo.action = cSpell.action||null;
    spellInfo.altaction = cSpell.altaction||null;
    spellInfo.conditions = cSpell.conditions||null;
    spellInfo.summonMonsters = cSpell.summonMonsters||null;
    spellInfo.levelDurations = cSpell.levelDurations||null;
    spellInfo.noShowDuration = cSpell.noShowDuration||null;
    spellInfo.save = cSpell.save||null;
    spellInfo.attack = cSpell.attack||null;
    return spellInfo;
}



function RenderBookSpellList(props) {
    const list = props.list;
    const sl = [];
    const res = [];
    let lastLevel = -1;
    let multilevel = false;

    for (let i in list) {
        const s = campaign.getSpell(list[i].contentId);
        if (s) {
            if (!multilevel) {
                if (lastLevel < 0) {
                    lastLevel = s.level;
                } else if (lastLevel != s.level) {
                    multilevel = true;
                }
            }
            sl.push(s);
        }
    }

    sl.sort(function(a,b){
        if (a.level != b.level) 
            return a.level-b.level; 

        return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
    });

    lastLevel = -1;
    for (let i in sl) {
        const s = sl[i];
        if (multilevel && (lastLevel !=s.level)) {
            lastLevel = s.level;
            res.push(<tr key={lastLevel}><td className="b f3">{lastLevel?(Parser.spLevelToFull(lastLevel)+" Level"):"Cantrips"}</td></tr>);
        }
        res.push(<tr key={s.name}><td><LinkHref href={"#spell?id="+encodeURIComponent(s.name)}>{s.displayName}</LinkHref></td></tr>);
    }
    return <table className="w-100"><tbody>{res}</tbody></table>;
}

function printSpellList(list) {
    const sl = [];
    const res = [];
    let lastLevel = -1;
    let multilevel = false;

    for (let i in list) {
        const s = campaign.getSpell(list[i].contentId);
        if (s) {
            if (!multilevel) {
                if (lastLevel < 0) {
                    lastLevel = s.level;
                } else if (lastLevel != s.level) {
                    multilevel = true;
                }
            }
            sl.push(s);
        }
    }

    sl.sort(function(a,b){
        if (a.level != b.level) 
            return a.level-b.level; 

        return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
    });

    lastLevel = -1;
    for (let i in sl) {
        const s = sl[i];
        if (multilevel && (lastLevel !=s.level)) {
            lastLevel = s.level;
            res.push(`<tr><td><b>${lastLevel?(Parser.spLevelToFull(lastLevel)+" Level"):"Cantrips"}</b></td></tr>`);
        }
        res.push(`<tr><td>${s.displayName||""}</td></tr>`);
    }
    return `<table>${res.join("\n")}</table>`;
}

function getClasses(clist) {
    let ret = [];
    const classes=campaign.getClassesListByName().map(function (a) {return a.displayName});

    for (let i in clist) {
        const c = clist[i];
        if (classes.includes(c)) {
            ret.push(c);
        }
    }
    return ret.join(", ");
}

function hasClass(s, cls) {
    let i;

    cls=cls.toLowerCase();
    for (i in s.classes){
        if (s.classes[i].toLowerCase()==cls)
            return true;
    }
    return false;
}

function listHasClass(s, clsList) {
    let i;

    for (i in s.classes){
        if (clsList.includes(s.classes[i].toLowerCase())) {
            return true;
        }
    }
    return false;
}

function listMatchList(list, vList) {
    if (list) {
        for (let v of list) {
            if (vList.includes(v)) {
                return true;
            }
        }
    }
    return false;
}

function listMatchLowerList(list, vList) {
    if (list) {
        for (let v of list) {
            if (vList.includes(v.toLowerCase())) {
                return true;
            }
        }
    }

    return false;
}

function getLowerArray(val) {
    const ret=[];
    if (val) {
        if (Array.isArray(val)) {
            for (let v of val) {
                if (v) {
                    ret.push(v.toLowerCase());
                }
            }
        } else {
            ret.push(val.toLowerCase());
        }
    }
    return ret;
}

function getDurationFromSpell(spell, castLevel) {
    if (!spell) {
        return null;
    }
    let sd;
    if (!castLevel) {
        castLevel = spell.level;
    }
    if (spell.levelDurations && castLevel) {
        for (let i=1; i<=castLevel; i++) {
            sd = spell.levelDurations[i]||sd;
        }
        if (sd) {
            return sd;
        }
    }
    if (spell.duration) {
        sd = spell.duration;
        if (sd.search(/(instantaneous|permanent)/i)>=0){
            return null;
        }
        sd =sd.replace(/\s*concentration\s*,?\s*/i, "");
        sd =sd.replace(/\s*up\s*to\s*/i,"");
    }
    return sd;
}

export {
    Renderspells,
    SpellDetails,
    SpellPicker,
    SpellBlock,
    printSpell,
    printSpellList,
    SpellsHeader,
    EditSpell,
    NewSpell,
    PickClasses,
    RenderBookSpellList,
    getSpellAttributes,
    getSchoolsOfMagic,
    hasClass,
    spSchoolAbvToFull,
    spellListFilters,
    getDurationFromSpell,
    getAllSpellSources
}
const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
import Tooltip from '@material-ui/core/Tooltip';
const {LinkHref}=require('./renderhref.jsx');

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';

const {SelectVal, TextVal, CheckVal} = require('./stdedit.jsx');
const {ArtPicker} = require('./renderart.jsx');
const {colorNameMap,colorContrastMap} = require('../lib/stdvalues.js');
import Divider from '@material-ui/core/Divider';

class AddObject extends React.Component {
    constructor(props) {
        super(props);

        this.state={diameter:5, width:5, height:5, fill:"#ffffff"};
        this.anchorRef = React.createRef();
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.showMenu && (prevState.showMenu != this.state.showMenu)) {
            this.setState({diameter:5, width:5, height:5, fill:"#ffffff"});
        }
    }

    render() {
        let mru=this.state.showMenu?this.getMru():[];

        return  <span>
            {this.props.useIcon?
                <Tooltip title={(this.props.defaultType=="Spell Token")?"Add Spell Token":"Add Map Token"}><span ref={this.anchorRef} className={(this.props.noSize?((this.props.className||"pa--2")+" hoverhighlight fas fa-magic"):((this.props.className||"pa1 f3")+" hoverhighlight fas fa-magic"))} onClick={this.doClick.bind(this)}/></Tooltip>:
                this.props.useMenu?
                <MenuItem ref={this.anchorRef} onClick={this.doClick.bind(this)}>Add Token</MenuItem>
                :<Button ref={this.anchorRef} className="mh1 minw2" color={this.props.color||"secondary"} variant={this.props.variant||"outlined"} size="small" onClick={this.doClick.bind(this)}>{this.props.buttonName || "add token"}</Button>
            }
            <span onClick={eatEvents} onKeyDown={eatEvents} onKeyUp={eatEvents} onKeyPress={eatEvents}>
                {this.state.showMenu?<Menu open 
                    disableAutoFocusItem 
                    anchorEl={this.anchorRef.current} 
                    transitionDuration={0} 
                    onClose={this.closeAll.bind(this)}
                    anchorOrigin={this.props.useMenu?{ vertical: 'top', horizontal: 'right'}:{ vertical: 'top', horizontal: 'left'}}
                >
                    <MenuItem onClick={this.showDialog.bind(this, this.props.defaultShape||"sphere")}>{(this.props.defaultType=="Spell Token")?"New Spell Token...":"New Map Token..."}</MenuItem>
                    {mru.length > 0?<Divider/>:null}
                    {mru}
                </Menu>:null}
                {this.state.showEdit?<EditMapObject defaultType={this.props.defaultType || "Map Token"} defaultName={this.props.defaultName} open object={this.state.newobject} playerControlled={this.props.playerControlled} onClose={this.handleCreateObject.bind(this)}/>:null}
                {this.state.showImagePicker?<ArtPicker defaultType={this.props.defaultType || "Map Token"} open onClose={this.onPickImage.bind(this)}/>:null}
            </span>
        </span>
    }

    doClick(e) {
        const mru=this.getMru();
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (mru.length) {
            this.showMenu(e);
        } else {
            this.showDialog(this.props.defaultShape||"sphere");
        }
    }

    getMru() {
        const character = this.props.character;
        const mru=[];

        const spell = this.props.spell;
        let mruList = (character&&spell)?character.getSpellTokenMRUList(spell):campaign.getMRUList("mruObjects");

        if (spell) {
            const addMru = getArtMatches(spell);
            if (addMru.length) {
                mruList = mruList.concat([]);
                for (let i in addMru) {
                    const a = addMru[i];

                    if (mruList.findIndex(function (t){return t.description == a.description})<0) {
                        mruList.push(a);
                    }
                }
            }
        }

        for (let i in mruList) {
            const m=mruList[i];
            if (!m.object.tokenArt || campaign.getArtInfo(m.object.tokenArt)) {
                mru.push(<MenuItem key={i} onClick={this.onClickMRU.bind(this, m)}>{m.description}</MenuItem>);
            }
        }
        return mru;
    }

    closeAll() {
        this.setState({
            showMenu:false,
            showEdit:false,
            showImagePicker:false
        });
    }

    showDialog(type) {
        switch (type) {
        case "image":
            this.setState({showMenu:false, showImagePicker:true});
            break;
        case "rectangle":
            this.setState({showMenu:false, showEdit:true, newobject:{ctype:"object", otype:"rectangle", fill:"#ffffff", width:5, height:5}});
            break;
        case "circle":
            this.setState({showMenu:false, showEdit:true, newobject:{ctype:"object", otype:"circle", fill:"#ffffff", diameter:5}});
            break;
        case "sphere":
        case "cube":
        case "cone":
        case "cylinder":
            this.setState({showMenu:false, showEdit:true, newobject:{ctype:"object", otype:type, fill:"#ffffff", width:this.props.defaultLength||5}});
            break;
        }
    }

    showMenu(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({showMenu:true});
    }

    closeMenu() {
        this.setState({showMenu:false});
    }

    onClickMRU(mru) {
        const character = this.props.character;
        const spell = this.props.spell;
        if (character && spell) {
            character.addSpellTokenMRUList(spell, mru);
        }

        if (spell) {
            const smru=Object.assign({}, mru);
            const spellInfo = campaign.getSpell(spell);
            if (spellInfo) {
                smru.description=spellInfo.displayName;
            }
            campaign.addMRUList("mruObjects", smru);
        } else {
            campaign.addMRUList("mruObjects", mru);
        }
        
        this.closeMenu();
        this.props.onAddObject(Object.assign({},mru.object));

    }

    handleCreateObject(ao) {
        this.closeAll();
        if (ao) {
            let {name,sname} = getDefaultNames(ao);
            ao = Object.assign({}, ao);

            if (!ao.name || ao.name=="") {
                ao.name = sname;
            } else {
                name=ao.name;
            }

            campaign.addMRUList("mruObjects", {description:this.props.defaultName||name, object:ao});

            const character = this.props.character;
            const spell = this.props.spell;
            if (character && spell) {
                character.addSpellTokenMRUList(spell, {description:name, object:ao});
            }

            this.props.onAddObject(ao);
        }
    }

    onPickImage(art) {
        this.closeAll();
        if (art) {
            const ao = {
                ctype:"object",
                otype:"image",
                name:art.displayName,
                tokenArt:art.name
            };
            const newMRU = {description:art.displayName, object:Object.assign({},ao)};
            campaign.addMRUList("mruObjects", newMRU);
            const spell = this.props.spell;
            if (character && spell) {
                character.addSpellTokenMRUList(spell, newMRU);
            }
            this.props.onAddObject(ao);
        }
    }
}

function getDefaultNames(ao) {
    let name;
    let sname;

    switch (ao.otype) {
        case "rectangle":
            name=colorNameMap[ao.fill||"#ffffff"]+" Rectangle "+ao.width+"ft x "+ao.height+"ft";
            sname="Rectangle";
            break;
        case "circle":
            name=colorNameMap[ao.fill||"#ffffff"]+" Circle "+ao.diameter+"ft diameter";
            sname="Circle";
            break;
        case "line":
            name=colorNameMap[ao.fill||"#ffffff"]+" "+ao.width+"ft Line area of effect";
            sname="Line area of effect";
            break;
        case "cylinder":
            name=colorNameMap[ao.fill||"#ffffff"]+" "+ao.width+"ft Cylinder area of effect";
            sname="Cylinder area of effect";
            break;
        case "sphere":
            name=colorNameMap[ao.fill||"#ffffff"]+" "+ao.width+"ft Sphere area of effect";
            sname="Sphere area of effect";
            break;
        case "cone":
            name=colorNameMap[ao.fill||"#ffffff"]+" "+ao.width+"ft Cone area of effect";
            sname="Cone area of effect";
            break;
        case "cube":
            name=colorNameMap[ao.fill||"#ffffff"]+" "+ao.width+"ft Cube area of effect";
            sname="Cube area of effect";
            break;
        case "image": {
            const art = campaign.getArtInfo(ao.tokenArt); 
            name = sname = (art&&art.displayName)||"Art";
            break;
        }
        default:
            console.log("unknown type")
    }
    return {name,sname}
}

function eatEvents(e) {
    e.stopPropagation();
}

class EditMapObject extends React.Component {
    constructor(props) {
        super(props);

        const ao = Object.assign({}, props.object||{});
        if (!ao.name && props.defaultName) {
            ao.name = props.defaultName;
        }
        this.state={ao};
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.open != this.props.open) {
            const ao = Object.assign({}, this.props.object||{});
            if (!ao.name && this.props.defaultName) {
                ao.name = this.props.defaultName;
            }
            this.setState({ao});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        // prevent require loop
        const {CustomPicker} = require('./customtable.jsx');

        const ao=this.state.ao;
        let inside;
        const types=tokenTypes.concat(getCustomTypesWithTokens());
        const it = ao.custom && campaign.getCustom(ao.custom, ao.customId);

        switch (ao.otype) {
            case "image":{
                const art = campaign.getArtInfo(ao.tokenArt);
                if (art) {
                    inside = <div>
                        <div className="mb2 flex">
                            <TextVal
                                isNum
                                text={ao.artWidth||""}
                                onChange={this.onChangeField.bind(this, "artWidth")}
                                helperText="Width (ft.)"
                                fullWidth
                                className="flex-auto w-50 mr1"
                            />
                            <TextVal
                                isNum
                                text={((ao.artWidth||0)*art.imgHeight/art.imgWidth) || ""}
                                onChange={this.onChangeImageHeight.bind(this, "height")}
                                helperText="Height (ft.)"
                                fullWidth
                                className="flex-auto w-50"
                            />
                        </div>
                        {ao.custom?<div className="mb2">
                            <Button onClick={this.pickCustom.bind(this)} color="primary" variant="outlined" size="small">{ao.customId?"Change pick ":"Pick"} from {ao.custom}</Button>
                            {" "}{it?<LinkHref href={"#customlist?type="+encodeURIComponent(ao.custom)+"&id="+ao.customId} className="b">{it.displayName}</LinkHref>:null}
                        </div>:null}
                        <div className="tc mb1">
                            {art.imgHeight>art.imgWidth?<img src={art.thumb || art.url} height={200} alt="Loading..."/>:<img src={art.thumb || art.url} width={200} alt="Loading..."/>}
                        </div>
                    </div>;
                } else {
                    inside = <div>
                        {ao.custom?<div>
                            <Button onClick={this.pickCustom.bind(this)} color="primary" variant="outlined" size="small">Pick from {ao.custom}</Button>
                            {" "}{it?<LinkHref href={"#customlist?type="+encodeURIComponent(ao.custom)+"&id="+ao.customId} className="b">{it.displayName}</LinkHref>:null}
                        </div>:null}
                    </div>;

                }
                break;
            }
            
            case "rectangle":{
                inside = <div className="mb2 flex">
                    <TextVal
                        isNum
                        text={ao.width||""}
                        onChange={this.onChangeField.bind(this, "width")}
                        helperText="Width (ft.)"
                        fullWidth
                        className="flex-auto w-50 mr1"
                    />
                    <TextVal
                        isNum
                        text={ao.height||""}
                        onChange={this.onChangeField.bind(this, "height")}
                        helperText="Height (ft.)"
                        fullWidth
                        className="flex-auto w-50"
                    />
                </div>;
                break;
            }
            
            case "circle":{
                inside = <div>
                    <TextVal
                        isNum
                        text={ao.diameter||""}
                        onChange={this.onChangeField.bind(this, "diameter")}
                        helperText="Diameter (ft.)"
                        fullWidth
                    />
                </div>;
                break;
            }

            case "line":
            case "cone":
            case "cube":{
                inside = <div>
                    <TextVal
                        isNum
                        text={ao.width||""}
                        onChange={this.onChangeField.bind(this, "width")}
                        helperText="Length (ft.)"
                        fullWidth
                    />
                </div>;
                break;
            }

            case "cylinder":
            case "sphere":{
                inside = <div>
                    <TextVal
                        isNum
                        text={ao.width||""}
                        onChange={this.onChangeField.bind(this, "width")}
                        helperText="Radius (ft.)"
                        fullWidth
                    />
                </div>;
                break;
            }
        }
        
        return  <Dialog 
            open
            fullWidth
            maxWidth="xs"
            allowFullscreen
        >
            <DialogTitle onClose={this.closeAll.bind(this,false)}>{this.props.defaultType||"Map Token"}</DialogTitle>
            <DialogContent>
                <TextVal fullWidth className="mb2" text={ao.name} helperText="Name" onChange={this.onChangeField.bind(this, "name")}/>
                <SelectVal fullWidth className="mb2" value={ao.custom?("c."+ao.custom):ao.otype} helperText="Shape" values={types} onClick={this.onChangeType.bind(this)}/>
                <div>
                    <CheckVal label="Show as Token" value={ao.showAsToken||false} onChange={this.onChangeField.bind(this,"showAsToken")} className="mr3"/>
                    <CheckVal label="Include in Combat" value={ao.includeInCombat||false} onChange={this.onChangeField.bind(this,"includeInCombat")}/>
                </div>
                {inside}
                {ao.otype!="image"?<ColorPicker
                    color={ao.fill} 
                    onClick={this.onChangeField.bind(this,"fill")}
                />:null}
            </DialogContent>
            <DialogActions>
                {!this.props.playerControlled?<CheckVal label="Player Controlled" value={ao.playerControlled||false} onChange={this.onChangeField.bind(this,"playerControlled")}/>:null}
                <Button onClick={this.pickArt.bind(this,true)} color="primary">
                    Pick Image
                </Button>
                <Button onClick={this.closeAll.bind(this,true)} color="primary">
                    OK
                </Button>
                <Button onClick={this.closeAll.bind(this,false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ArtPicker defaultType={this.props.defaultType||"Map Token"} open={this.state.showImagePicker||false} onClose={this.onPickImage.bind(this)}/>
            <CustomPicker 
                open={this.state.showCustomPicker} 
                known={1}
                type={ao.custom}
                onClose={this.onCloseCustomPicker.bind(this)}
            />
        </Dialog>;
    }

    pickArt(){
        this.setState({showImagePicker:true})
    }

    pickCustom() {
        this.setState({showCustomPicker:true});
    }

    onCloseCustomPicker(selected) {
        if (selected) {
            const keys = Object.keys(selected);
            const id = keys[0];
            if (id) {
                const ao = Object.assign({}, this.state.ao);
                const it = campaign.getCustom(ao.custom, id);
                if (it) {
                    const art = campaign.getArtInfo(it.defaultToken);
                    if (art) {
                        ao.tokenArt=art.name;
                        if (art.mapWidth) {
                            ao.artWidth = art.mapWidth;
                        } else if (!ao.artWidth) {
                            ao.artWidth=ao.width||10;
                        }
                    }        
                    if (!ao.name) {
                        ao.name=it.displayName;
                    }
                    ao.customId = id;
                    this.setState({ao});
                }
            }
        }
        this.setState({showCustomPicker:false});
    }

    onPickImage(art) {
        if (art) {
            const ao = Object.assign({}, this.state.ao);
            ao.tokenArt=art.name;
            if (!ao.name) {
                ao.name=art.displayName;
            }
            if (art.mapWidth) {
                ao.artWidth = art.mapWidth;
            } else if (!ao.artWidth) {
                ao.artWidth=ao.width||10;
            }
            ao.otype="image";
            this.setState({ao, showImagePicker:false});
        } else {
            this.setState({showImagePicker:false});
        }
    }

    onChangeImageHeight(height) {
        height=Number(height);
        const ao=this.state.ao;
        const art = campaign.getArtInfo(ao.tokenArt);
        if (art) {
            this.onChangeField("artWidth", height*art.imgWidth/art.imgHeight);
        }
    }

    onChangeType(type) {
        const ao = Object.assign({}, this.state.ao);
        if (type.startsWith("c.")) {
            ao.otype = "image";
            ao.custom = type.substr(2);
            delete ao.customId;
            delete ao.tokenArt;
        } else {
            ao.otype = type;
            delete ao.custom;
            delete ao.customId;
        }
        this.setState({ao});
    }

    onChangeField(prop,val) {
        const ao = Object.assign({}, this.state.ao);
        ao[prop]=val;
        this.setState({ao});
    }

    closeAll(save) {
        this.props.onClose(save?this.state.ao:null);
    }
}

function getCustomTypesWithTokens() {
    const allCustom = campaign.getAllCustom();
    const types = [];
    for (let c in allCustom) {
        const cl = allCustom[c].all||{};
        for (let i in cl) {
            const custom = cl[i];
            if (custom.defaultToken) {
                types.push({name:c, value:"c."+c});
                break;
            }
        }
    }
    types.sort(function(a,b){return a.name.toLowerCase().localeCompare(b.name.toLowerCase())});
    return types;
}

const tokenTypes = [
    {value:"image", name:"Image"},
    {value:"cone", name:"Cone"},
    {value:"cube", name:"Cube"},
    {value:"circle", name:"Circle"},
    {value:"cylinder", name:"Cylinder"},
    {value:"line", name:"Line 5ft wide"},
    {value:"sphere", name:"Sphere"},
    {value:"rectangle", name:"Rectangle"},
]

function getArtMatches(spell) {
    const spellInfo = campaign.getSpell(spell);
    const matches = [];
    if (spellInfo) {
        const name = spellInfo.displayName;
        const artList = campaign.getArt();
        for (let i in artList) {
            const a = artList[i];
            if ((a.type == "Spell Token") && a.keywords && a.keywords.includes(name)) {
                matches.push({description:a.displayName, object:{ctype:"object", otype:"image", name:a.displayName, tokenArt:a.name}});
            }
        }
    }
    return matches;
}



function ColorPicker(props) {
    const cList1=[];
    const cList2=[];
    const cList3=[];
    let c =0;

    for (let i in colorNameMap) {
        let cList = c<12?cList1:c<24?cList2:cList3;

        cList.push(<div key={c} className="pa--2">
            <div style={{width:"20px", height:"20px", backgroundColor:i}} onClick={clickColor}>
                {i==props.color?<span className={"fas fa-circle f6 pa--4 "+colorContrastMap[i]}/>:null}
            </div>
        </div>);
        c++;
        
        function clickColor() {
            props.onClick(i)
        }
    }
    return <div className="mv2">
        <div className="flex justify-between">
            {cList1}
        </div>
        <div className="flex justify-between">
            {cList2}
        </div>
        <div className="flex justify-between">
            {cList3}
        </div>
    </div>
}

export {
    AddObject,
    EditMapObject,
    getDefaultNames   
}
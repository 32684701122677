const React = require('react');
const {campaign,globalDataListener,areSameDeep,areSameDeepIgnore} = require('../lib/campaign.js');
const {Character} = require('../lib/character.js');
import sizeMe from 'react-sizeme';
const {EncounterPicker} = require("./renderplannedencounter.jsx");
const {getLocationInfo} = require("./renderhref.jsx");
const {EncounterDialog, getDefaultBookName} = require('./encounterview.jsx');
const {AddObject} = require('./objects.jsx');
const EventEmitter = require('events'); 
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
const {EntityEditor} = require('./entityeditor.jsx');
const {Book, BookPicker,getBookDescription,getChapterInfoFromFragment} = require('./book.jsx');
const {SharedTreasurePicker,TreasurePicker} = require('./items.jsx');
const {addToHandoutMRU} = require('./handouts.jsx');
const {SendInvitation} = require("./invite.jsx");
const {EncountersHistoryList} =require("./renderhistory.jsx");
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';

const {getAnchorPos, TextVal, CheckVal, AskYesNo,VDivider,HDivider,TabBlock,DeleteWithConfirm} = require('./stdedit.jsx');
const {MonsterPicker} = require("./rendermonster.jsx");
const {MonsterPCView} = require("./monsterpc.jsx");
const {Map, popupState} = require('./map.jsx');
const {EncounterMonsterList, calcXPTotals, getXP, getXPColor, Combatant,fixupCombatants,addRowToCombat,fixupSelection, toggleSelected} = require('./encountermonsterlist.jsx');
const {PickRandomEncounterInfoDialog} = require('./renderrandomtables.jsx');
const {RenderChat} = require('./renderchat.jsx');
const {CampaignConfig, DiceConfig} = require('./campaignconfig.jsx');
const {PlayerUserList,DialogCampaignCharacters} = require('./renderplayers.jsx');
const {NPCList} = require('./rendermonster.jsx');
const {PinDialog} = require('./pins.jsx');
const {FeedbackListDialog} = require('./renderfeedback.jsx');
const {DiceTower} = require('./dicetower.jsx');
const {RenderJournal,EditJournal} = require('./renderjournal.jsx');
const {RenderSounds} = require('./renderaudio.jsx');

const bookTab=10;
const creaturesTab = 11;
const logTab=12;
const trackerTab = 13;
const baseExtraBooksTab = 1000;
const mapTab=14;
const minTraySize = 120;

class AdventureView extends React.Component {
    constructor(props) {
        super(props);
        const prefs = campaign.getPrefs();

        this.state=this.getEncounterState({});
        this.state.selectedTab=mapTab;
        
        this.showImageHandoutFn = this.showImageHandout.bind(this);
        this.addRandomEncounterFn = this.addRandomEncounter.bind(this);

        this.state.selectedReference= prefs.selectedReference||"";
        this.state.chapter = prefs.referenceChapter;
        this.state.section = (prefs.referenceSection==null)?-1:prefs.referenceSection;
        this.state.subsection = (prefs.referenceSubsection==null)?-1:prefs.referenceSubsection;
        this.state.hideCombatTracker = prefs.hideCombatTracker||false;
        this.state.bookTabs = prefs.bookTabs;

        if (this.state.hideCombatTracker) {
            this.state.trackerPercent = 0;
        } else {
            this.state.trackerPercent = prefs.percentTracker ?? 30;
        }
        this.state.tabsPercent = prefs.percentTabs ?? 40;
        this.state.hPercent=prefs.percentDiceTray ?? 40;
        this.state.hTabsPercent=prefs.percentHTabs ?? 20;

        this.showEncounterFinishFn = this.showEncounterFinish.bind(this);
        this.dataChangeFn = this.datachange.bind(this);
        this.pickEncounterFn = this.pickEncounter.bind(this, false);
        this.onFinishEncounterFn = this.onFinishEncounter.bind(this);
        this.onChangeNextTurnTrueFn = this.onChangeNextTurn.bind(this, true);
        this.onChangeNextTurnFalseFn = this.onChangeNextTurn.bind(this, false);
        this.saveEncounterListFn = this.saveEncounterList.bind(this);
        this.updateEncounterCombatantsFn = this.updateEncounterCombatants.bind(this);
        this.getMapRefFn = this.getMapRef.bind(this);
        this.hideMonsterPickerFn = this.hideMonsterPicker.bind(this);
        this.changeEncounterReferenceFn = this.changeEncounterReference.bind(this);
        this.showMonsterPickerFn = this.showMonsterPicker.bind(this);
        this.onChangeCombatantPosFn = this.onChangeCombatantPos.bind(this);
        this.onTapTokenFn = this.onTapToken.bind(this);
        this.onAddEncounterFn = this.onAddEncounter.bind(this, false);
        this.onAddEncounterNoNavFn = this.onAddEncounter.bind(this, true);
        this.onAddPreviousEncounterFn = this.onAddPreviousEncounter.bind(this);
        this.onGoToMapFn = this.onGoToMap.bind(this);
        this.onGoToBookFn = this.onGoToBook.bind(this);
        this.changeBookFn = this.changeBook.bind(this);
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.handleClickContentFn = this.handleClickContent.bind(this);
        this.handleClickButtonFn = this.handleClickButton.bind(this);
        this.handleClickFragmentFn = this.handleClickFragment.bind(this);

        this.parentRef = React.createRef();
        this.hparentRef = React.createRef();
        this.hparentTabRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.encounter != prevState.encounter)) {
        }
    }

    componentDidMount() {
        popupState.subscribe(this.dataChangeFn);
        globalDataListener.onChangeCampaignSettings(this.handleOnDataChange);
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "adventure");
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "players");
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "monsters");
        this.props.pageSync.on("showImageHandout",  this.showImageHandoutFn);
        this.props.pageSync.on("addRandomEncounter",  this.addRandomEncounterFn);
        this.props.pageSync.on("clickcontent",  this.handleClickContentFn);
        this.props.pageSync.on("clickButton",  this.handleClickButtonFn);
        this.props.pageSync.on("clickfragment",  this.handleClickFragmentFn);
        this.props.pageSync.on("setcampaignbook",  this.onGoToBookFn);

        this.checkBook();
    }

    checkBook() {
        const book =campaign.getPrefs().selectedReference;
        if (book && !(campaign.getPrefs().addedBooks||[]).includes(book)) {
            const pre = getBookPregens(book);
            if (pre.length) {
                this.setState({askAddPregens:true, askText:"Do you want to add "+pre.length+" pregenerated characters from the book?"});
            }
        }
    }

    datachange() {
        this.setState({splitScreen:(popupState.numPanes==2), bookTabs:campaign.getPrefs().bookTabs});
        this.checkBook();
    }
  
    componentWillUnmount() {
        if (this.saveTimer) {
            clearTimeout(this.saveTimer);
            this.doUpdate();
        }
        popupState.unsubscribe(this.dataChangeFn);
        globalDataListener.removeCampaignSettingsListener(this.handleOnDataChange);
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "adventure");
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "players");
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "monsters");

        this.props.pageSync.removeListener("showImageHandout",  this.showImageHandoutFn);
        this.props.pageSync.removeListener("addRandomEncounter",  this.addRandomEncounterFn);
        this.props.pageSync.removeListener("clickcontent",  this.handleClickContentFn);
        this.props.pageSync.removeListener("clickfragment",  this.handleClickFragmentFn);
        this.props.pageSync.removeListener("clickButton",  this.handleClickButtonFn);
        this.props.pageSync.removeListener("setcampaignbook",  this.onGoToBookFn);
    }

    handleClickContent(click) {
        if (click.contentType == "Art") {
            const art = campaign.getArtInfo(click.id);
            if (art) {
                const ho = {
                    type:"art",
                    description:art.displayName,
                    art:art.name,
                    url:art.url,
                    imgHeight:art.imgHeight,
                    imgWidth:art.imgWidth
                };

                addToHandoutMRU(ho, true);
            }
        } else if (click.contentType == "BlockQuote") {
            const ho = {
                type:"html",
                description:click.summary,
                html:click.html,

            };

            //addToHandoutMRU(ho, true);
            this.setState({showHandoutMenu:true, handoutToDisplay:ho, anchorPos:getAnchorPos(click.event, true)});
        } else if (click.contentType=="Map") {
            this.onGoToMap({mapName:click.id});
        }
    }

    handleClickFragment(info) {
        this.setState({showJournalMenu:true, showJournalInfo:info, anchorPos:getAnchorPos(info.event, true)});
    }

    handleClickButton(action) {
        switch (action) {
            case "start":
                this.setState({showPlannedEncounterPicker:true});
                break;
            case "finish":
                this.showEncounterFinish();
                break;
        }
    }

    onDataChange() {
        const newState = this.getEncounterState(this.state);
        this.setState(newState);
    }

    getEncounterState(state) {
        if (this.saveTimer) {
            this.doUpdate();
            clearTimeout(this.saveTimer);
            this.saveTimer=null;
        }
        let encounter = campaign.getAdventure();
        let update;
        if (!encounter) {
            encounter={name:'default'};
        }

        if (encounter.positions) {
            const positions = encounter.positions;
            encounter = Object.assign({}, encounter);
            const combatants = (encounter.combatants||[]).concat();
            encounter.combatants = combatants;
            delete encounter.positions;

            for (let i in combatants) {
                let c = combatants[i];
                const p = positions[c.id];
                if (p) {
                    c = Object.assign({},c);
                    combatants[i]=c;
                    c.tokenX=p.tokenX;
                    c.tokenY=p.tokenY;
                    if (p.rotation != null) {
                        c.rotation=p.rotation;
                    } else {
                        delete c.rotation;
                    }
                }
            }
        }

        let combatants = encounter.combatants||[];
        const players=campaign.getPlayers();
        if ((combatants == this.savedCombatants) && (this.savedPlayers == players)) {
            return {};
        }
        if (this.savedPlayers != players) {
            let initCombatants = false;

            for (let x=combatants.length-1; x>=0; x--) {
                const c = combatants[x];
                if (["pc","cmonster"].includes(c.ctype)) {
                    const p = campaign.getPlayerInfo(c.name);
                    if (!p || p.claimable) {
                        combatants.splice(x,1);
                        update=true;
                    }
                }
            }

            for (let i in players) {
                const p = players[i];
                let found = false;

                if (!p.claimable) {
                    for (let x in combatants) {
                        const c = combatants[x]
                        if ((c.ctype == "pc") && (c.name==p.name)) {
                            c.displayName=p.displayName;
                            found = true;
                        }
                    }
                    if (!found) {
                        if (!initCombatants) {
                            combatants = combatants.concat([]);
                            initCombatants = true;
                        }
                        const id = campaign.newUid();
                        combatants.push({id:id, ctype:"pc", state:"active", type:null, name:p.name, displayName:p.displayName});
                        update=true;
                    }
                }
            }
            this.savedPlayers = players;
        }

        this.savedCombatants = combatants;
        if (fixupCombatants(combatants)||update) {
            encounter = Object.assign({}, encounter);
            encounter.combatants = combatants.concat([]);
            this.saveCampaign(encounter, true);
        }

        return Object.assign({encounter}, fixupSelection(combatants, state.selected, state.softSelected));
    }

    doUpdate() {
        campaign.updateCampaignContent("adventure", this.state.encounter);
    }

    render() {
        const {trackerPercent, tabsPercent} = this.state;
        const {showTracker,showMap, showTrackerTab, showMapTab,sections, trackerWidth, tabsWidth} = this.getWidths(trackerPercent, tabsPercent);
        this.showTracker = showTracker;
        this.trackerWidth = trackerWidth;
        this.tabsWidth = tabsWidth;

        return (
            <div className="h-100 w-100 relative">
                <div className="flex h-100 w-100 overflow-hidden items-stretch" ref={this.parentRef}>
                    {sections>=3?<div className="h-100" style={{width:trackerWidth}}>
                        {this.getDetailsCombatTracker(false, !showTracker)}
                    </div>:null}
                    {sections>=3?<VDivider 
                        onSlide={this.onSlideCombatTracker.bind(this)} 
                        getSections={this.getSectionsCombatTracker.bind(this)} 
                        parentRef={this.parentRef}
                    />:null}
                    {showMap?<div className="flex-auto wexact1 h-100 titlebordercolor bl br">
                        {this.getDetailsMaps()}
                    </div>:null}
                    {sections>1?<VDivider 
                        onSlide={this.onSlideTabs.bind(this)} 
                        getSections={this.getSectionsTabs.bind(this)} 
                        parentRef={this.parentRef}
                    />:null}
                    <div className="h-100" style={{width:tabsWidth}}>
                        {this.getCombinedDetails(showTrackerTab, showMapTab, sections>1)}
                    </div>
                </div>
                <EncounterPicker open={this.state.showPlannedEncounterPicker} onClose={this.pickEncounterFn}/>
                <FinishEncounterDialog show={this.state.showEncounterFinish||false} encounter={this.state.encounter} text={this.state.suggestEncounterName} onChange={this.onFinishEncounterFn}/>
                <PickRandomEncounterInfoDialog open={this.state.showPickRandomEncounter} row={this.state.randomEncounterRow} onClose={this.onCloseRandomEncounter.bind(this)}/>
                {this.getMenu()}
                <DiceTower/>
                <EditJournal open={this.state.newJournal} newJournal={this.state.newJournal} onClose={this.hideNewJournal.bind(this)}/>
            </div>
        );
    }

    getWidths(trackerPercent, tabsPercent) {
        const width = this.props.size.width;

        let showTracker,showMap, showTrackerTab, showMapTab,sections;
        let trackerWidth=0;
        let tabsWidth;

        if (width > 1200) {
            showTracker=showMap=true;
            sections=3;
        } else if (width > 850) {
            showMap = true;
            showTrackerTab=true;
            sections=2;
        } else {
            showTrackerTab=showMapTab=true;
            sections=1;
        }

        if (sections==3) {
            trackerWidth = width * trackerPercent/100;
            if (trackerWidth < 300) {
                showTracker=false;
                showTrackerTab=true;
                trackerWidth=15;
            }
    
            tabsWidth = (width-trackerWidth) * tabsPercent/100;
        } else if (sections==2) {
            tabsWidth = (width) * (tabsPercent)/100;
        } else {
            tabsWidth = width;
            trackerWidth=0;
        }

        if (tabsWidth < 340) {
            tabsWidth = 340;
        }
        let mapWidth = width-tabsWidth-trackerWidth;

        if ((mapWidth<=200) && showMap) {
            tabsWidth+=mapWidth;
            mapWidth=0;
            showMap=false;
            showMapTab=true;
        }

        return {showTracker,showMap, showTrackerTab, showMapTab,sections, trackerWidth, mapWidth, tabsWidth};
    }

    getSections(trackerPercent, tabsPercent){
        const {sections, trackerWidth, mapWidth, tabsWidth} = this.getWidths(trackerPercent, tabsPercent);
        const list = [mapWidth, tabsWidth];
        if (sections ==3) {
            list.unshift(trackerWidth);
        }
        return list;
    }

    getSectionsCombatTracker(pos) {
        const width = this.props.size.width;
        if (pos < 300) {
            pos=0;
        }
        return this.getSections(pos/width*100, this.state.tabsPercent);
    }

    onSlideCombatTracker(pos) {
        const width = this.props.size.width;
        if (pos < 300) {
            pos=0;
        }

        const trackerPercent = pos/width*100
        campaign.setPrefs({percentTracker:trackerPercent, hideCombatTracker:false});

        this.setState({trackerPercent});
    }

    getSectionsTabs(pos) {
        const width = this.props.size.width;
        let tabsPercent = (width - pos)/(width- this.trackerWidth)*100;
        if (tabsPercent > 100) {
            tabsPercent = 100;
        }
        return this.getSections(this.state.trackerPercent, tabsPercent);
    }

    onSlideTabs(pos) {
        const width = this.props.size.width;
        let tabsPercent = (width - pos)/(width- this.trackerWidth)*100;
        if (tabsPercent > 100) {
            tabsPercent = 100;
        }

        campaign.setPrefs({percentTabs:tabsPercent});
        this.setState({tabsPercent});
    }

    getHSections(pos) {
        const pRect = this.hparentRef.current.getBoundingClientRect();
        const height = pRect.height;

        const bottom = Math.min(height*0.8,Math.max(minTraySize, height-pos));
        const top = height-bottom;

        return [top,bottom];
    }

    onHSlide(pos) {
        const pRect = this.hparentRef.current.getBoundingClientRect();
        const height = pRect.height;
        const hPercent = Math.min(80, (height - pos)/height*100);

        campaign.setPrefs({percentDiceTray:hPercent});
        this.setState({hPercent});
    }

    getHTabSections(pos) {
        const pRect = this.hparentTabRef.current.getBoundingClientRect();
        const height = pRect.height;

        const bottom = Math.max(minTraySize, Math.min(height/2,height-pos));
        const top = height-bottom;

        return [top,bottom];
    }

    onHTabSlide(pos) {
        const pRect = this.hparentTabRef.current.getBoundingClientRect();
        const height = pRect.height;
        const hTabsPercent = (height - pos)/height*100;

        campaign.setPrefs({percentHTabs:hTabsPercent});
        this.setState({hTabsPercent});
    }

    getMenu() {
        if (this.state.showHandoutMenu) {
            return <Menu open disableAutoFocusItem anchorPosition={this.state.anchorPos} anchorReference="anchorPosition" onClose={this.closeMenu.bind(this)}>
                <MenuItem onClick={this.showHandout.bind(this)}>Show as Handout</MenuItem>
            </Menu>
        } if (this.state.showJournalMenu) {
            const {showJournalInfo}=this.state;
            let portion = ((showJournalInfo.subsection <0) && (showJournalInfo.section <0))?"Chapter":"Section";

            return <Menu open disableAutoFocusItem anchorPosition={this.state.anchorPos} anchorReference="anchorPosition" onClose={this.closeMenu.bind(this)}>
                <MenuItem onClick={this.showJournal.bind(this, true)}>Add Book to Journal</MenuItem>
                <MenuItem onClick={this.showJournal.bind(this, false)}>Add {portion} to Journal</MenuItem>
                <MenuItem onClick={this.showBookHandout.bind(this)}>Show {portion} as Handout</MenuItem>
            </Menu>
        }
        return null;
    }

    closeMenu() {
        this.setState({showHandoutMenu:false,showJournalMenu:false});
    }

    showHandout() {
        addToHandoutMRU(this.state.handoutToDisplay, true);
        this.setState({showHandoutMenu:false});
    }

    showBookHandout() {
        const {showJournalInfo} = this.state;
        const bookInfo = campaign.getBookInfo(showJournalInfo.bookname);

        if (bookInfo) {
            const ho = {
                type:"book",
                description:showJournalInfo.title||bookInfo.displayName,
                bookname:showJournalInfo.bookname,
                fragment:showJournalInfo.fragment
            };
            addToHandoutMRU(ho, true);
        }
        this.setState({showJournalMenu:false});
    }

    showJournal(wholeBook) {
        const {showJournalInfo} = this.state;
        const bookInfo = campaign.getBookInfo(showJournalInfo.bookname);

        if (bookInfo) {
            const newJournal={
                name:campaign.newUid(),
                createTime:Date.now(),
                bookname:showJournalInfo.bookname,
                displayName:wholeBook?bookInfo.displayName:showJournalInfo.title||bookInfo.displayName
            };

            if (!wholeBook) {
                newJournal.fragment = showJournalInfo.fragment;
            }

            this.setState({newJournal});
        }
        this.setState({showJournalMenu:false});
    }

    hideNewJournal() {
        this.setState({newJournal:null})
    }

    setHideCombatTracker(hideCombatTracker) {
        campaign.setPrefs({hideCombatTracker});
        if (hideCombatTracker) {
            this.setState({hideCombatTracker, trackerPercent:0});
        } else {
            this.setState({hideCombatTracker, trackerPercent:30});
        }
    }

    getDetailsCombatTracker(hideHide, tooSmall){
        const {xpTotal, target, xpAdjusted} = calcXPTotals(this.state.encounter.combatants, null, null);
        const {selected, softSelected} = this.state;
        const combatants = this.state.encounter.combatants||[];
        const allSmall = ((this.props.size.height-35)*this.state.hPercent/100) < 150;

        return <div className="w-100 flex flex-column h-100">
            {tooSmall?<div>
                <Tooltip title="show tracker"><span className="fas fa-chevron-right pa1 mt1 titlecolor f3 hoverhighlight" onClick={this.setHideCombatTracker.bind(this, false)}/></Tooltip>
            </div>:<div className="flex items-center w-100 defaultbackground">
                <div className="f5">
                    <Tooltip title="next turn">
                        <Button className="mh1 minw2" color="primary" variant="outlined" size="small" onClick={this.onChangeNextTurnTrueFn}><span className="fas fa-arrow-down"/></Button>
                    </Tooltip>
                    Turn
                    <Tooltip title="previous turn">
                        <Button className="mh1 minw2" color="primary" variant="outlined" size="small" onClick={this.onChangeNextTurnFalseFn}><span className="fas fa-arrow-up"/></Button>
                    </Tooltip>
                </div>
                <div className="flex-auto tc">
                    <span className="titleborder dib br3 ba pa1 mv--3 mh2 f5">Round {this.state.encounter.round||0}</span>
                </div>
                <div className="f6 tc mh1">
                    <span className={"br3 pa1 "+getXPColor(xpAdjusted, target)}>{xpTotal.toLocaleString()}XP</span>
                </div>
                {!hideHide?<div className="titleborder bl b h-100"><Tooltip title="hide tracker"><span className="fas fa-chevron-left pa1 mt1 titlecolor f3 hoverhighlight" onClick={this.setHideCombatTracker.bind(this, true)}/></Tooltip></div>:null}
            </div>}
            {tooSmall?null:<div className="flex-auto flex flex-column h1 relative" ref={this.hparentRef}>
                <div className="w-100 overflow-y-auto overflow-x-hidden relative h1 flex-auto defaultsemi">
                    <div className="pb1">
                        <EncounterMonsterList 
                            ref={this.saveEncounterListFn}
                            eventSync={this.props.pageSync}
                            members={combatants}
                            planning={false}
                            onClickCombatant={this.showCreatures.bind(this)}
                            onChange={this.updateEncounterCombatantsFn}
                            round={this.state.encounter.round}
                            getMapRef={this.getMapRefFn}
                            addSpellToken={this.addSpellToken.bind(this)}
                            enableAddTreasure
                            selected={selected} 
                            softSelected={softSelected}
                            toggleSelected={this.toggleSelected.bind(this)}
                            doSelection={this.doSelection.bind(this)}
                        />
                        {!combatants.length?<div className="stdcontent pa1 tc">
                            <p>
                                This section will display the combat tracker once you add characters or monsters.  Use it to track turn order and manage encounters.
                            </p>
                        </div>:null}
                        <div className="pv1 tc defaultbackground">
                            <Button className="ml1 mt1 minw2" color="primary" variant="outlined" size="small" onClick={this.showMonsterPickerFn}>add monsters</Button>
                        </div>
                    </div>
                </div>
                {!hideHide?<HDivider onSlide={this.onHSlide.bind(this)} getSections={this.getHSections.bind(this)} parentRef={this.hparentRef}/>:null}
                {!hideHide?<div className="relative" style={{height:Math.min(80,this.state.hPercent||0)+"%",minHeight:minTraySize}}>
                    <RenderChat 
                        allSmall={allSmall}
                        width={this.trackerWidth} 
                        addSpellToken={this.addSpellToken.bind(this,softSelected)} 
                        addToEncounter={addRowToCombat} 
                        selected={selected} 
                        softSelected={softSelected}
                    />
                </div>:null}
            </div>}
            <MonsterPicker includeCount onClose={this.hideMonsterPickerFn} open={this.state.showMonsterPicker} extraButtons={this.getMonsterPickerExtraButtons.bind(this)}/>
        </div>
    }

    toggleSelected(id) {
        this.setState(toggleSelected(this.state.selected, this.state.softSelected, id));
    }

    doSelection(type) {
        let {selected, softSelected,encounter} = this.state;
        const combatants = encounter.combatants;

        if (type=="clear") {
            selected = null;
        } else {
            selected = {};
            for (let i in combatants) {
                const c = combatants[i];
                if (["active", "dead"].includes(c.state||"active")) {
                    switch (type) {
                        case "all":
                            selected[c.id]=true;
                            break;
                        case "monsters":
                            if ((!c.ctype || c.ctype=="monster") && !c.friendly){
                                selected[c.id]=true;
                            }
                            break;
                        case "characters":
                            if (((!c.ctype || c.ctype=="monster") && c.friendly) || ["pc","cmonster"].includes(c.ctype)){
                                selected[c.id]=true;
                            }
                            break;
                    }
                }
            }
        }

        this.setState(fixupSelection(combatants, selected, softSelected));
    }

    getMonsterPickerExtraButtons() {
        return <CheckVal labelClass="titlecolor f7" label="SELECT TOKENS" value={this.state.showPickMonsterTokens} onChange={this.setShowPickMonsterTokens.bind(this, !this.state.showPickMonsterTokens)}/>
    }

    setShowPickMonsterTokens(showPickMonsterTokens){
        this.setState({showPickMonsterTokens});
    }

    saveEncounterList(r) {
        this.encounterList = r;
    }

    getMapRef() {
        return this.mapRef&&this.mapRef.map;
    }

    onChangeNextTurn(next, e){
        this.nextTurn(next);
    }

    nextTurn(goNext) {
        let newEM = this.state.encounter.combatants.concat([]);
        let round = this.state.encounter.round || 0;
        let first=-1, next=-1, last=-1, previous=-1, pick=-1;
        let found = false;
        let selectPlayer;

        for (let i in newEM) {
            let e = newEM[i];
            let saveLast = last;
            const crow = new Combatant(e);

            if (e.ctype && 
                (e.includeInCombat || (e.ctype != "object")) && 
                (!e.state || e.state == "active") &&
                (e.ctype=="pc" || !crow.showDead)
            ) {// eligible for a turn
                if (first < 0) {
                    first = i;
                }
                last = i;
                if (!e.currentTurn && found && (next <0)) {
                    next = i;
                }
            }

            if (e.currentTurn) {
                if (!found) {
                    previous=saveLast;
                }
                found = true;
                e = Object.assign({}, e);
                newEM[i]=e;
                e.currentTurn = false;
            } 
        }
        if (goNext) {
            if ((next < 0) && (first >=0)) {
                pick = first;
                round++;
            } else {
                pick = next;
            }
        } else {
            if (previous >= 0) {
                pick = previous;
            } else {
                pick = last;
                round--;
            }
        }
        if (pick >= 0) {
            let e=Object.assign({}, newEM[pick]);
            const crow = new Combatant(e);
            newEM[pick] = e;
            e.currentTurn=true;

            if (goNext) {
                const newE = crow.advanceTurn();
                if (newE) {
                    newEM[pick]=newE;
                }
            }
                        
            this.showCreatures(newEM[pick]);
        }
        if (round < 1) {
            round = 1;
        }
        this.setState({selected:null});
        this.updateEncounterProp("combatants", newEM, "round", round, selectPlayer?"selectedPlayer":null, selectPlayer);
    }

    showEncounterFinish() {
        const encounter = this.state.encounter;
        let base;

        if (encounter.encounters) {
            for (let i in encounter.encounters) {
                const einfo = campaign.getPlannedEncounterInfo(encounter.encounters[i]);
                if (einfo) {
                    base = einfo.displayName;
                    break;
                }
            }
        }

        if (!base) {
            base=encounter.tableDisplayName || "Encounter";
        }

        this.setState({showEncounterFinish:true, suggestEncounterName:base});
    }

    onFinishEncounter(res) {
        if (!res){
            this.setState({showEncounterFinish:false});
            return;
        }

        if (res.save) {
            let historyEncounter = Object.assign({}, this.state.encounter);
            historyEncounter.name = campaign.newUid();
            historyEncounter.displayName = res.name;
            historyEncounter.timeFinished = Date.now();
            historyEncounter.log = res.log||null;
            historyEncounter.keywords = res.keywords||null;

            historyEncounter.combatants = historyEncounter.combatants.concat([]);
            for (let i in historyEncounter.combatants) {
                const c = Object.assign({}, historyEncounter.combatants[i]);
                const crow = new Combatant(c);

                if (res.distributeXP && crow.player && ((c.state=="active")||!c.state)) {
                    const char = new Character(crow.player);
                    char.xp = char.xp+res.distributeXP;
                }

                c.displayName = crow.displayName;
                c.ac = crow.ac;
                c.hp = crow.hp;
                c.passive = crow.passive;

                historyEncounter.combatants[i] = c;

                historyEncounter.xpTotal = res.xpTotal;
            }

            if (historyEncounter.encounters) {
                const encounterPositions = [];

                for (let i in historyEncounter.encounters) {
                    const pin = campaign.findEncounterPin(historyEncounter.encounters[i]);
                    if (pin) {
                        encounterPositions.push(pin.mapPos);
                    }
                }
                historyEncounter.encounterPositions = encounterPositions;
            }
    
            campaign.updateCampaignContent("encountershistory", historyEncounter);
        }

        this.setState({showEncounterFinish:false});


        const encounter = {
            name:"default", 
            combatants:[],
            encounters:[],
            log:null
        };
        if (!res.save) {
            encounter.log = this.state.encounter.log||null;
        }

        const keep = res.keep;
        for (let x in this.state.encounter.combatants) {
            let c = this.state.encounter.combatants[x];
            if (!keep[c.id] && (c.ctype == "object" || (!c.ctype || c.ctype == "monster" ))) {
                // skip monsters and objects
                continue;
            } else if (["pc","cmonster"].includes(c.ctype)){
                const crow = new Combatant(c);
                crow.changeValue("initiative", null);
            }
            c = Object.assign({}, c);
            c.keep=true;
            c.initiative = null;
            c.currentTurn = false;
            encounter.combatants.push(c);
        }

        this.saveCampaign(encounter, false, true);
    }

    pickEncounter(noNav, name, row, selList) {
        this.setState({showPlannedEncounterPicker:false, selectedTab:mapTab});
        if (name) {
            const {showMonsterNames} = campaign.getPrefs();
            const newEncounter = Object.assign({},this.state.encounter);

            if (!newEncounter.encounters)
                newEncounter.encounters=[];

            const eindex = newEncounter.encounters.findIndex(function (m) {return !m.localeCompare(name)});
            const addEncounter = campaign.getPlannedEncounterInfo(name)||{};
            if (eindex < 0 ) {
                newEncounter.encounters = newEncounter.encounters.concat([name]);
            }
            newEncounter.combatants= (newEncounter.combatants || []).concat([]);

            if (addEncounter.combatants) {
                for (let i in addEncounter.combatants) {
                    const ac = Object.assign({},addEncounter.combatants[i]);
                    ac.id = campaign.newUid();

                    // override hideNames if showMonsterNames is set
                    if (showMonsterNames && (!ac.ctype || (ac.ctype == "monster"))) {
                        delete ac.hideName;
                    }

                    //make sure not to add unique monsters multiple times
                    const unique = ac.type && (campaign.getMonster(ac.type)||{}).unique;
                    const index = unique?newEncounter.combatants.findIndex(function (m) {return m.type==ac.type}):-1;
                    if (index < 0) {
                        newEncounter.combatants.push(ac);
                    }
                }
            }

            this.saveCampaign(newEncounter);

            const br=addEncounter.bookReference;
            if (br) {

                let chapter,section,subsection;
                if (br.fragment) {
                    const chaptInfo = getChapterInfoFromFragment(br.book, br.fragment);
    
                    chapter=chaptInfo.chapter;
                    section=chaptInfo.section;
                    subsection=chaptInfo.subsection;
                } else {
                    chapter=br.chapter;
                    section=br.section;
                    subsection=br.subsection;
                }
        
                campaign.setPrefs({selectedReference:br.book, referenceChapter:chapter, referenceSection:section, referenceSubSection:subsection});
                this.setState({selectedReference:br.book, chapter, section, subsection});
            }
    
            if (!noNav) {
                const pin = campaign.findEncounterPin(addEncounter.name);
                if (pin) {
                    this.setState({mapPosA:Object.assign({}, pin.mapPos)});
                }
            }
        } else if (row) {
            this.addRandomEncounter(row);
        } else if (selList) {
            this.encounterList.addMonsters(selList);
        }
    }

    onAddPreviousEncounter(name) {
        if (name) {
            const newEncounter = Object.assign({},this.state.encounter);
            const addEncounter = campaign.getEncounterHistoryInfo(name);
            if (!addEncounter) {
                return;
            }

            if (!newEncounter.encounters) {
                newEncounter.encounters=[];
            } else {
                newEncounter.encounters.concat([]);
            }

            for (let i in addEncounter.encounters||[]) {
                const addName = addEncounter.encounters[i];
                let eindex = newEncounter.encounters.findIndex(function (m) {return !m.localeCompare(addName)});
                if (eindex < 0 ) {
                    newEncounter.encounters.push(addName);
                }
            }
    
            newEncounter.combatants= (newEncounter.combatants || []).concat([]);

            if (addEncounter.combatants) {
                for (let i in addEncounter.combatants) {
                    const ac = addEncounter.combatants[i];
                    if (ac.ctype != "pc") {
                        let index = newEncounter.combatants.findIndex(function (m) {return m.id==ac.id});
                        if (index < 0)
                            newEncounter.combatants.push(ac);
                    }
                }
            }

            this.saveCampaign(newEncounter);
        }
    }

    getDetailsBook() {
        const v = this.state.selectedReference;
        
        const bookinfo = campaign.getBookInfo(v);

        return <div className="w-100 flex h-100 flex-column">
            <div className="flex items-center pa1 defaultbackground">
                <div className="flex-auto f3 titletext titlecolor">
                    {bookinfo?<span>{true?<span className="fas fa-thumbtack pa1 hoverhighlight" onClick={this.pinBook.bind(this)}/>:null}{bookinfo.displayName}</span>:"(no book selected)"}
                </div>
                <Button color="primary" variant="outlined" size="small" onClick={this.clickPickBook.bind(this)}>pick book</Button>
                {bookinfo?<Button className="ml1" color="primary" variant="outlined" size="small" onClick={this.toggleEditBook.bind(this)}>{this.state.editBook?"stop editing":"edit"}</Button>:null}
            </div>
            <div className="w-100 h1 overflow-hidden flex-auto defaultsemi">
                {bookinfo?<Book 
                    bookname={v} 
                    editable={this.state.editBook}
                    pageSync={this.props.pageSync}
                    onClick={this.changeBookFn} 
                    chapter={this.state.chapter} 
                    section={this.state.section} 
                    subsection={this.state.subsection}
                    onClickEncounter={this.onAddEncounterFn}
                    onClickLink={this.onGoToMapFn}
                    rollable
                    showHero
                    addSpellToken={this.addSpellToken.bind(this,null)}
                    handleHref={this.checkHandleHref.bind(this)}
                    addToEncounter={addRowToCombat}
                />:<div className="stdcontent pa1 tc">
                    <p>
                        Your selected book will display here.  Use the book as a reference, and to select maps, display handouts, or add planned encounters.
                    </p>
                </div>}
            </div>
            <BookPicker open={this.state.pickBook} onClose={this.onPickBook.bind(this)}/>
            <AskYesNo open={this.state.askAddPregens} onClose={this.closeAddPregens.bind(this)} text={this.state.askText}/>
            {this.getMRUBooks()}
        </div>;
    }

    pinBook() {
        const {selectedReference, chapter, section, subsection} = this.state;
        const bookinfo = campaign.getBookInfo(selectedReference);
        if (bookinfo) {
            const newTab = {
                name:getNameAbbrev(bookinfo.displayName), 
                book:selectedReference, 
                chapter, 
                section, 
                subsection
            };
            const bookTabs = (campaign.getPrefs().bookTabs||[]).concat([newTab]);

            campaign.setPrefs({bookTabs});
            this.setState({bookTabs, selectedTab:baseExtraBooksTab+(bookTabs.length-1)});
        }
    }

    removeBookTab(i) {
        let bookTabs = (campaign.getPrefs().bookTabs||[]).concat([]);

        bookTabs.splice(i,1);

        if (!bookTabs.length) {
            bookTabs=null;
        }
        campaign.setPrefs({bookTabs});
        this.setState({bookTabs});
    }

    toggleEditBookTab(i) {
        const bookTabs = (campaign.getPrefs().bookTabs||[]).concat([]);

        const bt = Object.assign({}, bookTabs[i]);
        bt.edit = !bt.edit;
        bookTabs[i]=bt;

        campaign.setPrefs({bookTabs});
        this.setState({bookTabs});
    }

    changeBookTab(i, chapter, section, subsection) {
        const bookTabs = (campaign.getPrefs().bookTabs||[]).concat([]);

        const bt = Object.assign({}, bookTabs[i]);
        Object.assign(bt, {chapter, section,subsection});
        bookTabs[i]=bt;

        campaign.setPrefs({bookTabs});
        this.setState({bookTabs});
    }

    addBookTabs(tabs) {
        const {bookTabs} = this.state;
        for (let i in bookTabs) {
            const bt = bookTabs[i];
            const bookinfo = campaign.getBookInfo(bt.book);

            const tabInfo = <div className="w-100 flex h-100 flex-column">
                <div className="flex items-center pa1 defaultbackground">
                    <div className="flex-auto f3 titletext titlecolor">
                        <span className="fas fa-times pa1 hoverhighlight" onClick={this.removeBookTab.bind(this, i)}/>{bookinfo?bookinfo.displayName:"(no book selected)"}
                    </div>
                    {bookinfo?<Button className="ml1" color="primary" variant="outlined" size="small" onClick={this.toggleEditBookTab.bind(this,i)}>{bt.edit?"stop editing":"edit"}</Button>:null}
                </div>
                <div className="w-100 h1 overflow-hidden flex-auto defaultsemi">
                    {bookinfo?<Book 
                        bookname={bt.book} 
                        editable={bt.edit}
                        pageSync={this.props.pageSync}
                        onClick={this.changeBookTab.bind(this,i)} 
                        chapter={bt.chapter} 
                        section={bt.section} 
                        subsection={bt.subsection}
                        onClickEncounter={this.onAddEncounterFn}
                        onClickLink={this.onGoToMapFn}
                        rollable
                        showHero
                        addSpellToken={this.addSpellToken.bind(this,null)}
                        handleHref={this.checkHandleHref.bind(this)}
                        addToEncounter={addRowToCombat}
                    />:<div className="stdcontent pa1 tc">
                        <p>
                            Book not available
                        </p>
                    </div>}
                </div>
            </div>;

            tabs.push({id:baseExtraBooksTab+Number(i), label:bt.name, body:tabInfo});
        }
    }

    checkHandleHref(href) {
        const page = getLocationInfo(href||"");
        if (page.page == "book") {
            if (page.fragment) {
                const ci = getChapterInfoFromFragment(page.id, page.fragment);
                this.onGoToBook(page.id, ci.chapter, ci.section, ci.subsection);
            } else {
                this.onGoToBook(page.id, page.chapter, page.section,page.subsection);
            }
            return true;
        }
        return false;
    }

    toggleEditBook() {
        this.setState({editBook:!this.state.editBook});
    }

    clickPickBook(event) {
        const mruList = campaign.getMRUList("mruBooks")||[];
        if (!mruList.length) {
            this.setState({pickBook:true});
        } else {
            this.setState({showMruBook:true, anchorMruBook:event.target})
        }
    }

    showMruBook() {
        this.setState({pickBook:true, showMruBook:false});
    }

    getMRUBooks() {
        if (!this.state.showMruBook) {
            return;
        }
        const ml = [];
        const mruList = campaign.getMRUList("mruBooks");

        for (let i in mruList) {
            const m=mruList[i];

            const bookInfo = campaign.getBookInfo(m.book);
            if (bookInfo) {
                ml.push(<MenuItem key={i} onClick={this.onGoToBook.bind(this, m.book, m.chapter, m.section, m.subsection)}>{m.description}</MenuItem>);
            }
        }

        return <Menu 
            open
            anchorEl={this.state.anchorMruBook} 
            onClose={this.hideMruBook.bind(this)}
        >
            <MenuItem onClick={this.showMruBook.bind(this)}>Pick From Library</MenuItem>
            <Divider/>
            {ml}
        </Menu>
    }

    hideMruBook() {
        this.setState({showMruBook:false});
    }

    onPickBook(book, newbook) {
        this.setState({pickBook:false, editBook:newbook||this.state.editBook});
        if (book) { 
            if (!(campaign.getPrefs().addedBooks||[]).includes(book)) {
                const pre = getBookPregens(book);
                if (pre.length) {
                    this.setState({askAddPregens:true, askText:"Do you want to add "+pre.length+" pregenerated characters from the book?"});
                }
            }
            this.onGoToBook(book, 0, -1, -1);
        }
    }

    closeAddPregens(add) {
        if (add) {
            const pre = getBookPregens(this.state.selectedReference);
            for (let i in pre) {
                const p = campaign.getMyCharacterInfo(pre[i]);
                if (p) {
                    const newCharacter = Object.assign({},p);
                    newCharacter.name = campaign.newUid();
                    delete newCharacter.pregen;
                    delete newCharacter.diceRolls;
                    delete newCharacter.lastRoll;
                    newCharacter.claimable = true;
            
                    campaign.updateCampaignContent("players", newCharacter)
                }
            }
        }
        const addedBooks = (campaign.getPrefs().addedBooks||[]).concat([]);
        addedBooks.push(this.state.selectedReference);
        campaign.setPrefs({addedBooks});
        this.setState({askAddPregens:false});
    }

    changeBook(chapter, section, subsection) {
        campaign.setPrefs({referenceChapter:chapter, referenceSection:section, referenceSubSection:subsection});
        this.setState({chapter, section, subsection});
    }

    changeEncounterReference(event) {
        campaign.setPrefs({selectedReference:event.target.value, referenceChapter:0, referenceSection:-1, referenceSubSection:-1});
        this.setState({selectedReference:event.target.value, chapter:0, section:-1, subsection:-1});
    }

    getCombinedDetails(showTracker, showMap) {
        const tabs = [
            {id:bookTab, label:"Book", body:this.getDetailsBook()},
        ];
        if (showTracker) {
            tabs.push({id:trackerTab, label:"Tracker", body:this.getDetailsCombatTracker(true)});
        }
        if (showMap) {
            tabs.push({id:mapTab, label:"Map", body:this.getDetailsMaps()});
        }
        tabs.push({id:creaturesTab, label:"Creatures", body:this.getDetailsMonsters()});
        tabs.push({id:logTab, label:"Log", body:this.getDetailsLog()});
        tabs.push({id:"journal", label:"Journal", body:<div className="w-100 h-100 overflow-y-auto overflow-x-hidden defaultsemi"><RenderJournal/></div>});
        if (campaign.getAudio().length) {
            tabs.push({id:"audio", label:"Audio", body:<div className="w-100 h-100 overflow-y-auto overflow-x-hidden defaultsemi"><RenderSounds/></div>});
        }
        tabs.push({id:"chat", label:"Chat", body:this.getChat()});
        this.addBookTabs(tabs);

        const showChat = showTracker && (this.state.selectedTab!="chat")
        const chatH = this.props.size.height*this.state.hTabsPercent/100

        return <div className="w-100 flex flex-column h-100"  ref={this.hparentTabRef}>
            <TabBlock tabs={tabs} currentTab={this.state.selectedTab||bookTab} onChangeTab={this.changeTab.bind(this)}>
                {showChat?<HDivider onSlide={this.onHTabSlide.bind(this)} getSections={this.getHTabSections.bind(this)} parentRef={this.hparentTabRef}/>:null}
                {showChat?<div className="relative" style={{height:chatH,minHeight:minTraySize,maxHeight:"50%"}}>
                    <RenderChat 
                        allSmall={chatH<120}
                        width={this.tabsWidth}
                        addSpellToken={this.addSpellToken.bind(this,this.state.softSelected)}
                        addToEncounter={addRowToCombat}
                        selected={this.state.selected} 
                        softSelected={this.state.softSelected}
                    />
                </div>:null}
            </TabBlock>
        </div>
    }

    showCreatures(d) {
        if (d.ctype != "object" || (d.custom)){
            if (this.showTracker){
                this.changeTab(creaturesTab);
            }
            this.setState({selectedCreature:d.id, softSelected:d.id})
        }
    }

    showImageHandout(ho) {
        addToHandoutMRU(ho, true);
    }

    addRandomEncounter(row) {
        if (row.monsters && !Object.keys(row.monsters).length) {
            this.onCloseRandomEncounter(row);
        } else {
            this.setState({showPickRandomEncounter:true, randomEncounterRow:row});
        }
    }

    onCloseRandomEncounter(row) {
        if (row){
            if (row.tableDisplayName) {
                // this is a hack add monsters will trigger a save changes which will save this value
                this.state.encounter.tableDisplayName = row.tableDisplayName;
            }
            this.encounterList.addMonsters(row.monsters);
            if (row.bookname) {
                this.onGoToBook(row.bookname, row.chapter, row.section, row.subsection);
            }
        }

        this.setState({showPickRandomEncounter:false});
    }

    changeTab(selectedTab) {
        this.setState({selectedTab});
    }

    getDetailsLog() {
        return <div className="w-100 h-100 overflow-y-auto overflow-x-hidden defaultsemi">
            <EncountersHistoryList/>
        </div>;
    }
    
    getChat() {
        return <div className="w-100 h-100">
            <RenderChat 
                width={this.tabsWidth}
                addSpellToken={this.addSpellToken.bind(this,this.state.softSelected)}
                addToEncounter={addRowToCombat}
                selected={this.state.selected} 
                softSelected={this.state.softSelected}
                allowDiceSounds
            />
        </div>;
    }
    
    getDetailsMonsters() {
        const combatants = this.state.encounter.combatants;

        return <div key="ml" className="mb1 h-100 flex-1 overflow-y-auto overflow-x-hidden nodrag defaultsemi">
            <MonsterPCView
                cid={this.state.selectedCreature} 
                eventSync={this.props.pageSync}
                members={combatants}
                onChange={this.updateEncounterCombatantsFn}
                showMessage={<div className="stdcontent pa1 tc">
                    <p>This section will display the details of the currently selected monster or character.  Either click the row in the combat tracker or the token on the map.</p>
                </div>}
                rollable
                addSpellToken={this.addSpellToken.bind(this)}
            />
        </div>;
    }

    addSpellToken(cid, ao) {
        const combatants = this.state.encounter.combatants;
        const mapRef = this.getMapRef();
        const mapName = mapRef && mapRef.state.imageName;
        let actionPos, actionMap;
    
        if (cid) {
            for (let i in combatants) {
                const c = combatants[i];

                if ((cid==c.id) && (c.tokenMap == mapName)) {
                    actionPos = {x:c.tokenX, y:c.tokenY};
                    actionMap = mapRef;
                }
            }
        }

        this.encounterList.addObject(ao, actionPos, actionMap);
    }

    getDetailsMaps() {
        const t=this;
        const pinRules ={location:true};
        const bi = this.getSelectedBookInfo();

        return <div key="mapl" className="h-100 w-100 flex-2 defaultsemi">
            <Map 
                mapRef={function(ref) {t.mapRef = ref;}} 
                combatants={this.state.encounter.combatants} 
                onChangeCombatantPos={this.onChangeCombatantPosFn} 
                onTapToken={this.onTapTokenFn}
                onTapMap={this.onTapMap.bind(this)}
                onAddEncounter={this.onAddEncounterNoNavFn}
                onGoToEncounter={this.onGoToEncounter.bind(this)}
                onAddPreviousEncounter={this.onAddPreviousEncounterFn}
                onGoToMap={this.onGoToMapFn}
                onGoToBook={this.onGoToBookFn}
                bookname={bi.selectedReference}
                chapter={bi.chapter} 
                section={bi.section} 
                subsection={bi.subsection}
                mapPos={this.state.mapPosA}
                eventSync={this.props.pageSync}
                pinRules={pinRules}
                showMessage={this.getShowMessage()}
                selected={this.state.selected}
                toggleSelected={this.toggleSelected.bind(this)}
                doSelection={this.doSelection.bind(this)}
                showAdventureMessage
                showPickHandout
            />
            {this.getMapContextMenu()}
            <EncounterDialog 
                encounter={this.state.encounterDetails} 
                open={this.state.showEncounter} 
                onClose={this.onCloseEncounter.bind(this)} 
                onGoToEncounter={this.onGoToEncounter.bind(this)}
                createNew={this.state.createNew} 
                mapPos={this.state.emapPos}
                startingBook={bi.selectedReference||null}
                startingChapter={bi.chapter}
                startingSection={bi.section}
                startingSubsection={bi.subsection}
            />
            <PinDialog 
                open={this.state.showNewPin} 
                bookname={bi.selectedReference}
                chapter={bi.chapter} 
                section={bi.section} 
                subsection={bi.subsection}
                pin={this.state.newPin}
                onClose={this.setNewPin.bind(this,false)}
            />
        </div>;
    }

    getSelectedBookInfo() {
        const {selectedTab,bookTabs} = this.state;

        if (bookTabs && (selectedTab >= baseExtraBooksTab)) {
            const ct = selectedTab-baseExtraBooksTab;
            const bt = bookTabs[ct];
            if (bt?.book) {
                return {selectedReference:bt.book, chapter:bt.chapter, section:bt.section, subsection:bt.subsection};
            }
        }
        return this.state;
    }

    getShowMessage() {
        return <div className="tc titletext">
            <div className="f2 titlecolor">Welcome to Your Campaign</div>
            <span className="f3">
                <p>To start playing, choose a map using one of the below buttons or choose a book in the book tab to play a packaged adventure. 
                    To learn more about adventuring check out our <a href="https://shardtabletop.com/campaign">adventuring getting started page</a>.
                </p>
                <p>To invite players, click <a onClick={triggerEvent.bind(null, this.props.pageSync, "sendInvitation")}>Invite</a>.</p>
                <p>When you finish an encounter and want clean up monsters for the next encounter, click <b>Finish</b> button.  The state from the current encounter will be added to the campaign log.</p>
            </span>
        </div>
    }

    onGoToEncounter(encounter) {
        this.setState({encounterDetails:encounter, showEncounter:true, emapPos:null, createNew:false})
    }

    setNewPin(showNewPin) {
        let newPin=null;
        if (showNewPin) {
            let mapPos = null;
            mapPos = Object.assign({}, this.state.actionPos);
            mapPos.mapName = this.state.actionMap.state.imageName;
            mapPos.diameter = 80;

            newPin = {
                name:campaign.newUid(),
                type:"location", 
                mapPos: mapPos,
            }

            const bi = this.getSelectedBookInfo();

            if (bi.selectedReference) {
                const {getFragmentFromChapterSection} = require('./book.jsx');
                const fragment = getFragmentFromChapterSection(bi.selectedReference, bi.chapter, bi.section, bi.subsection);
        
                newPin.links=[{
                    name:bi.selectedReference, 
                    type:"book", 
                    book:bi.selectedReference, 
                    fragment
                }];
            }
        }
        this.setState({showNewPin,newPin,showMapContextMenu:false});
    }

    onNewEncounter() {
        let mapPos = null;
        mapPos = Object.assign({}, this.state.actionPos);
        mapPos.mapName = this.state.actionMap.state.imageName;
        mapPos.diameter = 80;

        const bi = this.getSelectedBookInfo();

        let newEncounterName = getDefaultBookName(bi.selectedReference, bi.chapter, bi.section, bi.subsection);
        if (!newEncounterName && mapPos.mapName) {
            const mi = campaign.getMapInfo(mapPos.mapName);
            newEncounterName = mi && mi.displayName;
        }

        this.setState({showEncounter:true, encounterDetails:newEncounterName, createNew:true, emapPos:mapPos, showMapContextMenu:false});
    }

    onCloseEncounter() {
        this.setState({showEncounter:false})
    }

    onGoToMap(mapPos) {
        this.setState({mapPosA:Object.assign({}, mapPos),showMapContextMenu:false, selectedTab:mapTab});
    }

    onGoToBook(book, chapter, section, subsection) {
        if (this.state.selectedReference) {
            const b=this.state.selectedReference;
            const c = this.state.chapter;
            const s = this.state.section;
            const ss = this.state.subsection;
            campaign.addMRUList("mruBooks", {description:getBookDescription(b, c, s, ss), book:b, chapter:c||0, section:s||0, subsection:ss||0});
        }
        campaign.setPrefs({selectedReference:book, referenceChapter:chapter||0, referenceSection:section||0, referenceSubSection:subsection||0});
        this.setState({selectedReference:book, chapter, section, subsection, selectedTab:0, showMruBook:false});

    }

    onAddEncounter(noNav, encounter) {
        this.pickEncounter(noNav, encounter);
    }

    onTapToken(i, e) {
        if (this.encounterList) {
            this.showCreatures(this.state.encounter.combatants[i]);
            this.encounterList.displayContextMenu(i, getAnchorPos(e.evt, true));
        }
    }

    onChangeCombatantPos(index, x, y, rotation) {
        const newEncounter = Object.assign({},this.state.encounter);
        const combatants = (newEncounter.combatants||[]).concat([]);
        newEncounter.combatants = combatants;
        const update={};

        if (Array.isArray(index)) {
            for (let i in index) {
                const a = index[i];
                const c = Object.assign({},combatants[a.index]);
                combatants[a.index]=c;

                c.tokenX=a.x;
                c.tokenY=a.y;
                if (a.rotation != null) {
                    c.rotation=a.rotation;
                    update["positions."+c.id] = {tokenX:a.x, tokenY:a.y, rotation:a.rotation};
                } else {
                    c.rotation=(c.rotation!=null)?c.rotation:null;
                    update["positions."+c.id] = {tokenX:a.x, tokenY:a.y, rotation:c.rotation};
                }
            }
        } else {
            const c = Object.assign({},combatants[index]);
            combatants[index]=c;
            c.tokenX=x;
            c.tokenY=y;
            if (rotation != null) {
                c.rotation=rotation;
                update["positions."+c.id] = {tokenX:x, tokenY:y, rotation:rotation};
            } else {
                c.rotation=(c.rotation!=null)?c.rotation:null;
                update["positions."+c.id] = {tokenX:x, tokenY:y, rotation:c.rotation};
            }
        }
        campaign.updateAdventure(update);
        this.setState({encounter:newEncounter});
    }

    getMapContextMenu() {
        const t=this;
        if (!this.state.showMapContextMenu)
            return null;

        return <Menu className="nodrag" open={true} disableAutoFocusItem anchorPosition={this.state.anchorPos} anchorReference="anchorPosition" transitionDuration={0} onClose={function(){t.setState({showMapContextMenu:false})}}>
            {this.state.coverRegion?<MenuItem onClick={this.onToggleCoverRegion.bind(this)}>{this.state.coverRegion.uncovered?"Restore fog":"Unfog"}</MenuItem>:null}
            <MenuItem onClick={this.onCenterMap.bind(this)}>Center</MenuItem>
            <MenuItem onClick={this.onHuddleMap.bind(this, "monster")}>Huddle Monsters</MenuItem>
            <MenuItem onClick={this.onHuddleMap.bind(this, "pc")}>Huddle Characters</MenuItem>
            <MenuItem onClick={this.onAddMonsterToMap.bind(this)}>Add Monsters</MenuItem>
            <AddObject onAddObject={this.addObjectToMap.bind(this)} useMenu/>
            <Divider/>
            {this.state.coverRegion?<DeleteWithConfirm useMenu onClick={this.onDeleteCoverRegion.bind(this)} altText="Delete Fog Region" name="fog region" extraDescription="Use Unfog to reveal the map."/>:null}
            <MenuItem onClick={this.onNewEncounter.bind(this)}>New Encounter</MenuItem>
            <MenuItem onClick={this.setNewPin.bind(this,true)}>New Pin</MenuItem>
        </Menu>;
    }


    onToggleCoverRegion() {
        this.state.actionMap.toggleCover(this.state.coverRegion);
        this.setState({showMapContextMenu:false});
    }

    onDeleteCoverRegion() {
        this.state.actionMap.deleteCover(this.state.coverRegion);
        this.setState({showMapContextMenu:false});
    }

    onCenterMap() {
        const newPos = Object.assign({}, this.state.actionMap.state.mapPos);
        newPos.x = this.state.actionPos.x;
        newPos.y = this.state.actionPos.y;
        if (this.state.actionMap.props.mapIndex != 1) {
            this.setState({mapPosA:newPos, showMapContextMenu:false});
        } else {
            this.setState({mapPosB:newPos, showMapContextMenu:false});
        }
    }

    onHuddleMap(group) {
        this.encounterList.doHuddle(this.state.actionPos.x, this.state.actionPos.y, group, "active", this.state.actionMap, this.state.actionMap.state.imageName, -1);
        this.setState({showMapContextMenu:false});
    }

    onTapMap(pos, e, mapRef, coverRegion) {
        this.setState({showMapContextMenu:true, actionPos:pos, actionMap:mapRef, anchorPos:getAnchorPos(e.evt, true), coverRegion});
    }

    onAddMonsterToMap() {
        this.setState({showMapContextMenu:false, showMonsterPicker:true});
    }

    addObjectToMap(obj) {
        this.encounterList.addObject(obj, this.state.actionPos, this.state.actionMap);
        this.setState({showMapContextMenu:false});
    }

    onAddPlayer(name) {
        const newEncounter = Object.assign({},this.state.encounter);

        if (!newEncounter.combatants)
            newEncounter.combatants = [];

        const emon = newEncounter.combatants.concat([]);

        const player = campaign.getPlayerInfo(name);
        if (!player){
            console.log("could not add characters.  couldn't find character info", name);
            return;
        }

        for (let i in emon) {
            const m = emon[i];

            if ((m.ctype == "pc") && (player.name == m.name)) {
                console.log("character already added");
                return;
            }
        }

        const id = campaign.newUid();
        let newMon = {id:id, ctype:"pc", type:null, name:player.name};

        emon.push(newMon);
        newEncounter.combatants=emon;

        this.saveCampaign(newEncounter);
    }

    showMonsterPicker(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({showMonsterPicker:true, actionPos:null, actionMap:null});
    }

    hideMonsterPicker(selList) {
        if (selList) {
            this.encounterList.addMonsters(selList, this.state.actionPos, this.state.actionMap, this.state.showPickMonsterTokens);
        }
        this.setState({showMonsterPicker:false,showPickMonsterTokens:false});
    }

    addMonsterClick(sel) {
        this.addMonster(sel.id, 1);
        return true;
    }

    addMonster(monster, count, pos, map) {
        this.encounterList.addMonster(monster,count, pos, map);
    }

    addObject(obj) {
        this.encounterList.addObject(obj);
    }

    updateEncounterCombatants(newList) {
        const newEncounter = Object.assign({},this.state.encounter);
        newEncounter.combatants = newList;
        this.saveCampaign(newEncounter);
    }

    updateEncounterProp(prop, value, prop2, value2, prop3, value3) {
        const newEncounter = Object.assign({},this.state.encounter);
        newEncounter[prop] = value;
        if (prop2)
            newEncounter[prop2] = value2;
        if (prop3)
            newEncounter[prop3] = value3;
        this.saveCampaign(newEncounter);
    }

    saveCampaign(newEncounter, noSet, force) {
        const t=this;

        fixupCombatants(newEncounter.combatants);

        if (!noSet) {
            this.setState({encounter:newEncounter});
        }

        if (this.saveTimer) {
            clearTimeout(this.saveTimer);
        }
        if (force) {
            campaign.updateCampaignContent("adventure", newEncounter);
            t.saveTimer=null;
        } else {
            this.saveTimer = setTimeout(function () {
                t.doUpdate();
                t.saveTimer=null;
            }, 20);
        }
    }
}

class FinishEncounterDialog extends React.Component {
    constructor(props) {
        super(props);

	    this.state= this.initState(props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.show != prevProps.show) {
            this.setState(this.initState(this.props));
        }
    }

    initState(props){
        const keep={};
        const addXP={};
        if (props.encounter) {
            const combatants = props.encounter.combatants;

            for (let i in combatants) {
                const d=combatants[i];
                addXP[d.id]=0;
                if (d.keep) {
                    keep[d.id]=true;
                } else {
                    if ((!d.ctype || d.ctype=="monster") && ["active", "dead"].includes(d.state||"active") && !d.friendly) {
                        addXP[d.id] = getXP(d);
                    }
                }
            }    
        }

        return{
            text:props.text,
            save:true,
            log:props.encounter.log,
            keep,
            addXP,
            xpAdjust:0
        } 
    }

    handleClose(savechanges, event) {
        const noDistributeXP = !!campaign.getPrefs().noDistributeXP;
        const {text, keywords, save, keep, log, distributeXP} = this.state;
        this.props.onChange(savechanges?{name:text, keywords, save:save, xpTotal:this.xpTotal, keep:keep, log:log, distributeXP:noDistributeXP?0:(distributeXP || this.xpDistDefault)}:null);
        event.stopPropagation();
    };

    render() {
        if (!this.props.show) {
            return null;
        }
        const combatants = (this.props.encounter.combatants||[]).concat([]);
        const noDistributeXP = !!campaign.getPrefs().noDistributeXP;
        const save=this.state.save;
        combatants.sort(function (a,b) {return (a.name||"").toLowerCase().localeCompare((b.name||"").toLowerCase()) });
        const rows = [];
        const deadrows=[];
        const tokenrows =[];
        const keep=this.state.keep||{};
        const addXP=this.state.addXP||{};
        let xpBase= 0;
        let hasTreasure = false;
        let pcCnt = 0;

        for (let i in combatants) {
            const d=combatants[i];
            const crow = new Combatant(d);
            let hideXP=noDistributeXP;
            if (!["pc", "cmonster"].includes(d.ctype)) {
                let l=rows;
                if (d.ctype=="object") {
                    l=tokenrows;
                    hideXP=true;
                } else if (crow.hp==0){
                    l=deadrows;
                }
                l.push(<tr key={i}>
                    <td className="tc lh1"><span onClick={this.toggleKeep.bind(this,d.id)} className={keep[d.id]?"pa1 far fa-check-square hoverhighlight":"pa1 far fa-square hoverhighlight"}/></td>
                    {save?<td className="tr lh1">{!hideXP?<TextVal className="w4 nudge-down--5" placeholder="XP" text={(addXP[d.id]||0)} isNum inputProps={{className:"lh1 tr pv0"}} onChange={this.setAddXP.bind(this,d.id)}/>:null}&nbsp;</td>:null}
                    <td className="w-100 lh1">{crow.friendly?<span className="titlecolor far fa-smile mr--3"/>:null}{d.displayName||d.name}</td>
                </tr>);
            } else if ((d.ctype=="pc") && ((d.state == "active")||!d.state)) {
                pcCnt++;
            }
            if (d.treasure) {
                hasTreasure=true;
            }
            xpBase += (addXP[d.id]||0);
        }
        const xpTotal = xpBase+(this.state.xpAdjust||0);
        this.xpTotal = xpTotal;
        this.xpDistDefault = pcCnt?Math.trunc(xpTotal/pcCnt):0;
        
        return <Dialog
            open={this.props.show||false}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Finish Encounter</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    {save?<div>
                        <TextVal
                            text={this.state.text||""}
                            onChange={this.onChange.bind(this, "text")}
                            helperText="Encounter Log Name"
                            fullWidth
                        />
                        <TextVal text={this.state.keywords||""} onChange={this.onChange.bind(this,"keywords")} fullWidth helperText="Keywords"/>
                        <div className="w-100 mv1">
                            <EntityEditor onChange={this.updateLog.bind(this)} entry={this.state.log} placeholder="Description" depth="2"/>
                        </div>
                        <div className="mb1">
                            <span onClick={this.toggleNoDistribute.bind(this)} className={!noDistributeXP?"pa1 far fa-check-square hoverhighlight":"pa1 far fa-square hoverhighlight"}/>
                            Experience Points{noDistributeXP?null:<span>: {xpBase} + <TextVal className="w4 nudge-down--3" placeholder="XP" text={this.state.xpAdjust} isNum inputProps={{className:"pv0 titletext tr"}} onChange={this.setXPAdjust.bind(this)}/> = {xpTotal}XP</span>}
                        </div>
                        {pcCnt&&!noDistributeXP?<div className="mb2 ml3">
                            Distribute <TextVal disabled={!!noDistributeXP} className="w4 nudge-down--1" placeholder="zero" text={this.state.distributeXP || this.xpDistDefault} isNum inputProps={{className:"pv0 titletext tr"}} onChange={this.setDistributeXP.bind(this)}/> XP to {pcCnt} players
                        </div>:null}
                    </div>:null}
                    <div className="mb1">{hasTreasure?"There is some treasure that has not been granted.":null}
                        <Button className="mh1" color="secondary" variant="outlined" size="small" onClick={this.showTreasure.bind(this,true)}>Grant Treasure</Button>
                    </div>
                    <div>
                        <h2>Combatants</h2>
                        <div>
                            <table className="w-100">
                                <tbody>
                                    <tr><td  className="b">Keep</td>{save&&!noDistributeXP?<td className="b tc">XP</td>:null}</tr>
                                    {rows}
                                    {deadrows.length?<tr><td/>{save?<td/>:null}<td className="b">Dead</td></tr>:null}
                                    {deadrows}
                                    {tokenrows.length?<tr><td/>{save?<td/>:null}<td className="b">Tokens</td></tr>:null}
                                    {tokenrows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <CheckVal label="Save Encounter to Log" value={save} onChange={this.toggleSelected.bind(this)}/>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    OK
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <TreasurePicker open={this.state.showTreasure} onClose={this.showTreasure.bind(this,false)}/>
        </Dialog>;
    }

    toggleNoDistribute() {
        const noDistributeXP = !campaign.getPrefs().noDistributeXP;
        campaign.setPrefs({noDistributeXP});
        this.setState({noDistributeXP});
    }

    setDistributeXP(distributeXP) {
        this.setState({distributeXP});
    }

    showTreasure(show) {
        this.setState({showTreasure:show});
    }

    toggleKeep(id) {
        const keep= Object.assign({},this.state.keep||{});
        const addXP= Object.assign({},this.state.addXP||{});
        keep[id] = !keep[id];
        if (keep[id]) {
            addXP[id]=0;
        } else {
            const combatants = this.props.encounter.combatants;
            for (let i in combatants) {
                const d=combatants[i];
                if (d.id==id) {
                    if ((!d.ctype || d.ctype=="monster") && ["active", "dead"].includes(d.state||"active")) {
                        addXP[id] = getXP(d);
                    } else {
                        addXP[id]=0;
                    }
                }
            }
        }
        this.setState({keep,addXP,distributeXP:null});
    }

    updateLog(log) {
        this.setState({log});
    }

    setXPAdjust(xpAdjust){
        this.setState({xpAdjust,distributeXP:null});
    }

    setAddXP(id,value) {
        const addXP= Object.assign({},this.state.addXP||{});
        addXP[id] = value||0;
        this.setState({addXP,distributeXP:null});
    }

    onChange(prop,value) {
        const st = {};
        st[prop]=value
        this.setState(st);
    }

    toggleSelected() {
        this.setState({save:!this.state.save});
    }
}


class AdventureHeader extends React.Component {
    constructor(props) {
        super(props);

        this.onSendInvitationFn = this.onSendInvitation.bind(this);
        this.state= {};
        this.anchorRef = React.createRef();
    }

    componentDidMount() {
        this.props.pageSync.on("sendInvitation", this.onSendInvitationFn);
    }
  
    componentWillUnmount() {
        this.props.pageSync.removeListener("sendInvitation", this.onSendInvitationFn);
    }

    render() {
        return <span className="dib pv--1">
            <span className="hoverhighlight truncate mr1" ref={this.anchorRef} onClick={this.showMenu.bind(this,true)}>{campaign.getPrefs().displayName} <span className="fas fa-cog"/></span>
            <Button className="minw2 mr1" color="primary" variant="outlined" size="small" onClick={triggerEvent.bind(null, this.props.pageSync, "clickButton", "start")}>Start</Button>
            <Button className="minw2 mr1" color="primary" variant="outlined" size="small" onClick={triggerEvent.bind(null, this.props.pageSync, "clickButton", "finish")}>Finish</Button>
            <Button className="minw2 mr1" color="primary" variant="outlined" size="small" onClick={this.showTreasure.bind(this,true)}>Treasure</Button>
            <Button className="minw2 mr1" color="primary" variant="outlined" size="small" onClick={this.onSendInvitationFn}>Invite</Button>
            <SendInvitation show={this.state.showInvitation||false} onClose={this.onCloseSendInvitation.bind(this)}/>
            <TreasurePicker open={this.state.showTreasure} onClose={this.showTreasure.bind(this,false)} showTreasure/>
            <CampaignConfig open={this.state.showConfig} onClose={this.setShowConfig.bind(this, false)}/>
            <DiceConfig open={this.state.showDiceConfig} onClose={this.setShowDiceConfig.bind(this, false)}/>
            <Menu open={this.state.showMenu||false} 
                anchorEl={this.anchorRef.current} 
                onClose={this.showMenu.bind(this,false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'left',}}
            >
                <MenuItem onClick={this.setCampaignCharacters.bind(this, true)}>Campaign Characters</MenuItem>
                <MenuItem onClick={this.setShowPlayerUsers.bind(this,true)}>Campaign Player List</MenuItem>
                {campaign.getNPCs().length?<MenuItem onClick={this.setShowNPCs.bind(this,true)}>Campaign Non-Player Characters</MenuItem>:null}
                <MenuItem onClick={this.setShowDiceConfig.bind(this, true)}>Dice</MenuItem>
                <MenuItem onClick={this.setShowConfig.bind(this, true)}>Settings</MenuItem>
                <MenuItem onClick={this.showFeedback.bind(this, true)}>Feedback</MenuItem>
            </Menu>
            <PlayerUserList open={this.state.showPlayerUsers} onClose={this.setShowPlayerUsers.bind(this,false)}/>
            <DialogCampaignCharacters open={this.state.showCampaignCharacters} onClose={this.setCampaignCharacters.bind(this,false)}/>
            <NPCList open={this.state.showNPCs} onClose={this.setShowNPCs.bind(this,false)}/>
            <FeedbackListDialog open={this.state.showFeedback} onClose={this.showFeedback.bind(this,false)}/>
        </span>;
    }

    showFeedback(showFeedback) {
        this.setState({showFeedback, showMenu:false});
    }

    setShowPlayerUsers(showPlayerUsers) {
        this.setState({showPlayerUsers, showMenu:false});
    }

    setShowNPCs(showNPCs) {
        this.setState({showNPCs, showMenu:false});
    }

    setShowConfig(showConfig) {
        this.setState({showConfig, showMenu:false});
    }

    setShowDiceConfig(showDiceConfig) {
        this.setState({showDiceConfig, showMenu:false});
    }

    showMenu(show) {
        this.setState({showMenu:show});
    }

    showTreasure(show) {
        this.setState({showTreasure:show, showMenu:false});
    }

    onSendInvitation() {
        this.setState({showInvitation:true, showMenu:false});
    }
    
    onCloseSendInvitation() {
        this.setState({showInvitation:false});
    }

    changeName() {
        this.setState({changeName:true, showMenu:false});
    }

    onNewName(displayName) {
        if (displayName) {
            campaign.setPrefs({displayName});
        }
        this.setState({changeName:false});
    }

    setCampaignCharacters(showCampaignCharacters) {
        this.setState({showMenu:false,showCampaignCharacters});
    }
}

function getBookPregens(name) {
    const book = campaign.getBookInfo(name);
    const pregens = [];

    if (book) {
        for (let c in book.chapters) {
            const chapter = book.chapters[c];
            addPregens(chapter);
            for (let s in chapter.sections) {
                const section = chapter.sections[s];
                addPregens(section);
                for (let ss in section.subsections) {
                    const subsection = section.subsections[ss];
                    addPregens(subsection);
                }    
            }
        }
    }
    return pregens;

    function addPregens(s) {
        if (s.contentType == "Pregenerated Characters") {
            pregens.push(s.contentId)
        } else if (s.contentList) {
            for (let i in s.contentList) {
                const ce = s.contentList[i];
                if (ce.contentType == "Pregenerated Characters") {
                    pregens.push(ce.contentId);
                }
            }
        }
    }
}

function addPregens(book) {
    const pre = getBookPregens(book);
    for (let i in pre) {
        const p = campaign.getMyCharacterInfo(pre[i]);
        if (p) {
            const newCharacter = Object.assign({},p);
            newCharacter.name = campaign.newUid();
            delete newCharacter.pregen;
            delete newCharacter.diceRolls;
            delete newCharacter.lastRoll;
            newCharacter.claimable = true;
    
            campaign.updateCampaignContent("players", newCharacter)
        }
    }
    const addedBooks = (campaign.getPrefs().addedBooks||[]).concat([]);
    addedBooks.push(book);
    campaign.setPrefs({addedBooks});
}

function getNameAbbrev(name) {
    let an="";
    const v = name.split(" ");
    for (let s of v) {
        if (s.length) {
            an=an+ s.substr(0,1);
        }
    }
    return an;
}

function triggerEvent(eventSync, event, data) {
    eventSync.emit(event, data);
}
const AdventureViewSize = sizeMe({monitorHeight:true, monitorWidth:true})(AdventureView);

export {
    AdventureViewSize as AdventureView, 
    AdventureHeader,
}